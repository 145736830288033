import styled from "@emotion/styled";

import BaseFooterBar from "shared/components/FooterBar";
import { UnderlineLink } from "shared/components/Links";
import { MEDIA_QUERIES } from "shared/config/constants";

const StyledFooter = styled(BaseFooterBar)`
  justify-content: flex-end;

  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: row;
    padding: 0 50px 0 24px;
  }

  ${MEDIA_QUERIES.w.ltSm} {
    flex-direction: row;
    padding: 0 80px 0 24px;
  }

  ${MEDIA_QUERIES.w.gteLg} {
    flex-direction: row;
    padding: 0 50px 0 24px;
  }

  ${MEDIA_QUERIES.w.gteMd} {
    padding: 0 50px 0 24px;
  }

  & > :last-child {
    margin-right: 0;
  }

  .actions-dropdown {
    padding-right: 20px;
  }
`;

interface StyledButtonProps {
  showMobile?: boolean;
}

const StyledButton = styled.div<StyledButtonProps>`
  margin: 0 12px;
  ${MEDIA_QUERIES.w.ltSm} {
    display: ${({ showMobile }) => (showMobile ? "block" : "none")};
  }
`;

interface UnderlineLinkProps {
  noOrder?: boolean;
}

const InlineCancelButton = styled(UnderlineLink)<UnderlineLinkProps>`
  margin-right: 20px;
  margin-left: 20px;
  flex-shrink: 0;

  ${({ noOrder }) => !noOrder && `order: 3`}
`;

export { StyledFooter, StyledButton, InlineCancelButton };
