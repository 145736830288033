import { useMemo } from "react";

import * as Yup from "yup";

import { conditionallyApproveDeal } from "shared/lib/api/dealApi";

import { DealSummary } from "../types";

import { Form } from "./types";

export const useHook = (deal: DealSummary) => {
  const init: Form = useMemo(() => {
    return {
      applicants: deal?.applicantsSummary.map(({ id }) => ({
        application: String(id),
        selected: false,
        requiresAdditionalGuarantor: false,
        requiresAdditionalAttachment: false,
        additionalAttachments: [""],
        readMessage: false,
        customMessage: undefined,
      })),
    };
  }, [deal]);

  const approve = async (values: Form) => {
    const payload = {
      data: Object.values(values.applicants)
        .filter(({ selected }) => selected)
        .map(({ readMessage, customMessage, ...rest }) => ({
          ...(readMessage && { customMessage }),
          ...rest,
        }))
        .map(({ additionalAttachments, ...rest }) => ({
          additionalAttachments: additionalAttachments
            ?.map((item) => ({
              name: item,
            }))
            .filter(({ name }) => !!name),
          ...rest,
        })),
      id: deal.id,
    };

    await conditionallyApproveDeal(payload);
  };

  const schema = Yup.object().shape({
    applicants: Yup.array().of(
      Yup.object()
        .shape({
          selected: Yup.boolean(),
          requiresAdditionalGuarantor: Yup.boolean(),
          requiresAdditionalAttachment: Yup.boolean(),
          additionalAttachments: Yup.array()
            .of(Yup.string())
            .when("requiresAdditionalAttachment", {
              is: true,
              then: Yup.array()
                .of(Yup.string().required("attachment can't be blank"))
                .required("you must add at least one attachment"),
              otherwise: Yup.array().of(Yup.string()),
            }),
          readMessage: Yup.boolean(),
          customMessage: Yup.string().when("readMessage", {
            is: true,
            then: Yup.string().required("message is required"),
            otherwise: Yup.string(),
          }),
        })
        .test("atleastOneChecked", function validate(value) {
          if (value.selected) {
            if (
              value.requiresAdditionalAttachment ||
              value.requiresAdditionalGuarantor ||
              value.readMessage
            ) {
              return true;
            }
            return false;
          }
          return true;
        })
    ),
  });

  return { approve, init, schema };
};
