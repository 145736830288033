


import FlexContainer from "shared/components/FlexContainer";
import { SmallText } from "shared/components/Typography";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { StyledSumSection } from "./styled";

interface LeaseFeeSubSumProps {
  label: string;
  amount: number;
  canEditFees: boolean;
}

const LeaseFeeSubSum = ({
  label,
  amount,
  canEditFees,
}: LeaseFeeSubSumProps) => (
  <StyledSumSection>
    <FlexContainer
      justifyContent="space-between"
      className={`sub-sum-row ${canEditFees ? "fees-editable" : ""}`}
    >
      <SmallText light>{label}</SmallText>
      <SmallText light extraStrong>
        {printDollarsFromCents(amount)}
      </SmallText>
    </FlexContainer>
  </StyledSumSection>
);

export default LeaseFeeSubSum;
