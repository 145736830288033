import React from "react";

import PropTypes from "prop-types";

import Container from "shared/components/Container";
import Settings from "shared/components/Settings";

import ApplicationSetupHeader from "../Header";
import { StyledSkeleton as Skeleton } from "../styled";

import ApplicantQuestions from "./ApplicantQuestions";
import ManagerQuestionsTable from "./ManagerQuestionsTable";

const LeasePackages = ({
  isLoading,
  managerQuestions,
  applicantQuestions,
  editManagerQuestion,
  isEditLoading,
  openEditApplicantQuestionModal,
  openManagerQuestionModal,
  openDeleteManagerQuestionConfirmation,
  isPortfolioSettings,
}) => (
  <Settings
    hasComponentPadding={isLoading}
    header={
      <Skeleton loading={isLoading}>
        <ApplicationSetupHeader
          title="Lease Packages"
          subTitle="Please manage the documents for all questions that you wish to require from applicants, occupants and guarantors"
        />
      </Skeleton>
    }
    nodes={[
      {
        key: 1,
        component: (
          <Skeleton loading={isLoading}>
            <Container noPadding>
              <ManagerQuestionsTable
                loading={isLoading}
                managerQuestions={managerQuestions}
                editManagerQuestion={editManagerQuestion}
                isEditLoading={isEditLoading}
                openManagerQuestionModal={openManagerQuestionModal}
                openDeleteManagerQuestionConfirmation={
                  openDeleteManagerQuestionConfirmation
                }
              />
              <ApplicantQuestions
                loading={isLoading}
                applicantQuestions={applicantQuestions}
                openEditApplicantQuestionModal={openEditApplicantQuestionModal}
                isPortfolioSettings={isPortfolioSettings}
              />
            </Container>
          </Skeleton>
        ),
      },
    ]}
  />
);

LeasePackages.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  managerQuestions: PropTypes.arrayOf(PropTypes.object),
  applicantQuestions: PropTypes.arrayOf(PropTypes.object),
  editManagerQuestion: PropTypes.func.isRequired,
  isEditLoading: PropTypes.bool.isRequired,
  openEditApplicantQuestionModal: PropTypes.func.isRequired,
  openManagerQuestionModal: PropTypes.func.isRequired,
  openDeleteManagerQuestionConfirmation: PropTypes.func.isRequired,
  isPortfolioSettings: PropTypes.bool,
};

LeasePackages.defaultProps = {
  managerQuestions: [],
  applicantQuestions: [],
  isPortfolioSettings: undefined,
};

export default LeasePackages;
