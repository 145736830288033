import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

export const Title = styled.p`
  font-weight: 300;
  margin: 0;
  padding: 40px 50px 26px;
  ${MEDIA_QUERIES.w.ltMd} {
    padding: 20px;
  }
`;
