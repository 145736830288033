import React, { Key, useState } from "react";

import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RadioChangeEvent } from "antd/lib/radio";
import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import {
  useHandleLeaseOffline,
  useSetLeaseInProgress,
} from "manager/hooks/api";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";

import DownloadLeaseModal from "./DownloadLeaseModal";

interface DownloadLeaseModalContainerProps {
  unitName: string;
  propertyName: string;
  downloadLease: () => void;
  leaseId: Key;
  isSignedOffline: boolean;
  cacheESigning: (eSigning: boolean) => void;
  propertyAddress: string;
}

const DownloadLeaseModalContainer = ({
  unitName,
  propertyName,
  downloadLease,
  leaseId,
  isSignedOffline,
  cacheESigning,
  propertyAddress,
}: DownloadLeaseModalContainerProps) => {
  const [eSigning, setESigning] = useState();
  const [isSigningConfirmed, setSigningConfirmed] = useState(false);
  const [notAskAgain, setNotAskAgain] = useState(false);
  const history = useHistory();
  const { closeActiveModalDialog } = useModal();

  const { handleLeaseOffline, isLoading: isSigningOffline } =
    useHandleLeaseOffline(leaseId);

  const { setLeaseInProgress, isLoading: isSettingInProgress } =
    useSetLeaseInProgress(leaseId);

  const onESigningChange = (event: RadioChangeEvent) =>
    setESigning(event.target.value);

  const onAfterClose = () => {
    if (isSigningConfirmed && !eSigning) {
      history.push(transformRoute(ROUTES.leaseSummary, { id: leaseId }));
    }
  };
  const handleCloseModal = () => {
    closeActiveModalDialog();
    onAfterClose();
  };

  const signLeaseOffline = async () => {
    if (!isSignedOffline) {
      await handleLeaseOffline(leaseId);
    }
  };

  const signLeaseOnline = async () => {
    if (isSignedOffline) {
      await setLeaseInProgress(leaseId);
    }
  };

  const onConfirm = async () => {
    if (notAskAgain) {
      cacheESigning(eSigning);
    }

    if (eSigning) {
      await signLeaseOnline();
    } else {
      await signLeaseOffline();
    }

    downloadLease();
    setSigningConfirmed(true);
  };

  const onNotAskAgainChange = (event: CheckboxChangeEvent) =>
    setNotAskAgain(event.target.checked);

  return (
    <DownloadLeaseModal
      unitName={unitName}
      propertyName={propertyName}
      onESigningChange={onESigningChange}
      eSigning={eSigning}
      onConfirm={onConfirm}
      isSigningConfirmed={isSigningConfirmed}
      notAskAgain={notAskAgain}
      onNotAskAgainChange={onNotAskAgainChange}
      isSigningOffline={isSigningOffline}
      isSettingInProgress={isSettingInProgress}
      propertyAddress={propertyAddress}
      closeModal={handleCloseModal}
      onAfterClose={onAfterClose}
    />
  );
};

export default DownloadLeaseModalContainer;
