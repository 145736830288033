import { Key } from "react";

import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { Company } from "manager/interfaces/api/company";
import SHARED_ROUTES from "shared/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  /**
   * @returns {Promise} { username, password }
   */
  createOpenApiUser: () =>
    axios
      .post(`${API_URL}${ROUTES.createOpenApiUser}`)
      .then(({ data }) => data),
  /**
   * @param {string} webhookUrl
   * @returns {Promise}
   */
  testOpenApiWebhook: (webhookUrl) =>
    axios.post(
      `${API_URL}${ROUTES.testOpenApiWebhook}`,
      { webhookUrl },
      {
        skipInterceptorErrorHandling: true,
      }
    ),
  /**
   * @returns {Promise<{ password }>}
   */
  resetOpenApiPassword: () =>
    axios
      .post(`${API_URL}${ROUTES.resetOpenApiPassword}`)
      .then(({ data }) => data),
  /**
   * @returns {Promise}
   */
  setOpenApiSettings: ({ webhookEnabled, webhookUrl, redirectUrl }) =>
    axios.post(`${API_URL}${ROUTES.setOpenApiSettings}`, {
      webhookEnabled,
      webhookUrl,
      redirectUrl,
    }),
  getCompanies: () =>
    axios
      .get<Company[]>(`${API_URL}${ROUTES.companies}`)
      .then(({ data }) => data),
  getCompany: async (id: Key) =>
    axios
      .get<Company>(
        `${API_URL}${transformRoute(SHARED_ROUTES.company, { id })}`
      )
      .then(({ data }) => data),
  dismissCompany: (id: number) =>
    axios.patch(`${API_URL}${ROUTES.dismissCompany}`, undefined, {
      headers: {
        Company: `${id}`,
      },
    }),
  getPermissions: (companyId: Key) =>
    axios
      .get(`${API_URL}${SHARED_ROUTES.companyPermissions}`, {
        headers: {
          Company: companyId.toString(),
        },
      })
      .then(({ data }) => data),
};

export default api;
