import React from "react";

import Icon from "shared/components/Icon";

import { ApplicantName as ApplicantNameProps } from "../../Leases/interfaces";

import {
  ApplicantInfo,
  ApplicantName,
  StyledTag,
  StyledTagIcon,
  Contacts,
  EmailLink,
} from "./styled";

interface PersonalInfoProps {
  applicant: ApplicantNameProps;
}

const PersonalInfo = ({ applicant }: PersonalInfoProps) => (
  <ApplicantInfo>
    <Contacts>
      <ApplicantName>{`${applicant?.firstName} ${applicant?.lastName}`}</ApplicantName>
      <EmailLink href={`mailto:${applicant?.email}`}>
        {applicant?.email}
      </EmailLink>
    </Contacts>
    <StyledTag hideTag={!applicant?.isSigned}>
      <StyledTagIcon>
        <Icon.SignedDocumentIcon />
      </StyledTagIcon>
      signed
    </StyledTag>
  </ApplicantInfo>
);

export default PersonalInfo;
