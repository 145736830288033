import React from "react";

import { useFormikContext } from "formik";

import { Resident } from "manager/interfaces/api/renewals";
import {
  Select4,
  VeroFormField,
  RadioGroup,
  Radio,
  Select4Option,
} from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";
import { getFullnameData } from "shared/utils/applicant";

import { AddLeaseholderFormValues, InvitationMode } from "./interfaces";
import { StyledAddRenewalModal } from "./styled";

const AddRenewalLeaseholderModal = ({
  residents = [],
}: {
  residents?: Resident[];
}) => {
  const { values, handleSubmit, isSubmitting } =
    useFormikContext<AddLeaseholderFormValues>();

  return (
    <StyledAddRenewalModal
      title="Add a new leaseholder"
      submit={handleSubmit}
      submitting={isSubmitting}
      submitButtonLabel="Confirm"
    >
      <Modal.Body>
        <RegularText weak>
          How would you like to add the leaseholder?
        </RegularText>
        <Spacer size={Spacer.SIZES.sm} />
        <VeroFormField
          id="invitation"
          name="invitation"
          as={RadioGroup}
          options={[
            {
              label: "Select a current applicant",
              value: InvitationMode.existingApplicant,
            },
            {
              label: "Invite a new applicant to apply",
              value: InvitationMode.newApplicantWithScreening,
            },
            {
              label: "Invite leaseholder but don’t screen",
              value: InvitationMode.newApplicantWithoutScreening,
            },
          ]}
          radioOptionRenderer={(option) => {
            const isExistingApplicantOption =
              option.value === InvitationMode.existingApplicant;
            return (
              <>
                <Radio
                  key={option.value}
                  value={option.value}
                  className={`invitation-option ${
                    isExistingApplicantOption ? "existing-applicant" : ""
                  }`}
                >
                  <RegularText weak>{option.label}</RegularText>
                </Radio>
                {isExistingApplicantOption && (
                  <VeroFormField
                    id="resident"
                    name="resident"
                    as={Select4}
                    label="Leaseholders"
                    containerClassName="residents-select"
                    allowClear
                    disabled={
                      values.invitation !== InvitationMode.existingApplicant
                    }
                  >
                    {residents.map((item) => (
                      <Select4Option value={item.id} key={item.id}>
                        {getFullnameData(item).fullNameOrNA}
                      </Select4Option>
                    ))}
                  </VeroFormField>
                )}
              </>
            );
          }}
        />
      </Modal.Body>
    </StyledAddRenewalModal>
  );
};

export default AddRenewalLeaseholderModal;
