import { RenewalCategory } from "manager/config/constants";

import { RenewalsFilters, RenewalsOrderMap } from "./interfaces";

export const ORDER_BY_NAMES = Object.freeze({
  unitName: "expiring_lease__deal__unit__name",
  propertyName: "expiring_lease__deal___property__name",
  leaseExpirationDate: "expiring_lease__end_date",
  rent: "expiring_lease__rent",
});

export const ORDER_BY: RenewalsOrderMap = Object.freeze({
  unit: {
    ascend: [ORDER_BY_NAMES.unitName, ORDER_BY_NAMES.propertyName].join(),
    descend: [ORDER_BY_NAMES.unitName, ORDER_BY_NAMES.propertyName]
      .map((name) => `-${name}`)
      .join(),
  },
  leaseExpirationDate: {
    ascend: ORDER_BY_NAMES.leaseExpirationDate,
    descend: `-${ORDER_BY_NAMES.leaseExpirationDate}`,
  },
  rent: {
    ascend: ORDER_BY_NAMES.rent,
    descend: `-${ORDER_BY_NAMES.rent}`,
  },
});

export const blankFilters: RenewalsFilters = {
  unitPropertyOrApplicant: "",
  propertyId: [],
  dateMin: null,
  dateMax: null,
  renewalStatus: [],
  renewalCategory: RenewalCategory.ALL,
};

export const RENEWAL_CATEGORIES_LIST = [
  {
    key: RenewalCategory.ALL,
    apiKey: "",
    name: "All",
  },
  {
    key: RenewalCategory.UPCOMING,
    apiKey: RenewalCategory.UPCOMING,
    name: "Upcoming",
  },
  {
    key: RenewalCategory.OFFER_APPROVALS,
    apiKey: RenewalCategory.OFFER_APPROVALS,
    name: "Offers",
  },
  {
    key: RenewalCategory.PENDING,
    apiKey: RenewalCategory.PENDING,
    name: "Pending",
  },
  {
    key: RenewalCategory.COMPLETED,
    apiKey: RenewalCategory.COMPLETED,
    name: "Completed",
  },
];
