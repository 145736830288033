import React from "react";

import { RenewalOption } from "shared/interfaces/api/renewals";

import {
  LeaseRenewalPanelContainerProps,
  LeaseRenewalPanelFormData,
} from "./interfaces";
import { LeaseRenewalPanel } from "./LeaseRenewalPanel";

const INITIAL_VALUES: LeaseRenewalPanelFormData = { selectedOption: undefined };

export const LeaseRenewalPanelContainer = ({
  selectedLeaseForRenewal,
  acceptOffer,
  rejectOffer,
}: LeaseRenewalPanelContainerProps) => {
  const numOfferOptions =
    selectedLeaseForRenewal?.renewalOffer?.renewalOptions?.length;
  const leaseApplicationRenewalOffer = selectedLeaseForRenewal?.renewalOffer;

  const onSubmit = ({ selectedOption }: LeaseRenewalPanelFormData) => {
    const option = (
      selectedLeaseForRenewal?.renewalOffer?.renewalOptions || []
    ).find((o) => o.id === selectedOption);
    return acceptOffer({
      leaseApplicationId: selectedLeaseForRenewal?.leaseApplicationId,
      option,
    });
  };
  const onMoveOut = () => {
    return rejectOffer({
      leaseApplicationId: selectedLeaseForRenewal?.leaseApplicationId,
    });
  };
  const ownSelectedOption =
    leaseApplicationRenewalOffer?.applicantSelectedOption;
  const othersSelectedOption =
    leaseApplicationRenewalOffer?.othersSelectedOption;
  const selectedOption = (ownSelectedOption || othersSelectedOption)
    ?.renewalOption;
  const isOptionDisabled = (option: RenewalOption) => {
    return selectedOption && selectedOption.id !== option?.id;
  };
  const isChangeDisabled = !!ownSelectedOption;
  const initialValues = selectedOption
    ? {
        selectedOption: selectedOption?.id,
      }
    : INITIAL_VALUES;

  const message = isChangeDisabled
    ? 'The offer option is already selected. Contact the leasing agent if an alternative option is needed. Mark yourself as "Not Renewing" if you will be leaving the application group.'
    : "Select the offer to receive those details in your lease renewal";

  return (
    <LeaseRenewalPanel
      selectedLeaseForRenewal={selectedLeaseForRenewal}
      numOfferOptions={numOfferOptions}
      message={message}
      onSubmit={onSubmit}
      onMoveOut={onMoveOut}
      isOptionDisabled={isOptionDisabled}
      isChangeDisabled={isChangeDisabled}
      initialValues={initialValues}
    />
  );
};
