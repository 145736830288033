import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { RadioGroup } from "shared/components/Form";
import Settings from "shared/components/Settings";

import ApplicationSetupHeader from "../Header";
import { StyledSkeleton as Skeleton } from "../styled";

import { Container } from "./styled";

const LeaseESigning = ({ loading, disabled }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Settings
      header={
        <Skeleton loading={loading}>
          <ApplicationSetupHeader title="Lease E-signing" />
        </Skeleton>
      }
      nodes={[
        {
          key: 1,
          component: (
            <Skeleton loading={loading}>
              <Container>
                <RadioGroup
                  id="isElectronicallySigned"
                  name="isElectronicallySigned"
                  label="Will applicants and guarantors have access to electronically sign the lease?"
                  options={[
                    {
                      value: true,
                      label: "Yes",
                    },
                    {
                      value: false,
                      label: "No",
                    },
                  ]}
                  value={values.isElectronicallySigned}
                  onChange={(value) => {
                    setFieldValue("isElectronicallySigned", value, false);
                  }}
                  disabled={disabled}
                />
              </Container>
            </Skeleton>
          ),
        },
      ]}
    />
  );
};

LeaseESigning.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

LeaseESigning.defaultProps = {
  loading: false,
  disabled: false,
};

export default LeaseESigning;
