import { datadogRum } from "@datadog/browser-rum";

export const initDataDogRum = () => {
  if (!process.env.REACT_APP_DD_RUM_CLIENT_TOKEN) {
    console.log("Skip Datadog RUM");
    return;
  }

  datadogRum.init({
    applicationId:
      process.env.REACT_APP_DD_RUM_APPLICATION_ID ||
      "732ebd98-47bf-4bf7-9234-465ce375ffe1",
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: process.env.REACT_APP_DD_RUM_SERVICE || "frontend",
    env: process.env.REACT_APP_DD_RUM_ENVIRONMENT,
    version: process.env.REACT_APP_GIT_HASH,
    sampleRate: Number(process.env.REACT_APP_DD_RUM_SAMPLE_RATE) || 100,
    sessionReplaySampleRate:
      Number(process.env.REACT_APP_DD_RUM_REPLAY_SAMPLE_RATE) || 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
    enableExperimentalFeatures: ["feature_flags"],
  });
  datadogRum.startSessionReplayRecording();
  console.log("Initialized Datadog RUM");
};
