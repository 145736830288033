import React from "react";

import { useResendLease } from "manager/hooks/api";
import useModal from "shared/hooks/useModal";

import ResendLeaseModal from "./ResendLeaseModal";

const ResendLeaseModalContainer = () => {
  const { currentModalContext } = useModal();
  const {
    unitName,
    propertyName,
    applicants,
    leaseId,
    reminderSentAt,
    isLastReminderAuto,
  } = currentModalContext;

  const { resendLease, isLoading } = useResendLease(leaseId);

  return (
    <ResendLeaseModal
      unitName={unitName}
      propertyName={propertyName}
      applicants={applicants}
      lastReminderAt={reminderSentAt}
      resendLease={() => resendLease(leaseId)}
      isLoading={isLoading}
      isLastReminderAuto={isLastReminderAuto}
    />
  );
};

export default ResendLeaseModalContainer;
