import React from "react";

import { useGetRenewalsCompanySettings } from "manager/hooks/api";

import RenewalsSettingsForm, { SettingsLevel } from "./RenewalsSettingsForm";
import useRenewalsSettingsHandlers from "./useRenewalsSettingsHandlers";

const RenewalsCompanySettingsContainer = () => {
  /* TODO: [POD1-267] Replace /company-renewal-settings/ api
    with exposing renewal_settings_id on Company model */
  const { renewalsCompanySettings, isRenewalsSettingsLoading } =
    useGetRenewalsCompanySettings();

  const { emailConfigurations, initialValues, isLoading, saveChanges } =
    useRenewalsSettingsHandlers({
      renewalsSettings: renewalsCompanySettings,
      isRenewalsSettingsLoading,
    });

  return (
    <RenewalsSettingsForm
      initialValues={initialValues}
      onSubmit={saveChanges}
      saveChanges={saveChanges}
      isLoading={isLoading}
      emailConfigurations={emailConfigurations}
      level={SettingsLevel.company}
    />
  );
};

export default RenewalsCompanySettingsContainer;
