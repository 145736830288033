import React from "react";

import useModal from "shared/hooks/useModal";

import PreviewPDFModal from "./PreviewPDFModal";

const PreviewPDFModalContainer = () => {
  const { currentModalContext } = useModal();
  return <PreviewPDFModal {...currentModalContext} />;
};

export default PreviewPDFModalContainer;
