import axios from "axios";
import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";

import ROUTES from "manager/config/apiRoutes";
import { RenewalsSettings } from "manager/interfaces/api/renewalsSettings";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getRenewalsCompanySettings: () =>
    axios
      .get<RenewalsSettings>(`${API_URL}${ROUTES.renewalsCompanySettings}`)
      .then(({ data }) => data),

  getRenewalsSettings: ({ settingsId }: { settingsId: number }) =>
    axios
      .get<RenewalsSettings>(
        `${API_URL}${transformRoute(ROUTES.renewalsSettings, { settingsId })}`
      )
      .then(({ data }) => data),

  updateRenewalsSettings: ({
    settingsId,
    payload,
  }: {
    settingsId: number;
    payload: RenewalsSettings;
  }) =>
    axios
      .put<RenewalsSettings>(
        `${API_URL}${transformRoute(ROUTES.renewalsSettings, {
          settingsId,
        })}`,
        /** TODO:[POD1-268] Remove mapKeys and replace snakecase-keys -> lodash in setupApi.js */
        mapKeys(payload, (value, key) => snakeCase(key))
      )
      .then(({ data }) => data),

  copyRenewalSettingsFromPortfolio: (propertyId: number) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.copyRenewalSettingsFromPortfolio, {
          id: propertyId,
        })}`
      )
      .then(({ data }) => data),

  resetRenewalSettingsToPortfolio: (propertyId: number) =>
    axios
      .delete(
        `${API_URL}${transformRoute(ROUTES.resetRenewalSettingsToPortfolio, {
          id: propertyId,
        })}`
      )
      .then(({ data }) => data),
};

export default api;
