import React, { useEffect, useState } from "react";

import { useGetPropertiesWithLease, useGetLeases } from "manager/hooks/api";
import { useLeasesExport } from "manager/hooks/api/leaseQueries";
import { DEFAULT_PAGINATION } from "shared/config/constants";
import { useLeaseStatuses } from "shared/hooks/api";
import { SessionStorageCollectionManager } from "shared/utils/sessionStorage";

import Leases from "./Leases";
import {
  blankFilters,
  KEYS,
  LEASES_FILTERS_STORAGE,
  LEASE_CATEGORIES,
  ORDER_BY,
} from "./leases.constants";

const leasesFiltersStorage = new SessionStorageCollectionManager({
  storageKey: LEASES_FILTERS_STORAGE,
});

const combineFilters = ({ category, filters, pagination, ordering }) => {
  const { propertyId, leaseStatus, ...otherFilters } = filters;

  const propertyIdFilter = (propertyId || []).join(",");
  const leaseStatusFilter = (leaseStatus || []).join(",");

  return {
    ...otherFilters,
    propertyId: propertyIdFilter,
    leaseStatus: leaseStatusFilter,
    leaseCategory: LEASE_CATEGORIES.find((item) => item.key === category)
      ?.apiKey,
    ...pagination,
    ordering,
  };
};

const getInitialOrderingColumn = (ordering) => {
  const columnKey = Object.keys(ORDER_BY).find((key) => {
    return (
      ORDER_BY[key].ascend === ordering || ORDER_BY[key].descend === ordering
    );
  });

  return columnKey
    ? {
        [columnKey]:
          ORDER_BY[columnKey].ascend === ordering ? "ascend" : "descend",
      }
    : {};
};

const LeasesContainer = () => {
  const initialFilters =
    leasesFiltersStorage.getItem(KEYS.filters) || blankFilters;
  const initialCategory =
    leasesFiltersStorage.getItem(KEYS.category) || LEASE_CATEGORIES[0].key;
  const initialPagination =
    leasesFiltersStorage.getItem(KEYS.pagination) || DEFAULT_PAGINATION;
  const initialOrdering = leasesFiltersStorage.getItem(KEYS.ordering) || "";
  const initialOrderingColumn = getInitialOrderingColumn(initialOrdering);

  const [category, setCategory] = useState(initialCategory);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);
  const [ordering, setOrdering] = useState(initialOrdering);
  const [leasesFilters, setLeasesFilters] = useState(
    combineFilters({
      category,
      filters,
      pagination,
      ordering,
    })
  );
  const [isPropertiesSettled, setPropertiesSettled] = useState(false);

  const { data: leaseStatuses, isLoading: isLeaseStatusesLoading } =
    useLeaseStatuses();

  const { properties, isPropertiesLoading } = useGetPropertiesWithLease();

  const isFiltersLoading = isPropertiesLoading || isLeaseStatusesLoading;

  const { leases, isLeasesLoading } = useGetLeases(leasesFilters, {
    enabled: !isFiltersLoading && isPropertiesSettled,
  });

  const updateFilters = (values) => {
    if (values) {
      setFilters({
        ...filters,
        ...values,
      });
    } else {
      setFilters({ ...blankFilters });
    }

    setPagination({ ...DEFAULT_PAGINATION });
  };

  const updateCategory = (value: string) => {
    setCategory(value);
  };

  const updateAllFilters = (filterValues) => {
    setFilters({ ...filters, ...filterValues });
    setPagination({ ...DEFAULT_PAGINATION });
  };

  const onTableChange = (_tablePagination, _tableFilters, sorter) => {
    const newOrdering = sorter?.order
      ? `${ORDER_BY[sorter.columnKey][sorter.order]}`
      : undefined;
    setOrdering(newOrdering);
  };

  useEffect(() => {
    if (properties) {
      if (
        Number(filters.propertyId) &&
        !properties.some(
          (property) => property.id === Number(filters.propertyId)
        )
      ) {
        const cleanFilters = {
          ...filters,
          propertyId: [],
        };

        setFilters(cleanFilters);
      }

      setPropertiesSettled(true);
    }
  }, [properties]);

  /** Note
   * Whenever we update filters or category,
   * we should reset the pagination to avoid invalid page calls
   */
  useEffect(() => {
    setPagination({ ...DEFAULT_PAGINATION });
    leasesFiltersStorage.addItem(KEYS.filters, filters);
    leasesFiltersStorage.addItem(KEYS.category, category);
  }, [category, filters]);

  /** Note:
   * The actual deals reload is triggered
   * only when pagination and filters are fully settled
   */
  useEffect(() => {
    setLeasesFilters(
      combineFilters({
        category,
        filters,
        pagination,
        ordering,
      })
    );

    leasesFiltersStorage.addItem(KEYS.pagination, pagination);
    leasesFiltersStorage.addItem(KEYS.ordering, ordering);
  }, [pagination, ordering]);

  const { exportLeases, isExportingLeases } = useLeasesExport();

  return (
    <Leases
      dropdownProperties={properties}
      leases={leases?.results}
      leasesCount={leases?.count}
      updateFilters={updateFilters}
      updateAllFilters={updateAllFilters}
      filters={filters}
      pagination={pagination}
      hasInputValue={Boolean(filters.unitPropertyOrApplicant)}
      setPagination={setPagination}
      leaseStatuses={leaseStatuses}
      isFiltersLoading={isFiltersLoading}
      loading={isLeasesLoading}
      onTableChange={onTableChange}
      menuItems={LEASE_CATEGORIES}
      activeItem={category}
      setCategory={updateCategory}
      initialOrderingColumn={initialOrderingColumn}
      exportLeases={exportLeases}
      isExportingLeases={isExportingLeases}
    />
  );
};

export default LeasesContainer;
