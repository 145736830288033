/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from "react";

import camelCase from "lodash/camelCase";
import forIn from "lodash/forIn";
import groupBy from "lodash/groupBy";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import mapValues from "lodash/mapValues";
import pick from "lodash/pick";
import startCase from "lodash/startCase";
import startsWith from "lodash/startsWith";
import * as Yup from "yup";

import { FIELD_CATEGORIES } from "admin/config/constants";
import { LEASE_METADATA } from "manager/config/constants";
import { formatPhone, safeParsePhoneNumber } from "shared/utils/ui";

import {
  LEASING_MODEL,
  TOTAL_FIRST_MONTH_RENT_FIELD,
  TOTAL_RENT_FIELD,
} from "../constants";

import { CompletedIcon } from "./styled";

const getDocumentName = (unformattedName) =>
  startCase(unformattedName.slice(0, -3));

const isDocumentNamesEqual = (name1, name2) =>
  camelCase(name1) === camelCase(name2);

export const isCompletedDocument = (document) => {
  const isCompleted =
    isEmpty(document.fields) ||
    (!isEmpty(document.fields) &&
      document.fields.filter((field) => field.value || isBoolean(field.value))
        .length === document.fields.length);
  return isCompleted;
};

export const getDocumentLabel = (document) => {
  const isCompleted = isCompletedDocument(document);
  let label = document.menuLabel;

  if (isCompleted) {
    label = (
      <>
        {label} <CompletedIcon />
      </>
    );
  }
  return label;
};

export const getFormattedDocuments = (
  documents,
  leaseDocumentTemplates,
  isBluemoonLease
) => {
  const documentNames = Object.values(documents).map((doc) => doc.name);
  return Object.values(documents)
    .map((document, index) => {
      const updatedDocument = { ...document };
      const menuLabel = getDocumentName(documentNames[index]);
      const accordingLeaseDocumentInfo = isBluemoonLease
        ? updatedDocument
        : leaseDocumentTemplates.find((leaseDocument) =>
            isDocumentNamesEqual(leaseDocument.name, documentNames[index])
          );

      updatedDocument.key = accordingLeaseDocumentInfo?.id;
      updatedDocument.menuLabel = menuLabel;
      updatedDocument.sortingIdx = accordingLeaseDocumentInfo?.sortingIdx;
      updatedDocument.label = getDocumentLabel(updatedDocument);
      updatedDocument.language = leaseDocumentTemplates?.find((leaseDocument) =>
        isDocumentNamesEqual(leaseDocument.name, documentNames[index])
      )?.language;
      updatedDocument.anchor = `${camelCase(menuLabel)}-${
        accordingLeaseDocumentInfo?.id
      }`;
      return updatedDocument;
    })
    .sort((documentA, documentB) =>
      isBluemoonLease
        ? documentB.sortingIdx - documentA.sortingIdx
        : documentA.sortingIdx - documentB.sortingIdx
    );
};

const formatInitialDocumentFields = (initialValues) => {
  Object.keys(initialValues)
    .filter((key) => key.includes("phone"))
    .filter((key) => !!initialValues[key])
    .forEach((key) => {
      let phoneNumber = initialValues[key];
      if (!startsWith(phoneNumber, "(")) {
        phoneNumber = formatPhone(phoneNumber);
      }

      const parsedPhoneNumber = safeParsePhoneNumber(phoneNumber, "US");

      if (parsedPhoneNumber?.number) {
        // eslint-disable-next-line no-param-reassign
        initialValues[key] = parsedPhoneNumber.number.substring(1);
      }
    });

  return initialValues;
};

export const getInitialValues = (
  documents,
  feeConfiguration,
  isBluemoonLease
) => {
  const initialDocumentFields = {};

  documents?.forEach((document) => {
    document?.fields?.forEach((field) => {
      initialDocumentFields[field.name] = field.value;
    });
  });
  if (isBluemoonLease) {
    feeConfiguration?.charges?.forEach((item) => {
      if (Object.keys(initialDocumentFields)?.includes(item.name)) {
        initialDocumentFields[item.name] = item.customAmount * item.quantity;
      }
    });
    feeConfiguration?.rentableItems?.forEach((item) => {
      if (Object.keys(initialDocumentFields)?.includes(item.name)) {
        initialDocumentFields[item.name] = item.customAmount * item.quantity;
      }
    });
  }

  return {
    fields: formatInitialDocumentFields(initialDocumentFields),
    feeConfiguration: {
      charges:
        feeConfiguration?.charges?.map((item) => {
          return {
            ...item,
            customAmount: item.customAmount || item.baseAmount,
          };
        }) || [],

      rentableItems:
        feeConfiguration?.rentableItems?.map((item) => {
          return {
            ...item,
            customAmount: item.customAmount || item.baseAmount,
          };
        }) || [],
      prorateRentableItems: feeConfiguration?.prorateRentableItems,
    },
  };
};

export const getUpdatedLeaseFields = (
  updatedFields,
  documents,
  isBluemoonLease
) => {
  forIn(updatedFields, (value, key) => {
    if (!isBluemoonLease && key.includes("phone")) {
      // eslint-disable-next-line no-param-reassign
      updatedFields[key] = formatPhone(value);
    }
  });

  return documents.map((doc) => {
    const aqMetadata =
      doc?.fields
        ?.filter((field) => field.category === FIELD_CATEGORIES.applicant)
        .map((field) => ({
          name: field.name,
          value: updatedFields[field.name],
          model: LEASE_METADATA.aq,
        })) || [];

    const lqMetadata =
      doc?.fields
        ?.filter((field) => field.category === FIELD_CATEGORIES.lease)
        .map((field) => ({
          name: field.name,
          value: updatedFields[field.name],
          model: LEASE_METADATA.lq,
        })) || [];

    const pqMetadata =
      doc?.fields
        ?.filter((field) => field.category === FIELD_CATEGORIES.property)
        .map((field) => ({
          name: field.name,
          value: updatedFields[field.name],
          model: LEASE_METADATA.pq,
        })) || [];

    return {
      documentTemplate: {
        id: doc.key,
      },
      metadata: [...aqMetadata, ...lqMetadata, ...pqMetadata],
    };
  });
};

export const getUpdatedDocument = (updatedFields, documents) =>
  documents.map((doc) => ({
    ...doc,
    aq:
      doc?.fields
        ?.filter((field) => field.category === FIELD_CATEGORIES.applicant)
        .map((field) => ({
          ...field,
          value: updatedFields[field.name],
        })) || [],
    lq:
      doc?.fields
        ?.filter((field) => field.category === FIELD_CATEGORIES.lease)
        .map((field) => ({
          ...field,
          value: updatedFields[field.name],
        })) || [],
    pq:
      doc?.fields
        ?.filter((field) => field.category === FIELD_CATEGORIES.property)
        .map((field) => ({
          ...field,
          value: updatedFields[field.name],
        })) || [],
  }));

export const isElementVisible = (element) => {
  const scroll = window.scrollY || window.pageYOffset;
  const boundsTop = element.getBoundingClientRect().top + scroll;
  const offset = 200;
  const viewport = {
    top: scroll + offset,
    bottom: scroll + window.innerHeight - offset * 2,
  };
  const bounds = {
    top: boundsTop,
    bottom: boundsTop + element.clientHeight,
  };
  const isVisible =
    (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
    (bounds.top <= viewport.bottom && bounds.top >= viewport.top);

  return isVisible;
};

const baseFeeValidation = Yup.object().shape({
  isSelected: Yup.bool(),
  feeInventoryId: Yup.number(),
  feeLabel: Yup.string(),
  feeType: Yup.string(),
  name: Yup.string().when("isSelected", {
    is: true,
    then: Yup.string().required("Field value must be selected"),
  }),
  baseAmount: Yup.number().nullable(),
  customAmount: Yup.number().nullable().required("This field is required"),
  quantity: Yup.number(),
  appliedToTotalRent: Yup.bool(),
});

export const leaseFeeConfigurationValidation = Yup.object().shape({
  charges: Yup.array().of(baseFeeValidation),
  rentableItems: Yup.array().of(baseFeeValidation),
  prorateRentableItems: Yup.bool(),
});

export const leaseFieldsValidationSchema = Yup.object().shape({
  feeConfiguration: leaseFeeConfigurationValidation,
});

export const LEASE_FEE_FIELDS_SECTION = Object.freeze({
  key: "charges-section",
  anchor: "charges-section",
  label: "Charges & Rentable Items",
});

export const hasUnusedFeeFields = (values, feeFields) => {
  const feeNamesSet = new Set(
    [
      ...(values.feeConfiguration?.charges || []),
      ...(values.feeConfiguration?.rentableItems || []),
    ].map(({ name }) => name)
  );

  const hasSomeUnusedFeeFields = [
    ...(feeFields?.charges || []),
    ...(feeFields?.rentableItems || []),
  ].some((item) => !feeNamesSet.has(item));

  return hasSomeUnusedFeeFields;
};

export const hasEmptyDocumentFields = (documents) =>
  Object.values(documents).some((value) => isNil(value) || value === "");

/** Note: Identify which smart fields are reflecting
 *  Total Rent, Total First Month Rent calculated fields */
export const findCalculatedRentSmartFields = (documents = {}) => {
  const leaseFields =
    Object.values(documents)
      ?.map((doc) => doc.fields)
      ?.flat()
      ?.filter((field) => field.smartSource?.app === LEASING_MODEL) || [];

  let totalRentFields = groupBy(
    leaseFields,
    (item) => item.smartSource?.attribute
  );

  totalRentFields = pick(totalRentFields, [
    TOTAL_RENT_FIELD,
    TOTAL_FIRST_MONTH_RENT_FIELD,
  ]);

  totalRentFields = mapValues(totalRentFields, (sources) =>
    sources.reduce((prev, current) => {
      return {
        ...prev,
        [current.name]: "",
      };
    }, {})
  );

  return {
    [TOTAL_RENT_FIELD]: {},
    [TOTAL_FIRST_MONTH_RENT_FIELD]: {},
    ...totalRentFields,
  };
};

export const getShowChargesRentableItems = (feeConfiguration, feeFields) => {
  const hasAvailableFeeItems =
    !isEmpty(feeConfiguration?.charges) ||
    !isEmpty(feeConfiguration?.rentableItems);

  const hasAvailableFeeFields =
    !isEmpty(feeFields?.charges) || !isEmpty(feeFields?.rentableItems);

  const showChargesRentableItems =
    hasAvailableFeeItems && hasAvailableFeeFields;

  return showChargesRentableItems;
};
