import React from "react";

import { useGetRenewalsSettings } from "manager/hooks/api";

import RenewalsSettingsForm, { SettingsLevel } from "./RenewalsSettingsForm";
import useRenewalsSettingsHandlers from "./useRenewalsSettingsHandlers";

const RenewalsPortfolioSettings = ({
  renewalsSettingsId,
}: {
  renewalsSettingsId: number;
}) => {
  const { renewalsSettings, isRenewalsSettingsLoading } =
    useGetRenewalsSettings({ settingsId: renewalsSettingsId });

  const { emailConfigurations, initialValues, isLoading, saveChanges } =
    useRenewalsSettingsHandlers({
      renewalsSettings,
      isRenewalsSettingsLoading,
    });

  return (
    <RenewalsSettingsForm
      initialValues={initialValues}
      onSubmit={saveChanges}
      saveChanges={saveChanges}
      isLoading={isLoading}
      emailConfigurations={emailConfigurations}
      level={SettingsLevel.portfolio}
    />
  );
};

export default RenewalsPortfolioSettings;
