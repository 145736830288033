import React, { ReactElement } from "react";

import { Col, Row } from "antd";

import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import {
  RegularText,
  ExtraStrongText,
  WeakText,
} from "shared/components/Typography";
import { SUPPORT_EMAIL } from "shared/config/constants";

import { DocumentsVerificationModalProps } from "./interfaces";
import { Warning, WarningIconContainer, IssuesList } from "./styled";

const DocumentsVerificationModal = ({
  documentErrors,
}: DocumentsVerificationModalProps): ReactElement => {
  return (
    // @ts-ignore
    <Modal
      title="Number exceeds available signature lines"
      submitButtonLabel="SELECT NEW DOCUMENT(S)"
      cancelLinkLabel="Contact Support"
      onCancelLinkClick={() => window.open(`mailto:${SUPPORT_EMAIL}`)}
    >
      <Modal.Body>
        <Warning data-testid="warning">
          <WarningIconContainer data-testid="info-icon">
            <Icon.OrangeExclamationHollow width="40px" height="40px" />
          </WarningIconContainer>
          <RegularText>
            The below documents encountered errors. Please select a different
            document or contact support to create a new template.
          </RegularText>
        </Warning>
        <IssuesList>
          {documentErrors.map((issue, index) => {
            return (
              <li key={`${issue.name}-${index + 1}`}>
                <Row type="flex" align="middle">
                  <Col>
                    <WeakText>Document name: </WeakText>
                    <ExtraStrongText>
                      {issue.name.substring(0, issue.name.length - 4)}
                    </ExtraStrongText>
                  </Col>
                </Row>
                <Row type="flex" align="middle">
                  <Col>
                    <WeakText>
                      {issue.message.includes("guarantors")
                        ? "Total guarantors "
                        : "Total applicants "}
                    </WeakText>
                    <ExtraStrongText>
                      {`${issue.expected}.`}&nbsp;
                    </ExtraStrongText>
                  </Col>
                  <Col>
                    <WeakText>Signature lines: </WeakText>
                    <ExtraStrongText>{`${issue.found}.`}&nbsp;</ExtraStrongText>
                  </Col>
                </Row>
              </li>
            );
          })}
        </IssuesList>
      </Modal.Body>
    </Modal>
  );
};

export default DocumentsVerificationModal;
