import React from "react";

import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import Icon from "shared/components/Icon";
import { BackLink } from "shared/components/Links";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import {
  SaveLeaseProcessContainer,
  SaveLeaseProcessIcon,
  SaveLeaseProcessText,
} from "../../styled";
import { ActionsContainer } from "../styled";

import { FooterBar as StyledFooterBar } from "./styled";

const LeaseDocumentsFooter = ({
  onFinishLater,
  onSubmitStep,
  stepBack,
  isLoading,
  nextStepLoading,
  isSubmitDisabled,
}) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });

  const ActionsContainerComponent = isMobile
    ? React.Fragment
    : ActionsContainer;

  return (
    <Container noPaddingBottom noPaddingTop stickToBottom>
      <StyledFooterBar data-testid="lease-documents-footer">
        <BackLink
          onClick={stepBack}
          data-testid="previous-step"
          size="lg"
          disabled={isLoading}
        >
          Previous Step
        </BackLink>

        <ActionsContainerComponent>
          <SaveLeaseProcessContainer
            disabled={isLoading}
            onClick={onFinishLater}
            data-testid="finish-later"
          >
            <SaveLeaseProcessIcon />
            <SaveLeaseProcessText>Save & Finish Later</SaveLeaseProcessText>
          </SaveLeaseProcessContainer>

          <Button
            type="primary"
            disabled={isSubmitDisabled}
            loading={nextStepLoading}
            SuffixIcon={Icon.ButtonForwardArrowIcon}
            onClick={onSubmitStep}
            data-testid="next-step"
          >
            Next Step
          </Button>
        </ActionsContainerComponent>
      </StyledFooterBar>
    </Container>
  );
};

LeaseDocumentsFooter.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  nextStepLoading: PropTypes.bool.isRequired,
  onFinishLater: PropTypes.func.isRequired,
  onSubmitStep: PropTypes.func.isRequired,
  stepBack: PropTypes.func.isRequired,
};

export default LeaseDocumentsFooter;
