import React from "react";

import { ACTIVE_DOCUMENTS_STEPS } from "manager/config/constants";
import Icon from "shared/components/Icon";
import TeaserModal from "shared/components/Modals/TeaserModal";
import { RegularText } from "shared/components/Typography";
import VerticalStaticNumberedSteps from "shared/components/VerticalStaticNumberedSteps";

import { Description, DescriptionTitle } from "./styled";

const ActiveDocumentsErrorModal = () => (
  <TeaserModal
    subtitleMessage={
      <>
        Whoops! Looks like this property doesn&#39;t have an active lease
        document.
      </>
    }
    SubtitleIcon={Icon.ActiveLinkIcon}
    description={
      <Description>
        <DescriptionTitle>Double check the following:</DescriptionTitle>
        <VerticalStaticNumberedSteps
          items={ACTIVE_DOCUMENTS_STEPS.map((item) => ({
            label: <RegularText weak>{item.label}</RegularText>,
          }))}
        />
      </Description>
    }
  />
);

export default ActiveDocumentsErrorModal;
