import React, { useEffect, useState } from "react";

import { useStatusPage } from "manager/hooks/useStatusPage";
import { usePermissions } from "shared/hooks";

import ManagerApp from "./ManagerApp";

const ManagerAppContainer = () => {
  const {
    hasPermission,
    company,
    permissionsLoaded,
    isOpenApiEnabled,
    isMagicLinksEnabled,
    isEmailCustomizationEnabled,
    isPrescreeningEnabled,
    areRenewalsEnabled,
    userExperianAuthenticated,
  } = usePermissions();

  useStatusPage();

  return (
    <ManagerApp
      company={company}
      userExperianAuthenticated={userExperianAuthenticated}
      hasPermission={hasPermission}
      permissionsLoaded={permissionsLoaded}
      isMagicLinksEnabled={
        isMagicLinksEnabled &&
        !company.preventInvitesToTheApplicationWithoutUnit
      }
      isOpenApiEnabled={isOpenApiEnabled}
      isEmailCustomizationEnabled={isEmailCustomizationEnabled}
      isPrescreeningEnabled={isPrescreeningEnabled}
      areRenewalsEnabled={areRenewalsEnabled}
    />
  );
};

export default ManagerAppContainer;
