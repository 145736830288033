import styled from "@emotion/styled";

export const LoadingContainer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  height: "calc(100vh - 72px)",
  width: "100vw",
  zIndex: 300,
  svg: {
    position: "absolute",
    top: "calc(50% + 36px)",
    left: "50%",
  },
});
