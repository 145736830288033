import styled from "@emotion/styled";

import BaseFooterBar from "shared/components/FooterBar";
import { MEDIA_QUERIES } from "shared/config/constants";

const FooterBar = styled(BaseFooterBar)({
  justifyContent: "space-between",
  alignItems: "center",
  [MEDIA_QUERIES.w.ltMd]: {
    padding: "0 90px 0 15px",
  },
});

export { FooterBar };
