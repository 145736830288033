import React from "react";

import { Form, Formik } from "formik";
import capitalize from "lodash/capitalize";
import PropTypes from "prop-types";

import CourtesyMessageModal from "manager/components/Lumen/CourtesyMessageModal";
import ErrorList from "shared/components/ErrorList";
import { Modal } from "shared/components/Modal";
import { RegularText } from "shared/components/Typography";
import { useDeviceType } from "shared/hooks";

import { LeaseQuestionValidationSchema, LEASE_QUESTION_FIELDS } from "./config";
import ManagerQuestionInnerFormElements from "./ManagerQuestionInnerFormElements";

const ManagerQuestionModal = ({ submit, initialValues, documents, mode }) => {
  const { isMobile } = useDeviceType();

  return isMobile ? (
    <CourtesyMessageModal message="We recommend using this feature on a desktop for the best user experience." />
  ) : (
    <Formik
      validationSchema={LeaseQuestionValidationSchema}
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
    >
      {({ submitForm, isSubmitting, errors }) => (
        <Form>
          <Modal
            title={`${capitalize(mode)} Lease Question`}
            subtitle={
              <RegularText data-testid="subtitle">
                For Lease Package
              </RegularText>
            }
            submit={submitForm}
            submitting={isSubmitting}
            closeOnSubmit={false}
            submitButtonLabel="Save question"
            width={650}
          >
            {errors.nonFieldErrors && (
              <Modal.Body noPaddingBottom>
                <ErrorList errors={errors.nonFieldErrors} />
              </Modal.Body>
            )}
            <Modal.Body>
              <ManagerQuestionInnerFormElements documents={documents} />
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

const MANAGER_QUESTION_MODAL_MODE = Object.freeze({
  CREATE: "create",
  EDIT: "edit",
});

ManagerQuestionModal.propTypes = {
  submit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    [LEASE_QUESTION_FIELDS.questionName]: PropTypes.string,
    [LEASE_QUESTION_FIELDS.options]: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        [LEASE_QUESTION_FIELDS.answer]: PropTypes.string,
      })
    ),
  }),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  mode: PropTypes.oneOf(Object.values(MANAGER_QUESTION_MODAL_MODE)).isRequired,
};

ManagerQuestionModal.defaultProps = {
  initialValues: {
    [LEASE_QUESTION_FIELDS.questionName]: undefined,
    [LEASE_QUESTION_FIELDS.options]: [],
  },
  documents: [],
};

export default ManagerQuestionModal;

export { MANAGER_QUESTION_MODAL_MODE };
