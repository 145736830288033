import styled from "@emotion/styled";

import { IconLink } from "shared/components/Links";

const Tools = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const DownloadButton = styled(IconLink)`
  margin-right: 30px;
`;

export { Tools, DownloadButton };
