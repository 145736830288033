import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const PackageTitle = styled("h2")({
  [MEDIA_QUERIES.w.ltLg]: {
    paddingLeft: 20,
  },
});

const StyledPackage = styled("div")({
  padding: "25px 28px",
  fontSize: "16px",
  backgroundColor: COLORS.list.background,
  marginBottom: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  [MEDIA_QUERIES.w.ltLg]: {
    padding: "25px 44px",
  },
  [MEDIA_QUERIES.w.ltSm]: {
    padding: 20,
  },
});

const DocumentName = styled("span")({
  flex: 1,
});

const IconsContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  svg: {
    "&:last-of-type": {
      marginLeft: 10,
    },
    cursor: "pointer",
  },
});

export { PackageTitle, StyledPackage, DocumentName, IconsContainer };
