import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";

import {
  CONCESSION_DATA_VALIDATION,
  CONCESSION_TYPE_VALIDATION,
  ConcessionFormData,
  ConcessionMonthsDropdown,
  ConcessionMonthsPicker,
} from "manager/components/Leasing/Concession";
import { CONCESSION_TYPES_OPTIONS } from "manager/config/constants";
import { Modal } from "shared/components/Modal";
import RadioGroupBoxedList from "shared/components/RadioGroupBoxedList";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";
import { CONCESSION_TYPES } from "shared/config/constants";

const VALIDATION_SCHEMA = Yup.object().shape({
  concessionType: CONCESSION_TYPE_VALIDATION,
  ...CONCESSION_DATA_VALIDATION,
});

const CONCESSION_TYPES_RADIO_BOXED_LIST_OPTIONS = CONCESSION_TYPES_OPTIONS.map(
  (o) => ({
    id: o.value,
    title: o.label,
  })
);

interface RenewalOfferOptionConcessionModalProps {
  leaseStartDate: string;
  leaseEndDate: string;
  concessionMonths: {
    concessionStart: string;
    concessionEnd: string;
  };
  isConcessionMonthsLoading: boolean;
  initialValues: ConcessionFormData;
  onSubmit: (values: ConcessionFormData) => void;
  changeConcessionType: (
    concessionType: CONCESSION_TYPES,
    setValues: (values: ConcessionFormData) => void
  ) => void;
}

export const RenewalOfferOptionConcessionModal = ({
  leaseStartDate,
  leaseEndDate,
  concessionMonths,
  isConcessionMonthsLoading,
  initialValues,
  onSubmit,
  changeConcessionType,
}: RenewalOfferOptionConcessionModalProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={VALIDATION_SCHEMA}
      validateOnMount
    >
      {({ values, setValues, submitForm, isSubmitting }) => (
        <Modal
          title="Add concession details"
          submitButtonLabel="CONFIRM"
          submit={submitForm}
          submitting={isSubmitting}
          width="auto"
          style={{ maxWidth: 830 }}
          closeOnSubmit={false}
        >
          <Modal.Body>
            <RegularText weak>
              Enter the details and press confirm to add them to the selected
              offer.
            </RegularText>
            <Spacer size={Spacer.SIZES.md} />
            <RadioGroupBoxedList
              listItems={CONCESSION_TYPES_RADIO_BOXED_LIST_OPTIONS}
              currentSelection={values?.concessionType}
              onChangeSelection={(c) => changeConcessionType(c, setValues)}
            />
            <Spacer />
            {values?.concessionType === CONCESSION_TYPES.amortize && (
              <ConcessionMonthsDropdown
                leaseStartDate={leaseStartDate}
                leaseEndDate={leaseEndDate}
                concessionMonths={concessionMonths}
                isConcessionMonthsLoading={isConcessionMonthsLoading}
              />
            )}
            {values?.concessionType === CONCESSION_TYPES.specificMonths && (
              <ConcessionMonthsPicker
                leaseStartDate={leaseStartDate}
                leaseEndDate={leaseEndDate}
                concessionMonths={concessionMonths}
                isConcessionMonthsLoading={isConcessionMonthsLoading}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};
