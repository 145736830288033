import React, { useEffect } from "react";

import PropTypes from "prop-types";

import CourtesyMessageModal from "manager/components/Lumen/CourtesyMessageModal";
import EditApplicantQuestionModal from "manager/components/Modal/LeasePackage/EditApplicantQuestionModal";
import ManagerQuestionModal from "manager/components/Modal/LeasePackage/ManagerQuestionModal";
import {
  useDeleteLeaseQuestion,
  useUpsertManagerQuestion,
  useGetLeasePackagesApplicantQuestions,
  useGetLeasePackagesManagerQuestions,
} from "manager/hooks/api";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { useActiveCompany, useDeviceType } from "shared/hooks";
import useModal from "shared/hooks/useModal";

import LeasePackages from "./LeasePackages";

const LeasePackagesContainer = ({ propertyId, isPortfolioSettings }) => {
  const { isMobile } = useDeviceType();
  const { managerQuestions, isManagerQuestionsLoading } =
    useGetLeasePackagesManagerQuestions(propertyId);
  const { applicantQuestions, isApplicantQuestionsLoading } =
    useGetLeasePackagesApplicantQuestions(propertyId);
  const { upsertManagerQuestion, isUpsertManagerQuestionLoading } =
    useUpsertManagerQuestion();
  const { deleteLeaseQuestion } = useDeleteLeaseQuestion();
  const { openModalDialog } = useModal();
  const { activeCompany } = useActiveCompany();

  useEffect(() => {
    if (isMobile) {
      openModalDialog(CourtesyMessageModal, {
        message:
          "We recommend using this feature on a desktop for the best user experience.",
      });
    }
  }, []);

  const openEditApplicantQuestionModal = ({ question, documentTemplates }) =>
    openModalDialog(EditApplicantQuestionModal, {
      propertyId,
      question,
      applicantQuestions,
      documentTemplates,
    });

  const openManagerQuestionModal = (context) =>
    openModalDialog(ManagerQuestionModal, {
      propertyId,
      managerQuestions,
      ...context,
    });

  const openDeleteManagerQuestionConfirmation = (mangerQuestionId) =>
    openModalDialog(ConfirmationModal, {
      title: "Delete Lease Question",
      submitButtonLabel: "Okay, got it",
      message: "You are attempting to permanently delete this lease question.",
      description: `This question will no longer appear for applicants and may affect which lease documents appear for ${activeCompany?.name}.`,
      onConfirm: () => deleteLeaseQuestion(mangerQuestionId),
    });

  const editManagerQuestion = (managerQuestion) => {
    const leaseQuestions = managerQuestions?.filter(
      ({ id }) => id !== managerQuestion.id
    );

    leaseQuestions.push(managerQuestion);

    upsertManagerQuestion({
      id: propertyId,
      leaseQuestions: leaseQuestions.map((q) => ({
        ...q,
        id: undefined,
      })),
    });
  };

  return (
    <LeasePackages
      isLoading={
        !propertyId || isManagerQuestionsLoading || isApplicantQuestionsLoading
      }
      managerQuestions={managerQuestions}
      applicantQuestions={applicantQuestions}
      editManagerQuestion={editManagerQuestion}
      isEditLoading={isUpsertManagerQuestionLoading}
      openEditApplicantQuestionModal={openEditApplicantQuestionModal}
      openManagerQuestionModal={openManagerQuestionModal}
      openDeleteManagerQuestionConfirmation={
        openDeleteManagerQuestionConfirmation
      }
      isPortfolioSettings={isPortfolioSettings}
    />
  );
};

LeasePackagesContainer.propTypes = {
  propertyId: PropTypes.string,
  isPortfolioSettings: PropTypes.bool,
};

LeasePackagesContainer.defaultProps = {
  propertyId: undefined,
  isPortfolioSettings: undefined,
};

export default LeasePackagesContainer;
