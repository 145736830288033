import React from "react";

import { Col, Row } from "antd";
import pluralize from "pluralize";
import { Helmet } from "react-helmet";

import SummaryCard from "renter/components/SummaryCard";
import Container from "shared/components/Container";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Spacer from "shared/components/Spacer";
import StatusCircleLabel from "shared/components/StatusCircleLabel";
import Status from "shared/components/StatusWithIcon";
import Tile from "shared/components/Tile";
import { RenewalOptionResponseStatus } from "shared/config/constants";
import { useDeviceType } from "shared/hooks";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import LeaseDetails from "./LeaseDetails";
import LeaseRenewalPanel from "./LeaseRenewalPanel";
import { LeasesProps } from "./interfaces";
import {
  CityBackground,
  LeaseAdditionalDescription,
  LoadingContainer,
  RenewalLeaseFooterContainer,
  RentPrice,
} from "./styled";
import {
  getLeaseActionState,
  getLeaseAdditionalDescriptionStatus,
} from "./utils";

const Leases = ({
  name,
  user,
  formattedLeases = [],
  openLeaseApplication,
  selectedLeaseForRenewal,
  selectLeaseIdForRenewal,
  acceptOffer,
  rejectOffer,
  loading = false,
}: LeasesProps) => {
  const { isDesktop } = useDeviceType();
  const offersPanel = selectedLeaseForRenewal && (
    <LeaseRenewalPanel
      selectedLeaseForRenewal={selectedLeaseForRenewal}
      acceptOffer={acceptOffer}
      rejectOffer={rejectOffer}
    />
  );
  return (
    <>
      <CityBackground data-testid="city-bg" />
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <Container noPaddingBottom>
        <PageTitle.Container>
          <PageTitle>{name}</PageTitle>
        </PageTitle.Container>
      </Container>
      <Container noPaddingTop noMobilePaddingX>
        {loading ? (
          <LoadingContainer>
            <Icon.LoadingSpinnerGreenLargeIcon data-testid="loading" />
          </LoadingContainer>
        ) : (
          <Row gutter={14} type="flex">
            <Col xs={{ span: 24 }} xl={{ span: 12 }}>
              {formattedLeases.map((lease) => {
                const leaseActionState = getLeaseActionState(lease);
                const otherApplicants = lease.applicants.filter(
                  (applicant) => applicant.email !== user.email
                );
                const numOfferOptions =
                  lease?.renewalOffer?.renewalOptions?.length;
                const applicantAnswer =
                  lease?.renewalOffer?.applicantSelectedOption;

                const showOffers =
                  numOfferOptions > 0 &&
                  !lease?.isPastLease &&
                  applicantAnswer?.response !==
                    RenewalOptionResponseStatus.NOT_ACCEPTED;

                return (
                  <React.Fragment key={lease.id}>
                    <div>
                      <SummaryCard
                        applicationId={lease.applicationId}
                        propertyName={lease.propertyName}
                        unitName={lease.unitName}
                        propertyAddress={lease.propertyAddress}
                        otherApplicants={otherApplicants}
                        Details={() => <LeaseDetails lease={lease} />}
                        AdditionalDescription={() => (
                          <LeaseAdditionalDescription>
                            <StatusCircleLabel
                              {...getLeaseAdditionalDescriptionStatus(lease)}
                            />
                            <Spacer />
                            <RentPrice>
                              <Tile.Label noMb>Rent Price</Tile.Label>
                              <div data-testid="lease-rent">
                                {printDollarsFromCents(lease.rent) || "N/A"}
                              </div>
                            </RentPrice>
                          </LeaseAdditionalDescription>
                        )}
                        action={{
                          visible: true,
                          disabled: leaseActionState.disabled,
                          label: leaseActionState.title,
                          onClick: () =>
                            openLeaseApplication(
                              lease.leaseApplicationId,
                              lease.id
                            ),
                        }}
                        ActionAdditionalView={
                          leaseActionState.additionalAction &&
                          (() => (
                            <Status
                              onClick={() =>
                                openLeaseApplication(
                                  lease.leaseApplicationId,
                                  lease.id
                                )
                              }
                            >
                              {leaseActionState.title}
                            </Status>
                          ))
                        }
                        extraFooterContent={
                          showOffers && (
                            <RenewalLeaseFooterContainer>
                              <UnderlineLink
                                light
                                onClick={() =>
                                  selectLeaseIdForRenewal(lease?.id)
                                }
                              >
                                {numOfferOptions} RENEWAL{" "}
                                {pluralize("OFFER", numOfferOptions)}
                                {!applicantAnswer && " AVAILABLE"}
                              </UnderlineLink>
                            </RenewalLeaseFooterContainer>
                          )
                        }
                      />
                    </div>
                    {!isDesktop &&
                      selectedLeaseForRenewal === lease &&
                      offersPanel}
                  </React.Fragment>
                );
              })}
            </Col>
            {isDesktop && <Col span={12}>{offersPanel}</Col>}
          </Row>
        )}
      </Container>
    </>
  );
};

export default Leases;
