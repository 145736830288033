import React from "react";

import AnswerList from "../AnswerList";
import { Question as QuestionType } from "../interfaces";
import { QuestionListContainer, Question } from "../styled";

export interface QuestionListProps {
  questions?: QuestionType[];
}

const QuestionList = ({ questions = [] }: QuestionListProps) => (
  <QuestionListContainer data-testid="questions-container">
    {Object.keys(questions).map((questionName) => (
      <div key={`${questionName}`}>
        <Question data-testid="question">{questionName}</Question>
        <AnswerList answers={questions[questionName]} />
      </div>
    ))}
  </QuestionListContainer>
);

export default QuestionList;
