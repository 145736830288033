import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { COLORS } from "shared/config/constants";

const Title = styled.h2`
  margin: 0;
  line-height: normal;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
`;

const DisplayLeaseButton = styled(ActionLink)`
  margin: 0;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const GreenText = styled.span`
  color: ${COLORS.accentDark};
  font-size: 12px;
  white-space: nowrap;
`;

const SpinnerIcon = styled(Icon.LoadingSpinnerGreenIcon)`
  height: 15px;
`;

const DataRow = styled.div`
  padding-right: 10px;
  min-width: 80px;
  width: 200px;
`;

const SectionWrapper = styled.div`
  margin-top: 30px;
  display: flex;
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export {
  Title,
  SectionWrapper,
  DataRow,
  DisplayLeaseButton,
  StatusContainer,
  GreenText,
  SpinnerIcon,
  Action,
};
