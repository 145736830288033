import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  createLease: (lease) => axios.post(`${API_URL}${ROUTES.leases}`, lease),

  getPropertyDocumentTemplates: (id, params) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.propertyDocumentTemplates, { id })}`,
      {
        params: {
          only_active: params?.onlyActive,
        },
      }
    ),

  getLease: (id) =>
    axios.get(`${API_URL}${transformRoute(ROUTES.lease, { leaseId: id })}`),

  verifyLease: (leaseId, documents) =>
    axios.post(`${API_URL}${transformRoute(ROUTES.verifyLease, { leaseId })}`, {
      documents,
    }),

  updateLeaseStep: ({ id, data }) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.leaseStep, { leaseId: id })}`,
      data
    ),

  /**
   * @param {Object} params
   * @param {Number} params.id
   * @param {Object} params.data
   * @param {Number} params.data.rent cents
   * @param {Number} params.data.step
   * @param {String} params.data.startDate YYYY-MM-DD
   * @param {String} params.data.endDate YYYY-MM-DD
   * @param {Number} params.data.securityDeposit cents
   * @param {Boolean} params.data.hasConcession
   * @param {String} params.data.concessionType ["AM", "SM"]
   * @param {Number} params.data.concessionNumberOfMonths
   * @param {Boolean} params.data.endDateToEndOfTheMonth
   * @param {Array.<{ month: String, year: Number, duration: Number }>} params.data.concessionMonths
   */
  updateLeaseTerms: ({ id, data }) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.leaseTerms, { leaseId: id })}`,
      data
    ),

  assignLeaseOptionalDocuments: (leaseId, documents, nextStep) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.assignLeaseOptionalDocuments, {
        leaseId,
      })}`,
      {
        ...documents,
        step: nextStep,
      }
    ),

  assignLeaseOptionalDocumentsBluemoon: (leaseId, documents, nextStep) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.assignLeaseOptionalDocumentsBluemoon, {
        leaseId,
      })}`,
      {
        ...documents,
        step: nextStep,
      }
    ),

  getLeaseAssignedDocuments: (leaseId) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.leaseAssignedDocuments, { leaseId })}`
    ),

  getLeaseDocumentTemplates: (leaseId) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.leaseDocumentTemplates, { leaseId })}`
    ),

  getLeaseDocumentTemplatesBluemoon: (leaseId) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.leaseDocumentTemplatesBluemoon, {
          leaseId,
        })}`
      )
      .then((response) => response.data),

  getCompanyLeaseSetup: () =>
    axios
      .get(`${API_URL}${ROUTES.getCompanyLeaseSetup}`)
      .then((response) => response.data),

  updateCompanyLeaseSetup: ({ leaseSetup }) =>
    axios
      .post(`${API_URL}${ROUTES.updateCompanyLeaseSetup}`, leaseSetup)
      .then((response) => response.data),

  getPropertyLeaseSetup: (id) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getPropertyLeaseSetup, {
          id,
        })}`
      )
      .then((response) => response.data),

  updatePropertyLeaseSetup: ({ id, leaseSetup }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.updatePropertyLeaseSetup, { id })}`,
        leaseSetup
      )
      .then((response) => response.data),

  updateLeaseMetadata: (leaseId, metadata) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.updateLeaseMetadata, { leaseId })}`,
      metadata
    ),
  updateLeaseMetadataBluemoon: (leaseId, metadata) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.updateLeaseMetadataBluemoon, {
        leaseId,
      })}`,
      metadata
    ),
  countersignBluemoonLease: (id, values) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.countersignBluemoonLease, {
        leaseId: id,
      })}`,
      values
    ),
  leasePreview: (leaseId) =>
    axios.get(`${API_URL}${transformRoute(ROUTES.leasePreview, { leaseId })}`),
  sendLease: (id) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.sendLease, { leaseId: id })}`
    ),
  sendLeaseBluemoon: (id) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.sendLeaseBluemoon, { leaseId: id })}`
    ),
  resendLease: (id) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.resendLease, { leaseId: id })}`
    ),
  withdrawLease: (id) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.withdrawLease, { leaseId: id })}`
    ),
  getManagerSignUrl: ({ leaseId }) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.managerSignUrl, { leaseId })}`
    ),
  getPropertyFields: (id) =>
    axios.get(`${API_URL}${transformRoute(ROUTES.propertyFields, { id })}`),
  getDocumentFields: (id, documentId) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.propertyDocumentFields, {
        id,
        documentId,
      })}`
    ),
  getApplicationAnswers: (leaseId) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.applicationAnswers, { leaseId })}`
    ),
  getLeaseFields: (leaseId) =>
    axios.get(`${API_URL}${transformRoute(ROUTES.leaseFields, { leaseId })}`),

  getLeaseFieldsBluemoon: (leaseId) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.leaseFieldsBluemoon, { leaseId })}`
      )
      .then(({ data }) => data),

  handleLeaseOffline: (leaseId) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.handleLeaseOffline, {
        leaseId,
      })}`
    ),
  setLeaseInProgress: (leaseId) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.setLeaseInProgress, {
        leaseId,
      })}`
    ),

  processingManagerSignature: (leaseId) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.processingManagerSignature, {
        leaseId,
      })}`
    ),

  downloadLease: (id) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.downloadLease, { leaseId: id })}`
    ),

  getLeaseAvailableDocuments: (leaseId) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.leaseAvailableDocuments, {
        leaseId,
      })}`
    ),

  getLeaseAvailableDocumentsBluemoon: (leaseId) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.leaseAvailableDocumentsBluemoon, {
        leaseId,
      })}`
    ),

  getLeaseAdditionalDocuments: (leaseId) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getLeaseAdditionalDocuments, {
          leaseId,
        })}`
      )
      .then(({ data }) => data),

  getLeasePackagesManagerAnswers: (leaseId) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getLeasePackagesManagerAnswers, {
          leaseId,
        })}`
      )
      .then(({ data }) => data),

  getLeaseAdditionalManagerQuestions: (leaseId) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getLeaseAdditionalManagerQuestions, {
          leaseId,
        })}`
      )
      .then(({ data }) => data),

  refreshLeaseAvailableDocuments: (leaseId) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.refreshLeaseAvailableDocuments, {
        leaseId,
      })}`
    ),

  executeOfflineLease: (leaseId, finalDocument) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.executeOfflineLease, { leaseId })}`,
      { finalDocument }
    ),

  /**
   *
   * @param {String} leaseStart YYYY-MM-DD
   * @param {String} leaseEnd YYYY-MM-DD
   * @returns {Promise<{ concessionStart: string, concessionEnd: string }> }
   */
  getValidConcessionMonths: ({ leaseStart, leaseEnd }) =>
    axios
      .get(`${API_URL}${ROUTES.validConcessionMonths}`, {
        params: {
          lease_start: leaseStart,
          lease_end: leaseEnd,
        },
      })
      .then(({ data }) => data),

  /**
   * @param {String} leaseStart YYYY-MM-DD
   * @param {String} leaseEnd YYYY-MM-DD
   * @param {Number} numberOfConcessionMonths
   * @param {Number} rent cents
   * @returns {Promise<Number>} Net effective rent
   */
  calculateNetEffectiveRent: ({
    leaseStart,
    leaseEnd,
    numberOfConcessionMonths,
    rent,
  }) =>
    axios
      .get(`${API_URL}${ROUTES.calculateNetEffectiveRent}`, {
        params: {
          lease_start: leaseStart,
          lease_end: leaseEnd,
          number_of_concession_months: numberOfConcessionMonths,
          rent,
        },
      })
      .then(({ data }) => data),

  leasesExport: () => axios.post(`${API_URL}${ROUTES.leasesExport}`),

  /**
   * @param {Object<{ leaseId: number, securityDeposit: number }>}
   * @returns {Promise<{ remainingSecurityDeposit: number }>}
   */
  calculateSecurityDepositBalance: ({ leaseId, securityDeposit }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.calculateSecurityDepositBalance, {
          leaseId,
        })}`,
        { securityDeposit }
      )
      .then(({ data }) => data),
};

/**
 * @param {string} leaseStatus Options: Lookup in LEASE_STATUS_CODES (shared/config/app.constants.js)
 * @param {number} propertyId
 * @param {string} ordering Options: deal__unit_listing__unit__name,rent,created_at,start_date,end_date
 * @param {number} unitId
 * @param {string} leaseCategory Options: in_progress, active, expired, all
 * @param {string} dateMin Created at date Min
 * @param {string} dateMax Created at date max
 * @param {string} unitPropertyOrApplicant Search by unit, property or applicant
 * @param {number} page
 * @param {number} pageSize
 * @returns {Object} { results: [Lease]], count: number } (When paginated)
 * @returns {Object []} [Lease] (when not paginated)
 */
export const getLeases = ({
  leaseStatus,
  propertyId,
  ordering,
  unitId,
  leaseCategory,
  dateMin,
  dateMax,
  unitPropertyOrApplicant,
  page,
  pageSize,
}) =>
  axios
    .get(`${API_URL}${ROUTES.leases}`, {
      params: {
        start_date__gte: dateMin,
        end_date__lte: dateMax,
        property_id__in: propertyId,
        unit_id: unitId,
        unit_or_property_or_applicant: unitPropertyOrApplicant,
        lease_category: leaseCategory,
        lease_status: leaseStatus,
        page_size: pageSize,
        page,
        ordering,
      },
      // @ts-ignore
      clearStorageAndReloadOnBadRequest: true,
    })
    .then(({ data }) => data);

export default api;
