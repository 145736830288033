import { useMemo } from "react";

import { RenewalStatus } from "manager/config/constants";
import { RenewalBasic } from "manager/interfaces/api/renewals";
import { RenewalOfferStatus } from "shared/config/constants";
import { usePermissions } from "shared/hooks";
import { Permissions } from "shared/hooks/usePermissions";

export const getRenewalStatusFlags = (renewal: RenewalBasic) => {
  const isRenewalNotCompleted =
    renewal?.status === RenewalStatus.RENEWAL_NOT_COMPLETED;
  const isOfferNotCreated = renewal?.status === RenewalStatus.OFFER_NOT_CREATED;
  const isOfferCreationInProgress =
    renewal?.status === RenewalStatus.OFFER_CREATION_IN_PROGRESS;
  const isOfferCreated = renewal?.status === RenewalStatus.OFFER_CREATED;
  const isOfferApproved = renewal?.status === RenewalStatus.OFFER_APPROVED;
  const isOfferSent = renewal?.status === RenewalStatus.OFFER_SENT;
  const isOfferPartiallyAccepted =
    renewal?.status === RenewalStatus.OFFER_PARTIALLY_ACCEPTED;
  const isOfferPartiallyRejected =
    renewal?.status === RenewalStatus.OFFER_PARTIALLY_REJECTED;
  const isOfferRejected = renewal?.status === RenewalStatus.OFFER_REJECTED;
  const isRenewalCreationInProgress =
    renewal?.status === RenewalStatus.RENEWAL_CREATION_IN_PROGRESS;
  const isOfferWithdrawable = renewal?.isOfferWithdrawable;

  const isOfferOptionDeleteEnabled =
    renewal?.offer?.status === RenewalOfferStatus.IN_PROGRESS;

  const isPendingOfferResponse =
    isOfferSent || isOfferPartiallyAccepted || isOfferPartiallyRejected;

  return {
    isRenewalNotCompleted,
    isOfferNotCreated,
    isOfferCreationInProgress,
    isOfferCreated,
    isOfferApproved,
    isOfferSent,
    isOfferPartiallyAccepted,
    isOfferPartiallyRejected,
    isOfferRejected,
    isOfferWithdrawable,
    isRenewalCreationInProgress,
    isOfferOptionDeleteEnabled,
    isPendingOfferResponse,
  };
};

export const getRenewalHelpers = (
  renewal: RenewalBasic,
  permissions: Permissions
) => {
  const {
    areRenewalsEnabled,
    canCreateRenewals,
    canCreateRenewalsOffers,
    canApproveRenewalsOffers,
  } = permissions || {};
  const existsAcceptedOfferOption = !!(
    renewal?.offer?.renewalOptions || []
  ).find((o) => o.accepted);

  const {
    isRenewalNotCompleted,
    isOfferNotCreated,
    isOfferCreationInProgress,
    isOfferCreated,
    isOfferApproved,
    isOfferRejected,
    isRenewalCreationInProgress,
    isOfferOptionDeleteEnabled,
    isPendingOfferResponse,
    isOfferWithdrawable,
  } = getRenewalStatusFlags(renewal);

  const isWithdrawOfferEnabled = areRenewalsEnabled && isOfferWithdrawable;
  const isNotRenewingOfferEnabled =
    areRenewalsEnabled && isPendingOfferResponse;
  const isOfferCreationEnabled = areRenewalsEnabled && isOfferNotCreated;
  const isOfferEditEnabled =
    areRenewalsEnabled &&
    (isOfferCreationInProgress || isOfferCreated || isOfferApproved);

  const isInviteResidentEnabled =
    areRenewalsEnabled && isRenewalCreationInProgress;
  const isRemovingResidentEnabled =
    areRenewalsEnabled && isRenewalCreationInProgress;

  return {
    renewalHelpers: {
      canCreateRenewal: canCreateRenewals,
      canCancelRenewal: canCreateRenewals,
      canRequestRenewalCreation: true,
      canReadNotes: canCreateRenewalsOffers || canCreateRenewals,
    },
    offerHelpers: {
      isOfferCreationEnabled,
      canCreateOffer: canCreateRenewalsOffers,
      canRequestOffer: true,
      isOfferEditEnabled,
      canEditOffer: canCreateRenewalsOffers,
      isDeleteOptionEnabled: isOfferOptionDeleteEnabled,
      canDeleteOfferOption: canCreateRenewalsOffers,
      isWithdrawOfferEnabled,
      canWithdrawOffer: canCreateRenewalsOffers,
      canApproveOffer: canApproveRenewalsOffers,
      canRequestApproveOffer: canCreateRenewalsOffers,
      canSendOffers: canCreateRenewalsOffers,
      canUnapproveOffer: canApproveRenewalsOffers,
      isNotRenewingOfferEnabled,
      canNotRenewOffer: canCreateRenewalsOffers,
      existsAcceptedOfferOption,
      optionsDisabled:
        existsAcceptedOfferOption || isOfferRejected || isRenewalNotCompleted,
      isPendingOfferResponse,
    },
    residentsHelpers: {
      isInviteResidentEnabled,
      isRemovingResidentEnabled,
    },
  };
};

const useRenewalHelpers = ({ renewal }: { renewal: RenewalBasic }) => {
  const permissions = usePermissions();
  const helpers = useMemo(
    () => getRenewalHelpers(renewal, permissions),
    [renewal, permissions]
  );

  return helpers;
};

export default useRenewalHelpers;
