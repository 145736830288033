import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import Icon from "shared/components/Icon";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { SmallTitle } from "shared/components/Typography";
import { APPLICANT_TYPES } from "shared/config/constants";

import ApplicantQuestionsTable from "./ApplicantQuestionsTable";
import { LabelIconContainer } from "./styled";

const ApplicantQuestions = ({
  loading,
  applicantQuestions,
  openEditApplicantQuestionModal,
  isPortfolioSettings,
}) => {
  const { values } = useFormikContext();

  const getSpecificQuestions = (applicantType) =>
    (applicantQuestions || []).filter(
      (item) => item.applicantType === applicantType
    );

  return (
    <Tile
      noBorder
      noMarginBottom
      size="lg"
      header={{
        title: (
          <LabelIconContainer alignItems="center">
            <SmallTitle>Applicant type questions</SmallTitle>
            <Tooltip
              title="This section contains all single input applicant-type
                    questions created in Company Settings. Use the green edit
                    button to associate any necessary lease document(s) to each
                    question."
            >
              <Icon.InfoIcon className="extra-info" />
            </Tooltip>
          </LabelIconContainer>
        ),
        subtitle:
          "If necessary, select the apropriate documents for the questions required of applicants, occupants and guarantors",
        collapsibleOnDesktop: true,
        collapsibleOnMobile: true,
        fullWidth: true,
      }}
    >
      <ApplicantQuestionsTable
        applicantType="APPLICANTS"
        loading={loading}
        onEdit={(row) =>
          openEditApplicantQuestionModal({
            question: row,
            documentTemplates: values?.documentTemplates?.documentTemplates,
          })
        }
        questionsData={getSpecificQuestions(APPLICANT_TYPES.applicant)}
        isPortfolioSettings={isPortfolioSettings}
      />
      <ApplicantQuestionsTable
        applicantType="OCCUPANTS"
        loading={loading}
        onEdit={(row) =>
          openEditApplicantQuestionModal({
            question: row,
            documentTemplates: values?.documentTemplates?.documentTemplates,
          })
        }
        questionsData={getSpecificQuestions(APPLICANT_TYPES.occupant)}
        isPortfolioSettings={isPortfolioSettings}
      />
      <ApplicantQuestionsTable
        applicantType="GUARANTORS"
        loading={loading}
        onEdit={(row) =>
          openEditApplicantQuestionModal({
            question: row,
            documentTemplates: values?.documentTemplates?.documentTemplates,
          })
        }
        questionsData={getSpecificQuestions(APPLICANT_TYPES.guarantor)}
        isPortfolioSettings={isPortfolioSettings}
      />
    </Tile>
  );
};

ApplicantQuestions.propTypes = {
  applicantQuestions: PropTypes.arrayOf(PropTypes.object),
  openEditApplicantQuestionModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isPortfolioSettings: PropTypes.bool,
};

ApplicantQuestions.defaultProps = {
  applicantQuestions: [],
  loading: false,
  isPortfolioSettings: undefined,
};

export default ApplicantQuestions;
