import React from "react";

import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";

import { CompanyAdditionalSettings } from "manager/components/AdditionalSettings";
import DealSetupForm from "manager/components/CompanyDealSetupSettings/DealSetupForm";
import LeaseSetupForm from "manager/components/CompanyDealSetupSettings/LeaseSetupForm";
import AdditionalQuestions from "manager/components/DealSetupSettings/AdditionalQuestions";
import ApplicationFees from "manager/components/DealSetupSettings/ApplicationFees";
import PaymentAccount from "manager/components/DealSetupSettings/PaymentAccount";
import DocumentTemplates from "manager/components/DealSetupSettings/DocumentTemplates";
import LeaseESigning from "manager/components/DealSetupSettings/LeaseESigning";
import LeaseReminder from "manager/components/DealSetupSettings/LeaseReminder";
import LeaseTerms from "manager/components/DealSetupSettings/LeaseTerms";
import RequiredAttachments from "manager/components/DealSetupSettings/RequiredAttachments";
import RequiredAttachmentsBackgroundSetup from "manager/components/DealSetupSettings/RequiredAttachmentsBackgroundSetup";
import RequiredAttachmentsUnverifiedIncome from "manager/components/DealSetupSettings/RequiredAttachmentsUnverifiedIncome";
import ScreeningForApplicantsAndOccupants from "manager/components/DealSetupSettings/ScreeningForApplicantsAndOccupants";
import ScreeningForGuarantors from "manager/components/DealSetupSettings/ScreeningForGuarantors";
import CompanyEmailTemplatesSettings from "manager/components/EmailTemplates/CompanyEmailTemplatesSettings";
import CompanySettingsHoldingDeposit from "manager/components/HoldingDeposit/CompanyHoldingDepositSettings";
import { RenewalsCompanySettings } from "manager/components/Renewals/RenewalsSettings";
import SettingsTabs from "manager/components/SettingsTabs";
import ROUTES, { APPLICATION_LEASE_SETUP_TABS } from "manager/config/routes";
import SettingsPage from "manager/templates/SettingsPage";
import Tooltip from "shared/components/Tooltip";
import { ApplicantWorkflowStudio } from "shared/components/vega/ApplicantWorkflow";
import { LumenStudio } from "shared/components/vega/LumenStudio";
import { usePermissions } from "shared/hooks";
import { conditionalItem } from "shared/utils/array";
import { transformRoute } from "shared/utils/routing";

const ApplicationLeaseSetup = ({
  backLink,
  location,
  paymentAccountMappings,
  createPaymentAccountMapping,
}) => {
  const { tabName } = useParams();
  const { push } = useHistory();
  const {
    isEmailCustomizationEnabled,
    canCustomizeEmails,
    enableHoldingDeposit,
    areRenewalsEnabled,
  } = usePermissions();

  const onTabChange = (key) =>
    push(transformRoute(ROUTES.applicationsLeaseSetupTab, { tabName: key }));

  return (
    <SettingsPage title="Application & Lease Setup" backLink={backLink}>
      <SettingsTabs
        activeTab={tabName}
        onTabChange={onTabChange}
        items={[
          {
            key: APPLICATION_LEASE_SETUP_TABS.screeningSetup,
            label: "Screening Setup",
            node: (
              <DealSetupForm>
                <ScreeningForApplicantsAndOccupants />
                <ScreeningForGuarantors />
              </DealSetupForm>
            ),
          },
          {
            key: APPLICATION_LEASE_SETUP_TABS.applicationSetup,
            label: "Application Setup",
            node: (
              <DealSetupForm>
                <ApplicationFees />
                <PaymentAccount
                  location={location}
                  paymentAccountMappings={paymentAccountMappings}
                  createPaymentAccountMapping={createPaymentAccountMapping}
                />
                <AdditionalQuestions />
                <RequiredAttachments />
                <RequiredAttachmentsUnverifiedIncome />
                <RequiredAttachmentsBackgroundSetup />
              </DealSetupForm>
            ),
          },
          {
            key: APPLICATION_LEASE_SETUP_TABS.workflow,
            label: "Workflow",
            node: (
              <ApplicantWorkflowStudio
                location={location}
              />
            ),
          },
          {
            key: APPLICATION_LEASE_SETUP_TABS.lumen,
            label: "Lumen",
            node: (
              <LumenStudio
                location={location}
              />
            ),
          },
          {
            key: APPLICATION_LEASE_SETUP_TABS.documentSetup,
            label: "Lease Documents",
            node: (
              <LeaseSetupForm>
                <DocumentTemplates />
              </LeaseSetupForm>
            ),
          },
          {
            key: APPLICATION_LEASE_SETUP_TABS.leaseSetup,
            label: "Lease Options",
            node: (
              <LeaseSetupForm>
                <LeaseESigning />
                <LeaseTerms />
                <LeaseReminder />
              </LeaseSetupForm>
            ),
          },
          {
            key: APPLICATION_LEASE_SETUP_TABS.additionalSettings,
            label: "Additional Settings",
            node: <CompanyAdditionalSettings />,
          },
          ...conditionalItem(isEmailCustomizationEnabled, {
            key: APPLICATION_LEASE_SETUP_TABS.emailTemplates,
            label: (
              <Tooltip
                title={
                  !canCustomizeEmails
                    ? "You do not have access to Email Templates; contact your account manager."
                    : ""
                }
              >
                Email Templates
              </Tooltip>
            ),
            node: <CompanyEmailTemplatesSettings />,
            disabled: !canCustomizeEmails,
          }),
          ...conditionalItem(enableHoldingDeposit, {
            key: APPLICATION_LEASE_SETUP_TABS.applicationCharges,
            label: "Application Charges",
            node: <CompanySettingsHoldingDeposit />,
          }),
          ...conditionalItem(areRenewalsEnabled, {
            key: APPLICATION_LEASE_SETUP_TABS.renewals,
            label: "Renewals",
            node: <RenewalsCompanySettings />,
          }),
        ]}
      />
    </SettingsPage>
  );
};

ApplicationLeaseSetup.propTypes = {
  backLink: PropTypes.object.isRequired,
};

export default ApplicationLeaseSetup;
