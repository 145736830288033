import React from "react";

import capitalize from "lodash/capitalize";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";

import { DocumentPages } from "../../styled";

import { IconsContainer, StyledPackage, DocumentName } from "./styled";

const PackageItem = ({
  item,
  index,
  previewDocument,
  removeDocumentFromPackage,
  hidePreview,
}) => (
  <Draggable draggableId={`item-${item.id}`} index={index}>
    {(provided) => (
      <StyledPackage
        key={item.id}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <FlexContainer flexDirection="column">
          <DocumentName data-testid="package-document">
            {item.name}
          </DocumentName>
          <DocumentPages>
            {`${item.language ? `${capitalize(item.language)}, ` : ""}${
              item.numberOfPages
            } ${pluralize("page", item.numberOfPages)}`}
          </DocumentPages>
        </FlexContainer>
        <IconsContainer>
          {!hidePreview && (
            <Icon.ViewDetailsIcon
              onClick={() => previewDocument(item.id)}
              data-testid="preview-document-button"
            />
          )}
          {!item.isDefault && (
            <Icon.CrossRemoveIcon
              onClick={removeDocumentFromPackage(item.id)}
              data-testid="cross-remove-button"
            />
          )}
        </IconsContainer>
      </StyledPackage>
    )}
  </Draggable>
);

PackageItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    isDefault: PropTypes.bool,
    language: PropTypes.string,
    numberOfPages: PropTypes.number,
  }),
  index: PropTypes.number.isRequired,
  previewDocument: PropTypes.func.isRequired,
  removeDocumentFromPackage: PropTypes.func.isRequired,
  hidePreview: PropTypes.bool,
};

PackageItem.defaultProps = {
  item: {},
  hidePreview: false,
};

export default PackageItem;
