import React, { Key } from "react";

import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { useGetLease } from "manager/hooks/api";
import LeaseGroup from "manager/pages/LeaseGroup";
import LoaderContainer, {
  BigLoadingSpinner,
} from "shared/components/LoaderContainer";
import { BackLinkProps } from "shared/interfaces/misc";
import Layout from "shared/templates/Layout";

const backLink: BackLinkProps = {
  to: ROUTES.leases,
  children: "Leases",
};

const LeaseContainer = ({ sidebar, header }) => {
  const { id } = useParams() as { id: Key };
  const { data: lease, isLoading } = useGetLease(id && Number(id));
  const pageTitle = !lease?.deal?.unit?.name
    ? "Lease Details "
    : `${lease?.deal?.unit?.name} LEASE`;

  return (
    <Layout
      sidebar={sidebar}
      header={{
        ...header,
        backLink,
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <LoaderContainer loading={isLoading} Loader={<BigLoadingSpinner />}>
        <LeaseGroup leaseGroupId={lease?.leaseGroup} backLink={backLink} />
      </LoaderContainer>
    </Layout>
  );
};

export default LeaseContainer;
