import React from "react";

import useModal from "shared/hooks/useModal";

import DocumentsVerificationModal from "./DocumentsVerificationModal";
import { DocumentsVerificationModalProps } from "./interfaces";

const DocumentsVerificationModalContainer = () => {
  const { currentModalContext } = useModal();
  const { documentErrors }: DocumentsVerificationModalProps =
    currentModalContext;

  return <DocumentsVerificationModal documentErrors={documentErrors} />;
};

export default DocumentsVerificationModalContainer;
