import React from "react";

import { Radio } from "antd";
import { useFormikContext } from "formik";
import isBoolean from "lodash/isBoolean";
import startCase from "lodash/startCase";
import PropTypes from "prop-types";

import {
  DatePicker,
  Input,
  PhoneNumberInput,
  RadioGroup,
  Select,
  TextArea,
  VeroFormField,
} from "shared/components/Form";
import Icon from "shared/components/Icon";
import {
  BLUEMOON_FIELD_TYPES,
  FIELD_TYPES,
  YEAR_MONTH_DATE_FORMAT,
} from "shared/config/constants";

import {
  CheckboxContainer,
  CheckboxTitle,
  FieldContainer,
  InputField,
  TooltipContainer,
  InputContainer,
} from "../styled";

import Tooltip from "./Tooltip";

const getFieldName = (name) => `fields.${name}`;

const DocumentField = ({
  field,
  value,
  isPropertyQuestion,
  isBluemoonLease,
  fieldsToHide,
}) => {
  const { setFieldValue, values } = useFormikContext();
  const { fieldType, name, description, isEditable } = field;
  const getComponentData = () => {
    if (isBluemoonLease) {
      if (fieldType === BLUEMOON_FIELD_TYPES.enum) {
        return {
          as: Select,
          error: !values.fields[name] && `${startCase(name)} field is not set.`,
        };
      }

      if (fieldType === BLUEMOON_FIELD_TYPES.currency) {
        return { as: Input, suffix: <Icon.DollarSignGray /> };
      }
      if (fieldType === BLUEMOON_FIELD_TYPES.date) {
        return { as: DatePicker, displayFormat: YEAR_MONTH_DATE_FORMAT };
      }

      if (fieldType === BLUEMOON_FIELD_TYPES.day) {
        const formikValue = Number(values.fields[name]);
        return {
          as: Input,
          error:
            formikValue && (formikValue < 1 || formikValue > 31)
              ? `${startCase(name)} field must be between 1 and 31.`
              : null,
        };
      }

      if (fieldType === BLUEMOON_FIELD_TYPES.decpercent) {
        return { as: Input, suffix: <>%</> };
      }

      if (fieldType === BLUEMOON_FIELD_TYPES.boolean) {
        return {
          as: RadioGroup,
          options: [
            {
              label: "Yes",
              value: "True",
            },
            {
              label: "No",
              value: "False",
            },
          ],
        };
      }

      if (fieldType === BLUEMOON_FIELD_TYPES.TextArea) {
        return { as: TextArea };
      }
    }

    if (
      isBluemoonLease &&
      (fieldType === BLUEMOON_FIELD_TYPES.phone || name.includes("phone"))
    ) {
      return {
        as: PhoneNumberInput,
        onBlur: (e) =>
          setFieldValue(
            getFieldName(name),
            e.target.value.replace(/[^\d]/g, "")
          ),
        setFieldValue,
        value: values?.fields[name] || " ",
        disableDropdown: true,
        disableCountryCode: true,
        country: "us",
        onlyCountries: ["us"],
      };
    }
    if (
      !isBluemoonLease &&
      (fieldType === BLUEMOON_FIELD_TYPES.phone || name.includes("phone"))
    ) {
      return {
        as: PhoneNumberInput,
        setFieldValue,
      };
    }
    return { as: Input };
  };

  const getComponentChildren = () => {
    if (isBluemoonLease) {
      if (fieldType === BLUEMOON_FIELD_TYPES.enum) {
        return field.bluemoonFieldChoices?.map(
          ({ option, humanReadableOption }) => (
            <Select.Option key={option}>
              {humanReadableOption ?? option}
            </Select.Option>
          )
        );
      }

      if (fieldType === BLUEMOON_FIELD_TYPES.boolean) {
        return (
          <>
            <Radio value="True">Yes</Radio>
            <Radio value="False">No</Radio>
          </>
        );
      }
    }
    return null;
  };

  return (
    <FieldContainer
      key={name}
      className={
        isBluemoonLease && fieldsToHide?.includes(name) ? "hidden" : ""
      }
    >
      {field.type === FIELD_TYPES.checkbox ? (
        <CheckboxContainer>
          <CheckboxTitle>{description}</CheckboxTitle>
          <VeroFormField
            as={Radio.Group}
            id={getFieldName(name)}
            name={getFieldName(name)}
            label={description}
            disabled={
              (isPropertyQuestion && value !== null) ||
              (isBoolean(isEditable) && !isEditable)
            }
          >
            <Radio value>Yes</Radio>
            <Radio value={false}>No</Radio>
          </VeroFormField>
        </CheckboxContainer>
      ) : (
        <>
          <InputField>
            <InputContainer>
              <VeroFormField
                {...getComponentData()}
                id={getFieldName(name)}
                name={getFieldName(name)}
                label={startCase(name)}
                disabled={isBoolean(isEditable) && !isEditable}
              >
                {getComponentChildren()}
              </VeroFormField>
            </InputContainer>
            <TooltipContainer>
              <Tooltip field={field} />
            </TooltipContainer>
          </InputField>
        </>
      )}
    </FieldContainer>
  );
};

DocumentField.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]),
  isPropertyQuestion: PropTypes.bool,
  isBluemoonLease: PropTypes.bool,
  fieldsToHide: PropTypes.arrayOf(PropTypes.string),
};

DocumentField.defaultProps = {
  value: null,
  isPropertyQuestion: false,
  isBluemoonLease: false,
  fieldsToHide: [],
};
export default DocumentField;
