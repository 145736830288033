import React, { useMemo } from "react";

import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import { FIELD_CATEGORIES } from "admin/config/constants";
import BaseSpacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { SmallTitle } from "shared/components/Typography";

import {
  TypeSection,
  SectionTitle,
  SpacerContainer,
  Spacer,
  FieldsContainer,
  FieldsSection,
  InfoIcon,
  AllSetSection,
  CompletedIcon,
} from "../styled";

import DocumentField from "./DocumentField";

const DocumentFields = ({ document, isBluemoonLease, fieldstoHide }) => {
  const isDocumentWithoutFields = isEmpty(document.fields);
  const isDocumentWithAllFields = !isEmpty(document.fields);
  const getFieldsByCategory = (fields, category) =>
    fields.flat().filter((field) => field.category === category);

  const propertyFields = getFieldsByCategory(
    document.fields,
    FIELD_CATEGORIES.property
  );
  const leaseFields = getFieldsByCategory(
    document.fields,
    FIELD_CATEGORIES.lease
  );
  const applicantFields = getFieldsByCategory(
    document.fields,
    FIELD_CATEGORIES.applicant
  );

  const feeFieldsFlat = Object.values(fieldstoHide)
    .filter((value) => value.length > 0)
    .flat();

  const findMatchingFeeFieldsToHide = document?.fields.filter((field) =>
    feeFieldsFlat.includes(field.name)
  );
  const fieldsToHide =
    !isEmpty(findMatchingFeeFieldsToHide) &&
    findMatchingFeeFieldsToHide
      .map((field) => {
        const ar = document?.fields.filter((f) => f?.id === field?.id).flat();
        return ar;
      })
      ?.flat()
      ?.map((field) => field.name);

  const isDynamicPosition =
    isDocumentWithAllFields && propertyFields.length > leaseFields.length;

  const ApplicantQuestions = useMemo(
    () => (
      <TypeSection data-testid="applicant-questions">
        <SectionTitle>
          APPLICANT QUESTIONS
          <Tooltip
            title="These fields are dynamically brought in from the application. It is rare for you to need to change these fields."
            theme="light"
          >
            <InfoIcon />
          </Tooltip>
        </SectionTitle>
        <FieldsContainer>
          {applicantFields.map((field) => (
            <DocumentField field={field} key={field.name} />
          ))}
        </FieldsContainer>
      </TypeSection>
    ),
    [document]
  );

  return (
    <Tile data-testid={document.key}>
      <SpacerContainer>
        <Spacer id={`${document.anchor}`} />
      </SpacerContainer>

      <Tile.Inner id={`menu-${document.key}`}>
        <SmallTitle>{document.name}</SmallTitle>
        <BaseSpacer />
        <FieldsSection>
          {!isEmpty(leaseFields) && (
            <div>
              <TypeSection
                withMarginButtom={isDynamicPosition}
                data-testid="lease-questions"
              >
                <SectionTitle>LEASE QUESTIONS</SectionTitle>
                <FieldsContainer>
                  {leaseFields.map((field) => (
                    <DocumentField
                      field={field}
                      key={field.name}
                      isBluemoonLease={isBluemoonLease}
                      fieldsToHide={
                        Array.isArray(fieldsToHide) ? fieldsToHide : []
                      }
                    />
                  ))}
                </FieldsContainer>
              </TypeSection>
              {isDynamicPosition && ApplicantQuestions}
            </div>
          )}
          {!isEmpty(propertyFields) && (
            <TypeSection data-testid="property-questions">
              <SectionTitle>PROPERTY QUESTIONS</SectionTitle>
              <FieldsContainer>
                {propertyFields.map((field) => (
                  <DocumentField
                    field={field}
                    value={field.value}
                    key={field.name}
                    isPropertyQuestion
                    fieldsToHide={
                      Array.isArray(fieldsToHide) ? fieldsToHide : []
                    }
                  />
                ))}
              </FieldsContainer>
            </TypeSection>
          )}
          {!isEmpty(applicantFields) &&
            !isDynamicPosition &&
            ApplicantQuestions}
        </FieldsSection>
        {isDocumentWithoutFields && (
          <AllSetSection>
            <CompletedIcon />
            <div>
              All set here! This document doesn’t have any fields to fill in.
            </div>
          </AllSetSection>
        )}
      </Tile.Inner>
    </Tile>
  );
};

DocumentFields.propTypes = {
  document: PropTypes.object.isRequired,
  fieldstoHide: PropTypes.arrayOf(document),
  isBluemoonLease: PropTypes.bool,
};

export default DocumentFields;
