import React from "react";

import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";
import Well from "shared/components/Well";

import { RegularTextPurple } from "./styled";

const FirstMonthRentProrationInfo = () => (
  <Well noBorder largePadding>
    <RegularTextPurple strong>PRORATE THE FIRST MONTHS RATE</RegularTextPurple>
    <Spacer size={Spacer.SIZES.xs} />
    <RegularText weak>
      The first month’s rent on the lease is prorated for the number of days
      remaining in the month when the checkbox is selected.
    </RegularText>
    <Spacer />
    <RegularTextPurple strong>EXAMPLE</RegularTextPurple>
    <Spacer size={Spacer.SIZES.xs} />
    <RegularText weak>
      Lease Rent: <RegularText strong> $1,000.00 </RegularText>
      <br />
      Lease Start Date: <RegularText strong> June 15th</RegularText>
      <br />
      Lease Days in Start Month: <RegularText strong> 16</RegularText>
    </RegularText>
    <Spacer />
    <RegularText weak>
      Prorated First Month Rent $1,000 x (16 days charged / 30 days in June) ={" "}
      <RegularText strong> $533.33 first month rent</RegularText>
    </RegularText>
  </Well>
);

export default FirstMonthRentProrationInfo;
