import React, { useCallback, useEffect, useState } from "react";

import { isEqual } from "lodash";
import { queryCache } from "react-query";

import { Document } from "admin/pages/Documents/interfaces";
import { PROPERTY_FILTERS } from "manager/config/constants";
import { useGetPortfoliosForDocuments } from "manager/hooks/api";
import {
  useAssignDocumentToProperties,
  useUnassignDocumentFromProperties,
} from "shared/hooks/api/documentQueries";
import useModal from "shared/hooks/useModal";
import { filterArrayByMultipleFields } from "shared/utils/ui";

import DocumentToPropertiesConfirmModal from "../DocumentToPropertiesConfirmModal";

import DocumentToPropertiesModal from "./DocumentToPropertiesModal";
import {
  formatPortfolios,
  isParticipatesInDealProperties,
  sortPortfoliosForAssignment,
} from "./utils";

interface DocumentToPropertiesModalContainerProps {
  document: Document;
}

const DocumentToPropertiesModalContainer = ({
  document,
}: DocumentToPropertiesModalContainerProps) => {
  const { openModalDialog } = useModal();
  const { portfoliosForDocuments, isPortfoliosForDocuments } =
    useGetPortfoliosForDocuments(document?.id);
  const { assignPropertyToPortfolio, isAssignPropertyToPortfolio } =
    useAssignDocumentToProperties(document?.id);
  const { unassignDocumentFromProperties, isUnassignDocumentFromProperties } =
    useUnassignDocumentFromProperties(document?.id);

  const [displayedPortfolios, setDisplayedPortfolios] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [indeterminatePortfolios, setIndeterminatePortfolios] = useState([]);
  const [searchingValue, setSearchingValue] = useState("");
  const [dirty, setDirty] = useState(false);

  const saveChanges = useCallback(() => {
    const {
      propertiesToAssign,
      propertiesToUnassign,
    }: { propertiesToAssign: number[]; propertiesToUnassign: number[] } =
      sortPortfoliosForAssignment(portfoliosForDocuments, checkedItems);
    if (propertiesToAssign.length) {
      assignPropertyToPortfolio(propertiesToAssign);
    }

    if (propertiesToUnassign.length) {
      unassignDocumentFromProperties(propertiesToUnassign);
    }

    queryCache.invalidateQueries("getCompanyDocumentTemplates");
    queryCache.invalidateQueries("getPropertyDocumentTemplates");

    openModalDialog(DocumentToPropertiesConfirmModal, {
      documentName: document?.name,
      addedItemsCount: propertiesToAssign.length,
      removedItemsCount: propertiesToUnassign.length,
    });
  }, [portfoliosForDocuments, checkedItems]);

  const isShowWarning = useCallback(() => {
    const { propertiesToUnassign } = sortPortfoliosForAssignment(
      portfoliosForDocuments,
      checkedItems
    );
    return isParticipatesInDealProperties(
      portfoliosForDocuments,
      propertiesToUnassign
    );
  }, [checkedItems]);

  useEffect(() => {
    const {
      items: initialItems,
      checked: initialChecked,
      indeterminate: initialIndeterminate,
    } = formatPortfolios(portfoliosForDocuments);
    setDisplayedPortfolios(initialItems);
    setCheckedItems(initialChecked);
    setIndeterminatePortfolios(initialIndeterminate);
  }, [portfoliosForDocuments]);

  useEffect(() => {
    const { items: filteredItems } = formatPortfolios(
      filterArrayByMultipleFields(
        portfoliosForDocuments ?? [],
        PROPERTY_FILTERS,
        searchingValue
      )
    );
    setDisplayedPortfolios(filteredItems);
  }, [searchingValue]);

  useEffect(() => {
    const { checked: initialChecked } = formatPortfolios(
      portfoliosForDocuments
    );
    setDirty(!isEqual(initialChecked, checkedItems));
  }, [portfoliosForDocuments, checkedItems]);

  return (
    <DocumentToPropertiesModal
      document={document}
      portfolios={portfoliosForDocuments}
      portfoliosFetching={isPortfoliosForDocuments}
      isSubmitting={
        isAssignPropertyToPortfolio || isUnassignDocumentFromProperties
      }
      dirty={dirty}
      saveChanges={saveChanges}
      searchingValue={searchingValue}
      displayedPortfolios={displayedPortfolios}
      checkedItems={checkedItems}
      indeterminatePortfolios={indeterminatePortfolios}
      setCheckedItems={setCheckedItems}
      setSearchingValue={setSearchingValue}
      setIndeterminatePortfolios={setIndeterminatePortfolios}
      isShowWarning={isShowWarning}
    />
  );
};

export default DocumentToPropertiesModalContainer;
