import React from "react";

import ApplicationAnswersModal from "./ApplicationAnswersModal";
import {
  normalizeApplicationAnswers,
  normalizeApplicationLeaseTerms,
} from "./utils";

const ApplicationAnswersModalContainer = ({
  applicationAnswers,
  applicationAnswersTypes,
  propertyAddress,
  applicants,
}: {
  applicants?: string;
  propertyAddress?: string;
  applicationAnswersTypes?: any;
  applicationAnswers?: any;
}) => {
  const questions =
    applicants || propertyAddress
      ? {
          ...normalizeApplicationLeaseTerms(applicants),
          ...normalizeApplicationAnswers(
            applicationAnswers,
            applicationAnswersTypes
          ),
        }
      : undefined;

  return (
    <ApplicationAnswersModal
      // @ts-ignore
      questions={questions}
      propertyAddress={propertyAddress}
    />
  );
};

export default ApplicationAnswersModalContainer;
