import { pick } from "lodash";
import * as Yup from "yup";

import ROUTES, { APPLICATION_LEASE_SETUP_TABS } from "manager/config/routes";
import { transformRoute } from "shared/utils/routing";

const PAGE_TITLE = "Custom Email Builder";

const BACK_LINK = {
  children: "Company Settings",
  to: transformRoute(ROUTES.applicationsLeaseSetupTab, {
    tabName: APPLICATION_LEASE_SETUP_TABS.emailTemplates,
  }),
};

const getEmailInitialConfiguration = ({
  defaultEmailConfiguration,
  customEmailConfiguration,
  isEditMode,
  emailVariables,
}) => {
  const configuration = isEditMode
    ? customEmailConfiguration
    : defaultEmailConfiguration;

  return {
    id: isEditMode ? customEmailConfiguration?.id : null,
    defaultConfigurationId: defaultEmailConfiguration?.id,
    typeName: defaultEmailConfiguration?.typeName || "",
    typeNameDescription: defaultEmailConfiguration?.typeNameDescription || "",
    description: defaultEmailConfiguration?.description || "",
    name: configuration?.name || "",
    subject: configuration?.subject || "",
    title: configuration?.title || "",
    body: configuration?.body || "",
    hasCta: Boolean(defaultEmailConfiguration?.hasCta),
    ctaLabel: configuration?.ctaLabel || "",
    hideCta: configuration?.hideCta || false,
    selected: configuration?.selected || false,
    attachments:
      configuration?.attachments?.map((file) => ({
        id: file.id,
        name: file.filename,
        url: file.upload,
      })) || [],
    variables:
      defaultEmailConfiguration?.variables && emailVariables
        ? emailVariables.filter(({ key }) =>
            defaultEmailConfiguration.variables.includes(key)
          )
        : [],
  };
};

const getEmailInitialValues = (configuration) =>
  pick(configuration, [
    "id",
    "defaultConfigurationId",
    "name",
    "subject",
    "title",
    "body",
    "ctaLabel",
    "hasCta",
    "hideCta",
    "attachments",
    "selected",
  ]);

const getEmailSubmitValues = (values, isEditMode) => {
  if (isEditMode) {
    /** Note: Prevent deleting previously saved attachments */
    const previousAttachmentIds = values.attachments
      .filter((file) => Boolean(file.url))
      .map((file) => file.id);

    return {
      ...values,
      previousAttachmentIds,
    };
  }

  return values;
};

const emailValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, "Template name length should be less than 100 characters")
    .required("Template name is required"),
  subject: Yup.string()
    .max(300, "Email subject length should be less than 300 characters")
    .required("Email subject is required"),
  title: Yup.string()
    .max(500, "Email title length should be less than 500 characters")
    .required("Email title is required"),
  body: Yup.string()
    .max(10000, "Email body length should be less than 10,000 characters")
    .required("Email body is required"),
  ctaLabel: Yup.string().when(["hasCta", "hideCta"], {
    is: (hasCta, hideCta) => hasCta && !hideCta,
    then: Yup.string().required("CTA Label is required"),
  }),
  hideCta: Yup.bool(),
});

export {
  PAGE_TITLE,
  BACK_LINK,
  getEmailInitialConfiguration,
  emailValidationSchema,
  getEmailSubmitValues,
  getEmailInitialValues,
};
