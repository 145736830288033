import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import {
  DeleteLeaseGroupAttachmentRequest,
  EditLeaseGroupAttachmentPayload,
  LeaseGroup,
  LeaseGroupApplication,
  LeaseGroupAttachment,
} from "manager/interfaces/api/leaseGroups";
import { API_URL } from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

const api = {
  getLeaseGroups: () =>
    axios
      .get<LeaseGroup[]>(`${API_URL}${ROUTES.leaseGroups}`)
      .then(({ data }) => data),

  getLeaseGroupById: (id: number) =>
    axios
      .get<LeaseGroup>(`${API_URL}${transformRoute(ROUTES.leaseGroup, { id })}`)
      .then(({ data }) => data),

  getLeaseGroupApplications: (leaseGroupId: number) =>
    axios
      .get<LeaseGroupApplication[]>(
        `${API_URL}${transformRoute(ROUTES.leaseGroupApplications, {
          id: leaseGroupId,
        })}`
      )
      .then(({ data }) => data),

  getLeaseGroupAttachments: (leaseGroupId: number) =>
    axios
      .get<LeaseGroupAttachment[]>(
        `${API_URL}${transformRoute(ROUTES.leaseGroupAttachments, {
          id: leaseGroupId,
        })}`
      )
      .then(({ data }) => data),

  createLeaseGroupAttachment: (attachment: LeaseGroupAttachment) => {
    const formData = new FormData();
    formData.append("filename", attachment.filename);
    formData.append("upload", attachment.upload);

    return axios
      .post<LeaseGroupAttachment>(
        `${API_URL}${transformRoute(ROUTES.leaseGroupCreateAttachment, {
          id: attachment.leaseGroupId,
        })}`,
        formData
      )
      .then(({ data }) => data);
  },

  editLeaseGroupAttachment: (attachment: EditLeaseGroupAttachmentPayload) =>
    axios
      .patch<EditLeaseGroupAttachmentPayload>(
        `${API_URL}${transformRoute(ROUTES.leaseGroupEditAttachment, {
          id: attachment.leaseGroupId,
          attachmentId: attachment.id,
        })}`,
        attachment
      )
      .then(({ data }) => data),

  deleteLeaseGroupAttachment: ({
    leaseGroupId,
    id: attachmentId,
  }: DeleteLeaseGroupAttachmentRequest) =>
    axios
      .delete<void>(
        `${API_URL}${transformRoute(ROUTES.leaseGroupDeleteAttachment, {
          id: leaseGroupId,
          attachmentId,
        })}`
      )
      .then(({ data }) => data),
};

export default api;
