
import { Col, Row } from "antd";

import Property from "shared/components/Property";
import Tile from "shared/components/Tile";
import { calculateDuration, formatDate } from "shared/utils/ui";

import { LeasePropertyProps } from "../interfaces";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { PropertyContainer } from "./styled";

const LeaseProperty = ({ lease }: LeasePropertyProps) => (
  <PropertyContainer data-testid="lease-property">
    <Tile.Inner>
      <Row>
        <Col lg={6} md={8} xs={21}>
          <Property
            name={lease?.deal?.unit?.property?.name}
            address={lease?.deal?.unit?.property?.address}
            unit={lease?.deal?.unit?.name}
            fallbackContent="Lease Property"
          />
        </Col>
        <Col xs={0} md={1}>
          <Tile.Divider />
        </Col>
        <Col xs={12} md={3}>
          <Tile.Label data-testid="start-date">Start Date</Tile.Label>
          <div>{formatDate(lease?.startDate)}</div>
        </Col>
        <Col xs={12} md={3}>
          <Tile.Label data-testid="end-date">End Date</Tile.Label>
          <div>{formatDate(lease?.endDate)}</div>
        </Col>
        <Col xs={12} md={3}>
          <Tile.Label data-testid="duration">Duration</Tile.Label>
          <div>{calculateDuration(lease?.startDate, lease?.endDate)}</div>
        </Col>
        <Col xs={12} md={3}>
          <Tile.Label data-testid="rent-price">Rent Price</Tile.Label>
          <div>{printDollarsFromCents(lease?.rent) || "N/A"}</div>
        </Col>
        <Col xs={12} md={3}>
          <Tile.Label data-testid="security-deposit">
            Security Deposit
          </Tile.Label>
          <div>{printDollarsFromCents(lease?.securityDeposit) || "N/A"}</div>
        </Col>
      </Row>
    </Tile.Inner>
  </PropertyContainer>
);

export default LeaseProperty;
