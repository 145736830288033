import React, { useRef, useEffect, useCallback, useState } from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useParams, useHistory } from "react-router-dom";

import { LEASE_APP_STATUSES_TO_SIGN } from "renter/config/constants";
import ROUTES from "renter/config/routes";
import { useLeaseApplication, useProcessSignature } from "renter/hooks/api";
import { useRenterHelloSign } from "renter/hooks/useRenterHelloSign";
import PreventLeaveModal from "shared/components/PreventLeaveModal";
import { NOTIFICATIONS } from "shared/config/constants";
import Layout from "shared/templates/Layout";
import SignLeaseLayout from "shared/templates/SignLeaseLayout";
import { transformRoute } from "shared/utils/routing";
import { openNotification } from "shared/utils/ui";

const LeaseSignContainer = ({ sidebar, header }) => {
  const history = useHistory();
  const helloSignContainer = useRef();
  const { leaseApplicationId, leaseId } = useParams();
  const [shouldPrevent, setPrevent] = useState(false);

  const { data: leaseApplication, isLoading: loading } = useLeaseApplication({
    leaseApplicationId,
  });

  const unit = leaseApplication?.lease?.deal?.unit;
  const property = unit?.property;

  const unitName = unit?.name;
  const propertyName = property?.name;
  const propertyAddress = property?.address;
  const propertyState = property?.state;
  const propertyZipcode = property?.zipcode;

  const fullAddress = `${propertyName}, ${unitName}, ${propertyAddress}, ${propertyState}, ${propertyZipcode}`;

  const { processSignature } = useProcessSignature({
    onSuccess: () => {
      history.push(transformRoute(ROUTES.leases));
    },
  });

  const onSign = useCallback(
    (data, helloSignClient) => {
      setPrevent(false);
      helloSignClient.close();
      openNotification(
        `Congratulations! You've successfully signed the lease`,
        NOTIFICATIONS.info
      );
      processSignature({ leaseApplicationId });
    },
    [leaseApplicationId]
  );

  const {
    signatureUrl,
    isSignatureUrlAvailable,
    isSignatureUrlLoading,
    openManagerHelloSign,
  } = useRenterHelloSign({
    leaseApplicationId,
    enableToFetch: LEASE_APP_STATUSES_TO_SIGN.includes(
      leaseApplication?.status
    ),
    onSign,
  });

  useEffect(() => {
    if (!isSignatureUrlLoading && !isSignatureUrlAvailable) {
      openNotification(
        `E-Signing is not available for this application`,
        NOTIFICATIONS.error
      );
    }
  }, [isSignatureUrlLoading, isSignatureUrlAvailable]);

  useEffect(() => {
    if (isSignatureUrlAvailable && helloSignContainer?.current) {
      setPrevent(true);
      openManagerHelloSign(signatureUrl, helloSignContainer);
    }
  }, [isSignatureUrlAvailable, openManagerHelloSign, helloSignContainer]);

  return (
    <Layout
      sidebar={sidebar}
      header={{
        ...header,
        backLink: {
          children: `${unitName} LEASE`,
          to: transformRoute(ROUTES.lease, {
            leaseApplicationId,
            leaseId,
          }),
        },
      }}
    >
      <Helmet>
        <title>Lease Signing</title>
      </Helmet>

      <SignLeaseLayout
        isLoading={loading || isSignatureUrlLoading}
        title="Lease signing"
        subtitle={fullAddress}
        helloSignContainer={helloSignContainer}
      />

      <PreventLeaveModal
        title="Exit the lease signing process?"
        subtitle={`Unit: ${unitName}, ${propertyName}`}
        cancelText="Cancel"
        confirmText="Exit"
        preventLeaveWhen={shouldPrevent}
      />
    </Layout>
  );
};

LeaseSignContainer.propTypes = {
  sidebar: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired,
};

export default LeaseSignContainer;
