import * as Yup from "yup";

import {
  AMOUNT_RENT_CHANGE,
  PERCENT_RENT_CHANGE,
} from "manager/config/constants";
import { RentChangeOptions } from "shared/config/constants";
import { RenewalOption } from "shared/interfaces/api/renewals";
import { SelectItem } from "shared/interfaces/misc";
import { printDollarsFromCents } from "shared/utils/dollar-print";

export const INITIAL_VALUES = {};

type KeyOfOption = keyof RenewalOption;

export const OFFER_OPTION_FIELDS: Partial<{
  [key in KeyOfOption]: KeyOfOption;
}> = Object.freeze({
  durationInMonths: "durationInMonths",
  rentChangeOption: "rentChangeOption",
  rentChangeValue: "rentChangeValue",
});

export const getValidationSchema = ({ rent }: { rent: number }) => {
  const basicRentChangeValueSchema = Yup.number()
    .required("The change amount is required")
    .min(0, "The change amount must be greater than zero")
    .nullable();

  return Yup.object().shape({
    renewalOptions: Yup.array()
      .of(
        Yup.object().shape<Partial<{ [key in KeyOfOption]: Yup.BaseSchema }>>({
          [OFFER_OPTION_FIELDS.durationInMonths]: Yup.number()
            .positive("The renewal period must be a positive number")
            .max(1000, "The renewal period exceeds the maximum value")
            .required("The renewal period is required")
            .nullable(),
          [OFFER_OPTION_FIELDS.rentChangeOption]: Yup.string()
            .oneOf([...Object.values(RentChangeOptions), null])
            .required("The change type is required")
            .nullable(),
          [OFFER_OPTION_FIELDS.rentChangeValue]: Yup.number()
            .transform((_, val) => (val ? Number(val) : null))
            .nullable()
            .when(OFFER_OPTION_FIELDS.rentChangeOption, {
              is: (rentChangeOption: RentChangeOptions) =>
                AMOUNT_RENT_CHANGE.has(rentChangeOption),
              then: basicRentChangeValueSchema,
            })
            .when(OFFER_OPTION_FIELDS.rentChangeOption, {
              is: (rentChangeOption: RentChangeOptions) =>
                PERCENT_RENT_CHANGE.has(rentChangeOption),
              then: basicRentChangeValueSchema,
            })
            .when(OFFER_OPTION_FIELDS.rentChangeOption, {
              is: RentChangeOptions.AMOUNT_DECREASE,
              then: basicRentChangeValueSchema.lessThan(
                rent,
                `The change amount must be less than rent value ${printDollarsFromCents(
                  rent
                )}`
              ),
            })
            .when(OFFER_OPTION_FIELDS.rentChangeOption, {
              is: RentChangeOptions.PERCENT_DECREASE,
              then: basicRentChangeValueSchema.lessThan(
                100,
                "The change amount must be less than 100%"
              ),
            }),
        })
      )
      .min(1, "At lease one offer option is required for approval."),
  });
};

export const RENT_CHANGE_OPTIONS: SelectItem[] = [
  {
    label: "No Change",
    key: RentChangeOptions.NO_CHANGE,
  },
  {
    label: "(%) Increase",
    key: RentChangeOptions.PERCENT_INCREASE,
  },
  {
    label: "(%) Decrease",
    key: RentChangeOptions.PERCENT_DECREASE,
  },
  {
    label: "($) Increase",
    key: RentChangeOptions.AMOUNT_INCREASE,
  },
  {
    label: "($) Decrease",
    key: RentChangeOptions.AMOUNT_DECREASE,
  },
];

export const EMPTY_OPTION: Partial<RenewalOption> = {
  durationInMonths: undefined,
  rentChangeOption: undefined,
  rentChangeValue: undefined,
};

export enum FootBarState {
  NO_ACTIONS = "NO_ACTIONS",
  IN_PROGRESS = "IN_PROGRESS",
  WAITING_APPROVAL = "WAITING_APPROVAL",
  APPROVED = "APPROVED",
}
