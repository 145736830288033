import React from "react";

import { Document } from "admin/pages/Documents/interfaces";
import { useLeasePreview } from "manager/hooks/api";

import PreviewDocumentModal from "./PreviewDocumentModal";

interface PreviewLeaseModalContainerProps {
  document: Document;
  openBluemoonCountersignModal: () => void;
}

const PreviewLeaseModalContainer = ({
  document,
  openBluemoonCountersignModal,
}: PreviewLeaseModalContainerProps) => {
  const { isLoading, data: base64 } = useLeasePreview(
    { leaseId: document.id },
    { enabled: Boolean(document.id) }
  );

  return (
    <PreviewDocumentModal
      filename={document.name}
      base64={base64}
      loading={isLoading}
      bluemoonCountersign={openBluemoonCountersignModal}
    />
  );
};

export default PreviewLeaseModalContainer;
