import { queryCache, useMutation, useQuery } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { useNotFoundErrorHandler } from "shared/utils/queries.utils";
import { openNotification } from "shared/utils/ui";

export const GET_LEASE_GROUPS = "getLeaseGroups";
export const GET_LEASE_GROUP_APPLICATIONS = "getLeaseGroupApplications";
export const GET_LEASE_GROUP_ATTACHMENTS = "getLeaseGroupAttachments";

const QUERY_KEYS = Object.freeze({
  getLeaseGroups: () => [GET_LEASE_GROUPS],
  getLeaseGroupById: (id: number) => [GET_LEASE_GROUPS, id],
  getLeaseGroupApplications: (id: number) => [GET_LEASE_GROUP_APPLICATIONS, id],
  getLeaseGroupAttachments: (id: number) => [GET_LEASE_GROUP_ATTACHMENTS, id],
});

export const invalidateLeaseGroupApplicationsCache = () => {
  queryCache.invalidateQueries(GET_LEASE_GROUP_APPLICATIONS);
};

export const useGetLeaseGroups = () => {
  const { data: leaseGroups, isLoading: isLeaseGroupsLoading } = useQuery(
    QUERY_KEYS.getLeaseGroups(),
    () => api.getLeaseGroups(),
    {
      onError: () => {
        openNotification("Failed to load lease groups", NOTIFICATIONS.error);
      },
    }
  );

  return {
    leaseGroups,
    isLeaseGroupsLoading,
  };
};

export const useGetLeaseGroupById = (id: number) => {
  const notFoundErrorHandler = useNotFoundErrorHandler();

  const { data: leaseGroup, isLoading: isLeaseGroupLoading } = useQuery(
    QUERY_KEYS.getLeaseGroupById(id),
    () => api.getLeaseGroupById(id),
    {
      enabled: Boolean(id),
      forceFetchOnMount: true,
      onError: (error) => {
        if (!notFoundErrorHandler(error)) {
          openNotification("Failed to load the renewal", NOTIFICATIONS.error);
        }
      },
      retry: false,
    }
  );
  return {
    leaseGroup,
    isLeaseGroupLoading,
  };
};

export const useGetLeaseGroupApplications = (leaseGroupId: number) => {
  const {
    data: leaseGroupApplications,
    isLoading: isLeaseGroupApplicationsLoading,
  } = useQuery(
    QUERY_KEYS.getLeaseGroupApplications(leaseGroupId),
    () => api.getLeaseGroupApplications(leaseGroupId),
    {
      enabled: Boolean(leaseGroupId),
      forceFetchOnMount: true,
      onError: () =>
        openNotification(
          "Failed to load lease group applications",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    leaseGroupApplications,
    isLeaseGroupApplicationsLoading,
  };
};

export const useGetLeaseGroupAttachments = (leaseGroupId: number) => {
  const {
    data: leaseGroupAttachments,
    isLoading: isLeaseGroupAttachmentsLoading,
  } = useQuery(
    QUERY_KEYS.getLeaseGroupAttachments(leaseGroupId),
    () => api.getLeaseGroupAttachments(leaseGroupId),
    {
      enabled: Boolean(leaseGroupId),
      onError: () =>
        openNotification(
          "Failed to load lease group attachments",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    leaseGroupAttachments,
    isLeaseGroupAttachmentsLoading,
  };
};

export const useCreateLeaseGroupAttachment = () => {
  const [
    createLeaseGroupAttachment,
    { isLoading: isCreatingLeaseGroupAttachments },
  ] = useMutation(api.createLeaseGroupAttachment, {
    onSuccess: (_, request) => {
      queryCache.invalidateQueries(
        QUERY_KEYS.getLeaseGroupAttachments(request.leaseGroupId)
      );
    },
    onError: () =>
      openNotification(
        "Failed to create lease group attachment",
        NOTIFICATIONS.error
      ),
  });

  return {
    createLeaseGroupAttachment,
    isCreatingLeaseGroupAttachments,
  };
};

export const useEditLeaseGroupAttachment = () => {
  const [
    editLeaseGroupAttachment,
    { isLoading: isEditingLeaseGroupAttachment },
  ] = useMutation(api.editLeaseGroupAttachment, {
    onSuccess: (_, request) => {
      queryCache.invalidateQueries(
        QUERY_KEYS.getLeaseGroupAttachments(request.leaseGroupId)
      );
    },
    onError: () =>
      openNotification(
        "Failed to edit lease group attachment",
        NOTIFICATIONS.error
      ),
  });

  return {
    editLeaseGroupAttachment,
    isEditingLeaseGroupAttachment,
  };
};

export const useDeleteLeaseGroupAttachment = () => {
  const [
    deleteLeaseGroupAttachment,
    { isLoading: isDeletingLeaseGroupAttachment },
  ] = useMutation(api.deleteLeaseGroupAttachment, {
    onSuccess: (_, request) => {
      queryCache.invalidateQueries(
        QUERY_KEYS.getLeaseGroupAttachments(request.leaseGroupId)
      );
    },
    onError: () =>
      openNotification(
        "Failed to delete lease group attachment",
        NOTIFICATIONS.error
      ),
  });

  return {
    deleteLeaseGroupAttachment,
    isDeletingLeaseGroupAttachment,
  };
};
