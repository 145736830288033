import PropTypes from "prop-types";

import { TOTAL_FIRST_MONTH_RENT_FIELD, TOTAL_RENT_FIELD } from "../constants";

export const feeFieldsPropType = PropTypes.shape({
  charges: PropTypes.arrayOf(PropTypes.string),
  rentableItems: PropTypes.arrayOf(PropTypes.string),
});

export const totalRentCalcFieldsPropType = PropTypes.shape({
  [TOTAL_RENT_FIELD]: PropTypes.object,
  [TOTAL_FIRST_MONTH_RENT_FIELD]: PropTypes.object,
});
