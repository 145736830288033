import React from "react";

import { useFormikContext } from "formik";
import capitalize from "lodash/capitalize";
import PropTypes from "prop-types";

import DraggableTable from "shared/components/DraggableTable";
import FlexContainer from "shared/components/FlexContainer";
import { Checkbox } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { DOCUMENT_TYPES } from "shared/config/constants/app.constants";

import { StyledSkeleton as Skeleton, ApplyLink, EditGreenIcon } from "./styled";

const DocumentTemplates = ({
  openAssignToModal,
  previewDocument,
  updateDocumentOrdering,
  isOrderingLoading,
  resetOrderingForProperty,
  isLoadingReset,
  loading,
  editRiderType,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleOnChange = (id, checked) => {
    const index = values.documentTemplates?.findIndex((item) => item.id === id);
    setFieldValue(`documentTemplates[${index}].isDefault`, checked);
  };

  const onDragChange = (orders) => {
    updateDocumentOrdering(
      orders.map((item, index) => ({
        documentTemplateId: item.key,
        sortingIdx: index,
      }))
    );
  };

  const Action = (
    <FlexContainer alignItems="center">
      <ApplyLink
        onClick={() => resetOrderingForProperty()}
        disabled={isOrderingLoading || isLoadingReset}
      >
        Apply order to all setups
      </ApplyLink>
      <Tooltip
        theme="light"
        title="Once you confirm, the default documents at the property level
               will be prioritized in the way you’ve set at the company level.
               However you will still be able to change their order when generating a lease."
        placement="topRight"
      >
        <Icon.InfoIcon />
      </Tooltip>
    </FlexContainer>
  );

  return (
    <Tile
      header={{
        title: "Document Templates",
        subtitle:
          "Assign documents to properties, select defaults, and reorder them in a preferable way. ",
        primaryAction: Action,
      }}
      size="lg"
    >
      <Tile.Inner size="sm">
        <Skeleton loading={loading || !values.documentTemplates}>
          <DraggableTable
            columns={[
              {
                title: "Name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Type",
                dataIndex: "typeDisplayName",
                key: "typeDisplayName",
              },
              {
                title: "Available in Properties",
                dataIndex: "propertiesAssigned",
                key: "propertiesAssigned",
              },
            ]}
            columnBreakpoints={{
              xs: {
                column1: "name",
                column2: "typeDisplayName",
              },
              sm: ["name", "typeDisplayName"],
              md: ["name", "typeDisplayName", "propertiesAssigned"],
            }}
            placeholder={{
              Icon: Icon.NoDataIcon,
              label: "Documents",
            }}
            onDragChange={onDragChange}
            loading={isOrderingLoading}
            showActionColumn
            dropdownItems
            pagination={false}
            noClickableRows
            rows={values.documentTemplates?.map((document, index) => {
              return {
                key: document.id,
                draggableItem: true,
                values: [
                  <UnderlineLink onClick={() => previewDocument(document)}>
                    {document.name}
                  </UnderlineLink>,
                  <FlexContainer alignItems="center">
                    <span data-testid={`document-setup-type-${document.id}`}>
                      {document.type === DOCUMENT_TYPES.rider
                        ? `${document.typeDisplayName} (${capitalize(
                            document.riderTypeName
                          )})`
                        : document.typeDisplayName}
                    </span>
                    <span>
                      {document.type === DOCUMENT_TYPES.rider ? (
                        <EditGreenIcon
                          data-testid="set-rider-type"
                          onClick={() => editRiderType(document)}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </FlexContainer>,
                  <span>
                    <Tooltip
                      theme="light"
                      title={
                        document?.numAssignedProperties > 0 ? (
                          <ul>
                            {document?.properties?.map((property) => (
                              <li key={property.id}>{property.name}</li>
                            ))}
                          </ul>
                        ) : (
                          "N/A"
                        )
                      }
                    >
                      {document?.numAssignedProperties > 0 ? (
                        <UnderlineLink>
                          {document.numAssignedProperties}
                        </UnderlineLink>
                      ) : (
                        "N/A"
                      )}
                    </Tooltip>
                  </span>,
                ],
                dropdownItems: [
                  {
                    key: "MANAGE",
                    label: "Manage Availability",
                    onClick: () => {
                      openAssignToModal(document);
                    },
                  },
                ],
                primaryAction: (
                  <Checkbox
                    name={`documentTemplates[${index}].isDefault`}
                    id={`documentTemplates[${index}].isDefault`}
                    label="Default Document"
                    checked={document.isDefault}
                    onChange={() =>
                      handleOnChange(document.id, !document.isDefault)
                    }
                  />
                ),
              };
            })}
          />
        </Skeleton>
      </Tile.Inner>
    </Tile>
  );
};

DocumentTemplates.propTypes = {
  openAssignToModal: PropTypes.func.isRequired,
  previewDocument: PropTypes.func.isRequired,
  updateDocumentOrdering: PropTypes.func.isRequired,
  isOrderingLoading: PropTypes.bool.isRequired,
  resetOrderingForProperty: PropTypes.func.isRequired,
  isLoadingReset: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  editRiderType: PropTypes.func.isRequired,
};

DocumentTemplates.defaultProps = {
  loading: false,
};

export default DocumentTemplates;
