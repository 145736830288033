import { useEffect } from "react";

import { useFormikContext } from "formik";
import isEmpty from "lodash/isEmpty";

import { scrollToErrorSection } from "shared/utils/ui";

const FormErrors = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();
  useEffect(() => {
    if (isSubmitting && !isValidating) {
      if (!isEmpty(errors)) {
        scrollToErrorSection(errors);
      }
    }
  }, [errors, isSubmitting, isValidating]);
  return null;
};

export default FormErrors;
