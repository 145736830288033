import styled from "@emotion/styled";
import { Typography } from "antd";

import { ActionLink as BaseActionLink } from "shared/components/Links";

const { Text } = Typography;

export const Bar = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  padding: "23px 20px",
});

export const Title = styled(Text)({
  flex: 2,
  fontSize: 15,
  fontWeight: 500,
  lineHeight: 1,
  maxWidth: "max-content",
  marginRight: 30,
});

export const ActionLink = styled(BaseActionLink)({
  lineHeight: 1,
  flex: 1,
});
