import styled from "@emotion/styled";

import Tile from "shared/components/Tile";
import { COLORS } from "shared/config/constants";
import { AlignedCenterFlexPartial } from "shared/styles/commonStyles";

const BORDER_RADIUS = 4;

export const StyledTile = styled(Tile)`
  margin-bottom: 8px;
  border-radius: ${BORDER_RADIUS}px;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.accepted-option {
    background-color: ${COLORS.lightGreen3};
  }

  .tile-inner {
    display: flex;
    padding: 16px;
  }

  .option-info-row {
    flex: 1;

    .option-info-col {
      .label {
        display: block;
        margin-bottom: 8px;
      }

      .rent-change-text {
        &.increase {
          color: ${COLORS.accentDark};
        }

        &.decrease {
          color: ${COLORS.darkOrange};
        }
      }
    }
  }

  .option-gray-cover {
    position: absolute;
    background-color: ${COLORS.lightGray2};
    opacity: 0.9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${BORDER_RADIUS}px;
  }

  .concession-value {
    ${AlignedCenterFlexPartial};
    justify-content: space-between;
    width: 90px;

    svg {
      min-width: 16px;
      margin-left: 6px;
    }
  }

  .action-col {
    width: 40px;
    ${AlignedCenterFlexPartial};
    justify-content: flex-end;
  }
`;
