import React from "react";

import { Col, Row } from "antd";

import ROUTES from "manager/config/routes";
import { LeaseGroup } from "manager/interfaces/api/leaseGroups";
import { LeaseName } from "manager/interfaces/types";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { IconLink } from "shared/components/Links";
import Tile from "shared/components/Tile";
import { RegularText, SmallText } from "shared/components/Typography";
import Well from "shared/components/Well";
import { LEASE_EXECUTION_STATUSES } from "shared/config/constants";
import { transformRoute } from "shared/utils/routing";
import { formatDate } from "shared/utils/ui";

import { LeaseStatusCircle, StyledLeaseGroupHistory } from "./styled";
import { getLeaseStatusCircleTheme } from "./utils";

const LeaseGroupHistory = ({ leaseGroup }: { leaseGroup: LeaseGroup }) => {
  return (
    <StyledLeaseGroupHistory
      header={{
        title: <SmallText extraStrong>LEASE HISTORY</SmallText>,
        collapsibleOnDesktop: true,
        collapsibleOnMobile: true,
        withoutBorderBottom: true,
      }}
      noMarginBottom
    >
      <Tile.Inner noPaddingTop>
        {leaseGroup?.history?.map((lease) => {
          const isCurrentLease =
            lease.name === LeaseName.CurrentLease ||
            lease.name === LeaseName.ExpiringLease;
          const isRenewalLease = lease.name === LeaseName.RenewalLease;
          const isLeaseFullyExecuted = LEASE_EXECUTION_STATUSES.has(
            lease?.statusCode
          );
          const leaseStatus =
            isCurrentLease && isLeaseFullyExecuted ? "Active" : lease.status;

          return (
            <Well noBorder key={lease.id} className="lease-history-row">
              <FlexContainer alignItems="center">
                <Row
                  className="lease-history-details"
                  type="flex"
                  align="middle"
                  gutter={[32, 32]}
                >
                  <Col lg={12} xxl={8}>
                    <FlexContainer alignItems="center">
                      <Icon.PDFPreviewIconGray
                        width="28px"
                        height="32px"
                        className="pdf-icon"
                      />
                      <RegularText>
                        {lease.name}
                        <br />
                        <SmallText light>
                          {`${formatDate(lease.startDate)} - ${formatDate(
                            lease.endDate
                          )}`}
                        </SmallText>
                      </RegularText>
                    </FlexContainer>
                  </Col>
                  <Col lg={8}>
                    <FlexContainer alignItems="center">
                      <LeaseStatusCircle
                        color={getLeaseStatusCircleTheme({
                          isLeaseFullyExecuted,
                          isRenewalLease,
                        })}
                      />
                      &nbsp; &nbsp;
                      <RegularText>{leaseStatus}</RegularText>
                    </FlexContainer>
                  </Col>
                </Row>
                <IconLink
                  Icon={Icon.SelectArrowIcon}
                  to={transformRoute(ROUTES.leaseSummary, {
                    id: lease.id,
                  })}
                />
              </FlexContainer>
            </Well>
          );
        })}
      </Tile.Inner>
    </StyledLeaseGroupHistory>
  );
};

export default LeaseGroupHistory;
