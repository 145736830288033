import React from "react";

import { Document } from "admin/pages/Documents/interfaces";
import ApplicationSetupHeader from "manager/components/DealSetupSettings/Header";
import { FIELDS_STATUS } from "manager/config/constants";
import DraggableTable from "shared/components/DraggableTable";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Settings from "shared/components/Settings";

import { PropertyDocumentTemplatesProps } from "./interfaces";
import {
  StyledSkeleton as Skeleton,
  StyledSwitch,
  FieldsContainer,
  FieldStatus,
  FieldActionLink,
} from "./styled";

const PropertyDocumentTemplates = ({
  loading,
  disabled,
  openSetupFieldModal,
  previewDocument,
  isOrderingLoading,
  isMobile,
  onDragChange,
  redirectToSetupFields,
  propertyId,
  handleOnChange,
  documents,
  values,
}: PropertyDocumentTemplatesProps) => {
  const handleFieldStatus = (document: Document) => {
    const props = {
      document,
      propertyId,
      leaseSetup: values,
    };
    switch (document.fieldsStatus) {
      case FIELDS_STATUS.AS:
        return (
          <>
            <Icon.CheckIcon />
            <FieldStatus>All Set</FieldStatus>
            <FieldActionLink
              onClick={() => openSetupFieldModal(props)}
              data-testid="edit-fields"
            >
              Edit
            </FieldActionLink>
          </>
        );
      case FIELDS_STATUS.PS:
        return (
          <>
            <Icon.FieldPartiallySet />
            <FieldStatus>Partially set</FieldStatus>
            <FieldActionLink
              onClick={() => openSetupFieldModal(props)}
              data-testid="edit-fields"
            >
              Edit
            </FieldActionLink>
          </>
        );
      case FIELDS_STATUS.SF:
        return (
          <FieldActionLink
            onClick={() => openSetupFieldModal(props)}
            data-testid="setup-fields"
          >
            Setup fields
          </FieldActionLink>
        );
      case FIELDS_STATUS.NS:
      default:
        return (
          <>
            <Icon.CheckIcon />
            <FieldStatus>No setup needed</FieldStatus>
          </>
        );
    }
  };

  const renderSwitchAction = ({ id, isActive }: Document, index: number) => (
    <StyledSwitch
      // @ts-ignore
      id={`documentTemplates.documentTemplates[${index}].isActive`}
      checked={isActive}
      disabled={disabled}
      onChange={() => handleOnChange(id, !isActive)}
    />
  );

  return (
    <Settings
      header={
        <Skeleton loading={loading}>
          <ApplicationSetupHeader
            title="Document Templates"
            subTitle="Please activate the documents you’re going to use for this property and setup fields for them"
            actionLink={isMobile ? undefined : "Setup all fields"}
            disableLink={documents?.disableSetupFields}
            tooltip="Looks like the documents for this property have no fields to setup"
            actionLinkClick={redirectToSetupFields}
          />
        </Skeleton>
      }
      nodes={[
        {
          key: 1,
          component: (
            <Skeleton loading={loading || !documents}>
              {/* @ts-ignore */}
              <DraggableTable
                columns={[
                  {
                    title: "Name",
                    dataIndex: "name",
                    key: "name",
                  },
                  {
                    title: "Type",
                    dataIndex: "typeDisplayName",
                    key: "typeDisplayName",
                  },
                  {
                    title: "Default",
                    dataIndex: "default",
                    key: "default",
                  },
                  {
                    title: "Fields",
                    dataIndex: "fields",
                    key: "fields",
                  },
                  {
                    title: "Active",
                    dataIndex: "isActive",
                    key: "isActive",
                  },
                ]}
                columnBreakpoints={{
                  xs: {
                    column1: "name",
                    column2: "fields",
                  },
                  sm: ["name", "fields", "isActive"],
                  md: ["name", "fields", "isActive"],
                }}
                placeholder={{
                  Icon: Icon.NoDataIcon,
                  label: "Documents",
                }}
                loading={isOrderingLoading}
                showColumnTitles={false}
                pagination={false}
                noClickableRows
                onDragChange={onDragChange}
                rows={(documents?.documentTemplates || []).map(
                  (document, index) => {
                    return {
                      key: document.id,
                      draggableItem: true,
                      inlinePrimaryAction: renderSwitchAction(document, index),
                      values: [
                        <UnderlineLink
                          data-testid={`document-setup-name-${document.id}`}
                          onClick={() => previewDocument(document)}
                        >
                          {document.name}
                        </UnderlineLink>,
                        <span
                          data-testid={`document-setup-type-${document.id}`}
                        >
                          {document.typeDisplayName}
                        </span>,
                        <span
                          data-testid={`document-setup-default-${document.id}`}
                        >
                          {document.isDefault ? "Yes" : "No"}
                        </span>,
                        <FieldsContainer
                          data-testid={`document-setup-fields-${document.id}`}
                        >
                          {handleFieldStatus(document)}
                        </FieldsContainer>,
                        <div
                          data-testid={`document-setup-active-${document.id}`}
                        >
                          {renderSwitchAction(document, index)}
                        </div>,
                      ],
                    };
                  }
                )}
              />
            </Skeleton>
          ),
        },
      ]}
      hasComponentPadding={false}
    />
  );
};

export default PropertyDocumentTemplates;
