import React, { useRef } from "react";

import { FormikProps } from "formik";
import { useHistory } from "react-router-dom";

import { NOTIFICATIONS } from "shared/config/constants";
import ROUTES from "shared/config/routes";

import { useLogin } from "shared/hooks";
import {
  useGetPactSafeConfig,
  useGetSSOConfig,
  useLoginAuth,
  useGetValidateSetPasswordToken,
  useSetPassword,
} from "shared/hooks/api";
import { openNotification } from "shared/utils/ui";

import { LOGIN_STATUS_TYPE } from "./config";
import { SetPasswordError, SetPasswordValue } from "./interfaces";
import SetPasswordPage from "./SetPasswordPage";

interface SetPasswordPageConainterProps {
  token: string;
  onSuccessLogin: () => void;
  applicationId?: string | string[];
}

const SetPasswordPageConainter = ({
  token,
  onSuccessLogin,
  applicationId,
}: SetPasswordPageConainterProps) => {
  const history = useHistory();
  const formikRef = useRef<FormikProps<SetPasswordValue>>();

  const { ssoConfig, isSSOConfigLoading } = useGetSSOConfig();
  const { pactSafeConfig, isPactSafeConfigLoading } = useGetPactSafeConfig({
    token,
  });
  const { setPassword } = useSetPassword();
  const { loginAuth } = useLoginAuth();

  const onErrorLogin = (error) => {
    openNotification(
      !!error ? error?.toString() : "Failed to login automatically.",
      NOTIFICATIONS.error
    );
  };

  const loginCall = () => {
    const { email, password }: any = formikRef?.current?.values ?? {};

    return loginAuth({ email, password });
  };

  const { login } = useLogin({
    loginCall,
  });

  const submit = async (values: SetPasswordValue) =>
    setPassword({
      token,
      password: values.password,
    })
      .then(login)
      .then(onSuccessLogin)
      .catch(onErrorLogin);

  const onError = (data: SetPasswordError) => {
    const { status } = data.errors;

    if (status === LOGIN_STATUS_TYPE.INVITATION_EXPIRED) {
      history.push(ROUTES.setPasswordLinkExpired.replace(":token", token));
    }

    if (status === LOGIN_STATUS_TYPE.INVITATION_ALREADY_ACCEPTED) {
      history.push(ROUTES.login, { showInfoBanner: true });
    }
  };

  const { passwordToken, isPasswordTokenLoading } =
    useGetValidateSetPasswordToken(token, onError);

  const preValidationSSOButton = () => {
    const { values, setFieldError, setTouched, touched } =
      formikRef.current || {};
    let newTouched = touched ?? {};
    if (!values?.termsOfService) {
      setFieldError?.(
        "termsOfService",
        "The terms of service must be accepted."
      );
      newTouched = {
        ...newTouched,
        termsOfService: true,
      };
      setTouched?.({
        ...newTouched,
        password: false,
        passwordConfirmation: false,
      });
    }
    return values?.termsOfService;
  };

  return (
    <SetPasswordPage
      token={token}
      submit={submit}
      email={passwordToken?.email}
      loading={isPasswordTokenLoading}
      error={passwordToken?.error}
      ssoConfig={ssoConfig}
      isSSOConfigLoading={isSSOConfigLoading}
      pactSafeGroupKey={pactSafeConfig?.termsOfServiceAllGroupKey}
      isPactSafeConfigLoading={isPactSafeConfigLoading}
      formikRef={formikRef}
      preValidationSSOButton={preValidationSSOButton}
      applicationId={applicationId}
    />
  );
};

export default SetPasswordPageConainter;
