import React from "react";

import { Col } from "antd";
import PropTypes from "prop-types";

import { MODALS } from "manager/config/constants";
import List from "shared/components/List";
import { Modal } from "shared/components/Modal";
import Tile from "shared/components/Tile";
import { RegularText } from "shared/components/Typography";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { breakpointPropType } from "shared/utils/propTypes";
import { formatDateTime, formatPhone } from "shared/utils/ui";

import { Row } from "./styled";

const ResendLeaseModal = ({
  breakpoint,
  unitName,
  propertyName,
  applicants,
  lastReminderAt,
  resendLease,
  isLoading,
  isLastReminderAuto,
}) => {
  const lastReminderText =
    lastReminderAt &&
    `${formatDateTime(lastReminderAt)}${
      isLastReminderAuto ? " (automated)" : ""
    }`;

  const lastReminder = lastReminderText ? (
    <span data-testid="last-reminder">
      Last reminder: <RegularText strong>{lastReminderText}</RegularText>
    </span>
  ) : (
    "No reminders have been sent yet"
  );

  return (
    <Modal
      title="Send Reminder To Sign Lease"
      submitButtonLabel="Send reminder"
      footer={lastReminder}
      id={MODALS.resendLease}
      subtitle={
        <span data-testid="subtitle">
          Unit: <strong>{`${unitName}, ${propertyName}`}</strong>
        </span>
      }
      submit={resendLease}
      submitting={isLoading}
      width="auto"
      style={{ maxWidth: 776 }}
      fullScreen={BREAKPOINT_TYPES.xs === breakpoint}
    >
      <Modal.Body>
        <List>
          {applicants.map((applicant) => (
            <List.Item key={applicant.id}>
              <Row type="flex" gutter={[{ sm: 0, md: 20 }, 20]}>
                <Col
                  sm={{ span: 8 }}
                  xs={{ span: 24 }}
                  data-testid={`applicant-name-${applicant.id}`}
                >
                  <Tile.Label>Name</Tile.Label>
                  {applicant.firstName} {applicant.lastName}
                </Col>
                <Col
                  md={{ span: 10 }}
                  xs={{ span: 24 }}
                  data-testid={`applicant-email-${applicant.id}`}
                >
                  <Tile.Label>Email</Tile.Label>
                  {applicant.email}
                </Col>
                <Col
                  md={{ span: 6 }}
                  xs={{ span: 24 }}
                  data-testid={`applicant-phone-${applicant.id}`}
                >
                  <Tile.Label>Phone Number</Tile.Label>
                  {formatPhone(applicant.phoneNumber)}
                </Col>
              </Row>
            </List.Item>
          ))}
        </List>
      </Modal.Body>
    </Modal>
  );
};

ResendLeaseModal.propTypes = {
  breakpoint: breakpointPropType.isRequired,
  propertyName: PropTypes.string.isRequired,
  unitName: PropTypes.string.isRequired,
  applicants: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
    })
  ).isRequired,
  lastReminderAt: PropTypes.string,
  resendLease: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLastReminderAuto: PropTypes.bool.isRequired,
};

ResendLeaseModal.defaultProps = {
  lastReminderAt: undefined,
};

export default withBreakpoint(ResendLeaseModal);
