import {
  useGetCustomEmailConfigurations,
  useUpdateRenewalsSettings,
} from "manager/hooks/api";
import {
  CustomEmailTemplate,
  DefaultEmailType,
} from "manager/interfaces/api/customEmailTemplates";
import { RenewalsSettings } from "manager/interfaces/api/renewalsSettings";
import { usePermissions } from "shared/hooks";

import { renewalsInitialValues } from "./utils";

const useRenewalsSettingsHandlers = ({
  renewalsSettings,
  isRenewalsSettingsLoading,
}: {
  renewalsSettings?: RenewalsSettings;
  isRenewalsSettingsLoading: boolean;
}) => {
  const { updateRenewalsSettings } = useUpdateRenewalsSettings();
  const { isEmailCustomizationEnabled, canCustomizeEmails } = usePermissions();
  const { customEmailConfigurations, isCustomConfigurationsLoading } =
    useGetCustomEmailConfigurations(
      {
        typeName: DefaultEmailType.LEASE_RENEWAL_NOTICE,
      },
      {
        enabled: isEmailCustomizationEnabled && canCustomizeEmails,
      }
    );

  const saveChanges = (payload: RenewalsSettings) =>
    updateRenewalsSettings({
      settingsId: renewalsSettings.id,
      payload,
    });

  const initialValues: RenewalsSettings = {
    ...renewalsInitialValues,
    ...renewalsSettings,
  };

  return {
    initialValues,
    emailConfigurations: customEmailConfigurations as CustomEmailTemplate[],
    saveChanges,
    isLoading: isRenewalsSettingsLoading || isCustomConfigurationsLoading,
  };
};

export default useRenewalsSettingsHandlers;
