import { CheckboxChangeEvent } from "antd/lib/checkbox";
import get from "lodash/get";

import { Lease } from "manager/interfaces/api/lease";
import {
  BaseFeeConfiguration,
  CalculateLeaseTotalRentModel,
} from "manager/interfaces/api/leaseFeeConfiguration";

import { LeaseFieldsStepValues } from "../../../interfaces";

type FeeType = "charges" | "rentableItems";
type FormikSetFieldValue = (
  name: string,
  value: any,
  shouldValidate?: boolean
) => void;

const getOnFeeItemSelect =
  (
    type: FeeType,
    setFieldValue: FormikSetFieldValue,
    values: LeaseFieldsStepValues
  ) =>
  (event: CheckboxChangeEvent, index: number) => {
    const {
      target: { checked },
    } = event;

    const path = `feeConfiguration.${type}[${index}]`;
    const item: BaseFeeConfiguration = get(values, path);
    const updatedItem: BaseFeeConfiguration = checked
      ? {
          ...item,
          isSelected: checked,
        }
      : {
          ...item,
          name: "",
          customAmount: item.baseAmount,
          appliedToTotalRent: false,
          quantity: 1,
          isSelected: checked,
        };

    setFieldValue(path, updatedItem);
  };

export const getApplyToTotalRentOnNameChange =
  (
    type: FeeType,
    setFieldValue: FormikSetFieldValue,
    values: LeaseFieldsStepValues
  ) =>
  (value: string, index: number) => {
    const path = `feeConfiguration.${type}[${index}]`;
    const item: BaseFeeConfiguration = get(values, path);
    const updatedItem: BaseFeeConfiguration = {
      ...item,
      name: value,
      appliedToTotalRent: Boolean(value),
    };

    setFieldValue(path, updatedItem);
  };

export interface FinalSumItem {
  label: string;
  mobileLabel?: string;
  amount: number;
}

const getFinalSumFeeItems = (
  leaseCalculations: CalculateLeaseTotalRentModel,
  lease: Lease
): FinalSumItem[] => [
  {
    label: "Charges & Rentable Items",
    amount: leaseCalculations?.subTotalWithoutRent,
  },
  {
    label: "Lease Rent",
    amount: lease.rent,
  },
  {
    label: "Updated Monthly Rent",
    mobileLabel: "Updated Rent",
    amount: leaseCalculations?.totalRent,
  },
];

const getFinalProratedFeeItems = (
  leaseCalculations: CalculateLeaseTotalRentModel
): FinalSumItem[] => [
  {
    label: "Total Charges",
    amount: leaseCalculations?.chargesAmount,
  },
  {
    label: "Prorated Rentable Items",
    amount: leaseCalculations?.proratedRentableItemsAmount,
  },
  {
    label: "Prorated Lease Rent",
    amount: leaseCalculations?.firstMonthRent,
  },
  {
    label: "Prorarated First Month’s Rent",
    amount: leaseCalculations?.totalFirstMonthRent,
  },
];

export { getOnFeeItemSelect, getFinalSumFeeItems, getFinalProratedFeeItems };
