import { useQuery } from "react-query";

import api from "shared/lib/api";

export const useHelloSignCredentials = () => {
  const response = useQuery(
    ["getHelloSignCredentials"],
    () => api.getHelloSignCredentials().then(({ data }) => data),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return response;
};
