import React from "react";

import { Button } from "shared/components/Button";

import { LeaseToolsProps } from "../interfaces";

import { Tools } from "./styled";

const LeaseTools = ({
  isSignatureUrlAvailable,
  signLease,
}: LeaseToolsProps) => (
  <Tools>
    <Button
      data-testid="sign-button"
      type="primary"
      disabled={!isSignatureUrlAvailable}
      onClick={signLease}
    >
      Sign the Lease
    </Button>
  </Tools>
);

export default LeaseTools;
