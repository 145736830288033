import styled from "@emotion/styled";

import Icon from "shared/components/Icon";

const SetupContainer = styled.div`
  background-color: fff;
`;

const LoadingSpinner = styled(Icon.LoadingSpinnerGreenLargeIcon)`
  position: absolute;
  left: 50;
  bottom: 50%;
  transform: translateX(-50%);
`;

const Title = styled.p`
  font-weight: 300;
  margin-bottom: 0;
  padding: 35px 0 0 35px;
`;

export { SetupContainer, LoadingSpinner, Title };
