import React from "react";

import { Row, Col } from "antd";
import { useFormikContext, Field } from "formik";
import range from "lodash/range";
import PropTypes from "prop-types";

import { LEASE_REMINDER_MAX_SENDS } from "manager/config/constants";
import { RadioGroup, Select } from "shared/components/Form";
import Settings from "shared/components/Settings";
import { YES_NO_OPTIONS } from "shared/config/constants";
import { showError } from "shared/utils/forms";
import { TypesPropType } from "shared/utils/propTypes";

import ApplicationSetupHeader from "../Header";
import { StyledSkeleton as Skeleton } from "../styled";

import { Container } from "./styled";

const REMINDERS_ENABLED = "autoReminderEnabled";
const REMINDERS_FREQUENCY = "autoReminderFrequency";
const REMINDERS_MAX_SENDS = "autoReminderMaxSends";

const getMaxSendsOptions = () =>
  range(LEASE_REMINDER_MAX_SENDS.min, LEASE_REMINDER_MAX_SENDS.max + 1).map(
    (key) => {
      return (
        <Select.Option key={key} value={key}>
          {key}
        </Select.Option>
      );
    }
  );

const LeaseReminder = ({
  loading,
  disabled,
  frequencies,
  isFrequenciesLoading,
}) => {
  const { values, setFieldValue, setFieldTouched, touched, errors } =
    useFormikContext();
  return (
    <Settings
      header={
        <Skeleton loading={loading}>
          <ApplicationSetupHeader title="Lease Reminder" />
        </Skeleton>
      }
      nodes={[
        {
          key: 1,
          component: (
            <Skeleton loading={loading}>
              <Container>
                <Row gutter={[24, 24]}>
                  <Col lg={24}>
                    <RadioGroup
                      id={REMINDERS_ENABLED}
                      name={REMINDERS_ENABLED}
                      label="Enable automated reminders for applicants that have not signed their lease"
                      options={YES_NO_OPTIONS}
                      value={values[REMINDERS_ENABLED]}
                      onChange={(value) => {
                        setFieldValue(REMINDERS_ENABLED, value);
                      }}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                {values[REMINDERS_ENABLED] && (
                  <>
                    <Row gutter={[24, 24]}>
                      <Col lg={6} md={12}>
                        <Field
                          name={REMINDERS_FREQUENCY}
                          id={REMINDERS_FREQUENCY}
                          label="Select frequency"
                          as={Select}
                          data-testid="frequency-select"
                          onChange={(value) => {
                            setFieldValue(REMINDERS_FREQUENCY, value);
                          }}
                          disabled={disabled}
                          // TODO: hanna - replace with a VeroFormField wrapper
                          onBlur={() =>
                            setFieldTouched(REMINDERS_MAX_SENDS, true)
                          }
                          error={showError(
                            REMINDERS_FREQUENCY,
                            touched,
                            errors
                          )}
                          loading={isFrequenciesLoading}
                        >
                          {frequencies.map(({ id, value }) => (
                            <Select.Option key={id} value={id}>
                              {value}
                            </Select.Option>
                          ))}
                        </Field>
                      </Col>
                      <Col lg={6} md={12}>
                        <Field
                          name={REMINDERS_MAX_SENDS}
                          id={REMINDERS_MAX_SENDS}
                          label="Max Reminders"
                          as={Select}
                          data-testid="max-sends-select"
                          onChange={(value) => {
                            setFieldValue(REMINDERS_MAX_SENDS, value);
                          }}
                          // TODO: hanna - replace with a VeroFormField wrapper
                          onBlur={() =>
                            setFieldTouched(REMINDERS_MAX_SENDS, true)
                          }
                          disabled={disabled}
                          error={showError(
                            REMINDERS_MAX_SENDS,
                            touched,
                            errors
                          )}
                        >
                          {getMaxSendsOptions()}
                        </Field>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Skeleton>
          ),
        },
      ]}
    />
  );
};

LeaseReminder.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  frequencies: TypesPropType,
  isFrequenciesLoading: PropTypes.bool.isRequired,
};

LeaseReminder.defaultProps = {
  loading: false,
  disabled: false,
  frequencies: [],
};

export default LeaseReminder;
