import { StatusCircleThemes } from "../styled";

const getLeaseStatusCircleTheme = ({
  isLeaseFullyExecuted,
  isRenewalLease,
}) => {
  if (isLeaseFullyExecuted) {
    return isRenewalLease
      ? StatusCircleThemes.purple
      : StatusCircleThemes.green;
  }

  return StatusCircleThemes.default;
};

export { getLeaseStatusCircleTheme };
