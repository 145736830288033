import { useMutation, useQuery, queryCache } from "react-query";
import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { useRequestExport } from "manager/hooks/api/commonQueries";
import api from "manager/lib/api";
import { getLeases } from "manager/lib/leaseApi";
import { HTTP_ERROR_CODES, NOTIFICATIONS } from "shared/config/constants";
import { refetchMultipleActiveQueries } from "shared/utils/queries.utils";
import { transformRoute } from "shared/utils/routing";
import { openNotification } from "shared/utils/ui";

const GET_LEASES = "getLeases";
const LEASE_PREVIEW = "leasePreview";
const GET_LEASE_FIELDS = "getLeaseFields";
const GET_LEASE_FIELDS_BLUEMOON = "getLeaseFieldsBluemoon";
const LEASE_VERIFY = "verifyLease";
const GET_LEASE_ASSIGNED_DOCUMENTS = "getLeaseAssignedDocuments";
const GET_LEASE_DOCUMENTS_TEMPLATES = "getLeaseDocumentTemplates";
const GET_LEASE_DOCUMENTS_TEMPLATES_BLUEMOON =
  "getLeaseDocumentTemplatesBluemoon";
const GET_MANAGER_SIGN_URL = "getManagerSignUrl";
const GET_APPLICATION_ANSWERS = "getApplicationAnswers";
const GET_LEASE_AVAILABLE_DOCUMENTS = "getLeaseAvailableDocuments";
const GET_LEASE_AVAILABLE_DOCUMENTS_BLUEMOON =
  "getLeaseAvailableDocumentsBluemoon";
const GET_VALID_CONCESSION_MONTHS = "getValidConcessionMonths";
const CALCULATE_NET_EFFECTIVE_RENT = "calculateNetEffectiveRent";
const GET_LEASE_ADDITIONAL_DOCUMENTS = "getLeaseAdditionalDocuments";
const GET_LEASE_MANAGER_ANSWERS = "getLeasePackagesManagerAnswers";
const GET_LEASE_MANAGER_QUESTIONS = "getLeaseAdditionalManagerQuestions";

const QUERY_KEYS = Object.freeze({
  getLeases: () => [GET_LEASES],
  getLeasesByFilters: (filters) => [GET_LEASES, filters],
  getLeaseById: (id) => [GET_LEASES, Number(id)],
  verifyLease: (id) => [LEASE_VERIFY, Number(id)],
  leasePreview: (id) => [LEASE_PREVIEW, Number(id)],
  getLeaseFields: (id) => [GET_LEASE_FIELDS, Number(id)],
  getLeaseFieldsBluemoon: (id) => [GET_LEASE_FIELDS_BLUEMOON, Number(id)],
  getLeaseAssignedDocuments: (id) => [GET_LEASE_ASSIGNED_DOCUMENTS, Number(id)],
  getLeaseDocumentTemplates: (id) => [
    GET_LEASE_DOCUMENTS_TEMPLATES,
    Number(id),
  ],
  getLeaseDocumentTemplatesBluemoon: (id) => [
    GET_LEASE_DOCUMENTS_TEMPLATES_BLUEMOON,
    Number(id),
  ],
  getManagerSignUrl: (id) => [GET_MANAGER_SIGN_URL, Number(id)],
  getApplicationAnswers: (id) => [GET_APPLICATION_ANSWERS, Number(id)],
  getLeaseAvailableDocuments: (id) => [
    GET_LEASE_AVAILABLE_DOCUMENTS,
    Number(id),
  ],
  getLeaseAvailableDocumentsBluemoon: (id) => [
    GET_LEASE_AVAILABLE_DOCUMENTS_BLUEMOON,
    Number(id),
  ],
  getLeaseAdditionalDocuments: (id) => [
    GET_LEASE_ADDITIONAL_DOCUMENTS,
    Number(id),
  ],
  getLeasePackagesManagerAnswers: (id) => [
    GET_LEASE_MANAGER_ANSWERS,
    Number(id),
  ],
  getLeaseAdditionalManagerQuestions: (id) => [
    GET_LEASE_MANAGER_QUESTIONS,
    Number(id),
  ],
  getValidConcessionMonths: ({ leaseStart, leaseEnd }) => [
    GET_VALID_CONCESSION_MONTHS,
    { leaseStart, leaseEnd },
  ],
  calculateNetEffectiveRent: ({
    leaseStart,
    leaseEnd,
    numberOfConcessionMonths,
    rent,
  }) => [
    CALCULATE_NET_EFFECTIVE_RENT,
    { leaseStart, leaseEnd, numberOfConcessionMonths, rent: Number(rent) },
  ],
});

export const useUpdateLeaseStep = (leaseId) => {
  const [mutate, status] = useMutation(api.updateLeaseStep, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.getLeaseById(leaseId));
    },
    onError: () => {
      openNotification("Failed to update the lease.", NOTIFICATIONS.error);
    },
  });

  return {
    updateLeaseStep: mutate,
    ...status,
  };
};

export function useGetLeases(filters, config = {}) {
  const {
    data: leases,
    isLoading: isLeasesLoading,
    refetch: refetchLeases,
  } = useQuery(
    QUERY_KEYS.getLeasesByFilters(filters),
    () => getLeases(filters),
    {
      onError: () => {
        openNotification("Failed to load leases", NOTIFICATIONS.error);
      },
      ...config,
    }
  );

  return {
    leases,
    isLeasesLoading,
    refetchLeases,
  };
}

export const useUpdateLeaseTerms = () => {
  const [mutate, status] = useMutation(api.updateLeaseTerms, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.getLeases());
    },
    onError: (error) => {
      const message =
        // @ts-ignore
        Object.values(error?.errors)[0] || "Failed to update the lease.";
      openNotification(message, NOTIFICATIONS.error);
    },
  });

  return {
    updateLeaseTerms: mutate,
    ...status,
  };
};

export const useCountersignBluemoonLeaseQuery = (
  id,
  closeActiveModalDialog
) => {
  const history = useHistory();
  const [mutate, status] = useMutation(
    (values) => api.countersignBluemoonLease(id, values),
    {
      onSuccess: () => {
        openNotification(
          "Countersign successfully submitted",
          NOTIFICATIONS.info
        );
        closeActiveModalDialog();
        history.push(
          transformRoute(ROUTES.leaseSummary, {
            id,
          })
        );
        queryCache.invalidateQueries(QUERY_KEYS.getLeaseById(id));
      },
      onError: (error) => {
        const message =
          // @ts-ignore
          Object.values(error?.errors)[0] || "Failed to countersign the lease.";
        openNotification(message, NOTIFICATIONS.error);
      },
    }
  );

  return {
    countersignBlueemoonLease: mutate,
    ...status,
  };
};

export const useGetLease = (id) => {
  const response = useQuery(
    QUERY_KEYS.getLeaseById(id),
    () => api.getLease(id).then(({ data }) => data),
    {
      enabled: Boolean(id),
      forceFetchOnMount: true,
      onError: () =>
        openNotification("Failed to load the lease", NOTIFICATIONS.error),
    }
  );
  return response;
};

export const useLeasePreview = ({ leaseId }, { enabled }) => {
  const response = useQuery(
    QUERY_KEYS.leasePreview(leaseId),
    () => api.leasePreview(leaseId).then(({ data }) => data?.finalDocument),
    {
      enabled,
      forceFetchOnMount: true,
      onError: () =>
        openNotification("Failed to preview the lease", NOTIFICATIONS.error),
    }
  );
  return response;
};

export const useAssignLeaseOptionalDocuments = (id) => {
  const [mutate, status] = useMutation(
    // @ts-ignore
    ({ leaseId, documents, nextStep }) =>
      api.assignLeaseOptionalDocuments(leaseId, documents, nextStep),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(QUERY_KEYS.getLeases());
        queryCache.invalidateQueries(QUERY_KEYS.getLeaseFields(id));
        queryCache.invalidateQueries(QUERY_KEYS.getLeaseAssignedDocuments(id));
        queryCache.invalidateQueries(QUERY_KEYS.getLeaseDocumentTemplates(id));
      },
      onError: () => {
        openNotification(
          "Unable to assign document(s) to the lease",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    assignLeaseOptionalDocuments: mutate,
    isLoading: status.isLoading,
  };
};

export const useAssignLeaseOptionalDocumentsBluemoonQuery = (id) => {
  const [mutate, status] = useMutation(
    // @ts-ignore
    ({ leaseId, documents, nextStep }) =>
      api.assignLeaseOptionalDocumentsBluemoon(leaseId, documents, nextStep),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(QUERY_KEYS.getLeases());
        queryCache.invalidateQueries(QUERY_KEYS.getLeaseFieldsBluemoon(id));
        queryCache.invalidateQueries(QUERY_KEYS.getLeaseAssignedDocuments(id));
        queryCache.invalidateQueries(
          QUERY_KEYS.getLeaseDocumentTemplatesBluemoon(id)
        );
      },
      onError: () => {
        openNotification(
          "Unable to assign document(s) to the lease",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    assignLeaseOptionalDocuments: mutate,
    isLoading: status.isLoading,
  };
};

export const useLeaseAssignedDocuments = (id) => {
  const response = useQuery(
    QUERY_KEYS.getLeaseAssignedDocuments(id),
    () => api.getLeaseAssignedDocuments(id).then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load lease documents", NOTIFICATIONS.error),
    }
  );
  return response;
};

export const useLeaseDocumentTemplates = (leaseId) => {
  const response = useQuery(
    QUERY_KEYS.getLeaseDocumentTemplates(leaseId),
    () => api.getLeaseDocumentTemplates(leaseId).then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load lease documents", NOTIFICATIONS.error),
    }
  );

  return response;
};

export const useLeaseDocumentTemplatesBluemoonQuery = (leaseId) => {
  const response = useQuery(
    QUERY_KEYS.getLeaseDocumentTemplatesBluemoon(leaseId),
    () => api.getLeaseDocumentTemplatesBluemoon(leaseId),
    {
      onError: () =>
        openNotification("Failed to load lease documents", NOTIFICATIONS.error),
    }
  );

  return response;
};

export const useUpdateLeaseMetadata = (id) => {
  const [mutate, status] = useMutation(
    // @ts-ignore
    ({ leaseId, step, leaseDocumentTemplates, feeConfiguration, leaseData }) =>
      api.updateLeaseMetadata(leaseId, {
        step,
        leaseDocumentTemplates,
        feeConfiguration,
        leaseData,
      }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(QUERY_KEYS.getLeaseById(id));
        queryCache.invalidateQueries(QUERY_KEYS.getLeaseFields(id));
      },
      onError: () => {
        openNotification("Unable to update the lease", NOTIFICATIONS.error);
      },
    }
  );

  return {
    updateLeaseMetadata: mutate,
    ...status,
  };
};

export const useUpdateLeaseMetadataBluemoonQuery = (id) => {
  const [mutate, status] = useMutation(
    // @ts-ignore
    ({ leaseId, step, leaseDocumentTemplates, feeConfiguration, leaseData }) =>
      api.updateLeaseMetadataBluemoon(leaseId, {
        step,
        leaseDocumentTemplates,
        feeConfiguration,
        leaseData,
      }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(QUERY_KEYS.getLeaseById(id));
        queryCache.invalidateQueries(QUERY_KEYS.getLeaseFieldsBluemoon(id));
      },
      onError: () => {
        openNotification("Unable to update the lease", NOTIFICATIONS.error);
      },
    }
  );

  return {
    updateLeaseMetadata: mutate,
    ...status,
  };
};

export const useSendLease = ({ onSuccess, leaseId: id }) => {
  const [mutate, status] = useMutation(() => api.sendLease(id), {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.getLeaseById(id));
      openNotification("The lease was successfully sent", NOTIFICATIONS.info);
      onSuccess();
    },
    onError: () =>
      openNotification("Failed to send the lease", NOTIFICATIONS.error),
  });

  return {
    sendLease: mutate,
    isLoading: status.isLoading,
  };
};

export const useSendLeaseBluemoon = ({ onSuccess, leaseId: id }) => {
  const [mutate, status] = useMutation(() => api.sendLeaseBluemoon(id), {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.getLeaseById(id));
      openNotification("The lease was successfully sent", NOTIFICATIONS.info);
      onSuccess();
    },
    onError: () =>
      openNotification("Failed to send the lease", NOTIFICATIONS.error),
  });

  return {
    sendLeaseBluemoon: mutate,
    isLoadingBluemoon: status.isLoading,
  };
};

export const useResendLease = (leaseId) => {
  const [mutate, status] = useMutation(api.resendLease, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.getLeaseById(leaseId));
      openNotification("Reminder sent!", NOTIFICATIONS.info);
    },
    onError: () => {
      openNotification("Failed to resend the lease", NOTIFICATIONS.error);
    },
  });

  return {
    resendLease: mutate,
    isLoading: status.isLoading,
  };
};

export const useWithdrawLease = (leaseId) => {
  const [mutate, status] = useMutation(api.withdrawLease, {
    onSuccess: () => {
      queryCache.refetchQueries(QUERY_KEYS.getLeaseById(leaseId));
      openNotification(
        "The lease was successfully withdrawn",
        NOTIFICATIONS.info
      );
    },
    onError: ({ statusCode, errors }) => {
      if (statusCode === HTTP_ERROR_CODES.forbidden) {
        openNotification(errors.nonFieldErrors[0], NOTIFICATIONS.error);
      } else {
        openNotification("Failed to withdraw the lease", NOTIFICATIONS.error);
      }
    },
  });

  return {
    withdrawLease: mutate,
    isLoading: status.isLoading,
  };
};

export const useManagerSignUrl = ({ leaseId }, { enabled }, { onError }) => {
  const history = useHistory();
  const {
    isLoading,
    isFetching,
    data: signatureData,
    refetch,
  } = useQuery(
    QUERY_KEYS.getManagerSignUrl(leaseId),
    () => api.getManagerSignUrl({ leaseId }).then(({ data }) => data),
    {
      enabled,
      onError: ({ statusCode, errors }) => {
        if (onError) {
          onError();
        }
        history.goBack();

        if (statusCode === HTTP_ERROR_CODES.forbidden) {
          openNotification(errors.nonFieldErrors[0], NOTIFICATIONS.error);
        } else {
          openNotification(
            "Failed to load URL for signing process",
            NOTIFICATIONS.error
          );
        }
      },
      retry: false,
      forceFetchOnMount: true,
    }
  );

  return {
    isLoading,
    signatureData,
    refetch,
    isFetching,
  };
};

export const useDocumentPreview = () => {
  const [mutate, status] = useMutation(
    (id: number) => api.previewDocument(id),
    {
      onError: () => {
        openNotification(
          "Failed to load document preview.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    previewDocument: mutate,
    ...status,
  };
};

export const useLeaseApplicationAnswers = (leaseId) => {
  const { isLoading, data: applicationAnswers } = useQuery(
    QUERY_KEYS.getApplicationAnswers(leaseId),
    () => api.getApplicationAnswers(leaseId).then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load applicant answers",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    isLoading,
    applicationAnswers,
  };
};

export const useLeaseFields = (id) => {
  const response = useQuery(
    QUERY_KEYS.getLeaseFields(id),
    () => api.getLeaseFields(id).then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load fields", NOTIFICATIONS.error),
      staleTime: 0,
      cacheTime: 0,
    }
  );
  return response;
};

export const useLeaseFieldsBluemoonQuery = (leaseId) => {
  const response = useQuery(
    QUERY_KEYS.getLeaseFieldsBluemoon(leaseId),
    () => api.getLeaseFieldsBluemoon(leaseId),
    {
      onError: () =>
        openNotification("Failed to load fields", NOTIFICATIONS.error),
      staleTime: 0,
      cacheTime: 0,
    }
  );
  return response;
};

export const useHandleLeaseOffline = (id) => {
  const [mutate, status] = useMutation(api.handleLeaseOffline, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.getLeaseById(id));
    },
    onError: () => {
      openNotification("Failed to handle lease offline.", NOTIFICATIONS.error);
    },
  });

  return {
    handleLeaseOffline: mutate,
    ...status,
  };
};

export const useSetLeaseInProgress = (id) => {
  const [mutate, status] = useMutation(api.setLeaseInProgress, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.getLeaseById(id));
    },
    onError: () => {
      openNotification("Failed to set lease in progress.", NOTIFICATIONS.error);
    },
  });

  return {
    setLeaseInProgress: mutate,
    ...status,
  };
};

export const useCreateLease = ({ onError }) => {
  const history = useHistory();
  const { setLeaseInProgress, isLoading: isSettingInProgress } =
    // @ts-ignore
    useSetLeaseInProgress();

  const [mutate, status] = useMutation(api.createLease, {
    onSuccess: async ({ data }) => {
      if (data.id) {
        queryCache.invalidateQueries(QUERY_KEYS.getLeases());
        await setLeaseInProgress(data.id);
        const url = transformRoute(ROUTES.leaseBuilder, {
          id: data.id,
        });
        history.push(url);
      } else {
        openNotification("Failed to create lease.", NOTIFICATIONS.error);
      }
    },
    onError: (error) => {
      if (onError) {
        // @ts-ignore
        onError({ data: error.errors });
      } else {
        openNotification("Failed to create lease.", NOTIFICATIONS.error);
      }
    },
  });

  return {
    createLease: mutate,
    isLoading: status.isLoading || isSettingInProgress,
    isSuccess: status.isSuccess,
    // @ts-ignore
    data: status.error?.errors,
  };
};

export const useProcessManagerSignature = ({
  onSuccess,
  leaseId,
  ...otherParams
}) => {
  const [mutate, response] = useMutation(api.processingManagerSignature, {
    onSuccess: async () => {
      queryCache.invalidateQueries(QUERY_KEYS.getLeaseById(leaseId));
      onSuccess();
    },
    ...otherParams,
  });

  return { processManagerSignature: mutate, response };
};

export const useDownloadLease = (leaseId) => {
  const [mutate, response] = useMutation(() => api.downloadLease(leaseId), {
    onError: () =>
      openNotification("Failed to download a document", NOTIFICATIONS.error),
  });

  return { downloadLease: mutate, response };
};

export const useGetLeaseAdditionalDocuments = (
  leaseId,
  config = { enabled: true }
) => {
  const { isLoading: isAdditionalDocumentsLoading, data: additionalDocuments } =
    useQuery(
      QUERY_KEYS.getLeaseAdditionalDocuments(leaseId),
      () => api.getLeaseAdditionalDocuments(leaseId),
      {
        enabled: Boolean(leaseId) && config.enabled,
        onError: () =>
          openNotification(
            "Failed to load additional documents",
            NOTIFICATIONS.error
          ),
      }
    );
  return {
    additionalDocuments,
    isAdditionalDocumentsLoading,
  };
};

export const useLeaseAvailableDocuments = (leaseId) => {
  const response = useQuery(
    QUERY_KEYS.getLeaseAvailableDocuments(leaseId),
    () => api.getLeaseAvailableDocuments(leaseId).then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load available documents",
          NOTIFICATIONS.error
        ),
    }
  );
  return response;
};
export const useLeaseAvailableDocumentsBluemoonQuery = (leaseId) => {
  const response = useQuery(
    QUERY_KEYS.getLeaseAvailableDocumentsBluemoon(leaseId),
    () =>
      api.getLeaseAvailableDocumentsBluemoon(leaseId).then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load available documents",
          NOTIFICATIONS.error
        ),
    }
  );
  return response;
};

export const useGetLeasePackagesManagerAnswers = (
  leaseId,
  config = { enabled: true }
) => {
  const { data: managerAnswers, isLoading: isManagerAnswersLoading } = useQuery(
    QUERY_KEYS.getLeasePackagesManagerAnswers(leaseId),
    () => api.getLeasePackagesManagerAnswers(leaseId),
    {
      enabled: Boolean(leaseId) && config.enabled,
      onError: () => {
        openNotification(
          `Failed to load manager answers.`,
          NOTIFICATIONS.error
        );
      },
    }
  );
  return {
    managerAnswers,
    isManagerAnswersLoading,
  };
};

export const useGetLeaseAdditionalManagerQuestions = (
  leaseId,
  config = { enabled: true }
) => {
  const { data: managerQuestions, isLoading: isManagerQuestionsLoading } =
    useQuery(
      QUERY_KEYS.getLeaseAdditionalManagerQuestions(leaseId),
      () => api.getLeaseAdditionalManagerQuestions(leaseId),
      {
        enabled: Boolean(leaseId) && config.enabled,
        onError: () => {
          openNotification(
            `Failed to load manager questions.`,
            NOTIFICATIONS.error
          );
        },
      }
    );
  return {
    managerQuestions,
    isManagerQuestionsLoading,
  };
};

export const useRefreshLeaseAvailableDocuments = (leaseId) => {
  const [mutate, status] = useMutation(api.refreshLeaseAvailableDocuments, {
    onSuccess: () => {
      refetchMultipleActiveQueries([
        QUERY_KEYS.getLeaseAvailableDocuments(leaseId),
        QUERY_KEYS.getLeaseDocumentTemplates(leaseId),
        QUERY_KEYS.getLeaseById(leaseId),
      ]);
    },
    onError: () => {
      openNotification(
        "Failed to refresh available documents",
        NOTIFICATIONS.error
      );
    },
  });

  return {
    refreshLeaseAvailableDocuments: mutate,
    ...status,
  };
};

export const useExecuteOfflineLease = ({ onSuccess, onError, leaseId: id }) => {
  const [mutate, status] = useMutation(
    // @ts-ignore
    ({ leaseId, finalDocument }) =>
      api.executeOfflineLease(leaseId, finalDocument),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(QUERY_KEYS.getLeaseById(id));
        openNotification(
          "The lease was successfully executed",
          NOTIFICATIONS.info
        );
        onSuccess();
      },
      onError: () => {
        openNotification("Failed to execute the lease", NOTIFICATIONS.error);
        onError();
      },
    }
  );

  return {
    executeLease: mutate,
    isLoading: status.isLoading,
  };
};

export const useGetValidConcessionMonths = (
  { leaseStart, leaseEnd },
  config = { enabled: false }
) => {
  const { data: concessionMonths, isLoading: isConcessionMonthsLoading } =
    useQuery(
      QUERY_KEYS.getValidConcessionMonths({ leaseStart, leaseEnd }),
      () => api.getValidConcessionMonths({ leaseStart, leaseEnd }),
      {
        onError: () =>
          openNotification(
            "Failed to load available concession months",
            NOTIFICATIONS.error
          ),
        enabled: Boolean(leaseStart && leaseEnd) && config.enabled,
      }
    );
  return {
    concessionMonths,
    isConcessionMonthsLoading,
  };
};

export const useCalculateNetEffectiveRent = (
  { leaseStart, leaseEnd, numberOfConcessionMonths, rent },
  config = { enabled: false }
) => {
  const { data: netEffectiveRent, isLoading: isNetEffectiveRentLoading } =
    useQuery(
      QUERY_KEYS.calculateNetEffectiveRent({
        leaseStart,
        leaseEnd,
        numberOfConcessionMonths,
        rent,
      }),
      () =>
        api.calculateNetEffectiveRent({
          leaseStart,
          leaseEnd,
          numberOfConcessionMonths,
          rent,
        }),
      {
        onError: () =>
          openNotification(
            "Failed to calculate net effective rent",
            NOTIFICATIONS.error
          ),
        enabled:
          Boolean(leaseStart && leaseEnd && numberOfConcessionMonths && rent) &&
          config.enabled,
      }
    );
  return {
    netEffectiveRent,
    isNetEffectiveRentLoading,
  };
};

export const useLeasesExport = () => {
  const { mutate, isLoading } = useRequestExport(
    api.leasesExport,
    "Failed to request a leases download."
  );
  return {
    exportLeases: mutate,
    isExportingLeases: isLoading,
  };
};

export const useCalculateSecurityDepositBalance = () => {
  const [calculateSecurityDepositBalance, { data: balanceCalculations }] =
    useMutation(api.calculateSecurityDepositBalance, {
      onError: () =>
        openNotification(
          "Failed to calculate security deposit balance",
          NOTIFICATIONS.error
        ),
    });

  return {
    calculateSecurityDepositBalance,
    balanceCalculations,
  };
};
