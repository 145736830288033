import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

export const MonthPickerContainer = styled(FlexContainer)`
  .tile {
    width: 346px;
    height: 150px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .month-tile {
    background-color: ${COLORS.white};
    padding: 24px;
    position: relative;
  }

  .month-tile .month-switch {
    margin-top: 32px;
  }

  .month-tile .month-switch label {
    color: ${COLORS.gray2};
  }

  .month-tile .delete-icon {
    position: absolute;
    bottom: 4px;
    right: 4px;
    width: 32px;
    height: 32px;
  }

  .empty-tile {
    border: 1px solid ${COLORS.borders};
  }

  .empty-tile .date-icon {
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
  }

  ${MEDIA_QUERIES.w.ltSm} {
    .month-tile {
      padding: 24px 12px;
    }

    .month-tile .month-switch {
      margin-top: 12px;
    }
  }
`;
