import { useHelloSign } from "shared/hooks";

import { useManagerSignUrl } from "./api";

export const useManagerHelloSign = ({
  leaseId,
  enableToFetch,
  onSign,
  onError,
}) => {
  const {
    signatureData,
    isLoading: isSignatureUrlLoading,
    isFetching: isSignatureUrlFetching,
  } = useManagerSignUrl(
    { leaseId },
    { enabled: Boolean(leaseId) && enableToFetch },
    { onError }
  );

  const isSignatureUrlAvailable =
    !isSignatureUrlLoading &&
    !isSignatureUrlFetching &&
    Boolean(signatureData?.signatureUrl);

  const { openHelloSign, closeHelloSign } = useHelloSign({
    actions: { onSign },
  });

  return {
    signatureUrl: signatureData?.signatureUrl,
    isSignatureUrlLoading,
    isSignatureUrlAvailable,
    openManagerHelloSign: openHelloSign,
    closeManagerHelloSign: closeHelloSign,
  };
};
