import React from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import Layout from "manager/components/Layout";
import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";
import Notice from "shared/components/Notice";
import PageTitle from "shared/components/PageTitle";
import Spacer from "shared/components/Spacer";
import Well from "shared/components/Well";
import { VeroBluemoonLeaseLogo } from "shared/images";

import { getStepByIndex } from "./config";
import Header from "./Header";
import LeaseLoading from "./LeaseLoading";
import { leaseDetailsPropType } from "./propTypes";
import { Image } from "./styled";

const LeaseBuilder = ({
  sidebar,
  header,
  backLink,
  lease,
  isApplicationAnswersLoading,
  isLeaseLoading,
  currentStep,
  openAnswersModal,
  redirectBack,
  stepBack,
  stepForward,
  isRefreshingDocuments,
  documentsMismatchWarning,
}) => {
  const { isBluemoonLease, deal } = lease;
  const { name: unitName, property } = deal.unit;
  const { portfolioName, address, name: propertyName } = property;
  const propertyAddress = `${propertyName}, ${address}, ${unitName}`;
  const title = "Lease Builder";
  const { component: StepComponent } = getStepByIndex(currentStep) || {};

  return (
    <Layout
      sidebar={sidebar}
      header={{
        ...header,
        backLink,
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container noPaddingBottom>
        <BackLink.Container>
          <BackLink {...backLink} />
        </BackLink.Container>
        <PageTitle.Container center>
          <PageTitle>{title}</PageTitle>
          {isBluemoonLease && (
            <Image src={VeroBluemoonLeaseLogo} alt="vero-bluemoon-lease-logo" />
          )}
        </PageTitle.Container>
      </Container>
      {(isLeaseLoading || isRefreshingDocuments) && <LeaseLoading />}
      {!isLeaseLoading && StepComponent && (
        <>
          <Container noMobilePaddingX noPaddingTop noPaddingBottom>
            <Header
              isApplicationAnswersLoading={isApplicationAnswersLoading}
              applicantNames={deal.applicantNames}
              portfolioName={portfolioName}
              propertyAddress={propertyAddress}
              currentStep={currentStep}
              openAnswersModal={openAnswersModal}
            />
            {documentsMismatchWarning && (
              <>
                <Well lightError>
                  <Notice type="warning">{documentsMismatchWarning}</Notice>
                </Well>
                <Spacer size={Spacer.SIZES.sm} />
              </>
            )}
          </Container>
          <StepComponent
            lease={lease}
            currentStep={currentStep}
            redirectBack={redirectBack}
            stepBack={stepBack}
            stepForward={stepForward}
          />
        </>
      )}
    </Layout>
  );
};

LeaseBuilder.propTypes = {
  currentStep: PropTypes.number,
  backLink: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired,
  isLeaseLoading: PropTypes.bool.isRequired,
  isApplicationAnswersLoading: PropTypes.bool.isRequired,
  lease: leaseDetailsPropType,
  sidebar: PropTypes.object.isRequired,
  openAnswersModal: PropTypes.func.isRequired,
  redirectBack: PropTypes.func.isRequired,
  stepBack: PropTypes.func.isRequired,
  stepForward: PropTypes.func.isRequired,
  isRefreshingDocuments: PropTypes.bool.isRequired,
  documentsMismatchWarning: PropTypes.node,
};

LeaseBuilder.defaultProps = {
  currentStep: 0,
  lease: {
    step: 1,
    deal: {
      applicantNames: [],
      unit: {
        name: "",
        property: {},
      },
    },
  },
  documentsMismatchWarning: undefined,
};

export default LeaseBuilder;
