import React from 'react'

import { Col, Row } from 'antd'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'

import SummaryCard from 'renter/components/SummaryCard'
import ROUTES from 'renter/config/routes'
import { getStartingStep } from 'renter/utils/application-routing.utils'
import Container from 'shared/components/Container'
import LoaderContainer, {
  BigLoadingSpinner,
} from 'shared/components/LoaderContainer'
import PageTitle from 'shared/components/PageTitle'
import {
  APPLICATION_STATUSES,
  DEAL_UI_STATUS_CODES,
} from 'shared/config/constants'
import { cacheActiveCompany } from 'shared/utils/auth'
import { transformRoute } from 'shared/utils/routing'

import ApplicationDetails from './ApplicationDetails'
import { ApplicationsProps } from './interfaces'
import InviteApplicants from './InviteApplicants'
import { CityBackground } from './styled'
import {
  getAdditionalInfoMessage,
  getMakePaymentActions,
  showFooterActions,
} from './utils'

const Applications = ({ name, applications, loading }: ApplicationsProps) => {
  const history = useHistory()

  return (
    <>
      <CityBackground data-testid="city-bg" />
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <Container noPaddingBottom>
        <PageTitle.Container>
          <PageTitle>{name}</PageTitle>
        </PageTitle.Container>
      </Container>
      <Container noPaddingTop noMobilePaddingX>
        <LoaderContainer
          Loader={<BigLoadingSpinner data-testid="loading" />}
          fetching={loading}
        >
          <Row gutter={14} type="flex">
            {applications?.map((application) => (
              <Col key={application.id} xs={24} xl={12} xxl={8}>
                <SummaryCard
                  applicationId={application.id}
                  propertyName={application.propertyName}
                  unitName={application.unitName}
                  status={application.status}
                  propertyAddress={application.propertyAddress}
                  otherApplicants={application?.otherApplicants}
                  fallbackContent={application.companyName}
                  ingestionFlowEnabled={application.ingestionFlowEnabled}
                  Details={() => (
                    <ApplicationDetails
                      monthlyRent={application?.monthlyRent}
                      unitAvailableOn={application?.unitAvailableOn}
                    />
                  )}
                  FooterActions={() =>
                    showFooterActions(application.dealUiStatus) && (
                      <InviteApplicants
                        property={application?.propertyName}
                        unit={application?.unitName}
                        dealId={application?.deal}
                        companyName={application?.companyName}
                        hideOccupantsButAllowDependents={
                          application?.hideOccupantsButAllowDependents
                        }
                        hideGuarantorAsApplicantType={
                          application?.hideGuarantorAsApplicantType
                        }
                      />
                    )
                  }
                  additionalInfoMessage={getAdditionalInfoMessage(application)}
                  action={{
                    visible:
                      [
                        APPLICATION_STATUSES.inProgress,
                        APPLICATION_STATUSES.submitted,
                        APPLICATION_STATUSES.notStarted,
                        APPLICATION_STATUSES.approved,
                      ].includes(application.status) ||
                      application.dealUiStatus ===
                        DEAL_UI_STATUS_CODES.condApproved,
                    label: `${application.isSubmitted ? 'View' : 'Complete'}`,
                    onClick: () => {
                      cacheActiveCompany({
                        id: application.companyId,
                        name: application.companyName,
                      })

                      const path = application.isSubmitted ? transformRoute(ROUTES.application, { id: application.id }) : `/applicant/workflow/${application.id}`
                      window.location.href = `${window.location.origin}${path}`;
                    },
                  }}
                  ActionAdditionalView={
                    application.hasExtraUnpaidPayments &&
                    application.isSubmitted &&
                    getMakePaymentActions({ application, history })
                  }
                  companySlug={kebabCase(application.companyName)}
                  companyName={application.companyName}
                  dealId={application.deal}
                />
              </Col>
            ))}
          </Row>
        </LoaderContainer>
      </Container>
    </>
  )
}

export default Applications
