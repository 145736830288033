export const PDF_JS_DEPENDENCIES = ["/pdfjs/pdf.js", "/pdfjs/pdf.worker.js"];

export const SCALE_STEP = 0.2;

// NOTE: It's a name of the property on global object. pdfjs script adds it to window
export const INTERNAL_PATH = "pdfjs-dist/build/pdf";

export const INTERNAL_WORKER_PATH = "/pdfjs/pdf.worker.js";

export const SCROLL_THROTTLE = 100;
