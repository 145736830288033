import React, { useCallback, useEffect } from "react";

import dayjs from "dayjs";
import { FieldArray, useFormikContext } from "formik";

import ErrorList from "shared/components/ErrorList";
import FlexContainer from "shared/components/FlexContainer";
import { SwitchTitle, DatePicker, VeroFormField } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Tooltip from "shared/components/Tooltip";
import { MONTH_FORMAT, YEAR_MONTH_DATE_FORMAT } from "shared/config/constants";
import { showError } from "shared/utils/forms";

import { ConcessionFormData } from "./interfaces";
import { MonthPickerContainer } from "./styled";
import { getMonthCountBySelectedDates } from "./util";

export const ConcessionMonthsPicker = ({
  leaseStartDate,
  leaseEndDate,
  concessionMonths,
  isConcessionMonthsLoading,
}) => {
  const {
    values: { dates },
    setFieldValue,
    errors,
    touched,
  } = useFormikContext<ConcessionFormData>();

  const { concessionStart, concessionEnd } = concessionMonths || {};

  const disableDate = useCallback(
    (current) => {
      if (
        current &&
        concessionStart &&
        concessionEnd &&
        !isConcessionMonthsLoading
      ) {
        // Note: DatePicker takes current day and time for month picker dates
        // Resetting date time with re-formatting
        const currentDate = dayjs(current.format(YEAR_MONTH_DATE_FORMAT)).date(
          1
        );
        const isAlreadySelected = Boolean(
          dates.find(
            (date) =>
              date.value &&
              dayjs(date.value).format(MONTH_FORMAT) ===
                currentDate.format(MONTH_FORMAT)
          )
        );
        const isBeforeConcessionStart = currentDate.isBefore(
          dayjs(concessionStart)
        );
        const isAfterConcessionEnd = currentDate.isAfter(dayjs(concessionEnd));

        return (
          isAlreadySelected || isBeforeConcessionStart || isAfterConcessionEnd
        );
      }

      return false;
    },
    [dates, concessionStart, concessionEnd, isConcessionMonthsLoading]
  );

  const disabled = !leaseStartDate || !leaseEndDate;
  const disabledTooltip = disabled ? "Please specify lease dates" : "";

  // Note: update months count according to selected months
  useEffect(() => {
    const monthCount = getMonthCountBySelectedDates(dates);
    setFieldValue("numberOfMonths", monthCount);
  }, [dates]);

  return (
    <FieldArray
      name="dates"
      render={(arrayHelpers) => {
        return (
          <>
            {showError("dates", touched, errors) &&
              typeof errors.dates === "string" && (
                <ErrorList errors={[errors.dates]} />
              )}
            <MonthPickerContainer flexWrap="wrap">
              {dates.map((item, index) => {
                const fieldName = `dates.${index}.value`;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="tile month-tile" key={index}>
                    <Icon.DeleteIcon
                      className="action-icon delete-icon"
                      onClick={() => arrayHelpers.remove(index)}
                    />
                    <VeroFormField
                      as={DatePicker}
                      className="month-picker"
                      id={fieldName}
                      name={fieldName}
                      data-testid={fieldName}
                      monthPicker
                      label={`${index + 1} Month of Concession`}
                      displayFormat={MONTH_FORMAT}
                      valueFormat={MONTH_FORMAT}
                      disabledDate={disableDate}
                    />

                    <VeroFormField
                      as={SwitchTitle}
                      className="month-switch"
                      title="Half month concession"
                      name={`dates.${index}.isHalfMonth`}
                      noPadding
                      reverseOrder
                      size="small"
                    />
                  </div>
                );
              })}

              <FlexContainer
                className="tile empty-tile"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Icon.DatepickerIcon className="date-icon" />
                <Tooltip
                  title={disabledTooltip}
                  theme="light"
                  placement="bottom"
                >
                  <FlexContainer>
                    <ActionLink
                      plus
                      onClick={() =>
                        arrayHelpers.insert(dates.length, {
                          value: undefined,
                          isHalfMonth: false,
                        })
                      }
                      disabled={disabled}
                      data-testid="add-month-link"
                    >
                      Month
                    </ActionLink>
                    <span>&nbsp;/&nbsp;</span>
                    <ActionLink
                      onClick={() =>
                        arrayHelpers.insert(dates.length, {
                          value: undefined,
                          isHalfMonth: true,
                        })
                      }
                      disabled={disabled}
                      data-testid="add-half-month-link"
                    >
                      Half Month
                    </ActionLink>
                  </FlexContainer>
                </Tooltip>
              </FlexContainer>
            </MonthPickerContainer>
          </>
        );
      }}
    />
  );
};
