import React from "react";

import CheckList from "shared/components/CheckList";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { APPLICANT_TYPES } from "shared/config/constants";
import useDeviceType from "shared/hooks/useDeviceType";

import { TileIcon } from "../styled";

interface Applicant {
  firstName: string;
  lastName: string;
  id: number;
  applicantType: string | number;
}

interface ApplicantListProps {
  applicantsList: Array<Applicant>;
}

const ApplicantList = ({ applicantsList = [] }: ApplicantListProps) => {
  const { isMobileOrTablet } = useDeviceType();
  // on screens smaller than 991px,
  // display both applicants and guarantors in the same column
  const applicants = applicantsList.filter(
    !isMobileOrTablet
      ? ({ applicantType }) =>
          Number(applicantType) === APPLICANT_TYPES.applicant
      : ({ applicantType }) =>
          Number(applicantType) !== APPLICANT_TYPES.occupant
  );
  const guarantors = applicantsList.filter(
    ({ applicantType }) => Number(applicantType) === APPLICANT_TYPES.guarantor
  );
  const numOfGuarantors = guarantors.length;

  const MAX_NUM_OF_APPLICANTS_TO_SHOW = 2;
  const MAX_NUM_OF_GUARANTORS_TO_SHOW = 2;

  const showGouarantorsColumn = !isMobileOrTablet && numOfGuarantors > 0;

  return (
    <FlexContainer alignItems="center">
      <TileIcon>
        <Icon.ApplicantsTenantsIcon
          className="icon-light-violet"
          data-testid="tenant-icon"
        />
        <span>Applicants:</span>
      </TileIcon>
      {/* @ts-ignore */}
      <CheckList
        data-testid="applicants"
        inline
        violet
        withoutComma
        onHoverActive
        defaultItemsDisplayed={MAX_NUM_OF_APPLICANTS_TO_SHOW}
        items={applicants.map(({ firstName, lastName, id }) => ({
          key: id,
          label: `${firstName} ${lastName}`,
          checked: false,
        }))}
      />
      {showGouarantorsColumn && (
        <>
          <TileIcon>
            <span>Guarantors:</span>
          </TileIcon>
          {/* @ts-ignore */}
          <CheckList
            inline
            violet
            withoutComma
            onHoverActive
            defaultItemsDisplayed={MAX_NUM_OF_GUARANTORS_TO_SHOW}
            items={guarantors.map(({ firstName, lastName, id }) => ({
              key: id,
              label: `${firstName} ${lastName}`,
              checked: false,
            }))}
          />
        </>
      )}
    </FlexContainer>
  );
};

export default ApplicantList;
