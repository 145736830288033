import { useMemo } from "react";

import { stringify } from "shared/utils/string";

export const isMultiValueFilterValid = (
  multiValueFilter,
  items,
  getItemProp
) => {
  const itemsProps = (items || [])
    .map(getItemProp)
    .map((prop) => stringify(prop));
  const allItemsPropsSet = new Set(itemsProps);
  return !(multiValueFilter || []).find(
    (id) => !allItemsPropsSet.has(stringify(id))
  );
};

/**
 * Hook for checking if the provided multi value filter is valid compared
 * to the available options.
 * There are cases when the selected (cached) filter values are not available
 * anymore, in that case we have to cleanup the filter.
 *
 * @param multiValueFilter the value of the multivalue filter (list of selected items)
 * @param items the available items
 * @param getItemProp the function for extracting the prop from the available items (this is the value stored in the filter)
 */
const useCheckMultiValueFilterValidity = <T, O>({
  multiValueFilter,
  items,
  getItemProp = (item) => (item as any)?.id,
}: {
  multiValueFilter: T[];
  items: O[];
  getItemProp?: (i: O) => T | string | number;
}) => {
  return useMemo(() => {
    if (!multiValueFilter?.length || !items?.length) {
      return true;
    }
    return isMultiValueFilterValid(multiValueFilter, items, getItemProp);
  }, [items]);
};

export default useCheckMultiValueFilterValidity;
