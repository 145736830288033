import styled from "@emotion/styled";

const Container = styled("div")`
  background-color: #d8f3e4;
  color: #2d2943;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 15px;
  height: 30px;
  & > p {
    margin-bottom: 0;
  }
`;

const IconWrapper = styled("div")`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

export { Container, IconWrapper };
