import React from "react";

import { Button } from "shared/components/Button";
import { ActionLink, UnderlineLink } from "shared/components/Links";
import { usePermissions } from "shared/hooks";

import { FootBarState } from "./constants";
import { OfferFootBarContent } from "./styled";

export const RenewalOfferBuilderFootBar = ({
  footBarState,
  cancel,
  saveAndExit,
  confirm,
  reopenOffer,
  createNoteAction,
  isUpdating,
}: {
  footBarState: FootBarState;
  cancel: () => void;
  saveAndExit: () => Promise<any>;
  confirm: () => Promise<any>;
  reopenOffer: () => void;
  createNoteAction: () => void;
  isUpdating: boolean;
}) => {
  const { canApproveRenewalsOffers } = usePermissions();
  return (
    <OfferFootBarContent>
      {footBarState === FootBarState.IN_PROGRESS && (
        <>
          <UnderlineLink onClick={cancel}>Cancel</UnderlineLink>
          <ActionLink onClick={saveAndExit}>SAVE & SEND LATER</ActionLink>
          <Button type="primary" loading={isUpdating} onClick={confirm}>
            {canApproveRenewalsOffers ? "APPROVE OFFERS" : "SEND FOR APPROVAL"}
          </Button>
        </>
      )}
      {footBarState === FootBarState.WAITING_APPROVAL && (
        <>
          <UnderlineLink onClick={reopenOffer}>Edit</UnderlineLink>
          {canApproveRenewalsOffers && (
            <ActionLink onClick={createNoteAction}>Add Note</ActionLink>
          )}
          <Button type="primary" loading={isUpdating} onClick={confirm}>
            {canApproveRenewalsOffers
              ? "APPROVE OFFERS"
              : "RESEND FOR APPROVAL"}
          </Button>
        </>
      )}
      {footBarState === FootBarState.APPROVED && (
        <>
          <UnderlineLink onClick={reopenOffer}>Edit</UnderlineLink>
          <Button type="primary" loading={isUpdating} onClick={confirm}>
            SEND OFFERS
          </Button>
        </>
      )}
    </OfferFootBarContent>
  );
};
