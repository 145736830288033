import React from "react";

import PropTypes from "prop-types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import Spacer from "shared/components/Spacer";

import PackageItem from "./PackageItem";
import { PackageTitle } from "./styled";

const FinalDocumentsPackage = ({
  documents,
  removeDocumentFromPackage,
  previewDocument,
  onDragEnd,
  isBluemoonLease,
}) => (
  <div data-testid="final-lease-package">
    <Spacer size={Spacer.SIZES.sm} />
    <PackageTitle>Final Lease Package</PackageTitle>
    <Spacer size={Spacer.SIZES.sm} />

    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="lease-package">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {documents.map((document, index) => (
              <PackageItem
                key={document.id}
                item={document}
                index={index}
                removeDocumentFromPackage={removeDocumentFromPackage}
                previewDocument={previewDocument}
                hidePreview={isBluemoonLease}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </div>
);

FinalDocumentsPackage.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object),
  removeDocumentFromPackage: PropTypes.func.isRequired,
  previewDocument: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  isBluemoonLease: PropTypes.bool,
};

FinalDocumentsPackage.defaultProps = {
  documents: [],
  isBluemoonLease: false,
};

export default FinalDocumentsPackage;
