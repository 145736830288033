import React from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import LoginPage from "shared/pages/LoginPage";
import { getCachedActiveCompanyId } from "shared/utils/auth";

const LoginPageContainer = () => {
  const history = useHistory();

  const onSuccess = () =>
    history.push(
      `${getCachedActiveCompanyId() ? ROUTES.dashboard : ROUTES.selectCompany}${
        window.location.search
      }`
    );

  return <LoginPage title="Manager Sign In" onSuccess={onSuccess} />;
};

export default LoginPageContainer;
