import { LEASE_STATUS_CODES } from "shared/config/constants";

export const isLeaseWithdrawable = (status: LEASE_STATUS_CODES) => {
  let isWithdrawable;
  switch (status) {
    case LEASE_STATUS_CODES.sent:
      isWithdrawable = true;
      break;
    case LEASE_STATUS_CODES.applicantsSigned:
      isWithdrawable = true;
      break;
    default:
      isWithdrawable = false;
  }
  return isWithdrawable;
};
