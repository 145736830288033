import dayjs from "dayjs";
import sumBy from "lodash/sumBy";
import * as Yup from "yup";

import { MONTH_FORMAT, CONCESSION_TYPES } from "shared/config/constants";
import { ConcessionMonth } from "shared/interfaces/api/renewals";

import { ConcessionFormMonth } from "./interfaces";

export const CONCESSION_DATA_VALIDATION = {
  dates: Yup.array().when("concessionType", {
    is: CONCESSION_TYPES.specificMonths,
    then: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required("Please select a concession month"),
          isHalfMonth: Yup.boolean(),
        })
      )
      .min(1, "Please add at least one concession item")
      .required("Please select months of concessions"),
    otherwise: Yup.array(),
  }),
  numberOfMonths: Yup.number().when("concessionType", {
    is: CONCESSION_TYPES.amortize,
    then: Yup.number()
      .nullable()
      .required("Please select months of concessions"),
    otherwise: Yup.number().nullable(),
  }),
};

export const CONCESSION_TYPE_VALIDATION = Yup.string()
  .oneOf(Object.values(CONCESSION_TYPES))
  .required("Please select the type of concession");

export const getMonthCountBySelectedDates = (dates: ConcessionFormMonth[]) => {
  return sumBy(dates, (date) => {
    if (!date.value) {
      return 0;
    }

    return date.isHalfMonth ? 0.5 : 1;
  });
};

export const convertModelConcessionMonthToForm = (
  item: ConcessionMonth
): ConcessionFormMonth => ({
  value: `${item.month}, ${item.year}`,
  isHalfMonth: Number(item.duration) === 0.5,
});

export const convertFormConcessionMonthToModel = (
  item: ConcessionFormMonth
): ConcessionMonth => {
  const date = dayjs(item.value, MONTH_FORMAT);
  return {
    month: date.format("MMM"),
    year: Number(date.format("YYYY")),
    duration: item.isHalfMonth ? 0.5 : 1,
  };
};
