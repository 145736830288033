import { setJwt, useLoadActiveUser } from "shared/utils/auth";

/**
 * Hook that exposes an API which can be used for different entry points (Basic login, SSO, Self-signed deep links).
 * The API exposes a `login` method which implements the following flow:
 * - It makes a POST login request with the appropriate params
 * - After a successful login request we receive the JWT auth token and the refresh token
 * - We store the JWT tokens in the localStorage
 *
 * @param params.loginCall the actual login request
 * @param params.onSuccess success callback
 * @param params.onError error callback
 */
const useLogin = ({ loginCall, onSuccess = null, onError = null }) => {
  const { loadActiveUser } = useLoadActiveUser();

  const login = async () => {
    try {
      const { data } = await loginCall();
      // store the tokens
      setJwt(data);

      await loadActiveUser();

      // handle success
      onSuccess?.(data);
    } catch (error) {
      // handle error
      onError?.(error);
    }
  };

  return { login };
};

export default useLogin;
