import React from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";

import FooterBar from "shared/components/FooterBar";
import LoaderContainer from "shared/components/LoaderContainer";

import LeaseLoading from "../LeaseLoading";

import { leaseDetailsPropType } from "../propTypes";

import LeaseFieldsFooter from "./LeaseFieldsFooter";
import LeaseFieldsForm from "./LeaseFieldsForm";
import { feeFieldsPropType, totalRentCalcFieldsPropType } from "./propTypes";

import { StyledForm } from "./styled";
import {
  getUpdatedDocument,
  getDocumentLabel,
  leaseFieldsValidationSchema,
} from "./utils";

const LeaseFieldsStep = ({
  documents,
  isLoading,
  onFinishLater,
  formikRef,
  initialValues,
  onNextStep,
  onPrevStep,
  isUpdatingMetadata,
  lease,
  feeFields,
  totalRentCalcFields,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formikRef}
      validationSchema={leaseFieldsValidationSchema}
      onSubmit={onNextStep}
    >
      {({ values }) => {
        let updatedDocuments = documents;
        if (updatedDocuments) {
          updatedDocuments = getUpdatedDocument(values.fields, documents).map(
            (document) => ({
              ...document,
              label: getDocumentLabel(document),
            })
          );
        }
        return (
          <LoaderContainer
            loading={isLoading}
            fetching={isUpdatingMetadata}
            Loader={<LeaseLoading />}
          >
            <StyledForm data-testid="lease-fields-form">
              <LeaseFieldsForm
                documents={documents}
                updatedDocuments={updatedDocuments}
                lease={lease}
                feeFields={feeFields}
                totalRentCalcFields={totalRentCalcFields}
              />
              <FooterBar.Spacer />
              <LeaseFieldsFooter
                onFinishLater={onFinishLater}
                onPrevStep={onPrevStep}
                isSubmitting={isUpdatingMetadata}
              />
            </StyledForm>
          </LoaderContainer>
        );
      }}
    </Formik>
  );
};

LeaseFieldsStep.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onFinishLater: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  formikRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  initialValues: PropTypes.object.isRequired,
  isUpdatingMetadata: PropTypes.bool.isRequired,
  lease: leaseDetailsPropType.isRequired,
  feeFields: feeFieldsPropType,
  totalRentCalcFields: totalRentCalcFieldsPropType.isRequired,
};

LeaseFieldsStep.defaultProps = {
  formikRef: undefined,
  feeFields: undefined,
};

export default LeaseFieldsStep;
