import React, { useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "renter/config/routes";
import { useLeases, useAcceptOffer, useRejectOffer } from "renter/hooks/api";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { LeaseApplicationStatus } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";

import {
  AcceptOfferFn,
  LeasesContainerProps,
  RejectOfferFn,
} from "./interfaces";
import LeaseRenewalConfirmationModal from "./LeaseRenewalConfirmationModal";
import Leases from "./Leases";
import { formatLease } from "./utils";

const TIMEOUT = 5000;
const EMPTY_LEASES = [];

const LeasesContainer = (props: LeasesContainerProps) => {
  const history = useHistory();
  const { openModalDialog } = useModal();

  const [selectedLeaseForRenewalId, setSelectedLeaseForRenewalId] =
    useState(null);

  const { data: leases = EMPTY_LEASES, refetch, isLoading } = useLeases({});
  const { acceptOffer } = useAcceptOffer();
  const { rejectOffer } = useRejectOffer();

  const { formattedLeases, isRefetchLeases } = useMemo(() => {
    const formattedLeasesTmp = leases.map((lease) => formatLease(lease));
    const isRefetchLeasesTmp = leases.some(
      (lease) => lease.status === LeaseApplicationStatus.processingSignature
    );
    return {
      formattedLeases: formattedLeasesTmp,
      isRefetchLeases: isRefetchLeasesTmp,
    };
  }, [leases]);

  const selectedLeaseForRenewal = useMemo(() => {
    return (formattedLeases || []).find(
      (l) => l.id === selectedLeaseForRenewalId
    );
  }, [formattedLeases, selectedLeaseForRenewalId]);

  const openLeaseApplication = (leaseApplicationId, leaseId) => {
    history.push(transformRoute(ROUTES.lease, { leaseApplicationId, leaseId }));
  };

  const onAcceptOffer: AcceptOfferFn = ({ leaseApplicationId, option }) => {
    return openModalDialog(LeaseRenewalConfirmationModal, {
      option,
      onConfirm: () => {
        return acceptOffer({ leaseApplicationId, optionId: option?.id });
      },
    }).afterClose;
  };

  const onRejectOffer: RejectOfferFn = ({ leaseApplicationId }) => {
    return openModalDialog(ConfirmationModal, {
      title: "Move out",
      message:
        "Please confirm that you do not intend to renew your lease. Doing so will mark you as Moving Out.",
      onConfirm: () => {
        return rejectOffer(
          { leaseApplicationId },
          { onSuccess: () => setSelectedLeaseForRenewalId(null) }
        );
      },
    }).afterClose;
  };

  const selectLeaseIdForRenewal = (id: number) => {
    setSelectedLeaseForRenewalId((val) => (val === id ? null : id));
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isRefetchLeases && !isLoading) {
      const refresher = setInterval(() => {
        refetch();
      }, TIMEOUT);
      return () => clearInterval(refresher);
    }
  }, [isLoading, isRefetchLeases]);

  return (
    <Leases
      formattedLeases={formattedLeases}
      loading={isLoading}
      openLeaseApplication={openLeaseApplication}
      selectedLeaseForRenewal={selectedLeaseForRenewal}
      selectLeaseIdForRenewal={selectLeaseIdForRenewal}
      acceptOffer={onAcceptOffer}
      rejectOffer={onRejectOffer}
      {...props}
    />
  );
};

export default LeasesContainer;
