import React, { useState } from "react";

import useModal from "shared/hooks/useModal";
import { RenewalOption } from "shared/interfaces/api/renewals";

import LeaseRenewalConfirmationModal from "./LeaseRenewalConfirmationModal";

interface LeaseRenewalConfirmationModalContainerProps {
  option: RenewalOption;
  onConfirm: () => Promise<any>;
}

const LeaseRenewalConfirmationModalContainer = ({
  option,
  onConfirm: onConfirmCallback,
}: LeaseRenewalConfirmationModalContainerProps) => {
  const [submitting, setSubmitting] = useState(false);
  const { selfApi } = useModal();

  const onConfirm = async () => {
    if (onConfirmCallback) {
      try {
        setSubmitting(true);
        await onConfirmCallback();
      } finally {
        setSubmitting(false);
      }
    }
    selfApi.setClosingValue(true);
  };

  return (
    <LeaseRenewalConfirmationModal
      option={option}
      onConfirm={onConfirm}
      submitting={submitting}
    />
  );
};

export default LeaseRenewalConfirmationModalContainer;
