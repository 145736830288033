import { range } from "airbnb-prop-types";
import PropTypes from "prop-types";

import { SSO_IDPS, SSO_PROVIDERS } from "shared/config/authConstants";
import {
  BREAKPOINT_TYPES,
  PERSONA_CHECK_STATUS,
  PERSONA_FILE_TYPE,
  PERSONA_ID_CLASS,
  PERSONA_VERIFICATION_STATUS,
  VERIFICATION_TYPE,
  COUNTY_CRIMINAL_SEARCH_STATUS,
  APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS,
  INCOME_ASSET_STATUS_CODES,
  VERIFICATION_OF_RESIDENCY_STATUS,
  VERIFICATION_STATUSES,
  DEAL_UI_STATUS_CODES,
} from "shared/config/constants";

export const keyPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

export const breakpointPropType = PropTypes.oneOf(
  Object.values(BREAKPOINT_TYPES)
);

export const questionPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  description: PropTypes.string,
});

export const actionFlagPropType = PropTypes.shape({
  value: PropTypes.bool.isRequired,
  helpText: PropTypes.string.isRequired,
  errorCode: PropTypes.string,
  params: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.string,
    })
  ),
});

export const leasePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  step: PropTypes.number,
  rent: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  securityDeposit: PropTypes.number,
  deleted: PropTypes.bool,
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    applicantNames: PropTypes.arrayOf(PropTypes.object).isRequired,
    unit: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    unitListingId: PropTypes.number.isRequired,
  }),
  status: PropTypes.string,
});

export const basicPropertyPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  yardiConnected: PropTypes.bool,
  yardiId: PropTypes.string,
});

export const basicUnitPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  yardiConnected: PropTypes.bool,
});

export const refPropType = PropTypes.oneOfType([
  PropTypes.shape({
    current: PropTypes.object,
  }),
  PropTypes.func,
]);

export const OptionPropType = PropTypes.shape({
  label: PropTypes.node.isRequired,
  value: keyPropType,
});

export const OptionsPropType = PropTypes.arrayOf(OptionPropType);

export const companyPropType = PropTypes.shape({
  id: keyPropType.isRequired,
  name: PropTypes.string,
  shortName: PropTypes.string,
  logo: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipcode: PropTypes.string,
  contactEmail: PropTypes.string,
  contactFirstName: PropTypes.string,
  contactLastName: PropTypes.string,
  contactPhone: PropTypes.string,
  contactPosition: PropTypes.string,
  isDismissed: PropTypes.bool,
  isDisabled: PropTypes.bool,
  enableMagicLink: PropTypes.bool,
  enableMagicLinkHack: PropTypes.bool,
  logoMagicLink: PropTypes.string,
  enableYardi: PropTypes.bool,
  yardiConnected: PropTypes.bool,
  yardiSuspended: PropTypes.bool,
  yardiConfigurations: PropTypes.shape({
    securityDepositPreference: PropTypes.string,
    rentEditableDuringLeasing: PropTypes.bool,
  }),
  blockDealSubmitIfIncomeDataIsMissing: PropTypes.bool.isRequired,
  enableConditionallyProceed: PropTypes.bool,
});

export const TypesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: keyPropType.isRequired,
    value: PropTypes.string.isRequired,
  })
);

export const backLinkPropType = PropTypes.shape({
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
});

export const paginationPropType = PropTypes.shape({
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
});

export const attachmentFilePropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  pending: PropTypes.bool,
  skipped: PropTypes.bool,
  toBeDeleted: PropTypes.bool,
  url: PropTypes.string,
});

export const requiredAttachmentPropType = PropTypes.shape({
  name: PropTypes.string,
  files: PropTypes.arrayOf(attachmentFilePropType),
  metadata: PropTypes.shape({
    requiredAttachmentId: PropTypes.number,
  }),
  skipped: PropTypes.bool,
});

export const requiredAttachmentsPropType = PropTypes.arrayOf(
  requiredAttachmentPropType
);

export const otherAttachmentPropType = PropTypes.shape({
  metadata: PropTypes.shape({
    requiredAttachmentId: PropTypes.number,
  }),
  file: attachmentFilePropType,
});

export const otherAttachmentsPropType = PropTypes.arrayOf(
  otherAttachmentPropType
);

export const ocrResultPropType = PropTypes.shape({
  formType: PropTypes.string,
  formUuid: PropTypes.string,
  fraudSignals: PropTypes.array,
  url: PropTypes.string,
  captured: PropTypes.object,
  fraudVisualizations: PropTypes.array,
});

export const ocrResultsPropType = PropTypes.arrayOf(ocrResultPropType);

export const PAYMENT_PATTERN_STATUSES = {
  ON_TIME: "On Time",
  LATE_30_DAYS: "30 Days Late",
  LATE_60_DAYS: "60 Days Late",
  LATE_90_DAYS: "90 Days Late",
  LATE_120_DAYS: "120 Days Late",
  UNCLASSIFIED: "Unclassified",
};

export const paymentPatternPropType = PropTypes.arrayOf(
  PropTypes.shape({
    year: PropTypes.string.isRequired,
    months: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(PAYMENT_PATTERN_STATUSES))
    ).isRequired,
  })
);

export const creditScoreSummaryPropType = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  middleName: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  akas: PropTypes.arrayOf(PropTypes.string.isRequired),
  score: PropTypes.number.isRequired,
  creditLimit: PropTypes.number,
  creditBalance: PropTypes.number,
  percentageAvailable: range(0, 101),
  scoreFactors: PropTypes.arrayOf(PropTypes.string).isRequired,
  fraudIndicators: PropTypes.arrayOf(PropTypes.string).isRequired,
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zipcode: PropTypes.string.isRequired,
      dateReported: PropTypes.string,
    })
  ),
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: keyPropType.isRequired,
      status: PropTypes.string.isRequired,
      terms: PropTypes.string.isRequired,
      scheduledPayments: PropTypes.number,
      rating: PropTypes.string,
      type: PropTypes.string,
      balance: PropTypes.number,
      dateOpened: PropTypes.string.isRequired,
      worstPaymentStatus: PropTypes.string,
      pastDueBalance: PropTypes.number,
      times30DaysLate: PropTypes.number,
      times60DaysLate: PropTypes.number,
      times90DaysLate: PropTypes.number,
      paymentPatterns: paymentPatternPropType,
    })
  ).isRequired,
});

export const criminalRecordPropType = PropTypes.shape({
  date: PropTypes.string,
  type: PropTypes.string,
  docket: PropTypes.string.isRequired,
  outcome: PropTypes.string,
  severity: PropTypes.string,
  felony: PropTypes.bool,
  chargeText: PropTypes.string,
});

export const criminalRecordsPropType = PropTypes.shape({
  criminalRecordGroup: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string,
      state: PropTypes.string.isRequired,
      numRecords: PropTypes.number,
      records: PropTypes.arrayOf(criminalRecordPropType),
    })
  ).isRequired,
  lastRecord: criminalRecordPropType,
  lastRecordState: PropTypes.string,
  states: PropTypes.number,
  totalRecords: PropTypes.number,
  sexualCrime: PropTypes.bool.isRequired,
  biggestSeverity: PropTypes.string,
  felony: PropTypes.bool,
});

export const evictionRecordPropType = PropTypes.shape({
  caseType: PropTypes.string.isRequired,
  fileNumber: PropTypes.string.isRequired,
  judgement: PropTypes.string.isRequired,
  judgementAmount: PropTypes.number,
});

export const countyCriminalSearchStatusPropType = PropTypes.oneOf(
  Object.values(COUNTY_CRIMINAL_SEARCH_STATUS)
);

export const applicationCountyCriminalSearchStatusPropType = PropTypes.oneOf(
  Object.values(APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS)
);

export const countyCriminalRecordsPropType = PropTypes.shape({
  criminalRecordsByCounty: PropTypes.arrayOf(
    PropTypes.shape({
      county: PropTypes.string.isRequired,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      records: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          courtRecordId: PropTypes.string.isRequired,
          chargeStatus: PropTypes.string,
          chargeDescription: PropTypes.string,
          severity: PropTypes.string,
        })
      ),
    })
  ),
});

export const evictionsPropType = PropTypes.shape({
  evictionRecordGroup: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string,
      state: PropTypes.string.isRequired,
      numRecords: PropTypes.number,
      records: PropTypes.arrayOf(evictionRecordPropType),
    })
  ).isRequired,
  lastRecord: evictionRecordPropType,
  lastRecordState: PropTypes.string,
  states: PropTypes.number,
  totalEvictions: PropTypes.number,
});

export const applicantTypesPropType = PropTypes.objectOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
  })
);

export const verificationFilePropType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  key: PropTypes.oneOf(Object.values(PERSONA_FILE_TYPE)).isRequired,
});

export const verificationDocumentClassPropType = PropTypes.oneOf(
  Object.values(PERSONA_ID_CLASS)
);

export const verificationTypePropType = PropTypes.oneOf(
  Object.values(VERIFICATION_TYPE)
);

export const verificationOfResidencyStatusTypePropType = PropTypes.oneOf(
  Object.values(VERIFICATION_OF_RESIDENCY_STATUS)
);

export const verificationStatusPropType = PropTypes.oneOf(
  Object.values(PERSONA_VERIFICATION_STATUS)
);

export const verificationCheckPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  reasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  status: PropTypes.oneOf(Object.values(PERSONA_CHECK_STATUS)).isRequired,
});

export const verificationPropType = PropTypes.shape({
  verificationType: verificationTypePropType.isRequired,
  status: verificationStatusPropType,
  verificationDate: PropTypes.string,
  details: PropTypes.shape({
    id: PropTypes.number.isRequired,
    verificationFiles: PropTypes.arrayOf(verificationFilePropType),
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    inquiryId: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    checks: PropTypes.arrayOf(verificationCheckPropType),
    dateOfBirth: PropTypes.string,
    identificationNumber: PropTypes.string,
    completedAt: PropTypes.string,
    addressStreet: PropTypes.string,
    addressSecondLine: PropTypes.string,
    addressCity: PropTypes.string,
    addressSubdivision: PropTypes.string,
    addressSubdivisionAbbr: PropTypes.string,
    addressPostalCode: PropTypes.string,
    addressPostalCodeAbbr: PropTypes.string,
    addressCountryCode: PropTypes.string,
    expirationDate: PropTypes.string,
    phoneNumber: PropTypes.string,
    documentClass: verificationDocumentClassPropType,
    application: PropTypes.number,
  }),
});

export const idVerificationPropType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  verificationFiles: PropTypes.arrayOf(verificationFilePropType),
  attributes: PropTypes.shape({
    addressCity: PropTypes.string,
    addressPostalCode: PropTypes.string,
    addressStreet1: PropTypes.string,
    addressStreet2: PropTypes.string,
    addressSubdivision: PropTypes.string,
    backPhotoUrl: PropTypes.string,
    birthdate: PropTypes.string,
    birthplace: PropTypes.string,
    captureMethod: PropTypes.string,
    checks: PropTypes.arrayOf(
      PropTypes.shape({
        metadata: PropTypes.object,
        name: PropTypes.string,
        reasons: PropTypes.array,
        requirement: PropTypes.string,
        status: PropTypes.string,
      })
    ),
    completedAt: PropTypes.number,
    completedAtTs: PropTypes.string,
    countryCode: PropTypes.string,
    createdAt: PropTypes.number,
    createdAtTs: PropTypes.string,
    designations: PropTypes.string,
    documentNumber: PropTypes.string,
    endorsements: PropTypes.string,
    entityConfidenceReasons: PropTypes.arrayOf(PropTypes.string),
    entityConfidenceScore: PropTypes.number,
    expirationDate: PropTypes.string,
    frontPhotoUrl: PropTypes.string,
    height: PropTypes.string,
    idClass: PropTypes.string,
    identificationNumber: PropTypes.string,
    issueDate: PropTypes.string,
    issuingAuthority: PropTypes.string,
    issuingSubdivision: PropTypes.string,
    nameFirst: PropTypes.string,
    nameLast: PropTypes.string,
    nameMiddle: PropTypes.string,
    nameSuffix: PropTypes.string,
    nationality: PropTypes.string,
    photoUrls: PropTypes.object,
    restrictions: PropTypes.string,
    selfiePhoto: PropTypes.object,
    selfiePhotoUrl: PropTypes.string,
    sex: PropTypes.string,
    status: PropTypes.string,
    submittedAt: PropTypes.string,
    submittedAtTs: PropTypes.number,
    vehicleClass: PropTypes.string,
    videoUrl: PropTypes.string,
    visaStatus: PropTypes.string,
  }),
});

export const ssoProviderPropType = PropTypes.shape({
  name: PropTypes.oneOf(Object.values(SSO_PROVIDERS)).isRequired,
  type: PropTypes.oneOf(Object.values(SSO_IDPS)).isRequired,
  baseUrl: PropTypes.string.isRequired,
});

export const userProfilePropType = PropTypes.shape({
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  birthdate: PropTypes.string,
  phoneNumber: PropTypes.string,
});

const verifiedPercentage = range(0, 101);

export const incomeAndAssetPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  incomes: PropTypes.arrayOf(
    PropTypes.shape({
      frequency: PropTypes.string,
      id: PropTypes.number,
      isIncluded: PropTypes.bool,
      lastPayment: PropTypes.string,
      maxIncome: PropTypes.number,
      meanIncome: PropTypes.number,
      medianIncome: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountType: PropTypes.string,
      availableBalance: PropTypes.number,
      currentBalance: PropTypes.number,
      id: PropTypes.number,
      isIncluded: PropTypes.bool,
      name: PropTypes.string,
      institutionName: PropTypes.string,
    })
  ).isRequired,
  percentIncomeVerified: verifiedPercentage,
  totalIncome: PropTypes.number,
  totalAssets: PropTypes.number,
  averageMonthlyIncome: PropTypes.string,
  incomeOverRent: PropTypes.number.isRequired,
  statedEmployer: PropTypes.arrayOf(PropTypes.string),
  isInTheGreen: PropTypes.bool.isRequired,
  incomeVerificationStatus: PropTypes.oneOf(
    Object.values(VERIFICATION_STATUSES)
  ),
  assetsVerificationStatus: PropTypes.oneOf(
    Object.values(VERIFICATION_STATUSES)
  ),
  incomeStatus: PropTypes.oneOf(Object.values(INCOME_ASSET_STATUS_CODES)),
  assetsStatus: PropTypes.oneOf(Object.values(INCOME_ASSET_STATUS_CODES)),
  notes: PropTypes.string,
});

export const dealUiStatusPropType = PropTypes.oneOf(
  Object.values(DEAL_UI_STATUS_CODES)
);
