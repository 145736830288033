import React from "react";

import { useFormikContext } from "formik";
import startCase from "lodash/startCase";

import { Input, VeroFormField } from "shared/components/Form";
import { RadioGroup } from "shared/components/Form/RadioGroup";
import Icon from "shared/components/Icon";
import Tooltip from "shared/components/Tooltip";
import { FIELD_TYPES, YES_NO_OPTIONS } from "shared/config/constants";

import {
  SetupDocumentsFieldsFormikValues,
  SetupDocumentsFieldsProps,
} from "./interfaces";
import {
  CheckboxContainer,
  CheckboxTitle,
  FieldContainer,
  FieldsContainer,
} from "./styled";

const SetupDocumentsFields = ({ modalView }: SetupDocumentsFieldsProps) => {
  const { values } = useFormikContext<SetupDocumentsFieldsFormikValues>();
  const { fields = [] } = values ?? {};

  return (
    <FieldsContainer modalView={modalView}>
      {fields
        .sort((first, second) => (first.type > second.type ? -1 : 1))
        .map((field, index) => (
          <FieldContainer key={field.id} modalView={modalView}>
            {field.type === FIELD_TYPES.checkbox ? (
              <CheckboxContainer modalView={modalView}>
                <CheckboxTitle>{field.description}</CheckboxTitle>
                <VeroFormField
                  as={RadioGroup}
                  id={`fields[${index}].value`}
                  name={`fields[${index}].value`}
                  label={field.description}
                  options={YES_NO_OPTIONS}
                />
              </CheckboxContainer>
            ) : (
              <div>
                <VeroFormField
                  as={Input}
                  id={`fields[${index}].value`}
                  name={`fields[${index}].value`}
                  label={startCase(field.name)}
                  disabled={!field.isEditable}
                />
              </div>
            )}
            <Tooltip
              theme="light"
              title={
                <ul>
                  {field?.documentsAssigned?.map((documentName) => (
                    <li key={documentName}>{documentName}</li>
                  ))}
                </ul>
              }
            >
              <Icon.InfoIcon />
            </Tooltip>
          </FieldContainer>
        ))}
    </FieldsContainer>
  );
};

export default SetupDocumentsFields;
