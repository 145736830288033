import React from "react";

import { UnitIntegrationMark } from "manager/components/IntegrationMark";
import { UNIT_STATUSES } from "manager/config/constants";
import {
  LeaseGroup,
  LeaseGroupApplication,
} from "manager/interfaces/api/leaseGroups";
import Collapse from "shared/components/Collapse";
import DetectableOverflowTooltip from "shared/components/DetectableOverflowTooltip";
import DropdownMenu from "shared/components/DropdownMenu";
import FlexContainer from "shared/components/FlexContainer";
import { RegularText, SmallTitle } from "shared/components/Typography";
import { useDeviceType, usePermissions } from "shared/hooks";
import { getValueOrNA } from "shared/utils/ui";

import { StyledLeaseGroupHeader } from "./styled";
import {
  getLeaseGroupHeaderStatus,
  useGetLeaseGroupHeaderDropdown,
} from "./utils";

const LeaseGroupHeader = ({
  leaseGroup,
  residents,
}: {
  leaseGroup: LeaseGroup;
  residents: LeaseGroupApplication[];
}) => {
  const { areRenewalsEnabled } = usePermissions();
  const { isMobile } = useDeviceType();
  const { activeLease } = leaseGroup || {};
  const menuItems = useGetLeaseGroupHeaderDropdown(leaseGroup, residents);
  const propertyUnitName = `${activeLease?.unit?.propertyName}, ${activeLease?.unit?.name}`;
  const unitStatus = getValueOrNA(
    UNIT_STATUSES[activeLease?.unit?.status]?.label
  );
  const agentName = getValueOrNA(leaseGroup?.employee?.name);
  const headerStatus = getLeaseGroupHeaderStatus(
    leaseGroup,
    areRenewalsEnabled &&
      (leaseGroup?.isActiveLeaseExpiring || leaseGroup?.isRenewalLeaseCreated)
  );
  const propertyTitle = (
    <>
      <SmallTitle strong className="text-white header-title">
        <DetectableOverflowTooltip title={propertyUnitName}>
          {propertyUnitName}
        </DetectableOverflowTooltip>
      </SmallTitle>
      <UnitIntegrationMark unit={activeLease?.unit} dark />
    </>
  );

  const content = (
    <>
      <RegularText strong className="unit-status text-white">
        {isMobile ? "Status:" : null} {unitStatus}
      </RegularText>
      {!isMobile ? <div className="vertical-divider" /> : null}
      <RegularText strong className="text-white">
        Agent: {agentName}
      </RegularText>
    </>
  );

  return (
    <StyledLeaseGroupHeader>
      {!isMobile ? (
        <FlexContainer alignItems="center" className="header-property-info">
          {propertyTitle}
          {content}
        </FlexContainer>
      ) : (
        <Collapse
          header={({ CollapseIcon }) => (
            <FlexContainer alignItems="center" justifyContent="space-between">
              {propertyTitle}
              <CollapseIcon className="collapse-icon" />
            </FlexContainer>
          )}
          className="header-property-info"
        >
          <FlexContainer flexDirection="column">{content}</FlexContainer>
        </Collapse>
      )}
      <FlexContainer
        alignItems="center"
        justifyContent="space-between"
        className="header-notice-info"
      >
        <RegularText strong className="text-white">
          {headerStatus}
        </RegularText>
        <DropdownMenu placement="bottomRight" items={menuItems} />
      </FlexContainer>
    </StyledLeaseGroupHeader>
  );
};

export default LeaseGroupHeader;
