import dayjs from "dayjs";
import cloneDeep from "lodash/cloneDeep";
import range from "lodash/range";
import pluralize from "pluralize";
import * as Yup from "yup";

import {
  CONCESSION_TYPE_VALIDATION,
  CONCESSION_DATA_VALIDATION,
} from "manager/components/Leasing/Concession";
import {
  VALIDATION_MESSAGES,
  YEAR_MONTH_DATE_FORMAT,
} from "shared/config/constants";
import { addMonthsNormalized } from "shared/utils/dates";

import { MAXIMUM_LEASE_DURATION } from "./LeaseInfoStep.constants";

export const INITIAL_VALUES = Object.freeze({
  /** @type {string} - date */
  leaseStartDate: undefined,
  /** @type {string} - date */
  leaseEndDate: undefined,
  /** @type {number} */
  leaseDuration: undefined,
  /** @type {boolean} */
  endDateToEndOfTheMonth: false,
  /** @type {number} cents */
  monthlyRent: undefined,
  /** @type {number} - cents */
  securityDeposit: undefined,
  /** @type {boolean} */
  isConcessionsEnabled: undefined,
  hasConcessionDocuments: false,
  /** @type {string} - ["AM", "SM"] */
  concessionType: undefined,
  /** @type {number} - 0.5, 1, 1.5, etc. */
  numberOfMonths: null,
  /** @type {Array.<{ value: string, isHalfMonth: boolean }>} */
  dates: [],
  prorateFirstMonthRent: false,
});

/**
 *
 * @param {string} [name]
 * @returns {Object|any} initialValues | value
 */
export const getInitialValues = (name) => {
  const values = cloneDeep(INITIAL_VALUES);
  return name ? values[name] : values;
};

// NOTE we are removing 1 day to match BE calculation
export const getMinLeaseDurationDate = (leaseStartDate, minLeaseDuration) => {
  if (minLeaseDuration) {
    return addMonthsNormalized(leaseStartDate, minLeaseDuration);
  }
  return dayjs(leaseStartDate);
};

export const validationSchema = (minLeaseDurationMonths) =>
  Yup.object().shape({
    leaseStartDate: Yup.string()
      .nullable()
      .required("Lease Start Date is required"),
    leaseEndDate: Yup.string()
      .nullable()
      .required("Lease End Date is required")
      .when("leaseStartDate", (leaseStartDate, schema) => {
        const minMonths = minLeaseDurationMonths || 1;
        const minLimit = getMinLeaseDurationDate(leaseStartDate, minMonths);
        const maxLimit = dayjs(leaseStartDate).add(30, "years");

        return schema.test(
          "leaseDurationMonths",
          `The Lease duration cannot be less than ${minMonths} ${pluralize(
            "month",
            minMonths
          )}`,
          (value) => {
            return (
              value &&
              !(
                dayjs(value).isBefore(minLimit) ||
                dayjs(value).isAfter(maxLimit)
              )
            );
          }
        );
      }),
    leaseDuration: Yup.number().nullable(),
    endDateToEndOfTheMonth: Yup.bool().nullable(),
    monthlyRent: Yup.number()
      .nullable()
      .positive("Please enter a rent amount that is greater than $0")
      .required("Monthly Rent is required"),
    securityDeposit: Yup.number()
      .nullable()
      .required("Security Deposit is required"),
    isConcessionsEnabled: Yup.bool().required("This field is required"),
    concessionType: Yup.string().when("isConcessionsEnabled", {
      is: true,
      then: CONCESSION_TYPE_VALIDATION,
      otherwise: Yup.string().nullable(),
    }),
    ...CONCESSION_DATA_VALIDATION,
    managerAnswers: Yup.array().of(
      Yup.object().shape({
        answerOption: Yup.string().required(VALIDATION_MESSAGES.fieldRequired),
      })
    ),
    prorateFirstMonthRent: Yup.bool(),
  });

export const getLeaseDurationOptions = (minMonths) => {
  // [1..MAXIMUM_LEASE_DURATION]
  const options = range(1, MAXIMUM_LEASE_DURATION + 1);

  if (minMonths) {
    return options.slice(minMonths - 1);
  }
  return options;
};

// NOTE we are removing 1 day to match BE calculation
// If leaseStartDate is 01/01/2022 and leaseDuration is 3 months we should get 03/31/2022 instead of 04/01/2022
export const calculateLeaseEndDate = (
  leaseStartDate,
  leaseDuration,
  endDateToEndOfTheMonth
) => {
  let endDate = addMonthsNormalized(leaseStartDate, leaseDuration);
  if (endDateToEndOfTheMonth) {
    endDate = endDate.endOf("month");
  }
  endDate = endDate.format(YEAR_MONTH_DATE_FORMAT);

  return endDate;
};

export const isFirstMonthProRateEnabled = (lease) => lease.useProratedFields;
