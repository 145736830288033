import React from "react";

import sortBy from "lodash/sortBy";
import { useHistory } from "react-router-dom";

import { useUpsertApplicantQuestion } from "manager/hooks/api";

import { CONTENT_TYPE } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

import EditApplicantQuestionModal from "./EditApplicantQuestionModal";

const EditApplicantQuestionModalContainer = () => {
  const history = useHistory();
  const { currentModalContext, selfApi } = useModal();
  const { propertyId, question, documentTemplates, applicantQuestions } =
    currentModalContext;
  const { upsertApplicantQuestion } = useUpsertApplicantQuestion(propertyId);
  const initialValues = {
    propertyId,
    answers: {},
    ...question,
    options: sortBy(
      question?.options?.map((option) => ({
        ...option,
        documents: option.documents.map(({ id }) => `${id}`),
      })),
      "position"
    ),
  };

  const buildModel = (values) => ({
    ...values,
    id: undefined,
    options: values.options.map((option) => {
      const docIds = option?.documents.map((id) => `${id}`);
      return {
        ...option,
        documents: documentTemplates.filter(({ id }) =>
          docIds.includes(`${id}`)
        ),
      };
    }),
    objectId: propertyId,
    contentType: CONTENT_TYPE.property,
  });

  const submit = (values, formikApi) => {
    const onSuccess = () => selfApi.closeDialog();

    const onError = onDialogSubmitError(formikApi, history);

    const leaseQuestions = applicantQuestions?.filter(
      ({ id, contentType }) =>
        id !== values.id && contentType === CONTENT_TYPE.property
    );

    leaseQuestions.push(buildModel(values));

    upsertApplicantQuestion(
      {
        id: propertyId,
        leaseQuestions: leaseQuestions.map((q) => ({
          ...q,
          id: undefined,
        })),
      },
      { onSuccess, onError }
    );
  };

  const getDocuments = () =>
    sortBy(
      documentTemplates?.filter(({ isActive }) => isActive),
      "position"
    ).map(({ id, name }) => ({ key: id, label: name }));

  return (
    <EditApplicantQuestionModal
      initialValues={initialValues}
      submit={submit}
      documents={getDocuments()}
      questionName={question.questionName}
    />
  );
};

export default EditApplicantQuestionModalContainer;
