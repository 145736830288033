import React, { useMemo } from "react";

import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";

import {
  useRenewalActions,
  useRenewalHelpers,
} from "manager/components/Renewals";
import {
  LeaseGroup,
  LeaseGroupApplication,
} from "manager/interfaces/api/leaseGroups";
import { ApplicantTypes } from "shared/config/constants/appTyped.constants";
import { useApplicantTypes } from "shared/hooks/api";
import { contactByEmails } from "shared/utils/ui";

import useLeaseGroupResidentsDropdown from "../utils/useGetLeaseResidentsDropdown";

import LeaseGroupResidents from "./LeaseGroupResidents";

const LeaseGroupResidentsContainer = ({
  leaseGroup,
  residents,
}: {
  leaseGroup: LeaseGroup;
  residents: LeaseGroupApplication[];
}) => {
  const { data: types } = useApplicantTypes();
  const applicantTypes = keyBy(types, "id");
  const { residentsHelpers } = useRenewalHelpers({
    renewal: leaseGroup?.renewal,
  });
  const { renewalActions } = useRenewalActions();

  const { applicantsGuarantors, occupants } = useMemo(() => {
    const allApplicants = groupBy(residents, "applicantType");

    return {
      applicantsGuarantors: [
        ...(allApplicants[ApplicantTypes.applicant] || []),
        ...(allApplicants[ApplicantTypes.guarantor] || []),
      ],
      occupants: allApplicants[ApplicantTypes.occupant] || [],
    };
  }, [residents]);

  const residentsActions = useLeaseGroupResidentsDropdown(
    leaseGroup,
    residents
  );

  const contactAll = () => {
    const emails = residents?.map((item) => item.email);
    contactByEmails(emails);
  };

  const inviteRenewalResident = () => {
    renewalActions.addResident(leaseGroup?.renewal);
  };

  return (
    <LeaseGroupResidents
      applicantsGuarantors={applicantsGuarantors}
      occupants={occupants}
      applicantTypes={applicantTypes}
      residentsActions={residentsActions}
      contactAll={contactAll}
      inviteRenewalResident={
        residentsHelpers.isInviteResidentEnabled ? inviteRenewalResident : null
      }
    />
  );
};

export default LeaseGroupResidentsContainer;
