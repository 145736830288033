import React from "react";

import {
  AnswerListContainer,
  AnswerContainer,
  AnswerApplicant,
  Answer,
  AnswerDescription,
} from "../styled";

export interface AnswerListProps {
  answers?: {
    id?: number;
    applicant?: string;
    answer?: string;
    description?: string;
  }[];
}

const AnswerList = ({ answers = [] }: AnswerListProps) => (
  <AnswerListContainer data-testid="answers-container">
    {answers.map((answer) => (
      <AnswerContainer key={`${answer.applicant}`} data-testid="answer">
        <AnswerApplicant>{answer.applicant}</AnswerApplicant>
        <Answer>{answer.answer}</Answer>
        {answer.description && (
          <AnswerDescription>{answer.description}</AnswerDescription>
        )}
      </AnswerContainer>
    ))}
  </AnswerListContainer>
);

export default AnswerList;
