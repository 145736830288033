import React from "react";

import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { useGetRenewal } from "manager/hooks/api";
import LeaseGroup from "manager/pages/LeaseGroup";
import LoaderContainer, {
  BigLoadingSpinner,
} from "shared/components/LoaderContainer";
import { BackLinkProps } from "shared/interfaces/misc";
import Layout from "shared/templates/Layout";

const backLink: BackLinkProps = {
  to: ROUTES.renewals,
  children: "Renewals",
};

const RenewalContainer = ({ sidebar, header }) => {
  const { id } = useParams() as any;
  const { renewal, isRenewalLoading } = useGetRenewal(id && Number(id));
  const pageTitle = `${renewal?.unit?.name} RENEWAL`;

  return (
    <Layout
      sidebar={sidebar}
      header={{
        ...header,
        backLink,
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <LoaderContainer
        loading={isRenewalLoading}
        Loader={<BigLoadingSpinner />}
      >
        <LeaseGroup leaseGroupId={renewal?.leaseGroupId} backLink={backLink} />
      </LoaderContainer>
    </Layout>
  );
};

export default RenewalContainer;
