import React from "react";

import { Row, Col } from "antd";
import PropTypes from "prop-types";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import StepsWheel, { STEPS_WHEEL_THEMES } from "shared/components/StepsWheel";
import Tile from "shared/components/Tile";
import { COLORS } from "shared/config/constants";

import {
  APPLICATION_BAR_STEPS,
  APPLICATION_BAR_TITLE,
  STEPS_ORDER,
} from "../config";

import ApplicationAnswersBar from "./ApplicationAnswersBar";
import ApplicantList from "./components/ApplicantList";
import {
  AddressContainer,
  Address,
  Portfolio,
  RentDivider,
  TileIcon,
} from "./styled";

const Header = ({
  isApplicationAnswersLoading,
  applicantNames,
  propertyAddress,
  portfolioName,
  currentStep,
  openAnswersModal,
}) => {
  const showApplicationBar = APPLICATION_BAR_STEPS.includes(currentStep);
  const applicationBarTitle = APPLICATION_BAR_TITLE[currentStep];

  return (
    <Tile>
      <Tile.Inner spacer deepViolet>
        <Row type="flex" align="middle" gutter={[10, 10]}>
          <Col sm={24} md={11} lg={11} xl={11}>
            <ApplicantList applicantsList={applicantNames} />
          </Col>

          <Col sm={0} md={1} lg={1} xl={1}>
            <RentDivider type="vertical" />
          </Col>

          <Col sm={24} md={12} lg={12} xl={12}>
            <FlexContainer alignItems="center">
              <TileIcon>
                <Icon.MyPropertyIcon
                  style={{ stroke: COLORS.violetTheme.text }}
                />
              </TileIcon>
              <AddressContainer>
                <Address>{propertyAddress}</Address>
                <Portfolio>{portfolioName}</Portfolio>
              </AddressContainer>
            </FlexContainer>
          </Col>
        </Row>
      </Tile.Inner>

      <Tile.Inner violet>
        <FlexContainer alignItems="center" flexWrap="wrap">
          <StepsWheel
            current={currentStep - 1}
            items={STEPS_ORDER}
            theme={STEPS_WHEEL_THEMES.violet}
            data-testid="steps-wheel"
          />
        </FlexContainer>
      </Tile.Inner>
      {showApplicationBar && (
        <ApplicationAnswersBar
          isApplicationAnswersLoading={isApplicationAnswersLoading}
          openAnswersModal={openAnswersModal}
          title={applicationBarTitle}
        />
      )}
    </Tile>
  );
};

Header.propTypes = {
  isApplicationAnswersLoading: PropTypes.bool.isRequired,
  applicantNames: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      id: PropTypes.number,
    })
  ),
  currentStep: PropTypes.number,
  portfolioName: PropTypes.string,
  propertyAddress: PropTypes.string,
  openAnswersModal: PropTypes.func.isRequired,
};

Header.defaultProps = {
  applicantNames: [],
  currentStep: 0,
  portfolioName: "",
  propertyAddress: "",
};

export default Header;
