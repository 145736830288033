import styled from "@emotion/styled";

import Container from "shared/components/Container";
import FlexContainer from "shared/components/FlexContainer";
import Tile from "shared/components/Tile";
import Well from "shared/components/Well";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { CenteredFlexPartialCss } from "shared/styles/commonStyles";

const StyledLeaseGroupContainer = styled(Container)`
  flex-grow: 1;

  .text-white {
    color: ${COLORS.white} !important;
  }

  .lease-group-details-row {
    margin-bottom: 12px;
    ${MEDIA_QUERIES.w.ltLg} {
      flex-direction: column;
    }
  }

  .lease-group-footer {
    > *:not(:last-child) {
      margin-right: 24px;
    }

    justify-content: flex-end;

    .footer-note {
      margin-right: auto;
    }
  }
`;

const StyledLeaseGroupHeader = styled(FlexContainer)`
  overflow: hidden;
  border-radius: 4px;
  height: 67px;

  .header-property-info {
    flex-grow: 1;
    background: ${COLORS.darkViolet2};
    padding: 0 24px;

    .yardi-icon {
      margin-left: 12px;
    }

    .unit-status {
      margin-left: auto;
    }

    .vertical-divider {
      width: 1px;
      height: 28px;
      background: ${COLORS.lightViolet};
      margin: 0 20px;
    }

    .header-title {
      max-width: 600px;
    }
  }

  .header-notice-info {
    background: ${COLORS.lightViolet};
    width: 440px;
    padding: 0 24px;
  }

  .collapse-icon {
    width: 12px;
    height: 8px;
    path {
      stroke: ${COLORS.white};
    }
  }

  ${MEDIA_QUERIES.w.ltXxl} {
    .header-property-info {
      .header-title {
        max-width: 400px;
      }
    }
  }

  ${MEDIA_QUERIES.w.ltXl} {
    .header-property-info {
      .header-title {
        max-width: 300px;
      }
    }
  }

  ${MEDIA_QUERIES.w.ltLg} {
    flex-direction: column;
    height: initial;

    .header-notice-info {
      width: initial;
      padding: 12px 24px;
    }

    .header-property-info {
      padding: 12px 24px;
    }
  }

  ${MEDIA_QUERIES.w.ltMd} {
    .header-property-info {
      .unit-status {
        margin-top: 12px;
        margin-left: 0;
      }
    }
  }

  ${MEDIA_QUERIES.w.ltSm} {
    .header-property-info {
      .header-title {
        max-width: 200px;
      }
    }
  }
`;

const StyledLeaseGroupStatus = styled(Well)`
  background: ${COLORS.darkViolet2};
  width: 180px;
  text-align: center;
  margin-right: 16px;

  ${CenteredFlexPartialCss}
  flex-direction: column;

  > *:not(.subtitle) {
    margin-bottom: 24px;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    width: 100%;
    margin-right: 0;
  }
`;

const StyledLeaseGroupDetails = styled(Tile)`
  flex-grow: 1;
`;

const StyledLeaseGroupResidents = styled(Tile)`
  .leaseholders-title {
    margin-right: auto;
  }

  .invite-icon {
    margin-right: 8px;
  }

  .resident-item {
    margin-bottom: 8px;

    .resident-details {
      flex-grow: 1;
      margin-right: 12px;
    }

    .resident-status {
      &.reject-status {
        color: ${COLORS.lightRed2};
      }

      &.new-status {
        color: ${COLORS.green};
      }
    }
  }
`;

const StyledLeaseGroupOffers = styled(Tile)`
  .offer-link {
    margin-left: auto;
    margin-right: 16px;
  }

  .lease-group-offer {
    background: ${COLORS.lightGray2};
  }
`;

const StyledLeaseGroupHistory = styled(Tile)`
  .lease-history-row {
    margin-bottom: 12px;
  }

  .lease-history-details {
    flex: 1;
  }

  .pdf-icon {
    margin-right: 8px;
  }
`;

enum StatusCircleThemes {
  default = "default",
  green = "green",
  purple = "purple",
}

const StatusCircleThemeColors = Object.freeze({
  [StatusCircleThemes.default]: COLORS.gray1,
  [StatusCircleThemes.green]: COLORS.lightGreen2,
  [StatusCircleThemes.purple]: COLORS.violetTheme.lightViolet,
});

interface StatusCircleProps {
  color?: keyof typeof StatusCircleThemes;
}

const statusCircleBackground = ({
  color = StatusCircleThemes.default,
}: StatusCircleProps) => {
  return StatusCircleThemeColors[color];
};

const LeaseStatusCircle = styled.div<StatusCircleProps>`
  height: 8px;
  width: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 50%;
  background: ${statusCircleBackground};
`;

const StyledPrimaryHeader = styled.div`
  background: ${COLORS.primary};
  padding: 8px 24px;
`;

const StyledGroupNotes = styled.div`
  .notes-section {
    max-height: 500px;
    overflow-y: auto;
    margin-top: 6px;
  }
`;

const StyledGroupAttachments = styled.div`
  .upload-link {
    margin-left: auto;
  }
`;

export {
  StyledLeaseGroupContainer,
  StyledLeaseGroupHeader,
  StyledLeaseGroupStatus,
  StyledLeaseGroupResidents,
  StyledLeaseGroupOffers,
  StyledLeaseGroupHistory,
  LeaseStatusCircle,
  StatusCircleThemes,
  StyledPrimaryHeader,
  StyledGroupNotes,
  StyledGroupAttachments,
  StyledLeaseGroupDetails,
};
