import React from "react";

import { useFormikContext } from "formik";

import { ConcessionMonthsPicker } from "manager/components/Leasing/Concession";

import {
  ConcessionDefaultProps,
  ConcessionPropTypes,
} from "./LeaseInfoForm.propTypes";

const LeaseConcessionMonthsPicker = ({
  concessionMonths,
  isConcessionMonthsLoading,
}) => {
  const {
    values: { leaseStartDate, leaseEndDate },
  } = useFormikContext();

  return (
    <ConcessionMonthsPicker
      leaseStartDate={leaseStartDate}
      leaseEndDate={leaseEndDate}
      concessionMonths={concessionMonths}
      isConcessionMonthsLoading={isConcessionMonthsLoading}
    />
  );
};

LeaseConcessionMonthsPicker.propTypes = {
  ...ConcessionPropTypes,
};

LeaseConcessionMonthsPicker.defaultProps = {
  ...ConcessionDefaultProps,
};

export default LeaseConcessionMonthsPicker;
