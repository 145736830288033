import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import Menu from "shared/components/Menu";
import { MEDIA_QUERIES } from "shared/config/constants";

const TilesContainer = styled("div")({
  display: "flex",
});

const FieldsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const FieldContainer = styled.div`
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
  &.hidden {
    visibility: hidden;
    height: 0;
    margin: 0;
  }
`;

const LeaseFieldsDescription = styled("p")({
  fontSize: 17,
  fontWeight: 300,
  lineHeight: 1.7,
  marginBottom: 24,

  [MEDIA_QUERIES.w.ltSm]: {
    paddingLeft: 20,
  },
});

const SpacerContainer = styled("div")({
  position: "relative",
});

const Spacer = styled("div")({
  position: "absolute",
  top: "-80px",
});

const DocumentsMenu = styled(Menu)({
  width: "290px",
  height: "max-content",
  backgroundColor: "#FFF",
  marginRight: "20px",
  padding: "7px 2px",
  fontSize: "15px",
  position: "sticky",
  top: "70px",
  [MEDIA_QUERIES.w.ltSm]: {
    display: "none",
  },
});

const Documents = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const TypeSection = styled("div")((props) => ({
  backgroundColor: "#F9FAFC",
  border: "1px solid #E8E7EE",
  height: "min-content",
  color: "#807E8D",
  padding: "32px 20px 40px 54px",
  maxWidth: "450px",
  fontSize: "12px",
  [MEDIA_QUERIES.w.ltLg]: {
    maxWidth: "100%",
    width: "auto",
  },
  ...(props.withMarginButtom && {
    marginBottom: 25,
  }),
}));

const SectionTitle = styled("div")({
  marginBottom: "24px",
  display: "flex",
  alignItems: "center",
});

const FieldsSection = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(2, minmax(150px, 500px))",
  gap: 25,
  [MEDIA_QUERIES.w.ltLg]: {
    display: "flex",
    flexDirection: "column",
  },
});

const InfoIcon = styled(Icon.LargeInfoIcon)({
  width: "20px",
  height: "20px",
  marginLeft: "2px",
});

const CheckboxContainer = styled.div({
  color: "#2D2943",
  width: "100%",
});

const CheckboxTitle = styled.p({
  fontWeight: 500,
  fontSize: 17,
  marginBottom: 10,
});

const AllSetSection = styled.div({
  backgroundColor: "#F9FAFC",
  color: " #2D2943",
  width: "100%",
  padding: "18px 35px",
  display: "flex",
  alignItems: "center",
});

const CompletedIcon = styled(Icon.ApprovedIcon)({
  width: "16px",
  height: "16px",
  marginRight: "12px",
});

const TooltipTitle = styled("div")({
  display: "flex",
  flexDirection: "column",
  "&>div": {
    marginBottom: "15px",
  },
  "& div:last-child": {
    marginBottom: "0 !important",
  },
});

const TooltipContainer = styled.div`
  margin-top: 10px;
  width: 10%;
`;

const InputContainer = styled.div`
  width: 90%;
`;

const InputField = styled.div`
  display: flex;
  width: 100%;
`;

export {
  TilesContainer,
  FieldsContainer,
  FieldContainer,
  LeaseFieldsDescription,
  SpacerContainer,
  Spacer,
  DocumentsMenu,
  Documents,
  TypeSection,
  SectionTitle,
  FieldsSection,
  InfoIcon,
  CheckboxContainer,
  CheckboxTitle,
  AllSetSection,
  CompletedIcon,
  TooltipTitle,
  InputField,
  TooltipContainer,
  InputContainer,
};
