import React from "react";

import { Lease } from "manager/interfaces/api/lease";
import { LeaseFeeFieldsModel } from "manager/interfaces/api/leaseFeeConfiguration";
import Tile from "shared/components/Tile";
import { MediumTitle } from "shared/components/Typography";

import { TotalRentCalcFields } from "../../interfaces";
import { LEASE_FEE_FIELDS_SECTION } from "../../utils";

import LeaseFeeFieldsCharges from "./LeaseFeeFieldsCharges";
import LeaseFeeFieldsRentableItems from "./LeaseFeeFieldsRentableItems";
import LeaseFeeFieldsSummary from "./LeaseFeeFieldsSummary";
import { FeeFieldsTile } from "./styled";

interface LeaseFeeFieldsSectionProps {
  feeFields: LeaseFeeFieldsModel;
  lease: Lease;
  totalRentCalcFields: TotalRentCalcFields;
}

const LeaseFeeFieldsSection = ({
  feeFields,
  lease,
  totalRentCalcFields,
}: LeaseFeeFieldsSectionProps) => {
  return (
    <FeeFieldsTile id={LEASE_FEE_FIELDS_SECTION.anchor}>
      <Tile.Inner className="border-bottom">
        <MediumTitle>Charges & Rentable Items</MediumTitle>
      </Tile.Inner>
      <LeaseFeeFieldsCharges feeFieldsList={feeFields?.charges} />
      <LeaseFeeFieldsRentableItems feeFieldsList={feeFields?.rentableItems} />
      <LeaseFeeFieldsSummary
        lease={lease}
        totalRentCalcFields={totalRentCalcFields}
      />
    </FeeFieldsTile>
  );
};

export default LeaseFeeFieldsSection;
