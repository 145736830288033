import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

export const Container = styled("div")({
  flexGrow: 1,
  margin: "0 24px",
  position: "relative",
  [MEDIA_QUERIES.w.ltMd]: {
    margin: 0,
  },
});

export const LinkWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  [MEDIA_QUERIES.w.gteLg]: {
    marginRight: "24px !important",
  },
});

export const footerStyles = {
  [MEDIA_QUERIES.w.ltSm]: {
    padding: "0 80px 0 10px",
  },
};
