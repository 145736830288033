import React from "react";

import { Helmet } from "react-helmet";

import { RenewalCategory } from "manager/config/constants";
import Container from "shared/components/Container";
import FilterBar, { DROPDOWN_FILTER_MODES } from "shared/components/FilterBar";
import MobileFilters from "shared/components/MobileFilters";
import PageTitle from "shared/components/PageTitle";
import RadioButtons, {
  RADIO_BUTTONS_THEMES,
} from "shared/components/RadioButtons";
import Spacer from "shared/components/Spacer";
import { LargeTitle } from "shared/components/Typography";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { ContentContainer } from "shared/templates/TablePage/styled";

import { RenewalsConditionalPage } from "../RenewalsConditionalPage";

import { RENEWAL_CATEGORIES_LIST } from "./constants";
import { RenewalsProps } from "./interfaces";
import RenewalsTable from "./RenewalsTable";
import { Header, MobileFiltersContainer } from "./styled";

const Renewals = ({
  breakpoint,
  propertyOptions,
  renewalStatusOptions,
  renewals,
  renewalsCount,
  filters,
  pagination,
  initialOrderingColumn,
  hasInputValue,
  loading,
  updateFilters,
  clearFilters,
  onTableChange,
  // TODO igeshosk (renewals): implement (not needed for V1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  exportRenewals,
  actions,
}: RenewalsProps) => {
  const noRenewalAvailable =
    renewals?.length === 0 && !Object.keys(filters).some((key) => filters[key]);
  const disabledFilters = noRenewalAvailable || loading;

  const filterBarAction = {
    // TODO igeshosk (renewals): implement (not needed for V1)
    // key: "EXPORT_RENEWALS",
    // node: (
    //   <IconLink
    //     data-testid="export-renewals"
    //     onClick={exportRenewals}
    //     // TODO igeshosk (renewals): we need another icon here
    //     Icon={Icon.DownloadPdfDocGray}
    //     hideTextOnMobile
    //   />
    // ),
  };

  const filterBar = {
    title: "Filter Renewals",
    search: {
      action: (value: string) =>
        updateFilters({ unitPropertyOrApplicant: value }),
      placeholder: "Search by unit, property or applicant",
      width: 330,
      debounce: true,
      disabled: noRenewalAvailable,
      value: filters.unitPropertyOrApplicant,
    },
    dateRange: {
      label: "Lease Expires",
      date1Id: "dateMin",
      date2Id: "dateMax",
      setValues: updateFilters,
      fromValue: filters.dateMin,
      toValue: filters.dateMax,
      disabled: disabledFilters,
    },
    dropdownFilters: [
      {
        key: "propertyId",
        label: "Properties",
        value: filters.propertyId,
        onChange: (value: number[]) => updateFilters({ propertyId: value }),
        items: propertyOptions,
        mode: DROPDOWN_FILTER_MODES.multiselect,
        disabled: disabledFilters,
      },
      {
        key: "renewalStatus",
        label: "Renewal Status",
        value: filters.renewalStatus,
        onChange: (value) =>
          updateFilters({
            renewalStatus: value,
          }),
        items: renewalStatusOptions,
        mode: DROPDOWN_FILTER_MODES.multiselect,
        disabled: disabledFilters,
      },
    ],
    setFilters: (filtersValue) => updateFilters(filtersValue),
    clearFilters: {
      onClick: clearFilters,
      order: 2,
    },
    breakpointFilterPopover: [
      BREAKPOINT_TYPES.sm,
      BREAKPOINT_TYPES.md,
      BREAKPOINT_TYPES.lg,
      BREAKPOINT_TYPES.xl,
    ],
    action: filterBarAction,
  };

  return (
    <RenewalsConditionalPage>
      <Helmet>
        <title>Renewals</title>
      </Helmet>
      {breakpoint === BREAKPOINT_TYPES.xs && (
        <Container noPaddingBottom>
          <PageTitle>Renewals</PageTitle>
          <Spacer size={Spacer.SIZES.xs} />
          <MobileFiltersContainer>
            <MobileFilters {...filterBar} />
          </MobileFiltersContainer>
        </Container>
      )}
      <Container noMobilePaddingX>
        <Header>
          <LargeTitle strong className="title">
            Renewals
          </LargeTitle>
          <RadioButtons
            menuItems={RENEWAL_CATEGORIES_LIST}
            activeItem={filters.renewalCategory}
            onChange={(c) =>
              updateFilters({ renewalCategory: c as RenewalCategory })
            }
            theme={RADIO_BUTTONS_THEMES.blue}
          />
        </Header>
        <ContentContainer>
          <FilterBar {...filterBar} />
          <RenewalsTable
            hasInputValue={hasInputValue}
            pagination={pagination}
            renewals={renewals}
            renewalsCount={renewalsCount}
            loading={loading}
            onTableChange={onTableChange}
            initialOrderingColumn={initialOrderingColumn}
            actions={actions}
          />
        </ContentContainer>
      </Container>
    </RenewalsConditionalPage>
  );
};

export default withBreakpoint(Renewals);
