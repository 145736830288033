import axios from "axios";

import ROUTES from "shared/config/apiRoutes";

import { API_URL } from "./setupApi";

const api = {
  getHelloSignCredentials: () =>
    axios.get(`${API_URL}${ROUTES.getHelloSignCredentials}`),
};

export default api;
