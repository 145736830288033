import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

export const LeaseRenewalConfirmationModalContent = styled.div`
  display: flex;
  flex-direction: column;

  .offer-option-data-row {
    background-color: ${COLORS.lightGreen};
  }
`;
