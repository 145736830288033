import styled from "@emotion/styled";
import { Form } from "formik";

import FlexContainer from "shared/components/FlexContainer";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const ConcessionsSection = styled.section`
  margin-bottom: 48px;

  .concession-well {
    padding: 32px;
    margin-left: 30px;
  }

  .concession-row {
    margin-bottom: 8px;
  }

  .concession-radio {
    margin-bottom: 24px;
    margin-left: 30px;
  }

  label.ant-radio-wrapper span {
    color: ${COLORS.textColorPrimary};
  }

  ${MEDIA_QUERIES.w.ltSm} {
    .concession-well {
      margin-left: 0;
      padding: 24px 30px;
    }
  }
`;

const NetEffectiveRent = styled(FlexContainer)`
  color: ${COLORS.gray2};
`;

const StyledLeaseForm = styled(Form)`
  .first-month-rent {
    margin-left: 30px;
  }

  .first-month-rent-result {
    margin-left: 32px;
    margin-top: 12px;
  }

  .warning-well {
    width: fit-content;
  }

  .proration-info-icon {
    margin-left: 16px;
  }

  ${MEDIA_QUERIES.w.ltSm} {
    .proration-info-icon {
      margin-left: 0px;
    }
  }
`;

const BalanceFlexContainer = styled(FlexContainer)`
  > div {
    flex-grow: 2;
  }
`;

export {
  ConcessionsSection,
  NetEffectiveRent,
  BalanceFlexContainer,
  StyledLeaseForm,
};
