import React from "react";

import capitalize from "lodash/capitalize";
import reduce from "lodash/reduce";
import uniqBy from "lodash/uniqBy";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Table from "shared/components/Table";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import Well from "shared/components/Well";
import { useDeviceType } from "shared/hooks";

const ApplicantQuestionsTable = ({
  loading,
  applicantType,
  onEdit,
  questionsData,
  isPortfolioSettings,
}) => {
  const { isMobile } = useDeviceType();
  const getDocsList = (docs) => (
    <ul>
      {docs.map(({ id, name }) => (
        <li key={id}>{name}</li>
      ))}
    </ul>
  );

  const getRows = () => {
    return questionsData.map((row) => {
      const docs = uniqBy(
        reduce(
          row.options,
          (prev, { documents }) => [...prev, ...documents],
          []
        ),
        "id"
      );

      return {
        key: row.id,
        values: [
          row.questionName,
          <Tooltip
            title={getDocsList(docs)}
            trigger={docs.length > 0 ? ["hover", "click"] : []}
          >
            <UnderlineLink>{docs.length}</UnderlineLink>
          </Tooltip>,
          <Icon.EditGreenIcon
            className="action-icon"
            onClick={() => onEdit(row)}
          />,
        ],
      };
    });
  };
  const getQuestionLink = () =>
    isPortfolioSettings
      ? "/applications-lease-setup/application-setup#additionalQuestions"
      : `application-setup#additionalQuestions`;
  return (
    <>
      {isMobile && (
        <Well noBorder>
          <RegularText strong>QUESTIONS FOR {applicantType}</RegularText>
        </Well>
      )}
      <Table
        id="leads-table"
        alt
        loading={loading}
        rows={getRows()}
        pagination={false}
        noClickableRows
        columns={[
          {
            title: (
              <RegularText strong>QUESTIONS FOR {applicantType}</RegularText>
            ),
            dataIndex: "question",
            key: "question",
          },
          {
            title: <RegularText weak>Selected Document(s)</RegularText>,
            dataIndex: "numOfDocs",
            key: "numOfDocs",
            width: "30%",
          },
          {
            title: (
              <Tooltip title="Please select Edit Questions to delete this from your application in Company Settings.">
                <RegularText underline>
                  <Link to={getQuestionLink()}>Edit Questions</Link>
                </RegularText>
              </Tooltip>
            ),
            dataIndex: "editQuestion",
            key: "editQuestion",
            width: 170,
            align: "center",
          },
        ]}
        columnBreakpoints={{
          xs: {
            column1: "question",
            column2: "numOfDocs",
          },
          sm: ["question", "editQuestion"],
          md: ["question", "numOfDocs", "editQuestion"],
          lg: ["question", "numOfDocs", "editQuestion"],
        }}
        placeholder={{
          Icon: Icon.NoDataIcon,
          label: `${capitalize(applicantType)} Questions`,
        }}
      />
    </>
  );
};

ApplicantQuestionsTable.propTypes = {
  loading: PropTypes.bool,
  applicantType: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  questionsData: PropTypes.arrayOf(PropTypes.object),
  isPortfolioSettings: PropTypes.bool,
};

ApplicantQuestionsTable.defaultProps = {
  loading: false,
  questionsData: [],
  isPortfolioSettings: undefined,
};

export default ApplicantQuestionsTable;
