import React, { HTMLAttributes } from "react";


import FlexContainer from "shared/components/FlexContainer";
import { SmallText } from "shared/components/Typography";
import { useDeviceType } from "shared/hooks";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { StyledFinalSumSection } from "./styled";
import { FinalSumItem } from "./utils";

interface LeaseFinalSumSectionProps extends HTMLAttributes<HTMLDivElement> {
  feeItems: FinalSumItem[];
}

const LeaseFinalSumSection = ({
  feeItems = [],
  ...props
}: LeaseFinalSumSectionProps) => {
  const { isMobileOrTablet } = useDeviceType();

  return (
    <StyledFinalSumSection {...props}>
      {feeItems.map((item, i) => {
        const label = isMobileOrTablet
          ? item.mobileLabel || item.label
          : item.label;
        return (
          <React.Fragment key={item.label}>
            {i === feeItems.length - 1 && (
              <div className="sum-col-divider border-bottom" />
            )}
            <FlexContainer justifyContent="flex-end">
              <div className="sum-col">
                <SmallText light>{label}</SmallText>
              </div>
              <div className="sum-col">
                <SmallText light extraStrong>
                  {printDollarsFromCents(item.amount)}
                </SmallText>
              </div>
            </FlexContainer>
          </React.Fragment>
        );
      })}
    </StyledFinalSumSection>
  );
};

export default LeaseFinalSumSection;
