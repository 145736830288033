import { useEffect } from "react";

import { useFormikContext } from "formik";
import mapValues from "lodash/mapValues";

import RentableItemsProrationInfo from "manager/components/Leasing/RentableItemsProrationInfo";
import { Lease } from "manager/interfaces/api/lease";
import InformationModal from "shared/components/Modals/InformationModal";
import { SmallText } from "shared/components/Typography";
import { usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";

import {
  LeaseFieldsStepValues,
  TotalRentCalcFields,
} from "../../../interfaces";

import useLeaseTotalRentCalculation from "../../../useLeaseTotalRentCalculation";
import { leaseFeeConfigurationValidation } from "../../../utils";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import LeaseFeeFieldsSummary from "./LeaseFeeFieldsSummary";

interface SummaryContainerProps {
  lease: Lease;
  totalRentCalcFields: TotalRentCalcFields;
}

const LeaseFeeFieldsSummaryContainer = ({
  lease,
  totalRentCalcFields,
}: SummaryContainerProps) => {
  const { values, setValues } = useFormikContext<LeaseFieldsStepValues>();
  const { canOverrideFeesOnLeaseBuild } = usePermissions();
  const { openModalDialog } = useModal();

  const displayedLeaseTotalRent = useLeaseTotalRentCalculation({
    feeConfiguration: values.feeConfiguration,
    lease,
    schema: leaseFeeConfigurationValidation,
  });

  const openRentableItemsProrationModal = () =>
    openModalDialog(InformationModal, {
      title: "Rentable Items",
      subtitle: (
        <SmallText light extraStrong>
          Prorated Calculation
        </SmallText>
      ),
      description: <RentableItemsProrationInfo />,
    });

  /** Note: Show calculated Total Rent, Total First Month Rent in document fields  */
  useEffect(() => {
    if (totalRentCalcFields && displayedLeaseTotalRent) {
      setValues({
        ...values,
        fields: {
          ...values.fields,
          ...mapValues(totalRentCalcFields.total_rent, () =>
            printDollarsFromCents(displayedLeaseTotalRent.totalRent)
          ),
          ...mapValues(totalRentCalcFields.total_first_month_rent, () =>
            printDollarsFromCents(displayedLeaseTotalRent.totalFirstMonthRent)
          ),
        },
      });
    }
  }, [displayedLeaseTotalRent, totalRentCalcFields]);

  return (
    <LeaseFeeFieldsSummary
      lease={lease}
      leaseCalculations={displayedLeaseTotalRent}
      canEditFees={canOverrideFeesOnLeaseBuild}
      openRentableItemsProrationModal={openRentableItemsProrationModal}
    />
  );
};

export default LeaseFeeFieldsSummaryContainer;
