import React, { useEffect, useState, ReactNode } from "react";

import { useHistory } from "react-router-dom";

import {
  useNewAppVersionListener,
  useAnchorNavigateToElementWhenActive,
} from "shared/hooks";
import { watchLogout } from "shared/utils/auth";

interface User {
  name: string;
  email: string;
  phone?: string;
}
interface AppProps {
  id: string;
  render: ({ history, user, ...args }) => ReactNode;
  loadActiveUser: () => Promise<void>;
  logout: () => void;
  user?: User;
}

const App = ({ id, render, loadActiveUser, user, logout }: AppProps) => {
  const [initialized, setInitialized] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await loadActiveUser();
    })();
    setInitialized(true);
    watchLogout(logout);
  }, []);

  useNewAppVersionListener();
  useAnchorNavigateToElementWhenActive();

  return (
    <div
      data-testid={id}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {initialized &&
        render({
          history,
          user: { ...user, signOut: logout },
        })}
    </div>
  );
};

export default App;
