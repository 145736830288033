

import { Col } from "antd";

import { BaseFeeConfiguration } from "manager/interfaces/api/leaseFeeConfiguration";
import { MaskedInput } from "shared/components/Form";
import { ActionLink } from "shared/components/Links";
import { SmallText } from "shared/components/Typography";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { StyledFeeItemRow } from "./styled";

interface LeaseFeeItemProps {
  item: BaseFeeConfiguration;
  fieldName: string;
  onResetClick: (field: string) => void;
  canEditFees: boolean;
}

const LeaseFeeItem = ({
  item,
  fieldName,
  onResetClick,
  canEditFees,
}: LeaseFeeItemProps) => {
  const quantityPrefx = item.quantity > 1 ? ` (x${item.quantity})` : "";
  const label = `${item.feeLabel}${quantityPrefx}`;

  return (
    <StyledFeeItemRow gutter={[16, 32]} type="flex" justify="space-between">
      <Col lg={8} className="left-centered-content">
        <SmallText light>{label}</SmallText>
      </Col>
      <Col lg={16} className="right-centered-content">
        {canEditFees ? (
          <>
            <div>
              {/* @ts-ignore */}
              <MaskedInput.Formik.Money
                id={fieldName}
                name={fieldName}
                label="Amount"
                className="money-input"
              />
            </div>
            &nbsp;&nbsp;
            <ActionLink onClick={() => onResetClick(fieldName)}>
              RESET
            </ActionLink>
          </>
        ) : (
          <SmallText light extraStrong className="amount-label">
            {printDollarsFromCents(item.customAmount)}
          </SmallText>
        )}
      </Col>
    </StyledFeeItemRow>
  );
};

export default LeaseFeeItem;
