import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import {
  CalculateFirstMonthRentModel,
  CalculateFirstMonthRentRequest,
  CalculateLeaseTotalRentModel,
  CalculateLeaseTotalRentRequest,
  LeaseFeeConfigurationModel,
  LeaseFeeFieldsModel,
} from "manager/interfaces/api/leaseFeeConfiguration";
import { API_URL } from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

const api = {
  getLeaseFeeConfiguration: (leaseId: number) =>
    axios
      .get<LeaseFeeConfigurationModel>(
        `${API_URL}${transformRoute(ROUTES.leaseFeeConfiguration, {
          leaseId,
        })}`
      )
      .then(({ data }) => data),

  getLeaseFeeFields: (leaseId: number) =>
    axios
      .get<LeaseFeeFieldsModel>(
        `${API_URL}${transformRoute(ROUTES.leaseFeeFields, {
          leaseId,
        })}`
      )
      .then(({ data }) => data),

  calculateTotalRent: (configuration: CalculateLeaseTotalRentRequest) =>
    axios
      .post<CalculateLeaseTotalRentModel>(
        `${API_URL}${ROUTES.leaseCalculateTotalRent}`,
        configuration
      )
      .then(({ data }) => data),

  calculateFirstMonthRent: (configuration: CalculateFirstMonthRentRequest) =>
    axios
      .post<CalculateFirstMonthRentModel>(
        `${API_URL}${ROUTES.leaseCalculateFirstMonthRent}`,
        configuration
      )
      .then(({ data }) => data),
};

export default api;
