import React from "react";

import SignLeaseLayoutHeader from "./SignLeaseLayoutHeader";
import {
  LoadingSpinner,
  LayoutContainer,
  SignBackground,
  SignContainer,
} from "./styled";

export interface ISignLeaseLayout {
  isLoading: boolean;
  title?: string;
  subtitle?: string;
  helloSignContainer?: React.LegacyRef<HTMLDivElement>;
}

const SignLeaseLayout = ({
  isLoading,
  title = "",
  subtitle = "",
  helloSignContainer = undefined,
}: ISignLeaseLayout) =>
  !isLoading ? (
    <LayoutContainer data-testid="sign-lease-layout">
      <SignLeaseLayoutHeader title={title} subtitle={subtitle} />

      <SignBackground>
        <SignContainer ref={helloSignContainer} />
      </SignBackground>
    </LayoutContainer>
  ) : (
    <LoadingSpinner data-testid="sign-lease-loader" />
  );

export default SignLeaseLayout;
