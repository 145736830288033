const LEASING_MODEL = "leasing";
const FIRST_MONTH_RENT_FIELD = "first_month_rent";
const TOTAL_RENT_FIELD = "total_rent";
const TOTAL_FIRST_MONTH_RENT_FIELD = "total_first_month_rent";

export enum LeaseBuilderSteps {
  LEASE_INFO_STEP = 1,
  LEASE_DOCUMENTS_STEP = 2,
  LEASE_FIELDS_STEP = 3,
  LEASE_FINAL_DOCUMENT_STEP = 4,
}

export {
  LEASING_MODEL,
  FIRST_MONTH_RENT_FIELD,
  TOTAL_RENT_FIELD,
  TOTAL_FIRST_MONTH_RENT_FIELD,
};
