import React, { useState, useEffect, ReactElement } from "react";

import { useMediaQuery } from "react-responsive";

import Icon from "shared/components/Icon";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import { DownloadButton } from "../DownloadButton";
import { PageNumber } from "../PageNumber";
import { ViewerPanel as Panel } from "../Panel";
import { ZoomControls } from "../ZoomControls";
import { ZoomSelect } from "../ZoomSelect";

import {
  ScrollContainer,
  Content,
  ZoomContainer,
  LoaderContainer,
  InnerContainer,
  OuterContainer,
} from "./styled";
import { ViewerApi } from "./types";

export type PDFViewerProps = {
  CentralTools?: ReactElement;
  LeftTools?: ReactElement;
  RightTools?: ReactElement;
  loading?: boolean;
  showDownloadButton?: boolean;
  showZoomControls?: boolean;
  viewerApi: ViewerApi;
  showPageNumber?: boolean;
  showZoomSelect?: boolean;
  outerBackgroundColor?: string;
  backgroundColor?: string;
  disableMinHeight?: boolean;
  onDownload?: () => void | boolean;
  footerStyles?: object;
};

export const PDFViewer = ({
  CentralTools,
  LeftTools,
  RightTools,
  viewerApi,
  showZoomControls = false,
  loading = false,
  showDownloadButton = true,
  showZoomSelect = true,
  showPageNumber = true,
  outerBackgroundColor,
  backgroundColor,
  disableMinHeight = false,
  onDownload = null,
  footerStyles,
}: PDFViewerProps) => {
  const [selectedZoom, setZoom] = useState<number>();
  const isDesktop = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.md });

  const onPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(event.target.value);

    if (num > 0) {
      viewerApi.setPage(num);
    }
  };

  useEffect(() => {
    viewerApi.initialize();
    return viewerApi.cleanUp;
  }, []);

  useEffect(() => {
    if (viewerApi.isDocumentLoaded) {
      viewerApi.zoomTo(selectedZoom * ZoomSelect.PERCENT_COEFFICIENT);
    }
  }, [selectedZoom]);

  const Loader = loading && (
    <LoaderContainer>
      <Icon.LoadingSpinnerGreenLargeIcon data-testid="loading" />
    </LoaderContainer>
  );

  const DefaultTools = (
    <>
      {showPageNumber && (
        <PageNumber
          value={viewerApi.currentPage}
          onChange={onPageChange}
          id="page"
          name="page"
          data-testid="page"
          total={viewerApi.totalPages}
          disabled={!viewerApi.isDocumentLoaded}
        />
      )}
      {showZoomSelect && isDesktop && (
        <ZoomSelect
          id="zoom"
          data-testid="zoom"
          value={String(selectedZoom)}
          onChange={setZoom}
          disabled={!viewerApi.isDocumentLoaded}
        />
      )}
      {showDownloadButton && (
        <DownloadButton
          onDownloadClick={() => viewerApi.downloadDocument(onDownload)}
          isDownloading={viewerApi.isDocumentDownloading}
          disabled={!viewerApi.isDocumentLoaded}
        />
      )}
    </>
  );
  return (
    <Content disableMinHeight={disableMinHeight} data-testid="pdf-viewer">
      <OuterContainer
        ref={viewerApi.nodeOuterRef}
        backgroundColor={outerBackgroundColor}
      >
        {Loader}
        <ScrollContainer
          data-testid="viewer"
          visible={viewerApi.isInitialized && viewerApi.isDocumentLoaded}
          marginRight={showZoomControls}
          id="viewer"
          ref={viewerApi.nodeScrollRef}
          backgroundColor={backgroundColor}
        >
          <InnerContainer
            ref={viewerApi.nodeInnerRef}
            width={viewerApi.containerSize.width}
          />
        </ScrollContainer>
      </OuterContainer>

      <Panel externalStyles={footerStyles}>
        <Panel.LeftTools>{LeftTools}</Panel.LeftTools>
        <Panel.CentralTools>
          {DefaultTools}
          {CentralTools}
        </Panel.CentralTools>
        <Panel.RightTools>{RightTools}</Panel.RightTools>
      </Panel>

      {showZoomControls && (
        <ZoomContainer>
          <ZoomControls
            data-testid="zoom-controls"
            onZoomIn={viewerApi.zoomIn}
            onZoomOut={viewerApi.zoomOut}
            zoomInDisabled={!viewerApi.isDocumentLoaded}
            zoomOutDisabled={!viewerApi.isDocumentLoaded}
          />
        </ZoomContainer>
      )}
    </Content>
  );
};
