import React from "react";

import { useMediaQuery } from "react-responsive";

import { Button } from "shared/components/Button";
import DropdownMenu from "shared/components/DropdownMenu";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import { StyledFooter, StyledButton } from "./styled";

interface LeaseFooterProps {
  countersign: () => void;
  isCountersignAvailable: boolean;
  isWithdrawable: boolean;
  openWithdrawLeaseModal: () => void;
}

const LeaseFooter = ({
  isCountersignAvailable,
  countersign,
  isWithdrawable,
  openWithdrawLeaseModal,
}: LeaseFooterProps) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });

  return (
    <StyledFooter data-testid="lease-footer">
      {isMobile && (
        <DropdownMenu
          data-testid="actions-dropdown"
          // @ts-ignore
          style={{ paddingRight: 20 }}
          placement="topRight"
          items={[
            {
              key: "WITHDRAW_LEASE",
              label: "Withdraw Lease",
              onClick: openWithdrawLeaseModal,
              disabled: !isWithdrawable,
            },
            {
              key: "COUNTERSIGN",
              label: "Countersign",
              onClick: countersign,
              disabled: !isCountersignAvailable,
            },
          ]}
        />
      )}
      <StyledButton>
        {isWithdrawable && (
          <Button type="secondary" onClick={openWithdrawLeaseModal}>
            Withdraw Lease
          </Button>
        )}
      </StyledButton>
      <StyledButton>
        <Button
          type="primary"
          onClick={countersign}
          disabled={!isCountersignAvailable}
        >
          Countersign
        </Button>
      </StyledButton>
    </StyledFooter>
  );
};

export default LeaseFooter;
