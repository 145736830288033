import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { COLORS } from "shared/config/constants";

const LeaseQuestionsSection = styled.section`
  margin-bottom: 48px;
  padding-bottom: 24px;
  padding-left: 30px;

  label.ant-radio-wrapper span {
    color: ${COLORS.textColorPrimary};
  }
`;

const LabelIconContainer = styled(FlexContainer)`
  .extra-info {
    margin-left: 10px;
  }
`;

export { LeaseQuestionsSection, LabelIconContainer };
