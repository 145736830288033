import React from "react";

import {
  getDocumentFields,
  useGetPropertyLeaseSettings,
  useUpdatePropertyLeaseSetup,
} from "manager/hooks/api";
import { buildLeaseSetupDTO } from "manager/pages/PropertySettings/LeaseSetupForm/utils";
import { SetupFieldModalParams } from "manager/pages/PropertySettings/PropertyDocumentTemplates";
import { useDeviceType } from "shared/hooks";

import { Field } from "./interfaces";
import PropertySetupDocumentFields from "./PropertySetupDocumentFieldsModal";

const PropertySetupDocumentFieldsModalContainer = ({
  document,
  propertyId,
  leaseSetup,
}: SetupFieldModalParams) => {
  const { isMobile } = useDeviceType();
  const { data: fields, refetch: refetchFields } = getDocumentFields(
    propertyId,
    document.id
  );
  const { refetchLeaseSetup } = useGetPropertyLeaseSettings(propertyId);
  const { updateLeaseSetup } = useUpdatePropertyLeaseSetup(() => {
    refetchFields();
    refetchLeaseSetup();
  });

  const updateDocumentFields = ({
    fields: updatedFields,
  }: {
    fields: Field[];
  }) => {
    updateLeaseSetup({
      id: propertyId,
      leaseSetup: buildLeaseSetupDTO({
        ...leaseSetup,
        fields: updatedFields,
      }),
    });
  };

  return (
    <PropertySetupDocumentFields
      documentName={document?.name}
      fields={fields}
      updateDocumentFields={updateDocumentFields}
      isMobile={isMobile}
    />
  );
};

export default PropertySetupDocumentFieldsModalContainer;
