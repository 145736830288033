import { TU_PAGES } from "renter/config/constants";
import ROUTES from "renter/config/routes";
import {
  isStepIncomplete,
  listActiveStepData,
  listActiveStepObjects,
  firstUnfinishedStep,
} from "shared/utils/application-routing.utils";
import { transformRoute } from "shared/utils/routing";

export * from "shared/utils/application-routing.utils";

const getParentOrCurrentStepObject = (stepsData, currentStep) => {
  return stepsData?.find(
    (stepObj) =>
      stepObj.group === currentStep ||
      (stepObj.steps || []).find(
        (innerStepObj) => innerStepObj.step === currentStep
      )
  );
};

export const getUrlFromStep = (step) => {
  return '/applicant/workflow/:id'
};

export const getActiveStepGroups = (stepData) => listActiveStepData(stepData).map((g) => g.group);

export const getStartingStep = (stepsData) => {
  return getUrlFromStep();
};

export const hasUnfinishedStep = (stepsData) => !!firstUnfinishedStep(stepsData);

export const firstUnfinishedStepUrl = (stepsData) => getUrlFromStep(firstUnfinishedStep(stepsData));

export const isPreviousStepIncomplete = (stepData, currentStep) => {
  const activeStepObjects = listActiveStepObjects(stepData);
  let currentStepIdx = -1;
  let firstIncompleteStepIdx = -1;
  activeStepObjects.forEach((stepObj, idx) => {
    if (currentStepIdx === -1 && stepObj.step === currentStep) {
      currentStepIdx = idx;
    }
    if (firstIncompleteStepIdx === -1 && isStepIncomplete(stepObj)) {
      firstIncompleteStepIdx = idx;
    }
  });
  return firstIncompleteStepIdx >= 0
    ? firstIncompleteStepIdx < currentStepIdx
    : false;
};

export const getParentOrCurrentStep = (stepsData, currentStep) => {
  const { group, step } =
    getParentOrCurrentStepObject(stepsData, currentStep) || {};
  return group || step;
};

export const applicationSteps = (
  stepsData,
  currentStep,
  defaultNextStepUrl
) => {
  // NOTE: virtual steps (steps into the stepper, which could be the groups)
  const groupOrCurrentStep = getParentOrCurrentStep(stepsData, currentStep);
  const activeGroupsForStepper = getActiveStepGroups(stepsData);
  const stepperIndex = activeGroupsForStepper.indexOf(groupOrCurrentStep);

  // NOTE: real steps, without the groups (flattened list)
  const stepObjects = listActiveStepObjects(stepsData);
  const steps = stepObjects.map((stepObj) => stepObj.step);
  const currentRealStepIndex = steps.indexOf(currentStep);
  const isCompleted = stepObjects[currentRealStepIndex]?.completed;
  const isFirstStep = currentRealStepIndex === 0;
  const isLastStep = currentRealStepIndex + 1 === steps.length;

  const nextStepUrl =
    isLastStep && defaultNextStepUrl
      ? defaultNextStepUrl
      : getUrlFromStep(steps[currentRealStepIndex + 1]);
  const previousStepUrl = getUrlFromStep(steps[currentRealStepIndex - 1]);

  return {
    currentStepIndex: stepperIndex + 1,
    previousStepUrl,
    isFirstStep,
    nextStepUrl,
    isLastStep,
    isCompleted,
  };
};

export const getFirstActiveInnerStepUrl = (groupObj) => {
  const firstActiveInnerSepObj = listActiveStepData(groupObj.steps || [])[0];
  const firstActiveInnerStep = firstActiveInnerSepObj?.step;
  return getUrlFromStep(firstActiveInnerStep);
};

export const redirectRenterAfterSSOLogin = (history, state) => {
  const { r: redirectFragment, application_id: applicationId } = state || {};

  let redirectTo = ROUTES.applications;
  if (redirectFragment) {
    redirectTo = redirectFragment;
  } else if (applicationId) {
    redirectTo = `/applicant/workflow/${applicationId}`
  }

  window.location.href = `${window.location.origin}${redirectTo}`;
  return null;
};

export const redirectLeadAfterSSOLogin = (history, state) => {
  const { r: redirectFragment, application_id: applicationId } = state || {};

  // default redirects to the home page.
  // it will show invalid page but let the user know they should be using the link from their email
  let redirectTo = ROUTES.home;
  if (redirectFragment) {
    redirectTo = redirectFragment;
  } else if (applicationId) {
    redirectTo = transformRoute(ROUTES.applicationInvitation, {
      id: applicationId,
    });
  }

  window.location.href = `${window.location.origin}${redirectTo}`;
  return null;
};

export const getPreviousUrlFromSteps = (applicationId, steps) => {
  if (!steps?.isFirstStep) {
    return transformRoute(steps.previousStepUrl, {
      id: applicationId,
    });
  }
  return null;
};
