import flatten from "lodash/flatten";
import groupBy from "lodash/groupBy";
import isBoolean from "lodash/isBoolean";

import { getAnswerForCustomQuestion } from "shared/utils/questions";

import { formatDate } from "shared/utils/ui";

import { QUESTION_KEYS } from "./config";

export interface BuildAnswerWithDescriptionArgs {
  answer?: string;
  answerTypes?: any;
  isAnswerRequired?: boolean;
  additionalInfo?: string;
  description?: string;
  isNotShown?: boolean;
}

const buildAnswerWithDescription = (
  answer,
  answerTypes,
  isAnswerRequired = true,
  additionalInfo
): BuildAnswerWithDescriptionArgs => {
  const isBooleanAnswer = isBoolean(answer);
  const isArrayAnswer = Array.isArray(answer);

  if (!isAnswerRequired) {
    return {
      answer: "N/A",
      description: "",
      isNotShown: true,
    };
  }

  if (isBooleanAnswer) {
    return { answer: answer ? "Yes." : "No." };
  }

  if (isArrayAnswer) {
    return {
      answer: answer.length ? "Yes." : "No.",
      description: answer.length
        ? answer.map((item, index) => `${index + 1}. ${item}`).join("\n") +
          (additionalInfo ? `\nComment: ${additionalInfo}` : "")
        : "",
    };
  }
  return {
    answer: answerTypes
      ? answerTypes.find((type) => type.id === answer)?.value
      : answer,
    description: "",
  };
};

export const normalizeApplicationAnswers = (answersSummary, answersTypes) => {
  const normalizedAnswers = flatten(
    answersSummary.map((perApplicantSummary) => {
      const {
        applicant,
        questions,
        answers,
        requiredAdditionalInformation,
        additionalInformation,
        ...generalQuestions
      } = perApplicantSummary;

      const generalAnswers = Object.keys(generalQuestions)
        .map((generalQuestion) => {
          const { answerTypesKey, question, answerAdditionalInfoKey } =
            QUESTION_KEYS[generalQuestion];

          return {
            applicant,
            question: question || generalQuestion,
            ...buildAnswerWithDescription(
              generalQuestions[generalQuestion],
              answersTypes[answerTypesKey],
              requiredAdditionalInformation[generalQuestion],
              additionalInformation?.[answerAdditionalInfoKey]
            ),
          };
        })
        .filter((generalAnswer) => !generalAnswer.isNotShown);

      const additionalAnswers = questions.map((additionalQuestion) => {
        const answer = answers.find(
          (additionalAnswer) =>
            additionalAnswer.additionalQuestion?.id === additionalQuestion.id
        );

        return {
          applicant,
          question: additionalQuestion.questionName,
          // @ts-ignore
          ...buildAnswerWithDescription(getAnswerForCustomQuestion(answer)),
        };
      });

      return [...generalAnswers, ...additionalAnswers];
    })
  );

  return groupBy(normalizedAnswers, "question");
};

export const normalizeApplicationLeaseTerms = (applicants) => {
  const normalizedAnswers = flatten(
    applicants.map((perApplicantSummary) => {
      const {
        applicant,
        preferredLeaseDurationMonths,
        preferredLeaseStartDate,
      } = perApplicantSummary;

      return [
        {
          applicant,
          question: "Preferred lease duration (months)",
          answer: preferredLeaseDurationMonths,
          description: "",
        },
        {
          applicant,
          question: "Preferred lease start date",
          answer: formatDate(preferredLeaseStartDate),
          description: "",
        },
      ];
    })
  );

  return groupBy(normalizedAnswers, "question");
};
