import axios from "axios";

import { Property } from "manager/interfaces/api";

import ROUTES from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

import { API_URL } from "./setupApi";

export const getProperties = (params) => {
  return axios.get(`${API_URL}${ROUTES.properties}`, {
    params: {
      id: params?.id,
      portfolio_id: params?.portfolioId,
      name_or_address: params?.nameOrAddress,
      portfolio__is_default: params?.hasPortfolio,
      page: params?.page,
      page_size: params?.pageSize,
    },
  });
};

// TODO (POD2-148): Remove the Perf postfix as soon as we have removed the old endpoints.
export const getPropertiesPerf = (params) => {
  return axios
    .get(`${API_URL}${ROUTES.propertiesPerf}`, {
      params: {
        id: params?.id,
        portfolio_id: params?.portfolioId,
        name_or_address: params?.nameOrAddress,
        portfolio__is_default: params?.hasPortfolio,
        page: params?.page,
        page_size: params?.pageSize,
      },
    })
    .then((response) => response.data);
};

export const getAllPropertiesSimple = () => {
  return axios.get(`${API_URL}${ROUTES.allPropertiesSimple}`);
};

export const getPropertiesWithListing = (params) =>
  axios.get(`${API_URL}${ROUTES.propertiesWithUnitListing}`, {
    params: {
      portfolio_id: params?.portfolioId,
      name_or_address: params?.nameOrAddress,
      portfolio__is_default: params?.hasPortfolio,
      with_units: params?.withUnits,
      deal: params?.deal,
    },
  });

export const getPropertiesWithAlerts = () =>
  axios
    .get(`${API_URL}${ROUTES.properties}`, {
      params: {
        with_alerts: true,
      },
    })
    .then((response) => response.data);

export const assignPropertyToPortfolio = ({ propertyId, portfolioId }) =>
  axios.post(
    `${API_URL}${transformRoute(ROUTES.assignPropertyToPortfolio, {
      propertyId,
    })}`,
    {
      portfolio_id: portfolioId,
    }
  );

export const unassignPropertyFromPortfolio = (propertyId) =>
  axios.post(
    `${API_URL}${transformRoute(ROUTES.unassignPropertyFromPortfolio, {
      propertyId,
    })}`
  );

export const getProperty = (propertyId) =>
  axios.get(
    `${API_URL}${transformRoute(ROUTES.property, {
      propertyId,
    })}`
  );

export const createProperty = ({
  address,
  city,
  propertyType,
  state,
  zipcode,
  name,
}) =>
  axios
    .post(`${API_URL}${ROUTES.properties}`, {
      address,
      city,
      propertyType,
      state,
      zipcode,
      name,
    })
    .then(({ data }) => data);

export const updateProperty = (id: number, property: Property) =>
  axios
    .patch(
      `${API_URL}${transformRoute(ROUTES.property, { propertyId: id })}`,
      property
    )
    .then(({ data }) => data);

export const assignEmployeesToProperty = ({ id, employees }) =>
  axios.post(
    `${API_URL}${transformRoute(ROUTES.assignEmployees, { propertyId: id })}`,
    employees
  );

export const getEmployeesAssignedToProperty = ({ id }) =>
  axios.get(
    `${API_URL}${transformRoute(ROUTES.listEmployees, {
      propertyId: id,
    })}`
  );

// TODO (POD2-148): Remove the Perf postfix as soon as we have removed the old endpoints.
export const getEmployeesAssignedToPropertyPerf = ({ id }) =>
  axios
    .get(
      `${API_URL}${transformRoute(ROUTES.listEmployeesPerf, {
        propertyId: id,
      })}`
    )
    .then(({ data }) => data);

export const getEmployeesWithAssignmentsOnProperty = ({
  id,
  // @ts-ignore
  filters: { onlyYardiUnmapped, excludeUnassigned } = {},
}) =>
  axios.get(
    `${API_URL}${transformRoute(ROUTES.listEmployeesWithAssignments, {
      propertyId: id,
    })}`,
    {
      params: {
        only_yardi_unmapped: onlyYardiUnmapped,
        exclude_unassigned: excludeUnassigned,
      },
    }
  );

export const uploadPropertyLogo = (id, file) => {
  const formData = new FormData();
  formData.append("logo", file);
  return axios.post(
    `${API_URL}${transformRoute(ROUTES.uploadPropertyLogo, {
      propertyId: id,
    })}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const getPropertiesWithInvitation = () =>
  axios
    .get(`${API_URL}${ROUTES.propertiesWithInvitation}`)
    .then((response) => response.data);

export const getPropertiesWithLease = () =>
  axios
    .get(`${API_URL}${ROUTES.propertiesWithLease}`)
    .then((response) => response.data);

export const getPropertiesWithLead = () =>
  axios
    .get(`${API_URL}${ROUTES.propertiesWithLead}`)
    .then((response) => response.data);
