import React, { useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";

import ROUTES from "renter/config/routes";
import { useGetApplicationInvitation } from "renter/hooks/api";
import { getStartingStep } from "renter/utils/application-routing.utils";
import SetPasswordPage from "shared/pages/SetPasswordPage";
import { getQueryParams, transformRoute } from "shared/utils/routing";

const SetPasswordContainer = () => {
  const history = useHistory();
  const urlParams = getQueryParams();
  const {
    application_id: applicationId,
    lease_application_id: leaseApplicationId,
    lease_id: leaseId,
  } = urlParams || {};

  const { token } = useParams<{ token: string }>();

  const { application, refetchApplicationInvitation } =
    useGetApplicationInvitation(applicationId as string, false);

  useEffect(() => {
    if (application) {
      const redirectTo = transformRoute(getStartingStep(application.steps), {
        id: applicationId,
      });

      history.push(redirectTo);
    }
  }, [application]);

  const navigateToLease = () =>
    history.push(
      transformRoute(ROUTES.lease, {
        leaseApplicationId,
        leaseId,
      })
    );

  const onSuccess = async () => {
    if (applicationId) {
      await refetchApplicationInvitation();
    } else if (leaseApplicationId && leaseId) {
      navigateToLease();
    } else {
      history.push(ROUTES.applications);
    }
  };

  return (
    <SetPasswordPage
      onSuccessLogin={onSuccess}
      token={token}
      applicationId={leaseApplicationId || applicationId}
    />
  );
};

export default SetPasswordContainer;
