import React, { Key, useMemo } from "react";

import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";

import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import FilterBar, { DROPDOWN_FILTER_MODES } from "shared/components/FilterBar";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import MobileFilters from "shared/components/MobileFilters";
import PageTitle from "shared/components/PageTitle";
import RadioButtons, {
  RADIO_BUTTONS_THEMES,
} from "shared/components/RadioButtons";
import Spacer from "shared/components/Spacer";
import {
  BREAKPOINT_RESOLUTIONS,
  BREAKPOINT_TYPES,
} from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { Pagination } from "shared/interfaces";
import { ContentContainer } from "shared/templates/TablePage/styled";
import { sortLeaseStatuses } from "shared/utils/lease";

import { Lease, LeaseFilters } from "./interfaces";
import { ExportWrapper, Header, ExportWrapperDesktop } from "./Leases.styled";
import LeasesTable from "./LeasesTable";

interface LeasesProps {
  breakpoint?: BREAKPOINT_TYPES;
  leaseStatuses: { id: string; value: string }[];
  leases: Lease[];
  leasesCount: number;
  dropdownProperties: {
    id: number;
    name: string;
    yardiConnected?: boolean;
  }[];
  filters: LeaseFilters;
  hasInputValue: boolean;
  loading: boolean;
  pagination: Pagination;
  setPagination: (params: Pagination) => void;
  updateFilters: (params?: {
    unitPropertyOrApplicant?: Key;
    propertyId?: Key;
    leaseStatus?: Key;
  }) => void;
  updateAllFilters: (filtersValue: object) => void;
  onTableChange: (
    tablePagination: object,
    tableFilters: object,
    sorter: object
  ) => void;
  menuItems: { apiKey: string; key: string; name: string }[];
  activeItem: string;
  setCategory: (value: string) => void;
  initialOrderingColumn: {
    [x: string]: string;
  };
  isFiltersLoading: boolean;
  exportLeases: () => void;
  isExportingLeases: boolean;
}

const Leases = ({
  breakpoint,
  leaseStatuses = [],
  dropdownProperties = [],
  leases = [],
  leasesCount = 0,
  filters,
  updateFilters,
  updateAllFilters,
  pagination,
  setPagination,
  hasInputValue,
  loading,
  onTableChange,
  menuItems,
  activeItem,
  setCategory,
  initialOrderingColumn,
  isFiltersLoading,
  exportLeases,
  isExportingLeases,
}: LeasesProps) => {
  const isMediumScreen = useMediaQuery({
    maxWidth: BREAKPOINT_RESOLUTIONS.lg,
    minWidth: BREAKPOINT_RESOLUTIONS.sm,
  });
  const propertySelection = useMemo(
    () => [
      ...dropdownProperties.map(({ id, name }) => ({
        label: name,
        key: `${id}`,
      })),
    ],
    [dropdownProperties]
  );

  const leaseStatusSelection = useMemo(
    () => [
      // V2-2348: lease statuses SENT and PARTIALLY_SIGNED are combined into single status with the same text: remove duplicates
      // @ts-ignore
      ...new Map(
        leaseStatuses
          .sort(sortLeaseStatuses())
          .map(({ id, value }) => ({
            label: value,
            key: `${id}`,
          }))
          .map((item) => [item.label, item])
      ).values(),
    ],
    [leaseStatuses]
  );

  const disabledSearch =
    leases?.length === 0 && !Object.keys(filters).some((key) => filters[key]);
  const disabledFilters =
    (leases?.length === 0 &&
      !Object.keys(filters).some((key) => filters[key])) ||
    loading ||
    isFiltersLoading;

  const filterBar = {
    title: "Filter Leases",
    search: {
      action: (value) => updateFilters({ unitPropertyOrApplicant: value }),
      placeholder: "Search in leases",
      width: 350,
      debounce: true,
      disabled: disabledSearch,
      value: filters.unitPropertyOrApplicant,
    },
    dropdownFilters: [
      {
        key: "propertyId",
        label: "Properties",
        value: filters.propertyId,
        onChange: (value) => updateFilters({ propertyId: value }),
        items: propertySelection,
        mode: DROPDOWN_FILTER_MODES.multiselect,
        disabled: disabledFilters,
      },
      {
        key: "leaseStatus",
        label: "Lease statuses",
        value: filters.leaseStatus,
        onChange: (value) =>
          updateFilters({
            leaseStatus: value,
          }),
        items: leaseStatusSelection,
        mode: DROPDOWN_FILTER_MODES.multiselect,
        order: 1,
        disabled: disabledFilters,
        showOnEnd: true,
      },
    ],
    loadingDropdownOptions: isFiltersLoading,
    dateRange: {
      label: "Lease Dates",
      date1Id: "dateMin",
      date2Id: "dateMax",
      setValues: updateFilters,
      fromValue: filters.dateMin,
      toValue: filters.dateMax,
      disabled: disabledFilters,
    },
    setFilters: (filtersValue) => updateAllFilters(filtersValue),
    clearFilters: {
      onClick: () => updateFilters(),
      order: 2,
    },
    breakpointFilterPopover: [
      BREAKPOINT_TYPES.sm,
      BREAKPOINT_TYPES.md,
      BREAKPOINT_TYPES.lg,
      BREAKPOINT_TYPES.xl,
    ],
    action: {
      key: "EXPORT",
      node: (
        <ExportWrapperDesktop>
          {isMediumScreen ? (
            <Icon.HoverTracker
              Icon={Icon.ExportIconHover}
              title="Export Leases"
              onClick={exportLeases}
            />
          ) : (
            <Button
              type="secondary"
              onClick={exportLeases}
              loading={isExportingLeases}
              disabled={leasesCount === 0 || isExportingLeases}
            >
              Export Leases
            </Button>
          )}
        </ExportWrapperDesktop>
      ),
    },
  };

  return (
    <>
      <Helmet>
        <title>Leases</title>
      </Helmet>
      {breakpoint === BREAKPOINT_TYPES.xs && (
        <Container noPaddingBottom>
          <PageTitle>Leases</PageTitle>
          <Spacer size={Spacer.SIZES.xs} />
          <FlexContainer justifyContent="space-between" flexWrap="wrap">
            <ExportWrapper>
              <Button
                type="secondary"
                onClick={exportLeases}
                loading={isExportingLeases}
                disabled={leasesCount === 0 || isExportingLeases}
                size="small"
              >
                Export Leases
              </Button>
            </ExportWrapper>
            <MobileFilters {...filterBar} />
          </FlexContainer>
        </Container>
      )}
      <Container noMobilePaddingX>
        <Header>
          <h1 className="title">Leases</h1>
          <RadioButtons
            menuItems={menuItems}
            activeItem={activeItem}
            onChange={setCategory}
            theme={RADIO_BUTTONS_THEMES.primary300}
          />
        </Header>
        <ContentContainer>
          <FilterBar {...filterBar} />
          <LeasesTable
            hasInputValue={hasInputValue}
            setPagination={setPagination}
            pagination={pagination}
            leases={leases}
            leasesCount={leasesCount}
            loading={loading || isFiltersLoading}
            onTableChange={onTableChange}
            initialOrderingColumn={initialOrderingColumn}
          />
        </ContentContainer>
      </Container>
    </>
  );
};

export default withBreakpoint(Leases);
