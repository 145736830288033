import React from "react";

import { Radio } from "antd";
import isBoolean from "lodash/isBoolean";

import { Checkbox } from "shared/components/Form";
import Icon from "shared/components/Icon";
import List from "shared/components/List";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";

import { DownloadLeaseModalProps } from "./interfaces";
import {
  Title,
  ListItem,
  RadioOption,
  TileCentered,
  Label,
  StyledButton,
} from "./styled";

export const E_SIGN_CONFIRMED_TEXT =
  "Sounds good, you can send this lease for eSign as soon as you are ready.";
export const OFFLINE_CONFIRMED_TEXT =
  "Sounds good, you’ll be able to modify the lease at any time while handling it offline.";

const DownloadLeaseModal = ({
  unitName,
  propertyName,
  onESigningChange,
  eSigning,
  onConfirm,
  notAskAgain,
  onNotAskAgainChange,
  isSigningOffline,
  propertyAddress,
  isSettingInProgress,
  isSigningConfirmed,
  closeModal,
  onAfterClose,
}: DownloadLeaseModalProps) => {
  const Subtitle = (
    <span data-testid="subtitle">
      Unit:{" "}
      <strong>{`${propertyName}, ${propertyAddress}, ${unitName}`}</strong>
    </span>
  );

  const DownloadOptions = (
    <>
      <Radio.Group onChange={onESigningChange} value={eSigning}>
        <List>
          <List.Item>
            <Title>Do you plan to continue the leasing process offline? </Title>
          </List.Item>
          <ListItem selected={eSigning === false}>
            <RadioOption value={false}>Yes, I’ll continue offline</RadioOption>
          </ListItem>
          <ListItem selected={eSigning}>
            <RadioOption value>No, I prefer E-signing</RadioOption>
          </ListItem>
        </List>
      </Radio.Group>
      <Spacer />
      {/* @ts-ignore */}
      <Checkbox
        id="ask-again"
        label={<Label>Don’t ask me again</Label>}
        value={notAskAgain}
        onChange={onNotAskAgainChange}
      />
    </>
  );

  const SigningText = (
    <>
      <TileCentered>
        {eSigning && (
          <Tile.Inner noPaddingBottom>
            <Icon.OperationSuccess data-testid="verified-icon" />
          </Tile.Inner>
        )}

        <Tile.Inner noPaddingTop={eSigning}>
          <Title data-testid="sign-text">
            {eSigning ? E_SIGN_CONFIRMED_TEXT : OFFLINE_CONFIRMED_TEXT}
          </Title>
        </Tile.Inner>
      </TileCentered>
      <StyledButton type="primary" onClick={closeModal} data-testid="got-it">
        GOT IT
      </StyledButton>
    </>
  );

  return (
    <Modal
      title="Download Lease"
      submitButtonLabel="Confirm"
      subtitle={Subtitle}
      submit={onConfirm}
      closeOnSubmit={false}
      submitButtonDisabled={!isBoolean(eSigning)}
      submitting={isSigningOffline || isSettingInProgress}
      afterClose={onAfterClose}
      showFooter={!isSigningConfirmed}
    >
      <Modal.Body>
        {isSigningConfirmed ? SigningText : DownloadOptions}
      </Modal.Body>
    </Modal>
  );
};

export default DownloadLeaseModal;
