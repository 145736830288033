import React from "react";

import { useMediaQuery } from "react-responsive";

import { Button } from "shared/components/Button";
import DropdownMenu from "shared/components/DropdownMenu";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import { StyledFooter, StyledButton } from "./styled";

interface ResendFooterProps {
  resendLease?: () => void;
  isWithdrawable: boolean;
  openWithdrawLeaseModal: () => void;
}

const ResendFooter = ({
  isWithdrawable,
  openWithdrawLeaseModal,
  resendLease,
}: ResendFooterProps) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });

  return (
    <StyledFooter data-testid="lease-footer">
      {isMobile && (
        <DropdownMenu
          className="actions-dropdown"
          data-testid="actions-dropdown"
          placement="topRight"
          items={[
            {
              key: "WITHDRAW_LEASE",
              label: "Withdraw Lease",
              onClick: openWithdrawLeaseModal,
              disabled: !isWithdrawable,
            },
            ...(resendLease
              ? [
                  {
                    key: "SEND_REMINDER",
                    label: "Send reminder",
                    onClick: resendLease,
                  },
                ]
              : []),
          ]}
        />
      )}
      {isWithdrawable && (
        <StyledButton>
          <Button type="secondary" onClick={openWithdrawLeaseModal}>
            Withdraw Lease
          </Button>
        </StyledButton>
      )}
      {resendLease && (
        <StyledButton>
          <Button type="primary" onClick={resendLease}>
            Send Reminder
          </Button>
        </StyledButton>
      )}
    </StyledFooter>
  );
};

export default ResendFooter;
