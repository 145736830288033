import React from "react";

import PropTypes from "prop-types";

import { useGetLeaseReminderFrequencies } from "shared/hooks/api";

import LeaseReminder from "./LeaseReminder";

const LeaseReminderContainer = ({ loading, disabled }) => {
  const { frequencies, isFrequenciesLoading } =
    useGetLeaseReminderFrequencies();
  return (
    <LeaseReminder
      loading={loading}
      disabled={disabled}
      frequencies={frequencies}
      isFrequenciesLoading={isFrequenciesLoading}
    />
  );
};

LeaseReminderContainer.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

LeaseReminderContainer.defaultProps = {
  loading: false,
  disabled: false,
};

export default LeaseReminderContainer;
