import { queryCache, useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import ROUTES from "renter/config/routes";
import api from "renter/lib/api";
import { NOTIFICATIONS, HTTP_ERROR_CODES } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_LEASES_CACHE_KEY = "getLeases";

const invalidateLeasesCache = () => {
  queryCache.invalidateQueries(GET_LEASES_CACHE_KEY);
};

export const useLeases = (params) => {
  const response = useQuery(
    [GET_LEASES_CACHE_KEY, params],
    () => api.getLeases(params).then(({ data }) => data),
    {
      cacheTime: 0,
      onError: () =>
        openNotification("Failed to load leases", NOTIFICATIONS.error),
    }
  );
  return response;
};

export const useLeaseApplication = (leaseApplicationId) => {
  const history = useHistory();
  const response = useQuery(
    ["getLeaseApplication", leaseApplicationId],
    () => api.getLeaseApplication(leaseApplicationId).then(({ data }) => data),
    {
      cacheTime: 0,
      onError: (error) => {
        if (error.statusCode === HTTP_ERROR_CODES.notFound) {
          history.push(ROUTES.notFound, { backLink: ROUTES.applications });
        } else {
          openNotification(
            "Failed to load lease application",
            NOTIFICATIONS.error
          );
        }
      },
    }
  );

  return response;
};

export const useViewFinalLease = ({ leaseApplicationId }) => {
  const response = useQuery(
    ["getFinalLeaseDocument", leaseApplicationId],
    () =>
      api
        .getFinalLeaseDocument({ leaseApplicationId })
        .then(({ data }) => data),
    {
      cacheTime: 0,
      onError: (error) => {
        if (error.statusCode !== HTTP_ERROR_CODES.notFound) {
          openNotification(
            "Failed to load final version of the lease",
            NOTIFICATIONS.error
          );
        }
      },
    }
  );

  return response;
};

export const useAcceptOffer = () => {
  const [acceptOffer, { isLoading: isAcceptOfferLoading }] = useMutation(
    api.acceptOffer,
    {
      onSuccess: () => {
        openNotification(
          "Successfully accepted the renewal offer.",
          NOTIFICATIONS.info
        );
        invalidateLeasesCache();
      },
      onError: () => {
        openNotification(
          "Failed to accept the renewal offer.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    acceptOffer,
    isAcceptOfferLoading,
  };
};

export const useRejectOffer = () => {
  const [rejectOffer, { isLoading: isRejectOfferLoading }] = useMutation(
    api.rejectOffer,
    {
      onSuccess: () => {
        openNotification(
          "Successfully rejected the renewal offer.",
          NOTIFICATIONS.info
        );
        invalidateLeasesCache();
      },
      onError: () => {
        openNotification(
          "Failed to reject the renewal offer.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    rejectOffer,
    isRejectOfferLoading,
  };
};

export const useApplicantSignUrl = (params, config) => {
  const { data: signatureData, ...status } = useQuery(
    ["getApplicantSignUrl", params],
    () => api.getApplicantSignUrl(params).then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load URL for signing process",
          NOTIFICATIONS.error
        ),
      retry: false,
      cacheTime: 0,
      refetchOnMount: "always",
      ...config,
    }
  );

  return {
    signatureData,
    ...status,
  };
};

export const useProcessSignature = ({ onSuccess, ...otherParams }) => {
  const [mutate, response] = useMutation(api.processSignature, {
    onSuccess: async () => {
      await queryCache.invalidateQueries(GET_LEASES_CACHE_KEY);
      onSuccess();
    },
    ...otherParams,
  });

  return { processSignature: mutate, response };
};

export const useTrackDownload = () => {
  const [mutate, status] = useMutation(
    (leaseApplicationId) => api.trackDownloadStatistic({ leaseApplicationId }),
    {
      onError: () => {
        openNotification(
          "Failed to set download information.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    setDownload: mutate,
    ...status,
  };
};
