import React, { useCallback, useMemo, useRef } from "react";

import { isArray } from "lodash";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import {
  useLeaseFields,
  useLeaseFieldsBluemoonQuery,
  useUpdateLeaseMetadata,
  useUpdateLeaseMetadataBluemoonQuery,
  useLeaseDocumentTemplates,
  useLeaseDocumentTemplatesBluemoonQuery,
  useGetLeaseFeeConfiguration,
  useGetLeaseFeeFields,
} from "manager/hooks/api";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import useModal from "shared/hooks/useModal";
import { conditionalItem } from "shared/utils/array";
import { setTouchedAndValidate } from "shared/utils/forms";
import { convertCentsToDollars } from "shared/utils/ui";

import { leaseDetailsPropType } from "../propTypes";

import LeaseFieldsStep from "./LeaseFieldsStep";
import {
  findCalculatedRentSmartFields,
  getFormattedDocuments,
  getInitialValues,
  getShowChargesRentableItems,
  getUpdatedLeaseFields,
  hasEmptyDocumentFields,
  hasUnusedFeeFields,
} from "./utils";

const EMPTY_DOC_FIELDS_MESSAGE = (
  <>
    Some of the fields within your lease document(s) were left empty. If you
    continue, these fields will remain empty in the lease document.
  </>
);

const UNUSED_FEE_FIELDS_MESSAGE = (
  <>
    There are unmapped &quot;Charges & Rentable Item&quot; fields in your
    document. If you continue without mapping, they will appear empty in the
    lease document.
  </>
);

const LeaseFieldsStepContainer = ({
  lease,
  currentStep,
  stepBack,
  stepForward,
  redirectBack,
}) => {
  const { openModalDialog } = useModal();
  const { isBluemoonLease } = lease;
  const { isLoading: isLeaseDocumentsLoading, data: documents } =
    isBluemoonLease
      ? useLeaseFieldsBluemoonQuery(lease.id)
      : useLeaseFields(lease.id);

  const {
    isLoading: isLeaseDocumentTemplatesLoading,
    data: leaseDocumentTemplates,
  } = isBluemoonLease
    ? useLeaseDocumentTemplatesBluemoonQuery(lease.id)
    : useLeaseDocumentTemplates(lease.id);

  const { leaseFeeConfiguration, isLeaseFeeConfigLoading } =
    useGetLeaseFeeConfiguration(lease.id);

  const { feeFields, isFeeFieldsLoading } = useGetLeaseFeeFields(lease.id);

  const showChargesRentableItems = getShowChargesRentableItems(
    leaseFeeConfiguration,
    feeFields
  );

  const totalRentCalcFields = useMemo(
    () => findCalculatedRentSmartFields(documents),
    [documents]
  );

  const formikRef = useRef(null);

  const getNextStepValue = useCallback(
    (increaseStep) => {
      const isNextStepSaved = lease.step > currentStep;

      if (increaseStep) {
        return isNextStepSaved ? lease.step : currentStep + 1;
      }

      return isNextStepSaved ? lease.step : currentStep;
    },
    [lease, currentStep]
  );

  const formattedDocuments =
    documents && leaseDocumentTemplates
      ? getFormattedDocuments(
          documents,
          leaseDocumentTemplates,
          isBluemoonLease
        )
      : [];

  const onSaveSettled = () => formikRef?.current?.setSubmitting(false);

  const onErrorUpdateLease = (error) => {
    if (isArray(error?.errors)) {
      error?.errors?.forEach((e) => {
        const { setErrors, errors } = formikRef.current;
        setErrors({
          ...errors,
          fields: {
            ...errors.feeConfiguration,
            ...JSON.parse(e.replaceAll("'", '"')).errors,
          },
        });
      });
    }
  };

  const { updateLeaseMetadata, isLoading: isUpdatingMetadata } = isBluemoonLease
    ? useUpdateLeaseMetadataBluemoonQuery()
    : useUpdateLeaseMetadata();

  const saveWithEmptyFieldsWarning = ({ performUpdateLeaseMetadata }) => {
    const isContainingDocEmptyFields = hasEmptyDocumentFields(
      formikRef.current.values.fields
    );
    const isContainingUnusedFeeFields =
      showChargesRentableItems &&
      hasUnusedFeeFields(formikRef.current.values, feeFields);

    if (isContainingDocEmptyFields || isContainingUnusedFeeFields) {
      openModalDialog(ConfirmationModal, {
        title: "Blank Lease Fields Identified",
        submitButtonLabel: "REVIEW LEASE PDF",
        description: [
          ...conditionalItem(
            isContainingDocEmptyFields,
            EMPTY_DOC_FIELDS_MESSAGE
          ),
          ...conditionalItem(
            isContainingUnusedFeeFields,
            UNUSED_FEE_FIELDS_MESSAGE
          ),
        ],
        onConfirm: () => performUpdateLeaseMetadata(),
      });
    } else {
      performUpdateLeaseMetadata();
    }
  };

  const saveLeaseMetadata = ({ increaseStep, onSaveCompleted }) => {
    formikRef.current?.setSubmitting(true);
    const currentDocumentFields = { ...formikRef.current.values.fields };
    const updatedChargesAndRentableItems = Object.values(
      formikRef.current.values.feeConfiguration
    )
      .flat()
      ?.filter((item) => item.isSelected);
    const setValueToMatchingFields = { ...currentDocumentFields };
    updatedChargesAndRentableItems?.forEach((item) => {
      if (setValueToMatchingFields[item?.name]) {
        setValueToMatchingFields[item?.name] = convertCentsToDollars(
          item.baseAmount * item.quantity
        );
      }
    });
    const updatedDocumentFields = getUpdatedLeaseFields(
      isBluemoonLease ? setValueToMatchingFields : currentDocumentFields,
      formattedDocuments,
      isBluemoonLease
    );

    const payload = {
      leaseId: lease.id,
      step: getNextStepValue(increaseStep),
      leaseDocumentTemplates: updatedDocumentFields,
      feeConfiguration: formikRef.current.values.feeConfiguration,
    };

    const performUpdateLeaseMetadata = () =>
      updateLeaseMetadata(payload, {
        onSuccess: onSaveCompleted,
        onSettled: onSaveSettled,
        onError: onErrorUpdateLease,
      });

    if (increaseStep) {
      saveWithEmptyFieldsWarning({ performUpdateLeaseMetadata });
    } else {
      performUpdateLeaseMetadata();
    }
  };

  const onSaveProgress = async (onSaveCompleted) => {
    if (formikRef.current?.dirty) {
      const errors = await setTouchedAndValidate(formikRef);

      if (isEmpty(errors)) {
        saveLeaseMetadata({ increaseStep: false, onSaveCompleted });
      }
    } else {
      onSaveCompleted();
    }
  };

  const onNextStep = () =>
    saveLeaseMetadata({ increaseStep: true, onSaveCompleted: stepForward });
  const onPrevStep = () => onSaveProgress(stepBack);
  const onFinishLater = () => onSaveProgress(redirectBack);

  const isLoading =
    isLeaseDocumentsLoading ||
    isLeaseDocumentTemplatesLoading ||
    isLeaseFeeConfigLoading ||
    isFeeFieldsLoading;

  return (
    <LeaseFieldsStep
      documents={formattedDocuments}
      isLoading={isLoading}
      onFinishLater={onFinishLater}
      stepBack={stepBack}
      formikRef={formikRef}
      initialValues={getInitialValues(
        formattedDocuments,
        leaseFeeConfiguration,
        isBluemoonLease
      )}
      isUpdatingMetadata={isUpdatingMetadata}
      onNextStep={onNextStep}
      onPrevStep={onPrevStep}
      lease={lease}
      feeFields={feeFields}
      totalRentCalcFields={totalRentCalcFields}
    />
  );
};

LeaseFieldsStepContainer.propTypes = {
  lease: leaseDetailsPropType.isRequired,
  currentStep: PropTypes.number.isRequired,
  stepBack: PropTypes.func.isRequired,
  stepForward: PropTypes.func.isRequired,
  redirectBack: PropTypes.func.isRequired,
};

export default LeaseFieldsStepContainer;
