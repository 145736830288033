import React from "react";

import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";

import { Container, Title, IconWrapper } from "./styled";

interface LeaseHeaderProps {
  isExecuted: boolean;
  confirmExecution: () => void;
}

const LeaseHeader = ({ confirmExecution, isExecuted }: LeaseHeaderProps) => (
  <Container data-testid="container-header" isExecuted={isExecuted}>
    {isExecuted && (
      <IconWrapper>
        <Icon.OperationSuccess
          width="100%"
          height="100%"
          data-testid="executed-icon"
        />
      </IconWrapper>
    )}
    <Title data-testid="title-header" isExecuted={isExecuted}>
      {isExecuted
        ? "The lease is fully executed and uploaded to VERO. The unit status has changed to ‘Rented’."
        : "Please confirm once the lease has been fully executed. This is a crucial step to keeping your unit availability accurate."}
    </Title>
    {!isExecuted && (
      <ActionLink
        onClick={confirmExecution}
        data-testid="confirm-execution-button"
      >
        Confirm Lease Execution
      </ActionLink>
    )}
  </Container>
);

export default LeaseHeader;
