import { useEffect, useCallback } from "react";

import { useHelloSignCredentials } from "shared/hooks/api";

import helloSignInstance from "./helloSignInstance";

const useHelloSign = ({ actions = {} }) => {
  const { data: credentials, isLoading } = useHelloSignCredentials();

  useEffect(() => {
    const actionNames = Object.keys(actions);

    actionNames.forEach((actionName) => {
      helloSignInstance.on(actionName.replace("on", "").toLowerCase(), (data) =>
        actions[actionName](data, helloSignInstance)
      );
    });

    return () => {
      actionNames.forEach((actionName) => {
        helloSignInstance.off(actionName.replace("on", "").toLowerCase());
      });
    };
  }, [actions, helloSignInstance]);

  const openHelloSign = useCallback(
    (signUrl, containerRef) => {
      if (credentials) {
        helloSignInstance.open(signUrl, {
          container: containerRef?.current,
          clientId: credentials?.clientId,
          testMode: credentials?.testMode,
        });
      }
    },
    [helloSignInstance, credentials]
  );

  const closeHelloSign = useCallback(() => {
    helloSignInstance.close();
  }, [helloSignInstance]);

  return {
    openHelloSign,
    closeHelloSign,
    isLoading,
  };
};

export default useHelloSign;
