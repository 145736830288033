import { useRef } from "react";

import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

import { DealIntegrationMark } from "manager/components/IntegrationMark";
import { RenewalListItemDetails } from "manager/components/Renewals";
import { RenewalEscalationLevel } from "manager/config/constants";
import ROUTES from "manager/config/routes";
import CheckList from "shared/components/CheckList";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Table from "shared/components/Table";
import Tag, { TAG_COLORS } from "shared/components/Tag";
import Tooltip from "shared/components/Tooltip";
import { RegularText, SmallText } from "shared/components/Typography";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { getFullnameData } from "shared/utils/applicant";
import { transformRoute } from "shared/utils/routing";
import { formatDate } from "shared/utils/ui";

import { printDollars } from "shared/utils/dollar-print";
import { RenewalsTableProps } from "./interfaces";
import { Cell, TableContainer } from "./styled";
import { useRenewalsTable } from "./utils";

const RENEWAL_ESCALATION_TAG_COLOR: {
  [key in RenewalEscalationLevel]: string;
} = {
  [RenewalEscalationLevel.GREEN]: undefined,
  [RenewalEscalationLevel.YELLOW]: TAG_COLORS.ORANGE,
  [RenewalEscalationLevel.RED]: TAG_COLORS.RED,
};

const RenewalsTable = ({
  renewals = [],
  renewalsCount = 0,
  loading,
  hasInputValue,
  pagination,
  onTableChange,
  initialOrderingColumn,
  actions,
  breakpoint,
}: RenewalsTableProps) => {
  const { page, pageSize } = pagination;
  const history = useHistory();
  const tableRef = useRef<HTMLDivElement>();
  const {
    columnWidths,
    checkboxActions,
    onRowSelection,
    getRenewalsStatus,
    getRenewalDropdownItems,
  } = useRenewalsTable(
    renewals,
    tableRef?.current?.offsetWidth,
    breakpoint,
    actions
  );

  const onRowClick = (key: number) => {
    const renewalRoute = transformRoute(ROUTES.renewal, {
      id: key,
    });
    history.push(renewalRoute);
  };

  const largeScreenColumns = [
    "unit",
    "residents",
    "leaseExpirationDate",
    "rent",
    "renewalStatus",
  ];

  const columnBreakpoints = {
    xs: {
      column1: "unit",
      column2: "renewalStatus",
    },
    sm: ["unit", "renewalStatus"],
    md: ["unit", "leaseExpirationDate", "renewalStatus"],
    lg: ["unit", "residents", "leaseExpirationDate", "renewalStatus"],
    xl: largeScreenColumns,
    xxl: largeScreenColumns,
    xxxl: largeScreenColumns,
  };

  const currentColumns =
    columnBreakpoints[breakpoint] || columnBreakpoints[BREAKPOINT_TYPES.xl];
  const expandIconColIdx = Array.isArray(currentColumns)
    ? currentColumns?.length + 2
    : undefined;

  return (
    <TableContainer ref={tableRef}>
      <Table
        id="renewals-table"
        searching={hasInputValue}
        alt
        dropdownItems
        loading={loading}
        columns={[
          {
            title: "Unit",
            dataIndex: "unit",
            key: "unit",
            sorter: true,
            defaultSortOrder: initialOrderingColumn.unit,
            width: columnWidths[0],
          },
          {
            title: "Residents",
            dataIndex: "residents",
            key: "residents",
            width: columnWidths[1],
          },
          {
            title: "Lease Expires",
            dataIndex: "leaseExpirationDate",
            key: "leaseExpirationDate",
            sorter: true,
            defaultSortOrder: initialOrderingColumn.leaseExpirationDate,
            width: columnWidths[2],
          },
          {
            title: "Current Rent",
            dataIndex: "rent",
            key: "rent",
            sorter: true,
            defaultSortOrder: initialOrderingColumn.rent,
            width: columnWidths[3],
          },
          {
            title: "Renewal Status",
            dataIndex: "renewalStatus",
            key: "renewalStatus",
            width: columnWidths[4],
          },
          {
            title: "",
            dataIndex: "expandableRenewalDetails",
            key: "expandableRenewalDetails",
          },
        ]}
        columnBreakpoints={columnBreakpoints}
        checkboxActions={checkboxActions}
        onRowClick={onRowClick}
        onRowSelection={onRowSelection}
        placeholder={{
          Icon: Icon.LeasesIcon,
          label: "Renewals",
        }}
        rows={renewals.map((renewal, index) => {
          const {
            id,
            rent,
            residents = [],
            remainingDays,
            unit,
            leaseEndDate,
          } = renewal;
          const status = getRenewalsStatus(renewal);

          const firstResident = residents?.[0];
          const otherResidents = residents?.filter((_, idx) => idx > 0);

          const residentChecklistItems = [];

          if (firstResident) {
            const fullName = getFullnameData(firstResident).fullNameOrNA;
            residentChecklistItems.push({
              key: firstResident.id,
              label: fullName,
            });
          }

          const unitCell = (
            <Cell data-testid={`renewal-${index}-unit`}>
              <FlexContainer>
                <RegularText strong>{unit?.name}</RegularText>
                {unit && (
                  <DealIntegrationMark
                    unit={renewal.unit}
                    deal={renewal.expiringDeal}
                  />
                )}
              </FlexContainer>
              <SmallText light>{unit?.propertyName}</SmallText>
            </Cell>
          );

          const residentsCell = (
            <Cell
              data-testid={`renewal-${index}-residents`}
              alignItems="flex-start"
            >
              <CheckList items={residentChecklistItems} />
              {otherResidents?.length > 0 && (
                <Tooltip
                  title={otherResidents
                    .map((r) => getFullnameData(r).fullNameOrNA)
                    .join(", ")}
                >
                  <RegularText
                    className="cell-extra-info more-renters"
                    strong
                    // @ts-ignore
                    underline
                  >
                    {otherResidents.length} MORE
                  </RegularText>
                </Tooltip>
              )}
            </Cell>
          );

          const leaseExpirationCell = (
            <Cell alignItems="flex-start">
              <Tag
                color={RENEWAL_ESCALATION_TAG_COLOR[renewal.escalationLevel]}
              >
                <RegularText>
                  in {remainingDays} {pluralize("day", remainingDays)}
                </RegularText>
              </Tag>
              <SmallText className="cell-extra-info" light>
                on {formatDate(leaseEndDate)}
              </SmallText>
            </Cell>
          );

          const rentCell = (
            <Cell data-testid="lease-rent">{printDollars(rent)}</Cell>
          );

          const statusCell = <Cell data-testid="renewal-status">{status}</Cell>;

          const expandableRenewalDetailsCell = (
            <RenewalListItemDetails
              renewal={renewal}
              deleteOption={actions.deleteOption}
            />
          );

          return {
            key: id,
            values: [
              unitCell,
              residentsCell,
              leaseExpirationCell,
              rentCell,
              statusCell,
              expandableRenewalDetailsCell,
            ],
            dropdownItems: getRenewalDropdownItems(renewal),
            viewed: false,
          };
        })}
        pagination={{
          current: page,
          total: renewalsCount,
          defaultPageSize: pageSize,
          hideOnSinglePage: true,
          size: breakpoint === BREAKPOINT_TYPES.xs && "small",
        }}
        onChange={onTableChange}
        expandable={["expandableRenewalDetails"]}
        expandedRowRender={(_, idx) => (
          <RenewalListItemDetails
            renewal={renewals[idx]}
            deleteOption={actions.deleteOption}
          />
        )}
        expandIconColumnIndex={expandIconColIdx}
      />
    </TableContainer>
  );
};

export default withBreakpoint(RenewalsTable);
