import { DEFAULT_LEASE_STATUS_ORDER } from "shared/config/constants";

export const sortLeaseStatuses = () => {
  return (a, b) => {
    return (
      DEFAULT_LEASE_STATUS_ORDER.indexOf(a.id) -
      DEFAULT_LEASE_STATUS_ORDER.indexOf(b.id)
    );
  };
};
