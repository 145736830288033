import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const Title = styled.h2`
  margin: 0;
  line-height: normal;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
`;

const ApplicantInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ApplicantName = styled.div`
  color: ${COLORS.primary};
  width: 50%;
  font-weight: 500;
  margin-right: 10px;

  ${MEDIA_QUERIES.w.ltXl} {
    max-width: 150px;
  }
  ${MEDIA_QUERIES.w.ltLg} {
    max-width: 50%;
  }
  ${MEDIA_QUERIES.w.ltSm} {
    max-width: fit-content;
    width: auto;
  }
`;

const EmailLink = styled.a`
  color: ${COLORS.secondary};
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${MEDIA_QUERIES.w.ltXl} {
    max-width: 140px;
  }
  ${MEDIA_QUERIES.w.ltLg} {
    max-width: 50%;
  }
  ${MEDIA_QUERIES.w.ltSm} {
    max-width: fit-content;
    width: auto;
  }
`;

interface StyledTagProps {
  hideTag?: boolean;
}

const StyledTag = styled.div<StyledTagProps>`
  display: flex;
  align-items: center;
  color: ${COLORS.primary};
  max-width: 100px;
  ${(props) => props.hideTag && `visibility: hidden`}
`;

const StyledTagIcon = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: flex-end;
`;

const DataRow = styled.div`
  padding-right: 10px;
  width: 100%;
  margin-top: 24px;
  padding: 15px 20px;
  background-color: ${COLORS.itemActiveBg};
`;

const ApplicantsWrapper = styled.div`
  width: -webkit-fill-available;
  max-width: 580px;

  ${MEDIA_QUERIES.w.ltLg} {
    max-width: 100%;
  }
`;

const Contacts = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-right: 10px;
  ${MEDIA_QUERIES.w.ltSm} {
    justify-content: end;
    flex-direction: column;
    min-width: 120px;
    max-width: 140px;
  }
`;

export {
  Title,
  ApplicantInfo,
  ApplicantName,
  StyledTag,
  StyledTagIcon,
  DataRow,
  ApplicantsWrapper,
  EmailLink,
  Contacts,
};
