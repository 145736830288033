import { RenewalStatus } from "manager/config/constants";
import { LeaseGroup } from "manager/interfaces/api/leaseGroups";
import { LEASE_STATUS_CODES } from "shared/config/constants";

const getRenewalStatusLabel = (leaseGroup: LeaseGroup) => {
  switch (leaseGroup?.renewalUiStatus) {
    case RenewalStatus.OFFER_NOT_CREATED: {
      return "Offer not started";
    }
    case RenewalStatus.OFFER_CREATION_IN_PROGRESS: {
      return "Offer in progress";
    }
    case RenewalStatus.OFFER_CREATED: {
      return "Offer pending approval";
    }
    case RenewalStatus.OFFER_APPROVED: {
      return "Offer ready to send";
    }
    case RenewalStatus.OFFER_SENT: {
      return "Response pending";
    }
    case RenewalStatus.OFFER_PARTIALLY_ACCEPTED: {
      return "Offer partially accepted";
    }
    case RenewalStatus.OFFER_PARTIALLY_REJECTED: {
      return "Offer partially rejected";
    }
    case RenewalStatus.OFFER_REJECTED: {
      return "Not renewing";
    }
    case RenewalStatus.OFFER_ACCEPTED: {
      return "Renewal not started";
    }
    case RenewalStatus.RENEWAL_CREATION_IN_PROGRESS:
    case LEASE_STATUS_CODES.inProgress: {
      return "Renewal creation in progress";
    }
    case LEASE_STATUS_CODES.sent: {
      return "Signatures pending";
    }
    case LEASE_STATUS_CODES.partiallySigned: {
      return "Renewal partially signed";
    }
    case LEASE_STATUS_CODES.applicantsSigned:
    case LEASE_STATUS_CODES.processingManagerSignature: {
      return "Countersignature needed";
    }
    case LEASE_STATUS_CODES.leaseHandledOffline: {
      return "Renewal handled offline";
    }
    case RenewalStatus.RENEWAL_COMPLETED:
    case LEASE_STATUS_CODES.managerSigned: {
      return "Renewal executed";
    }
    case LEASE_STATUS_CODES.leaseExecutedOffline: {
      return "Renewal executed offline";
    }

    default: {
      return "";
    }
  }
};

const getLeaseGroupHeaderStatus = (
  leaseGroup: LeaseGroup,
  isRenewalLease: boolean
): string => {
  return isRenewalLease
    ? getRenewalStatusLabel(leaseGroup)
    : leaseGroup?.activeLease?.status;
};

export { getLeaseGroupHeaderStatus };
