import React from "react";

import { Button } from "shared/components/Button";

import { StyledFooter, StyledButton } from "./styled";

interface LeaseInProgressFooterProps {
  onFinishLease: () => void;
}

const LeaseInProgressFooter = ({
  onFinishLease,
}: LeaseInProgressFooterProps) => {
  return (
    <StyledFooter data-testid="lease-offline-footer">
      <StyledButton showMobile>
        <Button type="primary" onClick={onFinishLease}>
          Finish and send lease
        </Button>
      </StyledButton>
    </StyledFooter>
  );
};

export default LeaseInProgressFooter;
