import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
  name: Yup.string().nullable().required("This field is required"),
  title: Yup.string().nullable(),
  initials: Yup.string()
    .max(3)
    .nullable()
    .required("This field is required")
    .matches(/^[aA-zZ]+$/, "Only letters allowed"),
});
