import { API_URL } from "shared/config/apiRoutes";
import sharedApi from "shared/lib/api";

import additionalQuestionsApi from "./additionalQuestionsApi";
import agentsApi from "./agentsApi";
import applicantScreeningDocumentApi from "./applicantScreeningDocumentApi";
import applicationApi from "./applicationApi";
import applicationChargesApi from "./applicationChargesApi";
import bluemoonAlertResolverApi from "./bluemoonAlertResolverApi";
import bluemoonApi from "./bluemoonApi";
import companyApi from "./companyApi";
import countyCriminalSearchApi from "./countyCriminalSearchApi";
import customEmailConfigurations from "./customEmailConfigurations";
import dashboardApi from "./dashboardApi";
import documentsApi from "./documentsApi";
import integrationApi from "./integrationApi";
import leadsApi from "./leadsApi";
import leaseApi from "./leaseApi";
import leaseFeeConfigurationApi from "./leaseFeeConfigurationApi";
import leaseGroupsApi from "./leaseGroupsApi";
import magicLinksApi from "./magicLinksApi";
import magicPortalApi from "./magicPortalApi";
import managerApi from "./managerApi";
import renewalsApi from "./renewalsApi";
import renewalsSettingsApi from "./renewalsSettingsApi";
import sourcesApi from "./sourcesApi";
import surveyApi from "./surveyApi";
import systemAuditsApi from "./systemAuditsApi";
import unitsApi from "./unitApi";
import yardiIntegrationApi from "./yardiIntegrationApi";
import paymentServiceApi from './paymentServiceApi';

// TODO(V2-2973): Remove this export and replace all its usages with the const from `shared/config/apiRoutes`.
export { API_URL };

const api = {
  ...sharedApi,
  ...documentsApi,
  ...leaseApi,
  ...renewalsApi,
  ...leadsApi,
  ...yardiIntegrationApi,
  ...sourcesApi,
  ...magicLinksApi,
  ...agentsApi,
  ...managerApi,
  ...unitsApi,
  ...unitsApi,
  ...applicantScreeningDocumentApi,
  ...unitsApi,
  ...applicationApi,
  ...companyApi,
  ...magicPortalApi,
  ...systemAuditsApi,
  ...countyCriminalSearchApi,
  ...customEmailConfigurations,
  ...dashboardApi,
  ...leaseGroupsApi,
  ...renewalsSettingsApi,
  ...additionalQuestionsApi,
  ...applicationChargesApi,
  ...leaseFeeConfigurationApi,
  ...surveyApi,
  ...bluemoonAlertResolverApi,
  ...bluemoonApi,
  ...integrationApi,
  ...paymentServiceApi,
};

export default api;
