import React from "react";

import pluralize from "pluralize";

import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";

import { DocumentToPropertiesConfirmModalProps } from "./interfaces";
import {
  Title,
  IconContainer,
  UpdatesContainer,
  StepsContainer,
  CenterContainer,
  StepsTitle,
  Step,
} from "./styled";

const DocumentToPropertiesConfirmModal = ({
  documentName,
  addedItemsCount,
  removedItemsCount,
}: DocumentToPropertiesConfirmModalProps) => (
  <Modal showFooter={false}>
    <Modal.Body noPaddingTop>
      <IconContainer>
        <Icon.VerifiedIncomeAssetsIcon />
      </IconContainer>
      <Title>You’ve made the following updates for {documentName}:</Title>
      <UpdatesContainer>
        <ul>
          {Boolean(addedItemsCount) && (
            <li>
              {`Added to ${addedItemsCount} ${pluralize(
                "property",
                addedItemsCount
              )}`}
            </li>
          )}
          {Boolean(removedItemsCount) && (
            <li>
              {`Removed from ${removedItemsCount} ${pluralize(
                "property",
                removedItemsCount
              )}`}
            </li>
          )}
        </ul>
      </UpdatesContainer>
      {Boolean(addedItemsCount) && (
        <StepsContainer>
          <StepsTitle>Prepare this document for lease packages:</StepsTitle>
          <CenterContainer>
            {/* @ts-ignore */}
            <Step number={1}>Go to property settings</Step>
            {/* @ts-ignore */}
            <Step number={2}>Fill out the fields</Step>
            {/* @ts-ignore */}
            <Step number={3}>Activate document</Step>
          </CenterContainer>
        </StepsContainer>
      )}
    </Modal.Body>
  </Modal>
);

export default DocumentToPropertiesConfirmModal;
