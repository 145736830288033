export enum SecurityDepositDeductionStatus {
  INITIAL = "INITIAL",
  HOLDING_DEPOSIT_DEDUCTED = "HOLDING_DEPOSIT_DEDUCTED",
  HOLDING_DEPOSIT_CANNOT_BE_DEDUCTED = "HOLDING_DEPOSIT_CANNOT_BE_DEDUCTED",
}

export enum LeaseName {
  CurrentLease = "Current Lease",
  RenewalLease = "Renewal Lease",
  ExpiringLease = "Expiring Lease",
}
