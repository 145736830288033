import React, { useMemo } from "react";

import ManagerProfileMagicLink from "manager/components/ManagerProfileMagicLink";
import ROUTES, {
  GROUPED_ROUTES,
  GROUPED_ROUTES_KEYS,
} from "manager/config/routes";
import IntegrationsExtraControls from "manager/pages/Marketplace/IntegrationsExtraControls";
import Icon from "shared/components/Icon";
import { IntegrationProvider } from "shared/config/constants";
import { conditionalItem } from "shared/utils/array";
import { transformRoute } from "shared/utils/routing";

import { useManagerTranslate } from "./use-manager-translate";

const {
  DASHBOARD,
  LEADS,
  DEALS,
  LEASING,
  LEASES,
  RENEWALS,
  PROPERTIES_AND_PORTFOLIOS,
  MARKETPLACE,
  SETTINGS,
} = GROUPED_ROUTES_KEYS;

type Company = {
  logo?: string;
  shortName?: string;
  name?: string;
  enableYardi: boolean;
};

export type LayoutProps = {
  company?: Company;
  user: any;
  isPrescreeningEnabled: boolean;
  areRenewalsEnabled: boolean;
  isMagicLinksEnabled: boolean;
};

export type BacklinkProps = {
  children: string;
  to: string;
};

export const getBackLinks = (t: (key: string) => string) => ({
  companyRole: {
    children: t("back-link.dashboard"),
    to: ROUTES.dashboard,
  },
  dashboard: {
    children: t("back-link.switch-company"),
    to: ROUTES.selectCompany,
  },
  lead: {
    children: t("back-link.leads"),
    to: ROUTES.leads,
  },
  deal: {
    children: t("back-link.applications"),
    to: ROUTES.deals,
  },
  dealExtraDetails: (id) => ({
    children: t("back-link.application-summary"),
    to: id && transformRoute(ROUTES.deal, { id }),
  }),
  lease: {
    children: t("back-link.application-summary"),
    to: ROUTES.deal,
  },
  leaseSummary: (id) => ({
    children: t("back-link.application-summary"),
    to: id && transformRoute(ROUTES.deal, { id }),
  }),
  application: {
    children: `:unitName ${t("back-link.application")}`,
    to: ROUTES.deal,
  },
  propertySettings: (id) => {
    // eslint-disable-next-line no-restricted-globals
    const activeTab = location.pathname.split("/")[3];
    if (id && activeTab && activeTab !== "units") {
      return {
        children: t("back-link.property"),
        to: transformRoute(ROUTES.property, { id }),
      };
    }
    return {
      children: t("back-link.properties"),
      to: ROUTES.properties,
    };
  },
  propertyDocumentSetup: (id) => ({
    children: t("back-link.property-settings"),
    to: id && transformRoute(ROUTES.propertySettingsDocumentSetup, { id }),
  }),
  property: {
    children: t("back-link.properties"),
    to: ROUTES.properties,
  },
  portfolio: {
    children: t("back-link.portfolios"),
    to: ROUTES.portfolios,
  },
  unit: {
    children: t("back-link.manhatten-living-complex"),
    to: ROUTES.property,
  },
  employee: {
    children: t("back-link.employees"),
    to: ROUTES.employees,
  },
  employees: {
    children: t("back-link.company-settings"),
    to: ROUTES.settings,
  },
  applicationsLeaseSetup: {
    children: t("back-link.company-settings"),
    to: ROUTES.settings,
  },
  prescreeningSetup: {
    children: t("back-link.company-settings"),
    to: ROUTES.settings,
  },
  notificationsSettings: {
    children: t("back-link.company-settings"),
    to: ROUTES.settings,
  },
  applicant: {
    children: t("back-link.renters-&-applicants"),
    to: ROUTES.applicants,
  },
  specificIntegration: {
    children: t("back-link.marketplace"),
    to: ROUTES.marketplace,
  },
  integrationDashboard: (provider: IntegrationProvider) => ({
    children: t("back-link.integration-dashboard"),
    to: transformRoute(ROUTES.integrationDashboard, {
      provider,
    }),
  }),
  bluemoonDashboard: {
    children: t("back-link.marketplace"),
    to: ROUTES.bluemoonDashboard,
  },
  magicLinks: {
    children: t("back-link.company-settings"),
    to: ROUTES.settings,
  },
  downloadCenter: {
    children: t("back-link.company-settings"),
    to: ROUTES.settings,
  },
  openApiSettings: {
    children: t("back-link.company-settings"),
    to: ROUTES.settings,
  },
  lumenDashboard: {
    children: t("back-link.company-settings"),
    to: ROUTES.settings,
  },
  lumenFormula: {
    children: t("back-link.lumen-dashboard"),
    to: ROUTES.lumenDashboard,
  },
});

export const getHeader = (user: any, isMagicLinksEnabled: boolean) => ({
  logoLink: "/",
  user: {
    ...user,
    extraFields: isMagicLinksEnabled
      ? [{ key: "magicLink", node: <ManagerProfileMagicLink /> }]
      : [],
  },
  extraControls: <IntegrationsExtraControls />,
});

export const getSidebar = (
  t: (key: string) => string,
  company: Company,
  isPrescreeningEnabled: boolean,
  areRenewalsEnabled: boolean
) => ({
  items: [
    {
      key: DASHBOARD,
      text: t("sidebar-title.dashboard"),
      avatar: {
        src: company?.logo,
        alt: company?.shortName,
      },
      route: ROUTES.dashboard,
    },
    ...conditionalItem(isPrescreeningEnabled, {
      key: LEADS,
      text: t("sidebar-title.leads"),
      icon: Icon.LeadsIcon,
      route: ROUTES.leads,
    }),
    {
      key: DEALS,
      text: t("sidebar-title.applications"),
      icon: Icon.DealsIcon,
      route: ROUTES.deals,
    },
    {
      key: LEASING,
      text: t("sidebar-title.leasing"),
      icon: Icon.LeasesIcon,
      items: [
        {
          key: LEASES,
          text: t("sidebar-title.leases"),
          route: ROUTES.leases,
        },
        ...conditionalItem(areRenewalsEnabled, {
          key: RENEWALS,
          text: t("sidebar-title.renewals"),
          route: ROUTES.renewals,
        }),
      ],
    },
    {
      key: PROPERTIES_AND_PORTFOLIOS,
      text: t("sidebar-title.inventory"),
      icon: Icon.MyPropertyIcon,
      route: ROUTES.properties,
    },
    {
      key: MARKETPLACE,
      text: t("sidebar-title.marketplace"),
      icon: Icon.IntegrationsMenuItemIcon,
      route: ROUTES.marketplace,
    },
    {
      key: SETTINGS,
      text: t("sidebar-title.settings"),
      icon: Icon.SettingsIcon,
      route: ROUTES.settings,
    },
  ],
  groupedRoutes: GROUPED_ROUTES,
});

export const useLayout = ({
  company,
  user,
  isPrescreeningEnabled,
  areRenewalsEnabled,
  isMagicLinksEnabled,
}: LayoutProps) => {
  const { t } = useManagerTranslate();
  const sidebar = useMemo(
    () => getSidebar(t, company, isPrescreeningEnabled, areRenewalsEnabled),
    [company, isPrescreeningEnabled, areRenewalsEnabled]
  );

  const header = useMemo(
    () => getHeader(user, isMagicLinksEnabled),
    [user, isMagicLinksEnabled, company]
  );

  const backLinks = useMemo(() => getBackLinks(t), []);

  return { sidebar, backLinks, header };
};
