
import { useHistory } from "react-router-dom";

import DealIntegrationMark from "manager/components/IntegrationMark/DealIntegrationMark";

import ResendLeaseModal from "manager/components/Leasing/ResendLeaseModal/ResendLeaseModalContainer";
import ROUTES from "manager/config/routes";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Table from "shared/components/Table";
import { RegularText, SmallText } from "shared/components/Typography";
import {
  BREAKPOINT_TYPES,
  LEASE_STATUS_CODES,
  LEASE_STATUS_LABEL,
} from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import useModal from "shared/hooks/useModal";
import { Pagination } from "shared/interfaces";
import { transformRoute } from "shared/utils/routing";
import { formatDate, getValueOrNA } from "shared/utils/ui";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import {
  Cell,
  FlexWrapper,
  LeaseExecutedIcon,
  LeaseInProgressIcon,
  Link,
  StyledCheckList,
} from "./Leases.styled";
import { Lease } from "./interfaces";

const getActionLink = (lease) => {
  const { openModalDialog } = useModal();
  const { isBluemoonLease } = lease;

  if (lease?.status === LEASE_STATUS_CODES.applicantsSigned) {
    return (
      <Link
        to={transformRoute(
          isBluemoonLease ? ROUTES.leaseSummary : ROUTES.leaseCountersign,
          {
            id: lease.id,
          }
        )}
        data-testid={`csl-${lease.id}`}
      >
        Countersign
      </Link>
    );
  }
  if (lease?.status === LEASE_STATUS_CODES.inProgress) {
    return (
      <Link
        to={transformRoute(ROUTES.leaseBuilder, {
          id: lease.id,
        })}
        data-testid={`cl-${lease.id}`}
      >
        Finish And Send Lease
      </Link>
    );
  }
  if (
    lease?.status === LEASE_STATUS_CODES.leaseExecutedOffline ||
    lease?.status === LEASE_STATUS_CODES.leaseHandledOffline ||
    lease?.status === LEASE_STATUS_CODES.managerSigned ||
    lease?.status === LEASE_STATUS_CODES.processingManagerSignature
  ) {
    return (
      <Link
        to={transformRoute(ROUTES.lease, {
          id: lease.id,
        })}
        data-testid={`vl-${lease.id}`}
      >
        {lease?.status === LEASE_STATUS_CODES.leaseHandledOffline
          ? "View Details"
          : "View Lease"}
      </Link>
    );
  }
  if (
    lease?.status === LEASE_STATUS_CODES.partiallySigned ||
    lease?.status === LEASE_STATUS_CODES.sent
  ) {
    const applicantsNotSignedLease =
      lease?.applications?.filter((applicant) => !applicant.isSigned) || [];

    return (
      <UnderlineLink
        onClick={() =>
          openModalDialog(ResendLeaseModal, {
            propertyName: lease?.unit?.propertyName,
            unitName: lease?.unit?.name,
            leaseId: lease?.id,
            applicants: applicantsNotSignedLease,
            reminderSentAt: lease?.reminderSentAt,
            isLastReminderAuto: lease?.isLastReminderAuto,
          })
        }
        data-testid={`sr-${lease.id}`}
      >
        Send Reminder
      </UnderlineLink>
    );
  }

  return null;
};

interface LeasesTableProps {
  leases: Lease[];
  loading: boolean;
  hasInputValue: boolean;
  leasesCount: number;
  setPagination: (params: Pagination) => void;
  pagination: Pagination;
  onTableChange: (
    tablePagination: object,
    tableFilters: object,
    sorter: object
  ) => void;
  breakpoint?: BREAKPOINT_TYPES;
  initialOrderingColumn: { [x: string]: string };
}

const LeasesTable = ({
  leases = [],
  leasesCount,
  loading,
  hasInputValue,
  setPagination,
  pagination,
  onTableChange,
  initialOrderingColumn,
  breakpoint,
}: LeasesTableProps) => {
  const history = useHistory();
  const { page, pageSize } = pagination;

  const onPaginationChange = (currentPage) => {
    const newPagination = { page: currentPage, pageSize };
    setPagination(newPagination);
  };

  const getLeaseStatusText = (code, complete, total) => {
    const isPartiallySigned = code === LEASE_STATUS_CODES.partiallySigned;
    const leaseStatus =
      (isPartiallySigned
        ? LEASE_STATUS_LABEL[code](complete, total)
        : LEASE_STATUS_LABEL[code]) || "Unknown";
    const inProgress = code === LEASE_STATUS_CODES.inProgress;
    const isFullyExecuted =
      code === LEASE_STATUS_CODES.managerSigned ||
      code === LEASE_STATUS_CODES.leaseExecutedOffline;

    let StatusIcon;

    if (inProgress) {
      StatusIcon = <LeaseInProgressIcon />;
    } else if (isFullyExecuted) {
      StatusIcon = <LeaseExecutedIcon />;
    }

    return (
      <FlexContainer alignItems="center">
        {StatusIcon}
        {leaseStatus}
      </FlexContainer>
    );
  };

  return (
    <Table
      id="leases-table"
      searching={hasInputValue}
      alt
      loading={loading}
      columns={[
        {
          title: "Unit",
          dataIndex: "unit",
          key: "unit",
          sorter: true,
          defaultSortOrder: initialOrderingColumn.unit,
        },
        {
          title: "Name",
          dataIndex: "applicants",
          key: "applicants",
          width: 280,
        },
        {
          title: "Lease Dates",
          dataIndex: "leaseDate",
          key: "leaseDate",
          sorter: true,
          defaultSortOrder: initialOrderingColumn.leaseDate,
          width: 180,
        },
        {
          title: "Rent",
          dataIndex: "rent",
          key: "rent",
          sorter: true,
          defaultSortOrder: initialOrderingColumn.rent,
        },
        {
          title: "Status",
          dataIndex: "leaseStatus",
          key: "leaseStatus",
        },
      ]}
      columnBreakpoints={{
        xs: {
          column1: "unit",
          column2: "leaseDate",
        },
        sm: ["unit", "leaseDate", "leaseStatus"],
        md: ["unit", "leaseDate", "leaseStatus"],
        lg: ["unit", "applicants", "leaseDate", "rent", "leaseStatus"],
        xl: ["unit", "applicants", "leaseDate", "rent", "leaseStatus"],
      }}
      onRowClick={(key) =>
        history.push(transformRoute(ROUTES.lease, { id: key }))
      }
      placeholder={{
        Icon: Icon.LeasesIcon,
        label: "Leases",
      }}
      rows={leases.map((lease, index) => {
        const applicants = (lease?.applications || []).map(
          ({ id, firstName, lastName, isSigned }) => ({
            id,
            firstName,
            lastName,
            isSubmitted: true,
            leaseSigned: isSigned,
          })
        );
        const numTotalApplications = lease.applications?.length;

        const numSignedApplications = lease.applications?.filter(
          (applicant) => applicant.isSigned
        ).length;
        const isRentIconNeeded = lease.unit?.yardiConnected;
        const RentIcon = lease.rentPaid ? (
          <Icon.DollarSignGreen />
        ) : (
          <Icon.DollarSignGray />
        );

        const actionLink = getActionLink(lease);

        return {
          key: lease.id,
          values: [
            <Cell>
              <RegularText data-testid={`lease-${index}-unitName`}>
                {getValueOrNA(lease.unit?.name)}
                {lease.unit && (
                  <DealIntegrationMark
                    unit={lease.unit}
                    deal={{
                      yardiPcode: lease.yardiPcode,
                      yardiTcode: lease.yardiTcode,
                    }}
                  />
                )}
              </RegularText>
              <SmallText light data-testid={`lease-${index}-propertyName`}>
                {lease.unit?.propertyName}
              </SmallText>
            </Cell>,
            <Cell>
              <RegularText data-testid={`lease-${index}-applicants`}>
                {/* @ts-ignore */}
                <StyledCheckList items={applicants} />
              </RegularText>
            </Cell>,
            <Cell>
              <RegularText data-testid={`lease-${index}-leaseDate`}>
                {getValueOrNA(
                  lease.startDate &&
                    lease.endDate &&
                    `${formatDate(lease.startDate)} - ${formatDate(
                      lease.endDate
                    )}`
                )}
              </RegularText>
            </Cell>,
            <Cell>
              <FlexWrapper alignItems="center">
                <RegularText data-testid={`lease-${index}-rent`}>
                  {printDollarsFromCents(lease.rent)}
                </RegularText>
                {isRentIconNeeded && RentIcon}
              </FlexWrapper>
            </Cell>,
            <Cell>
              <div data-testid="lease-status">
                {getLeaseStatusText(
                  lease.status,
                  numSignedApplications,
                  numTotalApplications
                )}
                {actionLink || <></>}
              </div>
            </Cell>,
          ],
          viewed: false,
        };
      })}
      pagination={{
        current: page,
        onChange: onPaginationChange,
        total: leasesCount,
        defaultPageSize: pageSize,
        hideOnSinglePage: true,
        size: breakpoint === BREAKPOINT_TYPES.xs && "small",
      }}
      onChange={onTableChange}
    />
  );
};

export default withBreakpoint(LeasesTable);
