import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

const Description = styled("div")({
  textAlign: "left",
  [MEDIA_QUERIES.w.gteSm]: {
    marginLeft: "60px",
  },
});

const DescriptionTitle = styled("div")({
  marginBottom: "16px",
});

export { Description, DescriptionTitle };
