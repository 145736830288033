import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import {
  CreateRenewalNoteRequest,
  ApproveRenewalOffersRequest,
  ApproveRenewalOffersResponse,
  CreateUpdateRenewalOfferRequest,
  CreateRenewalResponse,
  RenewalBasic,
  RenewalNote,
  RenewalsPaginationRequest,
  SendRenewalOffersToApplicantsRequest,
  RenewalOfferActionRequest,
  WithdrawRenewalOfferOptionRequest,
  InviteApplicantsToRenewalsRequest,
} from "manager/interfaces/api/renewals";
import { API_URL } from "shared/config/apiRoutes";
import { Offer } from "shared/interfaces/api/renewals";
import { PaginationResponse } from "shared/interfaces/pagination";
import { transformRoute } from "shared/utils/routing";

const api = {
  getRenewals: ({
    unitPropertyOrApplicant,
    propertyId,
    renewalStatus,
    dateMin,
    dateMax,
    renewalCategory,
    ordering,
    page,
    pageSize,
  }: RenewalsPaginationRequest) =>
    axios
      .get<PaginationResponse<RenewalBasic>>(`${API_URL}${ROUTES.renewals}`, {
        params: {
          unit_or_property_or_applicant: unitPropertyOrApplicant,
          property_id__in: propertyId,
          renewal_status: renewalStatus,
          expiring_lease__end_date__gte: dateMin,
          expiring_lease__end_date__lte: dateMax,
          renewal_category: renewalCategory,
          ordering,
          page_size: pageSize,
          page,
        },
      })
      .then(({ data }) => data),

  getRenewal: (id: number) =>
    axios
      .get<RenewalBasic>(transformRoute(`${API_URL}${ROUTES.renewal}`, { id }))
      .then(({ data }) => data),

  sendRequestRenewal: (id: number) =>
    axios
      .post<void>(
        transformRoute(`${API_URL}${ROUTES.sendRequestRenewal}`, { id })
      )
      .then(({ data }) => data),

  createRenewal: (id: number) =>
    axios
      .post<CreateRenewalResponse>(
        transformRoute(`${API_URL}${ROUTES.createRenewal}`, { id })
      )
      .then(({ data }) => data),

  sendRenewalOfferRequest: (renewalId: number) =>
    axios
      .post<void>(
        transformRoute(`${API_URL}${ROUTES.sendRenewalOfferRequest}`, {
          id: renewalId,
        })
      )
      .then(({ data }) => data),

  sendRenewalOfferApprovalReminder: (renewalId: number) =>
    axios
      .post<void>(
        transformRoute(`${API_URL}${ROUTES.sendRenewalOfferApprovalReminder}`, {
          id: renewalId,
        })
      )
      .then(({ data }) => data),

  approveRenewalOffers: (request: ApproveRenewalOffersRequest) =>
    axios
      .post<ApproveRenewalOffersResponse>(
        `${API_URL}${ROUTES.approveRenewalOffers}`,
        request
      )
      .then(({ data }) => data),

  sendRenewalOffers: (request: SendRenewalOffersToApplicantsRequest) =>
    axios
      .post<void>(`${API_URL}${ROUTES.sendRenewalOffers}`, request)
      .then(({ data }) => data),

  sendRenewalOfferReminder: (renewalId: number) =>
    axios
      .post<void>(
        transformRoute(`${API_URL}${ROUTES.sendRenewalOfferReminder}`, {
          id: renewalId,
        })
      )
      .then(({ data }) => data),

  reopenRenewalOffer: ({ renewalId, offerId }: RenewalOfferActionRequest) =>
    axios
      .post<void>(
        transformRoute(`${API_URL}${ROUTES.reopenRenewalOffer}`, {
          id: renewalId,
          offerId,
        })
      )
      .then(({ data }) => data),

  withdrawRenewalOffer: ({ renewalId, offerId }: RenewalOfferActionRequest) =>
    axios
      .post<void>(
        transformRoute(`${API_URL}${ROUTES.withdrawRenewalOffer}`, {
          id: renewalId,
          offerId,
        })
      )
      .then(({ data }) => data),

  withdrawRenewalOfferOption: ({
    renewalId,
    offerId,
    optionId,
  }: WithdrawRenewalOfferOptionRequest) =>
    axios
      .post<void>(
        transformRoute(`${API_URL}${ROUTES.withdrawRenewalOfferOption}`, {
          id: renewalId,
          offerId,
          optionId,
        })
      )
      .then(({ data }) => data),

  createRenewalOffer: ({ renewalId, offer }: CreateUpdateRenewalOfferRequest) =>
    axios
      .post<Offer>(
        transformRoute(`${API_URL}${ROUTES.renewalOffers}`, { id: renewalId }),
        offer
      )
      .then(({ data }) => data),

  getRenewalOffer: (renewalId: number, offerId: number) =>
    axios
      .get<Offer>(
        transformRoute(`${API_URL}${ROUTES.renewalOffer}`, {
          id: renewalId,
          offerId,
        })
      )
      .then(({ data }) => data),

  updateRenewalOffer: (request: CreateUpdateRenewalOfferRequest) =>
    axios
      .put<Offer>(
        transformRoute(`${API_URL}${ROUTES.renewalOffer}`, {
          id: request?.renewalId,
          offerId: request?.offer?.id,
        }),
        request.offer
      )
      .then(({ data }) => data),

  rejectRenewal: (renewalId: number) =>
    axios
      .post<void>(
        transformRoute(`${API_URL}${ROUTES.rejectRenewal}`, {
          id: renewalId,
        })
      )
      .then(({ data }) => data),

  cancelRenewal: (renewalId: number) =>
    axios
      .post<void>(
        transformRoute(`${API_URL}${ROUTES.cancelRenewal}`, {
          id: renewalId,
        })
      )
      .then(({ data }) => data),

  getRenewalNotes: (id: number) =>
    axios
      .get<RenewalNote[]>(
        transformRoute(`${API_URL}${ROUTES.renewalNotes}`, { id })
      )
      .then(({ data }) => data),

  createRenewalNote: ({ note, id }: CreateRenewalNoteRequest) =>
    axios
      .post<RenewalNote>(
        transformRoute(`${API_URL}${ROUTES.createRenewalNote}`, { id }),
        {
          note,
        }
      )
      .then(({ data }) => data),

  rejectRenewalApplicationOffer: ({
    renewalId,
    applicationId,
  }: {
    renewalId: number;
    applicationId: number;
  }) =>
    axios
      .post<void>(
        transformRoute(`${API_URL}${ROUTES.rejectRenewalApplicationOffer}`, {
          id: renewalId,
        }),
        { applicationId }
      )
      .then(({ data }) => data),

  removeApplicantFromRenewal: ({
    renewalId,
    applicationId,
  }: {
    renewalId: number;
    applicationId: number;
  }) =>
    axios
      .post<void>(
        transformRoute(`${API_URL}${ROUTES.removeApplicantFromRenewal}`, {
          id: renewalId,
        }),
        { application: applicationId }
      )
      .then(({ data }) => data),

  addApplicantsToRenewal: ({
    renewalId,
    applicants,
  }: InviteApplicantsToRenewalsRequest) =>
    axios
      .post<void>(
        transformRoute(`${API_URL}${ROUTES.addApplicantsToRenewal}`, {
          id: renewalId,
        }),
        { applicants }
      )
      .then(({ data }) => data),
};

export default api;
