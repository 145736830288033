import styled from "@emotion/styled";
import { Typography } from "antd";
import { Form } from "formik";

import BaseFooterBar from "shared/components/FooterBar";
import Icon from "shared/components/Icon";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const FooterBar = styled(BaseFooterBar)({
  justifyContent: "space-between",
  [MEDIA_QUERIES.w.ltMd]: {
    padding: "0 56px",
  },
});

const { Text } = Typography;

const Documents = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const StyledDocument = styled("div")((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100px",
  backgroundColor: "#FFF",
  padding: "15px 20px",
  ...(props.default && {
    backgroundColor: "#FCFCFF",
  }),
}));

const DocumentChecker = styled("div")({
  display: "flex",
  alignItems: "center",
});

const DocumentThumbnail = styled(Icon.PDFPreviewIcon)((props) => ({
  width: "56px",
  height: "72px",
  ...(props.checked && {
    border: `1px solid ${COLORS.checkbox}`,
  }),
}));

const DocumentDescription = styled("div")({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "20px",
});

const DocumentName = styled(Text)({
  color: COLORS.primary,
  fontSize: "16px",
});

const DocumentPages = styled(Text)({
  color: COLORS.secondary,
  fontSize: "13px",
});

const ActionsContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  a: {
    marginRight: 24,
    [MEDIA_QUERIES.w.ltMd]: {
      marginRight: 10,
    },
  },
});

const LoadingSpinner = styled(Icon.LoadingSpinnerGreenLargeIcon)({
  position: "fixed",
  left: "50%",
  bottom: "40%",
  transform: "translateX(-50%)",
  zIndex: 100,
});

const CompletedIcon = styled(Icon.ApprovedIcon)({
  minWidth: "16px",
  maxWidth: "16px",
  marginLeft: "10px",
});

const StyledForm = styled(Form)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export {
  FooterBar,
  Documents,
  StyledDocument,
  DocumentChecker,
  DocumentThumbnail,
  DocumentDescription,
  DocumentName,
  DocumentPages,
  ActionsContainer,
  LoadingSpinner,
  CompletedIcon,
  StyledForm,
};
