import styled from "@emotion/styled";

import Well from "shared/components/Well";
import { COLORS } from "shared/config/constants";
import { ModalListScrollbar } from "shared/styles/commonStyles";

export const Warning = styled(Well)`
  display: flex;
  padding: 20px;
  align-items: center;
`;

export const WarningIconContainer = styled.div`
  margin-right: 15px;
`;

export const IssuesList = styled.ul`
  max-height: 200px;
  overflow-y: scroll;
  margin: 20px auto 0 auto;
  padding-left: 15px;
  border: 1px solid ${COLORS.borders};
  ${ModalListScrollbar(COLORS.gray1)}
  li {
    max-width: 95%;
    margin: auto;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid ${COLORS.borders};
    &::marker {
      color: ${COLORS.violetTheme.lightBlueViolet};
    }
  }
`;
