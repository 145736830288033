import React from "react";

import { Modal, SIZES } from "shared/components/Modal";
import { RegularText } from "shared/components/Typography";

import { Subtitle } from "./styled";

const WithdrawLeaseModal = ({
  unitName,
  propertyName,
  submit,
  isWithdrawingLease,
}) => (
  <Modal
    title="Withdraw Lease?"
    subtitle={
      <Subtitle>
        <RegularText>Unit:</RegularText>&nbsp;
        <RegularText strong>
          {unitName}, {propertyName}
        </RegularText>
      </Subtitle>
    }
    submit={submit}
    submitting={isWithdrawingLease}
    submitButtonLabel="withdraw"
    submitButtonSize={SIZES.lg}
    cancelLinkLabel="Cancel"
  >
    <Modal.Body data-testid="withdraw-lease-message">
      Withdrawing the lease will discard any current applicant signatures and
      other applicants will no longer be able to access the lease. Your lease
      details and data will be saved and editable.
    </Modal.Body>
  </Modal>
);

export default WithdrawLeaseModal;
