import React from "react";

import upperCase from "lodash/upperCase";

import { LeaseGroup } from "manager/interfaces/api/leaseGroups";
import { RegularText, SmallText } from "shared/components/Typography";

import { StyledLeaseGroupStatus } from "./styled";
import { getStatusDescription } from "./utils";

const LeaseGroupStatus = ({ leaseGroup }: { leaseGroup: LeaseGroup }) => {
  const description = getStatusDescription(leaseGroup);

  return description ? (
    <StyledLeaseGroupStatus noBorder largePadding>
      {description.Icon}
      <RegularText strong className="text-white">
        {upperCase(description.title)}
      </RegularText>
      {description.subtitle.map((item) => (
        <SmallText key={item} className="text-white subtitle">
          {item}
        </SmallText>
      ))}
    </StyledLeaseGroupStatus>
  ) : null;
};

export default LeaseGroupStatus;
