import React from "react";
import FadeIn from "shared/components/animated/FadeIn";
import FlexContainer from "shared/components/FlexContainer";
import { SmallTitle, MediumTitle } from "shared/components/Typography";
import { LumenV2Header } from "shared/components/vega/LumenV2";
import { Lease, Permission } from "shared/graphql";
import { COLORS, typography } from "../../../../shared/config/constants";
import { ApplicantSummary } from "../modals";
import { ApproveStatus, AppStatus } from "../types";

import { ActionButton, ActionPopover, DoneTitle } from "./blocks";
import { LumenItem } from "./interfaces";
import {
  MoreActionsButton,
  NoLumenTitle,
  ResponsiveFlexContainer,
  WellContainer,
} from "./styled";
import { useActionBar } from "./use-action-bar";

export interface LumenActionBarProps {
  score?: string;
  items?: LumenItem[];
  tooltip?: string;
  pending?: boolean;
}

export interface ActionBarProps {
  dealId?: string | number;
  lease?: Lease;
  showLumen: boolean;
  status?: AppStatus;
  approved?: ApproveStatus;
  statusLabel?: string;
  lumen?: LumenActionBarProps;
  permission?: Permission;
  refetch?: () => void;
  applicantsSummary: ApplicantSummary[];
  conditionalProceed: boolean;
  missingUnit?: boolean;
  countryCriminalSearchInProgress?: boolean;
  criminalSearchInProgress?: boolean;
  backgroundReportsUnavailable?: boolean;
  ocrProcessing?: boolean;
  canManuallySendToPms?: boolean;
  hasBeenSentToEntrata?: boolean;
  lastSentToPmsAt?: Date;
  lumenRun?: any;
  provider?: string;
}

export const ActionBar = (props: ActionBarProps) => {
  let { status } = props;
  const { showLumen, statusLabel, permission, lumenRun } = props;

  const {
    actions,
    button,
    buttons,
    visiblePopover,
    onVisibleChange,
    canManuallySendToPms,
    hasBeenSentToEntrata,
    lumenSubtitle,
  } = useActionBar(props);

  const isSubmitted = [AppStatus.Done, AppStatus.Submitted].includes(status);

  return (
    <>
      <WellContainer showLumen>
        <FadeIn>
          <ResponsiveFlexContainer
            justifyContent="space-between"
            fullHeight
            alignItems="center"
            gap="12px"
          >
            {showLumen && isSubmitted && <LumenV2Header lumenRun={lumenRun} />}
            <div className='vega title invert'>{statusLabel}</div>
            {showLumen && !isSubmitted && <div className='vega info invert'>{lumenSubtitle}</div>}

            {hasBeenSentToEntrata && (
              <MediumTitle
                strong
                style={{
                  color: COLORS.violetTheme.lightGreen,
                  alignSelf: "center",
                }}
              >
                APPLICATION SENT TO ENTRATA
              </MediumTitle>
            )}

            <FlexContainer alignItems="center">
              <ActionButton
                actions={actions}
                button={button}
                permission={permission}
              />

              <ActionPopover
                actions={actions}
                buttons={buttons}
                skip={button}
                permission={permission}
                visiblePopover={visiblePopover}
                onVisibleChange={onVisibleChange}
              >
                <MoreActionsButton type="primary">
                  MORE ACTIONS
                </MoreActionsButton>
              </ActionPopover>
            </FlexContainer>
          </ResponsiveFlexContainer>
        </FadeIn>
      </WellContainer>
    </>
  );
};
