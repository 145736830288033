import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";

const StyledAddRenewalModal = styled(Modal)`
  .invitation-option {
    margin-bottom: 24px;

    &.existing-applicant {
      margin-bottom: 12px;
    }
  }

  .residents-select {
    margin-bottom: 24px;
    margin-left: 30px;
  }
`;

export { StyledAddRenewalModal };
