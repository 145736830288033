import React from "react";

import NavigationPrompt from "react-router-navigation-prompt";

import { PreventLeaveModalProps } from "./interfaces";
import NavigationPromptInner from "./NavigationPromptInner";

const PreventLeaveModal = ({
  title = "Are you sure?",
  subtitle = "\n",
  preventLeaveWhen,
  cancelText = "Cancel",
  confirmText = "Exit",
}: PreventLeaveModalProps) => (
  <NavigationPrompt renderIfNotActive when={preventLeaveWhen} disableNative>
    {({ onCancel, onConfirm, isActive }) => (
      <NavigationPromptInner
        title={title}
        subtitle={subtitle}
        cancelText={cancelText}
        confirmText={confirmText}
        onCancel={onCancel}
        onConfirm={onConfirm}
        isActive={isActive}
      />
    )}
  </NavigationPrompt>
);

export default PreventLeaveModal;
