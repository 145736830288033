import React, { useEffect } from "react";

import dayjs from "dayjs";
import { Helmet } from "react-helmet";

import Layout from "manager/components/Layout";
import ROUTES from "manager/config/routes";
import { Lease } from "manager/interfaces/api/leaseGroups";
import Container from "shared/components/Container";
import { HeaderProps } from "shared/components/Header";
import { BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Property from "shared/components/Property";
import { SidebarProps } from "shared/components/Sidebar";
import Tag from "shared/components/Tag";
import Tile from "shared/components/Tile";
import { LEASE_STATUS_CODES, DATE_TIME_FORMAT } from "shared/config/constants";
import OptionItem from "shared/interfaces/api/optionItem";
import { transformRoute } from "shared/utils/routing";

import ApplicantsInfo from "./ApplicantsInfo";
import {
  LeaseOfflineFooter,
  LeaseFooter,
  LeaseInProgressFooter,
} from "./LeaseFooter";
import ResendFooter from "./LeaseFooter/ResendFooter";
import LeaseHeader from "./LeaseHeader";
import LeaseInfo from "./LeaseInfo";
import {
  LeaseContainer,
  LoadingSpinner,
  LeaseInfoContainer,
  TitleWrapper,
  GeneratedBy,
  GeneratedByWrapper,
  LeaseContent,
} from "./styled";
import { isLeaseWithdrawable } from "./utils";

interface LeaseSummaryProps {
  isLeaseLoading: boolean;
  lease: Lease;
  refetchLease: () => void;
  leaseStatuses: { id: string; value: string }[];
  viewLease: () => void;
  countersign: () => void;
  isESigningEnabled: boolean;
  isLeaseModifying: boolean;
  onModifyLease: () => void;
  onSwitchESign: () => void;
  applicantTypes: { [key: string]: OptionItem };
  openWithdrawLeaseModal: () => void;
  confirmExecution: () => void;
  sidebar: SidebarProps;
  header: HeaderProps;
  resendLease: () => void;
  onFinishLease: () => void;
}

const LeaseSummary = ({
  lease,
  refetchLease,
  leaseStatuses,
  isLeaseLoading,
  viewLease,
  countersign,
  isESigningEnabled,
  onModifyLease,
  isLeaseModifying,
  onSwitchESign,
  applicantTypes,
  openWithdrawLeaseModal,
  confirmExecution,
  sidebar,
  header,
  resendLease,
  onFinishLease,
}: LeaseSummaryProps) => {
  const TIMEOUT = 5000;
  const { name: unitName, property } = lease?.deal?.unit || {};
  const { address, name: propertyName } = property || {};
  const title = `${unitName} Lease`;
  const isWithdrawable = isLeaseWithdrawable(lease?.status);
  const isCountersignAvailable =
    lease?.status === LEASE_STATUS_CODES.applicantsSigned && !isLeaseLoading;
  const isOfflineLease =
    lease?.status === LEASE_STATUS_CODES.leaseHandledOffline ||
    lease?.status === LEASE_STATUS_CODES.leaseExecutedOffline;
  const isLeaseInProgress = lease?.status === LEASE_STATUS_CODES.inProgress;
  const isLeaseExecuted = lease?.status === LEASE_STATUS_CODES.managerSigned;
  const isProcessingSignature =
    lease?.status === LEASE_STATUS_CODES.processingManagerSignature;
  const isExecuted = lease?.status === LEASE_STATUS_CODES.leaseExecutedOffline;
  const isWaitingToSign =
    lease?.status === LEASE_STATUS_CODES.sent ||
    lease?.status === LEASE_STATUS_CODES.partiallySigned;

  const leaseStatusText = leaseStatuses?.find(
    (status) => status.id === lease?.status
  )?.value;

  const generatedAt =
    lease?.finalDocumentGeneratedAt &&
    dayjs(lease.finalDocumentGeneratedAt).format(DATE_TIME_FORMAT);
  let LeaseSummaryFooter = <></>;

  const backLink = {
    children: title,
    to: transformRoute(ROUTES.lease, { id: lease?.id }),
  };

  if (isOfflineLease) {
    LeaseSummaryFooter = (
      <LeaseOfflineFooter
        isESigningEnabled={isESigningEnabled}
        onModifyLease={onModifyLease}
        isLeaseModifying={isLeaseModifying}
        onSwitchESign={onSwitchESign}
        confirmExecution={confirmExecution}
        isExecuted={isExecuted}
      />
    );
  } else if (isLeaseInProgress) {
    LeaseSummaryFooter = (
      <LeaseInProgressFooter onFinishLease={onFinishLease} />
    );
  } else if (isWaitingToSign) {
    LeaseSummaryFooter = (
      <ResendFooter
        isWithdrawable={isWithdrawable}
        openWithdrawLeaseModal={openWithdrawLeaseModal}
        resendLease={resendLease}
      />
    );
  } else if (!isLeaseExecuted && !isProcessingSignature) {
    LeaseSummaryFooter = (
      <LeaseFooter
        isCountersignAvailable={isCountersignAvailable}
        countersign={countersign}
        isWithdrawable={isWithdrawable}
        openWithdrawLeaseModal={openWithdrawLeaseModal}
      />
    );
  }

  const LastGenerated = isOfflineLease && (
    <GeneratedByWrapper data-testid="lease-status">
      {/* @ts-ignore */}
      <GeneratedBy isExecuted={isExecuted}>
        {isExecuted ? "Uploaded: " : "Last downloaded: "}
        <strong>
          {`${generatedAt} by ${lease?.finalDocumentGeneratedBy}`}
        </strong>
      </GeneratedBy>
    </GeneratedByWrapper>
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isProcessingSignature && !isLeaseLoading) {
      const refresher = setInterval(() => {
        refetchLease();
      }, TIMEOUT);
      return () => clearInterval(refresher);
    }
  }, [isLeaseLoading, isProcessingSignature]);

  return (
    <Layout
      sidebar={sidebar}
      header={{
        ...header,
        backLink,
      }}
    >
      <Helmet>
        <title>Lease Summary</title>
      </Helmet>
      <Container expand noMobilePaddingX>
        <Container noPaddingTop noPaddingBottom>
          <BackLink.Container>
            <BackLink {...backLink} />
          </BackLink.Container>
          <PageTitle.Container center>
            <TitleWrapper>
              <PageTitle>{title}</PageTitle>
              {leaseStatusText && (
                <Tag color="green" size={Tag.SIZES.lg}>
                  {leaseStatusText}
                </Tag>
              )}
            </TitleWrapper>
            <Property
              horizontal
              name={propertyName}
              address={address}
              unit={unitName}
              fallbackContent="Application for company"
            />
          </PageTitle.Container>
        </Container>
        {isLeaseLoading && <LoadingSpinner data-testid="loading-spinner" />}
        {!isLeaseLoading && (
          <LeaseContainer>
            {isOfflineLease && (
              <LeaseHeader
                confirmExecution={confirmExecution}
                isExecuted={isExecuted}
              />
            )}
            <LeaseContent>
              <LeaseInfoContainer>
                {LastGenerated}
                <Tile.Inner noPaddingTop={Boolean(LastGenerated)}>
                  <LeaseInfo
                    lease={lease}
                    viewLease={viewLease}
                    isLeaseInProgress={isLeaseInProgress}
                  />
                </Tile.Inner>
              </LeaseInfoContainer>
              <ApplicantsInfo
                leaseMembers={lease?.deal?.applicantNames}
                applicantTypes={applicantTypes}
                data-testid="applicants-info"
              />
            </LeaseContent>
          </LeaseContainer>
        )}
      </Container>
      <Container noPaddingBottom noPaddingTop stickToBottom>
        {!isLeaseLoading && LeaseSummaryFooter}
      </Container>
    </Layout>
  );
};

export default LeaseSummary;
