import PropTypes from "prop-types";

const ConcessionPropTypes = {
  concessionMonths: PropTypes.shape({
    concessionStart: PropTypes.string,
    concessionEnd: PropTypes.string,
  }),
  isConcessionMonthsLoading: PropTypes.bool,
};

const ConcessionDefaultProps = {
  concessionMonths: {},
  isConcessionMonthsLoading: false,
};

export { ConcessionPropTypes, ConcessionDefaultProps };
