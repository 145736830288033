import styled from "@emotion/styled";
import { Divider, Typography } from "antd";

import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const { Text } = Typography;
const LoadingSpinner = styled(Icon.LoadingSpinnerGreenLargeIcon)({
  position: "absolute",
  left: "50%",
  bottom: "50%",
  transform: "translateX(-50%)",
});

const SaveLeaseProcessContainer = styled(ActionLink)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const SaveLeaseProcessIcon = styled(Icon.SaveIcon)({
  [MEDIA_QUERIES.w.gteSm]: { display: "none" },
});

const SaveLeaseProcessText = styled("span")({
  [MEDIA_QUERIES.w.ltSm]: { display: "none" },
});

const AddressContainer = styled("div")({
  display: "flex",
  alignItems: "baseline",
  [MEDIA_QUERIES.w.ltXl]: {
    flexDirection: "column",
  },
});

const Portfolio = styled("span")({
  fontSize: "12px",
  fontWeight: 300,
  color: COLORS.secondary,
  marginLeft: "12px",
  [MEDIA_QUERIES.w.ltXl]: {
    marginLeft: 0,
  },
});

const TileIcon = styled("div")({
  marginRight: "16px",
  [MEDIA_QUERIES.w.ltMd]: {
    alignSelf: "flex-start",
  },
});

const FlexTileContent = styled("div")({
  display: "flex",
  alignItems: "center",
});

const ApplicantTileContent = styled("div")({
  display: "flex",
  alignItems: "center",
  flexGrow: 1,
  [MEDIA_QUERIES.w.ltMd]: {
    display: "block",
  },
});

const Address = styled(Text)({
  color: COLORS.violetTheme.text,
  fontWeight: 300,
});

const RentDivider = styled(Divider)({
  [MEDIA_QUERIES.w.ltMd]: {
    display: "none",
  },
});

const NoApplicantTextContainer = styled("div")({
  color: COLORS.secondary,
});

const Image = styled.img`
  ${MEDIA_QUERIES.w.ltSm} {
    margin: 10px 0px;
  }
`;

export {
  AddressContainer,
  FlexTileContent,
  Address,
  ApplicantTileContent,
  Portfolio,
  RentDivider,
  TileIcon,
  LoadingSpinner,
  SaveLeaseProcessContainer,
  SaveLeaseProcessIcon,
  SaveLeaseProcessText,
  NoApplicantTextContainer,
  Image,
};
