import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";

import { useSetLeaseInProgress, useUpdateLeaseStep } from "manager/hooks/api";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";

import SwitchESignModal from "./SwitchESignModal";

const OPTIONS = {
  preview: "PREVIEW",
  modify: "MODIFY",
};

const SwitchESignModalContainer = () => {
  const [currentValue, setCurrentValue] = useState();
  const {
    currentModalContext: { leaseId, propertyName, propertyAddress, unitName },
  } = useModal();

  const { updateLeaseStep, isLoading: isLeaseMetadataUpdating } =
    useUpdateLeaseStep(leaseId);

  const { setLeaseInProgress, isLoading: isSettingInProgress } =
    useSetLeaseInProgress(leaseId);

  const history = useHistory();

  const onChange = (event) => setCurrentValue(event.target.value);

  const onConfirm = async () => {
    if (currentValue === OPTIONS.preview) {
      await setLeaseInProgress(leaseId);
      history.push(transformRoute(ROUTES.leaseBuilder, { id: leaseId }));
    } else if (currentValue === OPTIONS.modify) {
      await setLeaseInProgress(leaseId);
      await updateLeaseStep({
        id: leaseId,
        data: {
          step: 1,
        },
      });

      history.push(transformRoute(ROUTES.leaseBuilder, { id: leaseId }));
    }
  };

  return (
    <SwitchESignModal
      currentValue={currentValue}
      onChange={onChange}
      OPTIONS={OPTIONS}
      submitting={isLeaseMetadataUpdating || isSettingInProgress}
      onConfirm={onConfirm}
      propertyName={propertyName}
      propertyAddress={propertyAddress}
      unitName={unitName}
    />
  );
};

export default SwitchESignModalContainer;
