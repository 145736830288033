import { LOGIN_ERROR_CODES } from "shared/config/authConstants";

/**
 * Prefix error codes for error messages related to SSO.
 */
export const LOGIN_SSO_ERROR_CODES = [
  LOGIN_ERROR_CODES.accountDisabled,
  LOGIN_ERROR_CODES.userNotFound,
  LOGIN_ERROR_CODES.invitationMismatch,
];

export const LOGIN_SSO_ERROR_DESCRIPTIONS = Object.freeze({
  [LOGIN_ERROR_CODES.userNotFound]:
    "The email that you entered does not match our records. Please check the email address and try again.",
  [LOGIN_ERROR_CODES.invitationMismatch]:
    "The email used in your VERO invitation does not match our records. Please check the email address and try again.",
});
