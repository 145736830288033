import React from "react";

import { PreviewDocumentTemplateModal } from "manager/components/Leasing/PreviewLeaseModal";
import {
  EditDocumentRiderTypeModal,
  DocumentToPropertiesModal,
} from "manager/components/Modal";
import {
  useUpdateCompanyDocumentsOrdering,
  useResetOrderingForProperties,
} from "manager/hooks/api";
import useModal from "shared/hooks/useModal";

import DocumentTemplates from "./DocumentTemplates";

const DocumentTemplatesContainer = ({ ...props }) => {
  const { openModalDialog } = useModal();

  const openEditRiderTypeModal = (document) =>
    openModalDialog(EditDocumentRiderTypeModal, {
      document: {
        id: document.id,
        name: document.name,
        riderTypeName: document.riderTypeName,
      },
    });

  const openAssignToModal = (document) =>
    openModalDialog(DocumentToPropertiesModal, { document });

  const previewDocument = (document) =>
    openModalDialog(PreviewDocumentTemplateModal, {
      document: {
        name: document.name,
        id: document.id,
      },
    });

  const { updateDocumentOrdering, isLoading } =
    useUpdateCompanyDocumentsOrdering();

  const { resetOrderingForProperty, isLoadingReset } =
    useResetOrderingForProperties();

  return (
    <DocumentTemplates
      openAssignToModal={openAssignToModal}
      previewDocument={previewDocument}
      updateDocumentOrdering={updateDocumentOrdering}
      isOrderingLoading={isLoading}
      resetOrderingForProperty={resetOrderingForProperty}
      isLoadingReset={isLoadingReset}
      editRiderType={openEditRiderTypeModal}
      {...props}
    />
  );
};

export default DocumentTemplatesContainer;
