import React from "react";

import { Formik } from "formik";
import pluralize from "pluralize";
import * as Yup from "yup";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { RadioGroup, Radio } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import { RenewalOfferOption } from "shared/components/Renewals/RenewalOfferOption";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { RegularText, SmallText } from "shared/components/Typography";
import { conditionalItem } from "shared/utils/array";

import { LeaseRenewalPanelProps } from "./interfaces";
import { RenewalOfferTile } from "./styled";

const VALIDATION_SCHEMA = Yup.object().shape({
  selectedOption: Yup.number().required("An offer must be selected"),
});

const COL_SIZE_PROP = Object.freeze({
  xs: { span: 24 },
  sm: { span: 12 },
  lg: { span: 8 },
  xl: { span: 8 },
  xxl: { span: 6 },
});

export const LeaseRenewalPanel = ({
  selectedLeaseForRenewal,
  numOfferOptions,
  message,
  onSubmit,
  onMoveOut,
  isOptionDisabled,
  isChangeDisabled,
  initialValues,
}: LeaseRenewalPanelProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={VALIDATION_SCHEMA}
      validateOnMount
    >
      {({ submitForm, values, isValid, setFieldValue, isSubmitting }) => (
        <RenewalOfferTile
          header={{
            smallTitle: isChangeDisabled
              ? "RENEWAL OFFERS"
              : "RENEWAL OFFERS AVAILABLE",
          }}
        >
          <Tile.Inner>
            <FlexContainer>
              <div className="property-details">
                <FlexContainer alignItems="center">
                  <Icon.MyPropertyBoldIcon />
                  <RegularText strong>
                    {selectedLeaseForRenewal?.propertyName},{" "}
                    {selectedLeaseForRenewal?.unitName}
                  </RegularText>
                </FlexContainer>
                <SmallText light>
                  {selectedLeaseForRenewal?.propertyAddress}
                </SmallText>
              </div>
              {!isChangeDisabled && (
                <SmallText extraStrong className="available-offers-text">
                  {numOfferOptions} {pluralize("OFFER", numOfferOptions)}{" "}
                  AVAILABLE
                </SmallText>
              )}
            </FlexContainer>
            <Spacer />
            <SmallText className="select-offer-text">{message}</SmallText>
            <Spacer size={Spacer.SIZES.sm} />
            <RadioGroup
              id="selectedOption"
              name="selectedOption"
              options={
                selectedLeaseForRenewal?.renewalOffer?.renewalOptions || []
              }
              radioOptionRenderer={(option) => {
                const isDisabled = isOptionDisabled(option);
                const isDark =
                  values?.selectedOption &&
                  option.id !== values?.selectedOption;
                const className = [
                  "offer-option",
                  ...conditionalItem(isDark, "dark"),
                  ...conditionalItem(isDisabled, "disabled"),
                ].join(" ");
                const selectOption = () => {
                  if (!isDisabled) {
                    setFieldValue("selectedOption", option.id);
                  }
                };

                return (
                  <div
                    className={className}
                    role="radio"
                    tabIndex={0}
                    aria-checked={values?.selectedOption === option.id}
                    key={option.id}
                    onClick={selectOption}
                    onKeyPress={selectOption}
                  >
                    <Radio
                      key={option.id}
                      value={option.id}
                      disabled={isDisabled}
                    />
                    <RenewalOfferOption
                      className="offer-option-data-row"
                      option={option}
                      hideRentChange
                      hideActionsCol
                      colSizes={COL_SIZE_PROP}
                      ConcessionInfoIcon={Icon.InfoDark}
                    />
                  </div>
                );
              }}
            />
            <Spacer />
            <FlexContainer
              justifyContent="flex-end"
              alignItems="center"
              nodesMargin="0 0 0 24px"
            >
              <UnderlineLink onClick={onMoveOut} disabled={isChangeDisabled}>
                Not Renewing
              </UnderlineLink>
              <Button
                type="primary"
                onClick={submitForm}
                disabled={isChangeDisabled || !isValid}
                loading={isSubmitting}
              >
                RENEWAL LEASE
              </Button>
            </FlexContainer>
          </Tile.Inner>
        </RenewalOfferTile>
      )}
    </Formik>
  );
};
