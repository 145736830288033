import { SecurityDepositDeductionStatus } from "manager/interfaces/types";

export const getSecurityDepositBalanceMessage = (
  securityDepositDeductionStatus: SecurityDepositDeductionStatus
) => {
  let message: string;

  switch (securityDepositDeductionStatus) {
    case SecurityDepositDeductionStatus.HOLDING_DEPOSIT_DEDUCTED: {
      message =
        "The balance reflects the security deposit after holding deposits have been deducted.";
      break;
    }

    case SecurityDepositDeductionStatus.HOLDING_DEPOSIT_CANNOT_BE_DEDUCTED: {
      message =
        "The security deposit listed reflects the amount recorded in the property management software. " +
        "Therefore, the holding deposit is not deducted from this amount.";
      break;
    }

    default:
      message = "";
  }

  return message;
};

export const isSecurityDepositBalanceVisible = (
  leaseSecurityDepositStatus: SecurityDepositDeductionStatus
) =>
  [
    SecurityDepositDeductionStatus.HOLDING_DEPOSIT_DEDUCTED,
    SecurityDepositDeductionStatus.HOLDING_DEPOSIT_CANNOT_BE_DEDUCTED,
  ].includes(leaseSecurityDepositStatus);
