import React from "react";

import queryString from "query-string";

import ROUTES from "manager/config/routes";
import { useDismissCompany, useGetCompanies } from "manager/hooks/api";
import { useActiveCompany } from "shared/hooks";
import { useActiveUser } from "shared/hooks/api";
import { cacheActiveCompany, logout } from "shared/utils/auth";

import SelectCompany from "./SelectCompany";

const SelectCompanyContainer = () => {
  const { activeCompany } = useActiveCompany();
  const { companies = [], isGetCompaniesLoading } = useGetCompanies();
  const { activeUser } = useActiveUser();

  const activeCompanies = companies.filter((company) => !company.isDismissed);

  const { dismissCompany } = useDismissCompany();

  const redirect = () => {
    const urlParams = queryString.parse(window.location.search);
    const redirectPath = urlParams.r;

    if (redirectPath) {
      // @ts-ignore
      window.location.replace(redirectPath);
    } else {
      window.location.replace(ROUTES.dashboard);
    }
  };

  const selectCompany = (id: number, name: string) => {
    cacheActiveCompany({ id, name });
    redirect();
  };

  return (
    <SelectCompany
      user={activeUser}
      companies={activeCompanies}
      companySelected={!!activeCompany}
      loading={isGetCompaniesLoading}
      selectCompany={selectCompany}
      logout={logout}
      dismissCompany={dismissCompany}
      redirect={redirect}
    />
  );
};

export default SelectCompanyContainer;
