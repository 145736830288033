import React, { useRef, useEffect, useCallback, useState, Key } from "react";

import { Helmet } from "react-helmet";
import { useParams, useHistory } from "react-router-dom";

import Layout from "manager/components/Layout";
import ROUTES from "manager/config/routes";
import { useProcessManagerSignature, useGetLease } from "manager/hooks/api";
import { useManagerHelloSign } from "manager/hooks/useManagerHelloSign";
import { HeaderProps } from "shared/components/Header";
import PreventLeaveModal from "shared/components/PreventLeaveModal";
import { SidebarProps } from "shared/components/Sidebar";
import { LEASE_STATUS_CODES, NOTIFICATIONS } from "shared/config/constants";
import SignLeaseLayout from "shared/templates/SignLeaseLayout";
import { transformRoute } from "shared/utils/routing";
import { openNotification } from "shared/utils/ui";

interface LeaseCountersignContainerProps {
  sidebar: SidebarProps;
  header: HeaderProps;
}

const LeaseCountersignContainer = ({
  sidebar,
  header,
}: LeaseCountersignContainerProps) => {
  const history = useHistory();
  const helloSignContainer = useRef();
  const { id: leaseId } = useParams() as { id: Key };
  const [shouldPrevent, setPrevent] = useState(true);

  const { data: lease, isLoading: isLeaseLoading } = useGetLease(leaseId);

  const unit = lease?.deal?.unit;
  const property = unit?.property;

  const unitName = unit?.name;
  const propertyName = property?.name;
  const propertyAddress = property?.address;
  const propertyState = property?.state;
  const propertyZipcode = property?.zipcode;

  const fullAddress = `${propertyName}, ${unitName}, ${propertyAddress}, ${propertyState}, ${propertyZipcode}`;

  const { processManagerSignature } = useProcessManagerSignature({
    onSuccess: () =>
      history.push(transformRoute(ROUTES.leaseSummary, { id: leaseId })),
    leaseId,
  });

  const onSign = useCallback(
    (_data, helloSignClient) => {
      setPrevent(false);
      processManagerSignature(leaseId);
      helloSignClient.close();
      openNotification(
        `Congratulations! You've successfully signed the lease`,
        NOTIFICATIONS.info
      );
    },
    [leaseId]
  );

  const {
    signatureUrl,
    isSignatureUrlAvailable,
    isSignatureUrlLoading,
    openManagerHelloSign,
  } = useManagerHelloSign({
    leaseId,
    enableToFetch: Boolean(
      lease?.status === LEASE_STATUS_CODES.applicantsSigned
    ),
    onSign,
    onError: () => setPrevent(false),
  });

  useEffect(() => {
    if (isSignatureUrlAvailable && helloSignContainer?.current) {
      openManagerHelloSign(signatureUrl, helloSignContainer);
    }
  }, [isSignatureUrlAvailable, openManagerHelloSign, helloSignContainer]);

  return (
    <Layout
      sidebar={sidebar}
      header={{
        ...header,
        backLink: {
          children: `${unitName} LEASE`,
          to: transformRoute(ROUTES.leaseSummary, { id: leaseId }),
        },
      }}
    >
      <Helmet>
        <title>Countersign Lease</title>
      </Helmet>

      <SignLeaseLayout
        isLoading={isLeaseLoading || isSignatureUrlLoading}
        title="Countersign Lease"
        subtitle={fullAddress}
        helloSignContainer={helloSignContainer}
      />

      <PreventLeaveModal
        title="Exit countersigning process?"
        subtitle={`Unit: ${unitName}, ${propertyName}`}
        cancelText="Cancel"
        confirmText="Exit"
        preventLeaveWhen={shouldPrevent}
      />
    </Layout>
  );
};

export default LeaseCountersignContainer;
