import React from "react";

import {
  RenewalApplicationOfferStatus,
  RenewalApplicationStatus,
} from "manager/config/constants";
import { LeaseGroupApplication } from "manager/interfaces/api/leaseGroups";
import Icon from "shared/components/Icon";
import { LeaseApplicationStatus } from "shared/config/constants";

interface ResidentStatusDescription {
  title: string;
  Icon?: React.ReactNode;
  className?: string;
}

const getResidentStatus = (
  resident: LeaseGroupApplication
): ResidentStatusDescription => {
  if (resident?.renewalUiStatus) {
    switch (resident.renewalUiStatus) {
      case RenewalApplicationOfferStatus.OFFER_ACCEPTED: {
        return {
          title: "Accepted offer",
          Icon: <Icon.CheckGreenSmall />,
        };
      }

      case RenewalApplicationOfferStatus.OFFER_REJECTED:
      case RenewalApplicationStatus.NOT_RENEWING: {
        return {
          title: "Not renewing",
          className: "reject-status",
        };
      }
      case RenewalApplicationStatus.REMOVED: {
        return {
          title: "Removed",
          className: "reject-status",
        };
      }

      case LeaseApplicationStatus.signed: {
        return {
          title: "Signed renewal",
          Icon: <Icon.SignedDocumentIcon />,
        };
      }

      default:
        return null;
    }
  } else if (resident?.status) {
    switch (resident?.status) {
      case LeaseApplicationStatus.signed: {
        return {
          title: "Signed lease",
          Icon: <Icon.SignedDocumentIcon />,
        };
      }
      default:
        return null;
    }
  }

  return null;
};

export { getResidentStatus };
