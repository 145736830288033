import React, { useEffect, useMemo } from "react";

import { useRenewalActions } from "manager/components/Renewals";
import { RenewalCategory } from "manager/config/constants";
import { useGetRenewals, useGetPropertiesWithLease } from "manager/hooks/api";
import { useDataTable, useCheckMultiValueFilterValidity } from "shared/hooks";
import { useRenewalStatuses } from "shared/hooks/api";
import { TableState } from "shared/interfaces/dataTable";
import { SelectItem } from "shared/interfaces/misc";

import { blankFilters, ORDER_BY, RENEWAL_CATEGORIES_LIST } from "./constants";
import { RenewalsFilters, RenewalsOrderMap } from "./interfaces";
import Renewals from "./Renewals";

const RENEWALS_FILTERS_STORAGE = "RENEWALS_FILTERS_STORAGE";

const combineFilters = ({
  filters,
  pagination,
  ordering,
}: TableState<RenewalsFilters>) => {
  const { propertyId, renewalStatus, renewalCategory, ...otherFilters } =
    filters;

  const renewalStatusFilter = (renewalStatus || []).join(",");
  const propertyIdFilter = (propertyId || []).join(",");
  const categoryFilter = RENEWAL_CATEGORIES_LIST.find(
    (cat) => cat.key === renewalCategory
  )?.apiKey as RenewalCategory;

  return {
    ...otherFilters,
    ...pagination,
    renewalStatus: renewalStatusFilter,
    renewalCategory: categoryFilter,
    propertyId: propertyIdFilter,
    ordering,
  };
};

export const RenewalsContainer = () => {
  const { initialOrderingColumn, tableState, onTableChange, updateFilters } =
    useDataTable<RenewalsFilters, RenewalsOrderMap>({
      storageKey: RENEWALS_FILTERS_STORAGE,
      orderByMap: ORDER_BY,
      blankFilters,
      defaultOrdering: ORDER_BY.leaseExpirationDate.descend,
    });

  const renewalsFilters = useMemo(
    () => combineFilters(tableState),
    [tableState]
  );

  const { renewalStatuses = [], isRenewalStatusesLoading } =
    useRenewalStatuses();

  const { properties = [], isPropertiesLoading } = useGetPropertiesWithLease();

  const isPropertiesFilterValid = useCheckMultiValueFilterValidity<
    number,
    { id: number; name: string }
  >({
    multiValueFilter: tableState?.filters?.propertyId,
    items: properties,
  });

  const { renewals, isRenewalsLoading } = useGetRenewals(renewalsFilters, {
    enabled: properties?.length > 0 && isPropertiesFilterValid,
  });

  const { renewalActions } = useRenewalActions();

  const clearFilters = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { renewalCategory, ...filtersToReset } = blankFilters;
    updateFilters(filtersToReset);
  };

  const isLoading =
    isRenewalStatusesLoading ||
    isRenewalsLoading ||
    isPropertiesLoading ||
    !isPropertiesFilterValid;

  const exportRenewals = () => {
    // TODO igeshosk (renewals): implement (not needed for V1)
    // eslint-disable-next-line no-console
    console.log("Export renewals");
  };

  useEffect(() => {
    if (!isPropertiesFilterValid) {
      updateFilters({ propertyId: [] });
    }
  }, [isPropertiesFilterValid]);

  const propertyOptions = useMemo<SelectItem[]>(
    () =>
      properties.map(({ id, name }) => ({
        label: name,
        key: `${id}`,
      })),
    [properties]
  );

  const renewalStatusOptions = useMemo<SelectItem[]>(
    () =>
      renewalStatuses.map(({ id, value }) => ({
        label: value,
        key: `${id}`,
      })),
    [renewalStatuses]
  );

  return (
    <Renewals
      propertyOptions={propertyOptions}
      renewalStatusOptions={renewalStatusOptions}
      renewals={renewals?.results}
      renewalsCount={renewals?.count}
      filters={tableState.filters}
      pagination={tableState.pagination}
      initialOrderingColumn={initialOrderingColumn}
      hasInputValue={Boolean(tableState.filters.unitPropertyOrApplicant)}
      loading={isLoading}
      updateFilters={updateFilters}
      clearFilters={clearFilters}
      onTableChange={onTableChange}
      exportRenewals={exportRenewals}
      actions={renewalActions}
    />
  );
};
