import React from "react";

import range from "lodash/range";

import { BaseFeeConfiguration } from "manager/interfaces/api/leaseFeeConfiguration";
import { Select4Option } from "shared/components/Form/Select4";

const MAX_QUANTITY = 100;

const generateQuantityOptions = () =>
  range(MAX_QUANTITY).map((i) => {
    const num = i + 1;
    return (
      <Select4Option key={num} value={num}>
        {num}
      </Select4Option>
    );
  });

const getChargeFieldName = (name: keyof BaseFeeConfiguration, i: number) =>
  `feeConfiguration.charges[${i}].${name}`;

const getRentableFieldName = (name: keyof BaseFeeConfiguration, i: number) =>
  `feeConfiguration.rentableItems[${i}].${name}`;

export { generateQuantityOptions, getChargeFieldName, getRentableFieldName };
