import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import Dashboard1 from "shared/images/triangle-diagonal-white.svg";

interface ContainerProps {
  isExecuted?: boolean;
}

const Container = styled(FlexContainer)<ContainerProps>`
  background: ${COLORS.lightGray1};
  position: relative;
  background-image: url(${Dashboard1});
  background-repeat: no-repeat;
  background-size: contain;
  padding: 22px;
  margin-bottom: 16px;
  background-position: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isExecuted }) =>
    isExecuted &&
    `
      background: ${COLORS.lightViolet1};
      justify-content: end;
  `};

  ${MEDIA_QUERIES.w.ltSm} {
    margin-top: 16px;
    padding: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    background-size: auto;
    background-position: 120% 150%;
    ${({ isExecuted }) => isExecuted && `align-items: center`}
  }
`;

interface TitleProps {
  isExecuted?: boolean;
}

const Title = styled.p<TitleProps>`
  max-width: 900px;
  font-size: 17px;
  font-weight: 500;
  margin-right: 75px;
  margin-bottom: 0 !important;
  ${({ isExecuted }) =>
    isExecuted &&
    `
      color: white;
      margin-right: 0;
  `};

  ${MEDIA_QUERIES.w.ltSm} {
    margin-right: 0;
    margin-bottom: 10px !important;
  }
`;

const IconWrapper = styled.div`
  height: 30px;
  width: 30px;
  margin-right: 10px;
`;

export { Container, Title, IconWrapper };
