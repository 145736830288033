const applicantPrefix = "/applicant";
const applicationPrefix = `${applicantPrefix}/applications/:applicationId`;
const TEMP_MAGIC_LINKS_BASE = "/t-magic-link";
const MAGIC_LINKS_BASE = "/magic-link";
const MAGIC_LINKS_BASE_V2 = "/magic-link/v2";
const MAGIC_PORTAL_BASE = "/magic-portal";
const LEASES_BASE = `${applicantPrefix}/leases/`;
const LEASE_APPLICATION_BASE = `${LEASES_BASE}:leaseApplicationId/`;

const LEASE_ROUTES = {
  leases: LEASES_BASE,
  leaseApplication: LEASE_APPLICATION_BASE,
  applicantSignUrl: `${LEASE_APPLICATION_BASE}get-signature/`,
  processSignature: `${LEASE_APPLICATION_BASE}processing-signature/`,
  viewFinalLease: `${LEASE_APPLICATION_BASE}view-final-document/`,
  trackDownloadStatistic: `${LEASE_APPLICATION_BASE}download/`,
  acceptOffer: `${LEASE_APPLICATION_BASE}accept-offer/:optionId/`,
  rejectOffer: `${LEASE_APPLICATION_BASE}reject-offer/`,
};

const MAGIC_LINKS_ROUTES = {
  tempMagicLinksCompany: `${TEMP_MAGIC_LINKS_BASE}/:companyHashId/`,
  magicLinkProperties: `${TEMP_MAGIC_LINKS_BASE}/:companyHashId/properties/`,
  magicLinkDeal: `${MAGIC_LINKS_BASE}/deal/:companyHashId/`,
  magicLinkLogo: "/logo/",
  magicLinkV2Deal: `${MAGIC_LINKS_BASE_V2}/deal/`,
  magicLinkV2Details: `${MAGIC_LINKS_BASE_V2}/details/`,
};

const MAGIC_PORTAL_ROUTES = {
  getMagicPortalCompany: `${MAGIC_PORTAL_BASE}/:companyHashId/`,
  getMagicPortalProperties: `${MAGIC_PORTAL_BASE}/:companyHashId/properties/`,
  getMagicPortalProperty: `${MAGIC_PORTAL_BASE}/pr/:propertyHashId/`,
  getMagicPortalPropertyUnit: `${MAGIC_PORTAL_BASE}/unit/:unitHashId/`,
  getLroConfig: `${MAGIC_PORTAL_BASE}/config/lro/`,
  getMagicPortalPropertyUnits: `${MAGIC_PORTAL_BASE}/pr/:propertyHashId/units/`,
  getMagicPortalPropertyUnitListings: `${MAGIC_PORTAL_BASE}/unit/:unitHashId/listings/`,
  getMagicPortalUnitLro: `${MAGIC_PORTAL_BASE}/unit/:unitHashId/lro/`,
};

const BRANDING_ROUTES = {
  applicationLogo: `${applicantPrefix}/application/:applicationId/logo/`,
};

const APPLICATION_ROUTES = {
  applicationInvitation: `${applicationPrefix}/`,
  confirmApplicantGroup: `${applicationPrefix}/confirm-group/`,
  applicationPropertyDetails: `${applicationPrefix}/property-details/`,
  fillRenterProfile: `${applicationPrefix}/fill-profile/`,
  getRenterProfile: `${applicationPrefix}/get-profile/`,
  addApplicationAttachment: `${applicationPrefix}/add-attachment/`,
  deleteApplicationAttachments: `${applicationPrefix}/attachments/delete/`,
  skipApplicationAttachment: `${applicationPrefix}/attachments/skip/`,
  submitApplication: `${applicationPrefix}/submit/`,
  conditionalRequest: `${applicationPrefix}/conditional-request/`,
  getActiveUserApplicantTypes: `${applicationPrefix}/get-type/`,
  setApplicantType: `${applicationPrefix}/set-type/`,
  getApplicationSummary: `${applicationPrefix}/full/`,
  markPaymentCompleted: `${applicationPrefix}/mark-payment-completed/`,
};

const LINK_EMPLOYMENT_ROUTES = {
  employmentData: `${applicationPrefix}/employment-data/`,
  postArgyleEvent: `${applicationPrefix}/argyle/`,
  argyleStartSync: `${applicationPrefix}/argyle-sync/`,
  argyleSyncStatus: `${applicationPrefix}/argyle-status/`,
  completeLinkEmploymentStep: `${applicationPrefix}/complete-link-employment/`,
  argyleSetupData: `${applicantPrefix}/argyle-setup-data/`,
};

const APPLICATION_NOTES = {
  setApplicationNotes: `${applicantPrefix}/applications/:applicationId/set-notes/`,
};

const IDENTITY_VERIFICATION_ROUTES = {
  personaData: `${applicationPrefix}/persona-status/`,
  personaTokens: `${applicationPrefix}/persona-tokens/`,
  setPersonaInquiryId: `${applicationPrefix}/set-persona-inquiry-id/`,
  personaSetSkipped: `${applicationPrefix}/persona-set-skipped/`,
  personaSync: `${applicationPrefix}/persona-sync/`,
};

const DEAL_ROUTES = {
  applicantDeal: `${applicantPrefix}/deals/:id/`,
};

const RENTER_API_ROUTES = {
  ...APPLICATION_ROUTES,
  ...LEASE_ROUTES,
  ...MAGIC_LINKS_ROUTES,
  ...BRANDING_ROUTES,
  ...APPLICATION_NOTES,
  ...MAGIC_PORTAL_ROUTES,
  ...LINK_EMPLOYMENT_ROUTES,
  ...IDENTITY_VERIFICATION_ROUTES,
  ...DEAL_ROUTES,
};

export default RENTER_API_ROUTES;
