import React from "react";

import { Radio } from "antd";

import { MODALS } from "manager/config/constants";
import List from "shared/components/List";
import { Modal } from "shared/components/Modal";

import { EditDocumentRiderTypeModalProps } from "./interfaces";
import { ListItem, Title, RadioOption } from "./styled";

const EditDocumentRiderTypeModal = ({
  documentName,
  documentRiderTypeName,
  riderTypes = [],
  isRiderTypesLoading,
  currentRiderType,
  onRiderTypeChange,
  onConfirm,
  isConfirming,
}: EditDocumentRiderTypeModalProps) => (
  <Modal
    title="Change rider type"
    submitButtonLabel="Confirm"
    id={MODALS.editDocumentRiderType}
    submit={onConfirm}
    submitting={isConfirming}
    submitButtonDisabled={!currentRiderType}
  >
    <Modal.Body>
      <Radio.Group
        onChange={onRiderTypeChange}
        value={currentRiderType}
        // @ts-ignore
        loading={isRiderTypesLoading}
      >
        <List>
          <List.Item>
            <Title>{`Select type for rider ${documentName}`} </Title>
          </List.Item>
          {riderTypes.map(({ id, value }) => (
            <ListItem selected={currentRiderType === value} key={id}>
              <RadioOption
                value={id}
                disabled={value === documentRiderTypeName}
              >
                {value}
              </RadioOption>
            </ListItem>
          ))}
        </List>
      </Radio.Group>
    </Modal.Body>
  </Modal>
);

export default EditDocumentRiderTypeModal;
