import React, { useState } from "react";

import {
  getRenewalHelpers,
  RenewalActionFn,
  RenewalActions,
} from "manager/components/Renewals";
import {
  ACTION_NOT_PERMITTED_MESSAGE,
  RenewalStatus,
} from "manager/config/constants";
import { RenewalBasic } from "manager/interfaces/api/renewals";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import { LEASE_STATUS_CODES, BREAKPOINT_TYPES } from "shared/config/constants";
import { usePermissions } from "shared/hooks";
import useWindowSize from "shared/hooks/useWindowSize";
import { conditionalItem } from "shared/utils/array";
import { stringify } from "shared/utils/string";
import { replaceNegativeWithLeftoverSpace } from "shared/utils/ui";

import { StyledProgressBar } from "./styled";

const getColumnWidths = (tableWidth, breakpoint) => {
  const checkboxWidth = 64;
  const dropdownWidth = 61;
  const expandButtonWidth = 69;
  const expirationDateWidth = 170;
  const rentWidth = 170;
  const renewalStatusWidth = 260;
  // Columns: unit, residents, leaseExpirationDate, rent, status, dropdown ellipsis, expand button, checkbox
  let columnWidths = [];
  if (tableWidth) {
    if (BREAKPOINT_TYPES.xs === breakpoint) {
      columnWidths = [];
    } else if (BREAKPOINT_TYPES.sm === breakpoint) {
      columnWidths = [
        -1,
        undefined,
        undefined,
        undefined,
        renewalStatusWidth,
        dropdownWidth,
        expandButtonWidth,
        checkboxWidth,
      ];
    } else if (BREAKPOINT_TYPES.md === breakpoint) {
      columnWidths = [
        100,
        undefined,
        80,
        undefined,
        160,
        dropdownWidth,
        expandButtonWidth,
        checkboxWidth,
      ];
    } else if (BREAKPOINT_TYPES.lg === breakpoint) {
      columnWidths = [
        -1,
        -1,
        170,
        undefined,
        renewalStatusWidth,
        dropdownWidth,
        expandButtonWidth,
        checkboxWidth,
      ];
    } else if (BREAKPOINT_TYPES.xl === breakpoint) {
      columnWidths = [
        -1,
        -1,
        expirationDateWidth,
        rentWidth,
        renewalStatusWidth,
        dropdownWidth,
        expandButtonWidth,
        checkboxWidth,
      ];
    } else if (BREAKPOINT_TYPES.xxl === breakpoint) {
      columnWidths = [
        -1,
        -1,
        expirationDateWidth,
        rentWidth,
        renewalStatusWidth,
        dropdownWidth,
        expandButtonWidth,
        checkboxWidth,
      ];
    } else if (BREAKPOINT_TYPES.xxxl === breakpoint) {
      columnWidths = [
        -1,
        -1,
        expirationDateWidth,
        rentWidth,
        renewalStatusWidth,
        dropdownWidth,
        expandButtonWidth,
        checkboxWidth,
      ];
    }
  }

  return replaceNegativeWithLeftoverSpace(columnWidths, tableWidth);
};

const RenewalStatusActionProgressBar = ({
  renewal,
  label,
  action,
  disabledActionReason,
}: {
  renewal: RenewalBasic;
  label: string;
  action: RenewalActionFn;
  disabledActionReason?: string | boolean;
}) => {
  return (
    <>
      <Tooltip title={disabledActionReason} trigger="hover">
        <div>
          <UnderlineLink
            onClick={() => action(renewal)}
            disabled={Boolean(disabledActionReason)}
          >
            {label}
          </UnderlineLink>
        </div>
      </Tooltip>
      <StyledProgressBar progress={renewal.percentComplete} />
    </>
  );
};

const RenewalFinalStatus = ({
  label,
  isRejected = false,
}: {
  label: string;
  isRejected?: boolean;
}) => {
  const StatusIcon = isRejected ? Icon.ErrorXwarning : Icon.CheckGreenBig;
  return (
    <FlexContainer alignItems="center">
      <StatusIcon className="final-status-icon" />
      <RegularText>{label}</RegularText>
    </FlexContainer>
  );
};

const useRenewalsTable = (
  renewals: RenewalBasic[],
  tableWidth: number,
  breakpoint: BREAKPOINT_TYPES,
  actions: RenewalActions
) => {
  const permissions = usePermissions();

  // NOTE: Listen to the window-size changes to react properly when calculating the columns width
  useWindowSize();
  const [checkboxActions, setCheckboxActions] = useState([]);
  const columnWidths = getColumnWidths(tableWidth, breakpoint);

  const onRowSelection = (keys: string[]) => {
    let newCheckboxActions = [];
    const selectedKeysSet = new Set(keys.map((k) => stringify(k)));
    const selectedRenewalsStatuses = new Set<RenewalStatus>();
    const selectedRenewals = renewals.filter((r) => {
      const isSelected = selectedKeysSet.has(stringify(r.id));
      if (isSelected) {
        selectedRenewalsStatuses.add(r.status);
      }
      return isSelected;
    });

    // NOTE: all selected renewals must have the same status
    if (keys?.length > 1 && selectedRenewalsStatuses.size === 1) {
      const [status] = Array.from(selectedRenewalsStatuses);
      if (status === RenewalStatus.OFFER_CREATED) {
        newCheckboxActions = [
          ...conditionalItem(permissions.canApproveRenewalsOffers, {
            key: "APPROVE_OFFERS",
            label: "Approve Offers",
            onSelect: () => {
              actions.approveOffers(selectedRenewals);
            },
          }),
        ];
      } else if (status === RenewalStatus.OFFER_APPROVED) {
        newCheckboxActions = [
          ...conditionalItem(permissions.canCreateRenewalsOffers, {
            key: "SEND_OFFERS",
            label: "Send Offers",
            onSelect: () => {
              actions.sendOffers(selectedRenewals);
            },
          }),
        ];
      }
    }

    setCheckboxActions(newCheckboxActions);
  };

  const getRenewalsStatus = (renewal: RenewalBasic) => {
    let status: React.ReactNode = "";
    const helpers = getRenewalHelpers(renewal, permissions);

    if (RenewalStatus.OFFER_NOT_CREATED === renewal.status) {
      if (helpers.offerHelpers.canCreateOffer) {
        status = (
          <RenewalStatusActionProgressBar
            renewal={renewal}
            label="CREATE OFFER"
            action={actions.createOffer}
          />
        );
      } else {
        status = (
          <RenewalStatusActionProgressBar
            renewal={renewal}
            label="REQUEST OFFER"
            action={actions.requestOffer}
            disabledActionReason={
              !helpers.offerHelpers.canRequestOffer &&
              ACTION_NOT_PERMITTED_MESSAGE
            }
          />
        );
      }
    } else if (RenewalStatus.OFFER_CREATION_IN_PROGRESS === renewal.status) {
      status = (
        <RenewalStatusActionProgressBar
          renewal={renewal}
          label="FINISH CREATING OFFER"
          action={actions.finishCreatingOffer}
        />
      );
    } else if (RenewalStatus.OFFER_CREATED === renewal.status) {
      if (helpers.offerHelpers.canApproveOffer) {
        status = (
          <RenewalStatusActionProgressBar
            renewal={renewal}
            label="APPROVE OFFER"
            action={actions.approveOffer}
          />
        );
      } else {
        status = (
          <RenewalStatusActionProgressBar
            renewal={renewal}
            label="REQUEST APPROVAL"
            action={actions.requestOfferApproval}
            disabledActionReason={
              !helpers.offerHelpers.canRequestApproveOffer &&
              ACTION_NOT_PERMITTED_MESSAGE
            }
          />
        );
      }
    } else if (RenewalStatus.OFFER_APPROVED === renewal.status) {
      status = (
        <RenewalStatusActionProgressBar
          renewal={renewal}
          label="SEND OFFER"
          action={actions.sendOffer}
          disabledActionReason={
            !helpers.offerHelpers.canSendOffers && ACTION_NOT_PERMITTED_MESSAGE
          }
        />
      );
    } else if (RenewalStatus.OFFER_SENT === renewal.status) {
      status = (
        <RenewalStatusActionProgressBar
          renewal={renewal}
          label="RESEND OFFER"
          action={actions.sendOfferReminder}
          disabledActionReason={
            !helpers.offerHelpers.canSendOffers && ACTION_NOT_PERMITTED_MESSAGE
          }
        />
      );
    } else if (RenewalStatus.OFFER_ACCEPTED === renewal.status) {
      if (helpers.renewalHelpers.canCreateRenewal) {
        status = (
          <RenewalStatusActionProgressBar
            renewal={renewal}
            label="CREATE RENEWAL"
            action={actions.createRenewal}
          />
        );
      } else {
        status = (
          <RenewalStatusActionProgressBar
            renewal={renewal}
            label="REQUEST CREATION"
            action={actions.requestRenewalCreation}
            disabledActionReason={
              !helpers.renewalHelpers.canRequestRenewalCreation &&
              ACTION_NOT_PERMITTED_MESSAGE
            }
          />
        );
      }
    } else if (RenewalStatus.OFFER_REJECTED === renewal.status) {
      status = <RenewalFinalStatus label="Not Renewing" isRejected />;
    } else if (RenewalStatus.RENEWAL_CREATION_IN_PROGRESS === renewal.status) {
      status = (
        <RenewalStatusActionProgressBar
          renewal={renewal}
          label="FINISH CREATING RENEWAL"
          action={actions.finishCreatingRenewal}
        />
      );
      if (
        [LEASE_STATUS_CODES.sent, LEASE_STATUS_CODES.partiallySigned].includes(
          renewal.newLease?.status
        )
      ) {
        status = (
          <RenewalStatusActionProgressBar
            renewal={renewal}
            label="SEND REMINDER"
            action={actions.sendLeaseSignReminder}
          />
        );
      } else if (
        LEASE_STATUS_CODES.applicantsSigned === renewal.newLease?.status
      ) {
        status = (
          <RenewalStatusActionProgressBar
            renewal={renewal}
            label="COUNTERSIGN"
            action={actions.countersign}
          />
        );
      }
    } else if (RenewalStatus.RENEWAL_COMPLETED === renewal.status) {
      if (
        LEASE_STATUS_CODES.leaseExecutedOffline === renewal.newLease?.status
      ) {
        status = <RenewalFinalStatus label="Renewal executed offline" />;
      } else {
        status = <RenewalFinalStatus label="Renewal executed" />;
      }
    } else if (RenewalStatus.RENEWAL_NOT_COMPLETED === renewal.status) {
      status = <RenewalFinalStatus label="Not completed" isRejected />;
    }
    return status;
  };

  const getRenewalDropdownItems = (renewal: RenewalBasic) => {
    const helpers = getRenewalHelpers(renewal, permissions);
    return [
      {
        key: "CONTACT_RENTERS",
        label: "Contact renters",
        onClick: () => actions.contactRenters(renewal),
      },
      ...conditionalItem(helpers.offerHelpers.isWithdrawOfferEnabled, {
        key: "WITHDRAW_OFFER",
        label: "Withdraw offer",
        onClick: () => actions.withdrawOffer(renewal),
        disabled: !helpers.offerHelpers.canWithdrawOffer,
      }),
    ];
  };

  return {
    columnWidths,
    checkboxActions,
    onRowSelection,
    getRenewalsStatus,
    getRenewalDropdownItems,
  };
};

export { useRenewalsTable };
