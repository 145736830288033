import React from "react";

import { useHistory } from "react-router-dom";

import {
  useRenewalActions,
  useRenewalHelpers,
} from "manager/components/Renewals";
import {
  ACTION_NOT_PERMITTED_MESSAGE,
  RenewalStatus,
} from "manager/config/constants";
import ROUTES from "manager/config/routes";
import { LeaseGroup } from "manager/interfaces/api/leaseGroups";
import { Button } from "shared/components/Button";
import FooterBar from "shared/components/FooterBar";
import { UnderlineLink } from "shared/components/Links";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import { LEASE_STATUS_CODES } from "shared/config/constants";
import usePermissions from "shared/hooks/usePermissions";
import { transformRoute } from "shared/utils/routing";

const useGetFooterActions = ({
  leaseGroup,
  navigateToLeaseSummary,
}: {
  leaseGroup: LeaseGroup;
  navigateToLeaseSummary: () => void;
}) => {
  const { push } = useHistory();
  const { renewal } = leaseGroup || {};
  const { renewalActions } = useRenewalActions();
  const { offerHelpers, renewalHelpers } = useRenewalHelpers({ renewal });
  let footer: React.ReactNode = null;

  const cancelRenewalLink = (
    <Tooltip
      title={!renewalHelpers.canCancelRenewal && ACTION_NOT_PERMITTED_MESSAGE}
    >
      <div>
        <UnderlineLink
          onClick={() => renewalActions.cancelRenewal(renewal)}
          disabled={!renewalHelpers.canCancelRenewal}
        >
          Cancel renewal
        </UnderlineLink>
      </div>
    </Tooltip>
  );

  switch (leaseGroup?.renewalUiStatus) {
    case RenewalStatus.OFFER_NOT_CREATED: {
      footer = (
        <>
          {cancelRenewalLink}
          {offerHelpers.canCreateOffer ? (
            <Button
              type="primary"
              onClick={() => renewalActions.createOffer(renewal)}
            >
              Create offer
            </Button>
          ) : (
            <Tooltip
              title={
                !offerHelpers.canRequestOffer && ACTION_NOT_PERMITTED_MESSAGE
              }
            >
              <div>
                <Button
                  type="primary"
                  onClick={() => renewalActions.requestOffer(renewal)}
                  disabled={!offerHelpers.canRequestOffer}
                >
                  Request offer
                </Button>
              </div>
            </Tooltip>
          )}
        </>
      );
      break;
    }
    case RenewalStatus.OFFER_CREATION_IN_PROGRESS: {
      footer = offerHelpers.canCreateOffer ? (
        <>
          {cancelRenewalLink}
          <Button
            type="primary"
            onClick={() => renewalActions.finishCreatingOffer(renewal)}
          >
            Finish creating offer
          </Button>
        </>
      ) : (
        <>
          <RegularText className="footer-note">
            Please wait for the renewal offer to be created.
          </RegularText>
          {cancelRenewalLink}
        </>
      );
      break;
    }
    case RenewalStatus.OFFER_CREATED: {
      footer = offerHelpers.canApproveOffer ? (
        <>
          {cancelRenewalLink}
          <Button
            type="primary"
            onClick={() => renewalActions.approveOffer(renewal)}
          >
            Approve offer
          </Button>
        </>
      ) : (
        <>
          <RegularText className="footer-note">
            Please wait for the renewal offer to be approved.
          </RegularText>
          {cancelRenewalLink}
          <Tooltip
            title={
              !offerHelpers.canRequestApproveOffer &&
              ACTION_NOT_PERMITTED_MESSAGE
            }
          >
            <div>
              <Button
                type="primary"
                onClick={() => renewalActions.requestOfferApproval(renewal)}
                disabled={!offerHelpers.canRequestApproveOffer}
              >
                Request approval
              </Button>
            </div>
          </Tooltip>
        </>
      );
      break;
    }
    case RenewalStatus.OFFER_APPROVED: {
      footer = offerHelpers.canSendOffers ? (
        <>
          {cancelRenewalLink}
          <Button
            type="primary"
            onClick={() => renewalActions.sendOffer(renewal)}
          >
            Send offers
          </Button>
        </>
      ) : (
        <>
          <RegularText className="footer-note">
            Please wait for the renewal offer to be sent.
          </RegularText>
          {cancelRenewalLink}
        </>
      );
      break;
    }
    case RenewalStatus.OFFER_SENT:
    case RenewalStatus.OFFER_PARTIALLY_ACCEPTED:
    case RenewalStatus.OFFER_PARTIALLY_REJECTED: {
      footer = offerHelpers.canSendOffers ? (
        <>
          {cancelRenewalLink}
          <Button
            type="primary"
            onClick={() => renewalActions.sendOfferReminder(renewal)}
          >
            Resend offer reminder
          </Button>
        </>
      ) : (
        cancelRenewalLink
      );
      break;
    }
    case RenewalStatus.OFFER_ACCEPTED: {
      footer = renewalHelpers.canCreateRenewal ? (
        <>
          {cancelRenewalLink}
          <Button
            type="primary"
            onClick={() => renewalActions.createRenewal(renewal)}
          >
            Create renewal
          </Button>
        </>
      ) : (
        <>
          {cancelRenewalLink}
          <Tooltip
            title={
              !renewalHelpers.canRequestRenewalCreation &&
              ACTION_NOT_PERMITTED_MESSAGE
            }
          >
            <div>
              <Button
                type="primary"
                onClick={() => renewalActions.requestRenewalCreation(renewal)}
                disabled={!renewalHelpers.canRequestRenewalCreation}
              >
                Request renewal creation
              </Button>
            </div>
          </Tooltip>
        </>
      );
      break;
    }

    case RenewalStatus.RENEWAL_CREATION_IN_PROGRESS:
    case LEASE_STATUS_CODES.inProgress: {
      const finishRenewal = () => {
        if (leaseGroup?.isRenewalLeaseCreated) {
          navigateToLeaseSummary();
        } else if (leaseGroup?.newDealId) {
          push(transformRoute(ROUTES.deal, { id: leaseGroup?.newDealId }));
        }
      };

      footer = (
        <>
          {cancelRenewalLink}
          <Button type="primary" onClick={finishRenewal}>
            Finish creating renewal
          </Button>
        </>
      );

      break;
    }
    case LEASE_STATUS_CODES.sent:
    case LEASE_STATUS_CODES.partiallySigned: {
      footer = (
        <>
          {cancelRenewalLink}
          <Button
            type="primary"
            onClick={() => renewalActions.sendLeaseSignReminder(renewal)}
          >
            Send reminder
          </Button>
        </>
      );
      break;
    }
    case LEASE_STATUS_CODES.applicantsSigned: {
      footer = (
        <>
          {cancelRenewalLink}
          <Button
            type="primary"
            onClick={() => renewalActions.countersign(renewal)}
          >
            Countersign
          </Button>
        </>
      );
      break;
    }
    case LEASE_STATUS_CODES.leaseHandledOffline: {
      footer = cancelRenewalLink;
      break;
    }

    default:
      /** Note: No footer statuses
       *  LEASE_STATUS_CODES.processingManagerSignature
       *  LEASE_STATUS_CODES.leaseExecutedOffline
       *  RenewalStatus.RENEWAL_NOT_COMPLETED
       *  RenewalStatus.RENEWAL_COMPLETED
       */
      footer = null;
  }

  return footer;
};

const LeaseGroupFooter = ({ leaseGroup }: { leaseGroup: LeaseGroup }) => {
  const { push } = useHistory();
  const permissions = usePermissions();

  const navigateToLeaseSummary = () =>
    push(
      transformRoute(ROUTES.leaseSummary, {
        id: leaseGroup.activeLease?.id,
      })
    );

  const renewalFooter = useGetFooterActions({
    leaseGroup,
    navigateToLeaseSummary,
  });

  let footer: React.ReactNode;

  if (permissions.areRenewalsEnabled && leaseGroup?.isActiveLeaseExpiring) {
    footer = renewalFooter;
  } else {
    footer = (
      <Button type="primary" onClick={navigateToLeaseSummary}>
        Go to lease
      </Button>
    );
  }

  return (
    footer && (
      <>
        <FooterBar.Spacer />
        <FooterBar className="lease-group-footer">{footer}</FooterBar>
      </>
    )
  );
};

export default LeaseGroupFooter;
