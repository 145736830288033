import React from "react";

import pluralize from "pluralize";

import { RenewalStatus } from "manager/config/constants";
import { LeaseGroup } from "manager/interfaces/api/leaseGroups";
import Icon from "shared/components/Icon";
import { LEASE_STATUS_CODES } from "shared/config/constants";
import { conditionalItem } from "shared/utils/array";
import { formatDate } from "shared/utils/ui";

interface LeaseGroupStatusDescription {
  title: string;
  subtitle: string[];
  Icon: React.ReactNode;
}

export const getStatusDescription = (leaseGroup: LeaseGroup) => {
  let statusDescription: LeaseGroupStatusDescription;

  const getChangeSubtitle = (changedAt?: string, changedBy?: string) => {
    return [
      ...conditionalItem(Boolean(changedAt), `on ${formatDate(changedAt)}`),
      ...conditionalItem(Boolean(changedBy), `by ${changedBy}`),
    ];
  };

  switch (leaseGroup?.renewalUiStatus) {
    case RenewalStatus.OFFER_NOT_CREATED: {
      const remainingDays = leaseGroup?.renewal?.remainingDays;
      statusDescription = {
        title: "Lease expires",
        subtitle: [`in ${remainingDays} ${pluralize("day", remainingDays)}`],
        Icon: <Icon.HourglassIcon />,
      };
      break;
    }
    case RenewalStatus.OFFER_CREATION_IN_PROGRESS: {
      statusDescription = {
        title: "Renewal offer in progress",
        subtitle: getChangeSubtitle(leaseGroup?.renewal?.offer?.updatedAt),
        Icon: <Icon.DocumentProgressIcon />,
      };
      break;
    }
    case RenewalStatus.OFFER_CREATED: {
      statusDescription = {
        title: "Renewal offer pending approval",
        subtitle: getChangeSubtitle(
          leaseGroup?.renewal?.offer?.approvalRequestedAt
        ),
        Icon: <Icon.DocumentProgressIcon />,
      };
      break;
    }
    case RenewalStatus.OFFER_APPROVED: {
      statusDescription = {
        title: "Offer ready to send",
        subtitle: getChangeSubtitle(leaseGroup?.renewal?.offer?.approvedAt),
        Icon: <Icon.DocumentWhiteIcon />,
      };
      break;
    }
    case RenewalStatus.OFFER_SENT: {
      statusDescription = {
        title: "Pending tenant response",
        subtitle: getChangeSubtitle(leaseGroup?.renewal?.offer?.sentAt),
        Icon: <Icon.DocumentWhiteIcon />,
      };
      break;
    }
    case RenewalStatus.OFFER_PARTIALLY_ACCEPTED: {
      statusDescription = {
        title: "Offer partially accepted",
        subtitle: getChangeSubtitle(leaseGroup?.renewal?.updatedAt),
        Icon: <Icon.DocumentWhiteCheckedIcon />,
      };
      break;
    }
    case RenewalStatus.OFFER_PARTIALLY_REJECTED: {
      statusDescription = {
        title: "Offer partially rejected",
        subtitle: getChangeSubtitle(leaseGroup?.renewal?.updatedAt),
        Icon: <Icon.DocumentWhiteIcon />,
      };
      break;
    }
    case RenewalStatus.OFFER_REJECTED: {
      statusDescription = {
        title: "Not renewing",
        subtitle: getChangeSubtitle(leaseGroup?.renewal?.updatedAt),
        Icon: <Icon.NotRenewingIcon />,
      };
      break;
    }
    case RenewalStatus.RENEWAL_NOT_COMPLETED: {
      statusDescription = {
        title: "Renewal not completed",
        subtitle: getChangeSubtitle(leaseGroup?.renewal?.updatedAt),
        Icon: <Icon.NotRenewingIcon />,
      };
      break;
    }
    case RenewalStatus.OFFER_ACCEPTED: {
      statusDescription = {
        title: "Renewal offer accepted",
        subtitle: getChangeSubtitle(leaseGroup?.renewal?.updatedAt),
        Icon: <Icon.DocumentWhiteCheckedIcon />,
      };
      break;
    }
    case RenewalStatus.RENEWAL_CREATION_IN_PROGRESS:
    case LEASE_STATUS_CODES.inProgress: {
      statusDescription = {
        title: "Renewal creation in progress",
        subtitle: getChangeSubtitle(leaseGroup?.renewal?.updatedAt),
        Icon: <Icon.DocumentWhiteCheckedIcon />,
      };
      break;
    }
    case LEASE_STATUS_CODES.sent: {
      statusDescription = {
        title: "Renewal lease sent",
        subtitle: getChangeSubtitle(),
        Icon: <Icon.DocumentWhiteCheckedIcon />,
      };
      break;
    }
    case LEASE_STATUS_CODES.partiallySigned: {
      statusDescription = {
        title: "Renewal partially signed by renters",
        subtitle: getChangeSubtitle(),
        Icon: <Icon.SignLeaseIcon />,
      };
      break;
    }
    case LEASE_STATUS_CODES.applicantsSigned:
    case LEASE_STATUS_CODES.processingManagerSignature: {
      statusDescription = {
        title: "Renewal signed by renters",
        subtitle: getChangeSubtitle(),
        Icon: <Icon.SignLeaseIcon />,
      };
      break;
    }

    case LEASE_STATUS_CODES.leaseHandledOffline: {
      statusDescription = {
        title: "Renewal lease handled offline",
        subtitle: getChangeSubtitle(),
        Icon: <Icon.SignLeaseIcon />,
      };
      break;
    }

    case LEASE_STATUS_CODES.managerSigned:
    case LEASE_STATUS_CODES.leaseExecutedOffline:
    case RenewalStatus.RENEWAL_COMPLETED: {
      statusDescription = {
        title: "Renewal fully executed",
        subtitle: getChangeSubtitle(),
        Icon: <Icon.CheckGreenBig />,
      };
      break;
    }

    default: {
      statusDescription = null;
    }
  }

  return statusDescription;
};
