import React from "react";

import { Col, Row } from "antd";
import { Field } from "formik";
import PropTypes from "prop-types";

import { Checkbox, Input, RadioGroup } from "shared/components/Form";
import Icon from "shared/components/Icon";
import Settings from "shared/components/Settings";
import Tooltip from "shared/components/Tooltip";
import Well from "shared/components/Well";
import { LEASE_MIN_DURATION_POLICY } from "shared/config/constants";

import ApplicationSetupHeader from "../Header";
import { StyledSkeleton as Skeleton } from "../styled";

import { Container, CheckboxContainer } from "./styled";

const LeaseTerms = ({ loading, disabled }) => {
  return (
    <Settings
      header={
        <Skeleton loading={loading}>
          <ApplicationSetupHeader title="Lease Terms" />
        </Skeleton>
      }
      nodes={[
        {
          key: 1,
          component: (
            <Skeleton loading={loading}>
              <Container>
                <Row type="flex">
                  <Col lg={10} md={18} sm={24} xs={24}>
                    <Field
                      as={Input}
                      id="minLeaseDurationMonths"
                      name="minLeaseDurationMonths"
                      label="Minimum lease duration (months)"
                      type="number"
                      optional
                      min={0}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <RadioGroup
                  id="leaseDurationPolicy"
                  name="leaseDurationPolicy"
                  label="Will applicants be blocked on lease durations lower than minimum?"
                  options={[
                    {
                      value: LEASE_MIN_DURATION_POLICY.STRONG_VALIDATION,
                      label: "Yes, prevent applicant to move forward.",
                    },
                    {
                      value: LEASE_MIN_DURATION_POLICY.LIGHT_VALIDATION,
                      label: "No, allow the applicant to move forward.",
                    },
                  ]}
                  disabled={disabled}
                />
              </Container>
            </Skeleton>
          ),
        },
        {
          key: 2,
          component: (
            <Skeleton loading={loading}>
              <CheckboxContainer>
                <Field
                  id="default-lease-end-date"
                  name="leaseDefaultEndDateToEndOfTheMonth"
                  label="Default lease end date to end of month"
                  as={Checkbox}
                />
                <Tooltip
                  theme="light"
                  title={
                    <Well noBorder>
                      This will default all lease end dates to end of the month.
                      This can always be changed when creating the lease.
                    </Well>
                  }
                >
                  <Icon.InfoIcon className="extra-info" />
                </Tooltip>
              </CheckboxContainer>
            </Skeleton>
          ),
        },
      ]}
    />
  );
};

LeaseTerms.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

LeaseTerms.defaultProps = {
  loading: false,
  disabled: false,
};

export default LeaseTerms;
