import React from "react";

import { FieldArray } from "formik";

import SetupDocumentsFields from "manager/components/Form/SetupDocumentsFields";
import SettingsPage from "manager/templates/SettingsPage";

import { SetupAllFieldsProps } from "./interfaces";
import { SetupContainer, LoadingSpinner, Title } from "./styled";

const SetupAllFields = ({
  backLink,
  loading,
  propertyName,
}: SetupAllFieldsProps) => (
  <SettingsPage title="Property Fields Setup" backLink={backLink}>
    <FieldArray
      name="fields"
      render={() => (
        <SetupContainer>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Title>
                View and setup the property level fields for all documents
                assigned to {propertyName}
              </Title>
              <SetupDocumentsFields />
            </>
          )}
        </SetupContainer>
      )}
    />
  </SettingsPage>
);

export default SetupAllFields;
