import React, { useMemo } from "react";

import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";

import { useRenewalHelpers } from "manager/components/Renewals";
import {
  ACTION_NOT_PERMITTED_MESSAGE,
  RenewalApplicationOfferStatus,
  RenewalApplicationStatus,
} from "manager/config/constants";
import {
  useRejectRenewalApplicationOffer,
  useRemoveApplicantFromRenewal,
} from "manager/hooks/api";
import {
  LeaseGroup,
  LeaseGroupApplication,
} from "manager/interfaces/api/leaseGroups";
import { DropdownMenuItem } from "shared/components/DropdownMenu/interfaces";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { ApplicantTypes } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { getFullnameData } from "shared/utils/applicant";
import { conditionalItem } from "shared/utils/array";
import { contactByEmails } from "shared/utils/ui";

export interface ResidentsDropdown {
  [residentId: number]: DropdownMenuItem[];
}

const useLeaseGroupResidentsDropdown = (
  leaseGroup?: LeaseGroup,
  residents?: LeaseGroupApplication[]
): ResidentsDropdown => {
  const { offerHelpers, residentsHelpers } = useRenewalHelpers({
    renewal: leaseGroup?.renewal,
  });
  const { openModalDialog } = useModal();
  const { rejectRenewalApplicationOffer } = useRejectRenewalApplicationOffer();
  const { removeApplicantFromRenewal } = useRemoveApplicantFromRenewal();

  const notRenewIndividual = (resident: LeaseGroupApplication) =>
    openModalDialog(ConfirmationModal, {
      title: "Not renewing individual",
      subtitle: getFullnameData(resident)?.fullNameOrNA,
      message: "Are you sure you want to reject the offer for this individual?",
      onConfirm: () =>
        rejectRenewalApplicationOffer({
          renewalId: leaseGroup?.renewal?.id,
          applicationId: resident?.id,
        }),
    });

  const removeIndividual = (resident: LeaseGroupApplication) => {
    const fullNameOrNA = getFullnameData(resident)?.fullNameOrNA;

    openModalDialog(ConfirmationModal, {
      title: "Remove this applicant?",
      subtitle: fullNameOrNA,
      message: (
        <>
          <b>Please note:</b> Once you remove {fullNameOrNA}, you will have to
          re-invite them back into the application in case you change your
          decision.
        </>
      ),
      onConfirm: () =>
        removeApplicantFromRenewal({
          renewalId: leaseGroup?.renewal?.id,
          applicationId: resident?.id,
        }),
    });
  };

  const residentsActions = residents?.map((resident) => {
    const isDeletedResident =
      resident?.renewalUiStatus === RenewalApplicationStatus.REMOVED ||
      resident?.renewalUiStatus === RenewalApplicationStatus.NOT_RENEWING;

    return {
      resident,
      actions: [
        {
          key: "CONTACT",
          label: "Contact individual",
          onClick: () =>
            contactByEmails(
              [resident?.email],
              "The email is copied to clipboard!"
            ),
        },
        ...conditionalItem(
          offerHelpers.isNotRenewingOfferEnabled &&
            resident.renewalUiStatus ===
              RenewalApplicationOfferStatus.PENDING_OFFER_RESPONSE &&
            Number(resident.applicantType) === ApplicantTypes.applicant,
          {
            key: "NOT_RENEWING",
            label: "Not renewing",
            onClick: () => notRenewIndividual(resident),
            disabled: !offerHelpers.canNotRenewOffer,
            tooltip: {
              title:
                !offerHelpers.canNotRenewOffer && ACTION_NOT_PERMITTED_MESSAGE,
            },
          }
        ),
        ...conditionalItem(residentsHelpers.isRemovingResidentEnabled, {
          key: "REMOVE_INDIVIDUAL",
          label: "Remove individual",
          onClick: () => removeIndividual(resident),
          disabled: isDeletedResident || !resident.canBeRemoved.value,
          tooltip: { title: resident.canBeRemoved.helpText },
        }),
      ],
    };
  });

  const residentsActionsCollection = useMemo(
    () =>
      mapValues(
        groupBy(residentsActions, (item) => item?.resident?.id),
        ([item]) => item.actions
      ),
    [leaseGroup, residents]
  );

  return residentsActionsCollection;
};

export default useLeaseGroupResidentsDropdown;
