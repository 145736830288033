import React from "react";

import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";
import Well from "shared/components/Well";

import { RegularTextPurple } from "./styled";

const RentableItemsProrationInfo = () => (
  <Well noBorder largePadding>
    <RegularTextPurple strong>PRORATE THE FIRST MONTHS RATE</RegularTextPurple>
    <Spacer size={Spacer.SIZES.xs} />
    <RegularText weak>
      Rentable items on the first month’s rent of the lease are prorated for the
      number of days remaining in the month when the toggle is enabled.
    </RegularText>
    <Spacer />
    <RegularTextPurple strong>EXAMPLE</RegularTextPurple>
    <Spacer size={Spacer.SIZES.xs} />
    <RegularText weak>
      Parking Spot: <RegularText strong> $100.00 </RegularText>
      <br />
      Lease Start Date: <RegularText strong> June 15th</RegularText>
      <br />
      Lease Days in Start Month: <RegularText strong> 16</RegularText>
    </RegularText>
    <Spacer />
    <RegularText weak>
      Prorated Rentable Items Fee $100 x (16 days charged / 30 days in
      June)=&nbsp;
      <RegularText strong>$53.33 prorated parking spot fee</RegularText>
    </RegularText>
  </Well>
);

export default RentableItemsProrationInfo;
