import { css } from "@emotion/core";
import styled from "@emotion/styled";

import ApplicantsCheckList from "shared/components/ApplicantsCheckList";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { COLORS, MEDIA_QUERIES, colors } from "shared/config/constants";

const Header = styled(FlexContainer)`
  background: ${colors.primary[300]};
  position: relative;
  padding: 22px;
  margin-bottom: 10px;
  border-radius: 4px;

  ${MEDIA_QUERIES.w.ltSm} {
    margin-top: 16px;
    padding: 0;
  }

  .title {
    color: ${COLORS.white};
    font-weight: 500;
    margin-right: 75px;
    margin-bottom: 0 !important;

    ${MEDIA_QUERIES.w.ltSm} {
      display: none;
    }
  }

  .radio-buttons-container {
    z-index: 100;
  }

  .triangles-image {
    position: absolute;
    top: 0;
    height: 100%;
    right: 260px;

    path: {
      fill: ${COLORS.lightGreen2};
    }

    ${MEDIA_QUERIES.w.ltLg} {
      right: 0;
    }

    ${MEDIA_QUERIES.w.ltSm} {
      display: none;
    }
  }
`;

const FlexWrapper = styled(FlexContainer)`
  & > * {
    margin-right: 5px;
  }
`;

const Cell = styled.div`
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${MEDIA_QUERIES.w.ltSm} {
    min-height: auto;
  }
`;

const StyledCheckList = styled(ApplicantsCheckList)`
  color: ${COLORS.primary};
`;

const Link = styled(ActionLink)`
  min-height: 40px;
  align-items: center;
  color: ${colors.secondary.base};
`;

const iconStyles = css`
  margin-right: 6px;
  flex-shrink: 0;
`;

const LeaseInProgressIcon = styled(Icon.LeaseInProgressIcon)`
  ${iconStyles};
  width: 14px;
  height: 14px;
`;

const LeaseExecutedIcon = styled(Icon.DealApprovedIcon)`
  ${iconStyles};
  width: 24px;
  height: 24px;
`;

const ExportWrapperDesktop = styled.div`
  .ant-btn-secondary {
    border-color: ${colors.secondary[700]};
    .button-children {
      color: ${colors.secondary[700]};
    }
  }
`;

const ExportWrapper = styled.div`
  padding: 10px 10px 10px 0px;
  .ant-btn-secondary {
    border-color: ${colors.secondary[700]};
    .button-children {
      color: ${colors.secondary[700]};
    }
  }
`;

export {
  Header,
  FlexWrapper,
  Cell,
  StyledCheckList,
  Link,
  LeaseInProgressIcon,
  LeaseExecutedIcon,
  ExportWrapper,
  ExportWrapperDesktop,
};
