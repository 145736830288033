import styled from "@emotion/styled";

import BaseFooterBar from "shared/components/FooterBar";

const FooterBar = styled(BaseFooterBar)`
  justify-content: flex-end;

  > * {
    margin-right: 16px;
  }
`;

export { FooterBar };
