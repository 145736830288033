import React, { useCallback, useMemo } from "react";

import { Col, Row } from "antd";
import { FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";

import ROUTES, { APPLICATION_LEASE_SETUP_TABS } from "manager/config/routes";
import {
  CustomEmailTemplate,
  DefaultEmailType,
} from "manager/interfaces/api/customEmailTemplates";
import { RenewalsSettings } from "manager/interfaces/api/renewalsSettings";
import FlexContainer from "shared/components/FlexContainer";
import {
  Checkbox,
  FormPromptShell,
  Select,
  SwitchTitle,
  VeroFormField,
} from "shared/components/Form";
import { FormPromptShellProps } from "shared/components/Form/FormPromptShell";
import { ActionLink } from "shared/components/Links";
import Notice from "shared/components/Notice";
import Skeleton from "shared/components/Skeleton";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import Well from "shared/components/Well";
import { usePermissions } from "shared/hooks";
import { transformRoute } from "shared/utils/routing";

import { StyledRenewalsSettings } from "./styled";
import {
  DEFAULT_EMAIL_KEY,
  getEmailConfigurationTooltip,
  getNoticeOptions,
  NoticeOption,
} from "./utils";

interface RenewalsSettingsFormProps extends Partial<FormPromptShellProps> {
  initialValues: RenewalsSettings;
  onSubmit: (
    values: RenewalsSettings,
    formikBag: FormikHelpers<RenewalsSettings>
  ) => Promise<any>;
  saveChanges: (values: RenewalsSettings) => Promise<any>;
  isLoading: boolean;
  emailConfigurations?: CustomEmailTemplate[];
  disableForm?: boolean;
  infoMessage?: string;
  level: SettingsLevel;
}

export enum SettingsLevel {
  company = "company",
  portfolio = "portfolio",
  property = "property",
}

const RenewalsSettingsForm = ({
  initialValues,
  onSubmit,
  saveChanges,
  isLoading,
  emailConfigurations,
  disableForm,
  infoMessage,
  level,
  ...formProps
}: RenewalsSettingsFormProps) => {
  const permissions = usePermissions();
  const noticeOptions = useMemo(() => getNoticeOptions(), []);
  const { push } = useHistory();

  const navigateToEmailTemplates = useCallback(
    () =>
      push(
        transformRoute(ROUTES.applicationsLeaseSetupTab, {
          tabName: APPLICATION_LEASE_SETUP_TABS.emailTemplates,
        }),
        {
          typeName: DefaultEmailType.LEASE_RENEWAL_NOTICE,
        }
      ),
    []
  );
  const enableRenewalsTooltip = `When enabled, you will be informed about leases 
    close to expiration for this ${level} and you will be able to manage their renewal.`;
  const emailConfigurationTooltip = getEmailConfigurationTooltip(permissions);

  return (
    <FormPromptShell
      initialValues={initialValues}
      onSubmit={onSubmit}
      saveChanges={saveChanges}
      {...formProps}
    >
      {({ values, setFieldValue }) => (
        <Skeleton loading={isLoading}>
          <StyledRenewalsSettings>
            {infoMessage && (
              <Well className="info-message">
                <Notice type="info">
                  <RegularText>{infoMessage}</RegularText>
                </Notice>
              </Well>
            )}
            <Tile
              header={{
                title: "Renewals",
                subtitle: "Send renewal offers to residents",
                collapsibleOnDesktop: true,
                collapsibleOnMobile: true,
              }}
            >
              <Tile.Inner noPaddingTop noPaddingBottom>
                <VeroFormField
                  id="renewalEnabled"
                  as={SwitchTitle}
                  title="Enable renewals"
                  name="renewalEnabled"
                  className="settings-toggle"
                  disabled={disableForm}
                  tooltip={enableRenewalsTooltip}
                />
              </Tile.Inner>
            </Tile>
            {values.renewalEnabled && (
              <Tile
                header={{
                  title: "Renter Renewal Notice",
                  collapsibleOnDesktop: true,
                  collapsibleOnMobile: true,
                }}
              >
                <Tile.Inner noPaddingTop noPaddingBottom>
                  <VeroFormField
                    id="useNoticeSchedule"
                    as={SwitchTitle}
                    title="Apply leasing notice reminders to renewals?"
                    name="useNoticeSchedule"
                    className="settings-toggle"
                    value={values.useNoticeSchedule}
                    onChange={(value) =>
                      setFieldValue("useNoticeSchedule", value)
                    }
                    disabled={disableForm}
                  />
                </Tile.Inner>
                {values.useNoticeSchedule && (
                  <Tile.Inner borderTop>
                    <FlexContainer justifyContent="space-between">
                      <RegularText weak>
                        Please setup renewal notice emails for different types
                        of units
                      </RegularText>
                      <Tooltip title={emailConfigurationTooltip}>
                        <div>
                          <ActionLink
                            onClick={navigateToEmailTemplates}
                            disabled={Boolean(emailConfigurationTooltip)}
                          >
                            Email Templates
                          </ActionLink>
                        </div>
                      </Tooltip>
                    </FlexContainer>
                    <Spacer />
                    <Well noBorder className="notice-settings">
                      {noticeOptions.map((notice: NoticeOption) => {
                        return (
                          <Row key={notice.name} className="notice-row">
                            <Col md={8} xl={6} xxl={4}>
                              <VeroFormField
                                id={notice.name}
                                as={Checkbox}
                                label={
                                  <RegularText>{notice.title}</RegularText>
                                }
                                name={notice.name}
                                value={values[notice.name]}
                                disabled={disableForm}
                              />
                            </Col>
                            <Col md={8} xl={6} xxl={4}>
                              <VeroFormField
                                id={notice.emailName}
                                name={notice.emailName}
                                label="Email Configuration"
                                as={Select}
                                value={
                                  values[notice.emailName] === null
                                    ? DEFAULT_EMAIL_KEY
                                    : values[notice.emailName]
                                }
                                onChange={(key) =>
                                  setFieldValue(
                                    notice.emailName,
                                    key === DEFAULT_EMAIL_KEY ? null : key
                                  )
                                }
                                disabled={disableForm || !values[notice.name]}
                              >
                                <Select.Option
                                  key={DEFAULT_EMAIL_KEY}
                                  value={DEFAULT_EMAIL_KEY}
                                >
                                  Default
                                </Select.Option>
                                {emailConfigurations?.map((item) => (
                                  <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Select.Option>
                                ))}
                              </VeroFormField>
                            </Col>
                          </Row>
                        );
                      })}
                    </Well>
                  </Tile.Inner>
                )}
              </Tile>
            )}
          </StyledRenewalsSettings>
        </Skeleton>
      )}
    </FormPromptShell>
  );
};

export default RenewalsSettingsForm;
