import React from "react";

import { FieldArray, useFormikContext } from "formik";
import pluralize from "pluralize";
import { Helmet } from "react-helmet";

import { PERCENT_RENT_CHANGE } from "manager/config/constants";
import { Button } from "shared/components/Button";
import ErrorList from "shared/components/ErrorList";
import FlexContainer from "shared/components/FlexContainer";
import { Input, VeroFormField } from "shared/components/Form";
import { MaskedInput } from "shared/components/Form/MaskedInput";
import { Select4, Select4Option } from "shared/components/Form/Select4";
import Icon from "shared/components/Icon";
import { ActionLink, BackLink, UnderlineLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import { RentChangeOptions } from "shared/config/constants";
import { Offer, RenewalOption } from "shared/interfaces/api/renewals";
import { generateId } from "shared/utils/misc.util";

import { RenewalsConditionalPage } from "../RenewalsConditionalPage";

import {
  EMPTY_OPTION,
  OFFER_OPTION_FIELDS,
  RENT_CHANGE_OPTIONS,
} from "./constants";
import {
  OfferOptionRow,
  OfferOptionsActionsContainer,
  TitleContainer,
} from "./styled";
import { getDuplicatedLastOption } from "./utils";

interface RenewalOfferBuilderProps {
  isReadOnly: boolean;
  propertyUnitName: string;
  openConcessionModal: (option: RenewalOption, idx: number) => void;
  renewalBackLink: {
    children: React.ReactNode;
    to: string;
  };
}

export const RenewalOfferBuilder = ({
  isReadOnly,
  propertyUnitName,
  openConcessionModal,
  renewalBackLink,
}: RenewalOfferBuilderProps) => {
  const { values, errors } = useFormikContext<Offer>();
  const isRentChangeValueDisabled = (option: RenewalOption) => {
    return (
      !option.rentChangeOption ||
      option.rentChangeOption === RentChangeOptions.NO_CHANGE
    );
  };
  return (
    <RenewalsConditionalPage>
      <Helmet>
        <title>Renewal Offer</title>
      </Helmet>
      <BackLink.Container>
        <BackLink {...renewalBackLink} />
      </BackLink.Container>
      <TitleContainer>
        <PageTitle>Create renewal offer</PageTitle>
        <PageTitle.Subtitle>
          Unit: <RegularText strong>{propertyUnitName}</RegularText>
        </PageTitle.Subtitle>
      </TitleContainer>
      <Tile
        header={{
          title: "Renewal Offers",
          subtitle:
            "Enter the details for the renewal offer, add as many renewal offers as needed",
        }}
      >
        <Tile.Inner>
          {typeof errors.renewalOptions === "string" && (
            <ErrorList errors={[errors.renewalOptions]} noMarginBottom />
          )}
          <FieldArray
            name="renewalOptions"
            render={(arrayHelpers) => (
              <>
                {(values?.renewalOptions || []).map((option, idx) => {
                  const getFieldName = (name) => {
                    return `renewalOptions.${idx}.${name}`;
                  };
                  const durationInMonthsField = getFieldName(
                    OFFER_OPTION_FIELDS.durationInMonths
                  );
                  const rentChangeOptionField = getFieldName(
                    OFFER_OPTION_FIELDS.rentChangeOption
                  );
                  const rentChangeValueField = getFieldName(
                    OFFER_OPTION_FIELDS.rentChangeValue
                  );

                  const isConcessionEnabled =
                    !!option.durationInMonths && option.durationInMonths > 1;

                  const ValueChangeComponent = PERCENT_RENT_CHANGE.has(
                    option.rentChangeOption
                  )
                    ? // @ts-ignore
                      MaskedInput.Formik.PercentageInput
                    : // @ts-ignore
                      MaskedInput.Formik.Money;
                  return (
                    <OfferOptionRow key={option.id || option.guid}>
                      <div className="option-label-and-input">
                        <div className="label-col">
                          <RegularText light>OFFER {idx + 1}</RegularText>
                        </div>
                        <FlexContainer
                          flexGrow="1"
                          flexWrap="wrap"
                          alignItems="center"
                        >
                          <div className="input-col">
                            <VeroFormField
                              id={durationInMonthsField}
                              name={durationInMonthsField}
                              data-testid={durationInMonthsField}
                              label="Enter Renewal Period"
                              type="number"
                              required
                              as={Input}
                              disabled={isReadOnly}
                            />
                          </div>
                          <div className="input-col">
                            <VeroFormField
                              as={Select4}
                              name={rentChangeOptionField}
                              id={rentChangeOptionField}
                              label="Select Change Type"
                              disabled={isReadOnly}
                            >
                              {RENT_CHANGE_OPTIONS.map((item) => (
                                <Select4Option key={item.key} value={item.key}>
                                  {item.label}
                                </Select4Option>
                              ))}
                            </VeroFormField>
                          </div>
                          <div className="input-col">
                            <ValueChangeComponent
                              // @ts-ignore
                              id={rentChangeValueField}
                              name={rentChangeValueField}
                              data-testid={rentChangeValueField}
                              label="Enter Change Amount"
                              disabled={
                                isReadOnly || isRentChangeValueDisabled(option)
                              }
                            />
                          </div>
                          <FlexContainer
                            alignItems="center"
                            className="input-col"
                          >
                            {option?.concession ? (
                              <Tooltip
                                title={
                                  <>
                                    <RegularText strong>
                                      {option?.concession?.numberOfMonths}{" "}
                                      {pluralize(
                                        "month",
                                        option?.concession?.numberOfMonths
                                      )}
                                    </RegularText>{" "}
                                    concession
                                  </>
                                }
                              >
                                <div>
                                  <UnderlineLink
                                    disabled={isReadOnly}
                                    onClick={() =>
                                      openConcessionModal(option, idx)
                                    }
                                  >
                                    CONCESSION ADDED
                                  </UnderlineLink>
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={
                                  !isConcessionEnabled &&
                                  "Please add at least 2 months of renewal period"
                                }
                              >
                                <div>
                                  <ActionLink
                                    plus
                                    disabled={
                                      isReadOnly || !isConcessionEnabled
                                    }
                                    onClick={() =>
                                      openConcessionModal(option, idx)
                                    }
                                  >
                                    CONCESSION
                                  </ActionLink>
                                </div>
                              </Tooltip>
                            )}
                          </FlexContainer>
                        </FlexContainer>
                      </div>
                      {!isReadOnly && (
                        <Icon.DeleteSmallNoCircleIcon
                          className="action-icon"
                          onClick={() => arrayHelpers.remove(idx)}
                        />
                      )}
                    </OfferOptionRow>
                  );
                })}
                {!isReadOnly && (
                  <>
                    <Spacer size={Spacer.SIZES.md} />
                    <OfferOptionsActionsContainer>
                      <Button
                        type="secondary"
                        onClick={() =>
                          arrayHelpers.push({
                            guid: generateId("guid-"),
                            ...EMPTY_OPTION,
                          })
                        }
                      >
                        ADD AN OFFER OPTION
                      </Button>
                      {values?.renewalOptions?.length > 0 && (
                        <ActionLink
                          onClick={() =>
                            arrayHelpers.push(getDuplicatedLastOption(values))
                          }
                        >
                          DUPLICATE LAST OFFER
                        </ActionLink>
                      )}
                    </OfferOptionsActionsContainer>
                  </>
                )}
              </>
            )}
          />
        </Tile.Inner>
      </Tile>
    </RenewalsConditionalPage>
  );
};
