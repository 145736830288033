import React from "react";

import { Route, Switch } from "react-router-dom";

import Layout from "manager/components/Layout";
import ROUTES from "manager/config/routes";
import { HeaderProps } from "shared/components/Header";
import { SidebarProps } from "shared/components/Sidebar";

import Renewal from "./Renewal";
import { RenewalOfferBuilder } from "./RenewalOfferBuilder";
import { Renewals } from "./Renewals";

interface RenewalsRouterProps {
  sidebar: SidebarProps;
  header: HeaderProps;
}

const RenewalsRouter = ({ sidebar, header }: RenewalsRouterProps) => {
  return (
    <Switch>
      <Route exact path={ROUTES.renewals}>
        <Layout sidebar={sidebar} header={header}>
          <Renewals />
        </Layout>
      </Route>
      <Route exact path={ROUTES.renewal}>
        <Renewal sidebar={sidebar} header={header} />
      </Route>
      <Route exact path={ROUTES.renewalOffer}>
        <RenewalOfferBuilder sidebar={sidebar} header={header} />
      </Route>
    </Switch>
  );
};

export default RenewalsRouter;
