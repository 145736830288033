import React from "react";

import sortBy from "lodash/sortBy";
import { useHistory } from "react-router-dom";

import { useUpsertManagerQuestion } from "manager/hooks/api/leaseSetupQueries";
import {
  ANSWER_QUESTION_TYPES,
  CONTENT_TYPE,
  NOTIFICATIONS,
} from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";
import { openNotification } from "shared/utils/ui";

import { LEASE_QUESTION_FIELDS } from "./config";
import ManagerQuestionModal, {
  MANAGER_QUESTION_MODAL_MODE,
} from "./ManagerQuestionModal";

const ManagerQuestionModalContainer = () => {
  const history = useHistory();
  const { upsertManagerQuestion } = useUpsertManagerQuestion();
  const { currentModalContext, selfApi } = useModal();
  const {
    initialValues: initialValuesEdit,
    propertyId,
    documentTemplates,
    managerQuestions,
    mode,
  } = currentModalContext;

  const initialValues = {
    [LEASE_QUESTION_FIELDS.questionName]: "",
    [LEASE_QUESTION_FIELDS.options]: Array.from({ length: 2 }, (_, i) => ({
      [LEASE_QUESTION_FIELDS.answer]: "",
      [LEASE_QUESTION_FIELDS.position]: i + 1,
      [LEASE_QUESTION_FIELDS.documents]: [],
    })),
  };

  const buildModel = (values) => ({
    ...values,
    [LEASE_QUESTION_FIELDS.options]: values[LEASE_QUESTION_FIELDS.options].map(
      (option) => {
        const docIds = option[LEASE_QUESTION_FIELDS.documents];
        return {
          ...option,
          [LEASE_QUESTION_FIELDS.documents]: documentTemplates.filter(
            ({ id }) => docIds.includes(`${id}`)
          ),
        };
      }
    ),
  });

  const submit = (values, formikApi) => {
    const onSuccess = () => {
      selfApi.closeDialog();
      openNotification(
        `The lease question has been successfully ${
          mode === MANAGER_QUESTION_MODAL_MODE.CREATE ? "created" : "edited"
        }.`,
        NOTIFICATIONS.info
      );
    };

    const onError = onDialogSubmitError(formikApi, history);

    const leaseQuestions = managerQuestions?.filter(
      ({ id }) => id !== values.id
    );

    const leaseQuestion = {
      ...buildModel(values),
      objectId: propertyId,
      contentType: CONTENT_TYPE.property,
      isManagerQuestion: true,
      answerType: ANSWER_QUESTION_TYPES.singleChoice,
    };

    if (mode === MANAGER_QUESTION_MODAL_MODE.CREATE) {
      leaseQuestion.isActive = true;
    }

    leaseQuestions.push(leaseQuestion);
    upsertManagerQuestion(
      {
        id: propertyId,
        leaseQuestions: leaseQuestions.map((q) => ({
          ...q,
          id: undefined,
        })),
      },
      { onSuccess, onError }
    );
  };

  const getDocuments = () =>
    sortBy(
      documentTemplates.filter(({ isActive }) => isActive),
      "position"
    ).map(({ id, name }) => ({ key: `${id}`, label: name }));

  const prepareInitialValues = () => {
    initialValuesEdit[LEASE_QUESTION_FIELDS.options] = initialValuesEdit?.[
      LEASE_QUESTION_FIELDS.options
    ]?.map((option) => ({
      ...option,
      [LEASE_QUESTION_FIELDS.documents]: option[
        LEASE_QUESTION_FIELDS.documents
      ].map(({ id }) => `${id}`),
    }));

    initialValuesEdit[LEASE_QUESTION_FIELDS.options] = sortBy(
      initialValuesEdit[LEASE_QUESTION_FIELDS.options],
      LEASE_QUESTION_FIELDS.position
    );
    return initialValuesEdit;
  };

  return (
    <ManagerQuestionModal
      initialValues={
        mode === MANAGER_QUESTION_MODAL_MODE.CREATE
          ? initialValues
          : prepareInitialValues()
      }
      submit={submit}
      documents={getDocuments()}
      mode={mode}
    />
  );
};

export default ManagerQuestionModalContainer;
