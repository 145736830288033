import React from "react";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { RENEWAL_NOTE_MAX_LENGTH } from "manager/config/constants";
import { RenewalNoteValidationSchema } from "manager/utils/validationSchemas";
import FlexContainer from "shared/components/FlexContainer";
import { TextArea, VeroFormField } from "shared/components/Form";
import { ActionLink, UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import { SmallText } from "shared/components/Typography";
import { deferred } from "shared/utils/misc.util";

export interface NoteFormValues {
  message: string;
}

const initialValues: NoteFormValues = { message: "" };

const NoteValidationSchema = Yup.object().shape({
  message: RenewalNoteValidationSchema,
});

interface LeaseGroupNotesFormProps {
  onSubmit: (value: NoteFormValues) => Promise<any>;
  disabled?: boolean;
}

const LeaseGroupNotesForm = ({
  onSubmit,
  disabled,
}: LeaseGroupNotesFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={NoteValidationSchema}
      onSubmit={(values, helpers) =>
        onSubmit(values).then(() =>
          deferred(() =>
            helpers.resetForm({
              values: initialValues,
            })
          )
        )
      }
    >
      {({ values, setFieldTouched, resetForm, submitForm, isSubmitting }) => (
        <Form>
          {/* @ts-ignore */}
          <VeroFormField
            id="message"
            name="message"
            as={TextArea}
            placeholder="Add a new note..."
            maxLength={RENEWAL_NOTE_MAX_LENGTH}
            onBlur={() => setFieldTouched("message", false)}
            disabled={disabled}
          />
          <FlexContainer justifyContent="flex-end">
            <SmallText weak>
              {values.message.length}/{RENEWAL_NOTE_MAX_LENGTH}
            </SmallText>
          </FlexContainer>
          <Spacer size={Spacer.SIZES.xs} />
          <FlexContainer alignItems="center" justifyContent="flex-end">
            <UnderlineLink
              onClick={resetForm}
              disabled={isSubmitting || disabled}
            >
              Cancel
            </UnderlineLink>
            &nbsp; &nbsp;
            <ActionLink
              onClick={submitForm}
              disabled={isSubmitting || disabled}
            >
              Save
            </ActionLink>
          </FlexContainer>
        </Form>
      )}
    </Formik>
  );
};

export default LeaseGroupNotesForm;
