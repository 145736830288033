import React, { useCallback, useEffect } from "react";

import RefreshDocumentsModal from "manager/components/Modal/Lease/RefreshDocumentsModal";
import { useRefreshLeaseAvailableDocuments } from "manager/hooks/api";
import { Lease } from "manager/interfaces/api/lease";
import FlexContainer from "shared/components/FlexContainer";
import { ActionLink } from "shared/components/Links";
import { RegularText } from "shared/components/Typography";
import useModal from "shared/hooks/useModal";

import { LeaseBuilderSteps } from "./constants";

const BASE_DOCS_MISMATCH_MESSAGE =
  "New leasing documents have recently been assigned to this property.";

const getDocumentsMismatchWarning = ({
  lease,
  currentStep,
  onRefreshDocuments,
  navigateToDocuments,
}: {
  lease: Lease;
  currentStep: number;
  onRefreshDocuments: () => void;
  navigateToDocuments: () => void;
}) => {
  let message: React.ReactNode;

  if (lease && lease.existsLeasePropertyDocumentTemplatesMismatch) {
    const isDocumentsStep =
      currentStep === LeaseBuilderSteps.LEASE_DOCUMENTS_STEP;
    const isDocumentsStepAccessible =
      lease.step > LeaseBuilderSteps.LEASE_INFO_STEP;

    if (isDocumentsStep) {
      message = (
        <FlexContainer alignItems="center">
          <RegularText>{BASE_DOCS_MISMATCH_MESSAGE}</RegularText>
          &nbsp;
          <ActionLink onClick={onRefreshDocuments}>REFRESH</ActionLink>
        </FlexContainer>
      );
    } else if (isDocumentsStepAccessible) {
      message = (
        <FlexContainer alignItems="center">
          <RegularText>{BASE_DOCS_MISMATCH_MESSAGE}</RegularText>
          &nbsp;
          <ActionLink onClick={navigateToDocuments}>DOCUMENTS STEP</ActionLink>
        </FlexContainer>
      );
    }
  }

  return message;
};

const useLeaseDocumentsRefresh = ({
  lease,
  currentStep,
  navigateToDocuments,
}: {
  lease: Lease;
  currentStep: number;
  navigateToDocuments: () => void;
}) => {
  const { openModalDialog } = useModal();

  const { refreshLeaseAvailableDocuments, isLoading: isRefreshingDocuments } =
    useRefreshLeaseAvailableDocuments(lease?.id);

  const onRefreshDocuments = useCallback(() => {
    if (lease?.id) {
      openModalDialog(RefreshDocumentsModal, {
        onConfirm: () => {
          refreshLeaseAvailableDocuments(lease.id);
        },
      });
    }
  }, [lease, refreshLeaseAvailableDocuments]);

  useEffect(() => {
    if (
      lease?.step &&
      lease.step === currentStep &&
      currentStep === LeaseBuilderSteps.LEASE_INFO_STEP &&
      lease.existsLeasePropertyDocumentTemplatesMismatch
    ) {
      refreshLeaseAvailableDocuments(lease.id);
    }
  }, [lease?.step, currentStep]);

  return {
    documentsMismatchWarning: getDocumentsMismatchWarning({
      lease,
      currentStep,
      onRefreshDocuments,
      navigateToDocuments,
    }),
    isRefreshingDocuments,
  };
};

export default useLeaseDocumentsRefresh;
