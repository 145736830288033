import styled from "@emotion/styled";

import Image from "shared/components/Image";
import Tile from "shared/components/Tile";
import { DIMENSIONS, MEDIA_QUERIES, COLORS } from "shared/config/constants";

export const CityBackground = styled(Image.CityBackground)`
  position: fixed;
  left: 50%;
  padding: 0 24px;
  bottom: 0;
  max-width: 100%;
  height: auto;

  ${MEDIA_QUERIES.w.gteXl} {
    transform: translateX(
      calc(-50% + ${DIMENSIONS.sidebar.gteXl.widthInt / 2}px)
    );
  }

  ${MEDIA_QUERIES.w.ltXl} {
    transform: translateX(
      calc(-50% + ${DIMENSIONS.sidebar.ltXl.widthInt / 2}px)
    );
  }

  ${MEDIA_QUERIES.w.ltSm} {
    display: none;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  transform: translateY(50%);
`;

export const RentPrice = styled.div`
  height: 100%;
  margin-left: auto;
`;

export const LeaseAdditionalDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-start;
  flex: 1;
  min-width: 150px;
`;

export const RenewalLeaseFooterContainer = styled(Tile.Inner)`
  background-color: ${COLORS.violetTheme.lightViolet};
  padding: 16px 24px;
  border-radius: 0 0 2px 2px;
`;
