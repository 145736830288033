
import withSizes from "react-sizes";

import DealStatusColumn from "manager/components/DealStatusColumn";
import { DEAL_CATEGORIES } from "manager/config/constants";
import { useDealsTable } from "manager/hooks/useDealsTable";
import DealDetails from "manager/pages/Deals/DealsTable/DealDetails";
import ApplicantsCheckList from "shared/components/ApplicantsCheckList";
import FilterBar from "shared/components/FilterBar";
import FlexContainer from "shared/components/FlexContainer";
import { Checkbox } from "shared/components/Form";
import Icon from "shared/components/Icon";
import MobileFilters from "shared/components/MobileFilters/MobileFiltersContainer";
import RadioButtons, {
  RADIO_BUTTONS_THEMES,
} from "shared/components/RadioButtons";
import Table from "shared/components/Table";
import {
  BREAKPOINT_TYPES,
  DEAL_UI_STATUS_CODES,
  LEASE_STATUS_CODES,
} from "shared/config/constants";
import { formatDate, getBreakpoint } from "shared/utils/ui";

import { UnitDealsProps } from "../interfaces";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import {
  Cell,
  CheckboxContainer,
  FilterWrapper,
  FlexWrapper,
  LeaseInProgressIcon,
  PrimaryText,
  UnitDealsWrapper,
} from "./styled";
import {
  ExpandIconIndex,
  getDealStatusText,
  getLeaseStatusText,
} from "./utils";

const UnitDeals = ({
  width,
  deals = [],
  refetchDeals,
  isLoading,
  filters,
  updateFilters,
  activeCategory,
  setCategory,
  onTableChange,
  pagination,
  setPagination,
  dealsCount = 0,
  showArchived,
  onShowArchivedCheckboxChange,
}: UnitDealsProps) => {
  const breakpoint = getBreakpoint(width);
  const { page, pageSize } = pagination;

  const {
    getColumnWidth,
    getActionLink,
    goToDealSummary,
    onExpand,
    expandedRowKeys,
    getOtherRowEvent,
    getDealDetails,
  } = useDealsTable({ refetchDeals });
  const columnWidth = getColumnWidth(width);

  const onPaginationChange = (currentPage) => {
    const newPagination = { page: currentPage, pageSize };
    setPagination(newPagination);
  };

  const dealsFilters = {
    title: "Filter Applications",
    noBorderBottom: true,
    padding: "0 0 0 25px",
    search: {
      action: (value: string) =>
        updateFilters({ unitPropertyOrApplicant: value }),
      placeholder: "Search by applicant",
      width: 220,
      debounce: true,
      value: filters.unitPropertyOrApplicant,
    },
    dateRange: {
      label: "Application Date",
      date1Id: "dateMin",
      date2Id: "dateMax",
      setValues: updateFilters,
      fromValue: filters.dateMin,
      toValue: filters.dateMax,
    },
    clearFilters: {
      onClick: () => updateFilters(null),
    },
    breakpointFilterPopover: [
      BREAKPOINT_TYPES.sm,
      BREAKPOINT_TYPES.md,
      BREAKPOINT_TYPES.lg,
      BREAKPOINT_TYPES.xl,
    ],
  };

  return (
    <UnitDealsWrapper>
      <FlexContainer justifyContent="space-between" alignItems="center">
        <CheckboxContainer>
          {/* @ts-ignore */}
          <Checkbox
            id="show-archived-applications"
            label="Show archived applications"
            onChange={onShowArchivedCheckboxChange}
            checked={showArchived}
          />
        </CheckboxContainer>
        <MobileFilters {...dealsFilters} />
      </FlexContainer>
      <FilterWrapper>
        <RadioButtons
          theme={RADIO_BUTTONS_THEMES.gray}
          menuItems={DEAL_CATEGORIES}
          activeItem={activeCategory}
          onChange={setCategory}
        />
        <FilterBar {...dealsFilters} />
      </FilterWrapper>
      <Table
        alt
        loading={isLoading}
        columns={[
          {
            title: "Applicants",
            dataIndex: "applicants",
            key: "applicants",
            width: 250,
          },
          {
            title: "Date Created",
            dataIndex: "applicationDate",
            key: "applicationDate",
            width: columnWidth[0],
            sorter: true,
          },
          {
            title: "Gross Rent",
            dataIndex: "rent",
            key: "rent",
            width: columnWidth[1],
            sorter: true,
          },
          {
            title: "Application Status",
            dataIndex: "dealStatus",
            width: columnWidth[2],
            key: "dealStatus",
          },
          {
            key: "x",
            title: "",
            dataIndex: "x",
            width: 100,
          },
        ]}
        columnBreakpoints={{
          xs: {
            column1: "applicationDate",
            column2: "dealStatus",
          },
          sm: ["applicationDate", "dealStatus", "rent", "x"],
          md: ["applicationDate", "dealStatus", "rent", "x"],
          lg: ["applicants", "applicationDate", "rent", "dealStatus", "x"],
          xl: ["applicants", "applicationDate", "rent", "dealStatus", "x"],
        }}
        onRowClick={(key) => goToDealSummary(key)}
        placeholder={{
          Icon: Icon.NoDealsIcon,
          label: "Applications",
        }}
        rows={deals.map((deal, index) => {
          const applicants = deal?.applicantsSummary || [];
          const numTotalApplications = deal.applicantsSummary.length;
          const numCompleteApplications = deal.applicantsSummary.filter(
            (applicant) => applicant.isSubmitted
          ).length;
          const numSignedApplications = deal.applicantsSummary.filter(
            (applicant) => applicant.leaseSigned
          ).length;
          const isRentIcon = deal.unit?.yardiConnected && deal.lease?.rentPaid;

          const actionLink = getActionLink(deal);

          const { isArchived, lastArchivedAt } = deal;

          const dealStatus = getDealStatusText(
            deal.dealUiStatus,
            numCompleteApplications,
            numTotalApplications
          );

          const leaseStatus = getLeaseStatusText(
            deal.leaseStatus,
            numSignedApplications,
            numTotalApplications
          );

          return {
            key: deal.id,
            rawData: deal,
            values: [
              <Cell>
                <PrimaryText data-testid={`deal-${index}-applicants`}>
                  <ApplicantsCheckList
                    items={applicants}
                    //  @ts-ignore
                    onClick={() => goToDealSummary(deal.id)}
                  />
                </PrimaryText>
              </Cell>,
              <Cell>
                <PrimaryText data-testid={`deal-${index}-applicationDate`}>
                  {formatDate(deal.applicationDate)}
                </PrimaryText>
              </Cell>,
              <Cell>
                <FlexWrapper alignItems="center">
                  <PrimaryText data-testid={`deal-${index}-rent`}>
                    {printDollarsFromCents(Number(deal.rent))}
                  </PrimaryText>
                  {isRentIcon && <Icon.DollarSignIcon />}
                </FlexWrapper>
              </Cell>,
              <Cell>
                <DealStatusColumn
                  actionLink={actionLink}
                  dealUiStatus={dealStatus}
                  status={
                    <>
                      {deal.leaseStatus &&
                      DEAL_UI_STATUS_CODES.cancelled !== deal.dealUiStatus ? (
                        <>
                          {deal.leaseStatus ===
                            LEASE_STATUS_CODES.inProgress && (
                            <LeaseInProgressIcon data-testid="lease-progress-icon" />
                          )}
                          {leaseStatus}
                        </>
                      ) : (
                        dealStatus
                      )}
                    </>
                  }
                  isArchived={isArchived}
                  lastArchivedAt={lastArchivedAt}
                />
              </Cell>,
              <>
                {breakpoint === BREAKPOINT_TYPES.xs && (
                  <DealDetails deal={deal} columnWidth={columnWidth} />
                )}
              </>,
            ],
            viewed: false,
          };
        })}
        onExpand={onExpand}
        getOtherRowEvent={getOtherRowEvent}
        expandedRowKeys={expandedRowKeys}
        expandedRowRender={(record) => getDealDetails(record, columnWidth)}
        expandIconColumnIndex={ExpandIconIndex[breakpoint] || 4}
        expandable={["x"]}
        onChange={onTableChange}
        pagination={{
          current: page,
          onChange: onPaginationChange,
          total: dealsCount,
          defaultPageSize: pageSize,
          hideOnSinglePage: true,
          size: breakpoint === BREAKPOINT_TYPES.xs && "small",
        }}
      />
    </UnitDealsWrapper>
  );
};

export default withSizes(({ width }) => ({ width }))(UnitDeals);
