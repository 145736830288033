import cloneDeep from "lodash/cloneDeep";

import { RenewalOfferStatus } from "shared/config/constants";
import { Offer, RenewalOption } from "shared/interfaces/api/renewals";
import { generateId } from "shared/utils/misc.util";

import { EMPTY_OPTION, FootBarState } from "./constants";

export const getFootBarState = ({
  renewalOfferStatus,
  canCreateRenewalsOffers,
}: {
  renewalOfferStatus: RenewalOfferStatus;
  canCreateRenewalsOffers: boolean;
}): FootBarState => {
  let footBarState: FootBarState = FootBarState.NO_ACTIONS;
  if (canCreateRenewalsOffers) {
    if (renewalOfferStatus === RenewalOfferStatus.IN_PROGRESS) {
      footBarState = FootBarState.IN_PROGRESS;
    } else if (renewalOfferStatus === RenewalOfferStatus.READY_TO_BE_APPROVED) {
      footBarState = FootBarState.WAITING_APPROVAL;
    } else if (renewalOfferStatus === RenewalOfferStatus.APPROVED) {
      footBarState = FootBarState.APPROVED;
    }
  }
  return footBarState;
};

export const getDuplicatedLastOption = (
  offer: Offer
): Partial<RenewalOption> => {
  const options = offer?.renewalOptions || [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, accepted, ...cloneableProperties } = cloneDeep(
    options[options.length - 1] || EMPTY_OPTION
  );
  return {
    ...cloneableProperties,
    guid: generateId("guid-"),
  };
};

const getOfferWithStatus = (
  offerFormData: Offer,
  status: RenewalOfferStatus
) => {
  return {
    ...offerFormData,
    status,
  };
};

export const getApprovedOffer = (offerFormData: Offer) =>
  getOfferWithStatus(offerFormData, RenewalOfferStatus.APPROVED);

export const getWaitingApprovalOffer = (offerFormData: Offer) =>
  getOfferWithStatus(offerFormData, RenewalOfferStatus.READY_TO_BE_APPROVED);
