import React from "react";

import Container from "shared/components/Container";
import Tile from "shared/components/Tile";
import { ApplicantType } from "shared/interfaces";
import OptionItem from "shared/interfaces/api/optionItem";

import { ApplicantName } from "../../Leases/interfaces";

import PersonalInfo from "./PersonalInfo";
import { ApplicantsWrapper, DataRow, Title } from "./styled";

interface ApplicantsInfoProps {
  leaseMembers: ApplicantName[];
  // eslint-disable-next-line react/no-unused-prop-types
  applicantTypes: { [key: number]: OptionItem };
}

const ApplicantsInfo = ({ leaseMembers = [] }: ApplicantsInfoProps) => {
  const applicants = leaseMembers.filter(
    (applicant) => Number(applicant.applicantType) === ApplicantType.APPLICANT
  );

  const guarantors = leaseMembers.filter(
    (applicant) => Number(applicant.applicantType) === ApplicantType.GUARANTOR
  );
  const occupants = leaseMembers.filter(
    (applicant) => Number(applicant.applicantType) === ApplicantType.OCCUPANT
  );

  return (
    <ApplicantsWrapper>
      {Boolean(applicants.length) && (
        <Tile>
          <Tile.Inner>
            <Container noPadding data-testid="applicants-info">
              <Title>Applicants</Title>
              <DataRow>
                {Boolean(applicants.length) &&
                  applicants.map((applicant) => (
                    <PersonalInfo applicant={applicant} key={applicant.id} />
                  ))}
              </DataRow>
            </Container>
          </Tile.Inner>
        </Tile>
      )}
      {Boolean(guarantors.length) && (
        <Tile>
          <Tile.Inner>
            <Container noPadding data-testid="guarantors-info">
              <Title>Guarantors</Title>
              <DataRow>
                {guarantors.map((guarantor) => (
                  <PersonalInfo applicant={guarantor} key={guarantor.id} />
                ))}
              </DataRow>
            </Container>
          </Tile.Inner>
        </Tile>
      )}
      {Boolean(occupants.length) && (
        <Tile>
          <Tile.Inner>
            <Container noPadding data-testid="occupants-info">
              <Title>Occupants</Title>
              <DataRow>
                {occupants.map((occupant) => (
                  <PersonalInfo applicant={occupant} key={occupant.id} />
                ))}
              </DataRow>
            </Container>
          </Tile.Inner>
        </Tile>
      )}
    </ApplicantsWrapper>
  );
};

export default ApplicantsInfo;
