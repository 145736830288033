export const BASE = {
  home: "/",
};

export const AUTH = {
  login: "/login",
  loginSelfSignedDeepLink: "/selfsigned-deeplink/:linkHash",
  loginSSO: "/sso",
  setPassword: "/set-password/:token",
  setPasswordLinkExpired: "/invite-link-expired/:token",
  setPasswordLinkSent: "/invite-link-sent",
  setPasswordLinkAccepted: "/invite-link-accepted",
  passwordRecovery: "/password-recovery",
  passwordRecoveryEmailSent: "/password-recovery/email-sent",
  passwordRecoverySetPassword: "/password-recovery/set-password/:token",
  termsOfService: "/legal/terms-of-service",
  privacyPolicy: "/legal/privacy-policy",
};

export const APPLICATION = {
  application: "/applications/:id",
  applicationRenterProfile: "/applications/:id/renter-profile",
  applicationIncomeAssets: "/applications/:id/income-and-assets-verification",
  applicationCreditBackground: "/applications/:id/credit-and-background-check",
  applicationAttachments: "/applications/:id/attachments",
  applicationVerifications: "/applications/:id/verifications",
  applicationIdVerification: "/applications/:id/id-verification",
  applicationVorVerifications: "/applications/:id/vor-verifications",
  applicationWorkflow: "/applications/:id/workflow",
  applicationPayments: "/applications/:id/payments",
  applicationLumen: "/applications/:id/lumen",
};

export const MAGIC_LINKS = {
  magicLinkLandingPage: "/magiclink/:companyHashId",
  magicLinkPropertiesPage: "/magiclink/:companyHashId/properties",
  magicLinkGetStarted: "/ml/:companyHashId",
  prescreeningMagicLinkGetStarted: "/pml/:companyHashId",
  magicLinkUnavailable: "/ml/:entityName/unavailable",
  magicLinkV2GetStarted: "/apply",
  prescreeningMagicLinkV2GetStarted: "/prequalify",
};

export const MAGIC_PORTAL_LINKS = {
  magicPortal: "/mpl",
  magicPortalCompany: "/mpl/cp/:companyHashId",
  magicPortalCompanyProperty: "/mpl/cp/:companyHashId/pr/:propertyHashId",
  magicPortalProperty: "/mpl/pr/:propertyHashId",
  magicPortalPropertyUnit: "/mpl/pr/:propertyHashId/un/:unitHashId",
};

export const ERROR_ROUTES = {
  notFound: "/not-found",
};

const ROUTES = {
  ...BASE,
  ...AUTH,
  ...APPLICATION,
  ...MAGIC_LINKS,
  ...ERROR_ROUTES,
  ...MAGIC_PORTAL_LINKS,
};

export default ROUTES;
