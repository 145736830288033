import PropTypes from "prop-types";

import { SecurityDepositStatusPropType } from "manager/utils/propTypes";

export const leaseDetailsPropType = PropTypes.shape({
  id: PropTypes.number,
  step: PropTypes.number,
  rent: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  prorateFirstMonthRent: PropTypes.bool.isRequired,
  existsLeasePropertyDocumentTemplatesMismatch: PropTypes.bool.isRequired,
  deal: PropTypes.shape({
    unit: PropTypes.shape({
      id: PropTypes.number,
      property: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    holdingDepositFee: PropTypes.number,
  }),
  securityDepositDeductionStatus: SecurityDepositStatusPropType,
});
