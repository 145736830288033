import _ from 'lodash'

export function someNil (a) {
  return _.chain(a).map(_.isNil).some().value()
}

export function everyNil (a) {
  return _.chain(a).map(_.isNil).every().value()
}

export function buildClassName (classNames) {
  return _.chain(classNames).castArray().flattenDeep().compact().map(c => _.split(c, ' ')).flattenDeep().compact().uniq().join(' ').value()
}
