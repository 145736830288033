import React from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";

import {
  Form,
  FooterBar as StyledFootBar,
} from "manager/components/DealSetupSettings/styled";
import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import FooterBar from "shared/components/FooterBar";
import { PreventLeaveFormModal } from "shared/components/Form";
import Skeleton from "shared/components/Skeleton";
import Spacer from "shared/components/Spacer";

const LeaseSetupForm = ({
  children,
  saveChanges,
  loading,
  leaseSetup,
  ...props
}) => (
  <Container noPadding expand>
    <Formik
      initialValues={leaseSetup}
      enableReinitialize
      onSubmit={saveChanges}
    >
      {({ isSubmitting, dirty, resetForm }) => {
        return (
          <Form data-testid="application-setup-form">
            <Skeleton loading={loading}>
              {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                  ...props,
                  loading,
                });
              })}
            </Skeleton>
            <FooterBar.Spacer />
            <Container noPadding stickToBottom>
              <StyledFootBar>
                <Button
                  id="form-submit"
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                  data-testid="submit-button"
                >
                  Save changes
                </Button>
              </StyledFootBar>
            </Container>
            <PreventLeaveFormModal
              title="Save settings changes?"
              submitForm={() => document.getElementById("form-submit").click()}
              resetForm={resetForm}
              preventLeaveWhen={dirty}
              isSubmitting={isSubmitting}
            >
              <Spacer />
            </PreventLeaveFormModal>
          </Form>
        );
      }}
    </Formik>
  </Container>
);

LeaseSetupForm.propTypes = {
  saveChanges: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  leaseSetup: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

LeaseSetupForm.defaultProps = {
  loading: false,
};

export default LeaseSetupForm;
