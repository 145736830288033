import { uniqBy } from "lodash";

export const formatDocumentsToAssign = (documents) => {
  const documentsToAssign = documents
    .filter((document) => !document.isDefault)
    .map((document) => ({ id: document.id }));
  const ordering = documents.map((document, index) => ({
    id: document.id,
    sortingIdx: index,
  }));

  return {
    documents: documentsToAssign,
    ordering,
  };
};

export const isDocumentSelected = (document, selectedDocuments) =>
  selectedDocuments.some(
    (selectedDocument) => selectedDocument.id === document.id
  );

export const filterDocumentsById = (id, listOfDocuments) =>
  listOfDocuments.filter((document) => document.id !== id);

export const buildDocumentsToPackage = (
  documents,
  additionalDocuments,
  leaseDocuments,
  packageDocuments
) => {
  let finalPackage = leaseDocuments
    .concat(packageDocuments)
    .map((leaseDocument) => {
      const sourceDocument = documents.find(
        (document) => document.id === leaseDocument.id
      );
      if (!sourceDocument) return null;

      return {
        ...leaseDocument,
        ...sourceDocument,
      };
    })
    .filter((document) => Boolean(document));
  if (additionalDocuments) {
    finalPackage = [...finalPackage, ...additionalDocuments];
  }

  finalPackage = uniqBy(finalPackage, "id").sort(
    (a, b) => a.sortingIdx - b.sortingIdx
  );

  return finalPackage;
};

export const showProrationWarning = (lease) => {
  if (!lease?.prorateFirstMonthRent) {
    return false;
  }

  return !lease?.useProratedFields;
};
