import React from "react";

import { Col, Row } from "antd";
import { Gutter } from "antd/lib/grid/row";

import {
  LeaseTimelineDetails,
  Lease,
} from "manager/interfaces/api/leaseGroups";
import { SmallText } from "shared/components/Typography";

import Well from "shared/components/Well";
import { formatDateTime } from "shared/utils/ui";

const colSize = {
  xs: 24,
};

const gutter: [Gutter, Gutter] = [24, 24];

const getTimelineDetails = (lease: Lease): LeaseTimelineDetails => {
  return {
    sent: {
      author: lease?.sentBy?.fullName,
      date: formatDateTime(lease?.sentAt),
    },
    executed: {
      author: lease?.signedBy?.fullName,
      date: formatDateTime(lease?.managerSignedAt),
    },
  };
};

const LeaseGroupTimelineDetails = ({ lease }: { lease: Lease }) => {
  const { sent, executed } = getTimelineDetails(lease);

  return (
    <Row gutter={gutter}>
      <Col {...colSize}>
        <Well noBorder className="lease-timeline-information">
          <Row gutter={gutter}>
            <Col {...colSize}>
              <SmallText>Lease sent: </SmallText>
              <SmallText extraStrong>
                {sent?.date && sent?.author
                  ? `${sent.date} by ${sent.author}`
                  : "-"}
              </SmallText>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col {...colSize}>
              <SmallText>Lease fully executed: </SmallText>
              <SmallText extraStrong>
                {executed?.date && executed?.author
                  ? `${executed.date} by ${executed.author}`
                  : "-"}
              </SmallText>
            </Col>
          </Row>
        </Well>
      </Col>
    </Row>
  );
};

export default LeaseGroupTimelineDetails;
