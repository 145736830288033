import React, { useEffect } from "react";

import { useRenewalHelpers } from "manager/components/Renewals";
import { useCreateRenewalNote, useGetRenewalNotes } from "manager/hooks/api";
import { LeaseGroup } from "manager/interfaces/api/leaseGroups";

import { showLoaderFn } from "../utils";

import LeaseGroupNotes from "./LeaseGroupNotes";

const LeaseGroupNotesContainer = ({
  leaseGroup,
  showLoader,
}: {
  leaseGroup: LeaseGroup;
  showLoader: showLoaderFn;
}) => {
  const { renewalHelpers } = useRenewalHelpers({
    renewal: leaseGroup?.renewal,
  });
  const { renewalNotes, isRenewalNotesLoading } = useGetRenewalNotes(
    leaseGroup?.renewal?.id,
    { enabled: Boolean(leaseGroup?.renewal?.id) && renewalHelpers.canReadNotes }
  );
  const { createRenewalNote, isCreatingNote } = useCreateRenewalNote();

  const saveLeaseNote = ({ message }) =>
    createRenewalNote({ note: message, id: leaseGroup?.renewal?.id });

  const isNotesLoading = isRenewalNotesLoading || isCreatingNote;

  useEffect(() => {
    showLoader({
      isNotesLoading,
    });
  }, [isNotesLoading]);

  return (
    <LeaseGroupNotes
      saveLeaseNote={saveLeaseNote}
      notes={renewalNotes}
      disabled={!renewalHelpers.canReadNotes}
    />
  );
};

export default LeaseGroupNotesContainer;
