import dayjs from "dayjs";

import {
  COLORS,
  LeaseApplicationStatus,
  LEASE_STATUS_CODES,
  RenewalOptionResponseStatus,
} from "shared/config/constants";

import { FormattedLease } from "./interfaces";

export const formatLease = ({
  id: leaseApplicationId,
  lease,
  status,
  application,
  renewalOffer,
  renewalResponse,
}): FormattedLease => ({
  leaseApplicationId,
  id: lease?.id,
  applicationId: application?.id,
  dealId: lease?.deal?.id,
  applicants: lease?.deal?.applicantNames,
  propertyName: lease?.deal?.unit?.property?.name,
  unitName: lease?.deal?.unit?.name,
  propertyAddress: lease?.deal?.unit?.property?.address,
  startDate: lease?.startDate,
  endDate: lease?.endDate,
  rent: lease?.rent,
  leaseStatus: lease?.status,
  isPastLease: dayjs().isAfter(lease?.endDate, "day"),
  renewalOffer,
  renewalResponse,
  status,
});

export const getLeaseActionState = (
  lease: FormattedLease
): { title: string; disabled: boolean; additionalAction?: boolean } => {
  let leaseActionState;

  if (lease.leaseStatus === LEASE_STATUS_CODES.managerSigned) {
    leaseActionState = {
      title: "Countersigned",
      disabled: false,
      additionalAction: true,
    };
  } else if (
    lease.leaseStatus === LEASE_STATUS_CODES.leaseHandledOffline ||
    lease.leaseStatus === LEASE_STATUS_CODES.leaseExecutedOffline
  ) {
    leaseActionState = { title: "View the lease", disabled: false };
  } else {
    switch (lease.status) {
      case LeaseApplicationStatus.signed:
        leaseActionState = { title: "Signed by me", disabled: true };
        break;
      case LeaseApplicationStatus.processingSignature:
        leaseActionState = { title: "Processing signature", disabled: true };
        break;
      default:
        leaseActionState = { title: "Sign the lease", disabled: false };
        break;
    }
  }
  return leaseActionState;
};

export const getLeaseAdditionalDescriptionStatus = (
  lease: FormattedLease
): { label: string; statusColor: string } => {
  const renewalOffer = lease?.renewalOffer;
  const response =
    renewalOffer?.applicantSelectedOption || renewalOffer?.othersSelectedOption;

  let result = {
    label: "Current Lease",
    statusColor: COLORS.lightGreen2,
  };

  if (lease.isPastLease) {
    result = {
      label: "Past Lease",
      statusColor: COLORS.gray1,
    };
  } else if (response) {
    if (response.response === RenewalOptionResponseStatus.ACCEPTED) {
      result = {
        label: "Offer Accepted",
        statusColor: COLORS.lightGreen2,
      };
    } else if (response.response === RenewalOptionResponseStatus.NOT_ACCEPTED) {
      result = {
        label: "Moving Out",
        statusColor: COLORS.lightRed2,
      };
    }
  } else if (lease.renewalOffer?.renewalOptions?.length > 0) {
    result = {
      label: "Offer Available",
      statusColor: COLORS.violetTheme.lightViolet,
    };
  }

  return result;
};
