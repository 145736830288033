export const LEASE_CATEGORIES = [
  {
    key: "all",
    apiKey: "",
    name: "All",
  },
  {
    key: "inProgress",
    apiKey: "in_progress",
    name: "Incomplete",
  },
  {
    key: "active",
    apiKey: "active",
    name: "Pending",
  },
  {
    key: "expired",
    apiKey: "expired",
    name: "Archived",
  },
];

const ORDER_BY_NAMES = Object.freeze({
  unitName: "deal__unit_listing__unit__name",
  propertyName: "deal__unit_listing__unit__property__name",
  startDate: "start_date",
  endDate: "end_date",
  rent: "rent",
});

export const ORDER_BY = Object.freeze({
  unit: {
    ascend: [ORDER_BY_NAMES.propertyName, ORDER_BY_NAMES.unitName].join(),
    descend: [ORDER_BY_NAMES.propertyName, ORDER_BY_NAMES.unitName]
      .map((name) => `-${name}`)
      .join(),
  },
  leaseDate: {
    ascend: [ORDER_BY_NAMES.startDate, ORDER_BY_NAMES.endDate].join(),
    descend: [ORDER_BY_NAMES.startDate, ORDER_BY_NAMES.endDate]
      .map((name) => `-${name}`)
      .join(),
  },
  rent: {
    ascend: ORDER_BY_NAMES.rent,
    descend: `-${ORDER_BY_NAMES.rent}`,
  },
});

export const blankFilters = {
  dateMin: null,
  dateMax: null,
  propertyId: [],
  unitPropertyOrApplicant: "",
  leaseStatus: [],
};

export const KEYS = Object.freeze({
  filters: "filters",
  category: "category",
  pagination: "pagination",
  ordering: "ordering",
});

export const LEASES_FILTERS_STORAGE = "LEASES_FILTERS_STORAGE";
