import React from "react";

import Viewer from "react-file-viewer";

import { StyledPreviewPdfModal, StyledPreviewPdfModalBody } from "./styled";

export interface IPreviewPDFModal {
  closable?: boolean;
  closeOnSubmit?: boolean;
  fileUrl: string;
  showSubmitButton?: boolean;
  submit?: () => void;
  submitButtonLabel?: string;
  subtitle?: string;
  title?: string;
}

const PreviewPDFModal = ({
  fileUrl,
  title = "Preview Document",
  subtitle = undefined,
  submitButtonLabel = "Close",
  submit,
  showSubmitButton = true,
  closable = true,
  closeOnSubmit = true,
}: IPreviewPDFModal) => {
  return (
    <StyledPreviewPdfModal
      title={title}
      subtitle={subtitle}
      submitButtonLabel={submitButtonLabel}
      submit={submit}
      showSubmitButton={showSubmitButton}
      cancelLinkLabel="View & Download"
      onCancelLinkClick={() => window.open(fileUrl, "_blank").focus()}
      closable={closable}
      closeOnSubmit={closeOnSubmit}
    >
      <StyledPreviewPdfModalBody noMobilePaddingX>
        <Viewer filePath={fileUrl} fileType="pdf" />
      </StyledPreviewPdfModalBody>
    </StyledPreviewPdfModal>
  );
};

export default PreviewPDFModal;
