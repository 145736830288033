// @ts-nocheck
import { useEffect, useState } from "react";

import { datadogRum } from "@datadog/browser-rum";
import jwt from "jsonwebtoken";
import isString from "lodash/isString";
import kebabCase from "lodash/kebabCase";
import moment from "moment";
import { queryCache } from "react-query";
import { matchPath, useParams } from "react-router-dom";
import { filter, map, Subject } from "rxjs";

import { SSO_PROVIDERS_VIEW_CONFIG } from "shared/config/authConstants";
import { APPS, LOCAL_STORAGE, NOTIFICATIONS } from "shared/config/constants";
import ROUTES, {
  AUTH as AUTH_ROUTES,
  MAGIC_LINKS,
  ERROR_ROUTES,
  MAGIC_PORTAL_LINKS,
} from "shared/config/routes";
import api from "shared/lib/api/authApi";
import { ApiError, handleError } from "shared/lib/errors";
import { identifyFullStorySession } from "shared/utils/analytics";
import { encodeObjectToBase64, toCamelCaseKeys } from "shared/utils/misc.util";
import { getQueryParams } from "shared/utils/routing";

import { useManageCompany } from "./company";
import { LocalStorageCollectionManager } from "./localStorage";
import { ModalManager } from "./modalmanager";
import { openNotification } from "./ui";

const RENTER_LIKE_APPS = [APPS.renter, APPS.leads];

// Companies

export type CompanyToken = {
  id: number;
  name: string;
};

export const company$ = new Subject<CompanyToken>();

export const cacheActiveCompany = (company: CompanyToken) => {
  localStorage.setItem(LOCAL_STORAGE.activeCompany, JSON.stringify(company));
  company$.next(company);
};

export const getCachedActiveCompany = (): CompanyToken | null => {
  const token = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE.activeCompany) ?? null
  ) as CompanyToken;
  if (token) {
    return token;
  }
  const companyId = localStorage.getItem("activeCompanyId");
  if (companyId) {
    return { id: Number(companyId), name: "" };
  }
  return null;
};

export const getCachedActiveCompanyId = () =>
  getCachedActiveCompany()?.id ?? null;

export const clearCachedActiveCompany = () => {
  localStorage.removeItem(LOCAL_STORAGE.activeCompany);
  localStorage.removeItem("activeCompanyId");
};

export const getCompanySlugFromDomain = () => {
  const host = window.location.hostname;
  const slug = host.split(".").shift();

  if (
    /^([^.]+)\.((staging|test|demo)\.)?(manager|admin)\.(vero\.lease)$/.test(host) &&
    slug !== process.env.NODE_ENV
  ) {
    return kebabCase(slug);
  }

  return null;
};

export const getCachedActiveCompanySlug = () => {
  return getCompanySlugFromDomain() || kebabCase(getCachedActiveCompany()?.name)
};

const matchHost = (key) => {
  const host = window.location.hostname;

  return (
    host === process.env[`REACT_APP_${key.toUpperCase()}_HOST`] ||
    host.endsWith(`${key}.${process.env.REACT_APP_ROOT_DOMAIN}`) ||
    host.endsWith(`${key}.vero.lease`)
  );
};

const getActiveAppFromHost = () => {
  if (matchHost("admin")) {
    return APPS.admin;
  }

  if (matchHost("manager")) {
    return APPS.manager;
  }

  if (matchHost("renter")) {
    return APPS.renter;
  }

  if (matchHost("leads")) {
    return APPS.leads;
  }

  if (matchHost("surveys")) {
    return APPS.surveys;
  }

  return undefined;
};

const getActiveAppFromPort = () => {
  const { port } = window.location;

  switch (port) {
    case "3000":
      return APPS.admin;
    case "3001":
      return APPS.manager;
    case "3002":
      return APPS.renter;
    case "3003":
      return APPS.leads;
    case "3005":
      return APPS.surveys;
  }

  return undefined;
};

export const getActiveApp = () => {
  return getActiveAppFromPort() || getActiveAppFromHost();
};

export const isAdminApp = () => getActiveApp() === APPS.admin;
export const isManagerApp = () => getActiveApp() === APPS.manager;
export const isRenterApp = () => getActiveApp() === APPS.renter;
export const isLeadsApp = () => getActiveApp() === APPS.leads;
export const isSurveysApp = () => getActiveApp() === APPS.surveys;
export const isRenterLikeApp = () => {
  const activeApp = getActiveApp();
  return RENTER_LIKE_APPS.includes(activeApp);
};

// JWT

export const decodeJwt = (token): Token | undefined => {
  let formattedPayload;
  const decodedToken = jwt.decode(token, { complete: true });

  if (decodedToken) {
    const { payload } = decodedToken;
    const camelcasedPayload = toCamelCaseKeys(payload);
    const { userId } = camelcasedPayload;
    formattedPayload = {
      ...camelcasedPayload,
      userId: Number(userId),
    };
  }

  return formattedPayload;
};
const JWT_TOKEN = "jwt_auth_token";
const JWT_ID_TOKEN = "jwt_id_token";
const JWT_ACCESS_TOKEN = "jwt_access_token";
const JWT_REFRESH_TOKEN = "jwt_refresh_token";

const jwtStorage = new LocalStorageCollectionManager({
  storageKey: LOCAL_STORAGE.jwtToken,
});

export const setJwt = (tokens) => {
  tokens = isString(tokens)
    ? {
        token: tokens,
        idToken: tokens,
        accessToken: tokens,
        refreshToken: tokens,
      }
    : tokens;

  jwtStorage.addItem(JWT_TOKEN, tokens?.token);
  jwtStorage.addItem(JWT_ID_TOKEN, tokens?.idToken);
  jwtStorage.addItem(JWT_ACCESS_TOKEN, tokens?.accessToken);
  jwtStorage.addItem(JWT_REFRESH_TOKEN, tokens?.refreshToken);
};

export const jwt$ = jwtStorage.addItem$.pipe(
  filter(({ key }) => key === JWT_ID_TOKEN),
  map(({ value }) => decodeJwt(value))
);

export const clearJwt = () => {
  jwtStorage.removeItem(JWT_TOKEN);
  jwtStorage.removeItem(JWT_ID_TOKEN);
  jwtStorage.removeItem(JWT_ACCESS_TOKEN);
  jwtStorage.removeItem(JWT_REFRESH_TOKEN);
};

export type Token = {
  userId?: number;
  givenName?: string;
  familyName?: string;
  email?: string;
  username?: string;
  sub?: string;
  exp: number;
  iat?: number;
  roleId?: number;
  role?: string;
  createdAt?: number;
  origIat?: number;
};

export const getIdToken = () =>
  jwtStorage.getItem(JWT_ID_TOKEN) || jwtStorage.getItem(JWT_TOKEN);

export const getAccessToken = () =>
  jwtStorage.getItem(JWT_ID_TOKEN) || jwtStorage.getItem(JWT_TOKEN);
// we will use the raw cognito access_token until we can customize the scope appropriately
// or verified permissions offers another solution
// jwtStorage.getItem(JWT_ACCESS_TOKEN) || jwtStorage.getItem(JWT_TOKEN);

export const getRefreshToken = () => jwtStorage.getItem(JWT_REFRESH_TOKEN);
export const getClaims = () => decodeJwt(getIdToken());

export const isExpired = (token) => {
  if (token) {
    try {
      const { exp = undefined } = decodeJwt(token);

      if (exp) {
        return moment().isAfter(exp * 1000); // JWT exp is defined in seconds
      }
    } catch (error) {
      return true;
    }
  }

  return true;
};

export const getBaseAppUrl = () => {
  const { protocol, port, hostname } = window.location;
  const protocolPart = `${protocol}//`;
  const hostnamePart = port ? `${hostname}:${port}` : hostname;
  return `${protocolPart}${hostnamePart}`;
};

// SSO
export const getSSORedirectUri = () => {
  return `${getBaseAppUrl()}${ROUTES.loginSSO}`;
};

const ssoLogoutUrlStorage = new LocalStorageCollectionManager({
  storageKey: LOCAL_STORAGE.ssoLogoutUrl,
});

const SSO_LOGOUT_URL_TOKEN = "sso_logout_url_token";

export const setSSOLogoutUrl = (url) => {
  ssoLogoutUrlStorage.addItem(SSO_LOGOUT_URL_TOKEN, url);
};

export const getSSOLogoutUrl = () => {
  return ssoLogoutUrlStorage.getItem(SSO_LOGOUT_URL_TOKEN);
};

export const clearSSOLogoutUrl = () => {
  ssoLogoutUrlStorage.removeItem(SSO_LOGOUT_URL_TOKEN);
};

export const composeSSOLogoutUrl = (baseLogoutUrl) => {
  const hasQuery = baseLogoutUrl.indexOf("?") > 0;
  return `${baseLogoutUrl}${hasQuery ? "&" : "?"}logout_uri=${getBaseAppUrl()}${
    ROUTES.login
  }`;
};

export const useGetSSOState = ({ name, type, baseUrl, logoutUrl }) => {
  // @ts-ignore
  const { token } = useParams();
  const queryParams = getQueryParams();

  const providerViewConfig = SSO_PROVIDERS_VIEW_CONFIG[name];

  // NOTE: Adds the IDP type, invitation token and the query params to the state object which will be transferred back to the bounce page.
  const stateObject = {
    name,
    type,
    token,
    logoutUrl,
    ...queryParams,
  };
  const state = encodeObjectToBase64(stateObject);
  const loginUrl = `${baseUrl}&redirect_uri=${getSSORedirectUri()}&state=${state}`;

  return { providerViewConfig, loginUrl };
};

// Login error messages
export const extractLoginErrorMessage = (
  error,
  errorCodesAllowed,
  defaultErrorMessage,
  errorDescriptions
) => {
  let message = defaultErrorMessage || "Something went wrong.";
  let description;
  const isApiError = error instanceof ApiError;

  if (isApiError) {
    const rawErrorMessage = error.errors?.nonFieldErrors?.[0];
    if (rawErrorMessage) {
      const [code, errorMessage] = rawErrorMessage.split(":");
      if (errorCodesAllowed.includes(code)) {
        message = errorMessage;
        description = errorDescriptions && errorDescriptions[code];
      }
    }
  }

  return { message, description };
};

// Logout

export const logoutOfAllTabs = () =>
  window.localStorage.setItem(LOCAL_STORAGE.logout, String(Date.now()));

export const watchLogout = (logout) => {
  window.addEventListener("storage", (event) => {
    if (event.key === LOCAL_STORAGE.logout && !!getAccessToken()) {
      logout();
    }
  });
};

export const clearQueryCache = () => {
  queryCache.clear();
};

interface LogoutaParams {
  sessionExpired?: boolean;
  redirectAfterLogin?: boolean;
}

export const logout = async (params?: LogoutaParams) => {
  const { sessionExpired, redirectAfterLogin } = params ?? {};
  try {
    const jwt = getAccessToken();
    if (jwt && !sessionExpired) {
      await api.logout();
    }

    clearJwt();
    logoutOfAllTabs();
    clearQueryCache();
    clearCachedActiveCompany();
    ModalManager.closeAll();

    const ssoLogoutUrl = getSSOLogoutUrl();

    if (ssoLogoutUrl) {
      // NOTE: If SSO logout url is present we need to redirect there to clear the Cognito cookies from the browser
      // ATM, we support sso logout only for the IDP that are supported by Cognito.
      clearSSOLogoutUrl();
      window.location.href = ssoLogoutUrl;
    } else if (sessionExpired) {
      const notificationMessage = isRenterLikeApp()
        ? "You have been logged out due inactivity. Log back in an apply for your dream home!"
        : "Looks like it has been a little while! We logged you out for security purposes.";
      openNotification(notificationMessage, NOTIFICATIONS.info);
    } else {
      window.location.href = `${window.location.origin}${ROUTES.login}?r=${window.location.pathname}`;
    }
  } catch (error) {
    openNotification("Failed to log out.", NOTIFICATIONS.error);
  }
};

// Expire session

export const expireSession = () => {
  const jwt = getAccessToken();
  const loggedIn = jwt != null;
  if (loggedIn) {
    logout({ sessionExpired: true, redirectAfterLogin: true });
  }
};

export const useClaims = () => {
  const [claims, setClaims] = useState<Token>(getClaims());
  const [company, setCompany] = useState<CompanyToken>(
    getCachedActiveCompany()
  );
  useEffect(() => {
    const sub = jwt$.subscribe((token) => {
      setClaims(token);
    });

    const sub2 = company$.subscribe((company) => {
      setCompany(company);
    });

    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
    };
  }, []);

  return { claims, company };
};

/**
 * Acts like an initial call the app makes to the BE and loads the active user to store it for
 * further use. Many components rely on the active user, so this need to happen right after
 * login, or on `initApp` if we are already logged in.
 *
 * If JWT is not present (user is not logged in) during the app initialization, the user
 * will be redirected to the login screen.
 *
 * Errors thrown here are handled using the `handleError` handler.
 */
export const useLoadActiveUser = () => {
  const { manageCompany } = useManageCompany();

  const loadActiveUser = async () => {
    try {
      const claims = getClaims();
      if (claims) {
        const { userId } = claims;
        const isRenter = isRenterLikeApp();
        const isManager = isManagerApp();
        const userProfileResponse = await (isRenter
          ? api.getApplicantUserProfile()
          : api.getUserProfile());

        // FS Session Identification
        const { email, firstName, lastName } = userProfileResponse;
        const env = process.env.REACT_APP_ENVIRONMENT;
        const companyId = getCachedActiveCompanyId();

        identifyFullStorySession({
          userId: userProfileResponse?.id || userId,
          email,
          firstName,
          lastName,
          companyId,
          env,
        });

        datadogRum.setUser({
          id: `${userProfileResponse?.id || userId}`,
          name: `${firstName} ${lastName}`,
          email,
          companySlug: getCachedActiveCompanySlug(),
        });

        if (isManager) {
          manageCompany();
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  return { loadActiveUser };
};
