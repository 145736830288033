import styled from "@emotion/styled";

import BaseCheckList from "shared/components/ApplicantsCheckList";

const Subtitle = styled("span")({
  display: "flex",
  flexWrap: "wrap",
  gap: 8,
});

const ApplicantsCheckList = styled(BaseCheckList)({
  flex: 1,
});

export { Subtitle, ApplicantsCheckList };
