const formatPortfolios = (portfolios) => {
  const items = [];
  const checked = [];
  const indeterminate = [];

  if (portfolios) {
    portfolios.forEach((portfolio) => {
      const portfolioId = String(portfolio.id);
      const properties = portfolio.properties || [];

      const propertiesCount = properties.length;
      const assignedPropertiesCount = properties.filter(
        (property) => property.isAssigned
      ).length;

      items.push({
        key: portfolioId,
        label: portfolio.name,
        collapsible: propertiesCount > 0,
        disabled: propertiesCount === 0,
      });

      if (assignedPropertiesCount === propertiesCount && propertiesCount > 0) {
        checked.push(portfolioId);
      }
      if (
        assignedPropertiesCount !== propertiesCount &&
        assignedPropertiesCount !== 0
      ) {
        indeterminate.push(portfolioId);
      }

      properties.forEach((property) => {
        const propertyNewId = `${portfolio.id}_${property.id}`;

        items.push({
          key: propertyNewId,
          label: property.name,
          description: `${property.address}, ${property.city}, ${property.state}, ${property.zipcode}`,
          parent: portfolioId,
          popover: property.participatesInDeal && {
            content:
              "The document participates in the active application for this property. ",
          },
        });

        if (property.isAssigned) {
          checked.push(propertyNewId);
        }
      });
    });
  }

  return { items, checked, indeterminate };
};

const getSelectedProperties = (portfolios, checkedItems) => {
  return portfolios?.reduce((accumulator, portfolio) => {
    const properties = portfolio.properties || [];

    const selectedPropertiesCount = properties.filter(({ id }) =>
      checkedItems.includes(`${portfolio.id}_${id}`)
    ).length;

    if (selectedPropertiesCount) {
      accumulator.push({
        key: portfolio.id,
        label: portfolio.name,
        selected: selectedPropertiesCount,
        total: properties.length,
      });
    }
    return accumulator;
  }, []);
};

const sortPortfoliosForAssignment = (portfolios, checkedItems) => {
  const propertiesToAssign = [];
  const propertiesToUnassign = [];

  portfolios?.forEach((portfolio) => {
    const properties = portfolio.properties || [];
    properties.forEach((property) => {
      if (
        property.isAssigned &&
        !checkedItems.includes(`${portfolio.id}_${property.id}`)
      ) {
        propertiesToUnassign.push(Number(property.id));
      }
      if (
        !property.isAssigned &&
        checkedItems.includes(`${portfolio.id}_${property.id}`)
      ) {
        propertiesToAssign.push(Number(property.id));
      }
    });
  });

  return { propertiesToAssign, propertiesToUnassign };
};

const isParticipatesInDealProperties = (portfolios, propertiesToUnassign) => {
  const participatesInDealProperties = [];

  portfolios?.forEach((portfolio) => {
    const properties = portfolio.properties || [];
    properties.forEach((property) => {
      if (
        property.participatesInDeal &&
        propertiesToUnassign.some((propertyId) => propertyId === property.id)
      ) {
        participatesInDealProperties.push(Number(property.id));
      }
    });
  });

  return Boolean(participatesInDealProperties.length);
};

export {
  formatPortfolios,
  getSelectedProperties,
  sortPortfoliosForAssignment,
  isParticipatesInDealProperties,
};
