import { useEffect } from "react";

import { useFormikContext } from "formik";

import { CONCESSION_TYPES } from "shared/config/constants";

import { LeaseInfoFormValues } from "./interfaces";

const CONCESSION_TYPES_ID = "concession-types";
const CONCESSION_AMORTIZED_ID = "concession-amortized";
const CONCESSION_MONTHS_ID = "concession-months";

const useFormNavigation = () => {
  const {
    values: { isConcessionsEnabled, concessionType },
  } = useFormikContext<LeaseInfoFormValues>();

  useEffect(() => {
    let hash = "";

    if (isConcessionsEnabled) {
      switch (concessionType) {
        case CONCESSION_TYPES.amortize: {
          hash = `#${CONCESSION_AMORTIZED_ID}`;
          break;
        }
        case CONCESSION_TYPES.specificMonths: {
          hash = `#${CONCESSION_MONTHS_ID}`;
          break;
        }
        default: {
          hash = `#${CONCESSION_TYPES_ID}`;
        }
      }
    }

    window.location.hash = hash;
  }, [isConcessionsEnabled, concessionType]);
};

export default useFormNavigation;

export { CONCESSION_TYPES_ID, CONCESSION_MONTHS_ID, CONCESSION_AMORTIZED_ID };
