import { useRef, useState } from "react";

import mapValues from "lodash/mapValues";

import maxBy from "lodash/maxBy";
import throttle from "lodash/throttle";
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";

import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import {
  arrayBufferToBase64,
  downloadPDF,
  sanitizeFileName,
} from "shared/utils/pdf";
import { insertScripts } from "shared/utils/ui";

import { ViewerApi } from "../types";

import {
  INTERNAL_PATH,
  INTERNAL_WORKER_PATH,
  PDF_JS_DEPENDENCIES,
  SCALE_STEP,
  SCROLL_THROTTLE,
} from "./pdfjs.config";

const RENDER_ABORTED_ERROR = "COMPONENT_UNMOUNTED";

type PdfViewerRef = {
  GlobalWorkerOptions: { workerSrc: string };
  getDocument: (data: any) => any;
};

type PdfJsApi = ViewerApi & {
  loadDocument: ({
    base64,
    url,
    filename,
  }: {
    base64: string;
    url: string;
    filename: string;
  }) => Promise<void>;
  scrollToNextPage: () => void;
  scrollToPrevPage: () => void;
};

export const usePdfJs = (): PdfJsApi => {
  const pdfViewerRef = useRef<PdfViewerRef>();
  const nodeInnerRef = useRef<HTMLDivElement>();
  const nodeOuterRef = useRef<HTMLDivElement>();
  const nodeScrollRef = useRef<HTMLDivElement>();
  const documentViewerRef = useRef<PDFDocumentProxy>();
  const documentName = useRef<string>();
  const zoomRef = useRef({});
  const isUnmountedRef = useRef(false);
  const isRenderingRef = useRef(false);

  const [isInitialized, setInitialized] = useState(false);
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isDocumentDownloading, setDocumentDownloading] = useState(false);
  const [containerSize, setContainerSize] = useState({});

  const onScroll = throttle((event) => {
    // @ts-ignore
    const pages = [...nodeInnerRef.current?.querySelectorAll("canvas")];
    const visiblePage = pages.find((page) => {
      return (
        page.offsetTop + Math.round(0.75 * page.height) >=
        event.target.scrollTop
      );
    });

    const pageNumber =
      visiblePage?.dataset?.page && Number(visiblePage?.dataset?.page);

    if (pageNumber) {
      setCurrentPage(pageNumber);
    }
  }, SCROLL_THROTTLE);

  const calculateBoxSize = () => {
    const nodes = nodeInnerRef.current?.querySelectorAll("canvas") || [];
    const outerHeight = nodeOuterRef.current?.offsetHeight;

    const width = maxBy(nodes, "width")?.width;
    setContainerSize({
      width,
      height: outerHeight,
    });
  };

  const scrollTo = (num: number, smooth = false) => {
    if (isUnmountedRef.current) return;

    if (num >= 1 && num <= totalPages) {
      const pageCanvas: HTMLElement = nodeInnerRef.current.querySelector(
        `#page-${num}`
      );
      nodeScrollRef.current.scroll({
        top: pageCanvas.offsetTop,
        behavior: smooth ? "smooth" : undefined,
      });
    }
  };

  const setInitialScale = (page: PDFPageProxy, index: number) => {
    let scale = 1;

    const width = nodeInnerRef.current.offsetWidth;

    if (width < BREAKPOINT_RESOLUTIONS.sm) {
      scale = width / page.getViewport({ scale: 1 }).width;
    }

    zoomRef.current[index] = scale;
  };

  const renderPage = async (num) => {
    const canvas = document.createElement("canvas");
    canvas.id = `page-${num}`;
    canvas.setAttribute("data-page", num);
    const page = await documentViewerRef.current.getPage(num);

    if (isUnmountedRef.current) {
      throw new Error(RENDER_ABORTED_ERROR);
    }

    if (!zoomRef.current[num]) {
      setInitialScale(page, num);
    }

    const viewport = page.getViewport({ scale: zoomRef.current[num] });
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const renderContext = {
      canvasContext: context,
      viewport,
    };

    nodeInnerRef.current.appendChild(canvas);
    const renderTask = page.render(renderContext);
    await renderTask.promise;
  };

  const renderAll = async (initialRender = false) => {
    let isSuccessfullyRendered = false;
    isRenderingRef.current = true;

    try {
      const savedPage = currentPage;
      const nodes = nodeInnerRef.current?.querySelectorAll("canvas") ?? [];

      // eslint-disable-next-line func-names
      Array.prototype.forEach.call(nodes, function (node) {
        node.parentNode.removeChild(node);
      });

      for (let i = 1; i <= documentViewerRef.current.numPages; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await renderPage(i);
      }

      if (isUnmountedRef.current) {
        throw new Error(RENDER_ABORTED_ERROR);
      }

      calculateBoxSize();

      if (!initialRender) {
        scrollTo(savedPage);
      }

      isSuccessfullyRendered = true;
      return isSuccessfullyRendered;
    } catch (error) {
      if (error.message !== RENDER_ABORTED_ERROR) {
        throw error;
      }
      return isSuccessfullyRendered;
    } finally {
      isRenderingRef.current = false;
    }
  };

  const initialize = async () => {
    await insertScripts(PDF_JS_DEPENDENCIES);

    if (isUnmountedRef.current) return;

    pdfViewerRef.current = window[INTERNAL_PATH];
    if (pdfViewerRef.current) {
      pdfViewerRef.current.GlobalWorkerOptions.workerSrc = INTERNAL_WORKER_PATH;
    }

    nodeScrollRef.current.addEventListener("scroll", onScroll);

    setInitialized(true);
  };

  const cleanUp = () => {
    nodeScrollRef.current.removeEventListener("scroll", onScroll);

    isUnmountedRef.current = true;
  };

  const scrollToNextPage = () => {
    const next = currentPage + 1;

    if (next <= totalPages) {
      scrollTo(next, true);
    }
  };

  const scrollToPrevPage = () => {
    const next = currentPage - 1;

    if (next >= 1) {
      scrollTo(next, true);
    }
  };

  const loadDocument = async ({
    base64,
    url,
    filename = "document.pdf",
  }: {
    base64: string;
    url: string;
    filename: string;
  }) => {
    const data = url || { data: base64 && atob(base64) };

    try {
      const documentTask = pdfViewerRef.current?.getDocument(data);
      documentViewerRef.current = await documentTask.promise;

      if (isUnmountedRef.current) return;

      documentName.current = filename;
      setTotalPages(documentViewerRef.current.numPages);
      const isRendered = await renderAll(true);

      if (isRendered) {
        setDocumentLoaded(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Unable to load pdf:", error);
    }
  };

  const downloadDocument = async (onDownload = null) => {
    setDocumentDownloading(true);
    const file = await documentViewerRef.current.getData();
    const fileContent = arrayBufferToBase64(file);
    const fileName = sanitizeFileName({
      fileName: documentName.current,
      extension: ".pdf",
    });

    downloadPDF(fileContent, fileName);
    if (onDownload) {
      onDownload();
    }

    if (isUnmountedRef.current) return;

    setDocumentDownloading(false);
  };

  const zoomIn = async () => {
    zoomRef.current = mapValues(zoomRef.current, (scale) => {
      const newScale = scale + SCALE_STEP;
      return newScale > 0 ? newScale : 0;
    });
    await renderAll();
  };

  const zoomOut = async () => {
    zoomRef.current = mapValues(zoomRef.current, (scale) => {
      const newScale = scale - SCALE_STEP;
      return newScale > 0 ? newScale : 0;
    });
    await renderAll();
  };

  const zoomTo = async (newScale) => {
    zoomRef.current = mapValues(zoomRef.current, () => newScale);
    await renderAll();
  };

  const setPage = (num) => scrollTo(num);

  return {
    initialize,
    nodeInnerRef,
    nodeScrollRef,
    nodeOuterRef,
    loadDocument,
    isInitialized,
    isDocumentLoaded,
    setPage,
    currentPage,
    totalPages,
    isDocumentDownloading,
    downloadDocument,
    scrollToNextPage,
    scrollToPrevPage,
    containerSize,
    zoomIn,
    zoomOut,
    zoomTo,
    cleanUp,
  };
};
