import styled from "@emotion/styled";
import { Radio } from "antd";

import List from "shared/components/List";
import { COLORS } from "shared/config/constants";

export const Title = styled.span`
  color: ${COLORS.textColorPrimary};
  font-size: 17px;
  font-weight: 500;
`;

export const ListItem = styled(List.Item)<{ selected?: boolean }>`
  color: ${COLORS.textColorPrimary};
  ${({ selected }) =>
    selected &&
    `
      background-color: ${COLORS.lightGreen}
    `}
`;

export const RadioOption = styled(Radio)`
  color: ${COLORS.textColorPrimary};
`;
