import React from "react";

import {
  CollapsibleCheckboxList,
  SearchableList,
} from "shared/components/Form";
import Icon from "shared/components/Icon";
import { getIndeterminateItems } from "shared/utils/checkbox";

import { SelectPropertiesProps } from "./interfaces";
import { SelectPropertiesContainer, WarningContainer, Text } from "./styled";

const SelectPropertiesFormShared = ({
  portfolios = [],
  portfoliosFetching,
  checkedItems = [],
  indeterminatePortfolios = [],
  searchingValue = "",
  setCheckedItems,
  setIndeterminatePortfolios,
  setSearchingValue,
  showWarning,
}: SelectPropertiesProps) => (
  <SelectPropertiesContainer>
    <SearchableList
      loading={portfoliosFetching}
      search={{
        fieldName: "search",
        label: "Property, address or portfolio",
        action: (value) => setSearchingValue(value),
        width: "350px",
        debounce: true,
        // @ts-ignore
        disabled: portfoliosFetching,
      }}
      List={CollapsibleCheckboxList}
      listContainerProps={{
        checkedItems,
        indeterminateItems: indeterminatePortfolios,
        checkedItemsOnChange: (items) => {
          setCheckedItems(items);
          setIndeterminatePortfolios(getIndeterminateItems(portfolios, items));
        },
      }}
      listProps={{
        col1Width: "350px",
        items: portfolios,
      }}
      placeholder={{
        label: "Portfolios",
        Icon: Icon.NoPortfoliosIcon,
      }}
      hasInputValue={!!searchingValue}
    />
    {showWarning && (
      <WarningContainer>
        <Icon.NotVerifiedIncomeAssetsIcon />
        <Text>
          Removing the document from a property is not retroactive. Any lease
          packages currently in progress will have access to this document.
        </Text>
      </WarningContainer>
    )}
  </SelectPropertiesContainer>
);

export default SelectPropertiesFormShared;
