import React from "react";

import { Formik, Form, FieldArray } from "formik";

import SetupDocumentsFields from "manager/components/Form/SetupDocumentsFields";
import { Modal } from "shared/components/Modal";

import { PropertySetupDocumentFieldsModalProps } from "./interfaces";
import { Title } from "./styled";

const PropertySetupDocumentFieldsModal = ({
  isMobile,
  documentName,
  fields = [],
  updateDocumentFields,
}: PropertySetupDocumentFieldsModalProps) => (
  <Formik
    enableReinitialize
    initialValues={{ fields }}
    onSubmit={updateDocumentFields}
  >
    {({ submitForm, isValid }) => (
      <FieldArray
        name="fields"
        render={() => (
          <Form>
            <Modal
              title="Setup document fields"
              subtitle={
                <span>
                  Document: <strong>{documentName}</strong>
                </span>
              }
              submitButtonLabel="Confirm"
              submit={submitForm}
              submitButtonDisabled={!isValid}
              fullScreen={isMobile}
              width="auto"
              style={{ maxWidth: 915 }}
            >
              <Modal.Body noPaddingX>
                <Title>
                  The data you fill in below will be displayed in the final
                  lease document
                </Title>
                <SetupDocumentsFields modalView />
              </Modal.Body>
            </Modal>
          </Form>
        )}
      />
    )}
  </Formik>
);

export default PropertySetupDocumentFieldsModal;
