import { useMutation, useQuery } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_LEASE_FEE_CONFIGURATION = "GET_LEASE_FEE_CONFIGURATION";
const GET_LEASE_FEE_FIELDS = "GET_LEASE_FEE_FIELDS";

export const useGetLeaseFeeConfiguration = (leaseId: number) => {
  const { data: leaseFeeConfiguration, isLoading: isLeaseFeeConfigLoading } =
    useQuery(
      [GET_LEASE_FEE_CONFIGURATION, leaseId],
      () => api.getLeaseFeeConfiguration(leaseId),
      {
        onError: () =>
          openNotification(
            "Failed to load lease fee configuration",
            NOTIFICATIONS.error
          ),
      }
    );

  return {
    leaseFeeConfiguration,
    isLeaseFeeConfigLoading,
  };
};

export const useGetLeaseFeeFields = (leaseId: number) => {
  const { data: feeFields, isLoading: isFeeFieldsLoading } = useQuery(
    [GET_LEASE_FEE_FIELDS, leaseId],
    () => api.getLeaseFeeFields(leaseId),
    {
      onError: () =>
        openNotification(
          "Failed to load lease fee fields",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    feeFields,
    isFeeFieldsLoading,
  };
};

export const useCalculateLeaseTotalRent = () => {
  const [
    calculateTotalRent,
    { isLoading: isCalculatingTotalRent, data: leaseCalculations },
  ] = useMutation(api.calculateTotalRent, {
    onError: () =>
      openNotification(
        "Failed to calculate lease total monthly rent",
        NOTIFICATIONS.error
      ),
  });

  return {
    calculateTotalRent,
    isCalculatingTotalRent,
    leaseCalculations,
  };
};

export const useCalculateLeaseFirstMonthRent = () => {
  const [
    calculateFirstMonthRent,
    { isLoading: isCalculatingFirstMonthRent, data: leaseFirstMonthRent },
  ] = useMutation(api.calculateFirstMonthRent, {
    onError: () =>
      openNotification(
        "Failed to calculate lease first month rent",
        NOTIFICATIONS.error
      ),
  });

  return {
    calculateFirstMonthRent,
    isCalculatingFirstMonthRent,
    leaseFirstMonthRent,
  };
};
