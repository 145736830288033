const DEALS_BASE_URL = "/deals/";
const managerApplications = "/applications/";
const managerApplication = `${managerApplications}:applicationId/`;

const PROPERTIES_BASE_URL = "/properties/";
const PROPERTY_BASE_URL = `${PROPERTIES_BASE_URL}:id/`;

const PROPERTY_ROUTES = {
  propertyDocumentTemplates: `${PROPERTY_BASE_URL}document-templates/`,
  propertyDocumentFields: `${PROPERTY_BASE_URL}document-templates/:documentId/fields/`,
  propertyFields: `${PROPERTY_BASE_URL}fields/`,
  propertySettings: `${PROPERTY_BASE_URL}settings/`,
  propertiesList: `${PROPERTIES_BASE_URL}list-names/`,
};

const UNITS_BASE_URL = "/units/";

const UNITS_ROUTES = {
  unitsExport: `${UNITS_BASE_URL}export/`,
  getUnitsListForProperty: `${UNITS_BASE_URL}list-names/`,
};

const baseLeaseRoute = "/leases/:leaseId/";

const LEASE_ROUTES = {
  leases: "/leases/",
  lease: `${baseLeaseRoute}`,
  leaseTerms: `${baseLeaseRoute}lease-terms/`,
  leaseStep: `${baseLeaseRoute}step/`,
  assignLeaseOptionalDocuments: `${baseLeaseRoute}assign-optional-documents/`,
  leaseAssignedDocuments: `${baseLeaseRoute}property-document-templates/`,
  leaseDocumentTemplates: `${baseLeaseRoute}document-templates/`,
  updateLeaseMetadata: `${baseLeaseRoute}confirm-lease/`,
  leasePreview: `${baseLeaseRoute}view-final-document/`,
  sendLease: `${baseLeaseRoute}send/`,
  resendLease: `${baseLeaseRoute}resend/`,
  withdrawLease: `${baseLeaseRoute}withdraw/`,
  managerSignUrl: `${baseLeaseRoute}get-signature/`,
  applicationAnswers: `${baseLeaseRoute}additional-answers/`,
  leaseFields: `${baseLeaseRoute}fields/`,
  handleLeaseOffline: `${baseLeaseRoute}handle-offline/`,
  setLeaseInProgress: `${baseLeaseRoute}in-progress/`,
  processingManagerSignature: `${baseLeaseRoute}processing-signature/`,
  downloadLease: `${baseLeaseRoute}download/`,
  leaseAvailableDocuments: `${baseLeaseRoute}available-documents/`,
  refreshLeaseAvailableDocuments: `${baseLeaseRoute}refresh-documents/`,
  executeOfflineLease: `${baseLeaseRoute}execute-lease-offline/`,
  validConcessionMonths: "/leases/valid-concession-months/",
  calculateNetEffectiveRent: "/leases/calculate-net-effective-rent/",
  leasesExport: "/leases/export/",
  calculateSecurityDepositBalance: `${baseLeaseRoute}calculate-security-deposit/`,
  leaseFeeConfiguration: `${baseLeaseRoute}fee-configuration/`,
  leaseFeeFields: `${baseLeaseRoute}fee-fields/`,
  leaseCalculateTotalRent: "/leases/calculate-total-rent/",
  leaseCalculateFirstMonthRent: "/leases/calculate-first-month-rent/",
  verifyLease: `${baseLeaseRoute}verify/`,
  // BLUEMOON SPECIFIC ROUTES
  leaseAvailableDocumentsBluemoon: `${baseLeaseRoute}bluemoon/available-documents/`,
  leaseDocumentTemplatesBluemoon: `${baseLeaseRoute}bluemoon/document-templates/`,
  assignLeaseOptionalDocumentsBluemoon: `${baseLeaseRoute}bluemoon/assign-optional-documents/`,
  leaseFieldsBluemoon: `${baseLeaseRoute}bluemoon/fields/`,
  updateLeaseMetadataBluemoon: `${baseLeaseRoute}confirm-bluemoon-lease/`,
  sendLeaseBluemoon: `${baseLeaseRoute}send-bluemoon-sign/`,
  countersignBluemoonLease: `${baseLeaseRoute}send-bluemoon-countersign/`,
};

const DASHBOARD_ROUTES = {
  companyDashboard: "/dashboard-stats/company/",
};

const LEASE_SETUP_ROUTES = {
  getCompanyLeaseSetup: "/companies/lease/setup/",
  updateCompanyLeaseSetup: "/companies/lease/update-setup/",
  getPropertyLeaseSetup: "/properties/:id/lease-setup/",
  updatePropertyLeaseSetup: "/properties/:id/update-lease-setup/",
  getLeasePackagesManagerQuestions:
    "/properties/:id/manager-additional-questions/",
  getLeasePackagesApplicantQuestions:
    "/properties/:id/applicant-additional-questions/",
  upsertApplicantQuestion:
    "/properties/:id/add-applicant-additional-questions/",
  upsertManagerQuestion: "/properties/:id/add-manager-additional-questions/",
  deleteManagerQuestion: "/additional-questions/:id/delete-manager-question/",
  getLeasePackagesManagerAnswers: `${baseLeaseRoute}additional-answers-manager/`,
  getLeaseAdditionalDocuments: `${baseLeaseRoute}additional-documents/`,
  getLeaseAdditionalManagerQuestions: `${baseLeaseRoute}additional-questions-manager/`,
};

const RENEWALS_BASE_URL = "/renewals/";
const RENEWAL_BASE_URL = `${RENEWALS_BASE_URL}:id/`;
const RENEWAL_OFFERS_BASE_URL = `${RENEWAL_BASE_URL}renewal-offer/`;
const RENEWAL_OFFER_BASE_URL = `${RENEWAL_OFFERS_BASE_URL}:offerId/`;

const RENEWALS_ROUTES = {
  renewals: RENEWALS_BASE_URL,
  renewal: RENEWAL_BASE_URL,
  sendRequestRenewal: `${RENEWAL_BASE_URL}send-request-renewal/`,
  createRenewal: `${RENEWAL_BASE_URL}create-renewal/`,
  sendRenewalOfferRequest: `${RENEWAL_BASE_URL}send-request-offer/`,
  sendRenewalOfferApprovalReminder: `${RENEWAL_BASE_URL}send-approval-reminder/`,
  approveRenewalOffers: `${RENEWALS_BASE_URL}approve/`,
  sendRenewalOffers: `${RENEWALS_BASE_URL}send-offer-to-applicants/`,
  sendRenewalOfferReminder: `${RENEWAL_BASE_URL}send-reminder/`,
  renewalOffers: RENEWAL_OFFERS_BASE_URL,
  renewalOffer: RENEWAL_OFFER_BASE_URL,
  reopenRenewalOffer: `${RENEWAL_OFFER_BASE_URL}reopen/`,
  withdrawRenewalOffer: `${RENEWAL_OFFER_BASE_URL}withdraw/`,
  withdrawRenewalOfferOption: `${RENEWAL_OFFER_BASE_URL}withdraw-option/:optionId/`,
  rejectRenewal: `${RENEWAL_BASE_URL}reject/`,
  cancelRenewal: `${RENEWAL_BASE_URL}cancel/`,
  renewalNotes: `${RENEWAL_BASE_URL}notes/`,
  createRenewalNote: `${RENEWAL_BASE_URL}create-note/`,
  rejectRenewalApplicationOffer: `${RENEWAL_BASE_URL}reject-application-offer/`,
  removeApplicantFromRenewal: `${RENEWAL_BASE_URL}remove-applicants/`,
  addApplicantsToRenewal: `${RENEWAL_BASE_URL}add-applicants/`,
};

const BASE_LEASE_GROUPS_URL = "/lease-groups/";
const LEASE_GROUP_URL = `${BASE_LEASE_GROUPS_URL}:id/`;

const LEASE_GROUPS_ROUTES = {
  leaseGroups: BASE_LEASE_GROUPS_URL,
  leaseGroup: LEASE_GROUP_URL,
  leaseGroupApplications: `${LEASE_GROUP_URL}applications/`,
  leaseGroupAttachments: `${LEASE_GROUP_URL}attachments/`,
  leaseGroupCreateAttachment: `${LEASE_GROUP_URL}create-attachment/`,
  leaseGroupEditAttachment: `${LEASE_GROUP_URL}edit-attachment/:attachmentId/`,
  leaseGroupDeleteAttachment: `${LEASE_GROUP_URL}delete-attachment/:attachmentId/`,
};

const LEADS_BASE_URL = "/leads/";
const LEADS_COUNTS_URL = `${LEADS_BASE_URL}leads-summary/`;
const LEAD_URL = `${LEADS_BASE_URL}:id/`;

const LEADS_ROUTES = {
  leads: LEADS_BASE_URL,
  leadsCounts: LEADS_COUNTS_URL,
  lead: LEAD_URL,
  inviteLead: `${LEADS_BASE_URL}invite-lead/`,
  reinviteLead: `${LEADS_BASE_URL}:dealId/reinvite-lead/`,
  updateLeadArchiveStatus: `${LEAD_URL}update-archive-status/`,
  togglePropertyEligibilityForPrequalification: `${PROPERTIES_BASE_URL}:propertyId/toggle-eligible-for-prescreening/`,
  prescreeningProperties: `${PROPERTIES_BASE_URL}prescreening-properties/`,
  inviteLeadToApply: `${LEADS_BASE_URL}:id/invite-lead-to-apply/`,
  getSubmittedLeads: `${LEADS_BASE_URL}list-submitted/`,
  generateLeadPDF: `${LEAD_URL}generate-pdf/`,
};

const YARDI_BASE_URL = "/yardi/";
const YARDI_PROPERTIES_BASE_URL = "/yardi-properties/";
const YARDI_PROPERTY_BASE_URL = `${YARDI_PROPERTIES_BASE_URL}:yardiPropertyId/`;
const YARDI_MARKETING_SOURCES_BASE_URL = `${YARDI_PROPERTIES_BASE_URL}:id/marketing-sources/`;
const YARDI_UNITS_BASE_URL = `${YARDI_PROPERTY_BASE_URL}yardi-units/`;
const YARDI_ALERTS_BASE_URL = "/yardi-alerts/";
const YARDI_AGENTS_BASE_URL = "/yardi-agents/";
const YARDI_EXTRA = "/yardi-extra/";
const YARDI_EXTRA_CLIENT_REQ_RES = `${YARDI_EXTRA}client-req-res/:id/`;
const BLUEMOON_ALERTS_BASE_URL = "/bluemoon/";

const YARDI_INTEGRATION_ROUTES = {
  yardiPlatformTypes: "/types/yardi-platforms/",
  yardiPcodePoints: "/types/yardi-pcode-points/",
  yardiTcodePoints: "/types/yardi-tcode-points/",
  yardiLeaseSignEventPoints: "/types/yardi-lease-sign-points/",
  yardiSecurityDepositPreferences: "/types/yardi-security-deposit-prefs/",
  yardiSyncIntervals: "/types/yardi-sync-intervals/",
  yardiLeadSources: `/types/yardi-lead-sources/`,
  yardiCredentials: `${YARDI_BASE_URL}`,
  yardiUpdateCredentials: `${YARDI_BASE_URL}update_credentials/`,
  attachmentTypes: `${YARDI_BASE_URL}attachment_types/`,
  chargeTypes: `${YARDI_BASE_URL}charge_types/`,
  yardiMappingConfiguration: `${YARDI_BASE_URL}update_mapping_configuration/`,
  yardiIntegrationStatus: `${YARDI_BASE_URL}integration_status/`,
  yardiLeadSourcesMappings: `${YARDI_BASE_URL}update_lead_sources_mapping_configuration/`,
  yardiProperties: `${YARDI_PROPERTIES_BASE_URL}`,
  yardiProperty: YARDI_PROPERTY_BASE_URL,
  yardiAlerts: `${YARDI_ALERTS_BASE_URL}`,
  yardiPull: `${YARDI_BASE_URL}pull/`,
  yardiVersion: `${YARDI_BASE_URL}version/`,
  yardiPropertyMappings: `${YARDI_PROPERTIES_BASE_URL}:id/mappings/`,
  yardiPropertyReasons: `${YARDI_PROPERTIES_BASE_URL}:id/reasons/`,
  yardiUnits: `${YARDI_UNITS_BASE_URL}`,
  yardiUnit: `${YARDI_UNITS_BASE_URL}:yardiUnitId/`,
  autoCreateVeroUnits: `${YARDI_UNITS_BASE_URL}autocreate/`,
  yardiMarketingSources: YARDI_MARKETING_SOURCES_BASE_URL,
  autoCreateMarketingSources: `${YARDI_MARKETING_SOURCES_BASE_URL}autocreate/`,
  yardiAgents: `${YARDI_PROPERTIES_BASE_URL}:id/agents/`,
  yardiAgentsOnVeroProperties: `${YARDI_AGENTS_BASE_URL}list_by_vero_properties/`,
  yardiAgentsOnPropertiesMapping: `${YARDI_AGENTS_BASE_URL}mappings/`,
  yardiPushOperationTracking: `${YARDI_EXTRA}yardi-push-operation-tracking/:id/`,
  yardiPushOperationMessage: `${YARDI_EXTRA}yardi-push-operation-message/:id/`,
  yardiYardiActionTracking: `${YARDI_EXTRA}yardi-action-tracking/:id/`,
  yardiVeroEvent: `${YARDI_EXTRA}vero-events/:id/`,
  yardiClientReqRes: YARDI_EXTRA_CLIENT_REQ_RES,
  yardiClientReqResSoapResponse: `${YARDI_EXTRA_CLIENT_REQ_RES}soap-response/`,
  yardiClientReqResYardiServiceResponse: `${YARDI_EXTRA_CLIENT_REQ_RES}yardi-service-response/`,
  yardiDealStatusSync: `/yardi-push/deal/:id/`,
  importPmsProperties: `${YARDI_PROPERTIES_BASE_URL}import/`,
};

const YARDI_ALERT_RESOLVER_ROUTES = {
  yardiResolveLinkProperty: `${YARDI_ALERTS_BASE_URL}:alertId/resolve-property/`,
  yardiResolveLinkUnit: `${YARDI_ALERTS_BASE_URL}:alertId/resolve-unit/`,
  yardiResolveCreateMarketingSource: `${YARDI_ALERTS_BASE_URL}:alertId/resolve-marketing-source/`,
  yardiResolveCreateLeadSource: `${YARDI_ALERTS_BASE_URL}:alertId/resolve-lead-source/`,
  yardiResolveCreateUnitListing: `${YARDI_ALERTS_BASE_URL}:alertId/resolve-unit-listing/`,
  yardiResolveLinkAgents: `${YARDI_ALERTS_BASE_URL}:alertId/resolve-agents/`,
  yardiResolveLinkMarketingSources: `${YARDI_ALERTS_BASE_URL}:alertId/resolve-marketing-sources-link/`,
  yardiResolveLinkChargeTypes: `${YARDI_ALERTS_BASE_URL}:alertId/resolve-charge-types/`,
  yardiResolveLinkAttachmentTypes: `${YARDI_ALERTS_BASE_URL}:alertId/resolve-attachment-types/`,
};

const BLUEMOON_BASE = "/bluemoon/";

const BLUEMOON_INTEGRATIONS = {
  getBluemoonLicenses: `${BLUEMOON_BASE}licenses/`,
  getBluemoonFields: `${BLUEMOON_BASE}fields/`,
  updateBluemoonField: `${BLUEMOON_BASE}fields/:id/`,
  getBluemoonFieldsTypes: `${BLUEMOON_BASE}fields/types/`,
  getBluemoonFieldsDatapoints: `${BLUEMOON_BASE}fields/data-points/`,
  getBluemoonProperties: `${BLUEMOON_BASE}properties/`,
  getVeroProperties: `${BLUEMOON_BASE}vero-properties/`,
  hideBluemoonProperty: `${BLUEMOON_BASE}properties/hide/`,
  getBluemoonPropertiesCount: `${BLUEMOON_BASE}properties/count/`,
  unbindBluemoonProperties: `${BLUEMOON_BASE}vero-properties/:id/unbind/`,
  getBluemoonHiddenProperties: `${BLUEMOON_BASE}properties/hidden/`,
  unhideBluemoonProperties: `${BLUEMOON_BASE}properties/unhide/`,
  bluemoonBindProperties: `${BLUEMOON_BASE}properties/bind/`,
  getBluemoonDashboardData: `${BLUEMOON_BASE}licenses/dashboard/`,
};

const MARKETING_SOURCES_BASE_URL = "/marketing-sources/";
const LEAD_SOURCES_BASE_URL = "/lead-sources/";

const SOURCES_ROUTES = {
  marketingSources: `${MARKETING_SOURCES_BASE_URL}`,
  marketingSource: `${MARKETING_SOURCES_BASE_URL}:id/`,
  marketingSourcesFullSave: `${MARKETING_SOURCES_BASE_URL}property/:propertyId/full-save/`,
  leadSources: `${LEAD_SOURCES_BASE_URL}`,
  leadSource: `${LEAD_SOURCES_BASE_URL}:id/`,
  leadSourcesFullSave: `${LEAD_SOURCES_BASE_URL}full-save/`,
};

const MAGIC_LINKS_BASE_URL = "/magic-link/";

const MAGIC_LINKS_ROUTES = {
  magicLinksAgents: `${MAGIC_LINKS_BASE_URL}employees/`,
  companyMagicLink: `${MAGIC_LINKS_BASE_URL}company/`,
  magicLinkQRCode: `${MAGIC_LINKS_BASE_URL}qr-code/`,
  magicLinkProperties: `${MAGIC_LINKS_BASE_URL}properties/`,
  magicLinkToggleBranding: `${MAGIC_LINKS_BASE_URL}properties/:propertyId/toggle-property-branding/`,
  magicLinksExportAgents: `${MAGIC_LINKS_BASE_URL}employees/export/`,
  magicLinksExportProperties: `${MAGIC_LINKS_BASE_URL}properties/export/`,
  downloads: `/download/`,
};

const MAGIC_PORTAL_ROUTES = {
  togglePropertyDisplayOnPortal: `${PROPERTIES_BASE_URL}:propertyId/toggle-display-on-portal/`,
  toggleUnitDisplayOnPortal: `/units/:unitId/toggle-display-on-portal/`,
};

const AGENTS_ROUTES = {
  assignableEmployees: "/deals/:id/assignable-employees/",
  assignEmployeeToDeal: "/deals/:id/assign-employee/",
};

const LUMEN_BASE_URL = "/lumen/";
const LUMEN_FORMULAS_BASE_URL = `${LUMEN_BASE_URL}formulas/`;
const LUMEN_FORMULA_BASE_URL = `${LUMEN_FORMULAS_BASE_URL}:formulaId/`;
const LUMEN_FORMULA_ASSIGNMENTS_URL = `${LUMEN_FORMULA_BASE_URL}portfolios-and-properties/`;
const LUMEN_FORMULA_ASSIGN_URL = `${LUMEN_FORMULA_BASE_URL}assign/`;
const LUMEN_FORMULA_REVISIONS_BASE_URL = `${LUMEN_FORMULA_BASE_URL}revisions/`;
const LUMEN_FORMULA_REVISION_BASE_URL = `${LUMEN_FORMULA_REVISIONS_BASE_URL}:revisionId/`;
const LUMEN_FORMULA_MARK_AS_TEMPLATE = `${LUMEN_FORMULAS_BASE_URL}:formulaId/mark-as-template/`;
const LUMEN_FORMULA_TEMPLATES_BASE_URL = `${LUMEN_FORMULAS_BASE_URL}templates/`;

const LUMEN_ROUTES = {
  lumenFormulas: LUMEN_FORMULAS_BASE_URL,
  lumenFormula: LUMEN_FORMULA_BASE_URL,
  editLumenFormula: `${LUMEN_FORMULA_BASE_URL}edit/`,
  duplicateLumenFormula: `${LUMEN_FORMULA_BASE_URL}duplicate/`,
  lumenFormulaRevisions: LUMEN_FORMULA_REVISIONS_BASE_URL,
  lumenFormulaRevision: LUMEN_FORMULA_REVISION_BASE_URL,
  completeLumenFormulaRevision: `${LUMEN_FORMULA_REVISION_BASE_URL}save-and-complete/`,
  lumenResultForDeal: `${LUMEN_BASE_URL}deal/:dealId/result/`,
  lumenFormulaAssignments: LUMEN_FORMULA_ASSIGNMENTS_URL,
  lumenFormulaAssign: LUMEN_FORMULA_ASSIGN_URL,
  lumenFormulaTemplates: LUMEN_FORMULA_TEMPLATES_BASE_URL,
  lumenFormulaMarkAsTemplate: LUMEN_FORMULA_MARK_AS_TEMPLATE,
};

const TYPE_ROUTES = {
  creditPublicRecordOptions: "/types/credit-public-record/",
  creditPublicRecordBankruptcyOptions:
    "/types/credit-public-record-bankruptcy/",
  criminalRecordSeverityOptions: "/types/criminal-record-severity/",
  getSystemAuditSourceTypes: "/types/system-audit-sources/",
  getDashboardPeriods: "/types/dashboard-periods/",
  applicantTypes: "/types/applicants/",
};

const MANAGER_ROUTES = {
  managerProfile: "/manager/profile/",
};

const MANAGER_APPLICATION_ROUTES = {
  addAttachments: `${managerApplication}add-attachment/`,
  deleteAttachments: `${managerApplication}attachments/delete/`,
  cancelAttachments: `${managerApplication}cancel-attachments/`,
  confirmAttachments: `${managerApplication}confirm-attachments/`,
  blockDealSubmitIfIncomeMissing: `${managerApplication}block-deal-submit-if-income-missing/`,
};

const COMPANY_BASE_URL = "/companies/";

const COMPANY_ROUTES = {
  companies: COMPANY_BASE_URL,
  createOpenApiUser: `${COMPANY_BASE_URL}create_open_api_user/`,
  testOpenApiWebhook: `${COMPANY_BASE_URL}test_open_api_webhook/`,
  resetOpenApiPassword: `${COMPANY_BASE_URL}reset_password_open_api_user/`,
  setOpenApiSettings: `${COMPANY_BASE_URL}set_open_api_settings/`,
  dismissCompany: "/employees/dismiss-company/",
};

const APPLICANT_SCREENING_CRITERIA = {
  uploadCompanyScreeningCriteriaDocument: "/company-screening-documents/",
  uploadPropertyScreeningCriteriaDocument: "/property-screening-documents/",
  deleteCompanyScreeningCriteriaDocument:
    "/company-screening-documents/:companyId/",
  deletePropertyScreeningCriteriaDocument:
    "/property-screening-documents/:propertyId/",
  getCompanyScreeningCriteriaDocument:
    "/companies/:companyId/screening-document/",
  getPropertyScreeningCriteriaDocument: `${PROPERTIES_BASE_URL}:propertyId/screening-document/`,
};

const DEAL_BASE_URL = "/deals/:id/";

const SYSTEM_AUDIT_ROUTES = {
  getApplicationAudits: `${DEAL_BASE_URL}system-audits/`,
  createApplicationAuditNote: `${DEAL_BASE_URL}add-note/`,
  getApplicationAuditsCount: `${DEAL_BASE_URL}system-audits-count/`,
};

const COUNTY_CRIMINAL_SEARCH = {
  requestCountyCriminalSearch: `${DEALS_BASE_URL}:dealId/county-criminal-search/`,
};

const CUSTOM_EMAIL_CONFIGURATIONS = {
  defaultEmailConfigurations: "/default-email-configurations/",
  defaultEmailConfiguration: "/default-email-configurations/:id/",
  defaultEmailConfigurationSendTest: "/default-email-configurations/send/",
  customEmailConfigurations: "/custom-email-configurations/",
  customEmailConfiguration: "/custom-email-configurations/:id/",
  customEmailConfigurationSendTest: "/custom-email-configurations/:id/send/",
  setCustomEmailConfigurationAsDefault:
    "/custom-email-configurations/:id/set-as-default/",
  restoreDefaultEmailConfiguration:
    "/custom-email-configurations/restore-default/",
  propertyEmailConfigurations: "/property-email-configurations/",
  propertyEmailConfiguration:
    "/property-email-configurations/:configurationId/",
  emailTemplateVariables: "/types/email-variables/",
};

const DEAL_ROUTES = {
  updateDealArchiveStatus: `${DEAL_BASE_URL}update-archive-status/`,
  archiveDeal: `${DEAL_BASE_URL}archive/`,
  assignDealToUnitCheck: `${DEAL_BASE_URL}assign-unit-check/`,
  applicationIds: `/deal/:id/application-ids/`,
};

const APPLICATION_CHARGES_ROUTES = {
  applicationCharges: "/application-charges/:level/:id/",
  unitTypes: "/fee-inventory/unit-types/:propertyId/",
};

const baseRenewalsSettings = "/renewal-settings";

const RENEWALS_SETTINGS = {
  renewalsCompanySettings: `${baseRenewalsSettings}/company-renewal-settings/`,
  renewalsSettings: `${baseRenewalsSettings}/:settingsId/`,
  copyRenewalSettingsFromPortfolio: `${PROPERTY_BASE_URL}copy-renewal-settings-from-portfolio/`,
  resetRenewalSettingsToPortfolio: `${PROPERTY_BASE_URL}reset-renewal-settings-to-portfolio-settings/`,
};

const ADDITIONAL_QUESTION_BASE_URL = "/additional-questions/";

const ADDITIONAL_QUESTION_ROUTES = {
  additionalQuestions: `${ADDITIONAL_QUESTION_BASE_URL}`,
  additionalQuestion: `${ADDITIONAL_QUESTION_BASE_URL}:id/`,
  toggleAdditionalQuestionActive: `${ADDITIONAL_QUESTION_BASE_URL}:id/toggle-is-active/`,
};

const DEAL_SETUP_BASE_URL = "/deal-setups/:id/";
const SURVEY_BASE_URL = "/survey/";

const SURVEY_ROUTES = {
  survey: `${SURVEY_BASE_URL}`,
  dealSetupSurvey: `${DEAL_SETUP_BASE_URL}survey/`,
  surveyQuestions: `${SURVEY_BASE_URL}:id/questions/`,
  requestSurvey: `${SURVEY_BASE_URL}:id/notify/`,
  surveyQuestion: `${SURVEY_BASE_URL}:surveyId/questions/:questionId/`,
  updateSurveyQuestionsOrdering: `${SURVEY_BASE_URL}:surveyId/update-survey-questions-ordering/`,
};

const BLUEMOON_ALERT_RESOLVER_ROUTES = {
  bluemoonResolveConnectLicense: `${BLUEMOON_ALERTS_BASE_URL}licenses/`,
  bluemoonResolveEditLicense: `${BLUEMOON_ALERTS_BASE_URL}licenses/:id/`,
};

const EMPLOYEE_ROUTES = {
  getEmployees: "/employees/",
};

const MARKETPLACE_ROUTES = {
  getIntegrationStatuses: "/integrations/status/",
};

const MANAGER_API_ROUTES = {
  ...PROPERTY_ROUTES,
  ...LEASE_ROUTES,
  ...RENEWALS_ROUTES,
  ...LEASE_SETUP_ROUTES,
  ...LEADS_ROUTES,
  ...YARDI_INTEGRATION_ROUTES,
  ...YARDI_ALERT_RESOLVER_ROUTES,
  ...BLUEMOON_INTEGRATIONS,
  ...SOURCES_ROUTES,
  ...MAGIC_LINKS_ROUTES,
  ...AGENTS_ROUTES,
  ...MANAGER_ROUTES,
  ...MANAGER_APPLICATION_ROUTES,
  ...COMPANY_ROUTES,
  ...LUMEN_ROUTES,
  ...TYPE_ROUTES,
  ...UNITS_ROUTES,
  ...APPLICANT_SCREENING_CRITERIA,
  ...MAGIC_PORTAL_ROUTES,
  ...SYSTEM_AUDIT_ROUTES,
  ...COUNTY_CRIMINAL_SEARCH,
  ...CUSTOM_EMAIL_CONFIGURATIONS,
  ...DEAL_ROUTES,
  ...DASHBOARD_ROUTES,
  ...LEASE_GROUPS_ROUTES,
  ...RENEWALS_SETTINGS,
  ...ADDITIONAL_QUESTION_ROUTES,
  ...APPLICATION_CHARGES_ROUTES,
  ...SURVEY_ROUTES,
  ...BLUEMOON_ALERT_RESOLVER_ROUTES,
  ...EMPLOYEE_ROUTES,
  ...MARKETPLACE_ROUTES,
};

export default MANAGER_API_ROUTES;
