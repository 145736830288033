import React from "react";

import Icon from "shared/components/Icon";
import { IconLink } from "shared/components/Links";

export type DownloadButtonProps = {
  disabled?: boolean;
  isDownloading?: boolean;
  onDownloadClick: () => void;
};

export const DownloadButton = ({
  isDownloading = false,
  onDownloadClick,
  disabled = false,
}) =>
  isDownloading ? (
    <div>
      <Icon.LoadingSpinnerGreenIcon data-testid="download-spinner" />
    </div>
  ) : (
    <IconLink
      onClick={onDownloadClick}
      Icon={Icon.DownloadDocumentIcon}
      hideTextOnMobile
      disabled={disabled}
      data-testid="download-button"
    />
  );
