import React, { useEffect, useState, useCallback } from "react";

import { useParams, useHistory } from "react-router-dom";

import ApplicationAnswersModal from "manager/components/Modal/ApplicationAnswersModal";
import ROUTES from "manager/config/routes";
import { useGetLease, useLeaseApplicationAnswers } from "manager/hooks/api";

import { useAdditionalQuestionTypes } from "shared/hooks/api/typeQueries";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";

import { LeaseBuilderSteps } from "./constants";
import LeaseBuilder from "./LeaseBuilder";
import useLeaseDocumentsRefresh from "./useLeaseDocumentsRefresh";

const LeaseBuilderContainer = (props) => {
  const history = useHistory();
  const { openModalDialog } = useModal();

  const { id: leaseId } = useParams();
  const [currentStep, setCurrentStep] = useState();

  const {
    data: lease,
    isLoading: isLeaseLoading,
    isFetchedAfterMount: isLeaseFetchedAfterMount,
  } = useGetLease(leaseId);

  const backLink = {
    children: `${lease?.deal?.unit?.name} LEASE`,
    to: transformRoute(ROUTES.leaseSummary, { id: leaseId }),
  };

  const { applicationAnswers, isLoading: isApplicationAnswersLoading } =
    useLeaseApplicationAnswers(leaseId);
  const { data: applicationAnswersTypes, isLoading: isAnswerTypesLoading } =
    useAdditionalQuestionTypes(lease?.deal?.unit?.property.id);

  const openAnswersModal = useCallback(() => {
    openModalDialog(ApplicationAnswersModal, {
      propertyAddress: `${lease.deal.unit.property.name}, ${lease.deal.unit.property.address}, ${lease.deal.unit.name}`,
      applicationAnswers,
      applicationAnswersTypes,
      applicants: lease.deal.applicantNames,
    });
  }, [lease, applicationAnswers, isAnswerTypesLoading]);

  const redirectBack = () => {
    history.push(backLink.to);
  };

  const stepBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const stepForward = () => {
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    if (lease && !currentStep && isLeaseFetchedAfterMount) {
      setCurrentStep(lease.step);
    }
  }, [lease, isLeaseFetchedAfterMount]);

  const { documentsMismatchWarning, isRefreshingDocuments } =
    useLeaseDocumentsRefresh({
      lease,
      currentStep,
      navigateToDocuments: () =>
        setCurrentStep(LeaseBuilderSteps.LEASE_DOCUMENTS_STEP),
    });

  return (
    <LeaseBuilder
      lease={lease}
      isApplicationAnswersLoading={isApplicationAnswersLoading}
      isLeaseLoading={isLeaseLoading}
      currentStep={currentStep}
      openAnswersModal={openAnswersModal}
      redirectBack={redirectBack}
      stepBack={stepBack}
      stepForward={stepForward}
      documentsMismatchWarning={documentsMismatchWarning}
      isRefreshingDocuments={isRefreshingDocuments}
      backLink={backLink}
      {...props}
    />
  );
};

export default LeaseBuilderContainer;
