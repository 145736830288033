import { useCallback, useEffect, useState } from "react";

import throttle from "lodash/throttle";
import { ValidationError, AnyObjectSchema } from "yup";

import { useCalculateLeaseTotalRent } from "manager/hooks/api";
import { Lease } from "manager/interfaces/api/lease";
import { CalculateLeaseTotalRentModel } from "manager/interfaces/api/leaseFeeConfiguration";

import { FeeConfigurationValues } from "./interfaces";

interface LeaseTotalRentCalculationProps {
  timeout?: number;
  feeConfiguration: FeeConfigurationValues;
  schema: AnyObjectSchema;
  lease: Lease;
}

const useLeaseTotalRentCalculation = ({
  timeout = 500,
  feeConfiguration,
  lease,
  schema,
}: LeaseTotalRentCalculationProps) => {
  const [displayedLeaseTotalRent, setDisplayedLeaseTotalRent] =
    useState<CalculateLeaseTotalRentModel>();
  const { calculateTotalRent, leaseCalculations } =
    useCalculateLeaseTotalRent();

  const calculateTotalRentCallback = useCallback(
    throttle(calculateTotalRent, timeout),
    [calculateTotalRent]
  );
  const { rent, startDate, prorateFirstMonthRent } = lease;

  useEffect(() => {
    (async () => {
      try {
        /** Note: Avoid validation of items not included into Total Rent */
        const calculationPayload = {
          ...feeConfiguration,
          charges: feeConfiguration.charges?.filter(
            (fee) => fee.appliedToTotalRent
          ),
          rentableItems: feeConfiguration.rentableItems?.filter(
            (fee) => fee.appliedToTotalRent
          ),
        };

        /** Note: Perform manual validation instead of Formik/isValid
         *  because formik can first update values (that can be invalid) and then
         *  updates isValid prop to false. */
        await schema.validate(calculationPayload);

        calculateTotalRentCallback({
          rent,
          feeConfiguration: calculationPayload,
          startDate,
          prorateFirstMonthRent,
        });
      } catch (error) {
        /** Note: No actions on validation error */
        if (!(error instanceof ValidationError)) {
          throw error;
        }
      }
    })();
  }, [
    feeConfiguration,
    rent,
    startDate,
    prorateFirstMonthRent,
    calculateTotalRentCallback,
  ]);

  useEffect(() => {
    if (leaseCalculations) {
      setDisplayedLeaseTotalRent(leaseCalculations);
    }
  }, [leaseCalculations]);

  return displayedLeaseTotalRent;
};

export default useLeaseTotalRentCalculation;
