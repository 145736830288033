import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const StyledRenewalsSettings = styled.section`
  .settings-toggle {
    margin-left: -6px;
  }

  .notice-settings {
    padding: 40px 24px;

    .notice-row {
      background: ${COLORS.white};
      padding: 16px;
      margin-bottom: 4px;
    }

    .notice-row > div:first-of-type {
      padding-top: 8px;
    }
  }

  .info-message {
    background: ${COLORS.white};
    border-bottom: none;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    .notice-settings {
      .notice-row > div:first-of-type {
        margin-bottom: 12px;
      }
    }
  }
`;

export { StyledRenewalsSettings };
