import React, { useEffect, useState } from "react";

import differenceWith from "lodash/differenceWith";
import groupBy from "lodash/groupBy";
import PropTypes from "prop-types";

import { PreviewDocumentTemplateModal } from "manager/components/Leasing/PreviewLeaseModal";
import DocumentsVerificationModal from "manager/components/Modal/Lease/DocumentsVerificationModal";
import {
  useAssignLeaseOptionalDocuments,
  useLeaseDocumentTemplates,
  useLeaseAvailableDocuments,
  useLeaseDocumentTemplatesBluemoonQuery,
  useLeaseAvailableDocumentsBluemoonQuery,
  useAssignLeaseOptionalDocumentsBluemoonQuery,
  useGetLeaseAdditionalDocuments,
} from "manager/hooks/api";
import api from "manager/lib/api";
import { usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";

import LeaseDocumentsStep from "./LeaseDocumentsStep";
import {
  formatDocumentsToAssign,
  filterDocumentsById,
  buildDocumentsToPackage,
  showProrationWarning,
} from "./utils";

const LeaseDocumentsStepContainer = ({
  lease,
  currentStep,
  redirectBack,
  stepForward,
  stepBack,
}) => {
  const { openModalDialog } = useModal();
  const [finalPackageDocuments, setFinalPackageDocuments] = useState([]);
  const [nextStepLoading, setNextStepLoading] = useState(false);
  const { isBluemoonLease } = lease;

  const isProRateWarningShown = showProrationWarning(lease);
  const isNextStepSaved = lease.step > currentStep;

  const { assignLeaseOptionalDocuments, isLoading: isDocumentsAssigning } =
    isBluemoonLease
      ? useAssignLeaseOptionalDocumentsBluemoonQuery(lease.id)
      : useAssignLeaseOptionalDocuments(lease.id);

  const { isLoading: isDocumentsLoading, data: documents } = isBluemoonLease
    ? useLeaseAvailableDocumentsBluemoonQuery(lease.id)
    : useLeaseAvailableDocuments(lease.id);

  const { canManageLeasePackages } = usePermissions();

  const { isAdditionalDocumentsLoading, additionalDocuments } =
    useGetLeaseAdditionalDocuments(lease?.id, {
      enabled: canManageLeasePackages && !isBluemoonLease,
    });

  const {
    isLoading: isLeaseDocumentTemplatesLoading,
    data: leaseDocumentTemplates,
  } = isBluemoonLease
    ? useLeaseDocumentTemplatesBluemoonQuery(lease.id)
    : useLeaseDocumentTemplates(lease.id);

  const groupedDocuments = groupBy(documents, (doc) =>
    doc.isDefault ? "defaultDocuments" : "optionalDocuments"
  );

  groupedDocuments.additionalDocuments = differenceWith(
    additionalDocuments,
    groupedDocuments.defaultDocuments,
    ({ id: id1 }, { id: id2 }) => id1 === id2
  );

  groupedDocuments.optionalDocuments = differenceWith(
    groupedDocuments.optionalDocuments,
    additionalDocuments,
    ({ id: id1 }, { id: id2 }) => id1 === id2
  );

  const toggleDocument = (id, contextDocuments) => (event) => {
    if (event.target.checked) {
      const documentToAdd = contextDocuments.find(
        (document) => document.id === id
      );

      setFinalPackageDocuments([...finalPackageDocuments, documentToAdd]);
    } else {
      setFinalPackageDocuments(filterDocumentsById(id, finalPackageDocuments));
    }
  };

  const removeDocumentFromPackage = (id) => () => {
    setFinalPackageDocuments(filterDocumentsById(id, finalPackageDocuments));
  };

  const verifyDocuments = (issues) => {
    const context = {
      documentErrors: issues,
    };
    openModalDialog(DocumentsVerificationModal, context).afterClose.then(
      (result) => {
        if (result) {
          return Promise.resolve();
        }
        return undefined;
      }
    );
    setNextStepLoading(false);
  };

  const verifyReview = (review) => {
    const showDocumentsWarning =
      review.foundApplicantSignatures < review.expectedApplicantSignatures ||
      review.foundGuarantorSignatures < review.expectedGuarantorSignatures;
    return showDocumentsWarning;
  };

  const onSubmitStep = async () => {
    setNextStepLoading(true);
    const verificationResult = await api.verifyLease(
      lease.id,
      finalPackageDocuments.map((document) => document.id)
    );
    if (
      verificationResult?.data?.review &&
      verifyReview(verificationResult.data.review)
    ) {
      verifyDocuments(verificationResult?.data?.warnings);
    } else {
      const payload = {
        leaseId: lease.id,
        documents: formatDocumentsToAssign(finalPackageDocuments),
        nextStep: isNextStepSaved ? lease.step : currentStep + 1,
      };
      await assignLeaseOptionalDocuments(payload, {
        onSuccess: stepForward,
        onError: () => setNextStepLoading(false),
      });
    }
  };

  const onFinishLater = async () => {
    await assignLeaseOptionalDocuments(
      {
        leaseId: lease.id,
        documents: formatDocumentsToAssign(finalPackageDocuments),
        nextStep: lease.step,
      },
      { onSuccess: redirectBack }
    );
  };

  const previewDocument = (documentId) => {
    const documentToPreview = documents.find(
      (document) => document.id === documentId
    );

    openModalDialog(PreviewDocumentTemplateModal, {
      document: {
        name: documentToPreview.name,
        id: documentToPreview.id,
        isBluemoonLease,
      },
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      finalPackageDocuments,
      result.source.index,
      result.destination.index
    );

    setFinalPackageDocuments(items);
  };

  const onStepBack = () => {
    assignLeaseOptionalDocuments(
      {
        leaseId: lease.id,
        documents: formatDocumentsToAssign(finalPackageDocuments),
        nextStep: lease.step,
      },
      { onSuccess: stepBack }
    );
  };

  useEffect(() => {
    if (documents && leaseDocumentTemplates) {
      const documentsToPackage = buildDocumentsToPackage(
        documents,
        additionalDocuments,
        leaseDocumentTemplates,
        finalPackageDocuments
      );

      setFinalPackageDocuments(documentsToPackage);
    }
  }, [documents, additionalDocuments, leaseDocumentTemplates]);

  return (
    <LeaseDocumentsStep
      isLoading={
        isDocumentsLoading ||
        isAdditionalDocumentsLoading ||
        isLeaseDocumentTemplatesLoading ||
        isDocumentsAssigning
      }
      groupedDocuments={groupedDocuments}
      finalPackageDocuments={finalPackageDocuments}
      onDragEnd={onDragEnd}
      toggleDocument={toggleDocument}
      removeDocumentFromPackage={removeDocumentFromPackage}
      onSubmitStep={onSubmitStep}
      onFinishLater={onFinishLater}
      previewDocument={previewDocument}
      stepBack={onStepBack}
      nextStepLoading={nextStepLoading}
      isProRateWarningShown={isProRateWarningShown}
      isBluemoonLease={isBluemoonLease}
    />
  );
};

LeaseDocumentsStepContainer.propTypes = {
  currentStep: PropTypes.number.isRequired,
  lease: PropTypes.shape({
    deal: PropTypes.shape({
      unit: PropTypes.shape({
        property: PropTypes.shape({
          id: PropTypes.number,
        }),
      }),
    }),
    id: PropTypes.number,
    step: PropTypes.number,
    isBluemoonLease: PropTypes.bool,
  }).isRequired,
  redirectBack: PropTypes.func.isRequired,
  stepForward: PropTypes.func.isRequired,
  stepBack: PropTypes.func.isRequired,
};

export default LeaseDocumentsStepContainer;
