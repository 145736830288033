import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

import { API_URL } from "./setupApi";

const bucketInstance = axios.create();

const api = {
  getDocumentTemplates: async () =>
    axios.get(`${API_URL}${ROUTES.getDocumentTemplates}`),
  assignDocumentToProperties: (documentId: number, propertyIds: number[]) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.assignDocumentToProperties, {
        id: documentId,
      })}`,
      { properties: propertyIds }
    ),
  unassignDocumentFromProperties: (documentId: number, propertyIds: number[]) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.unassignDocumentFromProperties, {
        id: documentId,
      })}`,
      { properties: propertyIds }
    ),
  loadFileByUrl: async (url: string) =>
    bucketInstance
      .get(url, {
        responseType: "arraybuffer",
      })
      .then(({ data }) => data),
};

export default api;
