import React from "react";

import { Col, Row } from "antd";
import { useFormikContext } from "formik";
import get from "lodash/get";

import { Lease } from "manager/interfaces/api/lease";
import { CalculateLeaseTotalRentModel } from "manager/interfaces/api/leaseFeeConfiguration";
import FlexContainer from "shared/components/FlexContainer";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { RegularText, SmallText } from "shared/components/Typography";
import { useDeviceType } from "shared/hooks";

import { LeaseFieldsStepValues } from "../../../interfaces";
import { getChargeFieldName, getRentableFieldName } from "../utils";

import LeaseFeeItem from "./LeaseFeeItem";
import LeaseFeeSubSum from "./LeaseFeeSubSum";
import LeaseFinalProratedSumSection from "./LeaseFinalProratedSumSection";
import LeaseFinalSumSection from "./LeaseFinalSumSection";
import { StyledFeeSummaryInner } from "./styled";

import { getFinalSumFeeItems } from "./utils";

interface SummaryProps {
  leaseCalculations?: CalculateLeaseTotalRentModel;
  canEditFees: boolean;
  lease: Lease;
  openRentableItemsProrationModal: () => void;
}

const LeaseFeeFieldsSummary = ({
  lease,
  leaseCalculations,
  canEditFees,
  openRentableItemsProrationModal,
}: SummaryProps) => {
  const { values, setFieldValue } = useFormikContext<LeaseFieldsStepValues>();
  const { isMobileOrTablet } = useDeviceType();
  const {
    feeConfiguration: { charges, rentableItems },
  } = values;

  const finalSumFeeItems = getFinalSumFeeItems(leaseCalculations, lease);

  const onChargeFieldReset = (i: number) => {
    setFieldValue(
      getChargeFieldName("customAmount", i),
      get(values, getChargeFieldName("baseAmount", i))
    );
  };

  const onRentableFieldReset = (i: number) =>
    setFieldValue(
      getRentableFieldName("customAmount", i),
      get(values, getRentableFieldName("baseAmount", i))
    );

  const separator = (
    <>
      <Spacer size={Spacer.SIZES.xs} />
      <div className="border-bottom" />
      <Spacer size={Spacer.SIZES.md} />
    </>
  );

  const ChargesTitle = isMobileOrTablet ? (
    <SmallText light extraStrong>
      Charges
    </SmallText>
  ) : (
    <RegularText strong>CHARGES</RegularText>
  );

  const RentableItemsTitle = isMobileOrTablet ? (
    <SmallText light extraStrong>
      Rentable Items
    </SmallText>
  ) : (
    <RegularText strong>RENTABLE ITEMS</RegularText>
  );

  return (
    <Tile.Inner noPadding={isMobileOrTablet} noPaddingLtMd>
      <StyledFeeSummaryInner>
        <FlexContainer className="summary-header" alignItems="center">
          <RegularText>Review Total Rent</RegularText>
          {!isMobileOrTablet && <div className="vertical-divider" />}
          <SmallText light>
            Only charges and rentable items marked as ‘Include in Total Rent’
            will appear in the below table.
          </SmallText>
        </FlexContainer>
        <Row gutter={0} type="flex" align="stretch">
          <Col xs={24} lg={9} className="vertical-flex">
            <div className="summary-col-inner border-bottom">
              <div className="summary-col-content">
                <Spacer size={Spacer.SIZES.sm} />
                {ChargesTitle}
                {!isMobileOrTablet && (
                  <>
                    <br />
                    <SmallText light>One Time Payments</SmallText>
                    {separator}
                  </>
                )}
                {charges.map((item, i) =>
                  item.appliedToTotalRent ? (
                    <LeaseFeeItem
                      item={item}
                      fieldName={getChargeFieldName("customAmount", i)}
                      onResetClick={() => onChargeFieldReset(i)}
                      canEditFees={canEditFees}
                      key={item.feeLabel}
                    />
                  ) : null
                )}
                <Spacer size={Spacer.SIZES.md} />
              </div>
            </div>
            <LeaseFeeSubSum
              label="Total Charges"
              amount={leaseCalculations?.chargesAmount}
              canEditFees={canEditFees}
            />
          </Col>
          <Col xs={24} lg={9} className="vertical-flex">
            <div className="summary-col-inner border-bottom">
              <div className="summary-col-content">
                <Spacer size={Spacer.SIZES.sm} />
                {RentableItemsTitle}
                {!isMobileOrTablet && (
                  <>
                    <br />
                    <SmallText light>Recurring Monthly Payments</SmallText>
                    {separator}
                  </>
                )}
                {rentableItems.map((item, i) =>
                  item.appliedToTotalRent ? (
                    <LeaseFeeItem
                      item={item}
                      fieldName={getRentableFieldName("customAmount", i)}
                      onResetClick={() => onRentableFieldReset(i)}
                      canEditFees={canEditFees}
                      key={item.feeLabel}
                    />
                  ) : null
                )}
                <Spacer size={Spacer.SIZES.md} />
              </div>
            </div>
            <LeaseFeeSubSum
              label="Total Rentable Items"
              amount={leaseCalculations?.rentableItemsAmount}
              canEditFees={canEditFees}
            />
          </Col>
          <Col xs={24} lg={6} className="vertical-flex">
            {!isMobileOrTablet && (
              <div className="summary-col-inner border-bottom" />
            )}
            <div className="final-sum-section">
              <LeaseFinalSumSection
                feeItems={finalSumFeeItems}
                className="final-sum-content"
              />
            </div>
          </Col>
        </Row>
        <Spacer size={Spacer.SIZES.md} />
        <LeaseFinalProratedSumSection
          lease={lease}
          leaseCalculations={leaseCalculations}
          openRentableItemsProrationModal={openRentableItemsProrationModal}
        />
      </StyledFeeSummaryInner>
    </Tile.Inner>
  );
};

export default LeaseFeeFieldsSummary;
