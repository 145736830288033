import React from "react";

import { Col, Row, Input } from "antd";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import CourtesyMessageModal from "manager/components/Lumen/CourtesyMessageModal";
import { Multiselect, VeroFormField } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import { Spacer } from "shared/components/Spacer/styled";
import Tooltip from "shared/components/Tooltip";
import { RegularText, SmallTitle } from "shared/components/Typography";
import VerticalStaticNumberedSteps from "shared/components/VerticalStaticNumberedSteps";
import Well from "shared/components/Well";
import { useDeviceType } from "shared/hooks";

import { LabelIconContainer } from "./styled";

const EditApplicantQuestionModal = ({
  submit,
  initialValues,
  questionName,
  documents,
}) => {
  const { isMobile } = useDeviceType();

  return isMobile ? (
    <CourtesyMessageModal message="We recommend using this feature on a desktop for the best user experience." />
  ) : (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submit}
      validateOnChange
    >
      {({ submitForm, isSubmitting, values }) => {
        return (
          <Form>
            <Modal
              title="Link Applicant Question Rider"
              subtitle="For Lease Package"
              submitButtonLabel="SAVE QUESTION"
              submit={submitForm}
              submitting={isSubmitting}
              closeOnSubmit={false}
              width="auto"
              style={{ maxWidth: 700 }}
            >
              <Modal.Body>
                <VerticalStaticNumberedSteps
                  items={[
                    {
                      label: (
                        <LabelIconContainer alignItems="center">
                          <SmallTitle>Question</SmallTitle>
                          <Tooltip title="To edit Applicant Type Questions, view the question in Company Settings.">
                            <Icon.InfoIcon className="extra-info" />
                          </Tooltip>
                        </LabelIconContainer>
                      ),
                      content: (
                        <>
                          <Spacer size="sm" />
                          <Well noBorder>
                            <RegularText underline light>
                              {questionName}
                            </RegularText>
                          </Well>
                        </>
                      ),
                    },
                    {
                      label: (
                        <LabelIconContainer alignItems="center">
                          <SmallTitle>
                            Enter response options and select riders
                          </SmallTitle>
                          <Tooltip title="Select the document(s) for each answer option, if any, to include with the lease package based on the chosen response.">
                            <Icon.InfoIcon className="extra-info" />
                          </Tooltip>
                        </LabelIconContainer>
                      ),
                      content: (
                        <>
                          <Spacer />
                          {values?.options?.map(({ id, answer }, index) => (
                            <Row key={id} gutter={[20, 25]}>
                              <Col md={{ span: 12 }}>
                                <Tooltip title={answer} trigger="hover">
                                  <span>
                                    <Input disabled value={answer} />
                                  </span>
                                </Tooltip>
                              </Col>
                              <Col md={{ span: 12 }}>
                                <VeroFormField
                                  as={Multiselect}
                                  id={`options.${index}.documents`}
                                  name={`options.${index}.documents`}
                                  type="text"
                                  label="Select document(s)"
                                  options={documents}
                                  optional
                                />
                              </Col>
                            </Row>
                          ))}
                        </>
                      ),
                    },
                  ]}
                />
              </Modal.Body>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

EditApplicantQuestionModal.propTypes = {
  submit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  questionName: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      label: PropTypes.string,
    })
  ),
};

EditApplicantQuestionModal.defaultProps = {
  initialValues: {},
  questionName: undefined,
  documents: [],
};

export default EditApplicantQuestionModal;
