import React from "react";

import { useParams } from "react-router-dom";

import { PreviewDocumentTemplateModal } from "manager/components/Leasing/PreviewLeaseModal";
import { useGetPortfolioLeaseSetup } from "manager/hooks/api";

import useModal from "shared/hooks/useModal";

import { Document } from "../interfaces";

import PortfolioDocumentTemplates from "./PortfolioDocumentTemplates";

const PortfolioDocumentTemplatesContainer = () => {
  const { id: portfolioId }: { id?: string } = useParams();
  const { openModalDialog } = useModal();

  const { documentTemplates, isLoading } =
    useGetPortfolioLeaseSetup(portfolioId);

  const previewDocument = (document: Document) =>
    openModalDialog(PreviewDocumentTemplateModal, {
      document: {
        id: document.id,
        name: document.name,
      },
    });

  return (
    <PortfolioDocumentTemplates
      loading={isLoading}
      documentTemplates={documentTemplates}
      previewDocument={previewDocument}
    />
  );
};
export default PortfolioDocumentTemplatesContainer;
