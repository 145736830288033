import React from "react";

import { useCreateLease } from "manager/hooks/api";
import {
  onLeaseCreateError,
  executeCreateLease,
} from "manager/utils/deal.util";
import useModal from "shared/hooks/useModal";

import CreateLeaseModal from "./CreateLeaseModal";

const CreateLeaseModalContainer = (props) => {
  const {
    currentModalContext: { deal },
  } = useModal();

  const unit = deal?.unit;

  const { createLease, isLoading: isCreatingLease } = useCreateLease({
    onError: onLeaseCreateError,
  });

  const handleCreateLease = () => {
    executeCreateLease(deal, () =>
      createLease({ deal: deal.id, unit: unit?.id })
    );
  };

  return (
    <CreateLeaseModal
      createLease={handleCreateLease}
      isCreatingLease={isCreatingLease}
      deal={deal}
      {...props}
    />
  );
};

export default CreateLeaseModalContainer;
