import isNumber from "lodash/isNumber";
import pick from "lodash/pick";
import * as Yup from "yup";

import { LEASE_REMINDER_MAX_SENDS } from "manager/config/constants";
import { LEASE_MIN_DURATION_POLICY } from "shared/config/constants";

export const buildLeaseSetupDTO = (formValues) => {
  return {
    leaseSetup: {
      minLeaseDurationMonths: !isNumber(formValues.minLeaseDurationMonths)
        ? null
        : formValues.minLeaseDurationMonths,
      ...pick(formValues, [
        "leaseDurationPolicy",
        "isElectronicallySigned",
        "autoReminderEnabled",
        "autoReminderFrequency",
        "autoReminderMaxSends",
        "leaseDefaultEndDateToEndOfTheMonth",
      ]),
    },
    companyDocumentTemplates: formValues.documentTemplates.map(
      (documentTemplate) => ({
        documentTemplate: documentTemplate.id,
        isDefault: documentTemplate.isDefault,
      })
    ),
  };
};

export const leaseSettingsValidationSchema = Yup.object().shape({
  minLeaseDurationMonths: Yup.number().nullable(),
  leaseDurationPolicy: Yup.string()
    .oneOf(Object.values(LEASE_MIN_DURATION_POLICY))
    .required("Lease Duration Policy is required"),
  isElectronicallySigned: Yup.bool().required(
    "Lease E-signing flag is required"
  ),
  autoReminderEnabled: Yup.bool().required("Lease Reminder flag is required"),
  autoReminderFrequency: Yup.number().required("Frequency is required"),
  autoReminderMaxSends: Yup.number()
    .integer("The number should be integer")
    .min(
      LEASE_REMINDER_MAX_SENDS.min,
      `Min number is ${LEASE_REMINDER_MAX_SENDS.min}`
    )
    .max(
      LEASE_REMINDER_MAX_SENDS.max,
      `Max number is ${LEASE_REMINDER_MAX_SENDS.max}`
    )
    .required("Max sends is required"),
  leaseDefaultEndDateToEndOfTheMonth: Yup.bool().nullable(),
});
