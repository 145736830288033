
import pluralize from "pluralize";

import FlexContainer from "shared/components/FlexContainer";
import { RegularText } from "shared/components/Typography";
import { RenewalOption } from "shared/interfaces/api/renewals";
import { printDollarsFromCents } from "shared/utils/dollar-print";

export const ConcessionTooltipTitle = ({
  option,
}: {
  option: RenewalOption;
}) => {
  const concession = option?.concession;
  return option?.hasConcession ? (
    <FlexContainer flexDirection="column">
      <RegularText>
        Total Concession: {printDollarsFromCents(option?.concessionAmount)}
      </RegularText>
      <RegularText>
        Concession Duration: {concession?.numberOfMonths}{" "}
        {pluralize("Month", concession?.numberOfMonths)}
      </RegularText>
    </FlexContainer>
  ) : (
    <>No concessions</>
  );
};
