import React from "react";

import { Form, Formik } from "formik";

import InviteApplicantsWithLeadsModal from "manager/components/Modal/InviteApplicantsWithLeadsModal";
import { useAddApplicantsToRenewal } from "manager/hooks/api";
import { RenewalBasic } from "manager/interfaces/api/renewals";
import useModal from "shared/hooks/useModal";

import AddRenewalLeaseholderModal from "./AddRenewalLeaseholderModal";
import { AddLeaseholderFormValues, InvitationMode } from "./interfaces";

const initialValues: AddLeaseholderFormValues = {
  invitation: undefined,
  resident: undefined,
};

const AddRenewalLeaseholderModalContainer = () => {
  const { currentModalContext, openModalDialog, closeActiveModalDialog } =
    useModal();
  const { addApplicantsToRenewal } = useAddApplicantsToRenewal();
  const renewal = currentModalContext.renewal as RenewalBasic;

  const saveApplicants = (applicants = [], skipScreening = false) =>
    addApplicantsToRenewal({
      renewalId: renewal?.id,
      applicants:
        applicants?.map((applicant) => ({ ...applicant, skipScreening })) || [],
    });

  const saveWithInviteApplicantsModal = (skipScreening = false) =>
    openModalDialog(InviteApplicantsWithLeadsModal, {
      applicants: renewal?.residents,
      onSubmit: ({ applicants }) =>
        saveApplicants(applicants, skipScreening).finally(() =>
          closeActiveModalDialog()
        ),
    });

  const onSubmit = async (values: AddLeaseholderFormValues) => {
    if (values.invitation === InvitationMode.existingApplicant) {
      const existingResident = renewal.residents.find(
        (r) => r.id === values.resident
      );
      await saveApplicants([
        {
          email: existingResident?.email,
          firstName: existingResident?.firstName,
          lastName: existingResident?.lastName,
        },
      ]);
    } else if (values.invitation === InvitationMode.newApplicantWithScreening) {
      saveWithInviteApplicantsModal();
    } else if (
      values.invitation === InvitationMode.newApplicantWithoutScreening
    ) {
      saveWithInviteApplicantsModal(true);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <AddRenewalLeaseholderModal residents={renewal?.residents} />
      </Form>
    </Formik>
  );
};

export default AddRenewalLeaseholderModalContainer;
