import styled from "@emotion/styled";

import { RegularText } from "shared/components/Typography";
import { COLORS } from "shared/config/constants";

const RegularTextPurple = styled(RegularText)`
  color: ${COLORS.purple};
`;

export { RegularTextPurple };
