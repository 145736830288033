import React, { useMemo, useRef } from "react";

import isEmpty from "lodash/isEmpty";
import join from "lodash/join";
import PropTypes from "prop-types";

import {
  convertModelConcessionMonthToForm,
  convertFormConcessionMonthToModel,
} from "manager/components/Leasing/Concession";
import {
  useGetLeasePackagesManagerAnswers,
  useGetLeaseAdditionalManagerQuestions,
  useGetUnit,
  useUpdateLeaseTerms,
} from "manager/hooks/api";
import { getIntegrationType } from "manager/utils/integration.util";
import {
  checkSecurityDepositControlledByIntegration,
  shouldSecurityDepositUseRentValue,
  isMonthlyRentDisabled,
} from "manager/utils/unit.util";
import { CONCESSION_TYPES } from "shared/config/constants";
import { useActiveCompany, usePermissions } from "shared/hooks";
import { calculateDurationBetweenMonthsNormalized } from "shared/utils/dates";
import { setTouchedAndValidate } from "shared/utils/forms";

import { leaseDetailsPropType } from "../propTypes";

import LeaseInfoStep from "./LeaseInfoStep";
import { MAXIMUM_LEASE_DURATION } from "./LeaseInfoStep.constants";
import {
  getInitialValues,
  isFirstMonthProRateEnabled,
} from "./LeaseInfoStep.utils";

const getLeaseSubmitData = (values) => {
  const concessionMonths =
    values.concessionType === CONCESSION_TYPES.specificMonths
      ? values.dates.map(convertFormConcessionMonthToModel)
      : [];

  return {
    rent: values.monthlyRent,
    securityDeposit: values.securityDeposit,
    startDate: values.leaseStartDate,
    endDate: values.leaseEndDate,
    hasConcession: values.isConcessionsEnabled,
    concessionType: values.concessionType,
    concessionNumberOfMonths: Number(values.numberOfMonths),
    endDateToEndOfTheMonth: values.endDateToEndOfTheMonth,
    managerAnswers: values.managerAnswers,
    concessionMonths,
    prorateFirstMonthRent: values.prorateFirstMonthRent,
  };
};

const getLeaseFormData = (
  sourceData,
  company,
  unit,
  managerQuestions,
  managerAnswers
) => {
  const leaseDuration = calculateDurationBetweenMonthsNormalized(
    sourceData.startDate,
    sourceData.endDate
  );

  return {
    ...getInitialValues(),
    leaseStartDate: sourceData.startDate,
    leaseEndDate: sourceData.endDate,
    leaseDuration:
      leaseDuration && leaseDuration <= MAXIMUM_LEASE_DURATION
        ? leaseDuration
        : undefined,
    endDateToEndOfTheMonth: sourceData.endDateToEndOfTheMonth,
    monthlyRent: sourceData.rent,
    securityDeposit: sourceData.securityDeposit,
    minLeaseDurationMonths: sourceData.minLeaseDurationMonths,
    isConcessionsEnabled:
      Boolean(sourceData.hasConcessionRider) && sourceData.hasConcession,
    hasConcessionDocuments: sourceData.hasConcessionRider,
    concessionType: sourceData.concessionType,
    numberOfMonths: sourceData.concessionNumberOfMonths,
    dates:
      sourceData.concessionMonths?.map(convertModelConcessionMonthToForm) || [],
    managerAnswers: managerQuestions?.map(({ id: questionId }) => {
      const foundAnswer = managerAnswers?.find(
        ({ additionalQuestion }) => additionalQuestion === questionId
      );

      return {
        answerOption: foundAnswer?.answerOption,
        additionalQuestion: questionId,
        isActive: true,
      };
    }),
    managerQuestions,
    prorateFirstMonthRent: sourceData.prorateFirstMonthRent,
  };
};

const getLeaseErrors = (errors) => ({
  monthlyRent: join(errors.rent),
  leaseStartDate: join(errors.startDate),
  leaseEndDate: join(errors.endDate),
  securityDeposit: join(errors.securityDeposit),
});

const useDisabledLeaseInfoFields = (company, unit) => {
  return useMemo(() => {
    if (company && unit) {
      const integrationType = getIntegrationType(unit);
      const isSecurityDepositDisabled =
        checkSecurityDepositControlledByIntegration(integrationType, company);
      return {
        securityDeposit: isSecurityDepositDisabled,
        securityDepositBoundToRent: shouldSecurityDepositUseRentValue(
          integrationType,
          company
        ),
        monthlyRent: isMonthlyRentDisabled(integrationType, company),
      };
    }
    return {};
  }, [company, unit]);
};

const LeaseInfoStepContainer = ({
  lease,
  currentStep,
  redirectBack,
  stepForward,
}) => {
  const formikRef = useRef(null);
  const unitId = lease?.deal?.unit?.id;
  const { unit } = useGetUnit(unitId);
  const { activeCompany } = useActiveCompany();
  const disabledLeaseInfoFields = useDisabledLeaseInfoFields(
    activeCompany,
    unit
  );
  const isNextStepSaved = lease.step > currentStep;
  const isProRateEnabled = isFirstMonthProRateEnabled(lease);
  const { canManageLeasePackages } = usePermissions();
  const { managerQuestions, isManagerQuestionsLoading } =
    useGetLeaseAdditionalManagerQuestions(lease?.id, {
      enabled: canManageLeasePackages,
    });
  const { managerAnswers, isManagerAnswersLoading } =
    useGetLeasePackagesManagerAnswers(lease?.id, {
      enabled: canManageLeasePackages,
    });
  const leaseFormData =
    lease &&
    getLeaseFormData(
      lease,
      activeCompany,
      unit,
      managerQuestions,
      managerAnswers
    );

  const onError = (serverErrors) => {
    const errors = getLeaseErrors(serverErrors.errors);
    formikRef.current.setErrors(errors);
    formikRef.current.setTouched({
      monthlyRent: true,
      leaseStartDate: true,
      leaseEndDate: true,
      securityDeposit: true,
    });
    formikRef.current.setSubmitting(false);
  };

  const { updateLeaseTerms, isLoading: isLeaseMetadataUpdating } =
    useUpdateLeaseTerms();

  const onSubmitStep = async (values) => {
    const isDirty = formikRef.current.dirty || !isNextStepSaved;
    if (isDirty) {
      await updateLeaseTerms(
        {
          id: lease.id,
          data: {
            ...getLeaseSubmitData(values),
            step: isNextStepSaved ? lease.step : currentStep + 1,
          },
        },
        {
          onSuccess: stepForward,
          onError,
        }
      );
    } else {
      stepForward();
    }
  };

  const onFinishLater = async () => {
    if (formikRef.current.dirty) {
      const errors = await setTouchedAndValidate(formikRef);

      if (!isEmpty(errors)) {
        return;
      }

      formikRef.current.setSubmitting(true);
      await updateLeaseTerms(
        {
          id: lease.id,
          data: {
            ...getLeaseSubmitData(formikRef.current.values),
            step: isNextStepSaved ? lease.step : currentStep,
          },
        },
        {
          onSuccess: redirectBack,
          onError,
        }
      );
    } else {
      redirectBack();
    }
  };

  return (
    <LeaseInfoStep
      isLeaseMetadataUpdating={isLeaseMetadataUpdating}
      leaseFormData={leaseFormData}
      managerQuestionsData={{ managerQuestions, managerAnswers }}
      managerQuestionsDataLoading={
        isManagerQuestionsLoading || isManagerAnswersLoading
      }
      unit={unit}
      company={activeCompany}
      onFinishLater={onFinishLater}
      onSubmitStep={onSubmitStep}
      formikRef={formikRef}
      disabledLeaseInfoFields={disabledLeaseInfoFields}
      leaseSecurityDepositStatus={lease.securityDepositDeductionStatus}
      leaseId={lease.id}
      isProRateEnabled={isProRateEnabled}
    />
  );
};

LeaseInfoStepContainer.propTypes = {
  lease: leaseDetailsPropType.isRequired,
  currentStep: PropTypes.number.isRequired,
  redirectBack: PropTypes.func.isRequired,
  stepForward: PropTypes.func.isRequired,
};

export default LeaseInfoStepContainer;
