import React, { Key } from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { useWithdrawLease } from "manager/hooks/api";
import { transformRoute } from "shared/utils/routing";

import WithdrawLeaseModal from "./WithdrawLeaseModal";

interface WithdrawLeaseModalContainerProps {
  leaseId: Key;
  unitName: string;
  propertyName: string;
}

const WithdrawLeaseModalContainer = ({
  leaseId,
  unitName,
  propertyName,
}: WithdrawLeaseModalContainerProps) => {
  const history = useHistory();

  const { withdrawLease, isLoading: isWithdrawingLease } =
    useWithdrawLease(leaseId);

  const submit = () =>
    withdrawLease(leaseId, {
      onSuccess: () =>
        history.push(transformRoute(ROUTES.leaseSummary, { id: leaseId })),
    });

  return (
    <WithdrawLeaseModal
      submit={submit}
      isWithdrawingLease={isWithdrawingLease}
      unitName={unitName}
      propertyName={propertyName}
    />
  );
};

export default WithdrawLeaseModalContainer;
