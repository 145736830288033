import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";
import { COLORS } from "shared/config/constants";

export const Note = styled.p`
  font-size: 15px;
  font-weight: 300;
  line-height: 100%;
  margin-bottom: 25px;
`;

export const SelectPropertiesContainer = styled.div`
  margin-bottom: 32px;
`;

export const ModalBody = styled(Modal.Body)`
  padding-bottom: 0;
`;

export const WarningContainer = styled.div`
  background-color: ${COLORS.background};
  border: 1px solid ${COLORS.borders};
  border-top: 0;
  display: flex;
  padding: 15px 40px 15px 25px;
`;

export const Text = styled.p`
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
  margin-left: 20px;
`;
