import React from "react";

import { Helmet } from "react-helmet";

import Icon from "shared/components/Icon";
import LargeLoader from "shared/components/LargeLoader";
import { UnderlineLink } from "shared/components/Links";
import { SUPPORT_EMAIL } from "shared/config/constants";
import InfoPage from "shared/templates/InfoPage";

import { LoginSSOBouncePageProps } from "./interfaces";

const LoginSSOBouncePage = ({
  isLoading,
  errorMessage,
  onBackButtonClick,
}: LoginSSOBouncePageProps) => {
  const showErrorMessage = !isLoading && !!errorMessage;

  return (
    <>
      <Helmet>
        <title>Sign In - SSO</title>
      </Helmet>
      <LargeLoader showLoader={isLoading} absoluteCenter />
      {showErrorMessage && (
        <InfoPage
          title={errorMessage.message}
          description={
            errorMessage.description || (
              <>
                You can contact the VERO support team at{" "}
                <UnderlineLink href={`mailto:${SUPPORT_EMAIL}`}>
                  {SUPPORT_EMAIL}
                </UnderlineLink>{" "}
                or
                <br />
                use the live chat.
              </>
            )
          }
          button={{
            children: "Back",
            onClick: onBackButtonClick,
            PrefixIcon: Icon.ButtonBackArrowIcon,
          }}
          MainIcon={Icon.OrangeExclamationHollow}
        />
      )}
    </>
  );
};

export default LoginSSOBouncePage;
