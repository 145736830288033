import React from "react";

import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

import { Button } from "shared/components/Button";
import { PDFViewer, DownloadButton } from "shared/components/DocumentViewer";
import Icon from "shared/components/Icon";
import { BackLink } from "shared/components/Links";
import Tooltip from "shared/components/Tooltip";
import {
  BREAKPOINT_RESOLUTIONS,
  LEASE_STATUS_CODES,
} from "shared/config/constants";

import LeaseLoading from "../LeaseLoading";

import {
  SaveLeaseProcessContainer,
  SaveLeaseProcessIcon,
  SaveLeaseProcessText,
} from "../styled";

import { Container, LinkWrapper, footerStyles } from "./styled";

const E_SIGN_MESSAGE =
  "eSignatures are not active for this property. Please either download the lease for offline signing or reach out to your VERO account manager for help.";

const LeasePreviewStep = ({
  stepBack,
  loading,
  sendLease,
  status,
  isESigningEnabled,
  redirectBack,
  downloadLease,
  isUpdatingStatus,
  pdfApi,
  isBluemoonLease,
  counterSignBluemoonLease,
}) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });
  const isTablet = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.md });
  const isLeaseSignedByApplicants =
    status === LEASE_STATUS_CODES.applicantsSigned;
  const SendLeaseButton = (
    <Button
      type="primary"
      onClick={sendLease}
      data-testid="send-button"
      disabled={!isESigningEnabled}
      PrefixIcon={isTablet ? Icon.SendIcon : undefined}
    >
      {isTablet ? "Send" : "Send to applicants"}
    </Button>
  );

  const CounterSignButton = (
    <Button
      type="primary"
      onClick={counterSignBluemoonLease}
      data-testid="send-button"
      PrefixIcon={isTablet ? Icon.SendIcon : undefined}
    >
      Countersign
    </Button>
  );

  const MobileCurrentPage = (
    <span>
      {pdfApi.currentPage}/{pdfApi.totalPages}
    </span>
  );

  const WithdrawButton = (
    <LinkWrapper>
      <SaveLeaseProcessContainer
        onClick={redirectBack}
        data-testid="finish-later"
      >
        <SaveLeaseProcessIcon />
        <SaveLeaseProcessText>
          {isTablet ? "Withdraw" : "Withdraw Lease"}
        </SaveLeaseProcessText>
      </SaveLeaseProcessContainer>
    </LinkWrapper>
  );

  const SaveAndFinishLater = (
    <LinkWrapper>
      <SaveLeaseProcessContainer
        onClick={redirectBack}
        data-testid="finish-later"
      >
        <SaveLeaseProcessIcon />
        <SaveLeaseProcessText>
          {isTablet ? "Save" : "Save & Finish Later"}
        </SaveLeaseProcessText>
      </SaveLeaseProcessContainer>
    </LinkWrapper>
  );

  return (
    <Container>
      {loading && <LeaseLoading />}
      <PDFViewer
        viewerApi={pdfApi}
        showZoomControls
        LeftTools={
          <BackLink onClick={stepBack} size="lg" data-testid="previous-step">
            Previous Step
          </BackLink>
        }
        CentralTools={
          <>
            {isTablet && !isMobile && MobileCurrentPage}

            <DownloadButton
              onDownloadClick={downloadLease}
              isDownloading={pdfApi.isDocumentDownloading || isUpdatingStatus}
              disabled={!pdfApi.isDocumentLoaded}
            />

            {!isBluemoonLease && isMobile && SaveAndFinishLater}
            {isBluemoonLease && isMobile && WithdrawButton}
          </>
        }
        RightTools={
          <>
            {!isBluemoonLease && !isMobile && SaveAndFinishLater}
            {isBluemoonLease && !isMobile && WithdrawButton}
            {isBluemoonLease && isLeaseSignedByApplicants && CounterSignButton}

            {!isLeaseSignedByApplicants &&
              (isESigningEnabled ? (
                SendLeaseButton
              ) : (
                <Tooltip title={E_SIGN_MESSAGE} trigger="hover" theme="dark">
                  <div data-testid="tooltip-wrapper">{SendLeaseButton}</div>
                </Tooltip>
              ))}
          </>
        }
        id="viewer"
        showDownloadButton={false}
        showZoomSelect={false}
        showPageNumber={!isTablet}
        footerStyles={footerStyles}
      />
    </Container>
  );
};

LeasePreviewStep.propTypes = {
  loading: PropTypes.bool.isRequired,
  stepBack: PropTypes.func.isRequired,
  redirectBack: PropTypes.func.isRequired,
  sendLease: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  counterSignBluemoonLease: PropTypes.func.isRequired,
  isESigningEnabled: PropTypes.bool,
  downloadLease: PropTypes.func.isRequired,
  pdfApi: PropTypes.shape({
    isDocumentDownloading: PropTypes.bool.isRequired,
    isDocumentLoaded: PropTypes.bool.isRequired,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
  }).isRequired,
  isUpdatingStatus: PropTypes.bool.isRequired,
  isBluemoonLease: PropTypes.bool,
};

LeasePreviewStep.defaultProps = {
  isESigningEnabled: false,
};

export default LeasePreviewStep;
