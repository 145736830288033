export interface EmailTemplateAttachment {
  id: number;
  filename: string;
  upload: string;
  customConfiguration: number;
  defaultConfiguration?: number;
}

export interface CustomEmailTemplate {
  id: number;
  name: string;
  subject: string;
  title: string;
  body: string;
  hasCta: boolean;
  hideCta: boolean;
  ctaLabel: string;
  variables?: string[];
  selected: boolean;
  attachments: EmailTemplateAttachment[];
  defaultConfigurationId: number;
  companyId: number;
}

export enum DefaultEmailType {
  DEAL_APPROVED_WITH_UNIT = "DEAL_APPROVED_WITH_UNIT",
  DEAL_APPROVED_WITHOUT_UNIT = "DEAL_APPROVED_WITHOUT_UNIT",
  LEASE_COUNTERSIGN = "LEASE_COUNTERSIGN",
  LEASE_RENEWAL_NOTICE = "LEASE_RENEWAL_NOTICE",
  LEASE_RENEWAL_OFFER = "LEASE_RENEWAL_OFFER",
}
