import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useFormikContext } from "formik";
import capitalize from "lodash/capitalize";
import throttle from "lodash/throttle";
import pluralize from "pluralize";
import PropTypes from "prop-types";

import Container from "shared/components/Container";
import { conditionalItem } from "shared/utils/array";

import { leaseDetailsPropType } from "../../propTypes";
import { feeFieldsPropType, totalRentCalcFieldsPropType } from "../propTypes";
import { DocumentPages } from "../styled";
import {
  getShowChargesRentableItems,
  isElementVisible,
  LEASE_FEE_FIELDS_SECTION,
} from "../utils";

import DocumentFields from "./DocumentFields";
import LeaseFeeFieldsSection from "./LeaseFeeFieldsSection";
import {
  TilesContainer,
  LeaseFieldsDescription,
  DocumentsMenu,
  Documents,
} from "./styled";

const SCROLL_TIMEOUT = 300;

const LeaseFieldsForm = ({
  documents,
  updatedDocuments,
  lease,
  feeFields,
  totalRentCalcFields,
}) => {
  const { isBluemoonLease } = lease;

  const {
    values: { feeConfiguration },
  } = useFormikContext();

  const showChargesRentableItems = getShowChargesRentableItems(
    feeConfiguration,
    feeFields
  );

  const menuSections = [
    ...conditionalItem(showChargesRentableItems, LEASE_FEE_FIELDS_SECTION),
    ...updatedDocuments,
  ];
  const [activeKey, setActiveKey] = useState(menuSections[0]?.anchor);

  const menuItems = menuSections.map(
    ({ anchor: key, label, numberOfPages, language }) => ({
      key,
      label,
      rightSide: (
        <DocumentPages>
          {`${
            language ? `${capitalize(language)}, ` : ""
          }${numberOfPages} ${pluralize("page", numberOfPages)}`}
        </DocumentPages>
      ),
    })
  );

  const elementIds = useMemo(() => {
    const sections = [
      ...conditionalItem(showChargesRentableItems, LEASE_FEE_FIELDS_SECTION),
      ...documents,
    ];
    const sectionIds = sections
      .map(({ anchor }) => `#${anchor.replace(/^[0-9]+/g, "")}`)
      .join(", ");
    return sectionIds;
  }, [documents, showChargesRentableItems]);

  const handleScroll = useCallback(
    throttle(() => {
      const elements = [...document.querySelectorAll(elementIds)].reverse();
      const visibleElement = elements.find((el) => isElementVisible(el));

      if (visibleElement) {
        setActiveKey(visibleElement.id);
      }
    }, SCROLL_TIMEOUT),
    [elementIds]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [handleScroll]);

  return (
    <Container noMobilePaddingX noPaddingTop data-testid="lease-info-form">
      <LeaseFieldsDescription>
        Please fill in the fields below
      </LeaseFieldsDescription>

      <TilesContainer>
        {documents.length > 0 && (
          <DocumentsMenu activeKey={activeKey} items={menuItems} isLink />
        )}

        <Documents>
          {showChargesRentableItems ? (
            <LeaseFeeFieldsSection
              lease={lease}
              feeFields={feeFields}
              totalRentCalcFields={totalRentCalcFields}
            />
          ) : null}
          {documents?.map((document) => (
            <DocumentFields
              document={document}
              key={document.key}
              isBluemoonLease={isBluemoonLease}
              fieldstoHide={feeFields}
            />
          ))}
        </Documents>
      </TilesContainer>
    </Container>
  );
};

LeaseFieldsForm.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object),
  updatedDocuments: PropTypes.arrayOf(PropTypes.object),
  lease: leaseDetailsPropType.isRequired,
  feeFields: feeFieldsPropType,
  totalRentCalcFields: totalRentCalcFieldsPropType.isRequired,
};

LeaseFieldsForm.defaultProps = {
  documents: [],
  updatedDocuments: [],
  feeFields: undefined,
};

export default LeaseFieldsForm;
