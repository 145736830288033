import React from "react";

import { Row } from "antd";
import { Helmet } from "react-helmet";

import ROUTES from "manager/config/routes";
import Container from "shared/components/Container";
import Icon from "shared/components/Icon";
import LoaderContainer, {
  BigLoadingSpinner,
} from "shared/components/LoaderContainer";
import PageTitle from "shared/components/PageTitle";

import SettingsTile from "./SettingsTile";

const title = "Company Settings";

interface SettingsProps {
  isDownloadCenterVisible?: boolean;
  isLumenAdmin: boolean;
  isOpenApiEnabled: boolean;
  isPrescreeningEnabled: boolean;
  loading: boolean;
}

const Settings = ({
  isDownloadCenterVisible = false,
  isLumenAdmin,
  isOpenApiEnabled,
  isPrescreeningEnabled,
  loading,
}: SettingsProps) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {/* @ts-ignore */}
    <Container noPaddingBottom>
      <PageTitle.Container>
        <PageTitle>{title}</PageTitle>
      </PageTitle.Container>
    </Container>
    {/* @ts-ignore */}
    <Container noMobilePaddingX noPaddingTop maxWidth="xl">
      {loading ? (
        <LoaderContainer
          Loader={<BigLoadingSpinner data-testid="loading" />}
          fetching
        />
      ) : (
        <Row type="flex" gutter={[16, 2]}>
          <SettingsTile
            name="Application & Lease Setup"
            Icon={Icon.DealsLargeIcon}
            to={ROUTES.applicationsLeaseSetup}
          />
          {isPrescreeningEnabled && (
            <SettingsTile
              name="Prequalification Setup"
              Icon={Icon.PrescreeningLeadsVerification}
              to={ROUTES.prescreeningSetup}
            />
          )}
          {isLumenAdmin && (
            <SettingsTile
              name="Lumen"
              Icon={Icon.LumenBig}
              to={ROUTES.lumenDashboard}
            />
          )}
          <SettingsTile
            name="Magic Links"
            Icon={Icon.MagicLinksLogoIcon}
            to={ROUTES.magicLinks}
          />
          <SettingsTile
            name="Employees"
            Icon={Icon.EmployeesLargeIcon}
            to={ROUTES.employees}
          />
          {isOpenApiEnabled && (
            <SettingsTile
              name="Open API Setup"
              Icon={Icon.OpenApiIcon}
              to={ROUTES.openApiSettings}
            />
          )}
          {isDownloadCenterVisible && (
            <SettingsTile
              name="Download Center"
              Icon={Icon.CloudDownloadIcon}
              to={ROUTES.downloadCenter}
            />
          )}
        </Row>
      )}
    </Container>
  </>
);

export default Settings;
