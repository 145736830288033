import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Col, Row } from "antd";

import Tile from "shared/components/Tile";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const FeeFieldsTile = styled(Tile)`
  .border-bottom {
    border-bottom: 1px solid ${COLORS.borders};
  }

  .quantity-select {
    min-width: 90px;

    .ant-select-selection {
      min-width: 90px;
    }
  }
`;

const HeaderRow = styled(Row)`
  padding: 8px 24px;
  margin: 4px 0;

  ${MEDIA_QUERIES.w.ltLg} {
    padding: 8px 0px;
  }
`;

interface HeaderColProps {
  textAlignRight?: boolean;
}

const textAlignRight = (props: HeaderColProps) => {
  if (props.textAlignRight) {
    return css`
      text-align: right !important;
    `;
  }

  return css``;
};

const HeaderCol = styled(Col)<HeaderColProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 35px;

  ${textAlignRight}

  &.total-rent-title {
    padding-left: 24px;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    &.total-rent-title {
      padding-left: 0;
    }
  }
`;

const GrayRow = styled(Row)`
  background: ${COLORS.lightGray2};
  padding: 8px 0px;
  margin: 4px 0;

  ${MEDIA_QUERIES.w.ltLg} {
    padding: 24px 0px;
  }
`;

interface GrayColProps {
  noPaddingLeft?: boolean;
  noBorderRight?: boolean;
  paddingLeftLg?: boolean;
}

const noPaddingLeft = (props: GrayColProps) => {
  if (props.noPaddingLeft) {
    return css`
      padding-left: 0 !important;
    `;
  }

  return css``;
};

const noBorderRight = (props: GrayColProps) => {
  if (props.noBorderRight) {
    return css`
      border-right: none !important;
    `;
  }

  return css``;
};

const paddingLeftLg = (props: GrayColProps) => {
  if (props.paddingLeftLg) {
    return css`
      padding-left: 56px !important;
    `;
  }

  return css``;
};

const GrayCol = styled(Col)<GrayColProps>`
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  border-right: 1px solid ${COLORS.borders};

  &:first-of-type {
    padding-left: 24px;
  }

  &:last-of-type {
    padding-right: 24px;
    border-right: none;
  }

  ${MEDIA_QUERIES.w.ltXl} {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${noPaddingLeft}
  ${noBorderRight}
  ${paddingLeftLg}
`;

export { FeeFieldsTile, GrayRow, GrayCol, HeaderRow, HeaderCol };
