import React from "react";

import PropTypes from "prop-types";

import { Bar, Title, ActionLink } from "./styled";

const ApplicationAnswersBar = ({
  isApplicationAnswersLoading,
  openAnswersModal,
  title,
}) => {
  return (
    <Bar data-testid="application-answers-bar">
      <Title data-testid="application-answers-title">{title}</Title>

      <ActionLink
        onClick={openAnswersModal}
        disabled={isApplicationAnswersLoading}
        data-testid="review-answers-link"
      >
        Review Answers
      </ActionLink>
    </Bar>
  );
};

ApplicationAnswersBar.propTypes = {
  isApplicationAnswersLoading: PropTypes.bool.isRequired,
  openAnswersModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ApplicationAnswersBar;
