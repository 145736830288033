import pluralize from "pluralize";
import { queryCache, QueryConfig, useMutation, useQuery } from "react-query";

import {
  RenewalBasic,
  RenewalNote,
  RenewalsPaginationRequest,
} from "manager/interfaces/api/renewals";
import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { PaginationResponse } from "shared/interfaces/pagination";
import { useNotFoundErrorHandler } from "shared/utils/queries.utils";
import { openNotification } from "shared/utils/ui";

import {
  GET_LEASE_GROUPS,
  invalidateLeaseGroupApplicationsCache,
} from "./leaseGroupsQueries";

const GET_RENEWALS = "getRenewals";
const GET_RENEWAL_NOTES = "getRenewalNotes";
const GET_RENEWAL_OFFER = "getRenewalOffer";

const QUERY_KEYS = Object.freeze({
  getRenewalsByFilters: (filters) => [GET_RENEWALS, filters],
  getRenewalById: (id: number | string) => [GET_RENEWALS, Number(id)],
  getRenewalNotesById: (id: number | string) => [GET_RENEWAL_NOTES, Number(id)],
  getRenewalOffer: (renewalId, offerId) => [
    GET_RENEWAL_OFFER,
    Number(renewalId),
    Number(offerId),
  ],
});

const invalidateRenewalsCache = () => {
  queryCache.invalidateQueries(GET_RENEWALS);
  queryCache.invalidateQueries(GET_LEASE_GROUPS);
};

const invalidateRenewalOfferCache = () => {
  queryCache.invalidateQueries(GET_RENEWAL_OFFER);
};

export const useGetRenewals = (
  filtersOrderingAndPagination: RenewalsPaginationRequest,
  config: Partial<QueryConfig<PaginationResponse<RenewalBasic>>> = {}
) => {
  const {
    data: renewals,
    isLoading: isRenewalsLoading,
    refetch: refetchRenewals,
  } = useQuery(
    QUERY_KEYS.getRenewalsByFilters(filtersOrderingAndPagination),
    () => api.getRenewals(filtersOrderingAndPagination),
    {
      onError: () => {
        openNotification("Failed to load renewals", NOTIFICATIONS.error);
      },
      ...config,
    }
  );

  return {
    renewals,
    isRenewalsLoading,
    refetchRenewals,
  };
};

export const useGetRenewal = (id: number) => {
  const notFoundErrorHandler = useNotFoundErrorHandler();
  const { data, isLoading } = useQuery(
    QUERY_KEYS.getRenewalById(id),
    () => api.getRenewal(id),
    {
      enabled: !!id,
      forceFetchOnMount: true,
      onError: (error) => {
        if (!notFoundErrorHandler(error)) {
          openNotification("Failed to load the renewal", NOTIFICATIONS.error);
        }
      },
      retry: false,
    }
  );
  return {
    renewal: data,
    isRenewalLoading: isLoading,
  };
};

export const useGetRenewalNotes = (
  id: number,
  config?: QueryConfig<RenewalNote[]>
) => {
  const { data: renewalNotes, isLoading: isRenewalNotesLoading } = useQuery(
    QUERY_KEYS.getRenewalNotesById(id),
    () => api.getRenewalNotes(id),
    {
      enabled: Boolean(id),
      onError: () =>
        openNotification(
          "Failed to load the renewal notes",
          NOTIFICATIONS.error
        ),
      ...config,
    }
  );
  return {
    renewalNotes,
    isRenewalNotesLoading,
  };
};

export const useSendRequestRenewal = () => {
  const [sendRequestRenewal, { isLoading: isSendRequestRenewalLoading }] =
    useMutation(api.sendRequestRenewal, {
      onSuccess: () => {
        openNotification(
          "Successfully requested renewal creation",
          NOTIFICATIONS.info
        );
        invalidateRenewalsCache();
      },
      onError: () => {
        openNotification(
          "Failed to request renewal creation",
          NOTIFICATIONS.error
        );
      },
    });

  return {
    sendRequestRenewal,
    isSendRequestRenewalLoading,
  };
};

export const useCreateRenewal = () => {
  const [createRenewal, { isLoading: isCreateRenewalLoading }] = useMutation(
    api.createRenewal,
    {
      onSuccess: () => {
        openNotification("Successfully created renewal", NOTIFICATIONS.info);
        invalidateRenewalsCache();
        invalidateLeaseGroupApplicationsCache();
      },
      onError: () => {
        openNotification("Failed to create renewal", NOTIFICATIONS.error);
      },
    }
  );

  return {
    createRenewal,
    isCreateRenewalLoading,
  };
};

export const useSendRenewalOfferRequest = () => {
  const [
    sendRenewalOfferRequest,
    { isLoading: isSendRenewalOfferRequestLoading },
  ] = useMutation(api.sendRenewalOfferRequest, {
    onSuccess: () => {
      openNotification(
        "Successfully sent the renewal offer request",
        NOTIFICATIONS.info
      );
      invalidateRenewalsCache();
    },
    onError: () => {
      openNotification(
        "Failed to send the renewal offer request",
        NOTIFICATIONS.error
      );
    },
  });

  return {
    sendRenewalOfferRequest,
    isSendRenewalOfferRequestLoading,
  };
};

export const useSendRenewalOfferApprovalReminder = () => {
  const [
    sendRenewalOfferApprovalReminder,
    { isLoading: isSendRenewalOfferApprovalReminderLoading },
  ] = useMutation(api.sendRenewalOfferApprovalReminder, {
    onSuccess: () => {
      openNotification(
        "Successfully sent the renewal offer approval reminder",
        NOTIFICATIONS.info
      );
      invalidateRenewalsCache();
    },
    onError: () => {
      openNotification(
        "Failed to send the renewal offer approval reminder",
        NOTIFICATIONS.error
      );
    },
  });

  return {
    sendRenewalOfferApprovalReminder,
    isSendRenewalOfferApprovalReminderLoading,
  };
};

export const useApproveRenewalOffers = () => {
  const [approveRenewalOffers, { isLoading: isApproveRenewalOffersLoading }] =
    useMutation(api.approveRenewalOffers, {
      onSuccess: (_, variables) => {
        const message = `Successfully approved the selected renewal ${pluralize(
          "offer",
          variables?.renewals?.length
        )}.`;
        openNotification(message, NOTIFICATIONS.info);
        invalidateRenewalsCache();
      },
      onError: (_, variables) => {
        const message = `Failed to approve the selected renewal ${pluralize(
          "offer",
          variables?.renewals?.length
        )}.`;
        openNotification(message, NOTIFICATIONS.error);
      },
    });

  return {
    approveRenewalOffers,
    isApproveRenewalOffersLoading,
  };
};

export const useSendRenewalOffers = () => {
  const [sendRenewalOffers, { isLoading: isSendRenewalOffersLoading }] =
    useMutation(api.sendRenewalOffers, {
      onSuccess: (_, variables) => {
        const message = `Successfully sent the selected renewal ${pluralize(
          "offer",
          variables?.renewalOfferIds?.length
        )}.`;
        openNotification(message, NOTIFICATIONS.info);
        invalidateRenewalsCache();
        invalidateLeaseGroupApplicationsCache();
        invalidateRenewalOfferCache();
      },
      onError: (_, variables) => {
        const message = `Failed to send the selected renewal ${pluralize(
          "offer",
          variables?.renewalOfferIds?.length
        )}.`;
        openNotification(message, NOTIFICATIONS.error);
      },
    });

  return {
    sendRenewalOffers,
    isSendRenewalOffersLoading,
  };
};

export const useSendRenewalOfferReminder = () => {
  const [
    sendRenewalOfferReminder,
    { isLoading: isSendRenewalOfferReminderLoading },
  ] = useMutation(api.sendRenewalOfferReminder, {
    onSuccess: () => {
      openNotification(
        "Successfully sent the renewal offer reminder",
        NOTIFICATIONS.info
      );
      invalidateRenewalsCache();
    },
    onError: () => {
      openNotification(
        "Failed to send the renewal offer reminder",
        NOTIFICATIONS.error
      );
    },
  });

  return {
    sendRenewalOfferReminder,
    isSendRenewalOfferReminderLoading,
  };
};

export const useReopenRenewalOffer = () => {
  const [reopenRenewalOffer, { isLoading: isReopenRenewalOfferLoading }] =
    useMutation(api.reopenRenewalOffer, {
      onSuccess: () => {
        openNotification("Successfully reopened offers", NOTIFICATIONS.info);
        invalidateRenewalsCache();
        invalidateRenewalOfferCache();
      },
      onError: () => {
        openNotification("Failed to reopen offers", NOTIFICATIONS.error);
      },
    });

  return {
    reopenRenewalOffer,
    isReopenRenewalOfferLoading,
  };
};

export const useWithdrawRenewalOffer = () => {
  const [withdrawRenewalOffer, { isLoading: isWithdrawRenewalOfferLoading }] =
    useMutation(api.withdrawRenewalOffer, {
      onSuccess: () => {
        openNotification("Successfully withdrawn offer", NOTIFICATIONS.info);
        invalidateRenewalsCache();
      },
      onError: () => {
        openNotification("Failed to withdraw offer", NOTIFICATIONS.error);
      },
    });

  return {
    withdrawRenewalOffer,
    isWithdrawRenewalOfferLoading,
  };
};

export const useWithdrawRenewalOfferOption = () => {
  const [
    withdrawRenewalOfferOption,
    { isLoading: isWithdrawRenewalOfferOptionLoading },
  ] = useMutation(api.withdrawRenewalOfferOption, {
    onSuccess: () => {
      openNotification(
        "Successfully withdrawn offer option",
        NOTIFICATIONS.info
      );
      invalidateRenewalsCache();
    },
    onError: () => {
      openNotification("Failed to withdraw offer option", NOTIFICATIONS.error);
    },
  });

  return {
    withdrawRenewalOfferOption,
    isWithdrawRenewalOfferOptionLoading,
  };
};

export const useCreateRenewalOffer = () => {
  const [createRenewalOffer, { isLoading: isCreateRenewalOfferLoading }] =
    useMutation(api.createRenewalOffer, {
      onSuccess: () => {
        openNotification(
          "Successfully created renewal offer",
          NOTIFICATIONS.info
        );
        invalidateRenewalsCache();
      },
      onError: () => {
        openNotification("Failed to create renewal offer", NOTIFICATIONS.error);
      },
    });

  return {
    createRenewalOffer,
    isCreateRenewalOfferLoading,
  };
};

export const useGetRenewalOffer = (renewalId: number, offerId: number) => {
  const { data, isLoading } = useQuery(
    QUERY_KEYS.getRenewalOffer(renewalId, offerId),
    () => api.getRenewalOffer(renewalId, offerId),
    {
      enabled: !!renewalId && !!offerId,
      forceFetchOnMount: true,
      onError: () =>
        openNotification(
          "Failed to load the renewal offer",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    renewalOffer: data,
    isRenewalOfferLoading: isLoading,
  };
};

export const useCreateRenewalNote = () => {
  const [createRenewalNote, { isLoading: isCreatingNote }] = useMutation(
    api.createRenewalNote,
    {
      onSuccess: () => {
        openNotification(
          "The note has been successfully created",
          NOTIFICATIONS.info
        );
        queryCache.invalidateQueries([GET_RENEWAL_NOTES]);
      },
      onError: () =>
        openNotification(
          "Failed to create a renewal note",
          NOTIFICATIONS.error
        ),
    }
  );

  return { createRenewalNote, isCreatingNote };
};

export const useUpdateRenewalOffer = () => {
  const [updateRenewalOffer, { isLoading: isUpdateRenewalOfferLoading }] =
    useMutation(api.updateRenewalOffer, {
      onSuccess: () => {
        openNotification(
          "Successfully updated renewal offer",
          NOTIFICATIONS.info
        );
        invalidateRenewalOfferCache();
      },
      onError: () => {
        openNotification(
          "Failed to update the renewal offer",
          NOTIFICATIONS.error
        );
      },
    });

  return {
    updateRenewalOffer,
    isUpdateRenewalOfferLoading,
  };
};

export const useRejectRenewal = () => {
  const [rejectRenewal, { isLoading: isRejectRenewalLoading }] = useMutation(
    api.rejectRenewal,
    {
      onSuccess: () => {
        invalidateRenewalsCache();
        invalidateLeaseGroupApplicationsCache();
      },
      onError: () =>
        openNotification("Failed to reject the renewal", NOTIFICATIONS.error),
    }
  );

  return {
    rejectRenewal,
    isRejectRenewalLoading,
  };
};

export const useCancelRenewal = () => {
  const [cancelRenewal, { isLoading: isCancelRenewalLoading }] = useMutation(
    api.cancelRenewal,
    {
      onSuccess: () => {
        invalidateRenewalsCache();
      },
      onError: () =>
        openNotification("Failed to reject the renewal", NOTIFICATIONS.error),
    }
  );

  return {
    cancelRenewal,
    isCancelRenewalLoading,
  };
};

export const useRejectRenewalApplicationOffer = () => {
  const [rejectRenewalApplicationOffer, { isLoading: isRejectOfferLoading }] =
    useMutation(api.rejectRenewalApplicationOffer, {
      onSuccess: () => {
        invalidateRenewalsCache();
        invalidateLeaseGroupApplicationsCache();
      },
      onError: () =>
        openNotification(
          "Failed to reject the renewal offer",
          NOTIFICATIONS.error
        ),
    });

  return {
    rejectRenewalApplicationOffer,
    isRejectOfferLoading,
  };
};

export const useRemoveApplicantFromRenewal = () => {
  const [removeApplicantFromRenewal, { isLoading: isRemoveApplicantLoading }] =
    useMutation(api.removeApplicantFromRenewal, {
      onSuccess: () => {
        invalidateRenewalsCache();
        invalidateLeaseGroupApplicationsCache();
        openNotification(
          "The applicant has been successfully removed",
          NOTIFICATIONS.info
        );
      },
      onError: () =>
        openNotification(
          "Failed to remove the applicant from renewal",
          NOTIFICATIONS.error
        ),
    });

  return {
    removeApplicantFromRenewal,
    isRemoveApplicantLoading,
  };
};

export const useAddApplicantsToRenewal = () => {
  const [addApplicantsToRenewal, { isLoading: isAddApplicantsLoading }] =
    useMutation(api.addApplicantsToRenewal, {
      onSuccess: () => {
        invalidateRenewalsCache();
        invalidateLeaseGroupApplicationsCache();
        openNotification(
          "The applicant(s) have been successfully invited",
          NOTIFICATIONS.info
        );
      },
      onError: () =>
        openNotification(
          "Failed to add the applicants to renewal",
          NOTIFICATIONS.error
        ),
    });

  return {
    addApplicantsToRenewal,
    isAddApplicantsLoading,
  };
};
