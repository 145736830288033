import React from "react";

import { Radio } from "antd";
import PropTypes from "prop-types";

import { MODALS } from "manager/config/constants";
import List from "shared/components/List";
import { Modal } from "shared/components/Modal";

import { Title, ListItem, RadioOption } from "./styled";

const SwitchESignModal = ({
  onConfirm,
  onChange,
  currentValue,
  OPTIONS,
  submitting,
  propertyName,
  propertyAddress,
  unitName,
}) => {
  const Subtitle = (
    <span data-testid="subtitle">
      Unit:{" "}
      <strong>{`${propertyName}, ${propertyAddress}, ${unitName}`}</strong>
    </span>
  );

  return (
    <Modal
      id={MODALS.switchLeaseESign}
      title="Switch back to e-signing"
      subtitle={Subtitle}
      submitButtonLabel="Confirm"
      submit={onConfirm}
      submitting={submitting}
      submitButtonDisabled={!currentValue}
    >
      <Modal.Body>
        <Radio.Group onChange={onChange} value={currentValue}>
          <List>
            <List.Item>
              <Title> How would you like to proceed? </Title>
            </List.Item>
            <ListItem selected={currentValue === OPTIONS.preview}>
              <RadioOption value={OPTIONS.preview}>
                Preview and send the lease
              </RadioOption>
            </ListItem>
            <ListItem selected={currentValue === OPTIONS.modify}>
              <RadioOption value={OPTIONS.modify}>
                Modify the lease first
              </RadioOption>
            </ListItem>
          </List>
        </Radio.Group>
      </Modal.Body>
    </Modal>
  );
};

SwitchESignModal.propTypes = {
  OPTIONS: PropTypes.shape({
    modify: PropTypes.string.isRequired,
    preview: PropTypes.string.isRequired,
  }).isRequired,
  currentValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  propertyName: PropTypes.string,
  propertyAddress: PropTypes.string,
  unitName: PropTypes.string,
};

SwitchESignModal.defaultProps = {
  currentValue: undefined,
  propertyName: undefined,
  propertyAddress: undefined,
  unitName: undefined,
};

export default SwitchESignModal;
