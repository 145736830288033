import React from "react";

import Icon from "shared/components/Icon";

import { Container, IconWrapper } from "./styled";

export interface StatusProps {
  children: string;
  onClick?: () => void;
}

const Status = ({ children, onClick }: StatusProps) => (
  <Container onClick={onClick}>
    <IconWrapper>
      <Icon.CheckIcon data-testid="icon" />
    </IconWrapper>
    <p data-testid="status-label">{children}</p>
  </Container>
);

export default Status;
