import * as Yup from "yup";

const LEASE_QUESTION_FIELDS = Object.freeze({
  questionName: "questionName",
  options: "options",
  answer: "answer",
  documents: "documents",
  position: "position",
});

const LeaseQuestionValidationSchema = Yup.object().shape({
  [LEASE_QUESTION_FIELDS.questionName]: Yup.string().required(
    "Question is required"
  ),
  [LEASE_QUESTION_FIELDS.options]: Yup.array().of(
    Yup.object().shape({
      [LEASE_QUESTION_FIELDS.answer]:
        Yup.string().required("Answer is required"),
    })
  ),
});

export { LEASE_QUESTION_FIELDS, LeaseQuestionValidationSchema };
