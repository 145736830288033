import styled from "@emotion/styled";
import { Divider, Typography } from "antd";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const { Text } = Typography;

export const AddressContainer = styled.div`
  display: flex;
  align-items: baseline;
  ${MEDIA_QUERIES.w.ltXl} {
    flex-direction: column;
  }
`;

export const Address = styled(Text)`
  color: ${COLORS.violetTheme.text};
  font-weight: 300;
`;

export const Portfolio = styled.span`
  font-size: 12px;
  font-weight: 300;
  color: ${COLORS.secondary};
  margin-left: 12px;
  ${MEDIA_QUERIES.w.ltXl} {
    margin-left: 0;
  }
`;

export const RentDivider = styled(Divider)`
  ${MEDIA_QUERIES.w.ltSm} {
    display: none;
  }
`;

export const TileIcon = styled.div`
  display: flex;
  align-items: center;
  span {
    color: white;
    margin-left: 10px;
  }
  .icon-light-violet {
    stroke: ${COLORS.violetTheme.text};
  }
  ${MEDIA_QUERIES.w.ltLg} {
    align-self: flex-start;
    span {
      display: none;
    }
  }
`;
