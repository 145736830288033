import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getCompanyDocumentTemplates: () =>
    axios
      .get(`${API_URL}${ROUTES.getDocumentTemplates}`)
      .then((response) => response.data),
  updateCompanyDocumentTemplatesOrdering: (params) =>
    axios
      .patch(`${API_URL}${ROUTES.updateOrdering}`, params)
      .then((response) => response.data),
  resetOrderingForProperties: () =>
    axios
      .patch(`${API_URL}${ROUTES.resetOrderingForProperties}`)
      .then((response) => response.data),
  getPropertyDocumentTemplates: (id) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getPropertyDocumentTemplates, {
          id,
        })}`
      )
      .then((response) => response.data),
  updatePropertyDocumentTemplatesOrdering: (id, params) =>
    axios
      .patch(
        `${API_URL}${transformRoute(ROUTES.updatePropertiesDocumentOrdering, {
          id,
        })}`,
        params
      )
      .then((response) => response.data),
  getPortfolioDocumentTemplates: (id) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getPortfolioDocumentTemplates, {
          id,
        })}`
      )
      .then((response) => response.data),
  previewDocument: (id) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.previewDocument, {
          id,
        })}`
      )
      .then((response) => response.data),
  updateRiderType: ({ id, riderType }) =>
    axios
      .patch(
        `${API_URL}${transformRoute(ROUTES.updateRiderType, {
          id,
        })}`,
        { riderType }
      )
      .then((response) => response.data),
};

export default api;
