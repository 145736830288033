import { useMutation, useQuery, queryCache } from "react-query";

import documentsApi from "manager/lib/documentsApi";
import leaseApi from "manager/lib/leaseApi";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

export const usePropertyLeaseSetup = (propertyId, config = {}) => {
  const response = useQuery(
    ["getPropertyLeaseSetup", propertyId],
    () => leaseApi.getPropertyLeaseSetup(propertyId),
    {
      onError: () => {
        openNotification("Failed to load lease setup.", NOTIFICATIONS.error);
      },
      ...config,
    }
  );

  return response;
};

export const useGetPropertyLeaseSettings = (id) => {
  const {
    data: leaseSetup,
    isLoading,
    refetch: refetchLeaseSetup,
  } = usePropertyLeaseSetup(id);

  const {
    data: documentTemplates,
    isDocumentTemplatesLoading,
    refetch: refetchDocumentTemplates,
  } = useQuery(
    ["getPropertyDocumentTemplates", id],
    () => documentsApi.getPropertyDocumentTemplates(id),
    {
      onError: () => {
        openNotification(
          "Failed to load document templates.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    leaseSetup: {
      ...leaseSetup,
      documentTemplates,
    },
    isLoading: isLoading || isDocumentTemplatesLoading,
    refetchLeaseSetup: () => {
      refetchLeaseSetup();
      refetchDocumentTemplates();
    },
  };
};

export const useUpdatePropertyLeaseSetup = (refetchLeaseSetup) => {
  const [mutate, status] = useMutation(leaseApi.updatePropertyLeaseSetup, {
    onSuccess: () => {
      queryCache.invalidateQueries(["getDocumentFields"]);
      queryCache.invalidateQueries(["getPropertyFields"]);
      openNotification(`All changes saved!`, NOTIFICATIONS.info);
      refetchLeaseSetup();
    },
    onError: () => {
      openNotification("Failed to update lease setup.", NOTIFICATIONS.error);
    },
  });

  return {
    updateLeaseSetup: mutate,
    isLoading: status.isLoading,
  };
};
