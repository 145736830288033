import React from "react";

import { Col, Row } from "antd";

import {
  LeaseGroup,
  LeaseGroupApplication,
} from "manager/interfaces/api/leaseGroups";
import FlexContainer from "shared/components/FlexContainer";
import { BackLink } from "shared/components/Links";
import LoaderContainer, {
  BigLoadingSpinner,
} from "shared/components/LoaderContainer";
import Spacer from "shared/components/Spacer";
import { SmallText } from "shared/components/Typography";
import Well from "shared/components/Well";
import { BackLinkProps } from "shared/interfaces/misc";

import LeaseGroupAttachments from "./LeaseGroupAttachments";
import LeaseGroupDetails from "./LeaseGroupDetails";
import LeaseGroupHeader from "./LeaseGroupHeader";
import LeaseGroupHistory from "./LeaseGroupHistory";
import LeaseGroupNotes from "./LeaseGroupNotes";
import LeaseGroupOffers from "./LeaseGroupOffers";
import LeaseGroupResidents from "./LeaseGroupResidents";
import LeaseGroupStatus from "./LeaseGroupStatus";

import { StyledLeaseGroupContainer } from "./styled";
import { showLoaderFn } from "./utils";

interface LeaseGroupProps {
  leaseGroup?: LeaseGroup;
  isPageLoading: boolean;
  isAdditionalDataLoading: boolean;
  footer: React.ReactNode;
  residents?: LeaseGroupApplication[];
  showLoader: showLoaderFn;
  backLink: BackLinkProps;
  renewalsEnabled: boolean;
}

const LeaseGroupSummary = ({
  leaseGroup,
  isPageLoading,
  isAdditionalDataLoading,
  footer,
  showLoader,
  residents,
  backLink,
  renewalsEnabled,
}: LeaseGroupProps) => {
  const isRenewalCreated = Boolean(leaseGroup?.renewal);
  const showRenewalSection = renewalsEnabled && isRenewalCreated;

  return (
    <LoaderContainer
      Loader={<BigLoadingSpinner />}
      loading={isPageLoading}
      fetching={isAdditionalDataLoading}
    >
      <StyledLeaseGroupContainer noPaddingBottom>
        <BackLink.Container>
          <BackLink {...backLink} />
        </BackLink.Container>
        <LeaseGroupHeader leaseGroup={leaseGroup} residents={residents} />
        <Spacer size={Spacer.SIZES.sm} />
        <Row gutter={[12, 12]}>
          <Col lg={14}>
            <FlexContainer
              className="lease-group-details-row"
              alignItems="stretch"
            >
              {renewalsEnabled && <LeaseGroupStatus leaseGroup={leaseGroup} />}
              <LeaseGroupDetails leaseGroup={leaseGroup} />
            </FlexContainer>
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <LeaseGroupResidents
                  leaseGroup={leaseGroup}
                  residents={residents}
                />
              </Col>
            </Row>
            {showRenewalSection && (
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <LeaseGroupOffers leaseGroup={leaseGroup} />
                </Col>
              </Row>
            )}
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <LeaseGroupHistory leaseGroup={leaseGroup} />
              </Col>
            </Row>
          </Col>
          <Col lg={10}>
            <Well noBorder largePadding>
              <Row gutter={[0, 0]}>
                <Col span={24}>
                  <SmallText extraStrong>COMMUNICATION</SmallText>
                </Col>
              </Row>
              {showRenewalSection && (
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <LeaseGroupNotes
                      leaseGroup={leaseGroup}
                      showLoader={showLoader}
                    />
                  </Col>
                </Row>
              )}
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <LeaseGroupAttachments
                    leaseGroup={leaseGroup}
                    showLoader={showLoader}
                  />
                </Col>
              </Row>
            </Well>
          </Col>
        </Row>
        {footer}
      </StyledLeaseGroupContainer>
    </LoaderContainer>
  );
};

export default LeaseGroupSummary;
