import React, { useEffect, useState } from "react";

import { useParams, useHistory } from "react-router-dom";

import ROUTES from "shared/config/routes";
import { useLogin } from "shared/hooks";
import { useLoginSelfSignedDeepLink } from "shared/hooks/api";
import { extractLoginErrorMessage } from "shared/utils/auth";
import { getQueryParams } from "shared/utils/routing";

import { LOGIN_SELF_SIGNED_DEEP_LINKS_ERROR_CODES } from "./constants";
import { ErrorMessage } from "./interfaces";
import LoginSelfSignedDeepLinkBouncePage from "./LoginSelfSignedDeepLinkBouncePage";

interface LoginSelfSignedDeepLinkBouncePageContainerProps {
  hideBackButton?: boolean;
  defaultDescription?: JSX.Element;
}

const LoginSelfSignedDeepLinkBouncePageContainer = ({
  hideBackButton,
  defaultDescription,
}: LoginSelfSignedDeepLinkBouncePageContainerProps) => {
  const history = useHistory();
  const { linkHash } = useParams<{ linkHash?: string }>();
  // eslint-disable-next-line camelcase
  const { fallback_url: fallbackUrl }: { fallback_url?: string } =
    getQueryParams();
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  const { loginSelfSignedDeepLink, isLoading } = useLoginSelfSignedDeepLink();

  const loginCall = async () => loginSelfSignedDeepLink(linkHash);

  const onSuccess = ({ redirect }) => {
    if (redirect) {
      window.location.href = redirect;
    } else {
      history.replace(ROUTES.home);
    }
  };

  const onError = (error) => {
    // @ts-ignore
    const errorObject = extractLoginErrorMessage(
      error,
      LOGIN_SELF_SIGNED_DEEP_LINKS_ERROR_CODES,
      "This link is invalid"
    );

    if (
      errorObject.message === "This link has expired" &&
      !!localStorage?.getItem("jwtToken")
    ) {
      window.location.href = "/login";
    } else {
      setErrorMessage(errorObject);
    }
  };

  const { login } = useLogin({
    loginCall,
    onSuccess,
    onError,
  });

  useEffect(() => {
    login();
  }, []);

  return (
    <LoginSelfSignedDeepLinkBouncePage
      isLoading={isLoading}
      errorMessage={errorMessage}
      fallbackUrl={fallbackUrl}
      hideBackButton={hideBackButton}
      defaultDescription={defaultDescription}
    />
  );
};

export default LoginSelfSignedDeepLinkBouncePageContainer;
