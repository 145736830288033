/* eslint-disable react/forbid-prop-types */
import React from "react";

import PropTypes from "prop-types";

import FooterBar from "shared/components/FooterBar";
import LoaderContainer, {
  BigLoadingSpinner,
} from "shared/components/LoaderContainer";
import Notice from "shared/components/Notice";
import { RegularText } from "shared/components/Typography";
import Well from "shared/components/Well";

import LeaseDocuments from "./LeaseDocuments";
import LeaseDocumentsFooter from "./LeaseDocumentsFooter";
import { StyledLeaseDocumentsStep } from "./styled";

const LeaseDocumentsStep = ({
  groupedDocuments,
  finalPackageDocuments,
  onDragEnd,
  toggleDocument,
  removeDocumentFromPackage,
  onSubmitStep,
  onFinishLater,
  previewDocument,
  stepBack,
  nextStepLoading,
  isLoading,
  isProRateWarningShown,
  isBluemoonLease,
}) => (
  <LoaderContainer fetching={isLoading} Loader={<BigLoadingSpinner />}>
    <StyledLeaseDocumentsStep>
      {isProRateWarningShown && (
        <Well white className="warning-note">
          <Notice type="lightWarning">
            <RegularText strong>
              You opted to prorate the first month&apos;s rent; however, the
              selected lease package does not contain the document field
              &quot;First Month&apos;s Rent.&quot; Please disable prorating, or
              choose a new lease document to continue.
            </RegularText>
          </Notice>
        </Well>
      )}
      <LeaseDocuments
        groupedDocuments={groupedDocuments}
        finalPackageDocuments={finalPackageDocuments}
        onDragEnd={onDragEnd}
        toggleDocument={toggleDocument}
        removeDocumentFromPackage={removeDocumentFromPackage}
        previewDocument={previewDocument}
        isBluemoonLease={isBluemoonLease}
      />

      <FooterBar.Spacer />

      <LeaseDocumentsFooter
        finalPackageDocuments={finalPackageDocuments}
        isLoading={isLoading}
        onFinishLater={onFinishLater}
        onSubmitStep={onSubmitStep}
        stepBack={stepBack}
        nextStepLoading={nextStepLoading}
        isProRateWarningShown={isProRateWarningShown}
        isSubmitDisabled={
          !finalPackageDocuments.length || isLoading || isProRateWarningShown
        }
      />
    </StyledLeaseDocumentsStep>
  </LoaderContainer>
);

LeaseDocumentsStep.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  nextStepLoading: PropTypes.bool.isRequired,
  groupedDocuments: PropTypes.shape({
    defaultDocuments: PropTypes.array,
    optionalDocuments: PropTypes.array,
  }),
  finalPackageDocuments: PropTypes.arrayOf(PropTypes.object),
  onDragEnd: PropTypes.func.isRequired,
  toggleDocument: PropTypes.func.isRequired,
  removeDocumentFromPackage: PropTypes.func.isRequired,
  onFinishLater: PropTypes.func.isRequired,
  onSubmitStep: PropTypes.func.isRequired,
  previewDocument: PropTypes.func.isRequired,
  stepBack: PropTypes.func.isRequired,
  isProRateWarningShown: PropTypes.bool.isRequired,
  isBluemoonLease: PropTypes.bool,
};

LeaseDocumentsStep.defaultProps = {
  groupedDocuments: {},
  finalPackageDocuments: [],
  isBluemoonLease: false,
};

export default LeaseDocumentsStep;
