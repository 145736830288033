import { queryCache, useMutation, useQuery } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { refetchMultipleActiveQueries } from "shared/utils/queries.utils";
import { openNotification } from "shared/utils/ui";

import { GET_PROPERTY_SETTINGS } from "./propertyQueries";

const GET_RENEWALS_COMPANY_SETTINGS = "getRenewalsCompanySettings";
const GET_RENEWALS_SETTINGS = "getRenewalsSettings";

export const useGetRenewalsCompanySettings = () => {
  const {
    data: renewalsCompanySettings,
    isLoading: isRenewalsSettingsLoading,
  } = useQuery(
    [GET_RENEWALS_COMPANY_SETTINGS],
    () => api.getRenewalsCompanySettings(),
    {
      onError: () =>
        openNotification(
          "Failed to get renewals company settings",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    renewalsCompanySettings,
    isRenewalsSettingsLoading,
  };
};

export const useGetRenewalsSettings = ({
  settingsId,
}: {
  settingsId?: number;
}) => {
  const { data: renewalsSettings, isLoading: isRenewalsSettingsLoading } =
    useQuery(
      [GET_RENEWALS_SETTINGS, Number(settingsId)],
      () => api.getRenewalsSettings({ settingsId }),
      {
        onError: () =>
          openNotification(
            "Failed to get renewals settings",
            NOTIFICATIONS.error
          ),
        enabled: Boolean(settingsId),
      }
    );

  return {
    renewalsSettings,
    isRenewalsSettingsLoading,
  };
};

export const useUpdateRenewalsSettings = () => {
  const [updateRenewalsSettings, { isLoading: isUpdatingRenewalsSettings }] =
    useMutation(api.updateRenewalsSettings, {
      onSuccess: () => {
        refetchMultipleActiveQueries([
          GET_RENEWALS_COMPANY_SETTINGS,
          GET_RENEWALS_SETTINGS,
        ]);
        openNotification(
          "Renewals settings successfully updated",
          NOTIFICATIONS.info
        );
      },
      onError: () =>
        openNotification(
          "Failed to update renewals settings",
          NOTIFICATIONS.error
        ),
    });

  return {
    updateRenewalsSettings,
    isUpdatingRenewalsSettings,
  };
};

export const useCopyRenewalSettingsFromPortfolio = () => {
  const [
    copyRenewalSettingsFromPortfolio,
    { isLoading: isCopyingPortfolioRenewalSettings },
  ] = useMutation(api.copyRenewalSettingsFromPortfolio, {
    onSuccess: () => {
      queryCache.invalidateQueries([GET_PROPERTY_SETTINGS]);
    },
    onError: () =>
      openNotification(
        "Failed to copy renewals settings from portfolio",
        NOTIFICATIONS.error
      ),
  });

  return {
    copyRenewalSettingsFromPortfolio,
    isCopyingPortfolioRenewalSettings,
  };
};

export const useResetRenewalSettingsToPortfolio = () => {
  const [
    resetRenewalSettingsToPortfolio,
    { isLoading: isResettingsRenewalSettings },
  ] = useMutation(api.resetRenewalSettingsToPortfolio, {
    onSuccess: () => {
      queryCache.invalidateQueries([GET_PROPERTY_SETTINGS]);
    },
    onError: () =>
      openNotification(
        "Failed to reset renewals settings to portfolio",
        NOTIFICATIONS.error
      ),
  });

  return {
    resetRenewalSettingsToPortfolio,
    isResettingsRenewalSettings,
  };
};
