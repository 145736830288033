import { useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";
import throttle from "lodash/throttle";

import { useCalculateLeaseFirstMonthRent } from "manager/hooks/api";
import { CalculateFirstMonthRentModel } from "manager/interfaces/api/leaseFeeConfiguration";

interface LeaseFirstMonthRentProps {
  timeout?: number;
  leaseStart: dayjs.Dayjs;
  rent: number;
  prorateFirstMonthRent: boolean;
}

const useLeaseFirstMonthRentCalculation = ({
  timeout = 500,
  leaseStart,
  rent,
  prorateFirstMonthRent,
}: LeaseFirstMonthRentProps) => {
  const [displayedFirstMonthRent, setDisplayedFirstMonthRent] =
    useState<CalculateFirstMonthRentModel>();

  const { calculateFirstMonthRent, leaseFirstMonthRent } =
    useCalculateLeaseFirstMonthRent();

  const calculateTotalRentCallback = useCallback(
    throttle(calculateFirstMonthRent, timeout),
    [calculateFirstMonthRent]
  );

  useEffect(() => {
    if (leaseStart && rent) {
      calculateTotalRentCallback({
        leaseStart,
        rent,
        prorateFirstMonthRent,
      });
    }
  }, [leaseStart, rent, prorateFirstMonthRent, calculateTotalRentCallback]);

  useEffect(() => {
    if (leaseFirstMonthRent) {
      setDisplayedFirstMonthRent(leaseFirstMonthRent);
    }
  }, [leaseFirstMonthRent]);

  return displayedFirstMonthRent;
};

export default useLeaseFirstMonthRentCalculation;
