import React, { useState } from "react";

import {
  useGetLeaseGroupApplications,
  useGetLeaseGroupById,
} from "manager/hooks/api";
import { usePermissions } from "shared/hooks";
import { BackLinkProps } from "shared/interfaces/misc";

import LeaseGroup from "./LeaseGroup";
import LeaseGroupFooter from "./LeaseGroupFooter";

const LeaseGroupContainer = ({
  leaseGroupId,
  backLink,
}: {
  leaseGroupId: number;
  backLink: BackLinkProps;
}) => {
  const { areRenewalsEnabled } = usePermissions();
  const [activeQueries, setActiveQueries] = useState<{
    [key: string]: boolean;
  }>({});

  const { leaseGroup, isLeaseGroupLoading } = useGetLeaseGroupById(
    leaseGroupId && Number(leaseGroupId)
  );

  const isLoaderShown = Object.values(activeQueries).find((active) => active);

  const showLoader = (queries: { [key: string]: boolean }) =>
    setActiveQueries({ ...activeQueries, ...queries });

  const { leaseGroupApplications, isLeaseGroupApplicationsLoading } =
    useGetLeaseGroupApplications(leaseGroup?.id);

  return (
    <LeaseGroup
      leaseGroup={leaseGroup}
      isPageLoading={isLeaseGroupLoading || isLeaseGroupApplicationsLoading}
      isAdditionalDataLoading={isLoaderShown}
      footer={<LeaseGroupFooter leaseGroup={leaseGroup} />}
      showLoader={showLoader}
      residents={leaseGroupApplications}
      backLink={backLink}
      renewalsEnabled={areRenewalsEnabled}
    />
  );
};

export default LeaseGroupContainer;
