import styled from "@emotion/styled";
import { Radio } from "antd";

import List from "shared/components/List";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

export const Title = styled("span")({
  color: COLORS.textColorPrimary,
  fontSize: "17px",
  fontWeight: 500,
});

export const ListItem = styled(List.Item)((props) => ({
  color: COLORS.textColorPrimary,
  ...(props.selected && {
    backgroundColor: COLORS.lightGreen,
  }),
}));

export const RadioOption = styled(Radio)({
  color: COLORS.textColorPrimary,
  [MEDIA_QUERIES.w.gteSm]: {
    minWidth: "350px",
  },
  [MEDIA_QUERIES.w.ltSm]: {
    whiteSpace: "normal",
  },
});
