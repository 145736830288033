
import { Col, Row } from "antd";
import { Gutter } from "antd/lib/grid/row";

import ROUTES from "manager/config/routes";
import { LeaseGroup } from "manager/interfaces/api/leaseGroups";
import FlexContainer from "shared/components/FlexContainer";
import { ActionLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { RegularText, SmallText } from "shared/components/Typography";
import { formattedDifferenceBetweenDates } from "shared/utils/dates";
import { transformRoute } from "shared/utils/routing";
import { formatDate, getValueOrNA } from "shared/utils/ui";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import LeaseGroupTimelineDetails from "./LeaseGroupTimelineDetails";
import { StyledLeaseGroupDetails } from "./styled";

const colSize = {
  xs: 24,
  sm: 8,
};

const gutter: [Gutter, Gutter] = [24, 48];

const LeaseGroupDetails = ({ leaseGroup }: { leaseGroup: LeaseGroup }) => {
  const { activeLease } = leaseGroup || {};

  const durationLabel = getValueOrNA(
    formattedDifferenceBetweenDates(
      activeLease?.startDate,
      activeLease?.endDate
    )
  );
  const leaseRoute =
    activeLease?.id &&
    transformRoute(ROUTES.leaseSummary, { id: activeLease?.id });

  return (
    <StyledLeaseGroupDetails noBorder noMarginBottom>
      <Tile.Inner>
        <FlexContainer alignItems="center" justifyContent="space-between">
          <SmallText extraStrong>{activeLease?.name}</SmallText>
          {leaseRoute && <ActionLink to={leaseRoute}>View Lease</ActionLink>}
        </FlexContainer>
        <Spacer />

        <LeaseGroupTimelineDetails lease={activeLease} />

        <Row gutter={gutter}>
          <Col {...colSize}>
            <SmallText>Start date:</SmallText>
            <Spacer size={Spacer.SIZES.xs} />
            <RegularText strong>
              {formatDate(activeLease?.startDate)}
            </RegularText>
          </Col>
          <Col {...colSize}>
            <SmallText>End date:</SmallText>
            <Spacer size={Spacer.SIZES.xs} />
            <RegularText strong>{formatDate(activeLease?.endDate)}</RegularText>
          </Col>
          <Col {...colSize}>
            <SmallText>Duration:</SmallText>
            <Spacer size={Spacer.SIZES.xs} />
            <RegularText strong>{durationLabel}</RegularText>
          </Col>
        </Row>
        <Row gutter={gutter}>
          <Col {...colSize}>
            <SmallText>Monthly Rent:</SmallText>
            <Spacer size={Spacer.SIZES.xs} />
            <RegularText strong>
              {printDollarsFromCents(activeLease?.rent)}
            </RegularText>
          </Col>
          <Col {...colSize}>
            <SmallText>Concession:</SmallText>
            <Spacer size={Spacer.SIZES.xs} />
            <RegularText strong>
              {printDollarsFromCents(activeLease?.concessionAmount)}
            </RegularText>
          </Col>
          <Col {...colSize}>
            <SmallText>Security Deposit:</SmallText>
            <Spacer size={Spacer.SIZES.xs} />
            <RegularText strong>
              {printDollarsFromCents(activeLease?.securityDeposit)}
            </RegularText>
          </Col>
        </Row>
      </Tile.Inner>
    </StyledLeaseGroupDetails>
  );
};

export default LeaseGroupDetails;
