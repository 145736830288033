import styled from "@emotion/styled";

import VerticalStaticNumberedStepsBase from "shared/components/VerticalStaticNumberedSteps";

const VerticalStaticNumberedSteps = styled(VerticalStaticNumberedStepsBase)`
  &.ant-steps-vertical.ant-steps-small
    .ant-steps-item
    > .ant-steps-item-container {
    display: flex;

    .ant-steps-item-content {
      overflow: visible;
      width: 100%;
    }
  }

  .extra-info {
    margin-left: 10px;
  }

  .option-delete-icon {
    position: absolute;
    left: -30px;
    margin-top: 20px;
  }
`;

export { VerticalStaticNumberedSteps };
