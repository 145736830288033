import styled from "@emotion/styled";

import { AlignedCenterFlexPartial } from "shared/styles/commonStyles";

interface StatusCircleProps {
  statusColor: string;
}

const CIRCLE_SIZE = "8px";

export const StatusCircleLabelContent = styled.div<StatusCircleProps>`
  ${AlignedCenterFlexPartial};
  height: 24px;

  .status-circle {
    background-color: ${(props) => props?.statusColor};
    height: ${CIRCLE_SIZE};
    width: ${CIRCLE_SIZE};
    border-radius: ${CIRCLE_SIZE};
    margin-right: 12px;
  }
`;
