import React from "react";

import { useLocation } from "react-router-dom";

import { usePermissions } from "shared/hooks";
import { ConditionalPage } from "shared/utils/routing";
import { ConditionalPageParams } from "shared/utils/routing/interfaces";

interface RenewalsConditionalPageProps {
  children: React.ReactNode;
}

export const RenewalsConditionalPage = ({
  children,
}: RenewalsConditionalPageProps) => {
  const { permissionsLoaded, areRenewalsEnabled } = usePermissions();
  const location = useLocation();
  const params: ConditionalPageParams = { location };
  return (
    <ConditionalPage
      params={params}
      isInitialized={permissionsLoaded}
      isEnabled={areRenewalsEnabled}
    >
      {children}
    </ConditionalPage>
  );
};
