import React from "react";

import { Col, Row } from "antd";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import FlexContainer from "shared/components/FlexContainer";
import { Input, Multiselect, VeroFormField } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import Tooltip from "shared/components/Tooltip";
import { SmallTitle } from "shared/components/Typography";

import { LEASE_QUESTION_FIELDS } from "./config";
import { VerticalStaticNumberedSteps } from "./styled";

const ManagerQuestionInnerFormElements = ({ documents }) => {
  const { values, setFieldValue } = useFormikContext();

  const addNewOption = () =>
    setFieldValue(
      [LEASE_QUESTION_FIELDS.options],
      [
        ...values[LEASE_QUESTION_FIELDS.options],
        {
          [LEASE_QUESTION_FIELDS.answer]: "",
          [LEASE_QUESTION_FIELDS.documents]: [],
          [LEASE_QUESTION_FIELDS.position]:
            values[LEASE_QUESTION_FIELDS.options].length + 1,
        },
      ]
    );

  return (
    <VerticalStaticNumberedSteps
      className="lease-question-steps"
      items={[
        {
          label: "Enter the question text, all questions must be Single Choice",
          content: (
            <>
              <Spacer />
              <VeroFormField
                as={Input}
                id={LEASE_QUESTION_FIELDS.questionName}
                name={LEASE_QUESTION_FIELDS.questionName}
                label="Question text"
                data-testid={LEASE_QUESTION_FIELDS.questionName}
                required
              />
              <Spacer />
            </>
          ),
        },
        {
          label: (
            <FlexContainer alignItems="center">
              <SmallTitle>
                Enter response options and select documents
              </SmallTitle>
              <Tooltip title="Enter response options for the applicant and select the document(s), if any, to include with the lease package based on the chosen response">
                <Icon.InfoIcon className="extra-info" />
              </Tooltip>
            </FlexContainer>
          ),
          content: (
            <>
              <Spacer />
              {values?.[LEASE_QUESTION_FIELDS.options]?.map((option, index) => {
                const answerFieldPath = `${LEASE_QUESTION_FIELDS.options}.${index}.${LEASE_QUESTION_FIELDS.answer}`;
                const documentsFieldPath = `${LEASE_QUESTION_FIELDS.options}.${index}.${LEASE_QUESTION_FIELDS.documents}`;

                return (
                  <Row
                    key={option[LEASE_QUESTION_FIELDS.position]}
                    gutter={[20, 25]}
                    data-testid="option"
                  >
                    <Icon.HoverTracker
                      className="action-icon option-delete-icon"
                      disabled={
                        values?.[LEASE_QUESTION_FIELDS.options]?.length <= 2
                      }
                      Icon={Icon.DeleteSmallIcon}
                      onClick={() => {
                        if (
                          values?.[LEASE_QUESTION_FIELDS.options]?.length > 2
                        ) {
                          setFieldValue(
                            LEASE_QUESTION_FIELDS.options,
                            values[LEASE_QUESTION_FIELDS.options]?.filter(
                              (o, i) => i !== index
                            )
                          );
                        }
                      }}
                      data-testid="delete-icon"
                    />
                    <Col md={{ span: 12 }}>
                      <VeroFormField
                        as={Input}
                        id={answerFieldPath}
                        name={answerFieldPath}
                        label={`Answer Option ${index + 1}`}
                        data-testid="answer"
                        required
                      />
                    </Col>
                    <Col md={{ span: 12 }}>
                      <VeroFormField
                        as={Multiselect}
                        id={documentsFieldPath}
                        name={documentsFieldPath}
                        label="Select document(s)"
                        data-testid="documents"
                        options={documents}
                        optional
                      />
                    </Col>
                  </Row>
                );
              })}
              <ActionLink
                plus
                onClick={addNewOption}
                data-testid="add-option-link"
              >
                Option
              </ActionLink>
            </>
          ),
        },
      ]}
    />
  );
};

ManagerQuestionInnerFormElements.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ManagerQuestionInnerFormElements;
