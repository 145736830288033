import React from "react";

import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import Tooltip from "shared/components/Tooltip";

import { TooltipTitle, InfoIcon } from "../styled";

const TooltipField = ({ field }) => {
  const description = field?.description;
  const assignedDocuments = field?.documentsAssigned;
  const isEmptyValue = !field?.value && !isBoolean(field?.value);
  const isEmptyAndEditable = isEmptyValue && field?.isEditable;
  const isTooltipNeed =
    description || !isEmpty(assignedDocuments) || isEmptyAndEditable;

  const assignedDocumentsMoreThanMax = field?.documentsAssigned?.length >= 4;
  return isTooltipNeed ? (
    <Tooltip
      title={
        <TooltipTitle>
          {description && <div>{description}</div>}
          {!isEmpty(assignedDocuments) && !assignedDocumentsMoreThanMax && (
            <div>
              <b>This field is used in:</b>
              <ul>
                {assignedDocuments?.map((documentName) => (
                  <li key={documentName}>{documentName}</li>
                ))}
              </ul>
            </div>
          )}
          {!isEmpty(assignedDocuments) && assignedDocumentsMoreThanMax && (
            <div>
              <b>
                This field is used in {assignedDocuments.length} other documents
              </b>
            </div>
          )}
          {isEmptyAndEditable && (
            <div>
              <p>Please fill in the value for this field</p>
            </div>
          )}
        </TooltipTitle>
      }
      theme="light"
    >
      <InfoIcon />
    </Tooltip>
  ) : null;
};

TooltipField.propTypes = {
  field: PropTypes.object.isRequired,
};
export default TooltipField;
