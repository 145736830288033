import React from "react";

import { useMediaQuery } from "react-responsive";

import { Button } from "shared/components/Button";
import DropdownMenu from "shared/components/DropdownMenu";
import { ActionLink } from "shared/components/Links";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import { StyledFooter, StyledButton, InlineCancelButton } from "./styled";

interface LeaseOfflineFooterProps {
  isESigningEnabled: boolean;
  isLeaseModifying: boolean;
  onModifyLease: () => void;
  onSwitchESign: () => void;
  confirmExecution: () => void;
  isExecuted: boolean;
}

const LeaseOfflineFooter = ({
  isESigningEnabled,
  onModifyLease,
  isLeaseModifying,
  onSwitchESign,
  confirmExecution,
  isExecuted,
}: LeaseOfflineFooterProps) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });

  return (
    <StyledFooter data-testid="lease-offline-footer">
      {isMobile && !isExecuted && (
        <DropdownMenu
          items={[
            {
              key: "SWITCH_TO_E_SIGNING",
              label: "Switch to E-Signing",
              onClick: onSwitchESign,
              disabled: !isESigningEnabled,
            },
            {
              key: "MODIFY_LEASE",
              label: "Modify Lease",
              onClick: onModifyLease,
            },
            {
              key: "CONFIRM_LEASE",
              label: "Confirm execution",
              onClick: confirmExecution,
            },
          ]}
          data-testid="actions-dropdown"
        />
      )}
      {isExecuted ? (
        <InlineCancelButton noOrder onClick={confirmExecution}>
          Re-upload lease
        </InlineCancelButton>
      ) : (
        <>
          <StyledButton>
            <ActionLink onClick={onSwitchESign} disabled={!isESigningEnabled}>
              Switch to E-Signing
            </ActionLink>
          </StyledButton>
          <StyledButton>
            <Button
              type="secondary"
              onClick={onModifyLease}
              loading={isLeaseModifying}
            >
              Modify Lease
            </Button>
          </StyledButton>
          <StyledButton>
            <Button type="primary" onClick={confirmExecution}>
              Confirm execution
            </Button>
          </StyledButton>
        </>
      )}
    </StyledFooter>
  );
};

export default LeaseOfflineFooter;
