import React, { useEffect } from "react";

import { useParams, useHistory } from "react-router-dom";

import { LEASE_APP_STATUSES_TO_SIGN } from "renter/config/constants";
import ROUTES from "renter/config/routes";
import { useLeaseApplication, useViewFinalLease } from "renter/hooks/api";
import { usePdfJs } from "shared/components/DocumentViewer";
import { fileNameFromWords } from "shared/utils/pdf";
import { transformRoute } from "shared/utils/routing";

import { LeaseSummaryContainerParams } from "./interfaces";
import LeaseSummary from "./LeaseSummary";

const backLink = {
  children: "Back",
  to: ROUTES.leases,
};

const LeaseSummaryContainer = () => {
  const history = useHistory();
  const { leaseApplicationId, leaseId }: LeaseSummaryContainerParams =
    useParams();

  const { data: leaseApplication, isLoading: isLeaseApplicationLoading } =
    useLeaseApplication({
      leaseApplicationId,
    });

  const { data: preview, isLoading: isFinalDocumentLoading } =
    useViewFinalLease({
      leaseApplicationId,
    });

  const propertyName = leaseApplication?.lease?.deal?.unit?.property?.name;
  const unitName = leaseApplication?.lease?.deal?.unit?.name;
  const applicantLastName = leaseApplication?.application?.lastName;

  const isSignAvailable =
    !isLeaseApplicationLoading &&
    LEASE_APP_STATUSES_TO_SIGN.includes(leaseApplication?.status);

  const signLease = () => {
    const isBluemoonLease = leaseApplication?.lease?.isBluemoonLease;
    const bluemoonSignatureUrl = leaseApplication?.bluemoonSignatureUrl;
    if (isBluemoonLease && Boolean(bluemoonSignatureUrl)) {
      window.open(bluemoonSignatureUrl, "_blank").focus();
    } else {
      history.push(
        transformRoute(ROUTES.leaseSign, {
          leaseApplicationId,
          leaseId,
        })
      );
    }
  };

  const viewerApi = usePdfJs();

  const showPreviewFile = async () => {
    const filename = fileNameFromWords({
      words: [propertyName, unitName, applicantLastName],
      timestamp: true,
    });

    // @ts-ignore
    await viewerApi.loadDocument({
      base64: preview.finalDocument,
      filename,
    });
  };

  useEffect(() => {
    if (viewerApi.isInitialized && preview) {
      showPreviewFile();
    }
  }, [viewerApi.isInitialized, preview]);

  const lease = leaseApplication?.lease;
  return (
    <LeaseSummary
      lease={lease}
      loading={isLeaseApplicationLoading || isFinalDocumentLoading}
      viewerApi={viewerApi}
      backLink={backLink}
      isSignatureUrlAvailable={isSignAvailable}
      signLease={signLease}
    />
  );
};

export default LeaseSummaryContainer;
