export enum LEASE_STATUS_CODES {
  partiallySigned = "PSG",
  inProgress = "IP",
  sent = "STA",
  applicantsSigned = "SBA",
  processingManagerSignature = "PMS",
  managerSigned = "SBM",
  leaseHandledOffline = "LHO",
  leaseExecutedOffline = "LEO",
  expired = "LEE",
}

export const LEASE_EXECUTION_STATUSES = new Set([
  LEASE_STATUS_CODES.leaseExecutedOffline,
  LEASE_STATUS_CODES.managerSigned,
]);

export enum LeaseApplicationStatus {
  notViewed = "NV",
  viewed = "VI",
  processingSignature = "PS",
  signed = "SI",
}

export enum ApplicantTypes {
  applicant = 1,
  guarantor = 2,
  occupant = 3,
}

export enum CONCESSION_TYPES {
  amortize = "AM",
  specificMonths = "SM",
}

export enum RenewalOfferStatus {
  IN_PROGRESS = "IN_PROGRESS",
  READY_TO_BE_APPROVED = "READY_TO_BE_APPROVED",
  APPROVED = "APPROVED",
  SENT = "SENT",
}

export enum RentChangeOptions {
  NO_CHANGE = "NO_CHANGE",
  AMOUNT_INCREASE = "AMOUNT_INCREASE",
  AMOUNT_DECREASE = "AMOUNT_DECREASE",
  PERCENT_INCREASE = "PERCENT_INCREASE",
  PERCENT_DECREASE = "PERCENT_DECREASE",
}

export enum RenewalOptionResponseStatus {
  ACCEPTED = "ACCEPTED",
  NOT_ACCEPTED = "NOT_ACCEPTED",
}
