import styled from "@emotion/styled";

import PageTitle from "shared/components/PageTitle";
import { MEDIA_QUERIES } from "shared/config/constants";

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  transform: translateY(50%);
`;

const Note = styled.div`
  margin-bottom: 20px;

  ${MEDIA_QUERIES.w.ltMd} {
    padding-left: 20px;
  }
`;

const Title = styled(PageTitle)`
  display: flex;
  align-items: center;
  & > div {
    margin-left: 20px;
  }
`;

const Header = styled(PageTitle.Container)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ScrollWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`;

export { LoadingContainer, Note, Header, ScrollWrapper, Title };
