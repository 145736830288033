import { useCallback, useEffect, useState } from "react";

import { throttle } from "lodash";

import { useCalculateSecurityDepositBalance } from "manager/hooks/api";
import { SecurityDepositDeductionStatus } from "manager/interfaces/types";

import { isSecurityDepositBalanceVisible } from "./LeaseInfoForm.utils";

interface SecurityDepositBalanceProps {
  timeout?: number;
  leaseId?: number;
  securityDeposit?: number;
  leaseSecurityDepositStatus: SecurityDepositDeductionStatus;
}

const useSecurityDepositBalance = ({
  timeout = 500,
  leaseId,
  securityDeposit,
  leaseSecurityDepositStatus,
}: SecurityDepositBalanceProps) => {
  const [securityDepositBalance, setSecurityDepositBalance] =
    useState<number>();

  const { calculateSecurityDepositBalance, balanceCalculations } =
    useCalculateSecurityDepositBalance();

  const calculateTotalRentCallback = useCallback(
    throttle(calculateSecurityDepositBalance, timeout),
    []
  );

  useEffect(() => {
    const isDepositBalanceVisible = isSecurityDepositBalanceVisible(
      leaseSecurityDepositStatus
    );

    if (securityDeposit && leaseId && isDepositBalanceVisible) {
      calculateTotalRentCallback({ securityDeposit, leaseId });
    }
  }, [securityDeposit, leaseId, leaseSecurityDepositStatus]);

  useEffect(() => {
    if (balanceCalculations) {
      setSecurityDepositBalance(balanceCalculations.remainingSecurityDeposit);
    }
  }, [balanceCalculations]);

  return {
    securityDepositBalance,
  };
};

export default useSecurityDepositBalance;
