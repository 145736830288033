import React from "react";

import { Modal } from "shared/components/Modal/Modal";

import { NavigationPromptInnerProps } from "./interfaces";

const NavigationPromptInner = ({
  title = "Are you sure?",
  subtitle = `\n`,
  cancelText = "Cancel",
  confirmText = "Exit",
  onCancel,
  onConfirm,
  isActive,
  children,
}: NavigationPromptInnerProps) => (
  <Modal
    title={title}
    subtitle={subtitle}
    visible={isActive}
    close={() => {
      onCancel();
    }}
    submitButtonHTMLType="button"
    submit={() => {
      onConfirm();
    }}
    onCancelLinkClick={() => {
      onCancel();
    }}
    cancelLinkLabel={cancelText}
    submitButtonLabel={confirmText}
    afterClose={() => {}}
  >
    {children}
  </Modal>
);

export default NavigationPromptInner;
