import React from "react";

import { Upload } from "antd";
import PropTypes from "prop-types";

import { MODALS } from "manager/config/constants";
import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";

import {
  InfoContent,
  MessageContainer,
  IconWrapper,
  Message,
  UploadLeaseButton,
  DocumentWrapper,
  DocumentName,
  RemoveIconWrapper,
} from "./styled";

const ConfirmLeaseExecutionModal = ({
  propertyName,
  unitName,
  confirm,
  isConfirming,
  showWarning,
  documents,
  uploadDocuments,
  onRemove,
  beforeUpload,
}) => (
  <Modal
    id={MODALS.confirmLeaseExecution}
    title="Confirm lease execution?"
    subtitle={
      <span data-testid="subtitle">
        Unit:{" "}
        <strong>
          {propertyName}, {unitName}
        </strong>
      </span>
    }
    submit={confirm}
    submitButtonLabel="Confirm"
    submitButtonDisabled={showWarning}
    submitting={isConfirming}
    closeOnSubmit={false}
  >
    <Modal.Body noPaddingBottom>
      <InfoContent>
        Once you confirm, this unit is effectively rented and will be updated in
        VERO. This is the final step in the leasing process.
      </InfoContent>

      <MessageContainer data-testid="warning">
        {showWarning ? (
          <>
            <IconWrapper>
              <Icon.OrangeExclamation height="100%" width="100%" />
            </IconWrapper>
            <Message>
              We highly recommend to upload the countersigned lease. This tends
              to help with record keeping.
            </Message>
          </>
        ) : (
          <>
            {documents?.map((document) => (
              <DocumentWrapper
                key={document?.uid}
                data-testid="document-wrapper"
              >
                <IconWrapper>
                  <Icon.PDFPreviewIcon height="100%" width="100%" />
                </IconWrapper>
                <DocumentName>{document?.name}</DocumentName>
                <RemoveIconWrapper onClick={() => onRemove(document)}>
                  <Icon.CrossRemoveIcon height="100%" width="100%" />
                </RemoveIconWrapper>
              </DocumentWrapper>
            ))}
          </>
        )}
      </MessageContainer>
      <Upload
        onChange={uploadDocuments}
        showUploadList={false}
        beforeUpload={beforeUpload}
        accept="application/pdf"
      >
        <UploadLeaseButton onClick={() => {}}>
          {showWarning ? "Upload lease" : "Upload more"}
        </UploadLeaseButton>
      </Upload>
    </Modal.Body>
  </Modal>
);

ConfirmLeaseExecutionModal.propTypes = {
  confirm: PropTypes.func.isRequired,
  propertyName: PropTypes.string.isRequired,
  unitName: PropTypes.string.isRequired,
  isConfirming: PropTypes.bool.isRequired,
  showWarning: PropTypes.bool.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  beforeUpload: PropTypes.func.isRequired,
};

export default ConfirmLeaseExecutionModal;
