import { useRef, useState } from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import capitalize from "lodash/capitalize";
import { useHistory } from "react-router-dom";

import {
  AssignDealToUnitModal,
  UpdateDealRevenueDetailsModal,
  AssignDealRevenueManagementModal,
} from "manager/components/Modal";
import ROUTES from "manager/config/routes";
import {
  useUpdateDealArchiveStatus,
  useGenerateDealPDF,
} from "manager/hooks/api";
import { Deal } from "manager/hooks/application-summary/mappers";
import { UnitListing } from "manager/interfaces/api/unitListing";
import { disableEditingActions } from "manager/utils/deal.util";
import { DropdownMenuItem } from "shared/components/DropdownMenu";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { DEAL_UI_STATUS_CODES } from "shared/config/constants";
import { useDeviceType } from "shared/hooks";
import { useGetPricingOffers } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";
import { LeaseApplication } from "shared/interfaces/api/lease";
import { transformRoute } from "shared/utils/routing";

import AssignAgentToDealModal from "../modals/AssignAgentToDealModal";

import { DealHeaderItemsProps } from "./blocks";
import { DealHeaderPropsV2 } from "./component";
import { DealHeaderDeal, PdfProps } from "./types";
import {
  getApplicationSource,
  getNoUnitText,
  getSecurityDepositBalance,
} from "./utils";

dayjs.extend(utc);

export const mapToHeaderItems = (
  deal: DealHeaderDeal,
  isMobile: boolean,
  isExpanded: boolean,
  editRent?: () => void,
  unitListingDeal?: UnitListing,
  primaryApplicantDeal?: LeaseApplication
): DealHeaderItemsProps => {
  const {
    rent,
    unit,
    isMagicDeal,
    isOpenApiDeal,
    agent,
    remainingSecurityDeposit,
    moveInDate,
    isSecurityDepositDeducted,
    canEditRent,
  } = deal ?? {};

  const props: DealHeaderItemsProps = {
    unit,
    rent: rent ? rent * 100 : null,
    isMobile,
    expanded: isExpanded,
    availableDate: unit?.availableOn ? dayjs.utc(unit.availableOn) : null,
    moveInDate: moveInDate ? dayjs.utc(moveInDate) : null,
    source: getApplicationSource({ isMagicDeal, isOpenApiDeal }),
    hasListing: !!unitListingDeal,
    canEditRent,
    editRent,
    formattedSecurityDeposit: getSecurityDepositBalance(
      remainingSecurityDeposit,
      isSecurityDepositDeducted
    ),
    agentName: agent
      ? `${agent?.name ?? ""} ${agent?.firstName ?? ""} ${
          agent?.lastName ?? ""
        }`.trim()
      : undefined,
    term: primaryApplicantDeal?.preferredLeaseDurationMonths ?? null,
    leaseStartDate: primaryApplicantDeal?.preferredLeaseStartDate
      ? dayjs.utc(primaryApplicantDeal?.preferredLeaseStartDate)
      : null,
  };

  return props;
};

export function mapToHeaderDeal(
  deal: Deal,
  dealId: string | number,
  unitListingDeal?: UnitListing,
  primaryApplicantDeal?: LeaseApplication
): DealHeaderDeal {
  return {
    remainingSecurityDeposit:
      deal?.fees?.isSecurityDepositPaid === true
        ? 0
        : (deal?.fees?.securityDeposit ?? 0) * 100,
    isSecurityDepositDeducted: deal?.fees?.isSecurityDepositDeducted,
    rent: deal?.fees?.rent,
    externalUrl: undefined,
    isMagicDeal: deal?.isMagicDeal,
    isOpenApiDeal: deal?.isOpenApiDeal,
    agent: deal?.agent,
    canEditRent: !deal?.permission?.noEditRent,
    id: dealId,
    property: deal?.property ?? null,
    unit: deal?.unit
      ? {
          availableOn: deal?.unit?.availableOn,
          bathrooms: deal?.unit?.bathrooms,
          name: deal?.unit?.name,
          bedrooms: deal?.unit?.bedrooms?.toString(),
          squareFeet: deal?.unit?.squareFootage?.toString(),
          unitType: deal?.unit?.unitType,
          yardiConnected: deal?.unit?.yardiConnected,
          hasLroEnabled: deal?.unit?.hasLroEnabled,
          externalId: deal?.unit?.externalId,
        }
      : null,
    status: deal?.status,
    leaseStatus: deal?.lease?.status,
    moveInDate: deal?.moveInDate,
    yardiPcode: deal?.yardi?.pCode,
    yardiTcode: deal?.yardi?.tCode,
    isArchived: deal?.isArchived,
    canBeArchived: !deal?.permission?.noArchive,
    assignUnitDisabledTooltip: null,
    unitListingDeal,
    primaryApplicantDeal,
  };
}

export type DealHeaderDropdownItemsProps = {
  dealId: string | number;
  hasUnit: boolean;
  assignUnitAllowed: boolean;
  assignUnit: () => void;
  assignAgent: () => void;
  isArchived: boolean;
  canBeArchived: boolean;
  assignUnitDisabledTooltip?: string;
  canEditRent: boolean;
  editRent: () => void;
  refetchDeal: () => void;
  downloadDealPDF?: () => void;
  contactAll?: any;
  invite?: any;
  requestCountyRecords?: any;
};

export const useDealHeaderDropdownItems = ({
  dealId,
  hasUnit,
  assignUnitAllowed,
  assignUnit,
  assignAgent,
  isArchived,
  canBeArchived,
  assignUnitDisabledTooltip,
  canEditRent,
  editRent,
  refetchDeal,
  downloadDealPDF,
  contactAll,
  invite,
  requestCountyRecords
}: DealHeaderDropdownItemsProps): DropdownMenuItem[] => {
  const history = useHistory();
  const { openModalDialog } = useModal();
  const { isMobile } = useDeviceType();

  const { updateDealArchiveStatus } = useUpdateDealArchiveStatus();

  const goToExtraDetails = () => {
    const url = transformRoute(ROUTES.dealExtraDetails, { id: dealId });
    history.push(url);
  };

  const items: DropdownMenuItem[] = [];

  if (invite) {
    items.push({
      key: "INVITE",
      label: "Invite Applicants",
      onClick: invite,
    });
  }

  if (contactAll) {
    items.push({
      key: "CONTACT_ALL",
      label: "Contact All",
      onClick: contactAll,
    });
  }

  if (requestCountyRecords) {
    items.push({
      key: "COUNTY_RECORDS",
      label: "Request County Records",
      onClick: requestCountyRecords
    })
  }

  if (canEditRent) {
    items.push({
      key: "EDIT_RENT",
      label: "Edit Rent",
      onClick: editRent,
    });
  }

  if (hasUnit && assignUnitAllowed) {
    items.push({
      key: "CHANGE_UNIT",
      label: "Change Unit",
      onClick: assignUnit,
      disabled: Boolean(assignUnitDisabledTooltip),
      ...(assignUnitDisabledTooltip && {
        tooltip: {
          title: assignUnitDisabledTooltip,
        },
      }),
    });
  }

  items.push({
    key: "ASSIGN_AGENT",
    label: "Assign Agent",
    onClick: assignAgent,
  });

  items.push({
    key: "DEAL_EXTRA_DETAILS",
    label: "Go to extra details",
    onClick: goToExtraDetails,
  });
  if (isMobile) {
    items.push({
      key: "DOWNLOAD_PDF",
      label: "Download PDF",
      onClick: downloadDealPDF,
    });
  }

  if (canBeArchived) {
    const actionLabel = isArchived ? "unarchive" : "archive";
    const title = `${capitalize(actionLabel)} Application`;
    const message = `Confirm you would like to ${actionLabel} this Application. Select the “Show archived applications” filter to include archived applications within your table view.`;

    const context = {
      title,
      message,
      noDescriptionIcon: true,
      onConfirm: () => {
        return updateDealArchiveStatus(
          { id: dealId, isArchived: !isArchived },
          { onSuccess: refetchDeal }
        );
      },
    };

    items.push({
      key: "ARCHIVE_APPLICATION",
      label: `${isArchived ? "Unarchive" : "Archive"} Application`,
      onClick: () => openModalDialog(ConfirmationModal, context),
    });
  }

  return items;
};

export const useDealHeaderInternal = ({
  deal,
  refetchDeal,
  contactAll,
  invite,
  requestCountyRecords
}: DealHeaderPropsV2) => {
  const [isExpanded, setExpanded] = useState(false);
  const { isMobile } = useDeviceType();
  const toggleExpandedSection = () => setExpanded(!isExpanded);

  const { openModalDialog } = useModal();
  const { downloadDealPDF, isLoading: isLoadingPDF } = useGenerateDealPDF(
    deal?.id
  );

  const pdfProps: PdfProps = {
    hasPermissionToDownloadPDF: true,
    isDealPDFDownloadable: ![
      DEAL_UI_STATUS_CODES.invitesSent,
      DEAL_UI_STATUS_CODES.applicationsIncomplete,
      DEAL_UI_STATUS_CODES.partiallySubmitted,
    ].includes(deal?.status),
    downloadDealPDF,
    isLoadingPDF,
  };

  const { unitListingDeal, primaryApplicantDeal } = deal ?? {};

  const dealId = deal?.id;
  const status = deal?.status;
  const unitName = deal?.unit?.name;
  const property = deal?.property;
  const propertyName = property?.name;
  const propertyAddress = property?.address;
  const unitHeaderTitle = [propertyName, unitName]
    .filter((s) => !!s)
    .join(", ");
  const hasUnit = !!unitName;
  const hasProperty = !!deal.property;
  const source = getApplicationSource({
    isMagicDeal: deal.isMagicDeal,
    isOpenApiDeal: deal.isOpenApiDeal,
  });
  const hasSource = !!source;

  let mainTitle = unitHeaderTitle;

  if (!hasUnit) {
    const noUnitText = getNoUnitText(
      deal?.isMagicDeal,
      deal?.externalUrl,
      propertyName
    );
    mainTitle = noUnitText;
  }

  const assignAgent = () => {
    const context = {
      dealId,
      unitName,
      currentAgentId: deal.agent?.id,
      afterAgentChanged: refetchDeal,
    };
    openModalDialog(AssignAgentToDealModal, context);
  };

  const assignUnit = () =>
    openModalDialog(AssignDealToUnitModal, { dealId, refetchDeal });

  const assignUnitAllowed = !disableEditingActions(deal?.leaseStatus);

  let pricingOffers;
  let isPricingOffersLoading;
  if (deal?.unit?.externalId && deal?.unit?.hasLroEnabled) {
    ({ pricingOffers, isPricingOffersLoading } = useGetPricingOffers(
      deal.unit.externalId
    ));
  }

  const editRent = () => {
    const unit = deal?.unit;

    if (
      unit?.hasLroEnabled &&
      unit?.externalId &&
      !isPricingOffersLoading &&
      pricingOffers?.length
    ) {
      openModalDialog(AssignDealRevenueManagementModal, {
        dealId,
        refetchDeal,
        unitLocation: unit.externalId,
        propertyName,
        unitName,
        availableOn: unit.availableOn,
        pricingOffers,
        rent: deal?.rent ? deal?.rent * 100 : null,
        preferredLeaseDurationMonths:
          primaryApplicantDeal?.preferredLeaseDurationMonths,
        preferredLeaseStartDate:
          primaryApplicantDeal?.preferredLeaseStartDate,
        availableDate: unitListingDeal?.unitAvailableOn
          ? dayjs.utc(unitListingDeal?.unitAvailableOn)
          : null,
      });
    } else {
      openModalDialog(UpdateDealRevenueDetailsModal, {
        values: {
          rent: deal?.rent ? deal?.rent * 100 : null,
          preferredLeaseDurationMonths:
            primaryApplicantDeal?.preferredLeaseDurationMonths,
          preferredLeaseStartDate:
            primaryApplicantDeal?.preferredLeaseStartDate,
        },
        refetchDeal,
        dealId,
        availableDate: unitListingDeal?.unitAvailableOn
          ? dayjs.utc(unitListingDeal?.unitAvailableOn)
          : null,
      });
    }
  };

  const dropdownItems = useDealHeaderDropdownItems({
    dealId,
    hasUnit,
    assignUnitAllowed,
    isArchived: deal?.isArchived,
    canBeArchived: deal?.canBeArchived,
    canEditRent: deal?.canEditRent,
    assignUnitDisabledTooltip: deal?.assignUnitDisabledTooltip,
    assignUnit,
    assignAgent,
    editRent,
    refetchDeal,
    downloadDealPDF,
    contactAll,
    invite,
    requestCountyRecords
  });

  const dealHeaderTitleContainerRef = useRef(null);
  const extraContentRef = useRef(null);
  const items = mapToHeaderItems(
    deal,
    isMobile,
    isExpanded,
    editRent,
    unitListingDeal,
    primaryApplicantDeal
  );

  return {
    items,
    isMobile,
    dealHeaderTitleContainerRef,
    hasUnit,
    unitHeaderTitle,
    propertyAddress,
    mainTitle,
    hasProperty,
    extraContentRef,
    assignUnitAllowed,
    hasSource,
    isExpanded,
    status,
    pdfProps,
    dropdownItems,
    assignUnit,
    toggleExpandedSection,
    managerPropertyUrl: `/properties/${deal?.property?.id}`
  };
};
