import React from "react";

import { useFormikContext } from "formik";
import { useHistory, useParams } from "react-router-dom";

import { PreviewDocumentTemplateModal } from "manager/components/Leasing/PreviewLeaseModal";
import { PropertySetupDocumentFieldsModal } from "manager/components/Modal";
import ROUTES from "manager/config/routes";
import { useUpdatePropertyDocumentsOrdering } from "manager/hooks/api";
import { useDeviceType } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";

import {
  DocumentTemplate,
  DocumentFormikValues,
  SetupFieldModalParams,
} from "./interfaces";
import PropertyDocumentTemplates from "./PropertyDocumentTemplates";

interface PropertyDocumentTemplatesContainerProps {
  disabled?: boolean;
  loading?: boolean;
}

const PropertyDocumentTemplatesContainer = ({
  disabled,
  loading,
}: PropertyDocumentTemplatesContainerProps) => {
  const { id: propertyId }: { id?: string } = useParams();
  const history = useHistory();
  const { isMobile } = useDeviceType();
  const { openModalDialog } = useModal();
  const { values, setFieldValue } = useFormikContext<DocumentFormikValues>();
  const { documentTemplates: documents } = values;

  const openSetupFieldModal = (params: SetupFieldModalParams) =>
    openModalDialog(PropertySetupDocumentFieldsModal, params);

  const previewDocument = (document: DocumentTemplate) =>
    openModalDialog(PreviewDocumentTemplateModal, {
      document: {
        id: document.id,
        name: document.name,
      },
    });

  const { updatePropertyDocumentsOrdering, isLoading } =
    useUpdatePropertyDocumentsOrdering();

  const onDragChange = (orders) => {
    // @ts-ignore
    updatePropertyDocumentsOrdering({
      id: propertyId,
      values: orders.map((item, index) => ({
        documentTemplateId: item.key,
        sortingIdx: index,
      })),
    });
  };

  const redirectToSetupFields = () =>
    history.push(
      transformRoute(ROUTES.propertySettingsSetupFields, { id: propertyId })
    );

  const handleOnChange = (id: number, checked: boolean) => {
    const index = documents?.documentTemplates?.findIndex(
      (item) => item.id === id
    );
    setFieldValue(
      `documentTemplates.documentTemplates[${index}].isActive`,
      checked
    );
  };

  return (
    <PropertyDocumentTemplates
      openSetupFieldModal={openSetupFieldModal}
      previewDocument={previewDocument}
      isOrderingLoading={isLoading}
      isMobile={isMobile}
      onDragChange={onDragChange}
      redirectToSetupFields={redirectToSetupFields}
      handleOnChange={handleOnChange}
      propertyId={propertyId}
      documents={documents}
      values={values}
      disabled={disabled}
      loading={loading}
    />
  );
};

export default PropertyDocumentTemplatesContainer;
