import React, { useContext } from 'react'

import { Col, Row } from 'antd'
import _ from 'lodash'

import PropTypes from 'prop-types'

import { useGetPerson } from 'renter/hooks/api'
import ResidentialHistory from 'shared/components/Applicant/ResidentialHistory'
import Spacer from 'shared/components/Spacer'
import Tile from 'shared/components/Tile'
import { getFullnameData } from 'shared/utils/applicant'
import {
  calcTileInnerBorderBottom,
  formatDate,
  getValueOrNA,
} from 'shared/utils/ui'

import ApplicationContext from '../applicationContext'
import AdditionalInfo from '../components/AdditionalInfo'

import { EmploymentHistory, useEmploymentHistory } from '../EmploymentHistory'

import Person from './Person'

const RenterProfile = ({ type, name, applicationSummary }) => {
  const {
    industries,
    kidOptions,
    windowOptions,
    knobOptions,
    marketingSources,
    petTypes,
    application,
  } = useContext(ApplicationContext)

  const {
    email,
    phoneNumber,
    birthdate: dateOfBirth,
    residentialNonMatchingCriteriaNotes,
    residentialHistoryDuration,
    residentialHistoryRange,
    residentialDurationValid,
    canShowSsn,
    ssn,
    preferredLeaseDurationMonths,
    preferredLeaseStartDate,
  } = applicationSummary

  const {
    id: applicationId,
    emergencyContacts,
    residentialHistories: residentialHistory,
    additionalInformation: additionalInfo,
    requiredAdditionalInformation: requiredAdditionalInfo,
  } = application || {}

  const identities = _.get(application, 'identities', [])

  const { person } = useGetPerson(applicationId)

  // eslint-disable-next-line func-names
  const emergencyContactsSortedByDateCreated = emergencyContacts.sort(function (
    a,
    b,
  ) {
    return new Date(a.createdAt) - new Date(b.createdAt)
  })

  const employmentProps = useEmploymentHistory(applicationSummary)

  return (
    <>
      <Row gutter={14} type="flex">
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Tile
            equalHeight
            header={{
              title: 'Basic Info',
              label: {
                type: 'primary',
                text: type || 'Unknown',
              },
            }}
          >
            <Tile.Inner data-testid="person">
              <Person
                applicationId={applicationId}
                name={name}
                phoneNumber={phoneNumber}
                email={email}
                dateOfBirth={dateOfBirth}
                ssn={ssn}
                canShowSSN={canShowSsn}
              />
            </Tile.Inner>
          </Tile>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Tile
            equalHeight
            header={{
              title: 'Emergency Contacts',
            }}
            noBorderBottom
          >
            {emergencyContactsSortedByDateCreated?.length ? (
              emergencyContactsSortedByDateCreated.map((contact) => (
                <Tile.Inner
                  key={contact.id}
                  borderBottom
                  data-testid={`emergency-contact-${contact.id}`}
                >
                  <Person
                    name={
                      contact?.name ||
                      `${contact.firstName} ${contact.lastName}`
                    }
                    phoneNumber={contact?.phone || contact.phoneNumber}
                    relationshipType={contact.type || contact.relationship}
                    relationship={contact.relationship}
                  />
                </Tile.Inner>
              ))
            ) : (
              <Tile.Inner
                key={0}
                borderBottom
                data-testid={`emergency-contact-${0}`}
              >
                No emergency contacts added
              </Tile.Inner>
            )}
          </Tile>
        </Col>
      </Row>
      <Tile
        header={{
          title: 'Identities',
          collapsibleOnDesktop: true,
          collapsibleOnMobile: true,
        }}
      >
        {_.size(identities) > 0 ? (
          identities.map((identity, index) => {
            const { hasFullname, fullNameOrNA } = getFullnameData({
              firstName: _.replace(_.get(identity, 'firstName', ''), 'N/A', ''),
              middleName: _.replace(
                _.get(identity, 'middleName', ''),
                'N/A',
                '',
              ),
              lastName: _.replace(_.get(identity, 'lastName', ''), 'N/A', ''),
            })

            const fullName = hasFullname
              ? _.chain(fullNameOrNA).toLower().startCase().value()
              : fullNameOrNA

            return (
              <Tile.Inner
                key={_.get(identity, 'id')}
                borderBottom={calcTileInnerBorderBottom(identities, index)}
                data-testid={`identities-${_.get(identity, 'id')}`}
              >
                <Row type="flex">
                  <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 5 }}>
                    <Tile.Label>Verification Method</Tile.Label>
                    {getValueOrNA(_.get(identity, 'verificationMethod'))}
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 7 }}>
                    <Spacer hideGteMd />
                    <Tile.Label>Name</Tile.Label>
                    {fullName}
                  </Col>
                </Row>
              </Tile.Inner>
            )
          })
        ) : (
          <Tile.Inner borderBottom data-testid={`lease-preference-${0}`}>
            No identities added
          </Tile.Inner>
        )}
      </Tile>
      <ResidentialHistory
        residentialHistory={residentialHistory}
        residentialNonMatchingCriteriaNotes={
          residentialNonMatchingCriteriaNotes
        }
        residentialHistoryDuration={residentialHistoryDuration}
        residentialHistoryRange={residentialHistoryRange}
        residentialDurationValid={residentialDurationValid}
      />
      <EmploymentHistory {...employmentProps} />
      <Tile
        header={{
          title: 'Lease Preferences',
          collapsibleOnDesktop: true,
          collapsibleOnMobile: true,
        }}
      >
        {!!preferredLeaseDurationMonths && !!preferredLeaseStartDate ? (
          <Tile.Inner>
            <Row type="flex">
              <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 5 }}>
                <Tile.Label>Preferred lease duration months</Tile.Label>
                {getValueOrNA(preferredLeaseDurationMonths)}
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 7 }}>
                <Spacer hideGteMd />
                <Tile.Label>Preferred lease start date</Tile.Label>
                {formatDate(preferredLeaseStartDate)}
              </Col>
            </Row>
          </Tile.Inner>
        ) : (
          <Tile.Inner borderBottom data-testid={`lease-preference-${0}`}>
            No lease preferences added
          </Tile.Inner>
        )}
      </Tile>
      <AdditionalInfo
        additionalInfo={additionalInfo}
        industries={industries}
        kidOptions={kidOptions}
        windowOptions={windowOptions}
        knobOptions={knobOptions}
        marketingSourceOptions={marketingSources}
        petTypes={petTypes}
        requiredAdditionalInfo={requiredAdditionalInfo}
        person={person}
      />
    </>
  )
}

RenterProfile.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['Applicant', 'Guarantor']),
  applicationSummary: PropTypes.shape({
    email: PropTypes.string,
    birthdate: PropTypes.string,
    employmentHistoryDuration: PropTypes.number,
    employmentHistoryRange: PropTypes.string,
    employmentNonMatchingCriteriaNotes: PropTypes.string,
    phoneNumber: PropTypes.string,
    residentialHistoryDuration: PropTypes.number,
    residentialHistoryRange: PropTypes.string,
    residentialNonMatchingCriteriaNotes: PropTypes.string,
    employmentDurationValid: PropTypes.bool,
    residentialDurationValid: PropTypes.bool,
    canShowSsn: PropTypes.bool,
    ssn: PropTypes.string,
    preferredLeaseDurationMonths: PropTypes.string,
    preferredLeaseStartDate: PropTypes.string,
  }),
}

RenterProfile.defaultProps = {
  type: undefined,
  applicationSummary: {
    email: undefined,
    phoneNumber: undefined,
    birthdate: undefined,
    employmentHistoryDuration: undefined,
    employmentHistoryRange: '',
    employmentNonMatchingCriteriaNotes: '',
    residentialHistoryDuration: undefined,
    residentialHistoryRange: '',
    residentialNonMatchingCriteriaNotes: '',
    employmentDurationValid: false,
    residentialDurationValid: false,
    canShowSsn: false,
    ssn: '',
    preferredLeaseDurationMonths: undefined,
    preferredLeaseStartDate: undefined,
  },
}

export default RenterProfile
