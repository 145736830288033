import {
  useRenewalActions,
  useRenewalHelpers,
} from "manager/components/Renewals";
import { ACTION_NOT_PERMITTED_MESSAGE } from "manager/config/constants";
import {
  LeaseGroup,
  LeaseGroupApplication,
} from "manager/interfaces/api/leaseGroups";
import { conditionalItem } from "shared/utils/array";
import { contactByEmails } from "shared/utils/ui";

const useGetLeaseGroupHeaderDropdown = (
  leaseGroup?: LeaseGroup,
  residents?: LeaseGroupApplication[]
) => {
  const { renewalActions } = useRenewalActions();
  const { offerHelpers } = useRenewalHelpers({
    renewal: leaseGroup?.renewal,
  });
  return [
    {
      key: "CONTACT_RENTERS",
      label: "Contact renters",
      onClick: () =>
        contactByEmails(residents?.map((resident) => resident.email)),
    },
    ...conditionalItem(offerHelpers.isWithdrawOfferEnabled, {
      key: "WITHDRAW_OFFER",
      label: "Withdraw offer",
      onClick: () => renewalActions.withdrawOffer(leaseGroup?.renewal),
      disabled: !offerHelpers.canWithdrawOffer,
      tooltip: {
        title: !offerHelpers.canWithdrawOffer && ACTION_NOT_PERMITTED_MESSAGE,
      },
    }),
    ...conditionalItem(offerHelpers.isNotRenewingOfferEnabled, {
      key: "NOT_RENEWING",
      label: "Not renewing",
      onClick: () => renewalActions.notRenewing(leaseGroup?.renewal),
      disabled: !offerHelpers.canNotRenewOffer,
      tooltip: {
        title: !offerHelpers.canNotRenewOffer && ACTION_NOT_PERMITTED_MESSAGE,
      },
    }),
  ];
};

export default useGetLeaseGroupHeaderDropdown;
