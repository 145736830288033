import React from "react";

import Tile from "shared/components/Tile";
import { RegularText } from "shared/components/Typography";

import { StyledTile } from "./styled";

export const NoActiveRenewalOffer = () => {
  return (
    <StyledTile noBorder>
      <Tile.Inner className="tile-inner">
        <RegularText light>No active renewal offers created</RegularText>
      </Tile.Inner>
    </StyledTile>
  );
};
