import React from "react";

import {
  useRenewalActions,
  useRenewalHelpers,
} from "manager/components/Renewals";
import { LeaseGroup } from "manager/interfaces/api/leaseGroups";
import { ActionLink } from "shared/components/Links";
import { RenewalOfferOption } from "shared/components/Renewals/RenewalOfferOption";
import Tile from "shared/components/Tile";
import { SmallText } from "shared/components/Typography";

import { StyledLeaseGroupOffers } from "./styled";

const LeaseGroupOffers = ({ leaseGroup }: { leaseGroup: LeaseGroup }) => {
  const { renewalActions } = useRenewalActions();
  const { offerHelpers } = useRenewalHelpers({
    renewal: leaseGroup?.renewal,
  });

  const createOfferAction = (
    <ActionLink
      plus
      onClick={() => renewalActions.createOffer(leaseGroup?.renewal)}
      className="offer-link"
      disabled={!offerHelpers.canCreateOffer}
    >
      New Offer
    </ActionLink>
  );

  const editOfferAction = (
    <ActionLink
      onClick={() => renewalActions.finishCreatingOffer(leaseGroup?.renewal)}
      className="offer-link"
    >
      Edit Offer
    </ActionLink>
  );

  const primaryAction =
    (offerHelpers.isOfferCreationEnabled && createOfferAction) ||
    (offerHelpers.isOfferEditEnabled && editOfferAction) ||
    null;

  return (
    <StyledLeaseGroupOffers
      header={{
        title: <SmallText extraStrong>RENEWAL OFFERS</SmallText>,
        collapsibleOnDesktop: true,
        collapsibleOnMobile: true,
        withoutBorderBottom: true,
        primaryAction,
      }}
      noMarginBottom
    >
      <Tile.Inner noPaddingTop>
        {leaseGroup?.renewal?.offer?.renewalOptions?.map((option) => {
          return (
            <RenewalOfferOption
              key={option.id}
              option={option}
              deleteOption={() =>
                renewalActions.deleteOption(leaseGroup?.renewal, option)
              }
              grayOutRejectedOptions={offerHelpers.optionsDisabled}
              canDeleteOption={offerHelpers.canDeleteOfferOption}
              showDelete={offerHelpers.isDeleteOptionEnabled}
              className="lease-group-offer"
            />
          );
        })}
      </Tile.Inner>
    </StyledLeaseGroupOffers>
  );
};

export default LeaseGroupOffers;
