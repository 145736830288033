import {
  ConcessionFormData,
  convertFormConcessionMonthToModel,
  convertModelConcessionMonthToForm,
} from "manager/components/Leasing/Concession";
import { CONCESSION_TYPES } from "shared/config/constants";
import { RenewalOptionConcession } from "shared/interfaces/api/renewals";

export const convertModelRenewalConcessionToForm = (
  concession: RenewalOptionConcession
): ConcessionFormData => {
  return {
    concessionType: concession.type,
    dates: (concession.months || []).map(convertModelConcessionMonthToForm),
    numberOfMonths: concession.numberOfMonths,
  };
};

export const convertFormRenewalConcessionToModel = (
  values: ConcessionFormData
): RenewalOptionConcession => {
  const months =
    values.concessionType === CONCESSION_TYPES.specificMonths
      ? (values.dates || []).map(convertFormConcessionMonthToModel)
      : [];
  const numberOfMonths = Number(values.numberOfMonths) || undefined;

  return {
    type: values.concessionType,
    months,
    numberOfMonths,
  };
};
