import React from "react";

import { COLORS } from "manager/config/constants";
import { Popover } from "shared/components/Popover";
import Tooltip from "shared/components/Tooltip";
import Icon from "shared/components/Icon";
import { MediumTitle } from "shared/components/Typography";
import {
  COLORS as BaseColors,
  colors,
  typography,
} from "shared/config/constants";
import { Permission } from "shared/graphql";
import { useDeviceType } from "shared/hooks";

import { AppStatus } from "../types";

import { Actions, ButtonType, LumenSmallIconsByScore } from "./interfaces";
import {
  ActionMainButton,
  MoreActionsButton,
  LumenSublabel,
  Subtitle,
  EmptyBoxWhite,
  CalculatingWhite,
  PopoverContainer,
  StyledActionButton,
} from "./styled";

export const DoneTitle = ({ text }: { text: string }) => {
  return (
    <MediumTitle
      strong
      style={{ color: typography.textColors.dark.primary, alignSelf: "center" }}
    >
      {text?.toUpperCase()}
    </MediumTitle>
  );
};
export const LumenSubtitle: React.FC<{
  showItems: boolean;
  subtitle: string;
  onClick: () => void;
}> = ({ subtitle, showItems, onClick }) => {
  const { isMobile } = useDeviceType();
  if (subtitle) return <Subtitle>{subtitle}</Subtitle>;
  if (showItems)
    return (
      <LumenSublabel strong disabled={!isMobile} onClick={onClick}>
        Lumen identified the below items:
      </LumenSublabel>
    );

  return null;
};

export const LumenIcon: React.FC<{ status: AppStatus; score?: string }> = ({
  status,
  score,
}) => {
  const props = {
    width: "40px",
    height: "40px",
  };
  if (status === AppStatus.Empty) {
    return <EmptyBoxWhite {...props} />;
  }
  if (status === AppStatus.Submitting) {
    return <CalculatingWhite {...props} />;
  }

  if (!score) return <EmptyBoxWhite {...props} />;
  return <Icon.BulbGraySmall {...props} />;
};

export const ActionButton: React.FC<{
  actions: Actions;
  permission: Permission;
  button?: ButtonType;
}> = ({
  button,
  permission = {},
  actions: {
    cancel,
    approve,
    decline,
    conditional,
    createLease,
    conditionallyProceed,
    gotoLease,
    sendToPms,
  },
}) => {
  switch (button) {
    case ButtonType.goToLease:
      return (
        <ActionMainButton
          type="success"
          onClick={gotoLease}
          backgroundColor={BaseColors.blueViolet}
        >
          Go to lease
        </ActionMainButton>
      );
    case ButtonType.createLease:
      return (
        <Tooltip theme="light" title={permission.noCreateLeaseReason}>
          <div>
            <ActionMainButton
              type="success"
              onClick={createLease}
              backgroundColor={BaseColors.blueViolet}
              disabled={permission.noCreateLease}
            >
              Create Lease
            </ActionMainButton>
          </div>
        </Tooltip>
      );
    case ButtonType.approve:
      return (
        <Tooltip theme="light" title={permission.noApproveReason}>
          <div>
            <ActionMainButton
              type="success"
              onClick={approve}
              color={BaseColors.darkGreen}
              disabled={permission.noApprove}
            >
              Approve
            </ActionMainButton>
          </div>
        </Tooltip>
      );
    case ButtonType.conditional:
      return (
        <Tooltip
          theme="light"
          title={
            permission.noRequestInfoReason ||
            "Ask the applicant to submit additional requirements"
          }
        >
          <div>
            <ActionMainButton
              type="secondary"
              color={BaseColors.darkYellow}
              backgroundColor={BaseColors.orange}
              onClick={conditional}
              disabled={permission.noRequestInfo}
            >
              Request More Info
            </ActionMainButton>
          </div>
        </Tooltip>
      );
    case ButtonType.cancel:
      return (
        <Tooltip theme="light" title={permission.noCancelReason}>
          <div>
            <MoreActionsButton
              type="primary"
              onClick={cancel}
              disabled={permission.noCancel}
            >
              CANCEL DEAL
            </MoreActionsButton>
          </div>
        </Tooltip>
      );
    case ButtonType.decline:
      return (
        <Tooltip theme="light" title={permission.noDeclineReason}>
          <div>
            <ActionMainButton
              color={BaseColors.darkRed}
              type="danger"
              onClick={decline}
              disabled={permission.noDecline}
            >
              Decline
            </ActionMainButton>
          </div>
        </Tooltip>
      );
    case ButtonType.conditionallyProceed:
      return (
        <Tooltip title={permission.noConditionalProceedReason} theme="light">
          <div>
            <ActionMainButton
              type="success"
              color={BaseColors.darkGreen}
              onClick={conditionallyProceed}
              disabled={permission.noConditionalProceed}
            >
              Conditionally Proceed
            </ActionMainButton>
          </div>
        </Tooltip>
      );
    case ButtonType.sendToPms:
      return (
        <Tooltip
          title={
            permission.canSendToPms
              ? ""
              : "The roles assigned to your user do not allow access to this functionality"
          }
          theme="light"
        >
          <div>
            <ActionMainButton
              type={permission.canSendToPms ? "success" : ""}
              color={BaseColors.darkGreen}
              onClick={sendToPms}
              disabled={!permission.canSendToPms}
            >
              Send to Entrata
            </ActionMainButton>
          </div>
        </Tooltip>
      );
    default:
      return null;
  }
};

export const ActionPopover: React.FC<{
  actions: Actions;
  buttons: ButtonType[];
  skip?: ButtonType;
  permission?: Permission;
  visiblePopover: boolean;
  onVisibleChange: (value: boolean) => void;
}> = ({
  actions: {
    cancel,
    conditional,
    decline,
    approve,
    createLease,
    conditionallyProceed,
    gotoLease,
    recalculateLumen,
    sendToPms,
    resendToPms,
  },
  buttons,
  children,
  skip,
  permission = {},
  visiblePopover,
  onVisibleChange,
}) => {
  buttons = buttons.filter((b) => b !== skip);
  return (
    // @ts-ignore
    <Popover
      content={
        <PopoverContainer>
          {buttons.map((button) => {
            switch (button) {
              case ButtonType.goToLease:
                return (
                  <StyledActionButton
                    color={COLORS.deal.decisionDropdown.approve}
                    type="primary"
                    onClick={gotoLease}
                  >
                    Go to lease
                  </StyledActionButton>
                );
              case ButtonType.createLease:
                return (
                  <Tooltip theme="light" title={permission.noCreateLeaseReason}>
                    <div>
                      <StyledActionButton
                        color={COLORS.deal.decisionDropdown.approve}
                        type="primary"
                        onClick={createLease}
                        disabled={permission.noCreateLease}
                      >
                        Create Lease
                      </StyledActionButton>
                    </div>
                  </Tooltip>
                );
              case ButtonType.approve:
                return (
                  <Tooltip theme="light" title={permission.noApproveReason}>
                    <div>
                      <StyledActionButton
                        color={COLORS.deal.decisionDropdown.approve}
                        type="primary"
                        onClick={approve}
                        disabled={permission.noApprove}
                      >
                        Approve
                      </StyledActionButton>
                    </div>
                  </Tooltip>
                );
              case ButtonType.conditionallyProceed:
                return (
                  <Tooltip
                    theme="light"
                    title={permission.noConditionalProceedReason}
                  >
                    <div>
                      <StyledActionButton
                        color={COLORS.deal.decisionDropdown.approve}
                        type="primary"
                        onClick={conditionallyProceed}
                        disabled={permission.noConditionalProceed}
                      >
                        Conditionally Proceed
                      </StyledActionButton>
                    </div>
                  </Tooltip>
                );

              case ButtonType.conditional:
                return (
                  <Tooltip
                    theme="light"
                    title={
                      permission.noRequestInfoReason ||
                      "Ask the applicant to submit additional requirements"
                    }
                  >
                    <div>
                      <StyledActionButton
                        color={
                          COLORS.deal.decisionDropdown.conditionallyApproveV2
                        }
                        type="primary"
                        onClick={conditional}
                        disabled={permission.noRequestInfo}
                      >
                        Request More Info
                      </StyledActionButton>
                    </div>
                  </Tooltip>
                );
              case ButtonType.decline:
                return (
                  <Tooltip theme="light" title={permission.noDeclineReason}>
                    <div>
                      <StyledActionButton
                        color={COLORS.deal.decisionDropdown.decline}
                        type="primary"
                        onClick={decline}
                        disabled={permission.noDecline}
                      >
                        Decline Application
                      </StyledActionButton>
                    </div>
                  </Tooltip>
                );
              case ButtonType.cancel:
                return (
                  <Tooltip theme="light" title={permission.noCancelReason}>
                    <div>
                      <StyledActionButton
                        type="primary"
                        onClick={cancel}
                        disabled={permission.noCancel}
                      >
                        Cancel Application
                      </StyledActionButton>
                    </div>
                  </Tooltip>
                );
              case ButtonType.recalculateLumen:
                return (
                  <StyledActionButton
                    type="primary"
                    onClick={recalculateLumen}
                    color={colors.secondary[100]}
                  >
                    Recalculate Lumen
                  </StyledActionButton>
                );
              case ButtonType.sendToPms:
                return (
                  <StyledActionButton
                    color={COLORS.deal.decisionDropdown.approve}
                    onClick={sendToPms}
                    disabled={!permission.canSendToPms}
                    type="primary"
                  >
                    Send to Entrata
                  </StyledActionButton>
                );
              case ButtonType.resendToPms:
                return (
                  <StyledActionButton
                    color={COLORS.deal.decisionDropdown.approve}
                    onClick={resendToPms}
                    type="primary"
                  >
                    Resend to PMS
                  </StyledActionButton>
                );

              default:
                return null;
            }
          })}
        </PopoverContainer>
      }
      trigger="click"
      placement="bottomRight"
      withoutOverlay
      overlayClassName="dark-theme"
      visible={visiblePopover}
      onVisibleChange={onVisibleChange}
    >
      {!buttons?.length ? null : children}
    </Popover>
  );
};
