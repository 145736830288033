import styled from "@emotion/styled";
import { Radio } from "antd";

import { Button } from "shared/components/Button";
import List from "shared/components/List";
import Tile from "shared/components/Tile";
import { COLORS } from "shared/config/constants";

export const Title = styled.span`
  color: ${COLORS.textColorPrimary};
  font-size: 17px;
  font-weight: 500;
`;

export const ListItem = styled(List.Item)<{ selected?: boolean }>`
  color: ${COLORS.textColorPrimary};
  ${({ selected }) =>
    selected &&
    `
  backgroundColor: ${COLORS.lightGreen};
`}
`;

export const RadioOption = styled(Radio)`
  color: ${COLORS.textColorPrimary};
`;

export const Label = styled.span`
  color: ${COLORS.textColorPrimary};
`;

export const TileCentered = styled(Tile)`
  align-items: center;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
`;
