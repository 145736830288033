import React from "react";

import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import Icon from "shared/components/Icon";
import { BackLink } from "shared/components/Links";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import {
  SaveLeaseProcessContainer,
  SaveLeaseProcessIcon,
  SaveLeaseProcessText,
} from "../../styled";
import { ActionsContainer } from "../styled";

import { FooterBar as StyledFooterBar } from "./styled";

const LeaseFieldsFooter = ({ onFinishLater, onPrevStep, isSubmitting }) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });

  const ActionsContainerComponent = isMobile
    ? React.Fragment
    : ActionsContainer;

  return (
    <Container noPaddingBottom noPaddingTop stickToBottom>
      <StyledFooterBar data-testid="lease-fields-footer">
        <BackLink
          disabled={isSubmitting}
          onClick={onPrevStep}
          data-testid="previous-step"
          size="lg"
        >
          Previous Step
        </BackLink>

        <ActionsContainerComponent>
          <SaveLeaseProcessContainer
            onClick={onFinishLater}
            data-testid="finish-later"
            disabled={isSubmitting}
          >
            <SaveLeaseProcessIcon />
            <SaveLeaseProcessText>Save & Finish Later</SaveLeaseProcessText>
          </SaveLeaseProcessContainer>

          <Button
            htmlType="submit"
            type="primary"
            SuffixIcon={Icon.ButtonForwardArrowIcon}
            data-testid="next-step"
            disabled={isSubmitting}
          >
            Next Step
          </Button>
        </ActionsContainerComponent>
      </StyledFooterBar>
    </Container>
  );
};

LeaseFieldsFooter.propTypes = {
  onFinishLater: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default LeaseFieldsFooter;
