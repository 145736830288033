import React from "react";

import { useHistory, useParams } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { usePermissions } from "shared/hooks";
import { BackLinkProps } from "shared/interfaces/misc";
import { transformRoute } from "shared/utils/routing";

import { useGetPortfolio } from "../../hooks/api/portfolioQueries";

import PortfolioSettings from "./PortfolioSettings";

interface PortfolioSettingsContainerProps {
  backLink: BackLinkProps;
}

const PortfolioSettingsContainer = ({
  backLink,
}: PortfolioSettingsContainerProps) => {
  const history = useHistory();
  const { id, tabName }: { id?: string; tabName?: string } = useParams();

  const { portfolio, isPortfolioLoading } = useGetPortfolio(id);

  const {
    enableHoldingDeposit,
    areRenewalsEnabled,
    isPrescreeningEnabled,
    isYardiLROEnabled,
  } = usePermissions();

  const onTabChange = (key: string) =>
    history.push(
      transformRoute(ROUTES.portfolioSettingsTab, { id, tabName: key })
    );

  return (
    <PortfolioSettings
      portfolio={portfolio}
      loading={isPortfolioLoading}
      backLink={backLink}
      tabName={tabName}
      onTabChange={onTabChange}
      enableHoldingDeposit={enableHoldingDeposit}
      areRenewalsEnabled={areRenewalsEnabled}
      isPrescreeningEnabled={isPrescreeningEnabled}
      isYardiLROEnabled={isYardiLROEnabled}
    />
  );
};

export default PortfolioSettingsContainer;
