import React, { useState } from "react";

import { useExecuteOfflineLease } from "manager/hooks/api/leaseQueries";
import { MAX_FILE_UPLOAD_SIZE } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { useUploadValidation } from "shared/hooks/useUploadValidation";
import { mergePDF } from "shared/utils/pdf";

import ConfirmLeaseExecutionModal from "./ConfirmLeaseExecutionModal";
import { useShowWarning } from "./useShowWarning";

const ConfirmLeaseExecutionModalContainer = () => {
  const { currentModalContext, closeActiveModalDialog } = useModal();
  const { isFileSizeValid, isFileNameValid } = useUploadValidation();
  const { unitName, propertyName, leaseId } = currentModalContext;

  const [documents, setDocuments] = useState([]);
  const { showWarning } = useShowWarning(documents);

  const onSuccess = () => closeActiveModalDialog();
  const onError = () => closeActiveModalDialog();
  const { executeLease, isLoading } = useExecuteOfflineLease({
    onSuccess,
    onError,
    leaseId,
  });

  const onSubmit = async () => {
    const files = documents?.map((document) => document.base64);
    const finalDocument = await mergePDF(files, false);

    executeLease({ leaseId, finalDocument });
  };

  const uploadDocuments = async ({ file }) => {
    const alreadyExist = documents?.find(
      (document) => document.name === file.name
    );
    if (!alreadyExist) {
      const updatedDocument = { ...file };
      const reader = new FileReader();
      reader.onload = (e) => {
        updatedDocument.base64 = e.target.result;
      };
      reader.readAsDataURL(file.originFileObj);
      const updatedDocuments = [...documents, updatedDocument];
      setDocuments(updatedDocuments);
    }
  };

  const onRemove = async (file) => {
    const savedFiles = documents?.filter((item) => item.uid !== file.uid);
    setDocuments(savedFiles);
  };

  const beforeUpload = (file) => {
    const { name, size } = file;
    return (
      isFileNameValid(name, true) &&
      isFileSizeValid(MAX_FILE_UPLOAD_SIZE, size, name)
    );
  };

  return (
    <ConfirmLeaseExecutionModal
      unitName={unitName}
      propertyName={propertyName}
      confirm={onSubmit}
      isConfirming={isLoading}
      showWarning={showWarning}
      uploadDocuments={uploadDocuments}
      documents={documents}
      onRemove={onRemove}
      beforeUpload={beforeUpload}
    />
  );
};

export default ConfirmLeaseExecutionModalContainer;
