import React from "react";

import { RenewalBasic } from "manager/interfaces/api/renewals";
import FlexContainer from "shared/components/FlexContainer";
import {
  RenewalOfferOption,
  NoActiveRenewalOffer,
} from "shared/components/Renewals/RenewalOfferOption";
import Spacer from "shared/components/Spacer";
import { SmallText } from "shared/components/Typography";
import { formatDate } from "shared/utils/ui";

import { DeleteOptionFn } from "../interfaces";

import useRenewalHelpers from "../useRenewalHelpers";

import { RenewalDetailsContainer } from "./styled";

export interface RenewalListItemDetailsProps {
  renewal: RenewalBasic;
  deleteOption: DeleteOptionFn;
}

export const RenewalListItemDetails = ({
  renewal,
  deleteOption,
}: RenewalListItemDetailsProps) => {
  const hasOfferOptions = renewal.offer?.renewalOptions?.length > 0;
  const { offerHelpers } = useRenewalHelpers({
    renewal,
  });

  const sentAt = renewal?.offer?.sentAt;
  return (
    <RenewalDetailsContainer>
      <FlexContainer alignItems="center">
        <SmallText extraStrong>OFFER OPTIONS</SmallText>
        {sentAt && (
          <SmallText light className="sent-at">
            Sent on {formatDate(sentAt)}
          </SmallText>
        )}
      </FlexContainer>
      <Spacer />
      {hasOfferOptions ? (
        (renewal.offer?.renewalOptions || []).map((option) => (
          <RenewalOfferOption
            key={option.id}
            option={option}
            deleteOption={(o) => deleteOption(renewal, o)}
            grayOutRejectedOptions={offerHelpers.optionsDisabled}
            canDeleteOption={offerHelpers.canDeleteOfferOption}
            showDelete={offerHelpers.isDeleteOptionEnabled}
          />
        ))
      ) : (
        <NoActiveRenewalOffer />
      )}
    </RenewalDetailsContainer>
  );
};
