import React from "react";

import { Col, Row } from "antd";

import { LeaseGroupAttachment } from "manager/interfaces/api/leaseGroups";
import DetectableOverflowTooltip from "shared/components/DetectableOverflowTooltip";
import FileSelect from "shared/components/FileSelect";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ActionLink, IconLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import { RegularText, SmallText } from "shared/components/Typography";
import Well from "shared/components/Well";
import { formatDate } from "shared/utils/ui";

import { StyledGroupAttachments, StyledPrimaryHeader } from "../styled";

const LeaseGroupAttachments = ({
  attachments,
  onFileSelect,
  onDeleteAttachment,
  onEditAttachment,
  onFilePreview,
}: {
  attachments?: LeaseGroupAttachment[];
  onFileSelect: (files: any) => void;
  onDeleteAttachment: (attachment: LeaseGroupAttachment) => void;
  onEditAttachment: (attachment: LeaseGroupAttachment) => void;
  onFilePreview: (attachment: LeaseGroupAttachment) => void;
}) => {
  return (
    <StyledGroupAttachments>
      <StyledPrimaryHeader>
        <RegularText strong className="text-white">
          Documents
        </RegularText>
      </StyledPrimaryHeader>
      <Spacer size={Spacer.SIZES.sm} />
      <div>
        {attachments?.map((attachment) => (
          <Row key={attachment.id} gutter={[12, 12]}>
            <Col>
              <Well noBorder white>
                <Row gutter={[12, 24]}>
                  <Col md={2} lg={3} xl={2} xxl={2}>
                    <Icon.PDFPreviewIconGray width="28px" height="32px" />
                  </Col>
                  <Col md={12} lg={14} xl={8} xxl={12}>
                    <DetectableOverflowTooltip title={attachment.filename}>
                      <RegularText>{attachment.filename}</RegularText>
                    </DetectableOverflowTooltip>
                  </Col>
                  <Col md={4} lg={7} xl={8} xxl={6}>
                    <SmallText weak>
                      {formatDate(attachment.createdAt)}
                    </SmallText>
                  </Col>
                  <Col md={6} lg={24} xl={6} xxl={4}>
                    <FlexContainer
                      alignItems="center"
                      justifyContent="flex-end"
                      nodesMargin="0 0 0 8px"
                    >
                      <ActionLink onClick={() => onFilePreview(attachment)}>
                        View
                      </ActionLink>
                      <IconLink
                        Icon={Icon.EditPencilIcon}
                        onClick={() => onEditAttachment(attachment)}
                      />
                      <IconLink
                        Icon={Icon.DeleteSmallIcon}
                        onClick={() => onDeleteAttachment(attachment)}
                      />
                    </FlexContainer>
                  </Col>
                </Row>
              </Well>
            </Col>
          </Row>
        ))}
      </div>
      <Spacer size={Spacer.SIZES.sm} />
      <FileSelect
        Node={ActionLink}
        innerProps={{ children: "Upload", className: "upload-link" }}
        onFileSelect={onFileSelect}
        acceptFormats=".pdf"
      />
    </StyledGroupAttachments>
  );
};

export default LeaseGroupAttachments;
