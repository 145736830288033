import styled from "@emotion/styled";

import BaseCheckList from "shared/components/CheckList";
import { MEDIA_QUERIES } from "shared/config/constants";

export const Subtitle = styled.span`
  display: flex;
  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: column;
  }
`;

export const CheckList = styled(BaseCheckList)`
  ${MEDIA_QUERIES.w.gteMd} {
    margin-left: 8px;
  }
`;
