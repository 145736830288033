import React from "react";

import { Modal } from "shared/components/Modal";

interface RefreshDocumentsModalProps {
  onConfirm: () => void;
}

const RefreshDocumentsModal = ({ onConfirm }: RefreshDocumentsModalProps) => (
  <Modal
    title="Refresh leasing documents"
    submit={onConfirm}
    submitButtonLabel="Confirm"
  >
    <Modal.Body>
      If lease documents were changed at the property level, refreshing can
      impact your current lease package. You should only refresh if documents
      were added or removed at the property level and you need to access them
      here. Are you sure you would like to continue?
    </Modal.Body>
  </Modal>
);

export default RefreshDocumentsModal;
