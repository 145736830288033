import React from "react";

import Icon from "shared/components/Icon";
import {
  LEASE_STATUS_CODES,
  LEASE_STATUS_LABEL,
} from "shared/config/constants";

import DealPropTypes from "../../Deals.propTypes";
import { ProgressBar, PrimaryText, FlexWrapper } from "../styled";

const LeaseStatus = ({ deal, leaseStatuses }) => {
  const numSignedApplications = deal.applicantsSummary.filter(
    (applicant) => applicant.leaseSigned
  ).length;

  const numTotalApplications = deal.applicantsSummary.length;
  const isDealCompleted = deal.progressPercent === 100;

  const getLeaseStatusText = (code) =>
    LEASE_STATUS_LABEL[deal?.leaseStatus] ||
    leaseStatuses.find(({ id }) => id === code)?.value ||
    "Unknown";

  let tag;

  switch (deal.leaseStatus) {
    case LEASE_STATUS_CODES.partiallySigned:
      tag = (
        <PrimaryText>
          {LEASE_STATUS_LABEL[deal.leaseStatus](
            numSignedApplications,
            numTotalApplications
          )}
        </PrimaryText>
      );
      break;
    case LEASE_STATUS_CODES.inProgress:
      tag = (
        <FlexWrapper>
          <Icon.LeaseInProgressIcon data-testid="lease-progress-icon" />
          <PrimaryText>{LEASE_STATUS_LABEL[deal.leaseStatus]}</PrimaryText>
        </FlexWrapper>
      );
      break;

    case LEASE_STATUS_CODES.sent:
    case LEASE_STATUS_CODES.applicantsSigned:
    case LEASE_STATUS_CODES.processingManagerSignature:
      tag = <PrimaryText>{LEASE_STATUS_LABEL[deal.leaseStatus]}</PrimaryText>;
      break;

    case LEASE_STATUS_CODES.managerSigned:
    case LEASE_STATUS_CODES.leaseHandledOffline:
      tag = (
        <FlexWrapper>
          <Icon.DealApprovedIcon data-testid="approved-icon" />
          <PrimaryText>{LEASE_STATUS_LABEL[deal.leaseStatus]}</PrimaryText>
        </FlexWrapper>
      );
      break;

    default:
      tag = <PrimaryText>{getLeaseStatusText(deal.leaseStatus)}</PrimaryText>;
  }
  return (
    <div data-testid="lease-status">
      {tag}
      {!isDealCompleted && <ProgressBar progress={deal.progressPercent} />}
    </div>
  );
};

LeaseStatus.propTypes = {
  deal: DealPropTypes.deal.isRequired,
  leaseStatuses: DealPropTypes.leaseStatuses,
};

LeaseStatus.defaultProps = {
  leaseStatuses: [],
};

export default LeaseStatus;
