
import { Lease } from "manager/interfaces/api/leaseGroups";
import LeaseGroupTimelineDetails from "manager/pages/LeaseGroup/LeaseGroupTimelineDetails";
import Container from "shared/components/Container";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { LEASE_STATUS_CODES } from "shared/config/constants";
import { formatDate } from "shared/utils/ui";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import {
  Action,
  DataRow,
  DisplayLeaseButton,
  GreenText,
  SectionWrapper,
  SpinnerIcon,
  StatusContainer,
  Title,
} from "./styled";

interface LeaseInfoProps {
  viewLease: () => void;
  lease: Lease;
  isLeaseInProgress: boolean;
}

const LeaseInfo = ({ viewLease, lease, isLeaseInProgress }: LeaseInfoProps) => (
  <Container noPadding data-testid="lease-info">
    <Title style={{ margin: 0, lineHeight: "normal" }}>Lease Info</Title>
    <Spacer />
    <LeaseGroupTimelineDetails lease={lease} />
    <SectionWrapper>
      <DataRow>
        <Tile.Label data-testid="lease-start-date">Start Date:</Tile.Label>
        {formatDate(lease?.startDate)}
      </DataRow>
      <DataRow>
        <Tile.Label data-testid="lease-end-date">End Date:</Tile.Label>
        {formatDate(lease?.endDate)}
      </DataRow>
    </SectionWrapper>
    <SectionWrapper>
      <DataRow>
        <Tile.Label data-testid="lease-rent">Monthly Rent:</Tile.Label>
        {printDollarsFromCents(lease?.rent)}
      </DataRow>
      <DataRow>
        <Tile.Label data-testid="lease-deposit">Security Deposit:</Tile.Label>
        {printDollarsFromCents(lease?.securityDeposit)}
      </DataRow>
    </SectionWrapper>
    {!isLeaseInProgress && (
      <Action>
        {lease?.status !== LEASE_STATUS_CODES.processingManagerSignature ? (
          <DisplayLeaseButton onClick={viewLease}>
            View Lease
          </DisplayLeaseButton>
        ) : (
          <StatusContainer>
            <GreenText>Processing Signatures</GreenText>
            <SpinnerIcon />
          </StatusContainer>
        )}
      </Action>
    )}
  </Container>
);

export default LeaseInfo;
