import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getLeasePackagesManagerQuestions: (propertyId) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getLeasePackagesManagerQuestions, {
          id: propertyId,
        })}`
      )
      .then(({ data }) => data),

  getLeasePackagesApplicantQuestions: (propertyId) =>
    axios
      .patch(
        `${API_URL}${transformRoute(ROUTES.getLeasePackagesApplicantQuestions, {
          id: propertyId,
        })}`
      )
      .then(({ data }) => data),

  upsertApplicantQuestion: ({ id, leaseQuestions }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.upsertApplicantQuestion, { id })}`,
        leaseQuestions
      )
      .then(({ data }) => data),
  upsertManagerQuestion: ({ id, leaseQuestions }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.upsertManagerQuestion, { id })}`,
        leaseQuestions
      )
      .then(({ data }) => data),

  deleteLeaseQuestion: (id) =>
    axios.delete(
      `${API_URL}${transformRoute(ROUTES.deleteManagerQuestion, { id })}`
    ),
};

export default api;
