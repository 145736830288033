import React from "react";

import { useFormikContext } from "formik";

import { Lease } from "manager/interfaces/api/lease";
import { CalculateLeaseTotalRentModel } from "manager/interfaces/api/leaseFeeConfiguration";
import FlexContainer from "shared/components/FlexContainer";
import { SwitchTitle, VeroFormField } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { IconLink } from "shared/components/Links";
import Tooltip from "shared/components/Tooltip";
import { SmallText } from "shared/components/Typography";
import { formatDate } from "shared/utils/ui";

import { LeaseFieldsStepValues } from "../../../interfaces";

import LeaseFinalSumSection from "./LeaseFinalSumSection";
import { StyledProratedFinalSum } from "./styled";
import { getFinalProratedFeeItems } from "./utils";

interface LeaseFinalProratedSumSectionProps {
  leaseCalculations?: CalculateLeaseTotalRentModel;
  lease: Lease;
  openRentableItemsProrationModal: () => void;
}

const PRORATION_DISABLED_MESSAGE =
  'The feature is disabled, no "total_first_month_rent" field in the lease package.';
const PRORATION_INFO_MESSAGE =
  "Add one or more rentable items to prorate the amount.";

const LeaseFinalProratedSumSection = ({
  lease,
  leaseCalculations,
  openRentableItemsProrationModal,
}: LeaseFinalProratedSumSectionProps) => {
  const { values } = useFormikContext<LeaseFieldsStepValues>();
  const isProrationDisabled = !lease?.useProratedFields;

  const prorationTooltip = isProrationDisabled
    ? PRORATION_DISABLED_MESSAGE
    : PRORATION_INFO_MESSAGE;

  const finalProratedFeeItems = getFinalProratedFeeItems(leaseCalculations);

  return (
    <div className="summary-footer">
      <FlexContainer nodesMargin="0 16px 0 0">
        <Tooltip title={prorationTooltip}>
          <div>
            {/* @ts-ignore */}
            <VeroFormField
              as={SwitchTitle}
              id="feeConfiguration.prorateRentableItems"
              name="feeConfiguration.prorateRentableItems"
              size="small"
              title="PRORATED RENTABLE ITEMS"
              noPadding
              disabled={isProrationDisabled}
            />
          </div>
        </Tooltip>
        <IconLink
          Icon={Icon.QuestionBadge}
          onClick={openRentableItemsProrationModal}
        />
      </FlexContainer>
      {values.feeConfiguration.prorateRentableItems && (
        <StyledProratedFinalSum
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <div className="prorate-lease-info">
            <SmallText light>Lease Start Date: &nbsp;</SmallText>
            <SmallText light strong>
              {formatDate(lease.startDate)}
            </SmallText>
            <br />
            <SmallText light>Days Charged in First Month: &nbsp;</SmallText>
            <SmallText light strong>
              {lease.daysChargedInFirstMonth}
            </SmallText>
          </div>
          <LeaseFinalSumSection feeItems={finalProratedFeeItems} />
        </StyledProratedFinalSum>
      )}
    </div>
  );
};

export default LeaseFinalProratedSumSection;
