import React from "react";

import { RegularText } from "shared/components/Typography";

import { StatusCircleLabelContent } from "./styled";

export const StatusCircleLabel = ({ statusColor, label }) => {
  return (
    <StatusCircleLabelContent statusColor={statusColor}>
      <div className="status-circle" />
      <RegularText>{label}</RegularText>
    </StatusCircleLabelContent>
  );
};
