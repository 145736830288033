import { CONCESSION_TYPES, RentChangeOptions } from "shared/config/constants";
import { ApplicantType } from "shared/interfaces/misc";
import { isSubstrInStr } from "shared/utils/ui";

const COLORS = {
  incomeAndAssets: {
    verified: "#28BF8C",
    notVerified: "#FF8C6A",
  },
  deal: {
    decisionDropdown: {
      approve: "#78E2BF",
      conditionallyApprove: "#FFC2A8",
      conditionallyApproveV2: "#FFAF66",
      decline: "#FEA6A6",
    },
  },
  dealSetupSettingsModal: {
    text: "#2D2943",
  },
};

const MODALS = {
  createLease: "CREATE_LEASE",
  // Property
  assignPropertyToPortfolio: "ASSIGN_PROPERTY_TO_PORTFOLIO_MODAL",
  customizePropertySettings: "CUSTOMIZE_PROPERTY_SETTINGS",
  uploadPropertyLogo: "UPLOAD_PROPERTY_LOGO",
  // Invite applicants
  inviteApplicants: "INVITE_APPLICANTS",
  // DealSetup
  dealSetupQuestions: "DEAL_SETUP_QUESTIONS",
  dealSetupAttachments: "DEAL_SETUP_ATTACHMENTS",
  // Lease
  resendLease: "RESEND_LEASE",
  switchLeaseESign: "SWITCH_LEASE_E_SIGN",
  confirmLeaseExecution: "CONFIRM_LEASE_EXECUTION",
  editDocumentRiderType: "EDIT_RIDER_TYPE",
  // Integrations
  bluemoonConnectLicense: "BLUEMOON_CONNECT_LICENSE",
};

const ATTACHMENT_TYPES = {
  APPLICATION: "AP",
  UNVERIFIED_INCOME: "UI",
  UNVERIFIED_BACKGROUND: "UB",
};

const PROPERTY_FILTERS = {
  name: (name, searchingValue) => isSubstrInStr(name, searchingValue),
  properties: (properties, searchingValue) =>
    properties.some((property) =>
      isSubstrInStr(
        `${property.name} ${property.address} ${property.city} ${property.state} ${property.zipcode}`,
        searchingValue
      )
    ),
};

const ACTIVE_DOCUMENTS_STEPS = [
  {
    label: "Document is assigned to the property",
  },
  {
    label: " Fields are setup at the property level",
  },
  {
    label: "Document is activated",
  },
];

const FIELDS_STATUS = {
  AS: "all_set",
  PS: "partially_set",
  SF: "setup_fields",
  NS: "no_setup_needed",
};

export {
  COLORS,
  MODALS,
  ATTACHMENT_TYPES,
  PROPERTY_FILTERS,
  ACTIVE_DOCUMENTS_STEPS,
  FIELDS_STATUS,
};

export const LEASE_METADATA = {
  lq: "LeaseMetadata",
  pq: "PropertyMetadata",
  aq: "LeaseMetadata",
};

export const UNIT_STATUSES = {
  NL: {
    key: "NL",
    label: "No listing",
  },
  AL: {
    key: "AL",
    label: "Active Listing",
  },
  OH: {
    key: "OH",
    label: "On Hold",
  },
  PL: {
    key: "PL",
    label: "Pending Lease",
  },
  RE: {
    key: "RE",
    label: "Rented",
  },
  NA: {
    key: "NA",
    label: "N/A",
  },
};

export const DOWNLOAD_STATUSES = {
  inProgress: "In Progress",
  completed: "Completed",
  failed: "Failed",
};

export const LEADS_CATEGORIES = [
  {
    key: "",
    apiKey: "",
    name: "All",
    count: 0,
  },
  { key: "prospect", apiKey: "prospect", name: "Prospect", count: 0 },
  { key: "guest", apiKey: "guest", name: "Guest", count: 0 },
];

export const LEADS_CATEGORY_TYPES = {
  ALL: "all",
  PROSPECT: "prospect",
  GUEST: "guest",
};

export const LEADS_CATEGORY_LABEL_INFO = {
  [LEADS_CATEGORY_TYPES.PROSPECT]: "Prospect Leads",
  [LEADS_CATEGORY_TYPES.GUEST]: "Guest Leads",
};

export const DEAL_CATEGORY_TYPES = {
  ALL: "all",
  APPROVED: "approved",
  PENDING: "pending",
  INCOMPLETE: "incomplete",
  REJECTED: "rejected",
};

export const DEAL_CATEGORY_LABEL_INFO = {
  [DEAL_CATEGORY_TYPES.APPROVED]: "Approved Applications",
  [DEAL_CATEGORY_TYPES.PENDING]: "Applications Awaiting Decision",
  [DEAL_CATEGORY_TYPES.INCOMPLETE]: "Applications in Progress",
  [DEAL_CATEGORY_TYPES.REJECTED]: "Rejected Applications",
};

export const DEAL_CATEGORIES = [
  {
    key: DEAL_CATEGORY_TYPES.ALL,
    apiKey: "",
    name: "All",
  },
  {
    key: DEAL_CATEGORY_TYPES.PENDING,
    apiKey: "pending",
    name: "Pending",
  },
  {
    key: DEAL_CATEGORY_TYPES.INCOMPLETE,
    apiKey: "incomplete",
    name: "Incomplete",
  },
  {
    key: DEAL_CATEGORY_TYPES.APPROVED,
    apiKey: "approved",
    name: "Approved",
  },
  {
    key: DEAL_CATEGORY_TYPES.REJECTED,
    apiKey: "rejected",
    name: "Rejected",
  },
];

const DEAL_CATEGORY_FILTERS = {
  APPLICATION_APPROVED: "APPLICATION APPROVED",
  APPLICATIONS_INCOMPLETE: "APPLICATIONS INCOMPLETE",
  APPLICATIONS_PARTIALLY_SUBMITTED: "APPLICATIONS PARTIALLY SUBMITTED",
  INVITES_SENT: "INVITES SENT",
  APPLICATION_PENDING: "APPLICATION PENDING",
  REQUESTED_MORE_INFO: "REQUESTED MORE INFO",
  APPLICATION_CANCELLED: "APPLICATION CANCELLED",
  DECLINED: "DECLINED",
  SENT_TO_ENTRATA: "SENT TO ENTRATA",
};

export const DEAL_CATEGORY_FILTERS_MAPPING = {
  [DEAL_CATEGORY_FILTERS.APPLICATION_APPROVED]: "Application Approved",
  [DEAL_CATEGORY_FILTERS.APPLICATIONS_INCOMPLETE]: "Applications in Progress",
  [DEAL_CATEGORY_FILTERS.APPLICATIONS_PARTIALLY_SUBMITTED]:
    "Applications Partially Submitted",
  [DEAL_CATEGORY_FILTERS.INVITES_SENT]: "Invites Sent",
  [DEAL_CATEGORY_FILTERS.APPLICATION_PENDING]: "Application Awaiting Decision",
  [DEAL_CATEGORY_FILTERS.REQUESTED_MORE_INFO]: "Requested More Info",
  [DEAL_CATEGORY_FILTERS.APPLICATION_CANCELLED]: "Application Cancelled",
  [DEAL_CATEGORY_FILTERS.DECLINED]: "Declined",
  [DEAL_CATEGORY_FILTERS.SENT_TO_ENTRATA]: "Sent to Entrata",
};

export const DEAL_OVERVIEW_VERSION = {
  V1: "V1",
  V2: "V2",
};

export const APPLICATION_AUDITS_QUERY = "getApplicationAudits";

export const LEASE_REMINDER_MAX_SENDS = {
  min: 3,
  max: 10,
};

export enum RenewalStatus {
  OFFER_NOT_CREATED = "OFFER_NOT_CREATED",
  OFFER_CREATION_IN_PROGRESS = "OFFER_CREATION_IN_PROGRESS",
  OFFER_CREATED = "OFFER_CREATED",
  OFFER_APPROVED = "OFFER_APPROVED",
  OFFER_SENT = "OFFER_SENT",
  OFFER_PARTIALLY_ACCEPTED = "OFFER_PARTIALLY_ACCEPTED",
  OFFER_PARTIALLY_REJECTED = "OFFER_PARTIALLY_REJECTED",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  OFFER_REJECTED = "OFFER_REJECTED",
  RENEWAL_CREATION_IN_PROGRESS = "RENEWAL_CREATION_IN_PROGRESS",
  RENEWAL_COMPLETED = "RENEWAL_COMPLETED",
  RENEWAL_NOT_COMPLETED = "RENEWAL_NOT_COMPLETED",
}

export enum RenewalApplicationOfferStatus {
  OFFER_NOT_SENT = "OFFER_NOT_SENT",
  PENDING_OFFER_RESPONSE = "PENDING_OFFER_RESPONSE",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  OFFER_REJECTED = "OFFER_REJECTED",
}

export enum RenewalApplicationStatus {
  RENEWAL_APPLICATION = "RENEWAL_APPLICATION",
  NOT_RENEWING = "NOT_RENEWING",
  REMOVED = "REMOVED",
}

export enum RenewalCategory {
  ALL = "ALL",
  UPCOMING = "UPCOMING",
  OFFER_APPROVALS = "OFFER_APPROVALS",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}

export const PERCENT_RENT_CHANGE = new Set([
  RentChangeOptions.PERCENT_INCREASE,
  RentChangeOptions.PERCENT_DECREASE,
]);

export const AMOUNT_RENT_CHANGE = new Set([
  RentChangeOptions.AMOUNT_INCREASE,
  RentChangeOptions.AMOUNT_DECREASE,
]);

export enum RenewalEscalationLevel {
  GREEN = "green",
  YELLOW = "yellow",
  RED = "red",
}

export const CONCESSION_TYPES_OPTIONS = [
  {
    label: "Amortize concession",
    value: CONCESSION_TYPES.amortize,
  },
  {
    label: "Select specific months for concession",
    value: CONCESSION_TYPES.specificMonths,
  },
];

export const APPLICANT_TYPES_LABEL = {
  [ApplicantType.APPLICANT]: "Applicant",
  [ApplicantType.GUARANTOR]: "Guarantor",
  [ApplicantType.OCCUPANT]: "Occupant",
};

export const CREATE_LEASE_ERROR_CODES = Object.freeze({
  DEAL_ALREADY_HAS_LEASE: "DEAL_ALREADY_HAS_LEASE",
  NO_UNIT_LISTING: "NO_UNIT_LISTING",
  DEAL_NOT_APPROVED: "DEAL_NOT_APPROVED",
  YARDI_WAIT_UNIT: "YARDI_WAIT_UNIT",
  NO_ASSIGNED_DOCUMENTS: "NO_ASSIGNED_DOCUMENTS",
  NO_ACTIVE_DOCUMENTS: "NO_ACTIVE_DOCUMENTS",
  HOLDING_DEPOSIT_NOT_PAID: "HOLDING_DEPOSIT_NOT_PAID",
  PRIMARY_APPLICANT_NOT_PAID_ALL_PAYMENTS:
    "PRIMARY_APPLICANT_NOT_PAID_ALL_PAYMENTS",
});

export const RENEWAL_NOTE_MAX_LENGTH = 500;

export const ACTION_NOT_PERMITTED_MESSAGE =
  "You are not authorized to perform this action";
