import React, { useEffect, useMemo } from "react";

import { string } from "yup";

import {
  useCreateLeaseGroupAttachment,
  useDeleteLeaseGroupAttachment,
  useEditLeaseGroupAttachment,
  useGetLeaseGroupAttachments,
} from "manager/hooks/api";
import {
  LeaseGroup,
  LeaseGroupAttachment,
} from "manager/interfaces/api/leaseGroups";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import PreviewPDFModal from "shared/components/Modals/PreviewPDFModal";
import SingleInputModal from "shared/components/Modals/SingleInputModal";
import { MAX_FILE_UPLOAD_SIZE } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { useUploadValidation } from "shared/hooks/useUploadValidation";
import { sanitizeFileName } from "shared/utils/pdf";

import { showLoaderFn } from "../utils";

import LeaseGroupAttachments from "./LeaseGroupAttachments";

const LeaseGroupAttachmentsContainer = ({
  leaseGroup,
  showLoader,
}: {
  leaseGroup: LeaseGroup;
  showLoader: showLoaderFn;
}) => {
  const { openModalDialog, closeActiveModalDialog } = useModal();
  const { leaseGroupAttachments, isLeaseGroupAttachmentsLoading } =
    useGetLeaseGroupAttachments(leaseGroup?.id);
  const { createLeaseGroupAttachment, isCreatingLeaseGroupAttachments } =
    useCreateLeaseGroupAttachment();
  const { deleteLeaseGroupAttachment, isDeletingLeaseGroupAttachment } =
    useDeleteLeaseGroupAttachment();
  const { editLeaseGroupAttachment } = useEditLeaseGroupAttachment();
  const { isFileNameValid, isFileSizeValid } = useUploadValidation();

  const onFileSelect = async (files: any) => {
    const [file] = files;
    const { name, size } = file || {};

    if (
      isFileNameValid(name, true) &&
      isFileSizeValid(MAX_FILE_UPLOAD_SIZE, size, name)
    ) {
      await createLeaseGroupAttachment({
        filename: name,
        upload: file,
        leaseGroupId: leaseGroup?.id,
      });
    }
  };

  const onDeleteAttachment = (attachment: LeaseGroupAttachment) =>
    openModalDialog(ConfirmationModal, {
      title: "Delete Attachment",
      subtitle: attachment.filename,
      message: "Are you sure you want to delete this attachment?",
      onConfirm: () =>
        deleteLeaseGroupAttachment({
          leaseGroupId: leaseGroup?.id,
          id: attachment.id,
        }),
    });

  const onEditAttachment = (attachment: LeaseGroupAttachment) => {
    openModalDialog(SingleInputModal, {
      title: "Edit Attachment",
      initialValue: attachment.filename,
      valueValidationSchema: string().trim().required("This field is required"),
      inputPlaceholder: "Filename",
      submit: ({ value }) =>
        editLeaseGroupAttachment(
          {
            filename: sanitizeFileName({ fileName: value }),
            id: attachment.id,
            leaseGroupId: attachment.leaseGroupId,
          },
          { onSuccess: closeActiveModalDialog }
        ),
    });
  };

  const onFilePreview = (attachment: LeaseGroupAttachment) =>
    openModalDialog(PreviewPDFModal, {
      fileUrl: attachment.upload,
      title: attachment.filename,
      submitButtonLabel: "Close",
      closeOnSubmit: true,
    });

  const isAttachmentsLoading = useMemo(
    () =>
      isLeaseGroupAttachmentsLoading ||
      isCreatingLeaseGroupAttachments ||
      isDeletingLeaseGroupAttachment,
    [
      isLeaseGroupAttachmentsLoading,
      isCreatingLeaseGroupAttachments,
      isDeletingLeaseGroupAttachment,
    ]
  );

  useEffect(() => {
    showLoader({
      isAttachmentsLoading,
    });
  }, [isAttachmentsLoading]);

  return (
    <LeaseGroupAttachments
      attachments={leaseGroupAttachments}
      onFileSelect={onFileSelect}
      onDeleteAttachment={onDeleteAttachment}
      onEditAttachment={onEditAttachment}
      onFilePreview={onFilePreview}
    />
  );
};

export default LeaseGroupAttachmentsContainer;
