import LeaseDocumentsStep from "./LeaseDocumentsStep";
import LeaseFieldsStep from "./LeaseFieldsStep";
import LeaseInfoStep from "./LeaseInfoStep";
import LeasePreviewStep from "./LeasePreviewStep";

export const STEPS = {
  leaseInfo: {
    label: "Enter Lease Terms",
    component: LeaseInfoStep,
  },
  leaseDocuments: {
    label: "Select Lease Documents",
    component: LeaseDocumentsStep,
  },
  fillFields: {
    label: "Fill the Fields",
    component: LeaseFieldsStep,
  },
  previewLease: {
    label: "Preview & Send Lease",
    component: LeasePreviewStep,
  },
};

export const STEPS_ORDER = [
  STEPS.leaseInfo,
  STEPS.leaseDocuments,
  STEPS.fillFields,
  STEPS.previewLease,
];

export const getStepByIndex = (index) => STEPS_ORDER[index - 1];

export const APPLICATION_BAR_STEPS = [1, 2, 3];

export const APPLICATION_BAR_TITLE = {
  1: "Check the answers given during the application process",
  2: "Answers given during the application process will impact which documents should be sent",
  3: "Answers given during the application process will inform you how to configure the documents",
};
