import styled from "@emotion/styled";

import Container from "shared/components/Container";

export const RenewalDetailsContainer = styled(Container)`
  padding: 40px 65px;

  .sent-at {
    margin-left: 16px;
  }
`;
