import React from "react";

import { Modal } from "shared/components/Modal";
import { RenewalOfferOption } from "shared/components/Renewals/RenewalOfferOption";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";
import { RenewalOption } from "shared/interfaces/api/renewals";

import { LeaseRenewalConfirmationModalContent } from "./styled";

const COL_SIZE_PROP = Object.freeze({
  xs: { span: 24 },
  sm: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 },
  xxl: { span: 6 },
});

interface LeaseRenewalConfirmationModalProps {
  option: RenewalOption;
  onConfirm: () => Promise<any>;
  submitting: boolean;
}

const LeaseRenewalConfirmationModal = ({
  option,
  onConfirm,
  submitting,
}: LeaseRenewalConfirmationModalProps) => {
  return (
    <Modal
      title="Renew Lease"
      submitButtonLabel="CONFIRM"
      cancelLinkLabel="Cancel"
      submit={onConfirm}
      centered
      showSubmitButton
      submitting={submitting}
      width="auto"
      style={{ maxWidth: 648 }}
    >
      <Modal.Body>
        <LeaseRenewalConfirmationModalContent>
          <RegularText weak>
            Please confirm that you would like to accept the below offer. Once
            confirmed, the leasing agent will be notified to continue the
            renewal process.
          </RegularText>
          <Spacer size={Spacer.SIZES.md} />
          <RenewalOfferOption
            className="offer-option-data-row"
            option={option}
            hideActionsCol
            colSizes={COL_SIZE_PROP}
          />
        </LeaseRenewalConfirmationModalContent>
      </Modal.Body>
    </Modal>
  );
};

export default LeaseRenewalConfirmationModal;
