import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import Icon from "shared/components/Icon";

import {
  SaveLeaseProcessContainer,
  SaveLeaseProcessIcon,
  SaveLeaseProcessText,
} from "../../styled";

import { FooterBar as StyledFooterBar } from "./styled";

const LeaseInfoFooter = ({ onFinishLater }) => {
  const { handleSubmit, isSubmitting } = useFormikContext();

  return (
    <Container noPaddingBottom noPaddingTop stickToBottom>
      <StyledFooterBar data-testid="lease-info-footer">
        <SaveLeaseProcessContainer
          onClick={onFinishLater}
          disabled={isSubmitting}
          data-testid="finish-later"
        >
          <SaveLeaseProcessIcon />
          <SaveLeaseProcessText> Save & Finish Later</SaveLeaseProcessText>
        </SaveLeaseProcessContainer>

        <Button
          type="primary"
          SuffixIcon={Icon.ButtonForwardArrowIcon}
          onClick={handleSubmit}
          loading={isSubmitting}
          data-testid="next-step"
        >
          Next Step
        </Button>
      </StyledFooterBar>
    </Container>
  );
};

LeaseInfoFooter.propTypes = {
  onFinishLater: PropTypes.func.isRequired,
};

export default LeaseInfoFooter;
