import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import ProgressBar from "shared/components/ProgressBar";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { RenewalHeaderBase } from "shared/images";

export const MobileFiltersContainer = styled(FlexContainer)`
  flex-direction: column;

  .mobile-filters-container {
    width: 100%;
  }
`;

export const Header = styled(FlexContainer)`
  position: relative;
  padding: 22px;
  margin-bottom: 10px;
  border-radius: 2px;
  background-image: url(${RenewalHeaderBase});
  background-repeat: repeat;

  ${MEDIA_QUERIES.w.ltSm} {
    margin-top: 16px;
    padding: 0;
  }

  .title {
    color: ${COLORS.white} !important;
    margin-right: 75px;
    margin-bottom: 0 !important;

    ${MEDIA_QUERIES.w.ltSm} {
      display: none;
    }
  }
`;

export const Cell = styled(FlexContainer)`
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${MEDIA_QUERIES.w.ltSm} {
    min-height: auto;
  }

  .cell-extra-info {
    margin-top: 8px;
  }

  .more-renters {
    margin-left: 15px;
  }

  .final-status-icon {
    margin-right: 16px;
    height: 24px;
    width: 24px;
  }
`;

export const StyledProgressBar = styled(ProgressBar)`
  width: 160px;
  margin-top: 12px;
  height: 4px;
`;

export const TableContainer = styled.div`
  .dropdown-cell {
    padding-right: 0;
  }

  .expand-button-cell {
    padding-left: 10px;
  }

  .mobile-expanded-row {
    width: 100%;
  }
`;
