import styled from "@emotion/styled";

import Container from "shared/components/Container";
import Icon from "shared/components/Icon";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const LoadingSpinner = styled(Icon.LoadingSpinnerGreenLargeIcon)`
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%);
`;

const LayoutContainer = styled.div`
  height: 100%;
`;

const Header = styled(Container)`
  display: block;
`;

const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Subtitle = styled.p`
  color: ${COLORS.textColorPrimary};
  font-size: 15px;
  margin: 0;
  margin-left: 10;
`;

const SignBackground = styled.div`
  height: 100%;
  padding: 0 25px;
  ${MEDIA_QUERIES.w.ltMd} {
    padding: 0;
  }
`;

const SignContainer = styled.div`
  height: 110%;
  padding: 25px 0;
  ${MEDIA_QUERIES.w.ltMd} {
    padding: 0 0 80px;
  }
`;

export {
  LoadingSpinner,
  LayoutContainer,
  Header,
  SubtitleContainer,
  Subtitle,
  SignBackground,
  SignContainer,
};
