import React, { useState } from "react";

import { Document } from "admin/pages/Documents/interfaces";
import { useUpdateRiderType } from "manager/hooks/api";
import { useGetRiderTypes } from "shared/hooks/api";

import EditDocumentRiderTypeModal from "./EditDocumentRiderTypeModal";

interface EditDocumentRiderTypeModalContainerProps {
  document: Document;
}

const EditDocumentRiderTypeModalContainer = ({
  document,
}: EditDocumentRiderTypeModalContainerProps) => {
  const [currentRiderType, setRiderType] = useState();
  const { updateRiderType, isLoading: isLoading } = useUpdateRiderType();
  const { riderTypes, isRiderTypesLoading } = useGetRiderTypes();

  const onConfirm = () =>
    updateRiderType(
      // @ts-ignore
      {
        id: document.id,
        riderType: currentRiderType,
      }
    );

  const onRiderTypeChange = (event) => setRiderType(event.target.value);

  return (
    <EditDocumentRiderTypeModal
      documentName={document.name}
      documentRiderTypeName={document.riderTypeName}
      riderTypes={riderTypes}
      isRiderTypesLoading={isRiderTypesLoading}
      currentRiderType={currentRiderType}
      onRiderTypeChange={onRiderTypeChange}
      onConfirm={onConfirm}
      isConfirming={isLoading}
    />
  );
};

export default EditDocumentRiderTypeModalContainer;
