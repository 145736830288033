import React, { useEffect } from "react";

import { Button } from "shared/components/Button";

import { PDFViewer, usePdfJs } from "shared/components/DocumentViewer";
import { Modal } from "shared/components/Modal";
import { COLORS } from "shared/config/constants";

import { PreviewDocumentModalProps } from "./interfaces";

const PreviewDocumentModal = ({
  loading,
  base64,
  filename,
  bluemoonCountersign,
}: PreviewDocumentModalProps) => {
  const viewerApi = usePdfJs();

  useEffect(() => {
    if (viewerApi.isInitialized && base64) {
      // @ts-ignore
      viewerApi.loadDocument({ base64, filename });
    }
  }, [viewerApi.isInitialized, base64]);

  return (
    <Modal title={filename} showFooter={false} fullScreen>
      <PDFViewer
        viewerApi={viewerApi}
        showZoomControls
        loading={loading}
        outerBackgroundColor={COLORS.leasePreview.background}
        backgroundColor={COLORS.leasePreview.background}
        disableMinHeight
        RightTools={
          bluemoonCountersign ? (
            <Button type="primary" onClick={bluemoonCountersign}>
              Countersign
            </Button>
          ) : null
        }
      />
    </Modal>
  );
};

export default PreviewDocumentModal;
