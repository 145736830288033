/* eslint-disable max-lines */
import dayjs from "dayjs";
import isNil from "lodash/isNil";

import {
  LUMEN_CREDIT_RECORDS_SCOPE,
  LUMEN_CREDIT_SCORE_WITH_GUARANTORS_MODE,
  LUMEN_TOTAL_INCOME_OR_ASSETS_WITH_GUARANTORS_MODE,
  LumenResultScore,
} from "manager/config/lumen.config";
import { Deal } from "manager/hooks/application-summary/mappers";
import {
  LumenCode,
  LumenResponse,
  LumenResponseMessageType,
} from "manager/lib/lumenApi";
import { CardStatus } from "shared/components/ApplicationSummaryCard";
import {
  ApplicationStep,
  COUNTY_CRIMINAL_SEARCH_STATUS_ENUM,
  DEAL_UI_STATUS_CODES,
  DEAL_UI_STATUS_LABEL,
  PERSONA_ID_CLASS_LABELS,
  SKIPPED_BACKGROUND_AND_CREDIT_VERIFICATION_MSG,
} from "shared/config/constants";
import {
  ApplicantShort,
  ApplicantType,
  ArgyleStatus,
  Lumen,
  LumenRevision,
  VerificationType,
} from "shared/graphql";

import { ActionBarProps, LumenActionBarProps, LumenItem } from "./action-bar";
import { AppRowProps } from "./app-row";
import { AppWellProps } from "./app-well";
import { EmploymentStatus } from "./cards";
import { DependentProps } from "./dependents";
import {
  ApplicantSummary,
  ApproveDealModalProps,
  DeclineDealModalProps,
} from "./modals";
import { CountyRecordsProps } from "./request-county-records/types";
import { ApproveStatus, AppStatus, FraudIndicator } from "./types";
import {
  ApplicantIndicatorTab,
  ApplicantIndicatorType,
} from "./use-indicator-tabs";

export const VERIFIED_PCT_WARN = 25;
export const VERIFIED_PCT_SUCCESS = 95;
export const CREDIT_SCORE_WARN = 600;
export const CREDIT_SCORE_SUCCESS = 660;
export const EMPTY_INDICATOR = "-";

export const lumenRecommendationMap: Partial<Record<LumenCode, string>> = {
  [LumenCode.BCC_SKIPPED]:
    "Request applicant to resubmit credit and background check.",
  [LumenCode.IA_SKIPPED]: "Request applicant to submit income verification.",
  [LumenCode.INCOME_SKIPPED]:
    "Request applicant to submit income verification.",
  [LumenCode.ASSETS_SKIPPED]:
    "Request applicant to submit balances verification.",
  [LumenCode.SOME_APPLICANTS_CS_NO_MINIMUM]:
    "Review and confirm the credit report does not meet your requirements.",
  [LumenCode.SOME_GUARANTORS_CS_NO_MINIMUM]:
    "Review guarantors application for errors and proceed as necessary.",
  [LumenCode.CREDIT_RECORDS_SAFETYNET]:
    "Review and confirm the credit report does meet your requirements.",
  [LumenCode.CREDIT_RECORDS_CRUCIAL]:
    "Review and confirm the credit report does meet your requirements.",
  [LumenCode.CREDIT_CHECK_NOT_SUPPORTED]:
    "Request applicant resubmit the credit check.",
  [LumenCode.INCOME_NO_MINIMUM]:
    "Review income totals and confirm the required ratio.",
  [LumenCode.ASSETS_NO_MINIMUM]:
    "Review balances and confirm the required ratio.",
  [LumenCode.CRIMINAL_RECORDS_SAFETYNET]:
    "Review criminal records and confirm they do not meet your requirements.",
  [LumenCode.CRIMINAL_RECORDS_CRUCIAL]:
    "Review criminal records and confirm they do not meet your requirements.",
  [LumenCode.APPLICANTS_COLLECTIONS_EXCEED_MAX]:
    "Review the applicants collection records and confirm they do not meet your requirements.",
  [LumenCode.GUARANTORS_COLLECTIONS_EXCEED_MAX]:
    "Review the guarantors collection records and confirm they do not meet your requirements.",
  [LumenCode.COUNTY_CRIMINAL_REPORT_FAILED]:
    "Review criminal records and confirm they do not meet your requirements.",
  [LumenCode.COUNTY_CRIMINAL_REPORT_PARTIALLY_REQUESTED]:
    "Review criminal records and confirm they do not meet your requirements.",
  [LumenCode.HOUSING_COURT_RECORDS_SAFETYNET]:
    "Review eviction records and confirm they do not meet your requirements.",
  [LumenCode.HOUSING_COURT_RECORDS_CRUCIAL]:
    "Review eviction records and confirm they do not meet your requirements.",
  [LumenCode.NO_COLLECTION_RECORDS]: "No Recommendation Returned",
  [LumenCode.NO_COLLECTION_CRUCIAL_RECORDS]: "No Recommendation Returned",
  [LumenCode.COLLECTION_RECORDS_SAFETYNET]:
    "Review the applicants collection records and confirm they do not meet your requirements.",
  [LumenCode.COLLECTION_RECORDS_CRUCIAL]:
    "Review the applicants collection records and confirm they do not meet your requirements.",
  [LumenCode.COLLECTION_RECORDS_IGNORED]: "No Recommendation Returned",
  [LumenCode.IDENTITY_NOT_VERIFIED]:
    "Request the applicant uploads identity documentation to their application.",
};

export const calculateVerifiedCashPerRent = (
  rent: number,
  balances: number,
  preferredLeaseDurationMonths: number
): number => {
  return !rent || isNil(balances) || !preferredLeaseDurationMonths
    ? null
    : Math.max(0, balances) / (rent * preferredLeaseDurationMonths);
};

export const ApplicationStepLabels: Record<ApplicationStep, string> = {
  [ApplicationStep.acceptInvitation]: "Accept Invitation",
  [ApplicationStep.selectAccountType]: "Select Type",
  [ApplicationStep.confirmGroup]: "Confirm Group",
  [ApplicationStep.identityVerification]: "Snap",
  [ApplicationStep.linkEmployment]: "Lynk",
  [ApplicationStep.renterProfile]: "Renter Profile",
  [ApplicationStep.incomeAsset]: "Income and Balances",
  [ApplicationStep.creditCheck]: "Background & Credit check",
  [ApplicationStep.attachments]: "Attach Documents",
  [ApplicationStep.additionalData]: "Conditional Approval Request",
  [ApplicationStep.payment]: "Payment",
  // Note: The steps below would not normally be displayed to a manager.
  // The values are added to cover all possible cases.
  [ApplicationStep.screening]: "Screening",
  [ApplicationStep.verifications]: "Verifications",
  [ApplicationStep.submitApplication]: "Submit Application",
  [ApplicationStep.selectTypeAndConfirm]: "Select Type",
  [ApplicationStep.initialization]: "Initialization",
  [ApplicationStep.acceptTerms]: "Accept terms",
};

const mapVerificationTypeToString: Record<VerificationType, string> = {
  [VerificationType.DOCUMENT_UPLOADED]: "Document Uploaded",
  [VerificationType.LINKED_EMPLOYMENT]: "Employment",
  [VerificationType.LINK_BANK_ACCOUNT]: "Bank Account",
  [VerificationType.MANUAL_UPLOAD]: "Manual Upload",
  [VerificationType.UNEMPLOYED]: "-",
};

export const mapLumenResultToProps = (result?: Lumen): LumenActionBarProps => {
  if (!result) return null;

  return {
    score: result?.score,
    pending: result?.score === LumenResultScore.STILL_CALCULATING,
    tooltip: null,
    items:
      result?.messages
        ?.filter((m) => m.type === LumenResponseMessageType.lowlight)
        .map<LumenItem>((m) => ({
          title: m.text,
          recommendation: lumenRecommendationMap[m.code] ?? "N/A",
        })) ?? [],
  };
};

const formatRecord = (date?: Date, state?: string) =>
  `${state ? `State ${state}, ` : ""}${
    date ? dayjs(date).format("MMM. D, YYYY") : ""
  }`;

export const mapDealToAppTabsProps = (
  deal: Deal,
  applicationId: string
): ApplicantIndicatorTab => {
  const props: ApplicantIndicatorTab = {
    incomeBalance: ApplicantIndicatorType.None,
    creditBackground: ApplicantIndicatorType.None,
    verifications: ApplicantIndicatorType.None,
  };

  const applicant = deal?.applications?.find(
    (app) => String(app.id) === String(applicationId)
  )?.applicant;

  if (!applicant) return props;

  const _income = applicant?.income;
  const income = {
    skipped: _income?.skipped,
    verifiedPercent: _income?.percentVerified,
    income: _income?.totalIncome,
  };
  const incomeError =
    (income.skipped && !income.verifiedPercent) || !income.income;
  const incomeWarning =
    income.verifiedPercent > VERIFIED_PCT_WARN &&
    income.verifiedPercent <= VERIFIED_PCT_SUCCESS;

  const _balances = applicant?.balances;
  // always show balance card
  const balance = {
    skipped: _balances?.skipped,
    balance: _balances?.currentBalance,
  };
  const balancesError = balance.skipped || !balance.balance;

  props.incomeBalance =
    incomeError || balancesError
      ? ApplicantIndicatorType.Error
      : incomeWarning
      ? ApplicantIndicatorType.Warning
      : ApplicantIndicatorType.None;

  const _credit = applicant?.credit;
  const crediScoreError =
    !_credit?.skipped &&
    _credit?.creditScore &&
    _credit?.creditScore < CREDIT_SCORE_WARN;
  const creditScoreWarning =
    !_credit?.skipped &&
    _credit?.creditScore &&
    _credit?.creditScore > CREDIT_SCORE_WARN &&
    _credit?.creditScore <= CREDIT_SCORE_SUCCESS;

  const _criminal = applicant?.criminal;
  // do not show criminal card if conditional proceed is showing.
  const criminalError =
    Boolean(_criminal?.records) && !deal?.conditionalProceed;
  const _eviction = applicant?.eviction;
  const evictionError = Boolean(_eviction?.records);

  props.creditBackground =
    crediScoreError || criminalError || evictionError
      ? ApplicantIndicatorType.Error
      : creditScoreWarning
      ? ApplicantIndicatorType.Warning
      : ApplicantIndicatorType.None;

  const _employment = applicant?.employment;
  // always show employment card
  const employment = {
    enabled: _employment?.enabled ?? true, // if false, hide the card
    skipped: _employment?.skipped ?? false,
  };
  const employmentWarning = employment.enabled && employment.skipped;

  const _identity = applicant?.identity ?? {};
  const identity = {
    enabled: _identity?.enabled ?? true, // if false, hide the card
    skipped: _identity.verified === undefined || _identity.verified === null,
    verified: _identity.verified,
  };
  const identityError = identity.enabled && !identity.verified;
  const identityWarning =
    identity.enabled && identity.verified && identity.skipped;

  props.verifications = identityError
    ? ApplicantIndicatorType.Error
    : employmentWarning || identityWarning
    ? ApplicantIndicatorType.Warning
    : ApplicantIndicatorType.None;

  return props;
};

export const mapDealToAppRowProps = (
  deal: Deal,
  preferredLeaseDurationMonths?: number,
  lumenRevision?: LumenRevision,
  lumenResult?: LumenResponse,
  applicantsSummaryShort?: ApplicantShort[]
): AppRowProps[] =>
  deal?.applications?.map<AppRowProps>((app) => {
    const fees = deal?.fees;
    const rent = fees?.rent;
    const permission = app.permission ?? {};
    const existGuarantor = deal?.applications?.some(
      (app) => app.applicant.type === ApplicantType.guarantor
    );
    const { messages } =
      lumenResult?.applicants?.find((a) => a.id === Number(app.id)) ?? {};
    const { messages: lumenDealMessages } = lumenResult ?? {};
    const props: AppRowProps = {
      appId: app.id,
      dealId: deal.id,
      yardi: {
        isPrimary: app.isPrimary,
        property: deal.property,
        unit: deal.unit,
      },
      info: {
        applicationId: app.id,
        isApproved: deal.isApproved,
        hideCriminalHistory: app.hideCriminalHistory,
        name: [
          app.applicant?.firstName ?? "Unknown",
          ...(app.applicant?.middleName ? [app.applicant?.middleName] : []),
          ...(app.applicant?.lastName ? [app.applicant?.lastName] : []),
        ].join(" "),
        permission: {
          noMove: permission.noMove,
          noMoveReason: permission.noMoveReason,
          noRemove: permission.noRemove,
          noRemoveReason: permission.noRemoveReason,
          unblock: permission.canUnblock,
        },
        email: app.applicant?.email,
        isPrimary: app.isPrimary,
        phone: app.applicant?.phone,
        type: app.applicant?.type,
        step: ApplicationStepLabels[app.step],
        isSubmitted: app.isSubmitted,
        inviteLink: app.inviteLink,
        hasConditionallyProceedEnabled: app?.hasConditionallyProceedEnabled,
        isConditionallyProceeded: app?.isConditionallyProceeded,
        badges: [
          app.applicant?.isStudent ? "student" : null,
          app.applicant?.isForeigner ? "international" : null,
          app.applicant?.isSelfEmployed ? "self-employed" : null,
        ].filter((e) => !!e),
        isBlocked: app.isBlocked,
        applicantVrn: app.applicantVrn,
        location: app?.location,
        party: app?.party,
      },
      applicantVrn: app.applicantVrn,
      location: app?.location,
      party: app?.party,
      personVrn: app.personVrn,
      snapshot: app?.snapshot,
    };

    const _income = app.applicant?.income;
    // always show income card
    const syncFailed = app.argyleStatus === ArgyleStatus.SYNC_FAILED;
    const ocrProcessing = false;
    const pending = _income?.skipped && !_income?.percentVerified;
    props.income = {
      status:
        pending || isNil(_income?.totalIncome) || syncFailed
          ? CardStatus.error
          : _income?.percentVerified > VERIFIED_PCT_SUCCESS
          ? CardStatus.success
          : _income?.percentVerified > VERIFIED_PCT_WARN
          ? CardStatus.warn
          : CardStatus.error,
      pending,
      note: undefined,
      income: _income?.totalIncome,
      lumenEvaluates: app.lumenEvaluates,
      verifiedIncome: app.verifiedIncome,
      totalIncome: app.totalIncome,
      stated: _income?.stated,
      monthlyIncomePerRent: !rent ? 0 : _income?.totalIncome / 12 / rent,
      lumenEnabled: deal?.lumenEnabled,
      useIncomeRentRatio: lumenRevision?.useIncomeRentRatio,
      lumenRecords: {
        ideal: lumenDealMessages?.some(
          (m) => m.code === LumenCode.INCOME_MEET_IDEAL
        ),
        good: lumenDealMessages?.some(
          (m) => m.code === LumenCode.INCOME_MEET_MINIMUM
        ),
        negative: lumenDealMessages?.some(
          (m) => m.code === LumenCode.INCOME_NO_MINIMUM
        ),
        skipped: messages?.some((m) =>
          [LumenCode.IA_SKIPPED, LumenCode.INCOME_SKIPPED].includes(m.code)
        ),
        groupSkipped: lumenDealMessages?.some((m) =>
          [LumenCode.IA_SKIPPED, LumenCode.INCOME_SKIPPED].includes(m.code)
        ),
        argyleFailed: syncFailed,
        ocrProcessing,
        isTotalIncomeWithApplicant:
          existGuarantor &&
          app.applicant.type === ApplicantType.applicant &&
          lumenRevision?.totalIncomeWithGuarantor ===
            LUMEN_TOTAL_INCOME_OR_ASSETS_WITH_GUARANTORS_MODE.ONLY_GUARANTOR,
      },
    };
    // always show balances card
    const _balances = app.applicant?.balances;
    props.balance = {
      accounts: _balances?.bankAccounts?.length ?? 0,
      balance: _balances?.availableCash, // We only use the available total and not the current total
      cashPerRent: isNil(_balances?.availableCash)
        ? 0
        : _balances.availableCash / (rent ?? Number.POSITIVE_INFINITY),
      skipped: _balances?.skipped,
      skippedReason: _balances?.skippedReason,
      verifiedPercent: _balances?.verified ? 100 : 0,
      lumenEnabled: deal?.lumenEnabled,
      useAssets: lumenRevision?.useAssets,
      isCashRunwayNotMeet: lumenDealMessages?.some((m) =>
        [LumenCode.INCOME_MEET_IDEAL, LumenCode.INCOME_MEET_MINIMUM].includes(
          m.code
        )
      ),
      lumenRecords: deal?.lumenEnabled
        ? {
            ideal: lumenDealMessages?.some(
              (m) => m.code === LumenCode.ASSETS_MEET_IDEAL
            ),
            good: lumenDealMessages?.some(
              (m) => m.code === LumenCode.ASSETS_MEET_MINIMUM
            ),
            negative: lumenDealMessages?.some(
              (m) => m.code === LumenCode.ASSETS_NO_MINIMUM
            ),
            lumenSkipped: messages?.some((m) =>
              [LumenCode.ASSETS_SKIPPED].includes(m.code)
            ),
            groupDealLumenSkipped: lumenDealMessages?.some((m) =>
              [LumenCode.ASSETS_SKIPPED].includes(m.code)
            ),
          }
        : null,
      isTotalAssetsWithApplicant:
        existGuarantor &&
        app?.applicant?.type === ApplicantType.applicant &&
        lumenRevision?.totalAssetsWithGuarantor ===
          LUMEN_TOTAL_INCOME_OR_ASSETS_WITH_GUARANTORS_MODE.ONLY_GUARANTOR,
    };
    const _credit = app.applicant?.credit;
    if (_credit) {
      props.credit = {
        skipped: _credit.skipped,
        skippedReason: _credit.skippedReason,
        collectionAccounts: _credit.collectionAccounts,
        collectionTotal: _credit.collectionTotal,
        creditAvailablePercent: !_credit.creditLimit
          ? null
          : (_credit.creditBalance / _credit.creditLimit) * 100,
        creditBalance: _credit.creditBalance,
        creditScore: _credit.creditScore,
        latePayment30: _credit.latePayment30,
        latePayment60: _credit.latePayment60,
        latePayment90: _credit.latePayment90,
        lumenEnabled: deal?.lumenEnabled,
        useCreditScore: lumenRevision?.useCreditScore,
        useCollections: lumenRevision?.useCollections,
        applicantonlyGuarantors:
          existGuarantor &&
          lumenRevision?.creditRecordsScope !==
            LUMEN_CREDIT_RECORDS_SCOPE.NONE &&
          app.applicant.type === ApplicantType.applicant &&
          lumenRevision?.creditScoreScopeWithGuarantors ===
            LUMEN_CREDIT_SCORE_WITH_GUARANTORS_MODE.ONLY_GUARANTOR &&
          messages?.some((m) =>
            [
              LumenCode.NO_CREDIT_PUBLIC_RECORDS,
              LumenCode.NO_CREDIT_CRUCIAL_RECORDS,
              LumenCode.NO_CREDIT_BANKRUPTCIES_RECORDS,
            ].includes(m.code)
          ),

        onlyGuarantors:
          existGuarantor &&
          lumenRevision?.creditRecordsScope ===
            LUMEN_CREDIT_RECORDS_SCOPE.NONE &&
          app.applicant.type === ApplicantType.applicant &&
          lumenRevision?.creditScoreScopeWithGuarantors ===
            LUMEN_CREDIT_SCORE_WITH_GUARANTORS_MODE.ONLY_GUARANTOR,
        creditLumenRecords: deal?.lumenEnabled
          ? {
              crucial: messages?.some(
                (m) => m.code === LumenCode.CREDIT_RECORDS_CRUCIAL
              ),
              safetyNet: messages?.some(
                (m) => m.code === LumenCode.CREDIT_RECORDS_SAFETYNET
              ),
              negativeScore: messages?.some(
                (m) =>
                  m.code ===
                  (app.applicant.type === ApplicantType.guarantor
                    ? LumenCode.SOME_GUARANTORS_CS_NO_MINIMUM
                    : LumenCode.SOME_APPLICANTS_CS_NO_MINIMUM)
              ),
              idealScore: messages?.some(
                (m) =>
                  m.code ===
                  (app.applicant.type === ApplicantType.guarantor
                    ? LumenCode.GUARANTORS_MEET_CS_IDEAL
                    : LumenCode.APPLICANTS_MEET_CS_IDEAL)
              ),
              goodScore: messages?.some(
                (m) =>
                  m.code ===
                  (app.applicant.type === ApplicantType.guarantor
                    ? LumenCode.GUARANTORS_MEET_CS_MINIMUM
                    : LumenCode.APPLICANTS_MEET_CS_MINIMUM)
              ),
            }
          : null,
        collectionsLumenRecords: deal?.lumenEnabled
          ? {
              crucial: messages?.some(
                (m) => m.code === LumenCode.COLLECTION_RECORDS_CRUCIAL
              ),
              safetyNet: messages?.some(
                (m) => m.code === LumenCode.COLLECTION_RECORDS_SAFETYNET
              ),
            }
          : null,
      };
    }

    const _criminal = app.applicant?.criminal;
    const { skippedBgcc } =
      applicantsSummaryShort?.find((a) => a.id === Number(app.id)) ?? {};

    const criminalLumen = {
      skipped: skippedBgcc,
      skippedReason: SKIPPED_BACKGROUND_AND_CREDIT_VERIFICATION_MSG,
      lumenEnabled: deal?.lumenEnabled,
      lumenRecords: deal?.lumenEnabled
        ? {
            crucial: messages?.some(
              (m) => m.code === LumenCode.CRIMINAL_RECORDS_CRUCIAL
            ),
            safetyNet: messages?.some(
              (m) => m.code === LumenCode.CRIMINAL_RECORDS_SAFETYNET
            ),
          }
        : null,
      useCriminalRecords: lumenRevision?.useCriminalRecords,
    };
    // do not show criminal card if conditional proceed is showing.
    if (_criminal && !deal.conditionalProceed) {
      props.criminal = {
        highestSeverity: _criminal.highestSeverity,
        lastRecord: formatRecord(
          _criminal.lastRecordDate,
          _criminal.lastRecordState
        ),
        records: _criminal.records,
        ...criminalLumen,
      };
    } else if (skippedBgcc) {
      props.criminal = {
        ...criminalLumen,
      };
    }
    const _employment = app.applicant?.employment;
    let verificationType = _employment?.verificationTypes
      ?.map((type) => mapVerificationTypeToString[type] ?? "")
      .join(" & ");

    if (
      verificationType?.includes(
        mapVerificationTypeToString[VerificationType.LINK_BANK_ACCOUNT]
      ) ||
      verificationType?.includes(
        mapVerificationTypeToString[VerificationType.LINKED_EMPLOYMENT]
      )
    ) {
      verificationType = `Linked ${verificationType}`;
    }

    // always show employment card
    props.employment = {
      enabled: _employment?.enabled ?? true, // if false, hide the card
      skipped: _employment?.skipped ?? false,
      company: _employment?.company,
      end: _employment?.end ? dayjs(_employment.end) : null,
      start: _employment?.start ? dayjs(_employment.start) : null,
      jobTitle: _employment?.jobTitle,
      verificationType,
      lumenEnabled: deal?.lumenEnabled,
      status: isNil(_employment?.employed)
        ? null
        : app.applicant?.isStudent
        ? EmploymentStatus.Student
        : _employment.employed
        ? EmploymentStatus.Employed
        : EmploymentStatus.Unemployed,
    };

    const _eviction = app.applicant?.eviction;
    props.eviction = {
      skipped: _eviction?.skipped || _eviction?.enabled === false,
      lumenEnabled: deal?.lumenEnabled,
      lumenRecords: deal?.lumenEnabled
        ? {
            crucial: messages?.some(
              (m) => m.code === LumenCode.HOUSING_COURT_RECORDS_CRUCIAL
            ),
            safetyNet: messages?.some(
              (m) => m.code === LumenCode.HOUSING_COURT_RECORDS_SAFETYNET
            ),
          }
        : null,
      enabled: _eviction?.enabled,
      useHousingCourtRecords: lumenRevision?.useHousingCourtRecords,
      skippedReason: _eviction?.skipped
        ? SKIPPED_BACKGROUND_AND_CREDIT_VERIFICATION_MSG
        : _eviction?.enabled === false
        ? "Eviction data is not permitted for use in this application"
        : null,
      judgmentAmount: _eviction?.judgmentAmount,
      lastRecord: formatRecord(
        _eviction?.lastRecord,
        _eviction?.lastRecordState
      ),
      records: _eviction?.records,
    };

    const _identity = app.applicant?.identity;
    if (_identity) {
      props.identity = {
        enabled: _identity?.enabled ?? true, // if false, hide the card
        lumenEnabled: deal?.lumenEnabled,
        useVerifications: lumenRevision?.useVerifications,
        verifyIdentity: lumenRevision?.verifyIdentity,
        skipped:
          _identity.verified === undefined || _identity.verified === null,
        verified: _identity.verified,
        status:
          _identity.verified === true
            ? "Verified"
            : _identity.verified === false
            ? "Unverified"
            : "N/A",
        country: _identity.country,
        expires: _identity.expires ? dayjs(_identity.expires) : null,
        identityNumber: _identity.identityNumber,
        documentType:
          PERSONA_ID_CLASS_LABELS[_identity.documentType] ??
          _identity.documentType,
      };
    }

    const _vor = app.applicant?.verificationOfResidency;
    if (_vor && _vor.status) {
      props.vor = {
        enabled: _vor?.enabled ?? true, // if false, hide the card
        score: _vor.score,
        outOf: _vor.outOf,
        status: _vor.status,
        email: _vor.landlordEmail,
        phone: _vor.landlordPhone,
        name: _vor.landlordName,
        lowestScore: _vor.lowestScore,
        submission: _vor.submission ? dayjs(_vor.submission) : null,
        lumenEnabled: deal?.lumenEnabled,
      };
    }
    props.rent = rent;
    props.preferredLeaseDurationMonths = preferredLeaseDurationMonths;
    return props;
  }) ?? [];

export const mapDealToApplicantsSummary = (deal?: Deal): ApplicantSummary[] => {
  return (
    deal?.applications.map((a) => ({
      id: a.id,
      firstName: a.applicant?.firstName,
      isSubmitted: a.isSubmitted,
      lastName: a.applicant?.lastName,
    })) ?? []
  );
};

export const mapDealToActionBarProps = (deal?: Deal): ActionBarProps => {
  const submittedCount = deal?.applications?.reduce(
    (count, app) => (app?.isSubmitted ? count + 1 : count),
    0
  );
  const appStatus = !deal?.status
    ? null
    : ![
        DEAL_UI_STATUS_CODES.invitesSent,
        DEAL_UI_STATUS_CODES.applicationsIncomplete,
        DEAL_UI_STATUS_CODES.partiallySubmitted,
        DEAL_UI_STATUS_CODES.submitted,
      ].includes(deal.status)
    ? AppStatus.Done
    : deal.status === DEAL_UI_STATUS_CODES.submitted ||
      submittedCount === deal?.applications?.length
    ? AppStatus.Submitted
    : submittedCount === 0
    ? AppStatus.Empty
    : AppStatus.Submitting;

  const appStatusLabel = (DEAL_UI_STATUS_LABEL[deal?.status] as string) ?? "";
  const isApproved = [
    DEAL_UI_STATUS_CODES.approved,
    DEAL_UI_STATUS_CODES.approvedInYardi,
  ].includes(deal?.status);
  const approved =
    isApproved && Boolean(deal?.lease?.id)
      ? ApproveStatus.CreatedLease
      : isApproved
      ? ApproveStatus.Approved
      : null;

  const lumen = mapLumenResultToProps(deal?.lumen);

  return {
    showLumen: deal?.lumenEnabled,
    approved,
    lumen,
    lease: deal?.lease,
    status: appStatus,
    statusLabel: appStatusLabel,
    permission: deal?.permission,
    dealId: deal?.id,
    conditionalProceed: deal?.conditionalProceed,
    applicantsSummary: mapDealToApplicantsSummary(deal),
    missingUnit: !deal?.unit?.name,
    countryCriminalSearchInProgress:
      deal?.countyCriminal?.status?.value ===
      COUNTY_CRIMINAL_SEARCH_STATUS_ENUM.IN_PROGRESS,
    criminalSearchInProgress:
      deal?.applications?.reduce(
        (processing, app) => processing || app?.isCriminalComplete === false,
        false
      ) ?? false,
    backgroundReportsUnavailable:
      deal?.applications?.reduce(
        (recordsUnavailable, app) =>
          recordsUnavailable || app?.hasUnavailableBackgroundReports === true,
        false
      ) ?? false,
    ocrProcessing:
      deal?.applications?.reduce(
        (processing, app) =>
          processing || app?.argyleStatus === ArgyleStatus.OCR_PROCESSING,
        false
      ) ?? false,
    canManuallySendToPms: deal?.canManuallySendToPms,
    hasBeenSentToEntrata: deal?.status === DEAL_UI_STATUS_CODES.sentToEntrata,
    lastSentToPmsAt: deal?.lastSentToPmsAt,
    provider: deal?.unit?.provider,
  };
};

export const mapDealToDependentsProps = (deal?: Deal): DependentProps[] =>
  deal?.dependents?.map<DependentProps>((dep) => ({
    age: dep.age,
    firstName: dep.firstName,
    lastName: dep.lastName,
    relationship: dep.relationship,
    type: dep.type,
  })) || [];

export const mapToCountyRecordsProps = (deal: Deal): CountyRecordsProps => ({
  id: Number(deal?.id),
  lumenEnabled: deal?.lumenEnabled,
  applicantsSummary: deal?.countyCriminal?.applicants,
  countyCriminalSearchStartedAt: deal?.countyCriminal?.startedAt,
  countyCriminalSearchRequestedByName: deal?.countyCriminal?.requestedByName,
  countyCriminalSearchStatus: deal?.countyCriminal?.status,
});

export const mapDealToProps = (
  dealId: string,
  deal: Deal,
  preferredLeaseDurationMonths: number,
  rent?: number,
  lumenRevision?: LumenRevision,
  lumenResult?: LumenResponse,
  applicantsSummaryShort?: ApplicantShort[]
) => {
  const dealModalProps: ApproveDealModalProps & DeclineDealModalProps = {
    deal: {
      id: dealId,
      applicantsSummary: mapDealToApplicantsSummary(deal),
      property: deal?.property,
      unit: deal?.unit,
    },
  };
  const title = `Application Summary, ${
    deal?.unit ? `Unit ${deal?.unit.name}` : "no unit"
  }`;

  const appWellProps: AppWellProps = (() => {
    if (!deal) return null;
    const income =
      deal?.applications?.reduce<number>(
        (total, app) =>
          total +
          (app?.applicant?.income?.totalIncome ??
            app?.applicant?.income?.stated ??
            0),
        0
      ) ?? 0;

    const confidenceParts =
      deal?.applications?.reduce(
        ({ total, count }, app) => {
          const percent = app?.applicant?.income?.percentVerified;
          return {
            total: total + (percent ?? 0),
            count:
              percent === null || percent === undefined ? count : count + 1,
          };
        },
        { total: 0, count: 0 }
      ) ?? null;

    const incomeConfidence =
      confidenceParts.count === 0
        ? null
        : confidenceParts.total / confidenceParts.count;

    const balances =
      deal?.applications?.reduce<number>(
        (total, app) => total + (app?.applicant?.balances?.availableCash ?? 0),
        0
      ) ?? 0;
    const rent = deal?.fees?.rent;
    const fraudIndicators =
      deal?.applications?.flatMap(
        (app) =>
          app?.fraudIndicators?.map<FraudIndicator>((fraud) => ({
            name: `${app.applicant?.firstName} ${app.applicant?.lastName}`,
            description: fraud,
          })) ?? []
      ) ?? [];

    return {
      annualIncome: income,
      annualIncomeConfidence: incomeConfidence,
      monthlyIncomePerRent: !rent ? 0 : income / 12 / rent,
      verifiedCashPerRent: calculateVerifiedCashPerRent(
        rent,
        balances,
        preferredLeaseDurationMonths
      ),
      fraudIndicators,
    };
  })();

  return {
    title,
    dealModalProps,
    appWellProps,
    actionBarProps: mapDealToActionBarProps(deal),
    apps: mapDealToAppRowProps(
      deal,
      preferredLeaseDurationMonths,
      lumenRevision,
      lumenResult,
      applicantsSummaryShort
    ),
    deps: mapDealToDependentsProps(deal),
    countyRecordsProps: mapToCountyRecordsProps(deal),
  };
};

export const getValue = (value: string) => {
  if (!value || value === "N/A") {
    return EMPTY_INDICATOR;
  }
  return value;
};
