import { queryCache, useMutation, useQuery } from "react-query";

import { PORTFOLIO_QUERY_KEYS } from "manager/hooks/api";
import { NOTIFICATIONS } from "shared/config/constants";
import api from "shared/lib/api";
import { openNotification } from "shared/utils/ui";

const GET_LOAD_FILE_BY_URL = "getLoadFileByUrl";

export function useLoadFileByUrl(url: string) {
  const { data: fileByUrl, isLoading: isLoadingFileByUrl } = useQuery(
    [GET_LOAD_FILE_BY_URL],
    () => api.loadFileByUrl(url),
    {
      onError: () => {
        openNotification("Failed to load the document", NOTIFICATIONS.error);
      },
    }
  );

  return {
    fileByUrl,
    isLoadingFileByUrl,
  };
}

export const useAssignDocumentToProperties = (documentId: number) => {
  const [mutate, { isLoading }] = useMutation(
    (propertiesToAssign: number[]) =>
      api.assignDocumentToProperties(documentId, propertiesToAssign),
    {
      onSuccess: () =>
        queryCache.invalidateQueries(
          PORTFOLIO_QUERY_KEYS.getPortfoliosForDocuments(documentId)
        ),
      onError: () =>
        openNotification(
          "Failed to assign document to properties.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    assignPropertyToPortfolio: mutate,
    isAssignPropertyToPortfolio: isLoading,
  };
};

export const useUnassignDocumentFromProperties = (documentId: number) => {
  const [mutate, { isLoading }] = useMutation(
    (propertiesToUnassign: number[]) =>
      api.unassignDocumentFromProperties(documentId, propertiesToUnassign),
    {
      onSuccess: () =>
        queryCache.invalidateQueries(
          PORTFOLIO_QUERY_KEYS.getPortfoliosForDocuments(documentId)
        ),
      onError: () =>
        openNotification(
          "Failed to unassign document to properties.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    unassignDocumentFromProperties: mutate,
    isUnassignDocumentFromProperties: isLoading,
  };
};
