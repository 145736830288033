import { DEAL_CATEGORIES } from "manager/config/constants";
import {
  BREAKPOINT_TYPES,
  DEAL_UI_STATUS_CODES,
  DEAL_UI_STATUS_LABEL,
  LEASE_STATUS_CODES,
  LEASE_STATUS_LABEL,
} from "shared/config/constants";

export const combineFilters = ({
  category,
  filters,
  pagination,
  ordering,
  showArchived,
}) => {
  return {
    ...filters,
    dealCategory: DEAL_CATEGORIES.find((item) => item.key === category)?.apiKey,
    ...pagination,
    ordering,
    isArchived: showArchived ? null : false,
  };
};

export const ORDER_BY_NAMES = Object.freeze({
  createdAt: "created_at",
  rent: "combined_rent",
});

export const ORDER_BY = Object.freeze({
  applicationDate: {
    ascend: ORDER_BY_NAMES.createdAt,
    descend: `-${ORDER_BY_NAMES.createdAt}`,
  },
  rent: {
    ascend: ORDER_BY_NAMES.rent,
    descend: `-${ORDER_BY_NAMES.rent}`,
  },
});

export const ExpandIconIndex = Object.freeze({
  [BREAKPOINT_TYPES.lg]: 4,
  [BREAKPOINT_TYPES.md]: 3,
  [BREAKPOINT_TYPES.sm]: 3,
});

export const getDealStatusText = (
  code: string,
  complete: number,
  total: number
) => {
  const isPartiallySubmitted = code === DEAL_UI_STATUS_CODES.partiallySubmitted;
  const dealStatus = isPartiallySubmitted
    ? DEAL_UI_STATUS_LABEL[code](complete, total)
    : DEAL_UI_STATUS_LABEL[code];
  return dealStatus || "Unknown";
};

export const getLeaseStatusText = (
  code: string,
  complete: number,
  total: number
) => {
  const isPartiallySigned = code === LEASE_STATUS_CODES.partiallySigned;
  const leaseStatus = isPartiallySigned
    ? LEASE_STATUS_LABEL[code](complete, total)
    : LEASE_STATUS_LABEL[code];
  return leaseStatus || "Unknown";
};
