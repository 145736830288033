import React from "react";

import { UnderlineLink } from "shared/components/Links";

import TeaserModal from "shared/components/Modals/TeaserModal";

const AssignedDocumentsErrorModal = () => (
  <TeaserModal
    subtitleMessage={
      <>
        Whoops! Looks like leasing isn&#39;t active <br /> for this property.
      </>
    }
    description={
      <>
        Are you ready to take your leasing game to the next level? Reach out to
        us at{" "}
        <UnderlineLink href="mailto:leasing@sayvero.com">
          leasing@sayvero.com
        </UnderlineLink>{" "}
        today!
      </>
    }
  />
);

export default AssignedDocumentsErrorModal;
