import styled from "@emotion/styled";

import { CSSProperties } from "styled-components";

import { MEDIA_QUERIES, COLORS } from "shared/config/constants";

export const Content = styled("div")(
  (props: { disableMinHeight?: boolean }) => ({
    width: "100%",
    height: "100%",
    position: "absolute",

    ...(!props.disableMinHeight && {
      minHeight: "800px",
      [MEDIA_QUERIES.w.ltLg]: {
        minHeight: "600px",
      },
      [MEDIA_QUERIES.w.ltSm]: {
        minHeight: "450px",
      },
    }),
  })
);

export const OuterContainer = styled("div")(
  (props: { backgroundColor: string }) => ({
    flexGrow: 1,
    background: props.backgroundColor,

    width: "100%",
    height: "calc(100% - 72px)",
    position: "relative",

    [MEDIA_QUERIES.w.ltMd]: {
      height: "calc(100% - 50px)",
    },
  })
);

export const ScrollContainer = styled("div")(
  ({
    visible,
    marginRight,
    backgroundColor,
  }: {
    visible?: boolean;
    marginRight?: boolean;
    backgroundColor?: CSSProperties["color"];
  }) => ({
    background: backgroundColor || COLORS.backgroundGrey,
    ...(!visible && {
      background: "transparent",
    }),
    overflow: "auto",
    ...(marginRight && {
      marginRight: "50px",
    }),
    height: "100%",
    width: marginRight ? "calc(100% - 50px)" : "100%",
    padding: "0 5px",
    [MEDIA_QUERIES.w.ltSm]: {
      marginBottom: "50px",
    },
  })
);

export const InnerContainer = styled("div")(
  ({ width }: { width: CSSProperties["width"] }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "> canvas": {
      margin: "10px 0",
      border: "1px solid black",
    },
    margin: "auto",
    width,
  })
);

export const ZoomContainer = styled("div")({
  position: "absolute",
  top: "10px",
  right: "4px",
  zIndex: 20,
});

export const LoaderContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 200,
});

export const DownloadWrapper = styled("div")({
  marginLeft: "30px",
});
