import React from "react";

import { Row, Col } from "antd";

import Icon from "shared/components/Icon";
import PageTitle from "shared/components/PageTitle";

import { Header, SubtitleContainer, Subtitle } from "./styled";

export interface ISignLeaseLayoutHeader {
  title?: string;
  subtitle?: string;
}

const SignLeaseLayoutHeader = ({
  title = "",
  subtitle = "",
}: ISignLeaseLayoutHeader) => (
  <Header>
    <Row type="flex" justify="space-between" align="middle" gutter={[0, 15]}>
      <Col>
        <PageTitle>{title}</PageTitle>
      </Col>
      <Col>
        <SubtitleContainer>
          <Icon.MyPropertyBoldIcon style={{ minHeight: 28, minWidth: 28 }} />
          <Subtitle>{subtitle}</Subtitle>
        </SubtitleContainer>
      </Col>
    </Row>
  </Header>
);

export default SignLeaseLayoutHeader;
