import React from "react";

import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useGetPropertyLeaseSettings } from "manager/hooks/api";
import { useUpdatePropertyLeaseSetup } from "manager/hooks/api/propertyLeaseSetupQueries";

import LeaseSetupForm from "./LeaseSetupForm";
import { buildLeaseSetupDTO } from "./utils";

const LeaseSetupFormContainer = ({ fields, refetch, ...props }) => {
  const { id: propertyId } = useParams();
  const { leaseSetup, isLoading, refetchLeaseSetup } =
    useGetPropertyLeaseSettings(propertyId);

  const { updateLeaseSetup, isLoading: isSaving } = useUpdatePropertyLeaseSetup(
    () => {
      refetchLeaseSetup();
      refetch();
    }
  );

  const saveChanges = (values) =>
    updateLeaseSetup({
      id: propertyId,
      leaseSetup: buildLeaseSetupDTO(values),
    });

  return (
    <LeaseSetupForm
      saveChanges={saveChanges}
      loading={isLoading}
      isSaving={isSaving}
      leaseSetup={{ ...leaseSetup, fields }}
      {...props}
    />
  );
};

LeaseSetupFormContainer.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      isEditable: PropTypes.bool.isRequired,
      description: PropTypes.string,
      value: PropTypes.string,
      documentsAssigned: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  refetch: PropTypes.func,
};

LeaseSetupFormContainer.defaultProps = {
  fields: [],
  refetch: () => {},
};

export default LeaseSetupFormContainer;
