import { useMemo } from "react";

import isArray from "lodash/isArray";
import keyBy from "lodash/keyBy";

import ActiveDocumentsErrorModal from "manager/components/Modal/Deal/ActiveDocumentsErrorModal";
import AssignedDocumentsErrorModal from "manager/components/Modal/Deal/AssignedDocumentsErrorModal";
import {
  DEAL_OVERVIEW_VERSION,
  CREATE_LEASE_ERROR_CODES,
} from "manager/config/constants";
import TeaserModal from "shared/components/Modals/TeaserModal";
import {
  DEAL_UI_STATUS_CODES,
  HOLDING_DEPOSIT_STATUSES,
  LEASE_STATUS_CODES,
  LOCAL_STORAGE,
  NOTIFICATIONS,
} from "shared/config/constants";
import { LocalStorageCollectionManager } from "shared/utils/localStorage";
import { ModalManager } from "shared/utils/modalmanager";
import { openNotification } from "shared/utils/ui";

const DEAL_OVERVIEW_VERSION_KEY = "dealOverviewVersion";

export const dealOverviewLocalStorage = new LocalStorageCollectionManager({
  storageKey: LOCAL_STORAGE.dealOverviewVersion,
  autoSync: true,
});

export const getCurrentDealOverviewVersion = () => {
  return (
    // TODO (POD3-49) vido: follow the jira ticket and check if this has to be completely removed
    // or this has to be returned as it was before
    // dealOverviewLocalStorage.getItem(DEAL_OVERVIEW_VERSION_KEY) ||
    DEAL_OVERVIEW_VERSION.V2
  );
};

export const setCurrentDealOverviewVersion = (version) => {
  dealOverviewLocalStorage.addItem(DEAL_OVERVIEW_VERSION_KEY, version);
};

const LEASE_CREATE_ERROR_CODES_HANDLED_ON_THE_UI = [
  CREATE_LEASE_ERROR_CODES.NO_ASSIGNED_DOCUMENTS,
  CREATE_LEASE_ERROR_CODES.NO_ACTIVE_DOCUMENTS,
  CREATE_LEASE_ERROR_CODES.YARDI_WAIT_UNIT,
  CREATE_LEASE_ERROR_CODES.HOLDING_DEPOSIT_NOT_PAID,
];

export const shouldHandleLeaseCreateNotAllowed = (deal) => {
  const { errorCode } = deal?.canCreateLease || {};
  return LEASE_CREATE_ERROR_CODES_HANDLED_ON_THE_UI.includes(errorCode);
};

export const showCreateLeaseButton = (deal) => {
  // NOTE: we show the button if "Create Lease" is allowed or if the error
  // is handled by the FE with some kind of instruction set in a modal dialog.
  return (
    deal.canCreateLease &&
    (deal.canCreateLease.value || shouldHandleLeaseCreateNotAllowed(deal))
  );
};

export const handleLeaseCreateNotAllowed = (errorCode, message) => {
  let handled = false;
  if (errorCode === CREATE_LEASE_ERROR_CODES.NO_ASSIGNED_DOCUMENTS) {
    ModalManager.openModal(AssignedDocumentsErrorModal);
    handled = true;
  } else if (errorCode === CREATE_LEASE_ERROR_CODES.NO_ACTIVE_DOCUMENTS) {
    ModalManager.openModal(ActiveDocumentsErrorModal);
    handled = true;
  } else if (errorCode === CREATE_LEASE_ERROR_CODES.YARDI_WAIT_UNIT) {
    ModalManager.openModal(TeaserModal, {
      subtitleMessage:
        "Whoops! Looks like the unit assigned to the application is a Yardi wait list unit.",
      description: message,
    });
    handled = true;
  } else if (errorCode === CREATE_LEASE_ERROR_CODES.HOLDING_DEPOSIT_NOT_PAID) {
    ModalManager.openModal(TeaserModal, {
      subtitleMessage: "Whoops! Looks like the holding deposit is not paid.",
      description: message,
    });
    handled = true;
  }
  return handled;
};

export const onLeaseCreateError = ({ data }) => {
  const isErrorHandled = handleLeaseCreateNotAllowed(
    data.errorCode,
    data.nonFieldErrors
  );
  if (!isErrorHandled) {
    if (isArray(data) && data?.length > 0) {
      openNotification(data, NOTIFICATIONS.error);
    } else {
      openNotification("Failed to create lease.", NOTIFICATIONS.error);
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      "This error on 'Create Lease' should've been handled on the FE",
      data
    );
  }
};

export const showConditionallyProceed = (deal) =>
  deal?.hasConditionallyProceedEnabled &&
  !deal?.isConditionallyProceeded &&
  DEAL_UI_STATUS_CODES.submitted === deal?.dealUiStatus;

export const executeCreateLease = (deal, createLeaseAction) => {
  if (shouldHandleLeaseCreateNotAllowed(deal)) {
    const { errorCode, helpText } = deal.canCreateLease || {};
    handleLeaseCreateNotAllowed(errorCode, helpText);
  } else {
    createLeaseAction();
  }
};

export const disableEditingActions = (leaseStatus) => {
  return leaseStatus && LEASE_STATUS_CODES.inProgress !== leaseStatus;
};

export const getLumenResultPerApplicant = (lumenResult) =>
  useMemo(() => keyBy(lumenResult?.applicants || [], "id"), [lumenResult]);

export const isDealHoldingDepositPaid = (deal) =>
  Boolean(
    deal?.isHoldingDepositEnabled &&
      deal?.holdingDepositPaymentStatus ===
        HOLDING_DEPOSIT_STATUSES.HOLDING_DEPOSIT_PAID
  );
