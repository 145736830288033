import React from "react";

import { Modal } from "shared/components/Modal";

const SendLeaseModal = ({ unitName, propertyName, sendLease, isLoading }) => (
  <Modal
    title="Send the lease?"
    submitButtonLabel="Send"
    subtitle={
      <span data-testid="subtitle">
        Unit: <strong>{`${unitName}, ${propertyName}`}</strong>
      </span>
    }
    submit={sendLease}
    submitting={isLoading}
  >
    <Modal.Body>
      Please note that once you send the lease all lease participants will be
      notified.
    </Modal.Body>
  </Modal>
);

export default SendLeaseModal;
