import React from "react";

import { useGetCompanyLeaseSetup } from "manager/hooks/api";
import { useUpdateCompanyLeaseSetup } from "manager/hooks/api/leaseSetupQueries";

import LeaseSetupForm from "./LeaseSetupForm";
import { buildLeaseSetupDTO } from "./utils";

const LeaseSetupFormContainer = (props) => {
  const { leaseSetup, isLoading, refetchLeaseSetup } =
    useGetCompanyLeaseSetup();

  const { updateLeaseSetup, isLoading: isSaving } =
    useUpdateCompanyLeaseSetup(refetchLeaseSetup);

  const saveChanges = (values, resolve, reject) => {
    updateLeaseSetup({
      leaseSetup: buildLeaseSetupDTO(values),
    })
      .then(resolve)
      .catch(reject);
  };

  return (
    <LeaseSetupForm
      saveChanges={saveChanges}
      loading={isLoading}
      isSaving={isSaving}
      leaseSetup={leaseSetup}
      {...props}
    />
  );
};

export default LeaseSetupFormContainer;
