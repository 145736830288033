import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import Label from "shared/components/Label";
import Tile from "shared/components/Tile";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const LoadingSpinner = styled(Icon.LoadingSpinnerGreenLargeIcon)`
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%);
`;

const Divider = styled.div`
  height: 270px;

  ${MEDIA_QUERIES.w.ltSm} {
    display: none;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;

  & > * {
    margin-right: 10px;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    margin-bottom: 20px;
  }
`;

const LeaseContainer = styled.div`
  margin: 0 24px;
  position: relative;

  ${MEDIA_QUERIES.w.ltSm} {
    margin: 0;
    margin-bottom: 20px;
  }
`;

const GeneratedByWrapper = styled.div``;

interface GeneratedByProps {
  isExecuted?: boolean;
}

const GeneratedBy = styled(Label)<GeneratedByProps>`
  font-size: 12px;
  margin-left: auto;

  ${(props) =>
    props.isExecuted && `background-color: ${COLORS.tag.green.background}`}
`;

const LeaseInfoContainer = styled(Tile)`
  width: 70%;
  margin-right: 16px;
  height: fit-content;

  ${MEDIA_QUERIES.w.ltLg} {
    width: 100%;
    margin-right: 0;
  }
`;

const LeaseContent = styled.div`
  display: flex;

  ${MEDIA_QUERIES.w.ltLg} {
    flex-direction: column;
  }
`;

export {
  LeaseContainer,
  LoadingSpinner,
  Divider,
  TitleWrapper,
  GeneratedBy,
  GeneratedByWrapper,
  LeaseInfoContainer,
  LeaseContent,
};
