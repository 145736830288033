import React, { useMemo } from "react";

import { Row, Col } from "antd";
import dayjs from "dayjs";
import pluralize from "pluralize";

import { Select, VeroFormField } from "shared/components/Form";
import Tooltip from "shared/components/Tooltip";

export const ConcessionMonthsDropdown = ({
  leaseStartDate,
  leaseEndDate,
  concessionMonths,
  isConcessionMonthsLoading,
}) => {
  const { concessionStart, concessionEnd } = concessionMonths || {};
  const isConcessionsLoaded = Boolean(
    !isConcessionMonthsLoading && concessionStart && concessionEnd
  );

  const monthItems = useMemo(() => {
    const items = [];

    if (!isConcessionsLoaded) {
      return items;
    }

    const start = dayjs(concessionStart);
    const end = dayjs(concessionEnd);
    const step = 0.5;
    let index = step;
    let currentMonth = start;

    while (currentMonth?.isSameOrBefore(end)) {
      items.push({
        label: `${index} ${pluralize("month", index)}`,
        value: index,
      });

      if (Number.isInteger(index)) {
        currentMonth = currentMonth.add(1, "month");
      }

      index += step;
    }
    return items;
  }, [concessionStart, concessionEnd, isConcessionsLoaded]);

  const disabled = !leaseStartDate || !leaseEndDate;
  const disabledTooltip = disabled ? "Please specify lease dates" : "";
  return (
    <Row>
      <Col xs={24} sm={12} lg={6} xxl={4}>
        <Tooltip title={disabledTooltip} theme="light" placement="bottom">
          <VeroFormField
            id="numberOfMonths"
            name="numberOfMonths"
            label="Number of months"
            as={Select}
            disabled={disabled}
            loading={isConcessionMonthsLoading}
            data-testid="months-select"
          >
            {monthItems.map((item) => (
              <Select.Option
                key={item.value}
                data-testid={`${item.value}-month`}
              >
                {item.label}
              </Select.Option>
            ))}
          </VeroFormField>
        </Tooltip>
      </Col>
    </Row>
  );
};
