import React from "react";

import { useParams, useHistory } from "react-router-dom";

import ConfirmLeaseExecutionModal from "manager/components/Leasing/ConfirmLeaseExecutionModal";
import PreviewLeaseModal from "manager/components/Leasing/PreviewLeaseModal";
import ResendLeaseModal from "manager/components/Leasing/ResendLeaseModal";
import SwitchESignModal from "manager/components/Leasing/SwitchESignModal";
import WithdrawLeaseModal from "manager/components/Leasing/WithdrawLeaseModal";
import ROUTES from "manager/config/routes";
import {
  useGetLease,
  usePropertyLeaseSetup,
  useSetLeaseInProgress,
  useUpdateLeaseStep,
  useCountersignBluemoonLeaseQuery,
} from "manager/hooks/api";
import { HeaderProps } from "shared/components/Header";
import { SidebarProps } from "shared/components/Sidebar";
import { useLeaseStatuses, useGetApplicantTypes } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";
import { fileNameFromWords } from "shared/utils/pdf";
import { transformRoute } from "shared/utils/routing";

import BluemoonCounterSignModal from "../LeaseBuilder/LeasePreviewStep/BluemoonCounterSignModal";

import Lease from "./LeaseSummary";

interface LeaseSummaryContainerProps {
  header: HeaderProps;
  sidebar: SidebarProps;
}

const LeaseSummaryContainer = (props: LeaseSummaryContainerProps) => {
  const history = useHistory();
  const { id: leaseId } = useParams<{ id: string }>();
  const { openModalDialog, closeActiveModalDialog } = useModal();

  const {
    data: lease,
    isLoading: isLeaseLoading,
    refetch,
  } = useGetLease(leaseId);
  const { applicantTypes } = useGetApplicantTypes();
  const isBluemoonLease = lease?.isBluemoonLease;
  const finalDocumentGeneratedBy = lease?.finalDocumentGeneratedBy;
  const { countersignBlueemoonLease, isLoading: isBluemoonCountersignLoading } =
    useCountersignBluemoonLeaseQuery(lease?.id, closeActiveModalDialog);

  const propertyId = lease?.deal?.unit?.property?.id;
  const propertyName = lease?.deal?.unit?.property?.name;
  const unitName = lease?.deal?.unit?.name;
  const applicantLastName = lease?.deal?.applicantNames[0]?.lastName;
  const filename = fileNameFromWords({
    words: [propertyName, unitName, applicantLastName],
    timestamp: true,
  });

  const { isLoading: isLeaseSetupLoading, data: leaseSetup } =
    usePropertyLeaseSetup(propertyId, {
      enabled: Boolean(propertyId),
    });

  const { updateLeaseStep, isLoading: isLeaseMetadataUpdating } =
    useUpdateLeaseStep(leaseId);

  const { setLeaseInProgress, isLoading: isSettingInProgress } =
    useSetLeaseInProgress(leaseId);

  const { data: leaseStatuses } = useLeaseStatuses();

  const openBluemoonCountersignModal = () =>
    openModalDialog(
      BluemoonCounterSignModal,
      {
        managerName: finalDocumentGeneratedBy,
        submit: countersignBlueemoonLease,
        isLoading: isBluemoonCountersignLoading,
      },
      // @ts-ignore
      false
    );

  const countersign = () => {
    // eslint-disable-next-line no-unused-expressions
    isBluemoonLease
      ? openModalDialog(PreviewLeaseModal, {
          document: {
            name: filename,
            id: Number(leaseId),
          },
          openBluemoonCountersignModal,
        })
      : history.push(transformRoute(ROUTES.leaseCountersign, { id: leaseId }));
  };

  const viewLease = () =>
    openModalDialog(PreviewLeaseModal, {
      document: {
        name: filename,
        id: Number(leaseId),
      },
    });

  const onModifyLease = () => {
    setLeaseInProgress(lease.id, {
      onSuccess: () => {
        updateLeaseStep(
          {
            id: lease.id,
            data: {
              step: 1,
            },
          },
          {
            onSuccess: () =>
              history.push(
                transformRoute(ROUTES.leaseBuilder, { id: lease.id })
              ),
          }
        );
      },
    });
  };

  const onFinishLease = () =>
    history.push(transformRoute(ROUTES.leaseBuilder, { id: leaseId }));

  const onSwitchESign = () =>
    openModalDialog(SwitchESignModal, {
      leaseId: lease?.id,
      propertyName: lease?.deal?.unit?.property?.name,
      propertyAddress: lease?.deal?.unit?.property?.address,
      unitName: lease?.deal?.unit?.name,
    });

  const openWithdrawLeaseModal = () =>
    openModalDialog(WithdrawLeaseModal, {
      leaseId: lease?.id,
      propertyName: lease?.deal?.unit?.property?.name,
      unitName: lease?.deal?.unit?.name,
    });

  const confirmExecution = () =>
    openModalDialog(ConfirmLeaseExecutionModal, {
      propertyName: lease?.deal?.unit?.property?.name,
      unitName: lease?.deal?.unit?.name,
      leaseId: lease?.id,
    });

  const applicantsNotSignedLease =
    lease?.deal?.applicantNames.filter((applicant) => !applicant.isSigned) ||
    [];

  const resendLease = () =>
    openModalDialog(ResendLeaseModal, {
      propertyName: lease?.deal?.unit?.property?.name,
      unitName: lease?.deal?.unit?.name,
      leaseId: lease?.id,
      applicants: applicantsNotSignedLease,
      reminderSentAt: lease?.reminderSentAt,
      isLastReminderAuto: lease?.isLastReminderAuto,
    });

  const isResendAllowed = applicantsNotSignedLease?.length > 0;

  return (
    <Lease
      lease={lease}
      refetchLease={refetch}
      leaseStatuses={leaseStatuses}
      viewLease={viewLease}
      isLeaseLoading={isLeaseLoading || isLeaseSetupLoading}
      countersign={countersign}
      isESigningEnabled={leaseSetup?.isElectronicallySigned}
      onModifyLease={onModifyLease}
      isLeaseModifying={isLeaseMetadataUpdating || isSettingInProgress}
      onSwitchESign={onSwitchESign}
      applicantTypes={applicantTypes}
      openWithdrawLeaseModal={openWithdrawLeaseModal}
      confirmExecution={confirmExecution}
      resendLease={isResendAllowed ? resendLease : undefined}
      onFinishLease={onFinishLease}
      {...props}
    />
  );
};

export default LeaseSummaryContainer;
