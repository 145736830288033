import { useHelloSign } from "shared/hooks";

import { useApplicantSignUrl } from "./api";

export const useRenterHelloSign = ({
  leaseApplicationId,
  enableToFetch,
  onSign,
}) => {
  const {
    signatureData,
    isLoading: isSignatureUrlLoading,
    isFetching: isSignatureUrlFetching,
  } = useApplicantSignUrl(
    { leaseApplicationId },
    { enabled: Boolean(leaseApplicationId) && enableToFetch }
  );

  const isSignatureUrlAvailable = Boolean(signatureData?.signatureUrl);

  const {
    openHelloSign,
    closeHelloSign,
    isLoading: isHelloSignLoading,
  } = useHelloSign({
    actions: { onSign },
  });

  return {
    signatureUrl: signatureData?.signatureUrl,
    isSignatureUrlAvailable,
    isSignatureUrlLoading:
      isSignatureUrlLoading || isSignatureUrlFetching || isHelloSignLoading,
    openManagerHelloSign: openHelloSign,
    closeManagerHelloSign: closeHelloSign,
  };
};
