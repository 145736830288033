import React from "react";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { Col, Row } from "antd";
import SummaryCard from "renter/components/SummaryCard";
import Container from "shared/components/Container";
import LoaderContainer, { BigLoadingSpinner } from "shared/components/LoaderContainer";
import PageTitle from "shared/components/PageTitle";
import { APPLICATION_STATUSES } from "shared/config/constants";
import { useGetApplicantLeads } from "renter/hooks/api";
import { cacheActiveCompany } from "shared/utils/auth";

const LeadsHomePage = () => {
  const { applications, isLoading } = useGetApplicantLeads();

  return (
    <>
      <Helmet>
        <title>Prequalification</title>
      </Helmet>
      <Container noPaddingBottom>
        <PageTitle.Container>
          <PageTitle>Prequalification</PageTitle>
        </PageTitle.Container>
      </Container>
      <Container noPaddingTop noMobilePaddingX>
        <LoaderContainer
          Loader={<BigLoadingSpinner data-testid="loading" />}
          fetching={isLoading}
        >
          <Row gutter={14} type="flex">
            {applications?.map((application) => (
              <Col key={application.id} xs={24} xl={12} xxl={8}>
                <SummaryCard
                  applicationId={application.id}
                  propertyName={application.propertyName}
                  unitName={application.unitName}
                  status={application.status}
                  propertyAddress={application.propertyAddress}
                  otherApplicants={[]}
                  fallbackContent={application.companyName}
                  action={{
                    visible: [
                      APPLICATION_STATUSES.inProgress,
                      APPLICATION_STATUSES.notStarted
                    ].includes(application.status),
                    label: 'Complete',
                    onClick: () => {
                      cacheActiveCompany({
                        id: application.companyId,
                        name: application.companyName,
                      });

                      window.location.href = `${window.location.origin}/applicant/workflow/${application.id}`;
                      return null
                    },
                  }}
                  companySlug={kebabCase(application.companyName)}
                  companyName={application.companyName}
                  dealId={application.deal}
                />
              </Col>
            ))}
          </Row>
        </LoaderContainer>
      </Container>
    </>
  )
};

export default LeadsHomePage;
