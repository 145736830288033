import React from "react";

import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";

import Container from "shared/components/Container";
import { PDFViewer } from "shared/components/DocumentViewer";
import Icon from "shared/components/Icon";
import { BackLink } from "shared/components/Links";
import Status from "shared/components/StatusWithIcon";
import {
  LEASE_STATUS_CODES,
  BREAKPOINT_RESOLUTIONS,
} from "shared/config/constants";

import { LeaseSummaryProps } from "./interfaces";
import LeaseIndividuals from "./LeaseIndividuals";
import LeaseProperty from "./LeaseProperty";
import LeaseTools from "./LeaseTools";
import { LoadingContainer, Note, Header, ScrollWrapper, Title } from "./styled";

const LeaseSummary = ({
  lease,
  loading,
  viewerApi,
  backLink,
  isSignatureUrlAvailable,
  signLease,
}: LeaseSummaryProps) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });
  const isCountersigned = lease?.status === LEASE_STATUS_CODES.managerSigned;
  const isViewOnly =
    lease?.status === LEASE_STATUS_CODES.managerSigned ||
    lease?.status === LEASE_STATUS_CODES.leaseHandledOffline ||
    lease?.status === LEASE_STATUS_CODES.leaseExecutedOffline;

  const pageTitle = isViewOnly ? "View the lease" : "View and sign the lease";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Container noPaddingBottom>
        <Header>
          <Title data-testid="page-title">
            {pageTitle}
            {isCountersigned && <Status>Countersigned</Status>}
          </Title>
          <LeaseIndividuals applicants={lease?.deal?.applicantNames} />
        </Header>
      </Container>
      <Container noPaddingTop noPaddingBottom noMobilePaddingX expand>
        {loading ? (
          <LoadingContainer>
            <Icon.LoadingSpinnerGreenLargeIcon data-testid="loading" />
          </LoadingContainer>
        ) : (
          <>
            <LeaseProperty lease={lease} />
            {!isCountersigned && (
              <Note>
                Please review the lease before signing. If anything appears
                incorrect contact your landlord.
              </Note>
            )}
            <Container noPadding expand>
              <ScrollWrapper>
                <PDFViewer
                  viewerApi={viewerApi}
                  LeftTools={backLink && <BackLink {...backLink} />}
                  RightTools={
                    !isViewOnly && (
                      <LeaseTools
                        isSignatureUrlAvailable={isSignatureUrlAvailable}
                        signLease={signLease}
                      />
                    )
                  }
                  showZoomControls
                  showPageNumber={!isMobile}
                />
              </ScrollWrapper>
            </Container>
          </>
        )}
      </Container>
    </>
  );
};

export default LeaseSummary;
