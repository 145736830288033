import React from "react";

import capitalize from "lodash/capitalize";
import pluralize from "pluralize";
import PropTypes from "prop-types";

import { Checkbox } from "shared/components/Form";
import Icon from "shared/components/Icon";
import Tile from "shared/components/Tile";

import {
  StyledDocument,
  DocumentChecker,
  DocumentThumbnail,
  DocumentDescription,
  DocumentName,
  DocumentPages,
} from "../../styled";
import { isDocumentSelected } from "../../utils";

const DocumentsTile = ({
  title,
  documents,
  toggleDocument,
  finalPackageDocuments,
  previewDocument,
  disabled,
  defaultCheckedState,
  hidePreview,
  ...props
}) => (
  <Tile
    noBorder
    noMarginBottom
    header={{
      title,
      collapsible: true,
      separateActionsStyle: true,
      withoutBorderBottom: true,
    }}
    {...props}
  >
    <Tile.Inner>
      {documents.map((document) => (
        <StyledDocument default key={document.id} data-testid="document-item">
          <DocumentChecker>
            <Checkbox
              id={`${document.id}`}
              defaultCheckedState={defaultCheckedState}
              disabled={disabled}
              checked={
                disabled
                  ? defaultCheckedState
                  : isDocumentSelected(document, finalPackageDocuments)
              }
              onChange={toggleDocument?.(document.id, documents)}
            />
            <DocumentThumbnail
              checked={
                disabled
                  ? defaultCheckedState
                  : isDocumentSelected(document, finalPackageDocuments)
              }
            />
            <DocumentDescription>
              <DocumentName>{document.name}</DocumentName>
              <DocumentPages>
                {`${
                  document.language ? `${capitalize(document.language)}, ` : ""
                }${document.numberOfPages} ${pluralize(
                  "page",
                  document.numberOfPages
                )}`}
              </DocumentPages>
            </DocumentDescription>
          </DocumentChecker>
          {!hidePreview && (
            <div>
              <Icon.ViewDetailsIcon
                onClick={() => previewDocument(document.id)}
                data-testid="preview-document-button"
              />
            </div>
          )}
        </StyledDocument>
      ))}
    </Tile.Inner>
  </Tile>
);

DocumentsTile.propTypes = {
  title: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      numberOfPages: PropTypes.number,
    })
  ),
  finalPackageDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      numberOfPages: PropTypes.number,
    })
  ),
  toggleDocument: PropTypes.func,
  previewDocument: PropTypes.func,
  disabled: PropTypes.bool,
  defaultCheckedState: PropTypes.bool,
  hidePreview: PropTypes.bool,
};

DocumentsTile.defaultProps = {
  documents: [],
  finalPackageDocuments: [],
  toggleDocument: undefined,
  previewDocument: undefined,
  disabled: false,
  defaultCheckedState: false,
  hidePreview: false,
};

export default DocumentsTile;
