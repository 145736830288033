import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";

import { getCachedActiveCompany, getAccessToken } from "./auth";

export const useManageCompany = () => {
  const history = useHistory();

  const manageCompany = () => {
    const currentRoute = history.location.pathname;

    const cachedCompany = getCachedActiveCompany();
    const loggedIn = getAccessToken();

    const redirectToSelectCompany =
      !cachedCompany && loggedIn && currentRoute !== ROUTES.selectCompany;
    if (redirectToSelectCompany) {
      history.push(ROUTES.selectCompany);
    }
  };

  return { manageCompany };
};
