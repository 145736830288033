import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Skeleton from "shared/components/Skeleton";

const TagContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
});

const StyledSkeleton = styled(Skeleton)({
  paddingLeft: "40px",
  paddingRight: "40px",
});

const ApplyLink = styled(ActionLink)({
  marginRight: "12px",
});

const EditGreenIcon = styled(Icon.EditGreenIcon)({
  marginLeft: "7px",
});

export { TagContainer, StyledSkeleton, ApplyLink, EditGreenIcon };
