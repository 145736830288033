import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import DealDetails from "manager/pages/Deals/DealsTable/DealDetails";
import { Link, LinkText } from "manager/pages/Deals/DealsTable/styled";
import ExperianAuthModal from "manager/pages/ExperianAuth/ExperianAuthModal";
import { showCreateLeaseButton } from "manager/utils/deal.util";
import {
  APPLICANT_SOURCES,
  DEAL_UI_STATUS_CODES,
} from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";

const TIMEOUT = 2000;

export const useDealsTable = ({ refetchDeals } = {}) => {
  const history = useHistory();

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [hoverExpandedRowKeys, setHoverExpandedRowKeys] = useState({});
  const { openModalDialog } = useModal();

  const navigateToExperianAuth = () => {
    history.push(transformRoute(ROUTES.experianAuthentication));
  };
  const renderExperianAuthModal = () =>
    openModalDialog(ExperianAuthModal, { submit: navigateToExperianAuth });

  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys((previousValue) => [...previousValue, record.key]);
      if (hoverExpandedRowKeys[record.key]) {
        delete hoverExpandedRowKeys[record.key];
      }
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
    }
  };

  const onMouseLeave = (rowKey, recordKey) => {
    const isCurrentRow =
      rowKey === `${recordKey}-extra-row` || rowKey === String(recordKey);

    if (hoverExpandedRowKeys[recordKey] && !isCurrentRow) {
      clearTimeout(hoverExpandedRowKeys[recordKey]);
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== recordKey));
    }
  };

  const getOtherRowEvent = (record) => {
    const objectOfEvents = {};
    const isExpanded = expandedRowKeys.some((key) => key === record.key);
    if (!isExpanded) {
      objectOfEvents.onMouseEnter = () => {
        const timeout = setTimeout(() => {
          setExpandedRowKeys((previousValue) => [...previousValue, record.key]);
        }, TIMEOUT);
        setHoverExpandedRowKeys({
          ...hoverExpandedRowKeys,
          [record.key]: timeout,
        });
      };
    }
    objectOfEvents.onMouseLeave = (e) => {
      const relatedTargetKey =
        e.relatedTarget.parentElement?.parentElement?.dataset?.rowKey;
      onMouseLeave(relatedTargetKey, record.key);
    };

    return objectOfEvents;
  };

  const getColumnWidth = (width) => {
    const breakpoints = [
      { min: 1920, cols: [300, 250, 250, "auto"] },
      { min: 1620, cols: [265, 220, 200, "auto"] },
      { min: 1280, cols: [230, 200, 170, "auto"] },
      { min: 1098, cols: [200, 200, 150, "auto"] },
      { min: 990, cols: [190, 160, 130, "auto"] },
      { min: 768, cols: [200, 180, 130, "auto"] },
      { min: 570, cols: [190, 170, 130, "auto"] },
    ];

    const foundBreakpoint = breakpoints.find(
      (breakpoint) => width > breakpoint.min
    );

    return foundBreakpoint ? foundBreakpoint.cols : [];
  };

  const getActionLink = (deal, experianPrompt = false) => {
    const dealRoute = transformRoute(ROUTES.deal, {
      id: deal?.id,
    });

    const leaseSummaryRoute = deal?.leaseId
      ? transformRoute(ROUTES.leaseSummary, {
          id: deal.leaseId,
        })
      : "#";

    let to = dealRoute;
    let linkCTA = "View Details";

    if (deal?.dealUiStatus === DEAL_UI_STATUS_CODES.submitted) {
      linkCTA = "Review applications";
    } else if (
      DEAL_UI_STATUS_CODES.approved === deal?.dealUiStatus &&
      !deal.unitListing &&
      deal.applicantSource !== APPLICANT_SOURCES.PMS
    ) {
      linkCTA = "Assign unit";
    } else if (DEAL_UI_STATUS_CODES.cancelled === deal.dealUiStatus) {
      // already set to default
    } else if (showCreateLeaseButton(deal)) {
      linkCTA = "Create lease";
    } else if (deal?.leaseStatus) {
      to = leaseSummaryRoute;
      linkCTA = "Go to lease";
    }

    if (deal) {
      if (experianPrompt)
        return <LinkText onClick={renderExperianAuthModal}>{linkCTA}</LinkText>;
      return <Link to={to}>{linkCTA}</Link>;
    }
    return null;
  };

  const goToDealSummary = (key, experianPrompt = false) => {
    if (experianPrompt) {
      renderExperianAuthModal();
    } else {
      history.push(ROUTES.deal.replace(":id", key));
    }
  };

  const getDealDetails = (record, columnWidth, experianPrompt = false) => {
    const deal = record.rawData;
    return (
      <DealDetails
        onClick={() => goToDealSummary(record.key, experianPrompt)}
        onMouseLeave={(e) => {
          const relatedTargetKey =
            e.relatedTarget.parentElement?.dataset?.rowKey;
          onMouseLeave(relatedTargetKey, record.key);
        }}
        deal={deal}
        columnWidth={columnWidth}
        refetchDeals={refetchDeals}
      />
    );
  };

  return {
    onExpand,
    expandedRowKeys,
    onMouseLeave,
    getOtherRowEvent,
    getColumnWidth,
    getActionLink,
    goToDealSummary,
    getDealDetails,
  };
};
