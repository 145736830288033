export enum InvitationMode {
  existingApplicant,
  newApplicantWithScreening,
  newApplicantWithoutScreening,
}

export interface AddLeaseholderFormValues {
  invitation?: InvitationMode;
  resident?: number;
}
