import isNumber from "lodash/isNumber";

export const buildLeaseSetupDTO = (formValues) => {
  return {
    lease_setup: {
      isElectronicallySigned: formValues.isElectronicallySigned,
      isManagerQuestionsEnabled: formValues.isManagerQuestionsEnabled,
      minLeaseDurationMonths: !isNumber(formValues.minLeaseDurationMonths)
        ? null
        : formValues.minLeaseDurationMonths,
      leaseDurationPolicy: formValues.leaseDurationPolicy,
      leaseDefaultEndDateToEndOfTheMonth:
        formValues.leaseDefaultEndDateToEndOfTheMonth,
    },
    propertyDocumentTemplates:
      formValues.documentTemplates.documentTemplates.map(
        (documentTemplate) => ({
          documentTemplate: {
            id: documentTemplate.id,
          },
          isActive: documentTemplate.isActive,
        })
      ),
    metadata: formValues.fields.reduce((acc, field) => {
      if (field.value !== null) {
        acc.push({
          name: field.name,
          value: field.value,
        });
      }
      return acc;
    }, []),
  };
};
