import React from "react";

import isEmpty from "lodash/isEmpty";

import { Modal } from "shared/components/Modal";

import { Question } from "./interfaces";
import QuestionList from "./QuestionList";
import { Answer } from "./styled";

export interface ApplicationAnswersModalProps {
  propertyAddress?: string;
  questions?: Question[];
}

const ApplicationAnswersModal = ({
  propertyAddress = "N/A",
  questions = [],
}: ApplicationAnswersModalProps) => {
  return (
    <Modal
      title="Review applicants’ answers"
      subtitle={propertyAddress}
      width="auto"
      style={{ maxWidth: 946 }}
      showFooter={false}
    >
      <Modal.Body>
        {isEmpty(questions) ? (
          <Answer>There are no available answers here</Answer>
        ) : (
          <QuestionList questions={questions} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ApplicationAnswersModal;
