import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { SwitchTitle } from "shared/components/Form";

const StyledSwitchTitle = styled(SwitchTitle)`
  & label {
    padding-left: 0px;
  }
`;

const ActionsColumnContainer = styled(FlexContainer)`
  .action-icon:not(:first-of-type),
  span:not(:first-of-type) {
    margin-left: 30px;
  }
`;

const LabelIconContainer = styled(FlexContainer)`
  .extra-info {
    margin-left: 10px;
  }
`;

export { StyledSwitchTitle, ActionsColumnContainer, LabelIconContainer };
