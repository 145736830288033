import React, { Key } from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { useSendLease, useSendLeaseBluemoon } from "manager/hooks/api";
import { transformRoute } from "shared/utils/routing";

import SendLeaseModal from "./SendLeaseModal";

interface SendLeaseModalContainerProps {
  unitName: string;
  propertyName: string;
  leaseId: Key;
  isBluemoonLease: boolean;
}

const SendLeaseModalContainer = ({
  unitName,
  propertyName,
  leaseId,
  isBluemoonLease,
}: SendLeaseModalContainerProps) => {
  const history = useHistory();

  const onSuccess = () =>
    history.push(transformRoute(ROUTES.leaseSummary, { id: leaseId }));

  const { sendLease, isLoading } = useSendLease({
    onSuccess,
    leaseId,
  });

  const { sendLeaseBluemoon, isLoadingBluemoon } = useSendLeaseBluemoon({
    onSuccess,
    leaseId,
  });

  const sendLeaseAction = () =>
    isBluemoonLease ? sendLeaseBluemoon() : sendLease();

  return (
    <SendLeaseModal
      unitName={unitName}
      propertyName={propertyName}
      sendLease={sendLeaseAction}
      isLoading={isLoadingBluemoon || isLoading}
    />
  );
};

export default SendLeaseModalContainer;
