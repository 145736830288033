import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "shared/config/routes";
import { useLogin } from "shared/hooks";
import { useLoginSSO } from "shared/hooks/api";
import {
  composeSSOLogoutUrl,
  extractLoginErrorMessage,
  setSSOLogoutUrl,
} from "shared/utils/auth";
import { decodeBase64ToObject } from "shared/utils/misc.util";
import { getQueryParams } from "shared/utils/routing";

import { ErrorMessage } from "../LoginSelfSignedDeepLinkBouncePage/interfaces";

import {
  LOGIN_SSO_ERROR_CODES,
  LOGIN_SSO_ERROR_DESCRIPTIONS,
} from "./constants";
import LoginSSOBouncePage from "./LoginSSOBouncePage";

interface LoginSSOBouncePageContainerProps {
  redirectAfterSSOLogin?: (history, state) => void;
}

const LoginSSOBouncePageContainer = ({
  redirectAfterSSOLogin,
}: LoginSSOBouncePageContainerProps) => {
  const history = useHistory();
  const { code, state } = getQueryParams();
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  const { loginSSO, isLoading } = useLoginSSO();

  const decodedState = state && decodeBase64ToObject(state);
  const ssoLogoutUrl =
    decodedState?.logoutUrl && composeSSOLogoutUrl(decodedState.logoutUrl);

  const loginCall = async () =>
    loginSSO({
      code,
      name: decodedState?.name,
      type: decodedState?.type,
      linkHash: decodedState?.token,
    });

  const onSuccess = () => {
    if (ssoLogoutUrl) {
      // NOTE: Save the SSO logout url to use it on logout
      setSSOLogoutUrl(ssoLogoutUrl);
    }

    if (redirectAfterSSOLogin) {
      redirectAfterSSOLogin(history, decodedState);
    } else {
      history.replace(ROUTES.home);
    }
  };

  const onError = (error) => {
    const errorObject = extractLoginErrorMessage(
      error,
      LOGIN_SSO_ERROR_CODES,
      "Unable to Log In",
      LOGIN_SSO_ERROR_DESCRIPTIONS
    );
    setErrorMessage(errorObject);
  };

  const { login } = useLogin({
    loginCall,
    onSuccess,
    onError,
  });

  const onBackButtonClick = () => {
    if (ssoLogoutUrl) {
      // NOTE: If the user got an error when logging in with SSO we perform SSO logout that will redirect the user to the login page again
      window.location.href = ssoLogoutUrl;
    } else {
      history.push(ROUTES.login);
    }
  };

  useEffect(() => {
    login();
  }, []);

  return (
    <LoginSSOBouncePage
      isLoading={isLoading}
      errorMessage={errorMessage}
      onBackButtonClick={onBackButtonClick}
    />
  );
};

export default LoginSSOBouncePageContainer;
