import React from "react";

import ApplicationSetupHeader from "manager/components/DealSetupSettings/Header";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Settings from "shared/components/Settings";
import Skeleton from "shared/components/Skeleton";
import Table from "shared/components/Table";
import Tooltip from "shared/components/Tooltip";

import { PortfolioDocumentTemplatesProps } from "../interfaces";

const PortfolioDocumentTemplates = ({
  loading,
  documentTemplates,
  previewDocument,
}: PortfolioDocumentTemplatesProps) => (
  <Settings
    header={
      <Skeleton loading={loading}>
        <ApplicationSetupHeader
          title="Document Templates"
          subTitle="Please manage the list of document templates you’re going to use for this portfolio"
        />
      </Skeleton>
    }
    nodes={[
      {
        key: 1,
        component: (
          <Skeleton loading={loading}>
            <Table
              columns={[
                {
                  title: "Name",
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: "Type",
                  dataIndex: "typeDisplayName",
                  key: "typeDisplayName",
                },
                {
                  title: "Available in Properties",
                  dataIndex: "propertiesCount",
                  key: "propertiesCount",
                },
              ]}
              columnBreakpoints={{
                xs: {
                  column1: "name",
                  column2: "propertiesCount",
                },
                sm: ["name", "propertiesCount", "typeDisplayName"],
                md: ["name", "propertiesCount", "typeDisplayName"],
              }}
              placeholder={{
                Icon: Icon.NoDataIcon,
                label: "Documents",
              }}
              showColumnTitles={false}
              pagination={false}
              noClickableRows
              rows={documentTemplates?.map((document) => {
                return {
                  key: document.id,
                  values: [
                    <UnderlineLink
                      data-testid={`document-setup-name-${document.id}`}
                      onClick={() => previewDocument(document)}
                    >
                      {document.name}
                    </UnderlineLink>,
                    <span data-testid={`document-setup-type-${document.id}`}>
                      {document.typeDisplayName}
                    </span>,
                    <span data-testid={`document-available-${document.id}`}>
                      <Tooltip
                        theme="light"
                        title={
                          document?.propertiesCount > 0 ? (
                            <ul>
                              {document?.properties?.map((property) => (
                                <li>{property}</li>
                              ))}
                            </ul>
                          ) : (
                            "N/A"
                          )
                        }
                      >
                        {document?.propertiesCount > 0 ? (
                          <UnderlineLink>
                            {document.propertiesCount}
                          </UnderlineLink>
                        ) : (
                          "N/A"
                        )}
                      </Tooltip>
                    </span>,
                  ],
                };
              })}
            />
          </Skeleton>
        ),
      },
    ]}
    hasComponentPadding={false}
  />
);

export default PortfolioDocumentTemplates;
