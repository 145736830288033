import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { PaginationConfig } from 'antd/lib/pagination'
import { SorterResult } from 'antd/lib/table'
import { DEAL_CATEGORY_TYPES, DEAL_CATEGORY_LABEL_INFO } from 'manager/config/constants'
import { BREAKPOINT_TYPES, DEAL_UI_STATUS_CODES } from 'shared/config/constants'
import { useDeviceType } from 'shared/hooks'
import { statusToCategoryLabel, categoryToStatus } from 'shared/components/vega/SearchList/utils'
import Container from 'shared/components/Container'
import FilterBar, { DROPDOWN_FILTER_MODES } from "shared/components/FilterBar";
import SwitchLight from "shared/components/Form/SwitchLight/SwitchLight";
import MobileFilters from "shared/components/MobileFilters";
import PageTitle from "shared/components/PageTitle";
import { MenuItem } from 'shared/components/RadioButtons'
import TablePageCardHeader from 'shared/components/TablePageCardHeader'
import { ApplicationList, ApplicantList } from 'shared/components/vega/SearchList'
import { PaginationProps, OrderingProp } from 'shared/interfaces'
import OptionItem from 'shared/interfaces/api/optionItem'
import { ContentContainer } from 'shared/templates/TablePage/styled'
import DealsTable from './DealsTable'
import { Deal, Filters } from './interfaces'
import { FlexContainer, CheckboxContainer } from './styled'

interface DealsProps {
  dealStatuses?: OptionItem[];
  dealCategories?: any;
  leaseStatuses?: OptionItem[];
  deals?: Deal[];
  dealsCount?: number;
  refetchDeals?: () => void;
  filters: Filters;
  hasInputValue: boolean;
  loading: boolean;
  pagination: PaginationProps;
  setPagination: Function;
  updateFilters: (filters?: Filters) => void;
  updatedAllFilters: (filters: Filters) => void;
  onTableChange: (pagination: PaginationConfig, filters: Filters, sorter: SorterResult<Filters>) => void;
  menuItems: MenuItem[];
  activeItem: string;
  initialOrderingColumn: OrderingProp;
  isFiltersLoading: boolean;
  isDealsCountsLoading: boolean;
  onShowArchivedCheckboxChange: () => void;
  showArchived: boolean;
  onShowOnlyPaidFeesCheckboxChange: () => void;
  showOnlyPaidFees: boolean;
  disabledSearch: boolean;
  disabledFilters: boolean;
  propertySelection: { key: string; label: string }[];
  dealStatusSelection: { value: string; key: string; label: string }[];
  lumenResultSelection: { key: string; label: string }[];
  changeCategory: (value: string) => void;
  experianPrompt?: boolean;
  enableSearchService?: boolean;
  showSearchServiceResults?: boolean;
  setShowSearchServiceResults?: any;
}

const Deals = ({
  dealStatuses = [],
  dealCategories,
  deals = [],
  dealsCount = 0,
  refetchDeals,
  filters,
  updateFilters,
  updatedAllFilters,
  pagination,
  setPagination,
  hasInputValue,
  loading,
  onTableChange,
  leaseStatuses = [],
  menuItems,
  activeItem,
  initialOrderingColumn,
  isFiltersLoading,
  isDealsCountsLoading,
  onShowArchivedCheckboxChange,
  showArchived,
  onShowOnlyPaidFeesCheckboxChange,
  showOnlyPaidFees,
  disabledSearch,
  disabledFilters,
  propertySelection,
  dealStatusSelection,
  lumenResultSelection,
  changeCategory,
  experianPrompt,
  enableSearchService,
  showSearchServiceResults,
  setShowSearchServiceResults
}: DealsProps) => {
  const { isMobile } = useDeviceType()

  const [showApplicants, setShowApplicants] = useState(false)
  const searchServiceEnabled = enableSearchService && showSearchServiceResults

  const filterBar = {
    title: "Filter Applications",
    search: {
      action: (value: string) =>
        updateFilters({ unitPropertyOrApplicant: value }),
      placeholder: "Search in applications",
      width: 225,
      debounce: true,
      disabled: disabledSearch,
      value: filters.unitPropertyOrApplicant,
    },
    dropdownFilters: [
      {
        key: "propertyId",
        label: "Properties",
        value: filters.propertyId,
        onChange: (value: string[]) => updateFilters({ propertyId: value }),
        items: propertySelection,
        mode: DROPDOWN_FILTER_MODES.multiselect,
        minWidth: 200,
        disabled: disabledFilters,
      },
      {
        key: "lastLumenResults",
        label: "Lumen Results",
        value: filters.lastLumenResult,
        onChange: (value: string[]) =>
          updateFilters({ lastLumenResult: value }),
        items: lumenResultSelection,
        mode: DROPDOWN_FILTER_MODES.multiselect,
        minWidth: 200,
        disabled: disabledFilters,
      },
      {
        key: "dealStatus",
        label: "Application statuses",
        value: filters.dealStatus,
        onChange: (value: DEAL_UI_STATUS_CODES[]) =>
          updateFilters({ dealStatus: value }),
        items: dealStatusSelection,
        mode: DROPDOWN_FILTER_MODES.multiselect,
        order: 1,
        disabled: disabledFilters,
        minWidth: 200,
        showOnEnd: true,
      },
    ],
    loadingDropdownOptions: isFiltersLoading,
    dateRange: {
      label: "Application Date",
      date1Id: "dateMin",
      date2Id: "dateMax",
      setValues: updateFilters,
      fromValue: filters.dateMin,
      toValue: filters.dateMax,
      disabled: disabledFilters,
    },
    setFilters: (filtersValue: Filters) => updatedAllFilters(filtersValue),
    clearFilters: {
      onClick: () => updateFilters(),
      order: 2,
    },
    breakpointFilterPopover: [
      BREAKPOINT_TYPES.sm,
      BREAKPOINT_TYPES.md,
      BREAKPOINT_TYPES.lg,
      BREAKPOINT_TYPES.xl,
    ],
  }

  return (
    <>
      <Helmet>
        <title>Applications</title>
      </Helmet>
      {!searchServiceEnabled && isMobile && (
        <Container noPaddingBottom>
          <FlexContainer justifyContent="space-between" flexWrap="wrap">
            <PageTitle>Applications</PageTitle>
            <MobileFilters {...filterBar} />
          </FlexContainer>
        </Container>
      )}
      <Container noMobilePaddingX noMobilePaddingY>
        {!searchServiceEnabled && <TablePageCardHeader
          title='Applications'
          categoryItems={menuItems}
          activeItem={activeItem}
          categoryTypes={DEAL_CATEGORY_TYPES}
          categoryLabelInfo={DEAL_CATEGORY_LABEL_INFO}
          isCountsLoading={isDealsCountsLoading}
          onChange={changeCategory}
        />}

        <ContentContainer>
          {!searchServiceEnabled && <FilterBar {...filterBar} />}

          <CheckboxContainer data-testid='archived-checkbox'>
            {!searchServiceEnabled && <SwitchLight
              id='show-archived-applications'
              value={showArchived}
              onChange={onShowArchivedCheckboxChange}
              title='Include archived'
              noPadding
            />}

            {!searchServiceEnabled && <SwitchLight
              id='show-only-paid-fees'
              value={showOnlyPaidFees}
              onChange={() => {
                onShowOnlyPaidFeesCheckboxChange()
              }}
              title='Show paid only'
              noPadding
            />}

            {enableSearchService && <SwitchLight
              id='show-search-service-results'
              value={showSearchServiceResults}
              onChange={() => {
                setShowSearchServiceResults(!showSearchServiceResults)
              }}
              title='Use Search Service'
              noPadding
            />}

            {searchServiceEnabled && <SwitchLight
              id='show-applicants-search-service-results'
              value={showApplicants}
              onChange={() => {
                setShowApplicants(!showApplicants)
              }}
              title='Show Applicant List'
              noPadding
            />}
          </CheckboxContainer>

          {!loading && searchServiceEnabled && !showApplicants && <ApplicationList
            categories={dealCategories}
            categoryToStatus={category => categoryToStatus(category, dealCategories)}
            statusToCategoryLabel={status => statusToCategoryLabel(status, dealCategories)}
          />}

          {!loading && searchServiceEnabled && showApplicants && <ApplicantList
            statusToCategoryLabel={(status) => statusToCategoryLabel(status, dealCategories)}
          />}

          {!searchServiceEnabled && <DealsTable
            hasInputValue={hasInputValue}
            setPagination={setPagination}
            pagination={pagination}
            deals={deals}
            dealsCount={dealsCount}
            dealStatuses={dealStatuses}
            loading={loading}
            onTableChange={onTableChange}
            leaseStatuses={leaseStatuses}
            initialOrderingColumn={initialOrderingColumn}
            refetchDeals={refetchDeals}
            experianPrompt={experianPrompt}
          />}
        </ContentContainer>
      </Container>
    </>
  )
}

export default Deals
