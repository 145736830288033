import React, { useEffect, useRef } from "react";

import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { DownloadLeaseModal, SendLeaseModal } from "manager/components/Modal";
import ROUTES from "manager/config/routes";
import {
  useLeasePreview,
  useLeaseAssignedDocuments,
  usePropertyDocuments,
  usePropertyLeaseSetup,
  useHandleLeaseOffline,
  useSetLeaseInProgress,
  useCountersignBluemoonLeaseQuery,
} from "manager/hooks/api";
import { usePdfJs } from "shared/components/DocumentViewer";
import { LOCAL_STORAGE, LEASE_STATUS_CODES } from "shared/config/constants";
import { useActiveUser } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";
import { LocalStorageCollectionManager } from "shared/utils/localStorage";
import { fileNameFromWords } from "shared/utils/pdf";

import { transformRoute } from "shared/utils/routing";

import BluemoonCounterSignModal from "./BluemoonCounterSignModal";
import LeasePreviewStep from "./LeasePreviewStep";

const LeasePreviewStepContainer = ({
  lease,
  currentStep,
  stepBack,
  redirectBack,
}) => {
  const history = useHistory();
  const { openModalDialog } = useModal();
  const propertyId = lease.deal.unit.property.id;
  const propertyName = lease.deal.unit.property.name;
  const propertyAddress = lease.deal.unit.property.address;
  const unitName = lease.deal.unit.name;
  const applicantLastName = lease.deal.applicantNames[0].lastName;
  const isSignedOffline =
    lease.status === LEASE_STATUS_CODES.leaseHandledOffline;
  const filename = fileNameFromWords({
    words: [propertyName, unitName, applicantLastName],
    timestamp: true,
  });
  const { isBluemoonLease } = lease;
  const pdfApi = usePdfJs();
  const managerName = lease?.finalDocumentGeneratedBy;
  const { activeUser } = useActiveUser();
  const storageManager = useRef();
  const { handleLeaseOffline, isLoading: isSigningOffline } =
    useHandleLeaseOffline(lease.id);

  const { setLeaseInProgress, isLoading: isSettingInProgress } =
    useSetLeaseInProgress(lease.id);

  const { isLoading: isLeaseDocumentsLoading, data: leaseDocuments } =
    useLeaseAssignedDocuments(lease.id);

  const { isLoading: isDefaultsLoading, data: documents } =
    usePropertyDocuments({ id: propertyId });

  const { isLoading: isLeaseSetupLoading, data: leaseSetup } =
    usePropertyLeaseSetup(propertyId);

  const defaultDocuments = documents?.documentTemplates?.filter(
    (document) => document.isDefault
  );

  const { countersignBlueemoonLease, isLoading: isBluemoonCountersignLoading } =
    useCountersignBluemoonLeaseQuery(lease.id);

  const finalPackage = defaultDocuments &&
    leaseDocuments && [...defaultDocuments, ...leaseDocuments];

  const finalDocumentTemplates = finalPackage?.map(({ id, fields }) => ({
    documentTemplateId: id,
    fields: fields.map(({ id: fieldId }) => ({ id: fieldId })),
  }));

  const { isLoading: isLeasePreviewLoading, data: base64Document } =
    useLeasePreview(
      { leaseId: lease.id },
      { enabled: Boolean(finalDocumentTemplates) }
    );

  const signLeaseOffline = async () => {
    if (!isSignedOffline) {
      await handleLeaseOffline(lease.id);
    }
  };

  const signLeaseOnline = async () => {
    if (isSignedOffline) {
      await setLeaseInProgress(lease.id);
    }
  };

  const cacheESigning = (eSigning) => {
    storageManager.current.addItem(lease.id, eSigning);
  };

  const isLoading =
    isLeaseDocumentsLoading ||
    isDefaultsLoading ||
    isLeasePreviewLoading ||
    isLeaseSetupLoading ||
    isBluemoonCountersignLoading;

  const handleESigningDownload = async () => {
    const isESigningCached = storageManager.current.has(lease.id);
    const eSigning = storageManager.current.getItem(lease.id);

    if (isESigningCached) {
      if (eSigning) {
        await signLeaseOnline();
      } else {
        await signLeaseOffline();
      }

      pdfApi.downloadDocument();
    } else {
      openModalDialog(DownloadLeaseModal, {
        propertyName,
        unitName,
        propertyAddress,
        leaseId: lease.id,
        downloadLease: pdfApi.downloadDocument,
        cacheESigning,
        isSignedOffline,
      });
    }
  };

  const downloadLease = async () => {
    if (leaseSetup?.isElectronicallySigned) {
      await handleESigningDownload();
    } else {
      await signLeaseOffline();
      pdfApi.downloadDocument();
      history.push(
        transformRoute(ROUTES.leaseSummary, {
          id: lease.id,
        })
      );
    }
  };

  const sendLease = () =>
    openModalDialog(SendLeaseModal, {
      propertyName,
      unitName,
      dealId: lease.deal.id,
      leaseId: lease.id,
      isBluemoonLease: lease.isBluemoonLease,
    });

  const counterSignBluemoonLease = () =>
    openModalDialog(BluemoonCounterSignModal, {
      manager: managerName,
      submit: countersignBlueemoonLease,
      isLoading: isBluemoonCountersignLoading,
    });

  useEffect(() => {
    if (pdfApi.isInitialized && base64Document) {
      pdfApi.loadDocument({ base64: base64Document, filename });
    }
  }, [pdfApi.isInitialized, base64Document]);

  useEffect(() => {
    if (activeUser?.id && lease.id) {
      storageManager.current = new LocalStorageCollectionManager({
        storageKey: `${LOCAL_STORAGE.notAskLeaseDownload}_${activeUser?.id}`,
      });
    }
  }, [activeUser?.id, lease.id]);

  return (
    <LeasePreviewStep
      stepBack={stepBack}
      lease={lease}
      status={lease?.status}
      currentStep={currentStep}
      loading={isLoading}
      sendLease={sendLease}
      isESigningEnabled={leaseSetup?.isElectronicallySigned}
      redirectBack={redirectBack}
      downloadLease={downloadLease}
      isUpdatingStatus={isSigningOffline || isSettingInProgress}
      pdfApi={pdfApi}
      isBluemoonLease={isBluemoonLease}
      counterSignBluemoonLease={counterSignBluemoonLease}
    />
  );
};

LeasePreviewStepContainer.propTypes = {
  redirectBack: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  lease: PropTypes.shape({
    deal: PropTypes.shape({
      id: PropTypes.number,
      unit: PropTypes.shape({
        name: PropTypes.string,
        property: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          address: PropTypes.string.isRequired,
        }),
      }),
      applicantNames: PropTypes.arrayOf(
        PropTypes.shape({
          lastName: PropTypes.string.isRequired,
        })
      ),
    }).isRequired,
    id: PropTypes.number,
    step: PropTypes.number,
    isBluemoonLease: PropTypes.bool,
    finalDocumentGeneratedBy: PropTypes.string,
    status: PropTypes.string.isRequired,
  }).isRequired,
  stepBack: PropTypes.func.isRequired,
};

export default LeasePreviewStepContainer;
