import React from "react";

import useModal from "shared/hooks/useModal";

import BluemoonCounterSignModal from "./BluemoonCounterSignModal";

const BluemoonCounterSignModalContainer = () => {
  const { currentModalContext } = useModal();
  const { managerName, submit, isLoading } = currentModalContext;

  return (
    <BluemoonCounterSignModal
      managerName={managerName}
      isLoading={isLoading}
      submit={submit}
    />
  );
};

export default BluemoonCounterSignModalContainer;
