import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import FooterBar from "shared/components/FooterBar";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { AlignedCenterFlexPartial } from "shared/styles/commonStyles";

const EXTRA_MARGIN = 20;

export const TitleContainer = styled.div`
  margin-bottom: 30px;
`;

export const OfferOptionRow = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  min-height: 120px;
  padding: 0 24px 22px;
  background-color: ${COLORS.lightGray2};

  :not(:last-of-type) {
    margin-bottom: 8px;
  }

  > div {
    margin: 0 8px;
  }

  .label-col {
    ${AlignedCenterFlexPartial};
    min-width: 95px;
    margin-top: ${EXTRA_MARGIN}px;
  }

  .input-col {
    width: 210px;
    min-height: 40px;
    margin: ${EXTRA_MARGIN}px 24px 0 0;
  }

  .input-col span.error-message {
    position: absolute;
  }

  .action-icon {
    margin-top: ${EXTRA_MARGIN}px;
    min-width: 24px;
  }

  .option-label-and-input {
    display: flex;
    flex: 1;
  }

  ${MEDIA_QUERIES.w.ltSm} {
    .option-label-and-input {
      flex-direction: column;
    }
  }
`;

export const OfferOptionsActionsContainer = styled(FlexContainer)`
  align-items: center;
  > * {
    &:not(:first-child) {
      margin-left: 40px;
    }
  }
`;

export const OfferFootBarContent = styled(FooterBar)`
  justify-content: flex-end;
  align-items: center;

  > * {
    &:not(:first-child) {
      margin-left: 24px;
    }
  }
`;
