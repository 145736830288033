import React from "react";

import { CheckboxChangeEvent } from "antd/lib/checkbox";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import get from "lodash/get";

import FirstMonthRentProrationInfo from "manager/components/Leasing/FirstMonthRentProrationInfo";
import {
  useCalculateNetEffectiveRent,
  useGetValidConcessionMonths,
} from "manager/hooks/api";
import { SecurityDepositDeductionStatus } from "manager/interfaces/types";
import useLeaseFirstMonthRentCalculation from "manager/pages/Leasing/LeaseBuilder/useLeaseFirstMonthRentCalculation";
import InformationModal from "shared/components/Modals/InformationModal";
import { SmallText } from "shared/components/Typography";
import {
  CONCESSION_TYPES,
  YEAR_MONTH_DATE_FORMAT,
} from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import {
  calculateDurationBetweenMonthsNormalized,
  isFirstDayOfMonth,
} from "shared/utils/dates";

import { MAXIMUM_LEASE_DURATION } from "../LeaseInfoStep.constants";
import {
  calculateLeaseEndDate,
  getInitialValues,
  getMinLeaseDurationDate,
} from "../LeaseInfoStep.utils";

import { LeaseInfoFormValues } from "./interfaces";
import LeaseInfoForm from "./LeaseInfoForm";
import useSecurityDepositBalance from "./useSecurityDepositBalance";

interface LeaseInfoFormContainerProps {
  disabledLeaseInfoFields: object;
  isProRateEnabled: boolean;
  leaseSecurityDepositStatus: SecurityDepositDeductionStatus;
  leaseId: number;
}

const LeaseInfoFormContainer = ({
  disabledLeaseInfoFields,
  isProRateEnabled,
  leaseSecurityDepositStatus,
  leaseId,
}: LeaseInfoFormContainerProps) => {
  const { values, setFieldValue, setValues } =
    useFormikContext<LeaseInfoFormValues>();
  const { openModalDialog } = useModal();

  const {
    leaseStartDate,
    leaseEndDate,
    isConcessionsEnabled,
    numberOfMonths,
    monthlyRent,
    endDateToEndOfTheMonth,
    prorateFirstMonthRent,
    securityDeposit,
  } = values;

  const leaseFirstMonthRent = useLeaseFirstMonthRentCalculation({
    leaseStart: leaseStartDate,
    rent: monthlyRent,
    prorateFirstMonthRent,
  });

  const { securityDepositBalance } = useSecurityDepositBalance({
    leaseId,
    securityDeposit,
    leaseSecurityDepositStatus,
  });

  const { concessionMonths, isConcessionMonthsLoading } =
    useGetValidConcessionMonths(
      {
        leaseStart: leaseStartDate,
        leaseEnd: leaseEndDate,
      },
      {
        enabled: isConcessionsEnabled,
      }
    );

  const { netEffectiveRent } = useCalculateNetEffectiveRent(
    {
      leaseStart: leaseStartDate,
      leaseEnd: leaseEndDate,
      rent: monthlyRent,
      numberOfConcessionMonths: numberOfMonths,
    },
    {
      enabled: isConcessionsEnabled,
    }
  );

  const onLeaseDurationChange = (value: number) => {
    const endDate = calculateLeaseEndDate(
      leaseStartDate,
      value,
      endDateToEndOfTheMonth
    );

    setValues({
      ...values,
      leaseEndDate: endDate,
      leaseDuration: value,
    });
  };

  const onEndDateToEndOfTheMonthChange = (event: CheckboxChangeEvent) => {
    const { checked } = event.target;
    const endDate = leaseEndDate
      ? dayjs(leaseEndDate).endOf("month").format(YEAR_MONTH_DATE_FORMAT)
      : undefined;
    const leaseDuration = endDate
      ? calculateDurationBetweenMonthsNormalized(leaseStartDate, endDate)
      : undefined;

    setValues({
      ...values,
      leaseDuration:
        leaseDuration && leaseDuration <= MAXIMUM_LEASE_DURATION
          ? leaseDuration
          : undefined,
      leaseEndDate: endDate,
      endDateToEndOfTheMonth: checked,
    });
  };

  const openProRateInfoModal = () =>
    openModalDialog(InformationModal, {
      title: "First Month’s Rent",
      subtitle: (
        <SmallText light extraStrong>
          Prorated Calculation
        </SmallText>
      ),
      description: <FirstMonthRentProrationInfo />,
    });

  const isTypeAmortized = values.concessionType === CONCESSION_TYPES.amortize;
  const isTypeSpecificMonths =
    values.concessionType === CONCESSION_TYPES.specificMonths;

  const minMonths = values.minLeaseDurationMonths || 1;
  const maxYears = 30;

  const disablePastDate = (date: dayjs.Dayjs): boolean =>
    dayjs().isAfter(date, "day");
  const disableDate = (date: dayjs.Dayjs): boolean => {
    const minLimit = getMinLeaseDurationDate(values.leaseStartDate, minMonths);
    const maxLimit = dayjs(values.leaseStartDate).add(maxYears, "years");

    return (
      values.leaseStartDate &&
      (dayjs(date).isBefore(minLimit) || dayjs(date).isAfter(maxLimit))
    );
  };

  const onSelectStartDate = (name: string, value: dayjs.Dayjs) =>
    setValues({
      ...values,
      [name]: value,
      leaseEndDate: getInitialValues("leaseEndDate"),
      leaseDuration: undefined,
      numberOfMonths: getInitialValues("numberOfMonths"),
      dates: getInitialValues("dates"),
      ...(isFirstDayOfMonth(value) && {
        prorateFirstMonthRent: false,
      }),
    });

  const onSelectEndDate = (name: string, value: dayjs.Dayjs) => {
    const leaseDuration = calculateDurationBetweenMonthsNormalized(
      values.leaseStartDate,
      value
    );

    setValues({
      ...values,
      [name]: value,
      leaseDuration:
        leaseDuration && leaseDuration <= MAXIMUM_LEASE_DURATION
          ? leaseDuration
          : undefined,
      numberOfMonths: getInitialValues("numberOfMonths"),
      dates: getInitialValues("dates"),
    });
  };

  const onConcessionFlagChange = (value) => {
    setValues({
      ...values,
      isConcessionsEnabled: Boolean(value),
      concessionType: getInitialValues("concessionType"),
      numberOfMonths: getInitialValues("numberOfMonths"),
      dates: getInitialValues("dates"),
    });
  };

  const onConcessionTypeChange = (value) => {
    setValues({
      ...values,
      concessionType: value,
      numberOfMonths: getInitialValues("numberOfMonths"),
      dates: getInitialValues("dates"),
    });
  };

  const onAfterMonthlyRentChanged = (_e, _maskedRent, unmaskedRent) => {
    if (get(disabledLeaseInfoFields, "securityDepositBoundToRent")) {
      setFieldValue("securityDeposit", unmaskedRent, false);
    }
  };

  return (
    <LeaseInfoForm
      disabledLeaseInfoFields={disabledLeaseInfoFields}
      concessionMonths={concessionMonths}
      isConcessionMonthsLoading={isConcessionMonthsLoading}
      netEffectiveRent={netEffectiveRent}
      onLeaseDurationChange={onLeaseDurationChange}
      onEndDateToEndOfTheMonthChange={onEndDateToEndOfTheMonthChange}
      leaseSecurityDepositStatus={leaseSecurityDepositStatus}
      securityDepositBalance={securityDepositBalance}
      openProRateInfoModal={openProRateInfoModal}
      isProRateEnabled={isProRateEnabled}
      leaseFirstMonthRent={leaseFirstMonthRent}
      onSelectStartDate={onSelectStartDate}
      onSelectEndDate={onSelectEndDate}
      isTypeAmortized={isTypeAmortized}
      isTypeSpecificMonths={isTypeSpecificMonths}
      onConcessionFlagChange={onConcessionFlagChange}
      onConcessionTypeChange={onConcessionTypeChange}
      disablePastDate={disablePastDate}
      disableDate={disableDate}
      minMonths={minMonths}
      maxYears={maxYears}
      onAfterMonthlyRentChanged={onAfterMonthlyRentChanged}
    />
  );
};

export default LeaseInfoFormContainer;
