import React from "react";

import { Col, Row } from "antd";

import { LeaseGroupApplication } from "manager/interfaces/api/leaseGroups";
import DetectableOverflowTooltip from "shared/components/DetectableOverflowTooltip";
import DropdownMenu from "shared/components/DropdownMenu";
import { DropdownMenuItem } from "shared/components/DropdownMenu/interfaces";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { IconLink, UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { RegularText, SmallText } from "shared/components/Typography";
import Well from "shared/components/Well";
import { getFullnameData } from "shared/utils/applicant";

import { StyledLeaseGroupResidents } from "../styled";
import { getResidentStatus } from "../utils";
import { ResidentsDropdown } from "../utils/useGetLeaseResidentsDropdown";

const colSize = {
  lg: 24,
  xl: 12,
  xxl: 8,
};

const ResidentDetails = ({
  resident,
  applicantType,
  actions = [],
}: {
  resident: LeaseGroupApplication;
  applicantType: string;
  actions?: DropdownMenuItem[];
}) => {
  const fullName = getFullnameData(resident).fullNameOrNA;
  const status = getResidentStatus(resident);

  return (
    <Well noBorder key={resident.id} className="resident-item">
      <FlexContainer justifyContent="space-between" alignItems="center">
        <Row
          className="resident-details"
          gutter={[12, 12]}
          type="flex"
          align="middle"
          justify="start"
        >
          <Col {...colSize}>
            <div>
              <DetectableOverflowTooltip title={fullName}>
                <RegularText>{fullName}</RegularText>
              </DetectableOverflowTooltip>

              <Spacer size={Spacer.SIZES.xs} />
              <SmallText>{applicantType}</SmallText>
            </div>
          </Col>
          <Col {...colSize}>
            <DetectableOverflowTooltip title={resident.email}>
              <RegularText>{resident.email}</RegularText>
            </DetectableOverflowTooltip>
          </Col>
          <Col {...colSize}>
            {status ? (
              <FlexContainer alignItems="center">
                {status.Icon}
                <RegularText className={`resident-status ${status.className}`}>
                  {status.title}
                </RegularText>
              </FlexContainer>
            ) : null}
          </Col>
        </Row>
        <DropdownMenu placement="bottomRight" items={actions} />
      </FlexContainer>
    </Well>
  );
};

interface LeaseGroupResidentsProps {
  applicantsGuarantors: LeaseGroupApplication[];
  occupants: LeaseGroupApplication[];
  applicantTypes: any;
  residentsActions: ResidentsDropdown;
  contactAll: () => void;
  inviteRenewalResident?: () => void;
}

const LeaseGroupResidents = ({
  applicantsGuarantors,
  occupants,
  applicantTypes,
  residentsActions,
  contactAll,
  inviteRenewalResident,
}: LeaseGroupResidentsProps) => {
  const hasApplicants =
    applicantsGuarantors?.length > 0 || occupants?.length > 0;
  return (
    <StyledLeaseGroupResidents noBorder noMarginBottom>
      <Tile.Inner>
        <FlexContainer alignItems="center">
          <SmallText extraStrong className="leaseholders-title">
            LEASEHOLDERS({applicantsGuarantors?.length})
          </SmallText>
          {inviteRenewalResident && (
            <IconLink
              Icon={Icon.ApplicantInvite}
              className="invite-icon"
              onClick={inviteRenewalResident}
            />
          )}
          {hasApplicants && (
            <UnderlineLink onClick={contactAll}>Contact</UnderlineLink>
          )}
        </FlexContainer>
        <Spacer />
        {applicantsGuarantors?.map((resident) => (
          <ResidentDetails
            key={resident.id}
            resident={resident}
            applicantType={applicantTypes?.[resident.applicantType]?.value}
            actions={residentsActions[resident.id]}
          />
        ))}
        <Spacer />
        <div>
          <SmallText extraStrong>OCCUPANTS({occupants?.length})</SmallText>
          <Spacer />
          {occupants?.map((resident) => (
            <ResidentDetails
              key={resident.id}
              resident={resident}
              applicantType={applicantTypes?.[resident.applicantType]?.value}
              actions={residentsActions[resident.id]}
            />
          ))}
        </div>
      </Tile.Inner>
    </StyledLeaseGroupResidents>
  );
};

export default LeaseGroupResidents;
