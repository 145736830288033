import { queryCache, useMutation, useQuery } from "react-query";

import documentsApi from "manager/lib/documentsApi";
import leaseApi from "manager/lib/leaseApi";
import leaseSetupApi from "manager/lib/leaseSetupApi";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_MANAGER_QUESTIONS_KEY = "getLeasePackagesManagerQuestions";
export const GET_APPLICANT_QUESTIONS_KEY = "getLeasePackagesApplicantQuestions";
const GET_COMPANY_DOCUMENT_TEMPLATES = "getCompanyDocumentTemplates";

export const LEASE_SETUP_REQUEST_KEYS = Object.freeze({
  getLeasePackagesManagerQuestions: (propertyId) => [
    GET_MANAGER_QUESTIONS_KEY,
    propertyId,
  ],
  getLeasePackagesApplicantQuestions: (propertyId) => [
    GET_APPLICANT_QUESTIONS_KEY,
    propertyId,
  ],
  getCompanyDocumentTemplates: () => [GET_COMPANY_DOCUMENT_TEMPLATES],
});

export const useGetCompanyLeaseSetup = () => {
  const {
    data: leaseSetup,
    isLoading,
    refetch: refetchLeaseSetup,
  } = useQuery("getCompanyLeaseSetup", () => leaseApi.getCompanyLeaseSetup(), {
    onError: () => {
      openNotification("Failed to load lease setup.", NOTIFICATIONS.error);
    },
  });

  const {
    data: documentTemplates,
    isDocumentTemplatesLoading,
    refetch: refetchDocumentTemplates,
  } = useQuery(
    GET_COMPANY_DOCUMENT_TEMPLATES,
    documentsApi.getCompanyDocumentTemplates,
    {
      onError: () => {
        openNotification(
          "Failed to load document templates.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    leaseSetup: {
      ...leaseSetup,
      documentTemplates,
    },
    isLoading: isLoading || isDocumentTemplatesLoading,
    refetchLeaseSetup: () => {
      refetchLeaseSetup();
      refetchDocumentTemplates();
    },
  };
};

export const useUpdateCompanyLeaseSetup = (refetchLeaseSetup) => {
  const [mutate, status] = useMutation(leaseApi.updateCompanyLeaseSetup, {
    onSuccess: (leaseSetup) => {
      if (leaseSetup.id) {
        openNotification(
          `Successfully updated company lease setup.`,
          NOTIFICATIONS.info
        );
        refetchLeaseSetup();
      } else {
        openNotification(
          "Failed to update company lease setup.",
          NOTIFICATIONS.error
        );
      }
    },
    onError: () => {
      openNotification(
        "Failed to update company lease setup.",
        NOTIFICATIONS.error
      );
    },
  });

  return {
    updateLeaseSetup: mutate,
    isLoading: status.isLoading,
  };
};

export const useUpdateCompanyDocumentsOrdering = () => {
  const [mutate, response] = useMutation(
    (values) =>
      documentsApi
        .updateCompanyDocumentTemplatesOrdering(values)
        .then(({ data }) => data),
    {
      onSuccess: () => {
        queryCache.invalidateQueries([GET_COMPANY_DOCUMENT_TEMPLATES]);
      },
      onError: () => {
        openNotification(
          "Failed to update the company documents ordering.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    updateDocumentOrdering: mutate,
    isLoading: response.isLoading,
  };
};

export const useResetOrderingForProperties = () => {
  const [mutate, response] = useMutation(
    documentsApi.resetOrderingForProperties,
    {
      onSuccess: () => {
        openNotification(
          `Successfully applied the company documents ordering to all properties.`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        openNotification(
          "Failed to apply the company documents ordering.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    resetOrderingForProperty: mutate,
    isLoadingReset: response.isLoading,
  };
};

export const useUpdateRiderType = () => {
  const [mutate, response] = useMutation(
    (params) => documentsApi.updateRiderType(params).then(({ data }) => data),
    {
      onSuccess: () => {
        queryCache.invalidateQueries([GET_COMPANY_DOCUMENT_TEMPLATES]);
        openNotification(
          `Successfully updated the rider type.`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        openNotification(
          "Failed to update the rider type.",
          NOTIFICATIONS.error
        );
      },
    }
  );
  return {
    updateRiderType: mutate,
    isLoading: response.isLoading,
  };
};

export const useGetLeasePackagesManagerQuestions = (
  propertyId,
  config = { enabled: true }
) => {
  const { data: managerQuestions, isLoading: isManagerQuestionsLoading } =
    useQuery(
      LEASE_SETUP_REQUEST_KEYS.getLeasePackagesManagerQuestions(propertyId),
      () => leaseSetupApi.getLeasePackagesManagerQuestions(propertyId),
      {
        enabled: Boolean(propertyId) && config.enabled,
        onError: () => {
          openNotification(
            `Failed to load manager questions.`,
            NOTIFICATIONS.error
          );
        },
      }
    );
  return {
    managerQuestions,
    isManagerQuestionsLoading,
  };
};

export const useGetLeasePackagesApplicantQuestions = (propertyId) => {
  const { data: applicantQuestions, isLoading: isApplicantQuestionsLoading } =
    useQuery(
      LEASE_SETUP_REQUEST_KEYS.getLeasePackagesApplicantQuestions(propertyId),
      () => leaseSetupApi.getLeasePackagesApplicantQuestions(propertyId),
      {
        enabled: Boolean(propertyId),
        onError: () => {
          openNotification(
            `Failed to load applicant questions.`,
            NOTIFICATIONS.error
          );
        },
      }
    );
  return {
    applicantQuestions,
    isApplicantQuestionsLoading,
  };
};

export const useUpsertApplicantQuestion = (propertyId) => {
  const [mutate, response] = useMutation(
    (values) => leaseSetupApi.upsertApplicantQuestion(values),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(
          LEASE_SETUP_REQUEST_KEYS.getLeasePackagesApplicantQuestions(
            propertyId
          )
        );
        openNotification(
          `The applicant question has been successfully updated.`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        openNotification(
          "Failed to save changes for applicant question.",
          NOTIFICATIONS.error
        );
      },
    }
  );
  return {
    upsertApplicantQuestion: mutate,
    isLoading: response.isLoading,
  };
};

export const useUpsertManagerQuestion = () => {
  const [mutate, response] = useMutation(
    (payload) => leaseSetupApi.upsertManagerQuestion(payload),
    {
      onSuccess: () =>
        queryCache.invalidateQueries(["getLeasePackagesManagerQuestions"]),
      onError: () =>
        openNotification(
          "Failed to save changes for manager question.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    upsertManagerQuestion: mutate,
    isUpsertManagerQuestionLoading: response.isLoading,
  };
};

export const useDeleteLeaseQuestion = () => {
  const [mutate, status] = useMutation(
    (id) => leaseSetupApi.deleteLeaseQuestion(id),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["getLeasePackagesManagerQuestions"]);
        openNotification(
          `The lease question has been successfully deleted`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        openNotification(
          "Failed to delete the lease question.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    deleteLeaseQuestion: mutate,
    isDeleteLoading: status.isLoading,
  };
};
