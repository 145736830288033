import styled from "@emotion/styled";

import Tile from "shared/components/Tile";

const PropertyContainer = styled(Tile)`
  display: flex;
  flex-direction: row;
`;

export { PropertyContainer };
