import React, { useRef } from "react";

import dayjs from "dayjs";
import { FormikProps } from "formik";
import { useHistory, useParams } from "react-router-dom";

import Layout from "manager/components/Layout";
import {
  RenewalOfferOptionConcessionModal,
  useRenewalActions,
} from "manager/components/Renewals";
import { RENEWAL_NOTE_MAX_LENGTH } from "manager/config/constants";
import ROUTES from "manager/config/routes";
import {
  useCreateRenewalNote,
  useGetRenewal,
  useGetRenewalOffer,
  useReopenRenewalOffer,
  useUpdateRenewalOffer,
} from "manager/hooks/api";
import { RenewalNoteValidationSchema } from "manager/utils/validationSchemas";
import Container from "shared/components/Container";
import { FormPromptShell } from "shared/components/Form";
import LargeLoader from "shared/components/LargeLoader";
import LoaderContainer from "shared/components/LoaderContainer";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import SingleInputModal, {
  INPUT_MODE,
} from "shared/components/Modals/SingleInputModal";
import StatusNotificationModal from "shared/components/Modals/StatusNotificationModal";
import Well from "shared/components/Well";
import {
  RenewalOfferStatus,
  YEAR_MONTH_DATE_FORMAT,
} from "shared/config/constants";
import { usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import {
  Offer,
  RenewalOption,
  RenewalOptionConcession,
} from "shared/interfaces/api/renewals";
import { transformRoute } from "shared/utils/routing";

import { FootBarState, getValidationSchema, INITIAL_VALUES } from "./constants";
import { RenewalOfferBuilder } from "./RenewalOfferBuilder";
import { RenewalOfferBuilderFootBar } from "./RenewalOfferBuilderFootBar";
import {
  getFootBarState,
  getApprovedOffer,
  getWaitingApprovalOffer,
} from "./utils";

export const RenewalOfferBuilderContainer = ({ sidebar, header }) => {
  const { id: renewalId, offerId } = useParams() as any;
  const history = useHistory();
  const { openModalDialog } = useModal();
  const { canCreateRenewalsOffers, canApproveRenewalsOffers } =
    usePermissions();
  const formRef = useRef<FormikProps<Offer>>();
  const saveWithConfirmRef = useRef(false);
  const { renewal, isRenewalLoading } = useGetRenewal(renewalId);
  const { renewalOffer, isRenewalOfferLoading } = useGetRenewalOffer(
    renewalId,
    offerId
  );
  const { updateRenewalOffer, isUpdateRenewalOfferLoading } =
    useUpdateRenewalOffer();
  const { reopenRenewalOffer, isReopenRenewalOfferLoading } =
    useReopenRenewalOffer();
  const { renewalActions, actionInProgress, executeActionWithConfirmation } =
    useRenewalActions();
  // TODO igeshosk (renewals): check with the BE team if we need another end-point for "create & notify"
  const { createRenewalNote } = useCreateRenewalNote();
  const isUpdating =
    isUpdateRenewalOfferLoading ||
    isReopenRenewalOfferLoading ||
    actionInProgress;

  const propertyUnitName = [renewal?.unit?.propertyName, renewal?.unit?.name]
    .filter((item) => Boolean(item))
    .join(", ");

  const isReadOnly =
    !canCreateRenewalsOffers ||
    renewalOffer?.status !== RenewalOfferStatus.IN_PROGRESS;

  const footBarState = getFootBarState({
    renewalOfferStatus: renewalOffer?.status,
    canCreateRenewalsOffers,
  });

  const isLoading = isRenewalLoading || isRenewalOfferLoading;

  const getNewLeaseDates = (option: RenewalOption) => {
    const newLeaseStartDate = dayjs(renewal?.leaseEndDate).add(1, "day");
    const newLeaseStartDateStr = newLeaseStartDate.format(
      YEAR_MONTH_DATE_FORMAT
    );

    const leaseEndDate = newLeaseStartDate
      .add(option.durationInMonths, "months")
      .format(YEAR_MONTH_DATE_FORMAT);
    return {
      leaseStartDate: newLeaseStartDateStr,
      leaseEndDate,
    };
  };

  const openConcessionModal = (option: RenewalOption, idx: number) => {
    const { leaseStartDate, leaseEndDate } = getNewLeaseDates(option);
    openModalDialog(RenewalOfferOptionConcessionModal, {
      concession: option?.concession,
      leaseStartDate,
      leaseEndDate,
    }).afterClose.then((concession: RenewalOptionConcession) => {
      if (concession) {
        const updatedOption = {
          ...option,
          concession,
        };
        formRef.current.setFieldValue(`renewalOptions.${idx}`, updatedOption);
      }
    });
  };

  const renewalBackLink = {
    children: `${renewal?.unit?.name} RENEWAL`,
    to: transformRoute(ROUTES.renewal, {
      id: renewalId,
    }),
  };

  const navigateBack = () => {
    history.push(renewalBackLink.to);
  };

  const saveChanges = async (offer: Offer, onSuccess?: () => void) => {
    if (formRef.current.isValid) {
      const result = await updateRenewalOffer(
        { renewalId, offer },
        { onSuccess }
      );
      // NOTE: need to reset the form to clean up the "touched" state. If we don't
      // do this, the prompt for unsaved changes on navigation is triggered.
      formRef.current.resetForm();
      return result;
    }
    return null;
  };

  const sendOffersApprovalReminderAction = () => {
    return renewalActions.requestOfferApproval(renewal);
  };

  const sendOffersAction = () => {
    return renewalActions.sendOffer(renewal);
  };

  const approveOfferAction = async (offer: Offer) => {
    let isSaveSuccessful = false;
    await executeActionWithConfirmation({
      renewal,
      title: "Approve Offers",
      message:
        "Confirming this operation will approve the current offers. Do you want to continue?",
      onConfirm: () =>
        saveChanges(getApprovedOffer(offer), () => {
          isSaveSuccessful = true;
        }),
    });

    // NOTE: using the `isSaveSuccessful` flag instead of `onSuccess` callback
    // to avoid warnings about updating a modal which is unmounted.
    if (isSaveSuccessful) {
      const result = await openModalDialog(ConfirmationModal, {
        title: "Offers Approved",
        message: "Would you like to send the offers to the renters?",
        submitButtonLabel: "Yes",
        cancelLinkLabel: "No",
      }).afterClose;

      if (result) {
        sendOffersAction();
      }
    }
  };

  const sendOffersForApprovalAction = async (offer: Offer) => {
    let isSaveSuccessful = false;
    await executeActionWithConfirmation({
      renewal,
      title: "Send Offers for Approval",
      message:
        "Confirming this operation will send the current offers for approval. Do you want to continue?",
      onConfirm: () =>
        saveChanges(getWaitingApprovalOffer(offer), () => {
          isSaveSuccessful = true;
        }),
    });

    // NOTE: using the `isSaveSuccessful` flag instead of `onSuccess` callback
    // to avoid warnings about updating a modal which is unmounted.
    if (isSaveSuccessful) {
      openModalDialog(StatusNotificationModal, {
        message: "The created offers have been sent for approval.",
        extraContent: (
          <Well>
            Users with permission to approve the offers will be notified by
            email. You will be notified of any notes or when the offers are
            approved.
          </Well>
        ),
      });
    }
  };

  const onSubmit = (offerFormData: Offer) => {
    const isConfirm = saveWithConfirmRef.current;
    if (isConfirm) {
      if (footBarState === FootBarState.IN_PROGRESS) {
        return canApproveRenewalsOffers
          ? approveOfferAction(offerFormData)
          : sendOffersForApprovalAction(offerFormData);
      }
      if (footBarState === FootBarState.WAITING_APPROVAL) {
        return canApproveRenewalsOffers
          ? approveOfferAction(offerFormData)
          : sendOffersApprovalReminderAction();
      }
      if (footBarState === FootBarState.APPROVED) {
        return sendOffersAction();
      }
    }
    return saveChanges(offerFormData);
  };

  const submitForm = () => {
    return formRef.current.submitForm();
  };

  const saveAndExit = async () => {
    await submitForm();
    navigateBack();
  };

  const confirm = async () => {
    saveWithConfirmRef.current = true;
    try {
      await submitForm();
    } finally {
      saveWithConfirmRef.current = false;
    }
  };

  const reopenOffer = () => {
    executeActionWithConfirmation({
      renewal,
      title: "Reopen and Edit Offers",
      message:
        "Confirming this operation will reopen the current offers and make them available to be edited again. The offers must be approved again. Do you want to continue?",
      onConfirm: () => {
        return reopenRenewalOffer({ renewalId, offerId });
      },
    });
  };

  const createNoteAction = () => {
    openModalDialog(SingleInputModal, {
      title: "Add Note",
      inputMode: INPUT_MODE.multiline,
      maxLength: RENEWAL_NOTE_MAX_LENGTH,
      showCount: true,
      valueValidationSchema: RenewalNoteValidationSchema,
      initialValue: "",
      submit: ({ value }) => {
        return createRenewalNote({ note: value, id: renewalId });
      },
    });
  };

  const getPromptProps = ({ isValid }) => {
    return {
      title: "Unsaved changes",
      subtitle: isValid
        ? "Would you like to save the changes?"
        : "The changes you made are not valid and will not be saved.",
      submitButtonLabel: isValid ? "Yes" : "OK",
      cancelLinkLabel: isValid ? "No" : null,
    };
  };

  return (
    <Layout
      sidebar={sidebar}
      header={{
        ...header,
        backLink: renewalBackLink,
      }}
    >
      <Container expand noPaddingBottom>
        <FormPromptShell
          validationSchema={getValidationSchema({ rent: renewal?.rent })}
          initialValues={renewalOffer || INITIAL_VALUES}
          onSubmit={onSubmit}
          saveChanges={saveChanges}
          formRef={formRef}
          CustomFootBar={() => (
            <RenewalOfferBuilderFootBar
              footBarState={footBarState}
              cancel={navigateBack}
              saveAndExit={saveAndExit}
              confirm={confirm}
              reopenOffer={reopenOffer}
              createNoteAction={createNoteAction}
              isUpdating={isUpdating}
            />
          )}
          getPromptProps={getPromptProps}
        >
          <LoaderContainer
            Loader={<LargeLoader showLoader absoluteCenter />}
            loading={isLoading}
          >
            <RenewalOfferBuilder
              isReadOnly={isReadOnly}
              propertyUnitName={propertyUnitName}
              openConcessionModal={openConcessionModal}
              renewalBackLink={renewalBackLink}
            />
          </LoaderContainer>
        </FormPromptShell>
      </Container>
    </Layout>
  );
};
