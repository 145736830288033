import React from "react";

import {
  useCopyRenewalSettingsFromPortfolio,
  useGetRenewalsSettings,
  useResetRenewalSettingsToPortfolio,
} from "manager/hooks/api";
import { PropertySettings } from "manager/interfaces/api/propertySettings";
import PropertySettingsFooter from "manager/pages/PropertySettings/PropertySettingsFooter";
import FooterBar from "shared/components/FooterBar";

import RenewalsSettingsForm, { SettingsLevel } from "./RenewalsSettingsForm";
import useRenewalsSettingsHandlers from "./useRenewalsSettingsHandlers";

interface RenewalsPropertySettingsProps {
  propertyId: number;
  propertySettings: PropertySettings;
}

const RenewalsPropertySettings = ({
  propertyId,
  propertySettings,
}: RenewalsPropertySettingsProps) => {
  const portfolioName = propertySettings?.portfolioIsDefault
    ? "Company Settings"
    : propertySettings?.portfolioName;
  const portfolioSettingsName = `${portfolioName}${
    !propertySettings?.portfolioIsDefault ? " settings" : ""
  }`;
  const propertyName = propertySettings?.name;
  const renewalsSettingsId = propertySettings?.renewalSettingsId;
  const isRenewalSettingsFromPortfolio =
    propertySettings?.isRenewalSettingsFromPortfolio;

  const { renewalsSettings, isRenewalsSettingsLoading } =
    useGetRenewalsSettings({ settingsId: renewalsSettingsId });

  const { emailConfigurations, initialValues, isLoading, saveChanges } =
    useRenewalsSettingsHandlers({
      renewalsSettings,
      isRenewalsSettingsLoading,
    });

  const { copyRenewalSettingsFromPortfolio } =
    useCopyRenewalSettingsFromPortfolio();

  const { resetRenewalSettingsToPortfolio } =
    useResetRenewalSettingsToPortfolio();

  const CustomFootBar = () => (
    <FooterBar justifyContent="flex-end">
      <PropertySettingsFooter
        propertyId={propertyId}
        propertyName={propertyName}
        portfolioName={portfolioName}
        isPortfolioSettings={isRenewalSettingsFromPortfolio}
        copyPortfolioSettings={copyRenewalSettingsFromPortfolio}
        resetPropertySettings={resetRenewalSettingsToPortfolio}
      />
    </FooterBar>
  );

  const infoMessage = isRenewalSettingsFromPortfolio
    ? `${portfolioSettingsName} are active for renewals.`
    : `Custom settings specific to renewals are applied and refer to ${portfolioName}.`;

  return (
    <RenewalsSettingsForm
      initialValues={initialValues}
      onSubmit={saveChanges}
      saveChanges={saveChanges}
      isLoading={isLoading}
      emailConfigurations={emailConfigurations}
      disableForm={isRenewalSettingsFromPortfolio}
      CustomFootBar={CustomFootBar}
      infoMessage={infoMessage}
      level={SettingsLevel.property}
    />
  );
};

export default RenewalsPropertySettings;
