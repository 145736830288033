import React, { useMemo } from "react";

import { ConcessionFormData } from "manager/components/Leasing/Concession";
import { useGetValidConcessionMonths } from "manager/hooks/api";
import { CONCESSION_TYPES } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { RenewalOptionConcession } from "shared/interfaces/api/renewals";

import { RenewalOfferOptionConcessionModal } from "./RenewalOfferOptionConcessionModal";
import {
  convertFormRenewalConcessionToModel,
  convertModelRenewalConcessionToForm,
} from "./util";

const EMPTY_INITIAL_VALUES: ConcessionFormData = {
  concessionType: CONCESSION_TYPES.amortize,
  dates: [],
  numberOfMonths: undefined,
};

interface RenewalOfferOptionConcessionModalContainerProps {
  concession?: RenewalOptionConcession;
  leaseStartDate: string;
  leaseEndDate: string;
}

export const RenewalOfferOptionConcessionModalContainer = ({
  concession,
  leaseStartDate,
  leaseEndDate,
}: RenewalOfferOptionConcessionModalContainerProps) => {
  const { selfApi } = useModal();
  const { concessionMonths, isConcessionMonthsLoading } =
    useGetValidConcessionMonths(
      {
        leaseStart: leaseStartDate,
        leaseEnd: leaseEndDate,
      },
      { enabled: true }
    );

  const onSubmit = (values: ConcessionFormData) => {
    const updatedConcession: RenewalOptionConcession =
      convertFormRenewalConcessionToModel(values);
    selfApi.closeDialogWithValue(updatedConcession);
  };

  const changeConcessionType = (
    concessionType: CONCESSION_TYPES,
    setValues: (values: ConcessionFormData) => void
  ) => {
    const newValues: ConcessionFormData = {
      ...EMPTY_INITIAL_VALUES,
      concessionType,
    };
    setValues(newValues);
  };

  const initialValues = useMemo(
    () =>
      concession
        ? convertModelRenewalConcessionToForm(concession)
        : EMPTY_INITIAL_VALUES,
    [concession]
  );

  return (
    <RenewalOfferOptionConcessionModal
      leaseStartDate={leaseStartDate}
      leaseEndDate={leaseEndDate}
      concessionMonths={concessionMonths}
      isConcessionMonthsLoading={isConcessionMonthsLoading}
      initialValues={initialValues}
      onSubmit={onSubmit}
      changeConcessionType={changeConcessionType}
    />
  );
};
