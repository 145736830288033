import { openDefaultAnimatedActionModal } from "shared/components/Modals/AnimatedPendingActionModal";
import {
  COMMON_PENDING_ANIMATION_MESSAGES,
  COUNTY_CRIMINAL_SEARCH_STATUS_ENUM,
} from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import { approveDeal } from "shared/lib/api/dealApi";
import { notifyError } from "shared/utils/ui";

import AssignDealToUnitModal from "../../../Modal/Deal/AssignDealToUnitModal";
import CreateLeaseModal from "../../../Modal/Deal/CreateLeaseModal";
import { useUpload } from "../hooks";
import { DealSummary } from "../types";

export const useHook = (deal: DealSummary, hide: () => void) => {
  const { openModalDialog, closeActiveModalDialog } = useModal();
  const { uploadFile, attachments } = useUpload();

  const showCountyRecordsPendingMessage =
    deal?.countyCriminalSearchStatus?.value ===
    COUNTY_CRIMINAL_SEARCH_STATUS_ENUM.IN_PROGRESS;

  const approve = async () => {
    const messages = [...COMMON_PENDING_ANIMATION_MESSAGES, "Approved!"];

    hide();
    const animatedPendingActionApi = openDefaultAnimatedActionModal(messages);
    try {
      const response = await approveDeal({
        id: deal?.id,
        attachments,
        extraParams: null,
      });
      const updatedDeal = response.data;
      const afterAnimatedPendingModalCloseAction = () => {
        // refetchDealRedux();
        if (!updatedDeal.unitListing) {
          openModalDialog(AssignDealToUnitModal, { dealId: deal?.id });
        } else if (updatedDeal?.canCreateLease?.value) {
          openModalDialog(CreateLeaseModal, { deal: updatedDeal });
        }
      };

      animatedPendingActionApi.finishAnimation(
        afterAnimatedPendingModalCloseAction
      );
    } catch (err: any) {
      const { errors } = err;
      closeActiveModalDialog();
      if (errors?.nonFieldErrors?.length > 0) {
        notifyError(errors?.nonFieldErrors);
      } else {
        notifyError("Failed to approve an application.");
      }
      throw err;
    }
  };
  return { approve, uploadFile, showCountyRecordsPendingMessage };
};
