import React from "react";

import { Col, Row } from "antd";

import { RenewalNote } from "manager/interfaces/api/renewals";
import FlexContainer from "shared/components/FlexContainer";
import { RegularText, SmallText } from "shared/components/Typography";
import Well from "shared/components/Well";
import { formatDateTime } from "shared/utils/ui";

import { StyledGroupNotes, StyledPrimaryHeader } from "../styled";

import LeaseGroupNotesForm, { NoteFormValues } from "./LeaseGroupNoteForm";

interface LeaseGroupNotesProps {
  saveLeaseNote: (values: NoteFormValues) => Promise<any>;
  notes?: RenewalNote[];
  disabled?: boolean;
}

const LeaseGroupNotes = ({
  saveLeaseNote,
  notes = [],
  disabled,
}: LeaseGroupNotesProps) => {
  return (
    <StyledGroupNotes>
      <StyledPrimaryHeader>
        <RegularText strong className="text-white">
          Notes
        </RegularText>
      </StyledPrimaryHeader>
      <Row gutter={[0, 12]}>
        <Col>
          <Well noBorder white>
            <LeaseGroupNotesForm onSubmit={saveLeaseNote} disabled={disabled} />
          </Well>
        </Col>
      </Row>
      <section className="notes-section">
        {notes.map((note) => (
          <Row key={note.id} gutter={[0, 12]}>
            <Col>
              <Well noBorder white>
                <FlexContainer
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <SmallText extraStrong>
                    {formatDateTime(note.createdAt)}
                  </SmallText>
                  <SmallText extraStrong>{note.createdByName}</SmallText>
                </FlexContainer>
                <SmallText>{note.note}</SmallText>
              </Well>
            </Col>
          </Row>
        ))}
      </section>
    </StyledGroupNotes>
  );
};

export default LeaseGroupNotes;
