import { useMemo, useRef } from "react";

import { SessionStorageCollectionManager } from "shared/utils/sessionStorage";

export const useSessionStorageCollectionManager = (storageKey) => {
  const dataTableStorageManagerRef = useRef<SessionStorageCollectionManager>();
  return useMemo(() => {
    if (dataTableStorageManagerRef?.current?.destroy) {
      dataTableStorageManagerRef.current.destroy();
      dataTableStorageManagerRef.current = null;
    }

    const sessionStorageManager = new SessionStorageCollectionManager({
      storageKey,
    });
    dataTableStorageManagerRef.current = sessionStorageManager;
    return sessionStorageManager;
  }, [storageKey]);
};
