import React, { HTMLAttributes } from "react";

import { Col, Row } from "antd";
import pluralize from "pluralize";

import {
  AMOUNT_RENT_CHANGE,
  PERCENT_RENT_CHANGE
} from "manager/config/constants";
import Icon from "shared/components/Icon";
import { IconLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { RegularText, SmallText } from "shared/components/Typography";
import { RentChangeOptions } from "shared/config/constants";
import { RenewalOption } from "shared/interfaces/api/renewals";
import { conditionalItem } from "shared/utils/array";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { ConcessionTooltipTitle } from "./ConcessionTooltipTitle";
import { StyledTile } from "./styled";

const RENT_INCREASE = new Set([
  RentChangeOptions.AMOUNT_INCREASE,
  RentChangeOptions.PERCENT_INCREASE,
]);

const RENT_DECREASE = new Set([
  RentChangeOptions.AMOUNT_DECREASE,
  RentChangeOptions.PERCENT_DECREASE,
]);

enum RentChangeClass {
  INCREASE = "increase",
  DECREASE = "decrease",
}

enum RentChangeSign {
  POSITIVE = "+",
  NEGATIVE = "-",
}

const getRentChangeData = (option: RenewalOption) => {
  let rentChangeClassName = "";
  let sign = "";
  let value = "";

  if (RENT_INCREASE.has(option.rentChangeOption)) {
    rentChangeClassName = RentChangeClass.INCREASE;
    sign = RentChangeSign.POSITIVE;
  } else if (RENT_DECREASE.has(option.rentChangeOption)) {
    rentChangeClassName = RentChangeClass.DECREASE;
    sign = RentChangeSign.NEGATIVE;
  }

  if (AMOUNT_RENT_CHANGE.has(option.rentChangeOption)) {
    value = printDollarsFromCents(option.rentChangeValue);
  } else if (PERCENT_RENT_CHANGE.has(option.rentChangeOption)) {
    value = `${option.rentChangeValue}%`;
  } else {
    value = "0";
  }

  const rentChangeFormattedValue = `${sign}${value}`;
  return {
    rentChangeClassName,
    rentChangeFormattedValue,
  };
};

const COL_SIZE_PROP = Object.freeze({
  xs: { span: 24 },
  sm: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 },
  xxl: { span: 4 },
});

type ColSizes = typeof COL_SIZE_PROP;

interface RenewalOfferOptionProps extends HTMLAttributes<HTMLBaseElement> {
  option: RenewalOption;
  grayOutRejectedOptions?: boolean;
  deleteOption?: (o: RenewalOption) => void;
  canDeleteOption?: boolean;
  hideRentChange?: boolean;
  hideActionsCol?: boolean;
  colSizes?: ColSizes;
  ConcessionInfoIcon?: React.ComponentType;
  showDelete?: boolean;
}

export const RenewalOfferOption = ({
  option,
  grayOutRejectedOptions,
  deleteOption,
  className,
  canDeleteOption,
  hideRentChange = false,
  hideActionsCol = false,
  colSizes = COL_SIZE_PROP,
  ConcessionInfoIcon = Icon.InfoIcon,
  showDelete = false,
}: RenewalOfferOptionProps) => {
  const { rentChangeClassName, rentChangeFormattedValue } =
    getRentChangeData(option);

  const tileClass = [
    ...conditionalItem(option?.accepted, "accepted-option"),
    ...conditionalItem(!!className, className),
  ].join(" ");

  const shouldGrayOut = grayOutRejectedOptions && !option?.accepted;

  return (
    <StyledTile noBorder className={tileClass}>
      <Tile.Inner className="tile-inner">
        <Row type="flex" className="option-info-row">
          <Col className="option-info-col" {...colSizes}>
            <SmallText className="label">Renewal Period:</SmallText>
            <RegularText extraStrong>
              {option.durationInMonths}{" "}
              {pluralize("month", option.durationInMonths)}
            </RegularText>
          </Col>

          {!hideRentChange && (
            <Col className="option-info-col" {...colSizes}>
              <Spacer size={Spacer.SIZES.sm} hideGteSm />
              <SmallText className="label">Rent change:</SmallText>
              <RegularText
                extraStrong
                className={`rent-change-text ${rentChangeClassName}`}
              >
                {rentChangeFormattedValue}
              </RegularText>
            </Col>
          )}

          <Col className="option-info-col" {...colSizes}>
            <Spacer size={Spacer.SIZES.sm} hideGteLg />
            <SmallText className="label">New Rent:</SmallText>
            <RegularText extraStrong>
              {printDollarsFromCents(option.newRent)}
            </RegularText>
          </Col>

          <Col className="option-info-col" {...colSizes}>
            <Spacer size={Spacer.SIZES.sm} hideGteLg />
            <SmallText className="label">Concessions:</SmallText>
            <div className="concession-value">
              <RegularText extraStrong>
                {printDollarsFromCents(option.concessionAmount)}
              </RegularText>
              <Tooltip title={<ConcessionTooltipTitle option={option} />}>
                <ConcessionInfoIcon />
              </Tooltip>
            </div>
          </Col>
        </Row>
        {!hideActionsCol && (
          <div className="action-col">
            {showDelete && (
              <IconLink
                onClick={() => deleteOption(option)}
                Icon={Icon.DeleteSmallIcon}
                disabled={!canDeleteOption}
              />
            )}
            {option?.accepted && <Icon.CheckGreenBig />}
          </div>
        )}
      </Tile.Inner>
      {shouldGrayOut && <div className="option-gray-cover" />}
    </StyledTile>
  );
};
