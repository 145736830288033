import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

export const FieldsContainer = styled.div<{ modalView?: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, minmax(205px, 450px));
  gap: 32px 70px;
  margin-bottom: 40px;
  padding: 32px;
  ${({ modalView }) =>
    modalView &&
    `
    background-color: ${COLORS.layoutBodyBackground};
    grid-template-columns: repeat(2, minmax(342px, 415px));
    column-gap: 20px;
  `}

  ${MEDIA_QUERIES.w.ltMd} {
    grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
    column-gap: 20px;
  }
`;

export const FieldContainer = styled.div<{ modalView?: boolean }>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  div {
    margin-right: 16px;
    width: 100%;
    ${({ modalView }) =>
      modalView &&
      `
  label {
    background-color: ${COLORS.layoutBodyBackground};
  }
`}
  }
  svg {
    margin-top: 12px;
  }
`;

export const CheckboxContainer = styled.div<{ modalView?: boolean }>`
  border: 1px solid ${COLORS.borders};
  max-width: ${({ modalView }) => (modalView ? 380 : 420)};
  padding: 16px;
  width: 100%;
`;

export const CheckboxTitle = styled.p`
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
`;
