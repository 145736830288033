import React from "react";

import { useFormikContext } from "formik";
import reduce from "lodash/reduce";
import uniqBy from "lodash/uniqBy";
import PropTypes from "prop-types";

import { MANAGER_QUESTION_MODAL_MODE } from "manager/components/Modal/LeasePackage/ManagerQuestionModal";
import { Checkbox } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { ActionLink, IconLink, UnderlineLink } from "shared/components/Links";
import Table from "shared/components/Table";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";

import { ActionsColumnContainer, StyledSwitchTitle } from "./styled";

const ManagerQuestionsTable = ({
  loading,
  managerQuestions,
  editManagerQuestion,
  isEditLoading,
  openManagerQuestionModal,
  openDeleteManagerQuestionConfirmation,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const getDocsList = (docs) => (
    <ul>
      {docs.map(({ id, name }) => (
        <li key={id}>{name}</li>
      ))}
    </ul>
  );

  const getRows = () =>
    (managerQuestions || []).map((row, index) => {
      const docs = uniqBy(
        reduce(
          row.options,
          (prev, { documents }) => [...prev, ...documents],
          []
        ),
        "id"
      );

      return {
        key: row.id,
        values: [
          <Checkbox
            name={`managerQuestion[${index}].active`}
            id={`managerQuestion[${index}].active`}
            checked={row.isActive}
            disabled={isEditLoading || !values.isManagerQuestionsEnabled}
            onChange={() => {
              editManagerQuestion({ ...row, isActive: !row.isActive });
            }}
          />,
          row.questionName,
          <Tooltip
            title={getDocsList(docs)}
            trigger={docs.length > 0 ? ["hover", "click"] : []}
            theme="light"
          >
            <UnderlineLink>{docs.length}</UnderlineLink>
          </Tooltip>,
          <ActionsColumnContainer justifyContent="right">
            <IconLink
              onClick={() =>
                openManagerQuestionModal({
                  initialValues: row,
                  documentTemplates: values.documentTemplates.documentTemplates,
                  mode: MANAGER_QUESTION_MODAL_MODE.EDIT,
                })
              }
              Icon={Icon.EditGreenIcon}
              hoverableIcon={false}
              disabled={!values.isManagerQuestionsEnabled}
              className="action-icon"
            />
            <IconLink
              onClick={() => openDeleteManagerQuestionConfirmation(row.id)}
              Icon={Icon.DeleteSmallIcon}
              hoverableIcon={false}
              disabled={!values.isManagerQuestionsEnabled}
              className="action-icon"
            />
          </ActionsColumnContainer>,
        ],
      };
    });

  return (
    <Tile
      noBorderTop
      noBorderLeft
      noBorderRight
      noMarginBottom
      size="lg"
      expand={values.isManagerQuestionsEnabled}
      header={{
        title: (
          <StyledSwitchTitle
            noPadding
            id="isManagerQuestionsEnabled"
            name="isManagerQuestionsEnabled"
            title="Manager specific lease questions"
            value={values.isManagerQuestionsEnabled}
            onChange={(value, event) => {
              setFieldValue("isManagerQuestionsEnabled", value);
              event.stopPropagation();
            }}
            disabled={false}
          />
        ),
        subtitle:
          "These questions will be used to determine which documents will be added to the lease package",
        collapsibleOnDesktop: true,
        collapsibleOnMobile: true,
        defaultCollapsed: !values.isManagerQuestionsEnabled,
        fullWidth: true,
      }}
    >
      <Table
        id="manager-questions-table"
        loading={loading}
        alt
        rows={getRows()}
        pagination={false}
        placeholder={{
          Icon: Icon.NoDataIcon,
          label: "Questions",
        }}
        noClickableRows
        columns={[
          {
            title: <RegularText weak>Active</RegularText>,
            dataIndex: "active",
            key: "active",
            width: 100,
          },
          {
            title: <RegularText weak>Question</RegularText>,
            dataIndex: "question",
            key: "question",
          },
          {
            title: <RegularText weak>Selected Document(s)</RegularText>,
            dataIndex: "selectedDocuments",
            key: "selectedDocuments",
            width: "30%",
          },
          {
            title: values.isManagerQuestionsEnabled && (
              <ActionLink
                data-testid="create-action"
                onClick={() =>
                  openManagerQuestionModal({
                    documentTemplates:
                      values.documentTemplates.documentTemplates,
                    mode: MANAGER_QUESTION_MODAL_MODE.CREATE,
                  })
                }
              >
                + QUESTION
              </ActionLink>
            ),
            dataIndex: "addQuestion",
            key: "addQuestion",
            width: 170,
            minWidth: 170,
            align: "right",
          },
        ]}
        columnBreakpoints={{
          xs: {
            column1: "question",
            column2: "numOfDocs",
          },
          sm: ["active", "question"],
          md: ["active", "question", "selectedDocuments", "addQuestion"],
          lg: ["active", "question", "selectedDocuments", "addQuestion"],
        }}
      />
    </Tile>
  );
};

ManagerQuestionsTable.propTypes = {
  managerQuestions: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  editManagerQuestion: PropTypes.func.isRequired,
  isEditLoading: PropTypes.bool.isRequired,
  openManagerQuestionModal: PropTypes.func.isRequired,
  openDeleteManagerQuestionConfirmation: PropTypes.func.isRequired,
};

ManagerQuestionsTable.defaultProps = {
  managerQuestions: [],
  loading: false,
};

export default ManagerQuestionsTable;
