import styled from "@emotion/styled";
import { Row } from "antd";

import FlexContainer from "shared/components/FlexContainer";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

import {
  CenteredFlexPartialCss,
  VerticalFlexPartialCss,
} from "shared/styles/commonStyles";

const StyledFeeSummaryInner = styled.div`
  border: 1px solid ${COLORS.borders};

  .summary-header {
    background: ${COLORS.lightGray2};
    padding: 8px 24px;

    .vertical-divider {
      width: 1px;
      height: 40px;
      background: ${COLORS.borders};
      margin: 0 24px;
    }
  }

  .summary-footer {
    background: ${COLORS.lightGray2};
    padding: 16px 24px;

    .switch-text-wrapper {
      margin-left: -8px;
    }
  }

  .summary-col-inner {
    flex-grow: 1;
    min-height: 170px;

    .summary-col-content {
      padding-left: 24px;
    }
  }

  .left-centered-content {
    ${CenteredFlexPartialCss}
    justify-content: flex-start;
  }

  .right-centered-content {
    ${CenteredFlexPartialCss}
    justify-content: flex-end;
  }

  .custom-fee-input {
    margin-right: 8px;
  }

  .vertical-flex {
    ${VerticalFlexPartialCss}
  }

  .final-sum-section {
    min-height: 100px;
    position: relative;

    .final-sum-content {
      position: absolute;
      top: 8px;
      right: 24px;
      width: 220px;
    }
  }

  ${MEDIA_QUERIES.w.ltXxl} {
    .final-sum-section {
      .final-sum-content {
        width: 200px;
      }
    }
  }

  ${MEDIA_QUERIES.w.ltLg} {
    border: none;
    border-top: 1px solid ${COLORS.borders};

    .summary-col-inner {
      padding: 0;
      min-height: 100px;

      .summary-col-content {
        padding-right: 24px;
      }
    }

    .summary-header {
      flex-direction: column;
      align-items: flex-start;
    }

    .final-sum-section {
      .final-sum-content {
        width: 100%;
      }
    }
  }
`;

const StyledFeeItemRow = styled(Row)`
  .money-input {
    max-width: 100px;
  }

  ${MEDIA_QUERIES.w.ltXl} {
    .amount-label {
      padding-right: 50px;
    }
  }

  ${MEDIA_QUERIES.w.ltLg} {
    .amount-label {
      padding-right: 0;
    }
  }
`;

const StyledSumSection = styled.section`
  min-height: 100px;
  padding: 8px 0;

  .sub-sum-row {
    padding-left: 24px;
  }

  .sub-sum-row.fees-editable {
    padding-right: 100px;
  }

  ${MEDIA_QUERIES.w.ltXl} {
    .sub-sum-row {
      padding-left: 12px;
      padding-right: 50px;
    }
  }

  ${MEDIA_QUERIES.w.ltLg} {
    min-height: 80px;
    .sub-sum-row {
      padding-left: 24px;
      padding-right: 24px;
    }

    .sub-sum-row.fees-editable {
      padding-right: 124px;
    }
  }
`;

const StyledFinalSumSection = styled.div`
  .sum-col {
    text-align: right;
  }

  .sum-col-divider {
    width: 160px;
    margin-left: auto;
  }

  .sum-col:nth-of-type(even) {
    width: 50px;
    margin-left: 4px;
  }
`;

const StyledProratedFinalSum = styled(FlexContainer)`
  ${MEDIA_QUERIES.w.ltLg} {
    flex-direction: column;

    .prorate-lease-info {
      margin: 24px auto 24px 0;
    }
  }
`;

export {
  StyledFeeItemRow,
  StyledSumSection,
  StyledFinalSumSection,
  StyledFeeSummaryInner,
  StyledProratedFinalSum,
};
