import React from "react";

import { useHistory } from "react-router-dom";

import ResendLeaseModal from "manager/components/Leasing/ResendLeaseModal";
import ROUTES from "manager/config/routes";
import {
  useApproveRenewalOffers,
  useCancelRenewal,
  useCreateRenewal,
  useCreateRenewalOffer,
  useRejectRenewal,
  useSendRenewalOfferApprovalReminder,
  useSendRenewalOfferReminder,
  useSendRenewalOfferRequest,
  useSendRenewalOffers,
  useSendRequestRenewal,
  useWithdrawRenewalOffer,
  useWithdrawRenewalOfferOption,
} from "manager/hooks/api";
import { RenewalBasic } from "manager/interfaces/api/renewals";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";
import { contactByEmails } from "shared/utils/ui";

import AddRenewalLeaseholderModal from "./AddRenewalLeaseholderModal";
import {
  ActionWithConfirmation,
  DeleteOptionFn,
  MultipleRenewalsActionFn,
  MultipleRenewalsActionWithConfirmation,
  RenewalActionFn,
  RenewalActions,
} from "./interfaces";

const useRenewalActions = (): {
  renewalActions: RenewalActions;
  actionInProgress: boolean;
  executeActionWithConfirmation: ActionWithConfirmation;
} => {
  const history = useHistory();
  const { openModalDialog } = useModal();
  const { sendRequestRenewal, isSendRequestRenewalLoading } =
    useSendRequestRenewal();
  const { createRenewal: createRenewalAction, isCreateRenewalLoading } =
    useCreateRenewal();
  const { sendRenewalOfferRequest, isSendRenewalOfferRequestLoading } =
    useSendRenewalOfferRequest();
  const {
    sendRenewalOfferApprovalReminder,
    isSendRenewalOfferApprovalReminderLoading,
  } = useSendRenewalOfferApprovalReminder();
  const { createRenewalOffer, isCreateRenewalOfferLoading } =
    useCreateRenewalOffer();
  const { approveRenewalOffers, isApproveRenewalOffersLoading } =
    useApproveRenewalOffers();
  const { sendRenewalOffers, isSendRenewalOffersLoading } =
    useSendRenewalOffers();
  const { sendRenewalOfferReminder, isSendRenewalOfferReminderLoading } =
    useSendRenewalOfferReminder();
  const { withdrawRenewalOffer, isWithdrawRenewalOfferLoading } =
    useWithdrawRenewalOffer();
  const { withdrawRenewalOfferOption, isWithdrawRenewalOfferOptionLoading } =
    useWithdrawRenewalOfferOption();
  const { rejectRenewal, isRejectRenewalLoading } = useRejectRenewal();
  const { cancelRenewal: cancelRenewalAction, isCancelRenewalLoading } =
    useCancelRenewal();

  const navigateToOffer = (renewalId: number, offerId: number) => {
    history.push(
      transformRoute(ROUTES.renewalOffer, { id: renewalId, offerId })
    );
  };

  const navigateToRenewal = (renewalId: number) => {
    history.push(transformRoute(ROUTES.renewal, { id: renewalId }));
  };

  const navigateToLeaseCountersign = (leaseId: number) => {
    history.push(
      transformRoute(ROUTES.leaseCountersign, {
        id: leaseId,
      })
    );
  };

  const navigateToRenewalsTable = () => history.push(ROUTES.renewals);

  const getModalSubtitle = (renewal: RenewalBasic) => {
    return (
      <span data-testid="subtitle">
        Unit:{" "}
        <strong>{`${renewal?.unit?.name}, ${renewal?.unit?.propertyName}`}</strong>
      </span>
    );
  };

  const executeActionWithConfirmation: ActionWithConfirmation = ({
    renewal,
    title,
    message,
    onConfirm,
  }) => {
    return openModalDialog(ConfirmationModal, {
      title,
      subtitle: getModalSubtitle(renewal),
      message,
      onConfirm,
    }).afterClose;
  };

  const executeMultipleRenewalsActionWithConfirmation: MultipleRenewalsActionWithConfirmation =
    ({ title, message, onConfirm }) => {
      return openModalDialog(ConfirmationModal, {
        title,
        subtitle: "Multiple selected",
        message,
        onConfirm,
      }).afterClose;
    };

  const requestOffer: RenewalActionFn = (renewal) => {
    return executeActionWithConfirmation({
      renewal,
      title: "Request Offer",
      message:
        "Are you sure you want to request a new offer for the selected renewal?",
      onConfirm: () => {
        return sendRenewalOfferRequest(renewal?.id);
      },
    });
  };

  const createOffer: RenewalActionFn = (renewal) => {
    return executeActionWithConfirmation({
      renewal,
      title: "Create Offer",
      message:
        "Are you sure you want to create a new offer for the selected renewal?",
      onConfirm: async () => {
        const offer = await createRenewalOffer({
          renewalId: renewal?.id,
          offer: { renewalOptions: [] },
        });
        if (offer?.id) {
          navigateToOffer(renewal?.id, offer?.id);
        }
      },
    });
  };

  const finishCreatingOffer: RenewalActionFn = (renewal) => {
    navigateToOffer(renewal?.id, renewal?.offer?.id);
  };

  const requestOfferApproval: RenewalActionFn = (renewal) => {
    return executeActionWithConfirmation({
      renewal,
      title: "Request Offer Approval",
      message:
        "Are you sure you want to send a reminder for the approval of the offer for the selected renewal?",
      onConfirm: () => {
        return sendRenewalOfferApprovalReminder(renewal?.id);
      },
    });
  };

  const approveOffer: RenewalActionFn = (renewal) => {
    navigateToOffer(renewal?.id, renewal?.offer?.id);
  };

  const approveOffers: MultipleRenewalsActionFn = (renewals) => {
    return executeMultipleRenewalsActionWithConfirmation({
      title: "Approve Offers",
      message:
        "Are you sure you want to approve the offers for the selected renewals?",
      onConfirm: () => {
        return approveRenewalOffers({ renewals: renewals.map((r) => r.id) });
      },
    });
  };

  const sendOffer: RenewalActionFn = (renewal) => {
    return executeActionWithConfirmation({
      renewal,
      title: "Send Offer",
      message:
        "Are you sure you want to send the offer for the selected renewal?",
      onConfirm: () => {
        return sendRenewalOffers(
          { renewalOfferIds: [renewal?.offer?.id] },
          {
            onSuccess: () => navigateToRenewal(renewal?.id),
          }
        );
      },
    });
  };

  const sendOffers: MultipleRenewalsActionFn = (renewals) => {
    return executeMultipleRenewalsActionWithConfirmation({
      title: "Send Offers",
      message:
        "Are you sure you want to send the offers for the selected renewals?",
      onConfirm: () => {
        return sendRenewalOffers({
          renewalOfferIds: renewals.map((r) => r?.offer?.id),
        });
      },
    });
  };

  const sendOfferReminder: RenewalActionFn = (renewal) => {
    return executeActionWithConfirmation({
      renewal,
      title: "Send Offer Reminder",
      message:
        "Are you sure you want to send the offer reminder for the selected renewal?",
      onConfirm: () => {
        return sendRenewalOfferReminder(renewal?.id);
      },
    });
  };

  const requestRenewalCreation: RenewalActionFn = (renewal) => {
    return executeActionWithConfirmation({
      renewal,
      title: "Request Renewal Creation",
      message:
        "Are you sure you want to send the request for creating a renewal?",
      onConfirm: () => {
        return sendRequestRenewal(renewal?.id);
      },
    });
  };

  const createRenewal: RenewalActionFn = (renewal) => {
    return executeActionWithConfirmation({
      renewal,
      title: "Create Renewal",
      message: "Are you sure you want to create a renewal?",
      onConfirm: () => {
        return createRenewalAction(renewal?.id);
      },
    });
  };

  const finishCreatingRenewal: RenewalActionFn = (renewal) => {
    navigateToRenewal(renewal?.id);
  };

  const sendLeaseSignReminder: RenewalActionFn = (renewal) => {
    const lease = renewal?.newLease;
    const applicantsNotSignedLease =
      lease?.applications?.filter((applicant) => !applicant.isSigned) || [];
    openModalDialog(ResendLeaseModal, {
      propertyName: lease?.unit?.propertyName,
      unitName: lease?.unit?.name,
      leaseId: lease?.id,
      applicants: applicantsNotSignedLease,
      reminderSentAt: lease?.reminderSentAt,
      isLastReminderAuto: lease?.isLastReminderAuto,
    });
  };

  const countersign: RenewalActionFn = (renewal) => {
    navigateToLeaseCountersign(renewal?.newLease?.id);
  };

  const contactRenters: RenewalActionFn = (renewal) => {
    const emails = renewal?.residents?.map((resident) => resident.email);
    contactByEmails(emails);
  };

  const withdrawOffer: RenewalActionFn = (renewal) => {
    return executeActionWithConfirmation({
      renewal,
      title: "Withdraw Offer",
      message:
        "Are you sure you want to withdraw the offer for the selected renewal?",
      onConfirm: () => {
        return withdrawRenewalOffer({
          renewalId: renewal?.id,
          offerId: renewal?.offer?.id,
        });
      },
    });
  };

  const deleteOption: DeleteOptionFn = (renewal, option) => {
    return executeActionWithConfirmation({
      renewal,
      title: "Withdraw Offer Option",
      message:
        "Are you sure you want to withdraw the offer option for the selected renewal?",
      onConfirm: () => {
        return withdrawRenewalOfferOption({
          renewalId: renewal?.id,
          offerId: renewal?.offer?.id,
          optionId: option?.id,
        });
      },
    });
  };

  const cancelRenewal: RenewalActionFn = (renewal) => {
    return executeActionWithConfirmation({
      renewal,
      title: "Cancel Renewal",
      message: "Are you sure you want to cancel this renewal?",
      onConfirm: () => {
        return cancelRenewalAction(renewal?.id, {
          onSuccess: navigateToRenewalsTable,
        });
      },
    });
  };

  const notRenewing: RenewalActionFn = (renewal) => {
    return executeActionWithConfirmation({
      renewal,
      title: "Not Renewing",
      message: "Are you sure want to reject this renewal?",
      onConfirm: () => {
        return rejectRenewal(renewal?.id);
      },
    });
  };

  const addResident: RenewalActionFn = async (renewal) =>
    openModalDialog(AddRenewalLeaseholderModal, { renewal });

  const actionInProgress =
    isSendRenewalOfferRequestLoading ||
    isSendRenewalOfferApprovalReminderLoading ||
    isCreateRenewalOfferLoading ||
    isApproveRenewalOffersLoading ||
    isSendRenewalOffersLoading ||
    isSendRenewalOfferReminderLoading ||
    isWithdrawRenewalOfferLoading ||
    isWithdrawRenewalOfferOptionLoading ||
    isSendRequestRenewalLoading ||
    isCreateRenewalLoading ||
    isRejectRenewalLoading ||
    isCancelRenewalLoading;

  return {
    actionInProgress,
    renewalActions: {
      requestOffer,
      createOffer,
      finishCreatingOffer,
      requestOfferApproval,
      approveOffer,
      approveOffers,
      sendOffer,
      sendOffers,
      sendOfferReminder,
      sendLeaseSignReminder,
      requestRenewalCreation,
      createRenewal,
      finishCreatingRenewal,
      countersign,
      deleteOption,
      contactRenters,
      withdrawOffer,
      cancelRenewal,
      notRenewing,
      addResident,
    },
    executeActionWithConfirmation,
  };
};

export default useRenewalActions;
