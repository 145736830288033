
import { Col, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import get from "lodash/get";
import pluralize from "pluralize";

import { CONCESSION_TYPES_OPTIONS } from "manager/config/constants";
import { SecurityDepositDeductionStatus } from "manager/interfaces/types";
import Container from "shared/components/Container";
import FlexContainer from "shared/components/FlexContainer";
import {
  Checkbox,
  DatePicker,
  MaskedInput,
  RadioGroup,
  Select,
  VeroFormField,
} from "shared/components/Form";
import Icon from "shared/components/Icon";
import { IconLink } from "shared/components/Links";
import Notice from "shared/components/Notice";
import NumberTitle from "shared/components/NumberTitle";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { RegularText, SmallText } from "shared/components/Typography";
import Well from "shared/components/Well";
import { MONTH_FORMAT, YES_NO_OPTIONS } from "shared/config/constants";
import { useDeviceType } from "shared/hooks";
import { addMonthsNormalized, isFirstDayOfMonth } from "shared/utils/dates";
import { getValueOrNA } from "shared/utils/ui";

import { getLeaseDurationOptions } from "../LeaseInfoStep.utils";

import {
  ConcessionMonths,
  LeaseFirstMonthRent,
  LeaseInfoFormValues,
} from "./interfaces";
import LeaseConcessionMonthsDropdown from "./LeaseConcessionMonthsDropdown";
import LeaseConcessionMonthsPicker from "./LeaseConcessionMonthsPicker";
import {
  getSecurityDepositBalanceMessage,
  isSecurityDepositBalanceVisible,
} from "./LeaseInfoForm.utils";
import {
  BalanceFlexContainer,
  ConcessionsSection,
  NetEffectiveRent,
  StyledLeaseForm,
} from "./styled";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import useFormNavigation, {
  CONCESSION_AMORTIZED_ID,
  CONCESSION_MONTHS_ID,
  CONCESSION_TYPES_ID,
} from "./useFormNavigation";

interface LeaseInfoFormProps {
  disabledLeaseInfoFields: object;
  concessionMonths?: ConcessionMonths;
  isConcessionMonthsLoading?: boolean;
  netEffectiveRent?: number;
  onLeaseDurationChange: (value: number) => void;
  onEndDateToEndOfTheMonthChange: (event: CheckboxChangeEvent) => void;
  leaseSecurityDepositStatus: SecurityDepositDeductionStatus;
  securityDepositBalance: number;
  openProRateInfoModal: () => void;
  isProRateEnabled: boolean;
  leaseFirstMonthRent?: LeaseFirstMonthRent;
  isTypeSpecificMonths: boolean;
  isTypeAmortized: boolean;
  disableDate: (current: dayjs.Dayjs) => boolean;
  disablePastDate: (current: dayjs.Dayjs) => boolean;
  maxYears: number;
  minMonths: number;
  onSelectStartDate: (name: string | Date, value: dayjs.Dayjs) => void;
  onSelectEndDate: (name: string | Date, value: dayjs.Dayjs) => void;
  onConcessionFlagChange: (value: string | boolean | number) => void;
  onConcessionTypeChange: (value: string | boolean | number) => void;
  onAfterMonthlyRentChanged: (...any) => void;
}

const LeaseInfoForm = ({
  disabledLeaseInfoFields,
  concessionMonths = {},
  isConcessionMonthsLoading = false,
  netEffectiveRent = undefined,
  onLeaseDurationChange,
  onEndDateToEndOfTheMonthChange,
  leaseSecurityDepositStatus,
  securityDepositBalance,
  openProRateInfoModal,
  isProRateEnabled,
  leaseFirstMonthRent,
  isTypeSpecificMonths,
  isTypeAmortized,
  disableDate,
  maxYears,
  minMonths,
  disablePastDate,
  onSelectStartDate,
  onSelectEndDate,
  onConcessionFlagChange,
  onConcessionTypeChange,
  onAfterMonthlyRentChanged,
}: LeaseInfoFormProps) => {
  const { isMobile } = useDeviceType();
  const { values } = useFormikContext<LeaseInfoFormValues>();

  const formattedStartDate = getValueOrNA(
    values.leaseStartDate && dayjs(values.leaseStartDate).format(MONTH_FORMAT)
  );
  const formattedEndDate = getValueOrNA(
    values.leaseEndDate && dayjs(values.leaseEndDate).format(MONTH_FORMAT)
  );

  const EffectiveRent = (
    <NetEffectiveRent nodesMargin="24px 24px 0 0" flexWrap="wrap">
      <div>
        Lease effective rent will be:&nbsp;
        <strong data-testid="net-effective-rent">
          {printDollarsFromCents(netEffectiveRent)}
        </strong>
      </div>
      {isTypeSpecificMonths && (
        <div>
          Total selected:&nbsp;
          <strong data-testid="concession-months-count">
            {`${values.numberOfMonths} ${pluralize(
              "month",
              values.numberOfMonths
            )}`}
          </strong>
        </div>
      )}
      <div>
        (Leasing period: {formattedStartDate} - {formattedEndDate})
      </div>
    </NetEffectiveRent>
  );

  useFormNavigation();

  const leaseEndDateTooltip = (current: dayjs.Dayjs) => {
    const startDate = dayjs(values.leaseStartDate);
    if (
      values.leaseStartDate &&
      dayjs(current).isBefore(addMonthsNormalized(startDate, 1))
    ) {
      return "The Lease duration cannot be less than 1 month";
    }
    const maxLimit = dayjs(values.leaseStartDate).add(maxYears, "years");
    if (values.leaseStartDate && dayjs(current).isAfter(maxLimit)) {
      return "The end date is not valid";
    }

    const dateDisabled = disableDate(current);

    return dateDisabled
      ? `The lease duration is less than the minimum configured of ${minMonths} ${pluralize(
          "month",
          minMonths
        )}`
      : null;
  };

  const isDepositBalanceVisible = isSecurityDepositBalanceVisible(
    leaseSecurityDepositStatus
  );

  const securityDepositColSize = {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 8,
    xl: 6,
    xxl: 4,
  };

  return (
    <StyledLeaseForm data-testid="lease-info-form">
      <Container noMobilePaddingX noPaddingTop>
        <Tile
          header={{
            title: "Let’s start with the basics",
          }}
        >
          <Tile.Inner noPaddingTop noPaddingBottom>
            <Spacer size={Spacer.SIZES.lg} />
            {/* --- Leasing period --- */}
            <Row>
              <Col>
                <NumberTitle number={1}>
                  <div>
                    <span>What is the leasing period?</span>&nbsp;
                    {!!minMonths && (
                      <RegularText light>
                        (the minimum lease duration is {minMonths}{" "}
                        {pluralize("month", minMonths)})
                      </RegularText>
                    )}
                  </div>
                </NumberTitle>
              </Col>
            </Row>
            <Row justify="start" gutter={[16, 16]}>
              <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={4}>
                <VeroFormField
                  as={DatePicker}
                  id="lease-start-date"
                  name="leaseStartDate"
                  label={isMobile ? "Start Date" : "Lease Start Date"}
                  data-testid="lease-start"
                  disabledDate={disablePastDate}
                  onChange={(value: dayjs.Dayjs) =>
                    onSelectStartDate("leaseStartDate", value)
                  }
                />
              </Col>
              <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={4}>
                <VeroFormField
                  as={DatePicker}
                  id="lease-end-date"
                  name="leaseEndDate"
                  label={isMobile ? "End Date" : "Lease End Date"}
                  onChange={(value: dayjs.Dayjs) =>
                    onSelectEndDate("leaseEndDate", value)
                  }
                  data-testid="lease-end"
                  disabledDate={disableDate}
                  tooltip={leaseEndDateTooltip}
                  disabled={values.endDateToEndOfTheMonth}
                />
              </Col>
            </Row>
            <Row justify="start" gutter={[16, 16]}>
              <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={4}>
                <VeroFormField
                  as={Select}
                  id="leaseDuration"
                  name="leaseDuration"
                  label="Lease Duration"
                  onChange={onLeaseDurationChange}
                  disabled={!values.leaseStartDate}
                  data-testid="lease-duration"
                >
                  {getLeaseDurationOptions(minMonths).map((item) => (
                    <Select.Option
                      key={item}
                      value={item}
                      data-testid={`lease-duration-option-${item}`}
                    >
                      {item} {pluralize("month", item)}
                    </Select.Option>
                  ))}
                </VeroFormField>
              </Col>
            </Row>
            <Row justify="start" gutter={[16, 16]}>
              <Col>
                <VeroFormField
                  as={Checkbox}
                  id="endDateToEndOfTheMonth"
                  name="endDateToEndOfTheMonth"
                  data-testid="endDateToEndOfTheMonth"
                  label="Set end date to end of month"
                  onChange={onEndDateToEndOfTheMonthChange}
                />
              </Col>
            </Row>
            <Spacer size={Spacer.SIZES.lg} />
            {/* --- Monthly Rent & Security Deposit --- */}
            <Row>
              <Col>
                <NumberTitle number={2}>
                  What is the monthly rent and security deposit amount?
                </NumberTitle>
              </Col>
            </Row>
            <Row type="flex" justify="start" gutter={[16, 16]} align="bottom">
              <Col {...securityDepositColSize}>
                <MaskedInput.Formik.Money
                  id="monthly-rent"
                  name="monthlyRent"
                  label={isMobile ? "Rent" : "Monthly Rent"}
                  data-testid="monthly-rent"
                  onAfterChange={onAfterMonthlyRentChanged}
                  disabled={get(disabledLeaseInfoFields, "monthlyRent")}
                />
              </Col>
              <Col {...securityDepositColSize}>
                <MaskedInput.Formik.Money
                  id="security-deposit"
                  name="securityDeposit"
                  label={isMobile ? "Deposit" : "Security Deposit"}
                  disabled={get(disabledLeaseInfoFields, "securityDeposit")}
                  data-testid="security-deposit"
                />
              </Col>
              {isDepositBalanceVisible && (
                <Col {...securityDepositColSize}>
                  <BalanceFlexContainer alignItems="center">
                    <MaskedInput.Money
                      name=""
                      id="security-deposit-balance"
                      label={
                        isMobile
                          ? "Deposit Balance"
                          : "Security Deposit Balance"
                      }
                      disabled
                      data-testid="security-deposit-balance"
                      value={printDollarsFromCents(securityDepositBalance)}
                    />
                    &nbsp;
                    <Tooltip
                      title={getSecurityDepositBalanceMessage(
                        leaseSecurityDepositStatus
                      )}
                    >
                      <Icon.InfoIcon />
                    </Tooltip>
                  </BalanceFlexContainer>
                </Col>
              )}
            </Row>
            <Spacer size={Spacer.SIZES.lg} />

            {/* --- Pro-rate First Month Rent --- */}
            {!isProRateEnabled && (
              <>
                <Well lightError className="warning-well">
                  <Notice type="warning">
                    Please contact your account manager to enable pro-rated rent
                    on your lease documents
                  </Notice>
                </Well>
                <Spacer size={Spacer.SIZES.sm} />
              </>
            )}
            <Row>
              <Col>
                <NumberTitle number={3}>
                  <FlexContainer alignItems="center" nodesMargin="0 8px 0 0">
                    <span> First month’s rent</span>
                    <IconLink
                      Icon={Icon.QuestionBadge}
                      onClick={openProRateInfoModal}
                    />
                  </FlexContainer>
                </NumberTitle>
              </Col>
            </Row>
            <Row>
              <Col className="first-month-rent">
                <VeroFormField
                  as={Checkbox}
                  id="prorateFirstMonthRent"
                  name="prorateFirstMonthRent"
                  data-testid="prorateFirstMonthRent"
                  label="Prorate the first month’s rent"
                  disabled={
                    !isProRateEnabled ||
                    !values.leaseStartDate ||
                    isFirstDayOfMonth(values.leaseStartDate)
                  }
                />
                {values.prorateFirstMonthRent && (
                  <div className="first-month-rent-result">
                    <SmallText light>
                      Days charged in first month:&nbsp;
                      <SmallText light strong>
                        {getValueOrNA(
                          leaseFirstMonthRent?.daysChargedInFirstMonth
                        )}
                      </SmallText>
                    </SmallText>
                    <br />
                    <FlexContainer alignItems="center">
                      <SmallText light>
                        Prorated first month’s rent:&nbsp;
                        <SmallText light strong>
                          {printDollarsFromCents(
                            leaseFirstMonthRent?.firstMonthRent
                          )}
                        </SmallText>
                      </SmallText>
                      <Tooltip title="Value is rounded to the nearest cent. Proration does not include concessions.">
                        <Icon.InfoIcon className="proration-info-icon" />
                      </Tooltip>
                    </FlexContainer>
                  </div>
                )}
              </Col>
            </Row>
            <Spacer size={Spacer.SIZES.lg} />
            {/* --- Concessions --- */}
            <Row gutter={[16, 0]}>
              <Col>
                <NumberTitle number={4}>
                  Are there any concessions offered in this lease?
                </NumberTitle>
              </Col>
            </Row>
            <ConcessionsSection>
              <div className="concession-row concession-radio">
                <VeroFormField
                  as={RadioGroup}
                  id="isConcessionsEnabled"
                  name="isConcessionsEnabled"
                  options={YES_NO_OPTIONS}
                  onChange={onConcessionFlagChange}
                  disabled={!values.hasConcessionDocuments}
                />
              </div>

              {!values.hasConcessionDocuments && (
                <Well lightError className="warning-well">
                  <Notice type="warning">
                    Please contact your account manager to enable a Concession
                    Management document for this functionality
                  </Notice>
                </Well>
              )}

              {values.isConcessionsEnabled && (
                <>
                  <Well
                    noBorder
                    className="concession-well concession-row"
                    id={CONCESSION_TYPES_ID}
                  >
                    <VeroFormField
                      as={RadioGroup}
                      id="concessionType"
                      name="concessionType"
                      options={CONCESSION_TYPES_OPTIONS}
                      onChange={onConcessionTypeChange}
                    />
                  </Well>
                  {isTypeAmortized && (
                    <Well
                      noBorder
                      className="concession-well concession-row"
                      id={CONCESSION_AMORTIZED_ID}
                    >
                      <LeaseConcessionMonthsDropdown
                        concessionMonths={concessionMonths}
                        isConcessionMonthsLoading={isConcessionMonthsLoading}
                      />
                      {EffectiveRent}
                    </Well>
                  )}
                  {isTypeSpecificMonths && (
                    <Well
                      noBorder
                      className="concession-well concession-row"
                      id={CONCESSION_MONTHS_ID}
                    >
                      <LeaseConcessionMonthsPicker
                        concessionMonths={concessionMonths}
                        isConcessionMonthsLoading={isConcessionMonthsLoading}
                      />
                      {EffectiveRent}
                    </Well>
                  )}
                </>
              )}
            </ConcessionsSection>
          </Tile.Inner>
        </Tile>
      </Container>
    </StyledLeaseForm>
  );
};

export default LeaseInfoForm;
