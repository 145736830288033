import React from "react";

import ApplicationGroupIndividualsModal from "renter/components/Modal/ApplicationGroupIndividuals";
import { useGetApplicantProfile } from "renter/hooks/api";
import useModal from "shared/hooks/useModal";

import LeaseIndividuals from "./LeaseIndividuals";

interface LeaseIndividualsContainerProps {
  applicants: {
    id: number;
    email: string;
  }[];
}

const LeaseIndividualsContainer = ({
  applicants = [],
}: LeaseIndividualsContainerProps) => {
  const { applicantProfile } = useGetApplicantProfile();
  const { openModalDialog } = useModal();

  const individualsGroup = () => {
    const applicationId = applicants?.find(
      (item) => item.email === applicantProfile?.email
    )?.id;

    openModalDialog(ApplicationGroupIndividualsModal, {
      applicationId,
    });
  };

  const applicantsCount = applicants.length > 0 ? applicants.length - 1 : 0;

  return (
    <LeaseIndividuals
      individualsGroup={individualsGroup}
      applicantsCount={applicantsCount}
    />
  );
};

export default LeaseIndividualsContainer;
