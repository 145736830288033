import React from "react";

import { Row, Col } from "antd";
import { useFormikContext } from "formik";
import sortBy from "lodash/sortBy";
import PropTypes from "prop-types";

import Container from "shared/components/Container";
import { VeroFormField, RadioGroup } from "shared/components/Form";
import Icon from "shared/components/Icon";
import NumberTitle from "shared/components/NumberTitle";
import Skeleton from "shared/components/Skeleton";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";

import { LabelIconContainer, LeaseQuestionsSection } from "./styled";

const AdditionalRentalOptions = ({ loading }) => {
  const { values } = useFormikContext();

  return (
    <Container noMobilePaddingX noPaddingTop>
      <Tile header={{ title: "Additional rental options" }}>
        <Tile.Inner noPaddingTop noPaddingBottom>
          <Spacer size={Spacer.SIZES.md} />
          <Skeleton loading={loading}>
            <Row>
              <Col>
                <LabelIconContainer alignItems="center">
                  <RegularText>
                    These questions will determine which documents are added to
                    the lease package
                  </RegularText>
                  <Tooltip title="Edit these questions and adjust the selected documents by going to the Property Settings">
                    <Icon.InfoIcon className="extra-info" />
                  </Tooltip>
                </LabelIconContainer>
              </Col>
            </Row>
            <Spacer />
            {values?.managerQuestions?.map(({ questionName, options }, i) => {
              const answersOptionId = `manager-answers-${i}`;
              const answersOptionName = `managerAnswers.${i}.answerOption`;
              const sortedOptions = sortBy(options, "position");

              return (
                <div key={options.position}>
                  <Row>
                    <Col>
                      <NumberTitle number={i + 1}>
                        <RegularText>{questionName}</RegularText>
                      </NumberTitle>
                    </Col>
                  </Row>
                  <LeaseQuestionsSection>
                    <VeroFormField
                      as={RadioGroup}
                      id={answersOptionId}
                      name={answersOptionName}
                      block={options?.length > 2}
                      options={sortedOptions.map(({ answer, id }) => ({
                        value: id,
                        label: answer,
                      }))}
                      required
                    />
                  </LeaseQuestionsSection>
                </div>
              );
            })}
          </Skeleton>
        </Tile.Inner>
      </Tile>
    </Container>
  );
};

AdditionalRentalOptions.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default AdditionalRentalOptions;
