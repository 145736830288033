import React from "react";

import Tile from "shared/components/Tile";
import { leasePropType } from "shared/utils/propTypes";
import { formatDate } from "shared/utils/ui";

const LeaseDetails = ({ lease }) => {
  return (
    <>
      <div>
        <Tile.Label noMb>Lease Start Date</Tile.Label>
        <div data-testid="lease-start-date">{formatDate(lease.startDate)}</div>
      </div>
      <div>
        <Tile.Label noMb>Lease End Date</Tile.Label>
        <div data-testid="lease-end-date">{formatDate(lease.endDate)}</div>
      </div>
    </>
  );
};

LeaseDetails.propTypes = {
  lease: leasePropType.isRequired,
};

export default LeaseDetails;
