import * as FullStory from "@fullstory/browser";

export const identifyFullStorySession = (activeUser) => {
  FullStory?.identify(activeUser.userId, {
    displayName: `${activeUser.firstName} ${activeUser.lastName}`,
    email: activeUser.email,
    companyId: activeUser.companyId,
    environment: activeUser.env,
  });
};
