import React from "react";

import PropTypes from "prop-types";

import { MODALS } from "manager/config/constants";
import { Modal, SIZES } from "shared/components/Modal";

import { ApplicantsCheckList, Subtitle } from "./styled";

const CreateLeaseModal = ({ createLease, isCreatingLease, deal }) => {
  return (
    <Modal
      title="Create the lease now?"
      subtitle={
        <Subtitle>
          <span>Applicants:</span>
          <ApplicantsCheckList inline items={deal?.applicantsSummary} />
        </Subtitle>
      }
      submit={createLease}
      submitting={isCreatingLease}
      submitButtonLabel="Create Lease"
      submitButtonSize={SIZES.lg}
      cancelLinkLabel="I'll do it later"
      id={MODALS.createLease}
    >
      <Modal.Body data-testid="lease-message">
        Application is approved. Now you can create the lease.
      </Modal.Body>
    </Modal>
  );
};

CreateLeaseModal.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.number,
    applicantsSummary: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        isSubmitted: PropTypes.bool.isRequired,
      })
    ),
  }),
  createLease: PropTypes.func.isRequired,
  isCreatingLease: PropTypes.bool.isRequired,
};

CreateLeaseModal.defaultProps = {
  deal: undefined,
};

export default CreateLeaseModal;
