import { useQuery } from "react-query";

import {
  NOTIFICATIONS,
  APPLICATION_ANSWER_TYPES,
} from "shared/config/constants";
import { useGetMarketingSources } from "shared/hooks/api/sourcesQueries";
import api from "shared/lib/api";
import { openNotification } from "shared/utils/ui";
import typeApi from '../../../manager/lib/typeApi.js'

export const TYPES_REQUEST_KEYS = Object.freeze({
  getIndustries: () => ["getIndustries"],
  getKnobRiderOptions: () => ["getKnobRiderOptions"],
  getWindowGuardOptions: () => ["getWindowGuardOptions"],
  getKidOptions: () => ["getKidOptions"],
  getApplicationStatuses: () => ["getApplicationStatuses"],
  getApplicantTypes: () => ["getApplicantTypes"],
  getLeaseStatuses: () => ["getLeaseStatuses"],
  getRenewalStatuses: () => ["getRenewalStatuses"],
  getDealStatuses: () => ["getDealStatuses"],
  getDealCategories: () => ["getDealCategories"],
  getRiderTypes: () => ["getRiderTypes"],
  getDocumentTypes: () => ["getDocumentTypes"],
  getLivingSituations: () => ["getLivingSituations"],
  getDurationTypes: () => ["getDurationTypes"],
  getGetLeaseReminderFrequencies: () => ["getLeaseReminderFrequencies"],
  getEmergencyContactTypes: () => ["getEmergencyContactTypes"],
  getCountyCriminalSearchSyncOptions: () => [
    "getCountyCriminalSearchSyncOptions",
  ],
  getPropertyTypes: () => ["getPropertyTypes"],
  getCountries: () => ["getCountries"],
  getPets: () => ["getPets"],
});

export const useIndustries = () => {
  const response = useQuery(
    TYPES_REQUEST_KEYS.getIndustries(),
    () => api.getIndustries().then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load industries", NOTIFICATIONS.error),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return response;
};

export const useKnobRiderOptions = () => {
  const response = useQuery(
    TYPES_REQUEST_KEYS.getKnobRiderOptions(),
    () => api.getKnobRiderOptions().then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load knob rider question options",
          NOTIFICATIONS.error
        ),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return response;
};

export const useWindowGuardOptions = () => {
  const response = useQuery(
    TYPES_REQUEST_KEYS.getWindowGuardOptions(),
    () => api.getWindowGuardOptions().then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load window guard question options",
          NOTIFICATIONS.error
        ),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return response;
};

export const useKidOptions = () => {
  const response = useQuery(
    TYPES_REQUEST_KEYS.getKidOptions(),
    () => api.getKidOptions().then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load kids question options",
          NOTIFICATIONS.error
        ),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return response;
};

export const useAdditionalQuestionTypes = (propertyId) => {
  const { data: kidOptions, isLoading: kidOptionsLoading } = useKidOptions();
  const { data: windowGuardOptions, isLoading: windowGuardOptionsLoading } =
    useWindowGuardOptions();
  const { data: knobRiderOptions, isLoading: knobRiderOptionsLoading } =
    useKnobRiderOptions();
  const { data: industryOptions, isLoading: industryOptionsLoading } =
    useIndustries();
  const {
    marketingSources: marketingSourceOptions,
    isLoading: marketingSourceOptionsLoading,
  } = useGetMarketingSources(propertyId);

  return {
    data: {
      [APPLICATION_ANSWER_TYPES.kid]: kidOptions,
      [APPLICATION_ANSWER_TYPES.windowGuard]: windowGuardOptions,
      [APPLICATION_ANSWER_TYPES.knobRider]: knobRiderOptions,
      [APPLICATION_ANSWER_TYPES.industry]: industryOptions,
      [APPLICATION_ANSWER_TYPES.marketingSource]: marketingSourceOptions,
    },
    isLoading:
      kidOptionsLoading ||
      windowGuardOptionsLoading ||
      knobRiderOptionsLoading ||
      industryOptionsLoading ||
      marketingSourceOptionsLoading,
  };
};

export const useApplicationStatuses = () => {
  const response = useQuery(
    TYPES_REQUEST_KEYS.getApplicationStatuses(),
    () => api.getApplicationStatuses().then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load application statuses",
          NOTIFICATIONS.error
        ),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return response;
};

export const useApplicantTypes = () => {
  const response = useQuery(
    TYPES_REQUEST_KEYS.getApplicantTypes(),
    () => api.getApplicantTypes().then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load applicant types", NOTIFICATIONS.error),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return response;
};

export const useLeaseStatuses = () => {
  const response = useQuery(
    TYPES_REQUEST_KEYS.getLeaseStatuses(),
    () => api.getLeaseStatuses().then(({ data }) => data),
    {
      onError: () => openNotification("Failed to load lease statuses"),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return response;
};

export const useRenewalStatuses = () => {
  const { data: renewalStatuses, isLoading: isRenewalStatusesLoading } =
    useQuery(
      TYPES_REQUEST_KEYS.getRenewalStatuses(),
      () => api.getRenewalStatuses().then(({ data }) => data),
      {
        onError: () => openNotification("Failed to load the renewal statuses"),
        cacheTime: Infinity,
        staleTime: Infinity,
      }
    );
  return {
    renewalStatuses,
    isRenewalStatusesLoading,
  };
};

export const useDealStatuses = () => {
  const response = useQuery(
    TYPES_REQUEST_KEYS.getDealStatuses(),
    () => api.getDealStatuses().then(({ data }) => data),
    {
      onError: () => openNotification("Failed to load deal statuses"),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return response;
};

export const useDealCategories = () => {
  const response = useQuery(
    TYPES_REQUEST_KEYS.getDealCategories(),
    () => api.getDealCategories().then(({ data }) => data),
    {
      onError: () => openNotification("Failed to load deal categories"),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return response;
};

export const useGetRiderTypes = () => {
  const { data: riderTypes, isLoading: isRiderTypesLoading } = useQuery(
    TYPES_REQUEST_KEYS.getRiderTypes(),
    () => api.getRiderTypes().then(({ data }) => data),
    {
      onError: () => openNotification("Failed to load rider types"),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return {
    riderTypes,
    isRiderTypesLoading,
  };
};

export const useGetLivingSituations = () => {
  const response = useQuery(
    TYPES_REQUEST_KEYS.getLivingSituations(),
    () => api.getLivingSituationTypes().then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load living situations",
          NOTIFICATIONS.error
        ),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return response;
};

export const useGetDocumentTypes = () => {
  const { data: documentTypes, isLoading: isDocumentTypesLoading } = useQuery(
    TYPES_REQUEST_KEYS.getDocumentTypes(),
    () => api.getDocumentTypes().then(({ data }) => data),
    {
      onError: () => openNotification("Failed to load document types"),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return {
    documentTypes,
    isDocumentTypesLoading,
  };
};

export const useGetDurationTypes = () => {
  const { data: durationTypes, isLoading: isDurationTypesLoading } = useQuery(
    TYPES_REQUEST_KEYS.getDurationTypes(),
    () => api.getDurationTypes().then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load duration types", NOTIFICATIONS.error),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return {
    durationTypes,
    isDurationTypesLoading,
  };
};

export const useAnswerTypeOptions = () => {
  const { data: answerTypeOptions, isLoading: isAnswerTypeOptionsLoading } =
    useQuery(
      ["getAnswerTypeOptions"],
      () => api.getAnswerTypeOptions().then(({ data }) => data),
      {
        onError: () =>
          openNotification(
            "Failed to load answer type options",
            NOTIFICATIONS.error
          ),
        cacheTime: Infinity,
        staleTime: Infinity,
      }
    );
  return { answerTypeOptions, isAnswerTypeOptionsLoading };
};

export const useGetLeaseReminderFrequencies = () => {
  const { data: frequencies, isLoading: isFrequenciesLoading } = useQuery(
    TYPES_REQUEST_KEYS.getGetLeaseReminderFrequencies(),
    () => api.getLeaseReminderFrequencies().then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load lease reminder frequencies",
          NOTIFICATIONS.error
        ),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return {
    frequencies,
    isFrequenciesLoading,
  };
};

export const useCountyCriminalSearchSyncOptions = () => {
  const {
    data: countyCriminalSearchSyncOptions,
    isLoading: isCountyCriminalSearchSyncOptionsLoading,
  } = useQuery(
    TYPES_REQUEST_KEYS.getCountyCriminalSearchSyncOptions(),
    () => api.getCountyCriminalSearchSyncOptions().then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load the county criminal search sync options",
          NOTIFICATIONS.error
        ),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return {
    countyCriminalSearchSyncOptions: countyCriminalSearchSyncOptions || [],
    isCountyCriminalSearchSyncOptionsLoading,
  };
};

export const useEmergencyContactTypes = () => {
  const {
    data: emergencyContactTypes,
    isLoading: isEmergencyContactTypesLoading,
  } = useQuery(
    TYPES_REQUEST_KEYS.getEmergencyContactTypes(),
    () => api.getEmergencyContactTypes().then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load emergency contact types",
          NOTIFICATIONS.error
        ),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return { emergencyContactTypes, isEmergencyContactTypesLoading };
};

export const usePropertyTypes = () => {
  const { data: propertyTypes } = useQuery(
    TYPES_REQUEST_KEYS.getPropertyTypes(),
    api.getPropertyTypes,
    {
      onError: () =>
        openNotification("Failed to load property types", NOTIFICATIONS.error),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return {
    propertyTypes,
  };
};

export const useGetCountries = () => {
  const { data: countries, isLoading: isCountriesLoading } = useQuery(
    TYPES_REQUEST_KEYS.getCountries(),
    api.getCountries,
    {
      onError: () =>
        openNotification("Failed to load countries", NOTIFICATIONS.error),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return {
    countries,
    isCountriesLoading,
  };
};

export const usePets = () => {
  const { data: pets, isLoading: isPetsLoading } = useQuery(
    TYPES_REQUEST_KEYS.getPets(),
    api.getPets,
    {
      onError: () =>
        openNotification("Failed to load pets", NOTIFICATIONS.error),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return {
    pets,
    isPetsLoading,
  };
};

export const useGetApplicantTypes = () => {
  const { data: applicantTypes } = useQuery(
    ["getApplicantTypes"],
    typeApi.getApplicantTypes,
    { cacheTime: Infinity, staleTime: Infinity }
  );

  return { applicantTypes };
};
