import React, { useEffect } from "react";

import { Document } from "admin/pages/Documents/interfaces";
import { useDocumentPreview } from "manager/hooks/api";

import PreviewDocumentModal from "./PreviewDocumentModal";

const PreviewDocumentTemplateModalContainer = ({
  document,
}: {
  document: Document;
}) => {
  const { previewDocument, isLoading, data: base64 } = useDocumentPreview();

  useEffect(() => {
    if (document?.id) {
      previewDocument(document.id);
    }
  }, []);

  return (
    <PreviewDocumentModal
      filename={document.name}
      base64={base64}
      loading={isLoading}
    />
  );
};

export default PreviewDocumentTemplateModalContainer;
