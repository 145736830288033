import React from "react";

import { Row, Col } from "antd";
import { Formik } from "formik";

import { Input, VeroFormField } from "shared/components/Form";
import { Modal } from "shared/components/Modal";

import { ValidationSchema } from "./config";
import { BluemoonCounterSignModalProps, FormValues } from "./interfaces";

const BluemoonCounterSignModal = ({
  managerName,
  submit,
}: BluemoonCounterSignModalProps) => {
  const initialValues: FormValues = {
    name: managerName || "",
    title: "",
    initials: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={ValidationSchema}
      validateOnMount
    >
      {({ submitForm, isSubmitting, isValid, errors }) => (
        <Modal
          submit={submitForm}
          submitting={isSubmitting}
          submitButtonDisabled={!isValid}
          submitButtonLabel="Countersign"
          title="Countersign"
          subtitle="Countersign the lease with your electronic signature by entering your information and clicking the button."
        >
          <Modal.Body>
            <Row gutter={[20, 25]}>
              <Col xs={{ span: 24 }}>
                <VeroFormField
                  as={Input}
                  name="name"
                  id="name"
                  label="Full Name"
                />
              </Col>
            </Row>
            <Row gutter={[20, 25]}>
              <Col xs={{ span: 24 }}>
                <VeroFormField
                  as={Input}
                  name="title"
                  id="title"
                  label="Title"
                />
              </Col>
            </Row>
            <Row gutter={[20, 25]}>
              <Col xs={{ span: 24 }}>
                <VeroFormField
                  as={Input}
                  name="initials"
                  id="initials"
                  label="Initials"
                  error={errors?.initials}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

export default BluemoonCounterSignModal;
