import { useFormikContext } from "formik";
import keyBy from "lodash/keyBy";
import startCase from "lodash/startCase";
import { useMemo } from "react";
import FlexContainer from "shared/components/FlexContainer";
import {
  Checkbox,
  Select,
  Select4,
  SwitchTitle,
  VeroFormField,
} from "shared/components/Form";
import { Select4Option } from "shared/components/Form/Select4";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { RegularText, SmallText } from "shared/components/Typography";
import Well from "shared/components/Well";
import { useDeviceType } from "shared/hooks";
import { printDollarsFromCents } from "shared/utils/dollar-print";
import { LeaseFieldsStepValues } from "../../interfaces";
import {
  getApplyToTotalRentOnNameChange,
  getOnFeeItemSelect,
} from "./LeaseFeeFieldsSummary/utils";
import { GrayCol, GrayRow, HeaderCol, HeaderRow } from "./styled";
import { generateQuantityOptions, getRentableFieldName } from "./utils";

interface LeaseFeeFieldsRentableItemsProps {
  feeFieldsList?: string[];
}

const LeaseFeeFieldsRentableItems = ({
  feeFieldsList = [],
}: LeaseFeeFieldsRentableItemsProps) => {
  const { values, setFieldValue } = useFormikContext<LeaseFieldsStepValues>();
  const { isMobileOrTablet } = useDeviceType();
  const tileInnerClassName = !isMobileOrTablet ? "border-bottom" : undefined;

  const { feeConfiguration } = values;
  const { rentableItems = [] } = feeConfiguration;
  const quantityOptions = useMemo(() => generateQuantityOptions(), []);
  const selectedFields = keyBy(rentableItems, "name");
  const onFeeItemSelect = getOnFeeItemSelect(
    "rentableItems",
    setFieldValue,
    values
  );

  const onChangeRentableItemName = getApplyToTotalRentOnNameChange(
    "rentableItems",
    setFieldValue,
    values
  );

  return (
    <Tile.Inner className={tileInnerClassName}>
      <Spacer size={Spacer.SIZES.sm} />
      <RegularText strong>RENTABLE ITEMS</RegularText>
      <br />
      <SmallText light>Recurring Monthly Payments</SmallText>
      {rentableItems.length > 0 ? (
        <>
          <Spacer size={Spacer.SIZES.sm} />
          <HeaderRow>
            <HeaderCol xs={12} lg={8}>
              <SmallText light> Add to Lease </SmallText>
            </HeaderCol>
            {!isMobileOrTablet && (
              <>
                <HeaderCol lg={4}>
                  <SmallText light> Quantity</SmallText>
                </HeaderCol>
                <HeaderCol lg={8} />
              </>
            )}
            <HeaderCol
              xs={12}
              lg={4}
              textAlignRight={isMobileOrTablet}
              className="total-rent-title"
            >
              <SmallText light>
                Include in {isMobileOrTablet && <br />} Total Rent
              </SmallText>
            </HeaderCol>
          </HeaderRow>
          {rentableItems.map((item, i) => {
            const CheckboxField = (
              /* @ts-ignore */
              <VeroFormField
                id={getRentableFieldName("isSelected", i)}
                name={getRentableFieldName("isSelected", i)}
                as={Checkbox}
                label={item.feeLabel}
                onChange={(event) => onFeeItemSelect(event, i)}
              />
            );

            const BaseAmountLabel = (
              /* @ts-ignore */
              <RegularText light className="fee-amount">
                {printDollarsFromCents(item.baseAmount)}
              </RegularText>
            );

            const QuantitySelect = (
              /* @ts-ignore */
              <VeroFormField
                id={getRentableFieldName("quantity", i)}
                name={getRentableFieldName("quantity", i)}
                as={Select}
                label={item.feeLabel}
                className="quantity-select"
                disabled={!item.isSelected}
              >
                {quantityOptions}
              </VeroFormField>
            );

            const FeeFieldsSelect = (
              /* @ts-ignore */
              <VeroFormField
                as={Select4}
                id={getRentableFieldName("name", i)}
                name={getRentableFieldName("name", i)}
                label="Select associated field"
                disabled={!item.isSelected}
                allowClear
                defaultValue=""
                onChange={(value: string) => onChangeRentableItemName(value, i)}
              >
                {feeFieldsList.map((field) => (
                  <Select4Option
                    key={field}
                    value={field}
                    disabled={Boolean(selectedFields[field])}
                  >
                    {startCase(field)}
                  </Select4Option>
                ))}
              </VeroFormField>
            );

            const ApplyTotalRent = (
              /* @ts-ignore */
              <VeroFormField
                as={SwitchTitle}
                id={getRentableFieldName("appliedToTotalRent", i)}
                name={getRentableFieldName("appliedToTotalRent", i)}
                size="small"
                disabled
              />
            );

            return !isMobileOrTablet ? (
              <GrayRow key={item.feeLabel}>
                <GrayCol lg={8} noBorderRight>
                  <FlexContainer
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {CheckboxField}
                    {BaseAmountLabel}
                  </FlexContainer>
                </GrayCol>
                <GrayCol lg={4} noPaddingLeft>
                  {QuantitySelect}
                </GrayCol>
                <GrayCol lg={8}>{FeeFieldsSelect}</GrayCol>
                <GrayCol lg={4}>{ApplyTotalRent}</GrayCol>
              </GrayRow>
            ) : (
              <GrayRow key={item.feeLabel}>
                <GrayCol xs={24} noBorderRight>
                  <FlexContainer
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {CheckboxField}
                    {ApplyTotalRent}
                  </FlexContainer>
                </GrayCol>
                <GrayCol xs={24} paddingLeftLg>
                  <FlexContainer
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {BaseAmountLabel}
                    {QuantitySelect}
                  </FlexContainer>
                  <Spacer size={Spacer.SIZES.sm} />
                </GrayCol>
                <GrayCol xs={24}>{FeeFieldsSelect}</GrayCol>
              </GrayRow>
            );
          })}
        </>
      ) : (
        <>
          <Spacer size={Spacer.SIZES.md} />
          <Well noBorder>
            <SmallText light>
              This lease package does not contain any available rentable items.
            </SmallText>
          </Well>
        </>
      )}
    </Tile.Inner>
  );
};

export default LeaseFeeFieldsRentableItems;
