import { useQuery } from "react-query";

import documentsApi from "manager/lib/documentsApi";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

export const useGetPortfolioLeaseSetup = (id) => {
  const {
    data: documentTemplates,
    isDocumentTemplatesLoading,
    refetch: refetchDocumentTemplates,
  } = useQuery(
    ["getPortfolioDocumentTemplates", id],
    () => documentsApi.getPortfolioDocumentTemplates(id),
    {
      onError: () => {
        openNotification(
          "Failed to load document templates.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    documentTemplates,
    isLoading: isDocumentTemplatesLoading,
    refetchSetup: () => {
      refetchDocumentTemplates();
    },
  };
};
