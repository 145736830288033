import styled from "@emotion/styled";

import Tile from "shared/components/Tile";
import { COLORS } from "shared/config/constants";
import { AlignedCenterFlexPartial } from "shared/styles/commonStyles";

export const RenewalOfferTile = styled(Tile)`
  box-shadow: 0 1px 8px ${COLORS.darkGrayShadow};
  border: none;

  .tile-header {
    background-color: ${COLORS.violetTheme.lightViolet};
    border-radius: 2px 2px 0 0;
    span {
      color: ${COLORS.white} !important;
    }
  }

  .property-details {
    width: calc(100% - 140px);

    span {
      overflow-wrap: anywhere;
    }

    svg {
      margin-right: 8px;
      width: 18px;
      min-width: 18px;
    }
  }

  .available-offers-text {
    display: flex;
    color: ${COLORS.accentDark};
  }

  .select-offer-text {
    color: ${COLORS.violetTheme.lightViolet};
  }

  .offer-option {
    ${AlignedCenterFlexPartial};
    padding: 0 24px;
    border-radius: 2px;
    background-color: ${COLORS.tile.alt.innerBackground};
    width: 100%;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    * {
      color: ${COLORS.white} !important;
    }

    .offer-option-data-row {
      flex: 1;
      background-color: initial;
    }

    .ant-radio-inner {
      background-color: ${COLORS.tile.alt.innerBackground};
    }

    &.dark {
      background-color: ${COLORS.darkViolet3};

      .ant-radio-inner {
        background-color: ${COLORS.darkViolet3};
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .ant-radio-group {
    width: 100%;
  }
`;
