import styled from "@emotion/styled";
import { Switch } from "antd";

import { ActionLink } from "shared/components/Links";
import Skeleton from "shared/components/Skeleton";
import { COLORS } from "shared/config/constants";

export const StyledSwitch = styled(Switch)<{ checked?: boolean }>`
  background: ${({ checked }) => (checked ? COLORS.radio.default : "")};
`;

export const StyledSkeleton = styled(Skeleton)`
  padding-left: 40px;
  padding-right: 40px;
`;

export const FieldsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FieldStatus = styled.span`
  padding: 0 11px 0 8px;
`;

export const FieldActionLink = styled(ActionLink)`
  font-size: 12px;
`;
