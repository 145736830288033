import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const Container = styled.div`
  > * {
    padding-top: 40px;
    &:last-child {
      padding-bottom: 40px;
    }
  }
`;

const CheckboxContainer = styled.div`
  margin-right: 16px;
  color: ${COLORS.primary};
  font-size: 17px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    padding: 32px 0;
  }
  ${MEDIA_QUERIES.w.gteLg} {
    padding: 40px 0;
  }
`;

export { Container, CheckboxContainer };
