import React, { useRef } from "react";

import { useParams } from "react-router-dom";

import { getPropertyFields, useGetPropertySettings } from "manager/hooks/api";
import { BackLinkProps } from "shared/interfaces/misc";

import LeaseSetupForm from "../LeaseSetupForm/LeaseSetupFormContainer";

import SetupAllFields from "./SetupAllFields";

interface SetupAllFieldsContainerProps {
  backLink: BackLinkProps;
}

const SetupAllFieldsContainer = ({
  backLink,
}: SetupAllFieldsContainerProps) => {
  const setupAllFieldsForm = useRef();
  const { id: propertyId }: { id?: string } = useParams();

  const { fields, isLoadingFields, refetchFields } =
    getPropertyFields(propertyId);

  const { propertySettings } = useGetPropertySettings(propertyId);

  return (
    <LeaseSetupForm
      fields={fields}
      refetch={refetchFields}
      formRef={setupAllFieldsForm}
    >
      <SetupAllFields
        loading={isLoadingFields}
        propertyName={propertySettings?.name}
        backLink={backLink}
      />
    </LeaseSetupForm>
  );
};

export default SetupAllFieldsContainer;
