import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";

const LabelIconContainer = styled(FlexContainer)`
  .extra-info {
    margin-left: 10px;
  }
`;

export { LabelIconContainer };
