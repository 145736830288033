import React from "react";

import { Modal } from "shared/components/Modal";
import SelectedItemList from "shared/components/SelectedItemList";

import { DocumentsToPropertiesModalProps } from "./interfaces";
import SelectProperties from "./SelectProperties";
import { Note, ModalBody } from "./styled";
import { getSelectedProperties } from "./utils";

const DocumentsToPropertiesModal = ({
  isSubmitting,
  portfolios = [],
  portfoliosFetching,
  document,
  dirty,
  saveChanges,
  searchingValue,
  displayedPortfolios,
  checkedItems,
  indeterminatePortfolios,
  setCheckedItems,
  setSearchingValue,
  setIndeterminatePortfolios,
  isShowWarning,
}: DocumentsToPropertiesModalProps) => (
  <Modal
    title="Manage Document Availability"
    subtitle={`Document: ${document?.name}`}
    submitButtonLabel="CONFIRM"
    submitButtonDisabled={!dirty}
    submit={saveChanges}
    submitting={isSubmitting}
    width="auto"
    style={{ maxWidth: 946 }}
  >
    <ModalBody>
      <Note>The document will be available for properties selected below</Note>
      <SelectProperties
        searchingValue={searchingValue}
        portfolios={displayedPortfolios}
        portfoliosFetching={portfoliosFetching}
        checkedItems={checkedItems}
        indeterminatePortfolios={indeterminatePortfolios}
        setSearchingValue={setSearchingValue}
        setCheckedItems={setCheckedItems}
        setIndeterminatePortfolios={setIndeterminatePortfolios}
        showWarning={isShowWarning()}
      />
      {!portfoliosFetching && (
        <SelectedItemList
          label="property"
          items={getSelectedProperties(portfolios, checkedItems)}
        />
      )}
    </ModalBody>
  </Modal>
);

export default DocumentsToPropertiesModal;
