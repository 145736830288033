import React from "react";

import pluralize from "pluralize";

import { UnderlineLink } from "shared/components/Links";

import { LeaseIndividualsProps } from "../interfaces";

import { ApplicantCount } from "./styled";

const LeaseIndividuals = ({
  applicantsCount,
  individualsGroup,
}: LeaseIndividualsProps) => (
  <ApplicantCount data-testid="applicants-count">
    The lease includes {applicantsCount} other{" "}
    {applicantsCount > 0 ? (
      <UnderlineLink data-testid="individuals" onClick={individualsGroup}>
        {pluralize("individuals", applicantsCount)}
      </UnderlineLink>
    ) : (
      "individuals"
    )}
  </ApplicantCount>
);

export default LeaseIndividuals;
