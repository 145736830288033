import React from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";

import { SecurityDepositStatusPropType } from "manager/utils/propTypes";
import FooterBar from "shared/components/FooterBar";
import FormErrors from "shared/components/FormErrors";
import { CONCESSION_TYPES } from "shared/config/constants";

import AdditionalRentalOptions from "./AdditionalRentalOptions";
import LeaseInfoFooter from "./LeaseInfoFooter";
import LeaseInfoForm from "./LeaseInfoForm";
import { validationSchema } from "./LeaseInfoStep.utils";

const LeaseInfoStep = ({
  leaseFormData,
  formikRef,
  onFinishLater,
  onSubmitStep,
  disabledLeaseInfoFields,
  leaseSecurityDepositStatus,
  managerQuestionsData,
  managerQuestionsDataLoading,
  leaseId,
  isProRateEnabled,
}) => {
  return (
    <Formik
      onSubmit={onSubmitStep}
      initialValues={leaseFormData}
      enableReinitialize
      validationSchema={validationSchema(leaseFormData?.minLeaseDurationMonths)}
      innerRef={formikRef}
    >
      <>
        <FormErrors />
        <LeaseInfoForm
          disabledLeaseInfoFields={disabledLeaseInfoFields}
          leaseSecurityDepositStatus={leaseSecurityDepositStatus}
          leaseId={leaseId}
          isProRateEnabled={isProRateEnabled}
        />
        {managerQuestionsData?.managerQuestions?.length > 0 && (
          <AdditionalRentalOptions loading={managerQuestionsDataLoading} />
        )}
        <FooterBar.Spacer />
        <LeaseInfoFooter
          onFinishLater={onFinishLater}
          onSubmitStep={onSubmitStep}
        />
      </>
    </Formik>
  );
};

LeaseInfoStep.propTypes = {
  leaseFormData: PropTypes.shape({
    leaseStartDate: PropTypes.string,
    leaseEndDate: PropTypes.string,
    monthlyRent: PropTypes.number,
    securityDeposit: PropTypes.number,
    minLeaseDurationMonths: PropTypes.number,
    isConcessionsEnabled: PropTypes.bool,
    concessionType: PropTypes.oneOf(Object.values(CONCESSION_TYPES)),
    numberOfMonths: PropTypes.number,
    dates: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        isHalfMonth: PropTypes.bool,
      })
    ),
  }),
  managerQuestionsData: PropTypes.shape({
    managerQuestions: PropTypes.arrayOf(PropTypes.object),
    managerAnswers: PropTypes.arrayOf(PropTypes.object),
  }),
  managerQuestionsDataLoading: PropTypes.bool,
  formikRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  onFinishLater: PropTypes.func.isRequired,
  onSubmitStep: PropTypes.func.isRequired,
  disabledLeaseInfoFields: PropTypes.object.isRequired,
  leaseSecurityDepositStatus: SecurityDepositStatusPropType.isRequired,
  leaseId: PropTypes.number.isRequired,
  isProRateEnabled: PropTypes.bool.isRequired,
};

LeaseInfoStep.defaultProps = {
  formikRef: undefined,
  leaseFormData: undefined,
  managerQuestionsData: undefined,
  managerQuestionsDataLoading: false,
};

export default LeaseInfoStep;
