import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";
import { MEDIA_QUERIES } from "shared/config/constants";

const StyledPreviewPdfModal = styled(Modal)`
  top: 50px;
  height: calc(100% - 100px);
  overflow: hidden;
  max-width: 950px !important;
  width: 90% !important;

  .ant-modal-content {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    overflow: hidden;
    flex-grow: 1;
    position: relative;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    top: 20px;
    height: calc(100% - 40px);
    width: 98% !important;
  }
`;

const StyledPreviewPdfModalBody = styled(Modal.Body)`
  width: 100%;
  height: 100%;

  ${MEDIA_QUERIES.w.ltMd} {
    padding-bottom: 20px;
  }
`;

export { StyledPreviewPdfModal, StyledPreviewPdfModalBody };
