import styled from "@emotion/styled";

import { ActionLink } from "shared/components/Links";
import { COLORS } from "shared/config/constants";

export const InfoContent = styled("div")({});

export const MessageContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  gap: 15,
  marginTop: 15,
  padding: "16px 20px",
  backgroundColor: COLORS.background,
});

export const IconWrapper = styled("div")({
  height: 30,
  width: 30,
  marginRight: "10px",
});

export const Message = styled("div")({
  fontSize: "15px",
  fontWeight: 500,
  lineHeight: 1.3,
  flex: 1,
});

export const UploadLeaseButton = styled(ActionLink)({
  margin: "20px 0",
});

export const DocumentWrapper = styled("div")({
  fontSize: "15px",
  fontWeight: 400,
  width: "100%",
  display: "flex",
  alignItems: "center",
});

export const DocumentName = styled("div")({
  width: "100%",
  marginRight: "10px",
});

export const RemoveIconWrapper = styled("div")({
  height: 16,
  width: 16,
});
