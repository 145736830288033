import { RenewalsSettings } from "manager/interfaces/api/renewalsSettings";
import { Permissions } from "shared/hooks/usePermissions";
import { conditionalItem } from "shared/utils/array";

export const renewalsInitialValues: RenewalsSettings = {
  renewalEnabled: false,
  useNoticeSchedule: false,
  notification120Enabled: false,
  notification90Enabled: false,
  notification60Enabled: false,
  notification30Enabled: false,
  notification120EmailId: null,
  notification90EmailId: null,
  notification60EmailId: null,
  notification30EmailId: null,
};

export interface NoticeOption {
  name: string;
  title: string;
  emailName: string;
}

export const getNoticeOptions = () => {
  const fieldRegExp = /notification(\d+)Enabled/;

  return Object.keys(renewalsInitialValues)
    .filter((key) => key.match(fieldRegExp))
    .map<NoticeOption>((key) => {
      const [, days] = key.match(fieldRegExp);

      return {
        name: key,
        title: `${days} Day Notice`,
        emailName: `notification${days}EmailId`,
      };
    });
};

export const DEFAULT_EMAIL_KEY = "DEFAULT_EMAIL_KEY";

export const getEmailConfigurationTooltip = (permissions: Permissions) => {
  const { isEmailCustomizationEnabled, canCustomizeEmails } = permissions;

  return [
    ...conditionalItem(
      !isEmailCustomizationEnabled,
      "Email customization is not enabled for this company."
    ),
    ...conditionalItem(
      !canCustomizeEmails,
      "You do not have access to Email Templates; contact your account manager."
    ),
  ].join(" ");
};
