import React from "react";

import { Col, Row } from "antd";
import PropTypes from "prop-types";

import Container from "shared/components/Container";
import Tile from "shared/components/Tile";

import DocumentsTile from "./DocumentsTile";
import FinalDocumentsPackage from "./FinalDocumentsPackage";

const LeaseDocuments = ({
  groupedDocuments,
  finalPackageDocuments,
  onDragEnd,
  toggleDocument,
  removeDocumentFromPackage,
  previewDocument,
  isBluemoonLease,
}) => (
  <Container noMobilePaddingX noPaddingTop>
    <Row gutter={[{ xs: 0, md: 32 }, 0]}>
      <Col xs={24} sm={24} md={24} lg={18} xl={18}>
        <Tile>
          {groupedDocuments?.defaultDocuments && (
            <DocumentsTile
              title="Default Documents"
              documents={groupedDocuments.defaultDocuments}
              previewDocument={previewDocument}
              disabled
              defaultCheckedState
              data-testid="default-documents"
              hidePreview={isBluemoonLease}
            />
          )}

          {groupedDocuments?.additionalDocuments?.length > 0 && (
            <DocumentsTile
              title="Additional Applicant Services"
              documents={groupedDocuments.additionalDocuments}
              finalPackageDocuments={finalPackageDocuments}
              toggleDocument={toggleDocument}
              previewDocument={previewDocument}
              defaultCheckedState
              data-testid="additional-documents"
              hidePreview={isBluemoonLease}
            />
          )}

          {groupedDocuments?.optionalDocuments?.length > 0 && (
            <DocumentsTile
              title="Optional Documents"
              documents={groupedDocuments.optionalDocuments}
              finalPackageDocuments={finalPackageDocuments}
              toggleDocument={toggleDocument}
              previewDocument={previewDocument}
              data-testid="optional-documents"
              hidePreview={isBluemoonLease}
            />
          )}
        </Tile>
      </Col>
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        <FinalDocumentsPackage
          documents={finalPackageDocuments}
          removeDocumentFromPackage={removeDocumentFromPackage}
          previewDocument={previewDocument}
          onDragEnd={onDragEnd}
          isBluemoonLease={isBluemoonLease}
        />
      </Col>
    </Row>
  </Container>
);

LeaseDocuments.propTypes = {
  groupedDocuments: PropTypes.shape({
    defaultDocuments: PropTypes.arrayOf(PropTypes.object),
    optionalDocuments: PropTypes.arrayOf(PropTypes.object),
    additionalDocuments: PropTypes.arrayOf(PropTypes.object),
  }),
  finalPackageDocuments: PropTypes.arrayOf(PropTypes.object),
  onDragEnd: PropTypes.func.isRequired,
  toggleDocument: PropTypes.func.isRequired,
  removeDocumentFromPackage: PropTypes.func.isRequired,
  previewDocument: PropTypes.func.isRequired,
  isBluemoonLease: PropTypes.bool,
};

LeaseDocuments.defaultProps = {
  groupedDocuments: {},
  finalPackageDocuments: [],
  isBluemoonLease: false,
};

export default LeaseDocuments;
