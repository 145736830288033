import axios from "axios";

import ROUTES from "renter/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getLeases: () => axios.get(`${API_URL}${ROUTES.leases}`),
  getLease: ({ leaseApplicationId }) =>
    axios.post(`${API_URL}${ROUTES.lease}`, {
      leaseApplication: leaseApplicationId,
    }),
  getFinalLeaseDocument: ({ leaseApplicationId }) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.viewFinalLease, {
        leaseApplicationId,
      })}`
    ),
  acceptOffer: ({ leaseApplicationId, optionId }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.acceptOffer, {
        leaseApplicationId,
        optionId,
      })}`
    ),
  rejectOffer: ({ leaseApplicationId }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.rejectOffer, {
        leaseApplicationId,
      })}`
    ),
  getLeaseApplication: ({ leaseApplicationId }) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.leaseApplication, {
        leaseApplicationId,
      })}`
    ),
  getApplicantSignUrl: ({ leaseApplicationId }) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.applicantSignUrl, {
        leaseApplicationId,
      })}`
    ),
  processSignature: ({ leaseApplicationId }) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.processSignature, {
        leaseApplicationId,
      })}`
    ),
  trackDownloadStatistic: ({ leaseApplicationId }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.trackDownloadStatistic, {
        leaseApplicationId,
      })}`
    ),
};

export default api;
