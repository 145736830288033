import React from "react";

import { Route, Switch } from "react-router-dom";

import Layout from "manager/components/Layout";
import ROUTES from "manager/config/routes";
import { HeaderProps } from "shared/components/Header";
import { SidebarProps } from "shared/components/Sidebar";

import Lease from "./Lease";
import LeaseBuilder from "./LeaseBuilder";
import LeaseCountersign from "./LeaseCountersign";
import Leases from "./Leases";
import LeaseSummary from "./LeaseSummary";

interface LeasingRouterProps {
  sidebar: SidebarProps;
  header: HeaderProps;
}

const LeasingRouter = ({ sidebar, header }: LeasingRouterProps) => (
  <Switch>
    <Route exact path={ROUTES.leases}>
      <Layout sidebar={sidebar} header={header}>
        <Leases />
      </Layout>
    </Route>
    <Route exact path={ROUTES.lease}>
      <Lease sidebar={sidebar} header={header} />
    </Route>
    <Route exact path={ROUTES.leaseSummary}>
      <LeaseSummary sidebar={sidebar} header={header} />
    </Route>
    <Route exact path={ROUTES.leaseBuilder}>
      <LeaseBuilder sidebar={sidebar} header={header} />
    </Route>
    <Route exact path={ROUTES.leaseCountersign}>
      <LeaseCountersign sidebar={sidebar} header={header} />
    </Route>
  </Switch>
);

export default LeasingRouter;
